import styled from 'styled-components'
import { Modal } from 'antd'
import { BigButton } from '../../GlobalStyles'

export const ContainerModal = styled(Modal)`
  &&& .ant-modal-content {
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  }

  &&& .ant-modal-body {
    border-radius: 12px;
    padding: 40px;
  }

  &&& .ant-modal-close-x {
    color: red;
  }

  &&& .ant-upload.ant-upload-drag {
    width: auto !important;
    height: auto !important;
    padding: 28px 5px;
  }
`

interface IProps {
  color: string
  colorHover: string
  active: boolean
}

export const ContainerButtonsAvaliate = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`

export const TitleModal = styled.p`
  font-size: 30px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  text-align: center;
  max-width: 90%;
  margin: 0 auto 20px;
`

export const TitleDescrip = styled.p`
  font-size: 17px;
  margin-top: 5px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  color: #c9c9c9;
`

export const SmallTitleModal = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  text-align: center;
  max-width: 90%;
  margin: 0 auto 20px;
`

export const ButtonAvaliate = styled.div<IProps>`
  border: none;
  cursor: pointer;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  width: fit-content;
  height: 35px;
  padding: 15px;
  font-size: 20px;

  background-color: ${({ color }) => color};

  ${({ active, colorHover }) => active && `background-color: ${colorHover};`};

  :hover {
    transition: all 0.3s;
    background-color: ${({ colorHover }) => colorHover};
  }
`
export const ButtonSendAvaliate = styled(BigButton)`
  width: 250px;
  height: 40px;
  margin: 10px auto;
  background-color: #5c03b8;
  color: #fff;
  font-size: 20px;
`
