import styled from 'styled-components'

interface Props {
  color: string;
}

export const ContainerMenu = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
`

export const ContainerStatus = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const CardDonaults = styled.div`
  border-radius: 12px;
  border: 1px solid #d6d6d6;
  background-color: #f9f9f9;
  padding: 30px;
  margin-top: 30px;
  height: 350px;
`

export const CardStatus = styled.div`
  border-radius: 12px;
  border: 1px solid #d6d6d6;
  background-color: #f9f9f9;
  padding: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

export const TextHeader = styled.p`
  color: #272d3b;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  font-style: normal;
  text-transform: capitalize;
`

export const TextInfo = styled.p`
  color: #6f7782;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 20px;
`

export const DataNumber = styled.div`
  display: flex;
  align-items: center;
  color: #272d3b;
  font-family: "Open Sans";
  font-size: 17px;
  font-weight: 700;

  img{
    width: 20px;
    margin-right: 10px;
  }
`

export const TextDescription = styled.p`
  color: #242424;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 400;
`

export const ContainerInfo = styled.div`
`
export const InfoChart = styled.div<Props>`
  display: flex;
  align-items: center;
  color: #242424;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 400;

  div {
    width: 16px;
    height: 16px;
    background-color: ${(props) => props.color ? props.color : '#8884d8'};
    margin-right: 10px;
    border-radius: 50%;
  }
`

export const CardApps = styled.div`
  padding: 22px;
  border-radius: 12px;
  border: 1px solid #d6d6d6;
  background-color: #f9f9f9;
  height: 100%;
`

export const ContainerHeaderGb = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const TextGb = styled.p`
  color: #6600cc;
  font-family: "Open Sans";
  font-size: 20px;
`

export const ViewApp = styled.div`
  padding: 12px;
  border-radius: 12px;
  border: 1px solid #d6d6d6;
  background-color: #f7f7f7;
  height: 420px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #b56aff;
    border: 3px solid #b56aff;
    border-radius: 5px;
  }
`

export const LogoApp = styled.img`
  width: 45px;
  height: 45px;
  margin: 10px;
`

export const CardMap = styled.div`
  border-radius: 12px;
  border: 1px solid #d6d6d6;
  background-color: #f9f9f9;
  width: 100%;
  height: 100%;
  padding: 20px;
`

export const ContainerInfoCel = styled.div`
  margin: 20px 0;
  display: flex;
  align-items: center;
  color: #272d3b;
  font-family: "Open Sans";
  font-size: 15px;
  font-style: normal;

  svg{
    margin-right: 15px;
    color: rgb(181, 106, 255);
    font-size:  25px;
  }

  b{
    font-weight: 600;
    margin-left: 7px;
  }
`

export const ContainerData = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &&& .ant-picker-range{
    width: 100%;
  }
`

export const ButtonBack = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #6f7782;
    font-family: "Open Sans";
    font-size: 16px;
    margin-left: 10px;
    background-color: transparent;
`
