import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    margin-top: 20px;
    padding-bottom: 30px;
    img{
        max-width: 117px;
    }
`;

export const Text = styled.p`
    color: #4e4e4e;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 8px;
`