export const options = [
  { value: 'PROMOTER', label: 'PROMOTOR' },
  { value: 'GESTOR', label: 'GESTOR' },
  { value: 'SUPERVISOR', label: 'SUPERVISOR' },
  { value: 'CLIENT', label: 'CLIENTE' },
]

export const optionsProductType = [
  { label: 'Próprio', value: 'OWN' },
  { label: 'Concorrente', value: 'CONCURRENT' },
]

export const alerts = [
  { label: 'Offline', value: 'OFFLINE' },
  { label: 'Online', value: 'ONLINE' },
  { label: 'Todos', value: 'ALL' },
]

export const alertItens = [
  {
    label: 'Offline',
    value: 'offline',
  },
  {
    label: 'Online',
    value: 'online',
  },
  {
    label: 'Não conectaram',
    value: 'not-connect',
  },
  {
    label: 'Conectaram',
    value: 'connect',
  },
]

export const OptionsWeekDay = [
  {
    value: 'SUNDAY_ONE',
    label: 'Primeiro domingo',
  },
  {
    value: 'SUNDAY_TWO',
    label: 'Segundo domingo',
  },
  {
    value: 'SUNDAY_THREE',
    label: 'Terceiro domingo',
  },
  {
    value: 'SUNDAY_FOUR',
    label: 'Quarto domingo',
  },
  {
    value: 'MONDAY_ONE',
    label: 'Primeira segunda-feira',
  },
  {
    value: 'MONDAY_TWO',
    label: 'Segunda segunda-feira',
  },
  {
    value: 'MONDAY_THREE',
    label: 'Terceira segunda-feira',
  },
  {
    value: 'MONDAY_FOUR',
    label: 'Quarta segunda-feira',
  },
  {
    value: 'TUESDAY_ONE',
    label: 'Primeira terça-feira',
  },
  {
    value: 'TUESDAY_TWO',
    label: 'Segunda terça-feira',
  },
  {
    value: 'TUESDAY_THREE',
    label: 'Terceira terça-feira',
  },
  {
    value: 'TUESDAY_FOUR',
    label: 'Quarta terça-feira',
  },
  {
    value: 'WEDNESDAY_ONE',
    label: 'Primeira quarta-feira',
  },
  {
    value: 'WEDNESDAY_TWO',
    label: 'Segunda quarta-feira',
  },
  {
    value: 'WEDNESDAY_THREE',
    label: 'Terceira quarta-feira',
  },
  {
    value: 'THURSDAY_ONE',
    label: 'Primeira quinta-feira',
  },
  {
    value: 'THURSDAY_TWO',
    label: 'Segunda quinta-feira',
  },
  {
    value: 'THURSDAY_THREE',
    label: 'Terceira quinta-feira',
  },
  {
    value: 'THURSDAY_FOUR',
    label: 'Quarta quinta-feira',
  },
  {
    value: 'FRIDAY_ONE',
    label: 'Primeira sexta-feira',
  },
  {
    value: 'FRIDAY_TWO',
    label: 'Segunda sexta-feira',
  },
  {
    value: 'FRIDAY_THREE',
    label: 'Terceira sexta-feira',
  },
  {
    value: 'FRIDAY_FOUR',
    label: 'Quarta sexta-feira',
  },
  {
    value: 'SATURDAY_ONE',
    label: 'Primeiro sabado',
  },
  {
    value: 'SATURDAY_TWO',
    label: 'Segundo sabado',
  },
  {
    value: 'SATURDAY_THREE',
    label: 'Terceiro sabado',
  },
  {
    value: 'SATURDAY_FOUR',
    label: 'Quarto sabado',
  },
]

export const weekOptions = [
  {
    value: 'ONE',
    label: 'Primeira semana',
    test: 1,
  },
  {
    value: 'TWO',
    label: 'Segunda semana',
    test: 2,
  },
  {
    value: 'THREE',
    label: 'Terceira semana',
    test: 3,
  },
  {
    value: 'FOUR',
    label: 'Quarta semana',
    test: 4,
  },
]

export const dayOptions = [
  {
    value: 'SUNDAY',
    label: 'Domingo',
    index: 0,
  },
  {
    value: 'MONDAY',
    label: 'Segunda',
    index: 1,
  },
  {
    value: 'TUESDAY',
    label: 'Terça',
    index: 2,
  },
  {
    value: 'WEDNESDAY',
    label: 'Quarta',
    index: 3,
  },
  {
    value: 'THURSDAY',
    label: 'Quinta',
    index: 4,
  },
  {
    value: 'FRIDAY',
    label: 'Sexta',
    index: 5,
  },
  {
    value: 'SATURDAY',
    label: 'Sábado',
    index: 6,
  },
]

export const OptionsFreq = [
  {
    value: 'WEEKLY',
    label: 'Semanal',
    index: 1,
  },
  {
    value: 'FORTNIGHTLY',
    label: 'Quinzenal',
    index: 2,
  },
  {
    value: 'MONTHLY',
    label: 'Mensal',
    index: 4,
  },
]

export const field_true = [
  // { value: 'BOOLEAN_TRUE', label: 'SIM' },
  // { value: 'BOOLEAN_FALSE', label: 'NÃO' },
  // { value: 'BARCODE', label: 'Código de barras' }
  { value: 'TEXT', label: 'Textos' },
  { value: 'DATE', label: 'Data' },
  { value: 'BOOLEAN', label: 'Sim/Não' },
  { value: 'PICTURE_CAPTURE', label: 'Captura de imagem' },
  { value: 'INFO_PLANOGRAM', label: 'Planograma' },
  { value: 'MONEY', label: 'Dinheiro' },
  { value: 'NUMBER_INTEGER', label: 'Número Inteiro' },
  { value: 'NUMBER_DECIMAL', label: 'Número Decimal' },
  { value: 'HOUR', label: 'Hora' },
]

export const optionUF = [
  { label: 'AC', value: 'AC' },
  { label: 'AL', value: 'AL' },
  { label: 'AP', value: 'AP' },
  { label: 'AM', value: 'AM' },
  { label: 'BA', value: 'BA' },
  { label: 'CE', value: 'CE' },
  { label: 'DF', value: 'DF' },
  { label: 'ES', value: 'ES' },
  { label: 'GO', value: 'GO' },
  { label: 'MA', value: 'MA' },
  { label: 'MT', value: 'MT' },
  { label: 'MS', value: 'MS' },
  { label: 'MG', value: 'MG' },
  { label: 'PA', value: 'PA' },
  { label: 'PB', value: 'PB' },
  { label: 'PR', value: 'PR' },
  { label: 'PE', value: 'PE' },
  { label: 'PI', value: 'PI' },
  { label: 'RJ', value: 'RJ' },
  { label: 'RN', value: 'RN' },
  { label: 'RS', value: 'RS' },
  { label: 'RO', value: 'RO' },
  { label: 'RR', value: 'RR' },
  { label: 'SC', value: 'SC' },
  { label: 'SP', value: 'SP' },
  { label: 'SE', value: 'SE' },
  { label: 'TO', value: 'TO' },
]

export const Money = [
  { label: 'Real', value: 'BRL' },
  { label: 'Dolar', value: 'USD' },
  { label: 'Euro', value: 'EUR' },
]

export const FieldPurpose = [
  { value: 'RUPTURE', label: 'Ruptura' },
  { value: 'SHARE', label: 'Share' },
  { value: 'PRICE', label: 'Preço' },
  { value: 'EXTRA_POINT', label: 'Ponto extra' },
]

export const fieldTypes = [
  // { value: 'STARS', label: 'Estrelas' },
  // { value: 'BARCODE', label: 'Código de barras' }
  { value: 'TEXT', label: 'Textos' },
  { value: 'DATE', label: 'Data' },
  { value: 'INFO_TEXT', label: 'Texto informativo' },
  { value: 'BOOLEAN', label: 'Sim/Não' },
  { value: 'PICTURE_CAPTURE', label: 'Captura de imagem' },
  { value: 'MONEY', label: 'Dinheiro' },
  { value: 'NUMBER_INTEGER', label: 'Número Inteiro' },
  { value: 'NUMBER_DECIMAL', label: 'Número Decimal' },
  { value: 'HOUR', label: 'Hora' },
]

export const statusVisit = [
  {
    value: 'COMPLETE',
    label: 'COMPLETO',
  },
  {
    value: 'IN_PROGRESS',
    label: 'EM PROGRESSO',
  },
]

export const isActiveSelect = [
  { value: true, label: 'ATIVO' },
  { value: false, label: 'INATIVO' },
]

export const isActiveSelectVisita = [
  { value: 'COMPLETE', label: 'Concluído' },
  { value: 'PENDENT', label: 'Pendente' },
  { value: 'IN_PROGRESS', label: 'Em progresso' },
  { value: 'JUSTIFIED_ABSENCE', label: 'Justificada' },
]

export const isActivePromoter = [
  { value: true, label: 'ATIVO' },
  { value: false, label: 'INATIVO' },
]

export const statusFileReader = [
  { value: 'PENDENT', label: 'Pendente' },
  { value: 'COMPLETE', label: 'Completo' },
  { value: 'IN_PROGRESS', label: 'Em progresso' },
]

export const contextFile = [
  { value: 'VISIT', label: 'Visita' },
  { value: 'PROMOTER', label: 'Promotor' },
  { value: 'MIX_FORM', label: 'Mix de Formulário' },
  { value: 'SCRIPT', label: 'Roteiro' },
  { value: 'MIX_PRODUCT', label: 'Mix de Produtos' },
  { value: 'PRODUCT', label: 'Produto' },
  { value: 'PROMOTER', label: 'Promotor' },
  { value: 'PDV', label: 'PDV' },
]
