import React, { useEffect, useState } from 'react'
import { Container, TextError, Title } from './styles'
import { InputNumber } from 'antd'

interface IQuestion {
  id: number
  is_required: boolean
  name: string
  order: string
  type: string
  fields_true?: any
  minNumber?: any
  maxNumber?: any
}
interface IDataQuestion {
  dataQuestion: IQuestion
  handleAnswers: (value: any, dataQuestion: IQuestion) => void
  error?: boolean
  checkRequired: (value: any) => void
  defaultVal?: any
}

export const Int = ({ dataQuestion, handleAnswers, checkRequired, error, defaultVal }: IDataQuestion) => {
  const [value, setValue] = useState('')
  const [isRender, setIsRender] = useState(false)

  const handleValue = (value: string) => {
    console.log(value)
    setValue(value)
    handleAnswers([value], dataQuestion)
  }

  useEffect(() => {
    if (dataQuestion.is_required && !isRender) {
      setIsRender(true)
      checkRequired(dataQuestion.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const val = defaultVal && (defaultVal[0] || defaultVal[0] == 0) ? defaultVal[0] : undefined
    if(val || val == 0) {
      handleValue(val)
    }
  }, [defaultVal])

  return (
    <Container>
      <Title>
        {dataQuestion.order} - {dataQuestion.name} {dataQuestion.is_required && '*'}
      </Title>
      {dataQuestion.minNumber && <p style={{fontSize: 12}}>Valor mínimo:{dataQuestion.minNumber}</p>}
      {dataQuestion.maxNumber && <p style={{fontSize: 12}}>Valor máximo:{dataQuestion.maxNumber}</p>}
      <InputNumber value={value} onChange={handleValue} style={{ width: '100%' }} />
      {error && !value && <TextError>Campo obrigatório</TextError>}
    </Container>
  )
}
