import styled from 'styled-components'
import { Modal } from 'antd'

export const ContainerModal = styled(Modal)`
  &&& .ant-modal-content {
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  }

  &&& .ant-modal-body {
    border-radius: 12px;
    height: 750px;
  }

  &&& .ant-modal-close-x {
    color: red;
  }

  @media (max-width: 762px) {
    &&& .ant-modal-body {
      height: auto !important;
    }

    &&& .ant-modal-body {
      height: auto !important;
    }

  }
  &&& .overflow-kanban::-webkit-scrollbar {
    width: 0px;
  }
`
export const Header = styled.div`
  width: 100%;
  height: 86px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const PinCircule = styled.div`
  width: 13px;
  height: 13px;
  border-radius: 7px;
  background-color: #6701cd;
  border: 2px solid #fff;
`

export const ContainerDate = styled.div`
  width: 198px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #272d3b;
  font-size: 23px;
`

export const TextDate = styled.p`
  color: #242424;
  font-family: 'Open Sans';
  font-size: 27px;
  font-weight: 600;
  margin: 0;
`

export const ContainerUser = styled.div`
  width: 100%;
  border-radius: 12px;
  border: 1px dashed #b56aff;
  background-color: #f7f7f7;
  padding: 14px 16px;
`

export const HeaderUser = styled.div`
  display: flex;
  justify-content: space-between;
  color: #6f7782;
  margin-bottom: 15px;
`

export const DatasUser = styled.div`
  display: flex;

  img {
    margin-right: 13px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
  }
`

export const ContainerStatus = styled.div``

export const Name = styled.p`
  color: #242424;
  font-family: 'Open Sans';
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
`
export const ContainerOff = styled.div`
  display: flex;
  align-items: center;
`

export const CirculeStatus = styled.div`
  width: 10px;
  height: 10px;
  background-color: #e9830f;
  border-radius: 5px;
`

export const TextOff = styled.p`
  color: #6f7782;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: italic;
  margin-bottom: 0;
  margin-left: 10px;
`

export const Active = styled.div`
  margin-top: 7px;
  width: 53px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid #16c266;
  background-color: rgba(184, 255, 217, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0b9c4e;
  font-family: 'Open Sans';
  font-size: 12px;
  font-weight: 600;
  margin-left: 10px;
`
export const HeaderInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    color: #6600cc;
    font-size: 20px;
    margin-right: 10px;
  }
`

export const Info = styled.div`
  color: #6f7782;
  font-family: 'Open Sans';
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ContainerTasks = styled.div`
  width: 100%;
  height: 33px;
  border-radius: 7px;
  background-color: #f7f0fe;
  display: flex;
  align-items: center;
  color: #6600cc;
  font-family: Poppins;
  font-size: 13px;
  padding-left: 7px;
  margin-top: 11px;
  margin-bottom: 23px;

  div {
    display: flex;
  }

  svg {
    margin-right: 10px;
    font-size: 20px;
  }
`

export const ContainerPdv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #d6d6d6;
  padding: 0 10px 15px 10px;

  &&& .ant-select {
    width: 70%;
  }

  &&& .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
  }

  &&& .ant-select-arrow {
    font-size: 13px;
    color: black;
  }

  &&& .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    font-size: 18px;
  }
`

export const TextPdv = styled.div`
  color: #242424;
  font-family: 'Open Sans';
  font-size: 18px;
  font-weight: 600;
  width: 90px;
  min-height: 25px;
  color: #242424;
  text-align: center;
  svg {
    margin-left: 15px;
  }
`

export const ContainerHistory = styled.div`
  /* width: 100%; */
  max-height: 50%;
  overflow-y: auto;
  padding-top: 5px;
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #ebebeb;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #666666;
    border-radius: 20px;
    border: 3px solid #ebebeb;
  }
`

export const DataHistory = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  min-height: 40px;
  padding: 8px;
  border-bottom: 1px solid #d6d6d6;
  flex-wrap: wrap;
`

export const ContainerPointer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 17px;
`

export const HR = styled.div`
  border: 2px solid #d6d6d6;
  height: calc(100% - 14px);
  width: 2px;
`

export const Point = styled.div`
  width: 11px;
  height: 11px;
  background-color: #6600cc;
  border-radius: 5px;
  margin-bottom: 3px;
`

export const TextHistory = styled.div`
  color: #6f7782;
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: 400;
  display: flex;
  height: 100%;
  min-height: 40px;
  max-width: 70%;
`

export const TextHour = styled.p`
  width: 90px;
  color: #29374d;
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  height: 100%;
  margin: 0;
`

export const ContainerMap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100% !important;
`

export const ContainerAdress = styled.div`
  width: 100%;
  color: #6f7782;
  font-family: 'Open Sans';
  font-size: 16px;
  text-align: center;
`
export const Map = styled.div`
  width: 534px;
  height: 100%;
  min-height: 250px;
  max-height: 531px;
  border-radius: 5px;
  margin-bottom: 30px;
  position: relative;
`

export const ContainerImg = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  img {
    max-height: 70px;
    margin: 7px;
    cursor: pointer;
  }
`
