import styled from 'styled-components'

export const Container = styled.div`
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 20px;
  @media (max-width: 767px) {
    padding: 10px;
    padding-top: 20px;
  }
`

export const CreateForm = styled.div`
  input {
    border: 1px solid #c9d3dd;
    padding: 5px 10px;
    box-sizing: content-box;
  }
`

export const SearchWorks = styled.div`
  color: #545454;
  display: flex;

  svg {
    font-size: 20px;
    margin: 5px 10px;
  }
`

export const Wrapper = styled.header`
  justify-content: center;
  align-items: center;
  
  h1 {
    color: #000;
  }

  p {
    color: #637282;
    margin-bottom: 30px;
  }
`

export const WrapperAction = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const WorkspaceList = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  margin-top: 1rem;
  flex-wrap: wrap;
  padding-top: 10px;
`

export const WorkspaceOption = styled.div`
  background: #f8f8f8;
  border-radius: 10px;
  border: 1px solid #ccc;
  width: calc(15% - 10px);
  min-width: 150px;
  margin: 5px 10px;
  text-align: center;
  box-shadow: -2px 2px 8px 3px #dddddd;
  cursor: pointer;
  transition: 0.1s;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 120px;
  :hover {
    transition: all 0.2s;
    background: #ffffff;
    border: none;
  }
  svg {
    font-size: 35px;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`

export const ContainerIcon = styled.div`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  svg {
    font-size: 15px !important;
  }
`

export const WorkspaceLogo = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin: 0 auto 10px;
  background-color: #ccc;
  border: 1px solid #ccc;
`

export const WorkspaceName = styled.h4`
  font-weight: 500;
`
export const Header = styled.div`
  width: calc(94% - 25px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 767px) {
    width: 100%;
    flex-wrap: wrap;
  }
`
export const ContainerSearch = styled.div`
  border-radius: 5px;
  width: 200px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #eeddfe;
  font-size: 17px;
  color: black;
  margin-right: 20px;
  @media (max-width: 767px) {
    width: 100%;
    margin-right: 0px;
    height: 40px;
  }
`
export const Search = styled.input`
  height: 100%;
  width: 100%;
  border: none;
  background-color: #eeddfe;
  outline: none;
  border-radius: 5px;
  padding-left: 5px;
  font-size: 17px;
`

export const BigButton = styled.button`
  padding: 0 5px 0 10px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  margin-left: 20px;
  background-color: #6d55cc;
  border: none;
  border-radius: 5px;
`
export const ContainerOptions = styled.div`
  height: auto;
  border: solid 1px #a6a6a6;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 3px;
  justify-content: space-between;
  padding: 5px 3px;
  gap: 15px;
  z-index: 2;
  svg {
    font-size: 19px;
  }

  svg:hover {
    background-color: #f1f1f1;
  }
`

export const ContainerFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 17px;
  position: relative;
  top: 60%;

  img {
    width: 150px;
  }
`
