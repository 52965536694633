import styled from 'styled-components'

export const Container = styled.div`
  flex-direction: column;
  flex: 1;
  padding: 20px;
`

export const SubContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  box-shadow: 1px 1px 4px #7158c1;
  padding: 30px;
  width: 100%;
  margin: 15px 0;
`
export const UploadImage = styled.div`
  position: relative;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  text-align: center;
  border: 1px dashed #d9d9d9;
  cursor: pointer;
  background: #fafafa;

  transition: border-color 0.3s;

  h5 {
    cursor: pointer;
  }

  &:hover {
    border-color: #40a9ff;
  }

  svg {
    margin-top: 10px;
    cursor: pointer;
  }

  img {
    height: 150px;
    width: 100%;
  }
`

export const Wrapper = styled.header`
  justify-content: center;
  align-items: center;

  h1 {
    color: #000;
  }

  p {
    color: #637282;
    margin-bottom: 30px;
  }
`


export const LabelCheck = styled.div`
  text-align: center;
  font-weight: bold;
  margin: 10px 0;
`

export const InputCheckbox = styled.div`
  label,
  span {
    display: flex;
    margin: 0 auto;
  }
`