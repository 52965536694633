import { ConfigProvider } from 'antd'
import ptBR from 'antd/es/locale/pt_BR'
import React, { useEffect, useState } from 'react'
import Menu from '../Menu'
import { SwitchWorkspace } from '../SwitchWorkspace'
import { Container, Content, Header, Page } from './styles'
import history from '../../routes/history'
import { Footer } from '../Footer'
export interface workspace {
  avatar_url: any
  name: string
}

const LayoutDefault: React.FC = ({ children }: any) => {
  const [workspace, setWorkspace] = useState<workspace>()
  let getWorkspace = sessionStorage.getItem('@rocketpdv:workspace')

  useEffect(() => {
    getWorkspace === null ? history.push('/backoffice/workspace') : setWorkspace(JSON.parse(getWorkspace))

    if (getWorkspace && JSON.parse(getWorkspace).id === 0) {
      history.push('/backoffice/newWorkspace')
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ConfigProvider locale={ptBR}>
      <Container>
        <Header>{workspace && <Menu Iworkspace={workspace} />}</Header>
        <SwitchWorkspace Iworkspace={workspace} />
        <Content>
          <Page>{children}</Page>
        </Content>
        <Footer />
      </Container>
    </ConfigProvider>
  )
}

export default LayoutDefault
