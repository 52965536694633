import React from 'react'
import {
  ContainerQuestion,
  TitleDecimal,
  Description,
} from '../style'
import { InputNumber, Form } from 'antd'
import ViewPicture from './viewPicture'

const FloatQuestion: React.FC<any> = ({ quest }) => {

  return (
    <>
      <ContainerQuestion>
        <TitleDecimal>
          <h1>
            {quest.is_required ? '*' : null} {quest.order} →{' '}
          </h1>{' '}
          {quest.name}
        </TitleDecimal>
        {quest.description ? <Description>{quest.description}</Description> : null}
        {quest.maxNumber || quest.minNumber ? (
          <div style={{ marginBottom: '20px;' }}>
            {quest.maxNumber ? (
              <p style={{ fontSize: '17px' }}>
                Valor máximo: <b>{quest.maxNumber}</b>{' '}
              </p>
            ) : null}
            {quest.minNumber ? (
              <p style={{ fontSize: '17px' }}>
                Valor mínimo: <b>{quest.minNumber}</b>{' '}
              </p>
            ) : null}
          </div>
        ) : null}
        {quest.picture && typeof quest.picture == 'string' &&  <ViewPicture picture={quest.picture} />}
        <Form.Item
          name='NUMBER_DECIMAL'
          label='Número quebrado'
          rules={[{ required: quest.is_required ? true : false }]}
        >
          <InputNumber
            min={1}
            defaultValue={1000}
            style={{ width: '100%' }}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
          />
        </Form.Item>
      </ContainerQuestion>
    </>
  )
}

export default FloatQuestion
