import { Col, Row, Tooltip, Pagination, DatePicker, Tag } from 'antd'
import { TextHeader } from '../TabGeneralUser/style'
import {
  CaretRightOutlined,
  FilterOutlined,
  CaretLeftOutlined,
  FileSearchOutlined,
  ClearOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { Loading } from '../Loading'
import api_v2 from '../../services/api-v2'
import qs from 'qs'
import useReactRouter from 'use-react-router'
import { INIT_GLOBAL_RESPONSE } from '../../utils/global-response'
import { CardPicture } from '../CardPicture'
import { ButtonClear, ButtonSearch, ContainerPaginationFooter, LabelForm } from '../../pages/Team/Promoter/style'
import { ContainerVisit } from '../TabsVisits/style'
import { HeaderScript } from '../TabsScript/style'
import { BigButton, ContainerButtonsFootter, ContainerFormFilter, GroupForm } from '../../GlobalStyles'
import { Form } from '@unform/web'
import { Search } from '../form/SimpleSearch'
import locale from 'antd/es/date-picker/locale/pt_BR'
import { FormHandles } from '@unform/core'
import moment from 'moment'
import { DrawerFilter } from '../DrawerFilter'
import { ContainerTagg } from '../../pages/Survey/FormListPage/styles'
import { NoSearch } from '../NoSearch'
const dateFormat = 'DD/MM/YYYY'

interface Props {
  dateFilter: any
  setterDateFilter: any
}

export const TabsPictures: React.FC<Props> = ({ dateFilter, setterDateFilter }) => {
  const [loading, setLoading] = useState(false)
  const formRef = useRef<FormHandles>(null)
  const { id }: any = useParams()
  const { location } = useReactRouter()
  const [filterTag, setFilterTag] = useState<any>({})
  const [filter, setFilter] = useState<any>({})
  const [dataPictures, setDataPicture] = useState<any>(INIT_GLOBAL_RESPONSE)
  const [visible, setVisible] = useState(false)
  const [dateRange, setDateRange] = useState<any>([
    moment(new Date(), dateFormat).subtract(7, 'd'),
    moment(new Date(), dateFormat),
  ])

  const getDataPictures = async (dataParam: any) => {
    try {
      const { flag_id, pdv_id, network_id, form_id } = dataParam
      setDataPicture(INIT_GLOBAL_RESPONSE)
      setLoading(true)
      let dataTag = {}
      let filterExport: any = {}

      let obj: any = {
        perpage: dataPictures.perpage,
        page: dataPictures.page,
        promoter: id,
      }

      if (flag_id && flag_id.length > 0) {
        filterExport = Object.assign({ flag_id: flag_id.map((el: any) => el.value) }, filterExport)
        let text = ''
        flag_id.forEach((el: any) => (text = text === '' ? `${el.value}` : text + `,${el.value}`))
        obj = { ...obj, flag: text }
        dataTag = { ...dataTag, flag_id }
      }

      if (pdv_id && pdv_id.length > 0) {
        filterExport = Object.assign({ pdv_id: pdv_id.map((el: any) => el.value) }, filterExport)
        let text = ''
        pdv_id.forEach((el: any) => (text = text === '' ? `${el.value}` : text + `,${el.value}`))
        obj = { ...obj, pdv: text }
        dataTag = { ...dataTag, pdv_id }
      }

      if (network_id && network_id.length > 0) {
        filterExport = Object.assign({ network_id: network_id.map((el: any) => el.value) }, filterExport)
        let text = ''
        network_id.forEach((el: any) => (text = text === '' ? `${el.value}` : text + `,${el.value}`))
        obj = { ...obj, network: text }
        dataTag = { ...dataTag, network_id }
      }

      if (form_id && form_id.length > 0) {
        filterExport = Object.assign({ form_id: form_id.map((el: any) => el.value) }, filterExport)
        let text = ''
        form_id.forEach((el: any) => (text = text === '' ? `${el.value}` : text + `,${el.value}`))
        obj = { ...obj, form: text }
        dataTag = { ...dataTag, form_id }
      }

      if (dateRange) {
        let start_date = moment(dateRange[0]).format('YYYY-MM-DD').toString()
        let end_date = moment(dateRange[1]).format('YYYY-MM-DD').toString()
        obj = { ...obj, start_date, end_date }
        dataTag = { ...dataTag, start_date: start_date, end_date: end_date }
        filterExport = Object.assign({ start_date, end_date }, filterExport)
      }

      const query = qs.stringify(obj)
      const { data } = await api_v2.get(`/pictures?${query}`)
      setDataPicture({ ...data, loading: false })
      setVisible(false)
      setFilterTag(dataTag)
      setFilter(filterExport)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const handlePage = async (current: any, perpage: any) => {
    try {
      setLoading(true)
      let obj: any = {
        promoter: id,
        perpage: perpage,
        page: current,
        start_date: filterTag.start_date ? filterTag.start_date : moment().format('YYYY-MM-DD'),
        end_date: filterTag.end_date ? filterTag.end_date : moment().format('YYYY-MM-DD'),
      }

      const query = qs.stringify(obj)
      const { data } = await api_v2.get(`/pictures?${query}`)
      setDataPicture({ ...data, loading: false })
      setLoading(false)

      window.scrollTo(0, 0)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const itemRender = (current: any, type: any, originalElement: any) => {
    if (type === 'prev') {
      return (
        <div style={{ display: 'flex', alignItems: 'center', color: '#6f7782' }}>
          <CaretLeftOutlined style={{ marginRight: 10 }} />
          <p style={{ margin: 0 }}>anterior</p>
        </div>
      )
    }
    if (type === 'next') {
      return (
        <div style={{ display: 'flex', alignItems: 'center', color: '#6f7782' }}>
          <p style={{ margin: 0 }}>próximo</p>
          <CaretRightOutlined style={{ marginRight: 10 }} />
        </div>
      )
    }
    return originalElement
  }

  useEffect(() => {
    getDataPictures({})

    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  const cleanFilter = async () => {
    setDataPicture({ ...dataPictures, loading: true })
    setVisible(false)
    const { data } = await api_v2.get(`/pictures`)
    setDataPicture({ ...data, loading: false })
  }

  const removeFilter = async (typeRemove: string, value: any) => {
    let newFilter: any = filter
    let newObjTagg = filterTag
    if (typeRemove === 'pdv_id') {
      newObjTagg.pdv_id = newObjTagg.pdv_id.filter((el: any) => el.value !== value)
      if (newObjTagg.pdv_id.length === 0) delete newObjTagg.pdv_id

      newFilter.pdv_id = newFilter.pdv_id.filter((el: any) => el !== value)

      if (newFilter.pdv_id.length === 0) delete newFilter.pdv_id
    }
    if (typeRemove === 'form_id') {
      newObjTagg.form_id = newObjTagg.form_id.filter((el: any) => el.value !== value)
      if (newObjTagg.form_id.length === 0) delete newObjTagg.form_id

      newFilter.form_id = newFilter.form_id.filter((el: any) => el !== value)

      if (newFilter.form_id.length === 0) delete newFilter.form_id
    }
    if (typeRemove === 'flag_id') {
      newObjTagg.flag_id = newObjTagg.flag_id.filter((el: any) => el.value !== value)
      if (newObjTagg.flag_id.length === 0) delete newObjTagg.flag_id

      newFilter.flag_id = newFilter.flag_id.filter((el: any) => el !== value)

      if (newFilter.flag_id.length === 0) delete newFilter.flag_id
    }

    if (typeRemove === 'network_id') {
      newObjTagg.network_id = newObjTagg.network_id.filter((el: any) => el.value !== value)
      if (newObjTagg.network_id.length === 0) delete newObjTagg.network_id

      newFilter.network_id = newFilter.network_id.filter((el: any) => el !== value)

      if (newFilter.network_id.length === 0) delete newFilter.network_id
    }
    if (typeRemove === 'start_date') delete newObjTagg.start_date
    if (typeRemove === 'end_date') delete newObjTagg.end_date

    setLoading(true)
    setFilter(newFilter)
    setFilterTag(newObjTagg)
    setLoading(false)
    getDataPictures(newFilter)
  }

  return (
    <ContainerVisit>
      {loading ? (
        <Loading />
      ) : (
        <>
          <HeaderScript style={{ marginBottom: 20 }}>
            <TextHeader>Fotos do promotor</TextHeader>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <>
                <Tooltip placement='top' title={'Filtrar'}>
                  <BigButton onClick={() => setVisible(!visible)} style={{ padding: 9 }}>
                    <FilterOutlined />
                  </BigButton>
                </Tooltip>
                <ContainerTagg>
                  {filterTag.pdv_id &&
                    filterTag.pdv_id.map((el: any) => (
                      <Tag color='geekblue' onClick={() => removeFilter('pdv_id', el.value)}>
                        {el.label} <CloseOutlined />
                      </Tag>
                    ))}
                  {filterTag.flag_id &&
                    filterTag.flag_id.map((el: any) => (
                      <Tag color='geekblue' onClick={() => removeFilter('flag_id', el.value)}>
                        {el.label} <CloseOutlined />
                      </Tag>
                    ))}
                  {filterTag.network_id &&
                    filterTag.network_id.map((el: any) => (
                      <Tag color='geekblue' onClick={() => removeFilter('network_id', el.value)}>
                        {el.label} <CloseOutlined />
                      </Tag>
                    ))}
                  {filterTag.start_date && (
                    <Tag color='geekblue' onClick={() => removeFilter('start_date', `${filterTag.start_date}`)}>
                      {moment(filterTag.start_date).format('DD/MM/YYYY')}
                      <CloseOutlined />
                    </Tag>
                  )}
                  {filterTag.end_date && (
                    <Tag color='geekblue' onClick={() => removeFilter('end_date', `${filterTag.end_date}`)}>
                      {moment(filterTag.end_date).format('DD/MM/YYYY')}
                      <CloseOutlined />
                    </Tag>
                  )}
                  {filterTag.form_id &&
                    filterTag.form_id.map((el: any) => (
                      <Tag color='geekblue' onClick={() => removeFilter('form_id', el.value)}>
                        {el.label} <CloseOutlined />
                      </Tag>
                    ))}
                </ContainerTagg>
              </>
            </div>
          </HeaderScript>
          {dataPictures.result && dataPictures.result.length > 0 ? (
            <Row gutter={[30, 30]} justify='center'>
              {dataPictures.result.length === 0 ? (
                <ContainerVisit style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <h3 style={{ fontSize: 18, fontWeight: 'bold', letterSpacing: 2 }}>
                    Sem fotos coletadas até o momento.
                  </h3>
                </ContainerVisit>
              ) : (
                <>
                  {dataPictures.result.map((el: any) => (
                    <Col>
                      <CardPicture data={el} />
                    </Col>
                  ))}
                </>
              )}
              <Col span={24}>
                <ContainerPaginationFooter>
                  <Pagination
                    defaultCurrent={dataPictures.page}
                    total={dataPictures.total}
                    onChange={handlePage}
                    itemRender={itemRender}
                  />
                </ContainerPaginationFooter>
              </Col>
            </Row>
          ) : (
            <NoSearch text='Não encontramos nenhum dado aqui.' />
          )}
        </>
      )}
      <DrawerFilter
        visible={visible}
        close={setVisible}
        title='Fotos'
        footer={
          <ContainerButtonsFootter>
            <ButtonSearch type='button' onClick={() => formRef?.current?.submitForm()}>
              <FileSearchOutlined />
              Pesquisar
            </ButtonSearch>
            <ButtonClear onClick={() => cleanFilter()}>
              <ClearOutlined />
              Limpar
            </ButtonClear>
          </ContainerButtonsFootter>
        }
      >
        <ContainerFormFilter
          tabIndex={0}
          onKeyDown={(evt: any) => {
            if (evt.key === 'Enter') formRef?.current?.submitForm()
          }}
        >
          <Form ref={formRef} className='form' style={{ marginTop: '15px' }} onSubmit={getDataPictures}>
            <GroupForm>
              <LabelForm>Selecione a loja</LabelForm>
              <Search placeholder='Selecione o PDV' name='pdv_id' path='pdvs' isMult={true} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione a bandeira</LabelForm>
              <Search placeholder='Selecione a bandeira' name='flag_id' path='flags' isMult={true} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione a rede</LabelForm>
              <Search placeholder='Selecione a rede' name='network_id' path='networks' isMult={true} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione o formulário</LabelForm>
              <Search placeholder='Selecione o formulário' name='form_id' path='forms' isMult={true} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione o periodo </LabelForm>
              <DatePicker.RangePicker
                locale={locale}
                defaultValue={dateRange}
                format={dateFormat}
                ranges={{
                  Hoje: [moment(), moment()],
                  'Esta semana': [moment().startOf('week'), moment().endOf('week')],
                  'Este mês': [moment().startOf('month'), moment().endOf('month')],
                }}
                onChange={(val: any) => setDateRange(val)}
              />
            </GroupForm>
          </Form>
        </ContainerFormFilter>
      </DrawerFilter>
    </ContainerVisit>
  )
}
