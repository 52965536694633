import React from 'react'

const ViewPicture: React.FC<any> = ({ picture }) => {


  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 20
    }}>
      <img src={picture} alt='picture' style={{
        width: 100,
        height: 100
      }} />
    </div>
  )
}

export default ViewPicture
