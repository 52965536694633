import { DeleteOutlined, EditOutlined, MoreOutlined, DownOutlined } from '@ant-design/icons'

import { Button, Col, Dropdown, Menu, message, Popconfirm } from 'antd'

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { DataStatus } from '../../../components/TabsVisits/style'
import { getStatus } from '../../Survey/VisitsPage'
import { compareByAlph, exportXls } from '../../../utils/funcoes'
import DefaultPage from '../../../components/DefaultPage'
import { Mixpanel } from '../../../utils/mixpanel'
import { ContextUpload } from '../../../context/contextUpload'
import { hasIndex } from '../../../components/Menu/menuHorizonte'
import api_v2 from '../../../services/api-v2'

export interface RegionaisFilter {
  name?: string
}

const RegionalListPage: React.FC = () => {
  const { permissions } = ContextUpload()
  const [loading, setLoading] = useState(false)
  const [reload, setReload] = useState(false)
  const [selecteds, setSelecteds] = useState<any>([])

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      setSelecteds(selectedRows)
    },
    getCheckboxProps: (record: any) => ({
      disabled: !record.is_active, // Column configuration not to be checked
    }),
  }

  async function handleDelete(id: string) {
    setLoading(true)
    try {
      await api_v2.delete(`/regional/${id}`)
      message.success('Inativado com sucesso')
      Mixpanel.track('Inativado regional', {
        params: {
          id: id,
        },
      })
      setReload(!reload)
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  const deleteMass = async (id: string) => {
    try {
      await api_v2.delete(`/regional/${id}`)
      Mixpanel.track('Inativou regional', {
        params: { id: id },
      })
      message.success('Inativado com sucesso')
    } catch (err) {}
  }

  return (
    <DefaultPage
      titlePage='Regionais'
      breadcrumb={[{ name: 'Home', url: '/' }, { name: 'Regionais' }]}
      urlPage='/backoffice/store/regionals'
      routeApi={{
        method: 'get',
        route: '/regional',
        versionApi: 'v1',
        reload,
      }}
      listButtons={{
        buttonCreate: {
          visible: hasIndex(['store.add_regional'], permissions) > -1,
          type: 'url',
          url: '/backoffice/store/regionals/create',
        },
        buttonExport: {
          visible: hasIndex(['store.export_regional'], permissions) > -1,
          type: 'function',
          function: async () => {
            setLoading(true)
            exportXls({}, 'regionals')
            setLoading(false)
          },
        },
        buttonimport: {
          visible: hasIndex(['store.import_regional'], permissions) > -1,
          path: 'REGIONAL',
        },
        buttonFilter: {
          visible: true,
          filters: [
            {
              label: 'Selecione a região:',
              name: 'name',
              type: 'search',
              urlSearch: 'regionals',
              getEnv: 'label',
            },
          ],
        },
        newButton: {
          visible: selecteds.length > 0 ? true : false,
          children: (
            <Col span={5}>
              <Dropdown
                overlay={
                  <Menu>
                    {hasIndex(['store.change_regional'], permissions) > -1 && (
                      <Menu.Item key='1' icon={<DeleteOutlined />}>
                        <Popconfirm
                          title='Tem certeza que deseja inativar estas regionais?'
                          onConfirm={async () => {
                            setLoading(!loading)

                            for await (let regional of selecteds) {
                              Mixpanel.track('Confirmou inativar regional', regional)
                              await deleteMass(regional.id)
                            }
                            setLoading(!loading)
                            setReload(!reload)
                          }}
                          okText='Sim'
                          cancelText='Não'
                          placement='topRight'
                        >
                          Inativar regional
                        </Popconfirm>
                      </Menu.Item>
                    )}
                  </Menu>
                }
              >
                <Button>
                  Ações em massa ({selecteds.length}) <DownOutlined />
                </Button>
              </Dropdown>
            </Col>
          ),
        },
      }}
      rowSelection={{
        type: 'checkbox',
        ...rowSelection,
      }}
      load={{ loading, setLoading }}
      columns={[
        {
          title: 'Status',
          dataIndex: 'is_active',
          key: 'is_active',
          width: 80,
          render: (status: any) => {
            return <DataStatus>{getStatus(status.toString())}</DataStatus>
          },
        },
        {
          title: 'Nome',
          dataIndex: 'name',
          sorter: (a: any, b: any) => compareByAlph(a.name, b.name),
        },
        {
          title: '#',
          width: 50,
          render: (obj: any) => {
            if (hasIndex(['store.remove_regional', 'store.change_regional'], permissions) === -1) return
            return (
              <Dropdown
                overlay={
                  <Menu>
                    {hasIndex(['store.change_regional'], permissions) > -1 && (
                      <Menu.Item key='1' icon={<DeleteOutlined />}>
                        <Popconfirm
                          onConfirm={() => handleDelete(obj.id)}
                          title='Tem certeza que deseja inativar esta região?'
                          okText='Sim'
                          cancelText='Não'
                          placement='topRight'
                        >
                          Inativar região
                        </Popconfirm>
                      </Menu.Item>
                    )}
                    {hasIndex(['store.change_regional'], permissions) > -1 && (
                      <Link to={`/backoffice/store/regionals/edit/${obj.id}`}>
                        <Menu.Item key='2' icon={<EditOutlined />}>
                          Editar
                        </Menu.Item>
                      </Link>
                    )}
                  </Menu>
                }
              >
                <Button size='small' shape='circle' icon={<MoreOutlined />} />
              </Dropdown>
            )
          },
        },
      ]}
    />
  )
}
export default RegionalListPage
