import { DeleteOutlined, EditOutlined, MoreOutlined, DownOutlined } from '@ant-design/icons'

import { Button, Col, Dropdown, Menu, message, Popconfirm } from 'antd'

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { isActiveSelect } from '../../../utils/option-user'
import { DataStatus } from '../../../components/TabsVisits/style'
import { getStatus } from '../../Survey/VisitsPage'
import { compareByAlph, exportXls } from '../../../utils/funcoes'
import DefaultPage from '../../../components/DefaultPage'
import { Mixpanel } from '../../../utils/mixpanel'
import { ContextUpload } from '../../../context/contextUpload'
import { hasIndex } from '../../../components/Menu/menuHorizonte'
import api_v2 from '../../../services/api-v2'

const NetworkListPage: React.FC = () => {
  const { permissions } = ContextUpload()
  const [loading, setLoading] = useState(false)
  const [reload, setReload] = useState(false)
  const [selecteds, setSelecteds] = useState<any>([])

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      setSelecteds(selectedRows)
    },
    getCheckboxProps: (record: any) => ({
      disabled: !record.is_active, // Column configuration not to be checked
    }),
  }

  async function handleDelete(id: string) {
    setLoading(true)
    try {
      await api_v2.delete(`/network/${id}`)

      Mixpanel.track('Deletou Rede', {
        params: {
          id: id,
        },
      })
      message.success('Rede inativada com sucesso')
      setReload(!reload)
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  const deleteMass = async (id: string) => {
    try {
      await api_v2.delete(`/network/${id}`)
      Mixpanel.track('Inativou Rede', {
        params: { id: id },
      })
      message.success('Inativado com sucesso')
    } catch (err) {}
  }

  return (
    <DefaultPage
      titlePage='Redes'
      breadcrumb={[{ name: 'Home', url: '/' }, { name: 'Redes' }]}
      urlPage='/backoffice/store/networks'
      routeApi={{
        method: 'get',
        route: '/network',
        versionApi: 'v1',
        reload,
      }}
      listButtons={{
        buttonCreate: {
          visible: hasIndex(['store.add_network'], permissions) > -1,
          type: 'url',
          url: '/backoffice/store/networks/create',
        },
        buttonExport: {
          visible: hasIndex(['store.export_network'], permissions) > -1,
          type: 'function',
          function: async () => {
            setLoading(true)
            exportXls({}, 'networks')
            setLoading(false)
          },
        },
        buttonimport: {
          visible: hasIndex(['store.import_network'], permissions) > -1,
          path: 'NETWORK',
        },
        buttonFilter: {
          visible: true,
          filters: [
            {
              label: 'Selecione a rede:',
              name: 'name',
              type: 'search',
              urlSearch: 'networks',
              getEnv: 'label',
            },
            {
              label: 'Selecione o status',
              name: 'is_active',
              type: 'select',
              options: isActiveSelect,
              getEnv: 'value',
            },
          ],
        },
        newButton: {
          visible: selecteds.length > 0 ? true : false,
          children: (
            <Col span={5}>
              <Dropdown
                overlay={
                  <Menu>
                    {hasIndex(['store.remove_network'], permissions) > -1 && (
                      <Menu.Item key='1' icon={<DeleteOutlined />}>
                        <Popconfirm
                          title='Tem certeza que deseja inativar estas redes?'
                          onConfirm={async () => {
                            setLoading(!loading)

                            for await (let networks of selecteds) {
                              Mixpanel.track('Confirmou inativar redes', networks)
                              await deleteMass(networks.id)
                            }
                            setLoading(!loading)
                            setReload(!reload)
                          }}
                          okText='Sim'
                          cancelText='Não'
                          placement='topRight'
                        >
                          Inativar redes
                        </Popconfirm>
                      </Menu.Item>
                    )}
                  </Menu>
                }
              >
                <Button>
                  Ações em massa ({selecteds.length}) <DownOutlined />
                </Button>
              </Dropdown>
            </Col>
          ),
        },
      }}
      load={{ loading, setLoading }}
      rowSelection={{
        type: 'checkbox',
        ...rowSelection,
      }}
      columns={[
        {
          title: 'Status',
          dataIndex: 'is_active',
          key: 'status',
          width: 80,
          render: (status: any) => {
            return <DataStatus>{getStatus(status)}</DataStatus>
          },
        },
        {
          title: 'Nome',
          dataIndex: 'name',
          sorter: (a: any, b: any) => compareByAlph(a.name, b.name),
        },
        {
          title: '#',
          width: 50,
          render: (obj: any) => {
            if (hasIndex(['store.remove_network', 'store.change_network'], permissions) === -1) return undefined
            return (
              <Dropdown
                overlay={
                  <Menu>
                    {hasIndex(['store.remove_network'], permissions) > -1 && (
                      <Menu.Item key='1' icon={<DeleteOutlined />}>
                        <Popconfirm
                          onConfirm={() => handleDelete(obj.id)}
                          title='Tem certeza que deseja inativar esta rede ?'
                          okText='Sim'
                          cancelText='Não'
                          placement='topRight'
                        >
                          Inativar rede
                        </Popconfirm>
                      </Menu.Item>
                    )}
                    {hasIndex(['store.change_network'], permissions) > -1 && (
                      <Link to={`/backoffice/store/networks/edit/${obj.id}`}>
                        <Menu.Item key='2' icon={<EditOutlined />}>
                          Editar
                        </Menu.Item>
                      </Link>
                    )}
                  </Menu>
                }
              >
                <Button size='small' shape='circle' icon={<MoreOutlined />} />
              </Dropdown>
            )
          },
        },
      ]}
    />
  )
}

export default NetworkListPage
