import { useEffect, useMemo, useRef, useState } from 'react'
import { Row, Col, Spin, Breadcrumb, Modal, Select, message, DatePicker, Pagination } from 'antd'
import { RetweetOutlined, CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons'
import debounce from 'lodash/debounce'
import qs from 'qs'
import { Container, ContainerCards, ContainerPaginationFooter, LabelForm } from './style'
import { NoSearch } from '../../../components/NoSearch'
import { AddButtonWhite } from '../../../components/DefaultPage/style'
import { GroupForm } from '../../../GlobalStyles'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import api_v2 from '../../../services/api-v2'
import SimpleSelectObject from '../../../components/form/SimpleSelectObject'
import api from '../../../services/api'
import { LabelCheck } from '../../Products/ProductCreatePage/styles'
import { Button } from '../../../components/ModalCreateVisit/style'
import { INIT_GLOBAL_RESPONSE } from '../../../utils/global-response'
import CardAfeterBefore from '../../../components/CardAfeterBefore'
import { Loading } from '../../../components/Loading'
import history from '../../../routes/history'
import useReactRouter from 'use-react-router'

function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }: any) {
  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState([])
  const fetchRef = useRef(0)

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: any) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)
      fetchOptions(value).then((newOptions: any) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return
        }

        setOptions(newOptions)
        setFetching(false)
      })
    }

    return debounce(loadOptions, debounceTimeout)
  }, [fetchOptions, debounceTimeout])

  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching && <Spin size='small' />}
      {...props}
      options={options}
      style={{
        height: 'auto',
      }}
    />
  )
}

async function fetchUserList(value: string, path: string) {
  try {
    let { data } = await api_v2.get(`/search/${path}?search=${value}`)
    return data
  } catch (error) {
    console.log(error)
  }
}

export const AfterAndLast = () => {
  const formRef = useRef<FormHandles>(null)
  const { location } = useReactRouter()

  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(true)
  const [pictures, setPictures] = useState<any>(INIT_GLOBAL_RESPONSE)
  const [optionsAfter, setOptionsAfter] = useState<any>([])
  const [optionsBefore, setOptionsBefore] = useState<any>([])
  const [selected, setSelected] = useState<any>({
    form_before_id: null,
    form_before_question_id: null,
    form_after_id: null,
    form_after_question_id: null,
    dt_visit: null,
  })
  const [selectedName, setSelectedName] = useState<any>({
    form_before_name: null,
    form_before_question_name: null,
    form_after_name: null,
    form_after_question_name: null,
  })

  const getForm = async (value: any, type: string) => {
    setLoading(true)
    const { data } = await api.get(`/form/form_id/${value.value}`)
    let options = data.header.map((el: any) => {
      return {
        value: el.key,
        label: el.label,
      }
    })
    if (type === 'after') {
      setSelected({ ...selected, form_after_id: value.value })
      setSelectedName({ ...selectedName, form_after_name: value.label })
      setOptionsAfter(options)
    }

    if (type === 'before') {
      setSelected({ ...selected, form_before_id: value.value })
      setSelectedName({ ...selectedName, form_before_name: value.label })
      setOptionsBefore(options)
    }
    setLoading(false)
  }

  const validationForm = () => {
    if (
      !selected.form_after_id ||
      !selected.form_after_question_id ||
      !selected.form_before_id ||
      !selected.form_before_question_id ||
      !selected.dt_visit
    ) {
      return false
    }
    return true
  }

  async function onChangeDateIniti(value: any, dateString: any) {
    dateString = dateString.replace('/', '').replace('/', '')
    dateString = dateString.replace('-', '').replace('-', '')

    if (dateString === '') {
      setSelected({ ...selected, dt_visit: null })
      return
    }

    let day = dateString.substr(0, 2)
    let month = dateString.substr(2, 2)
    let year = dateString.substr(4, 4)
    let date = year + '-' + month + '-' + day

    setSelected({ ...selected, dt_visit: date })
  }

  const getPictures = async (url?: any) => {
    setLoading(true)
    const { data } = await api_v2.post('/pictures/compare', url ? url.selected : selected)
    history.push(
      `/backoffice/monitory/pictureLastAndAfter?${qs.stringify({
        selected: url ? JSON.stringify(url.selected) : JSON.stringify(selected),
        selectedName: url ? JSON.stringify(url.selectedName) : JSON.stringify(selectedName),
      })}`
    )
    setPictures(data)
    setLoading(false)
  }

  const itemRender = (current: any, type: any, originalElement: any) => {
    if (type === 'prev') {
      return (
        <div style={{ display: 'flex', alignItems: 'center', color: '#6f7782' }}>
          <CaretLeftOutlined style={{ marginRight: 10 }} />
          <p style={{ margin: 0 }}>anterior</p>
        </div>
      )
    }
    if (type === 'next') {
      return (
        <div style={{ display: 'flex', alignItems: 'center', color: '#6f7782' }}>
          <p style={{ margin: 0 }}>próximo</p>
          <CaretRightOutlined style={{ marginRight: 10 }} />
        </div>
      )
    }
    return originalElement
  }

  const handlePage = async (current: any, perpage: any) => {
    try {
      setLoading(true)
      let obj: any = {
        perpage: perpage,
        page: current,
      }

      const query = qs.stringify(obj)
      const { data } = await api_v2.post(`/pictures/compare?${query}`, selected)
      history.push(
        `/backoffice/monitory/pictureLastAndAfter?${qs.stringify({
          selected: JSON.stringify(selected),
          selectedName: JSON.stringify(selectedName),
        })}`
      )
      setPictures(data)
      setLoading(false)

      window.scrollTo(0, 0)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  let convertUrl = () => {
    let getUrl: any = qs.parse(location.search.replaceAll('?', ''))
    if (getUrl && getUrl.selected && getUrl.selectedName) {
      if (getUrl.selected) getUrl.selected = JSON.parse(getUrl.selected)
      if (getUrl.selectedName) getUrl.selectedName = JSON.parse(getUrl.selectedName)
      setSelected(getUrl.selected)
      setSelectedName(getUrl.selectedName)
      console.log(getUrl)
      getPictures(getUrl)
      setOpenModal(false)
    }
  }

  useEffect(() => {
    convertUrl()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <Row style={{ marginBottom: 20 }} justify='space-between'>
        <Col span={6}>
          <Breadcrumb>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>Processamento</Breadcrumb.Item>
            <Breadcrumb.Item>Antes x Depois</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col flex='auto'>
          <Row gutter={[8, 8]} justify='end'>
            <Col>
              <AddButtonWhite onClick={() => setOpenModal(true)}>
                <RetweetOutlined style={{ fontSize: 20 }} />
              </AddButtonWhite>
            </Col>
          </Row>
        </Col>
      </Row>
      {loading ? (
        <Loading text='Carregando aguarde' />
      ) : (
        <ContainerCards>
          {pictures.result && pictures.result.length > 0 ? (
            <Row gutter={[16, 16]}>
              {pictures.result.map((el: any, index: number) => (
                <Col span={24} key={index}>
                  <CardAfeterBefore data={el} formName={selectedName} />
                </Col>
              ))}
            </Row>
          ) : (
            <NoSearch text='Não encontramos nenhum dado aqui.' />
          )}
        </ContainerCards>
      )}
      <Modal
        visible={openModal}
        onCancel={() => setOpenModal(false)}
        closable={true}
        destroyOnClose={true}
        footer={null}
        width={600}
      >
        <Form ref={formRef} className='form' style={{ marginTop: '15px' }} onSubmit={(a) => {}}>
          <h2 style={{ width: '100%', textAlign: 'center' }}>Fotos antes e depois</h2>
          <Row gutter={[30, 30]}>
            <Col span={12}>
              <GroupForm>
                <LabelForm>Selecione o formulário de antes</LabelForm>
                <DebounceSelect
                  showSearch
                  fetchOptions={(text: string) => fetchUserList(text, 'forms')}
                  onChange={(a: any) => getForm(a, 'before')}
                  value={{
                    label: selectedName.form_before_name,
                    value: selected.form_before_id,
                  }}
                  style={{
                    width: '100%',
                  }}
                />
              </GroupForm>
              <GroupForm>
                <LabelForm>Selecione a questão</LabelForm>
                <SimpleSelectObject
                  placeholder='Selecione a questão'
                  name='Questão'
                  options={optionsBefore}
                  isDisabled={selected.form_before_id === null}
                  onChange={(a: any) => {
                    setSelectedName({ ...selectedName, form_before_question_name: a.label })
                    setSelected({ ...selected, form_before_question_id: a.value })
                  }}
                />
              </GroupForm>
            </Col>
            <Col span={12}>
              <GroupForm>
                <LabelForm>Selecione o formulário de depois</LabelForm>
                <DebounceSelect
                  showSearch
                  fetchOptions={(text: string) => fetchUserList(text, 'forms')}
                  onChange={(a: any) => getForm(a, 'after')}
                  value={{
                    label: selectedName.form_after_name,
                    value: selected.form_after_id,
                  }}
                  style={{
                    width: '100%',
                  }}
                />
              </GroupForm>
              <GroupForm>
                <LabelForm>Selecione a questão</LabelForm>
                <SimpleSelectObject
                  placeholder='Selecione a questão'
                  name='Questão'
                  options={optionsAfter}
                  isDisabled={selected.form_after_id === null}
                  onChange={(a: any) => {
                    setSelectedName({ ...selectedName, form_after_question_name: a.label })
                    setSelected({ ...selected, form_after_question_id: a.value })
                  }}
                />
              </GroupForm>
            </Col>
          </Row>
          <LabelCheck>Data da visita</LabelCheck>
          <DatePicker format='DD/MM/YYYY' onChange={onChangeDateIniti} name='dt_visit' style={{ width: '100%' }} />
          <Button
            style={{ width: '100%', height: '55px', marginTop: 20 }}
            onClick={() => {
              let validation = validationForm()
              if (validation === true) {
                setOpenModal(false)
                getPictures()
                return
              }
              message.error('Selecione todos os campos')
            }}
          >
            Visualizar
          </Button>
        </Form>
      </Modal>
      <ContainerPaginationFooter>
        <Pagination current={pictures.page} total={pictures.total} onChange={handlePage} itemRender={itemRender} />
      </ContainerPaginationFooter>
    </Container>
  )
}
