import styled from 'styled-components'

export const Container = styled.div`
  min-width: 180px;
  height: 120px;
  border-radius: 10px;
  border: 1px solid lightgray;
  margin-left: 20px;
  background: linear-gradient(90deg, #6600cc 0%, #b56aff 100%);;

  :hover {
    transition: all 0.3s;
    box-shadow: 2px 2px 5px lightgray;
  }
`

export const Header = styled.div`
  min-width: 180px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size: 25px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 70%;

  p {
    margin: 0;
    margin-right: 10px;
  }
`

export const Body = styled.div`
  border-top: 1px solid #fff;
  height: 30%;

  display:flex;
  align-items: center;
  justify-content: center;
  color: #dfdfdf;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  text-transform: uppercase;
`