import React, { useEffect, useState } from 'react'
import { Container, Title, ContainerRadio, TextError } from './styles'
import { Picture } from '../Picture'
import { Int } from '../Int'
import { Text } from '../Text'
import { MultipleChoise } from '../MultipleChoise/index'
import { Money } from '../Money'
import { Boolean } from '../Boolean'
import { DateForm } from '../DateForm'
import { Radio } from 'antd'

interface IQuestion {
  id: number
  is_required: boolean
  name: string
  order: string
  type: string
  fields?: any[]
  options?: any
}
interface IDataQuestion {
  dataQuestion: IQuestion
  handleAnswers: (value: any, dataQuestion: IQuestion) => void
  error?: boolean
  checkRequired: (value: any) => void
  listError: any
  body: any
}

export const UniqueChoice = ({ dataQuestion, handleAnswers, error, checkRequired, listError, body }: IDataQuestion) => {
  const [checked, setChecked] = useState<number>(9999)
  const [isRender, setIsRender] = useState(false)
  const [subCheck, setSubCheck] = useState<number>(-1)

  const handleValue = (check: any) => {
    setChecked(check)
    handleAnswers([check], dataQuestion)
    if (dataQuestion.is_required) checkRequired(dataQuestion.id)
  }

  const getPosition = () => {
    let position: any

    dataQuestion?.options.forEach((el: any, index: number) => {
      if (el === checked) position = index
    })

    setSubCheck(position)
  }

  useEffect(() => {
    getPosition()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked])

  useEffect(() => {
    if (dataQuestion.is_required && !isRender) {
      setIsRender(true)
      checkRequired(dataQuestion.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <Title>
        {dataQuestion.order} - {dataQuestion.name} {dataQuestion.is_required && '*'}
      </Title>
      <ContainerRadio>
        <Radio.Group value={checked} onChange={(index) => handleValue(index.target.value)}>
          {dataQuestion?.options &&
            dataQuestion?.options.map((el: string) => (
              <Radio key={el} value={el}>
                {el}
              </Radio>
            ))}
        </Radio.Group>
      </ContainerRadio>
      { subCheck >= 0 && dataQuestion.fields ? (
        <>
          {dataQuestion.fields[subCheck]?.subFields &&
            dataQuestion.fields[subCheck]?.subFields.map((question: any, index: number) => (
              <div key={index}>
                {question.type === 'PICTURE_CAPTURE' && (
                  <Picture
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    handleAnswers={handleAnswers}
                    error={listError.indexOf(question.id) > -1}
                    body={body}
                  />
                )}
                {question.type === 'NUMBER_INTEGER' && (
                  <Int
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    handleAnswers={handleAnswers}
                    error={listError.indexOf(question.id) > -1}
                  />
                )}
                {question.type === 'UNIQUE_CHOICE' && (
                  <UniqueChoice
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    handleAnswers={handleAnswers}
                    error={listError.indexOf(question.id) > -1}
                    listError={listError}
                    body={body}
                  />
                )}
                {question.type === 'BOOLEAN' && (
                  <Boolean
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    handleAnswers={handleAnswers}
                    error={listError.indexOf(question.id) > -1}
                    listError={listError}
                    body={body}
                  />
                )}
                {question.type === 'TEXT' && (
                  <Text
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    handleAnswers={handleAnswers}
                    error={listError.indexOf(question.id) > -1}
                  />
                )}
                {question.type === 'MULTIPLE_CHOICE' && (
                  <MultipleChoise
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    handleAnswers={handleAnswers}
                    error={listError.indexOf(question.id) > -1}
                    listError={listError}
                    body={body}
                  />
                )}
                {question.type === 'MONEY' && (
                  <Money
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    handleAnswers={handleAnswers}
                    error={listError.indexOf(question.id) > -1}
                  />
                )}
                {question.type === 'DATE' && (
                  <DateForm
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    handleAnswers={handleAnswers}
                    key={index}
                    error={listError.indexOf(question.id) > -1}
                  />
                )}
              </div>
            ))}
        </>
      ) : null}

      {error && checked === 9999 && <TextError>Campo obrigatório</TextError>}
    </Container>
  )
}
