import {
  DislikeOutlined,
  FileSearchOutlined,
  FilterOutlined,
  CloseOutlined,
  ClearOutlined,
  LikeOutlined,
  ExclamationCircleOutlined,
  CloudDownloadOutlined,
} from '@ant-design/icons'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { Breadcrumb, Col, Row, Table, Tag, Tooltip, Modal, Button, Image as ImageAntd, message } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import qs from 'querystring'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import history from '../../../routes/history'
import api from '../../../services/api'
import { Loading } from '../../../components/Loading'
import { IGlobalResponse, INIT_GLOBAL_RESPONSE } from '../../../utils/global-response'
import {
  BigButton,
  ContainerButtonsFootter,
  ContainerFormFilter,
  ContainerList,
  GroupForm,
} from '../../../GlobalStyles'
import { ContainerTagg } from '../../Survey/FormListPage/styles'
import { ButtonClear, ButtonSearch, LabelForm } from '../../Team/Promoter/style'
import useReactRouter from 'use-react-router'
import { NoSearch } from '../../../components/NoSearch'
import { DrawerFilter } from '../../../components/DrawerFilter'
import { Search } from '../../../components/form/SimpleSearch'
import SimpletSelect from '../../../components/form/SimpleSelect2'
import SimpleRangePicker from '../../../components/form/SimpleRangePicker'
import moment from 'moment'
import api_v2 from '../../../services/api-v2'
import { exportXls } from '../../../utils/funcoes'
import { ModalReproveJustify } from '../../../components/ModalReproveJustify'
const { confirm } = Modal

interface CheckInUser {
  id: string
  name: string
  email: string
  cpf: string
  type: string
  is_active: boolean
}

interface CheckInList extends IGlobalResponse {
  result: CheckInUser[]
}

const VisitsJustify: React.FC = () => {
  const { location } = useReactRouter()

  const [userCheck, setUserCheck] = useState<CheckInList>(INIT_GLOBAL_RESPONSE)
  const [visible, setVisible] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [reproveModal, setReproveModal] = useState<any>({
    visible: false,
    row: {}
  })
  const [filterTag, setFilterTag] = useState<any>({})
  const [reasonOption, setReasonOption] = useState<any>([])
  const [urlFilter, setUrlFilter] = useState<string>('')
  const formRef = useRef<FormHandles>(null)

  const handleTable = async (queryParam: any) => {
    setUserCheck({ ...userCheck })

    let newObj = {
      perpage: queryParam.pageSize,
      page: queryParam.current,
    }
    const query = qs.stringify(newObj)
    const { data } = await api_v2.get(`/visit/justifies?${query}${urlFilter ? '&' + urlFilter : ''}`)
    setUserCheck({ ...data, loading: false })
  }

  async function searchUser(body: any) {
    setLoading(true)
    let { promoter_id, pdv_id, flag_id, status, dt_visit, date, network_id, reason, profile_id,sub_workspace } = body
    let filterQuery: any = {}
    let filterQueryUrl: any = {}
    let dataTag = {}
    if (promoter_id && promoter_id.value) {
      filterQuery.promoter_id = promoter_id.value
      dataTag = { ...dataTag, promoter_id }
      filterQueryUrl = { ...filterQueryUrl, promoter_id: JSON.stringify(promoter_id) }
    }
    if (pdv_id && pdv_id.value) {
      filterQuery.pdv_id = pdv_id.value
      dataTag = { ...dataTag, pdv_id }
      filterQueryUrl = { ...filterQueryUrl, pdv_id: JSON.stringify(pdv_id) }
    }

    if (profile_id && profile_id.value) {
      filterQuery.profile_id = profile_id.value
      dataTag = { ...dataTag, profile_id }
      filterQueryUrl = { ...filterQueryUrl, profile_id: JSON.stringify(profile_id) }
    }

    if (flag_id && flag_id.value) {
      filterQuery.flag_id = flag_id.value
      dataTag = { ...dataTag, flag_id }
      filterQueryUrl = { ...filterQueryUrl, flag_id: JSON.stringify(flag_id) }
    }

    if (network_id && network_id.value) {
      filterQuery.network_id = network_id.value
      dataTag = { ...dataTag, network_id }
      filterQueryUrl = { ...filterQueryUrl, network_id: JSON.stringify(network_id) }
    }

    if (reason && reason.value) {
      filterQuery.reason = reason.value
      dataTag = { ...dataTag, reason }
      filterQueryUrl = { ...filterQueryUrl, reason: JSON.stringify(reason) }
    }

    if (dt_visit) {
      filterQuery.dt_visit = dt_visit.trim()
      dataTag = { ...dataTag, dt_visit }
      filterQueryUrl = { ...filterQueryUrl, dt_visit }
    }

    if (status) {
      filterQuery.status = status
      dataTag = { ...dataTag, status }
      filterQueryUrl = { ...filterQueryUrl, status }
    }
    if (date) {
      filterQueryUrl = { ...filterQueryUrl, date: JSON.stringify(date) }
      if (date[0]) {
        filterQuery.start_date = date[0]
        dataTag = { ...dataTag, start_date: date[0] }
      }

      if (date[1]) {
        filterQuery.end_date = date[1]
        dataTag = { ...dataTag, end_date: date[1] }
      }
    } else {
      filterQuery = {
        ...filterQuery,
        end_date: moment().format('YYYY-MM-DD'),
        start_date: moment().format('YYYY-MM-DD'),
      }
      dataTag = { ...dataTag, end_date: moment().format('YYYY-MM-DD'), start_date: moment().format('YYYY-MM-DD') }
    }
    if (sub_workspace && sub_workspace.value) {
      filterQuery.sub_workspace = sub_workspace.value
      dataTag = { ...dataTag, sub_workspace }
      filterQueryUrl = { ...filterQueryUrl, sub_workspace: JSON.stringify(sub_workspace) }
    }
    const queryFilter = qs.stringify({ ...filterQuery })
    const queryFilterUrl = qs.stringify({ ...filterQueryUrl })
    setUrlFilter(queryFilter)
    const { data } = await api_v2.get(`/visit/justifies?${queryFilter}`)
    setUserCheck({ ...data, loading: false })
    setLoading(false)
    setFilterTag(dataTag)
    setVisible(false)
    history.push(`/backoffice/reports/justifyVisits?${queryFilterUrl}`)
  }

  const removeFilter = async (typeRemove: string, value: string) => {
    let newUrl: any = qs.parse(urlFilter)
    let newObjTagg = filterTag
    if (typeRemove === 'promoter_id') {
      delete newObjTagg.promoter_id
      delete newUrl.promoter_id
    }

    if (typeRemove === 'reason') {
      delete newObjTagg.reason
      delete newUrl.reason
    }

    if (typeRemove === 'pdv_id') {
      delete newObjTagg.pdv_id
      delete newUrl.pdv_id
    }

    if (typeRemove === 'profile_id') {
      delete newObjTagg.profile_id
      delete newUrl.profile_id
    }

    if (typeRemove === 'flag_id') {
      delete newObjTagg.flag_id
      delete newUrl.flag_id
    }

    if (typeRemove === 'network_id') {
      delete newObjTagg.network_id
      delete newUrl.network_id
    }

    if (typeRemove === 'dt_visit') {
      delete newObjTagg.dt_visit
      delete newUrl.dt_visit
    }
    if (typeRemove === 'start_date') {
      delete newObjTagg.start_date
      delete newUrl.start_date
    }
    if (typeRemove === 'end_date') {
      delete newObjTagg.end_date
      delete newUrl.end_date
    }
    if (typeRemove === 'sub_workspace') {
      delete newObjTagg.sub_workspace
      delete newUrl.sub_workspace
    }
    

    const queryFilter = qs.stringify({ ...newUrl })

    setLoading(true)
    const { data } = await api_v2.get(`/visit/justifies?${queryFilter}`)
    setUserCheck({ ...data, loading: false })
    setUrlFilter(queryFilter)
    setFilterTag(newObjTagg)
    setLoading(false)
    history.push(`/backoffice/reports/justifyVisits?${newUrl}`)
  }

  function compareByAlph(a: any, z: any) {
    if (a > z) {
      return -1
    }
    if (a < z) {
      return 1
    }
    return 0
  }

  function showAproveConfirm(row: any) {''
    confirm({
      title: 'Aprovação',
      icon: <ExclamationCircleOutlined />,
      content: 'Você deseja aprovar a justificativa',
      okText: 'Aprovar',
      cancelText: 'Cancelar',
      async onOk() {
        try {
          const {data} = await api_v2.post(`/justify/justifie-response/${row.id}`, {faults_response: 'approve'})
          if(!data) return message.error('Erro ao aprovar justificativa')
          message.success('Justificativa aprovada')
          searchUser(convertUrl())
        } catch (error) {
          message.error('Erro ao aprovar justificativa')
        }
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  const columns: ColumnsType<CheckInUser> = [
    {
      title: 'Nome',
      dataIndex: 'promoter',
      sorter: (a: any, b: any) => compareByAlph(a.promoter, b.promoter),
      ellipsis: true,
      width: 250,
    },
    {
      title: 'PDV',
      dataIndex: 'pdv',
      sorter: (a: any, b: any) => compareByAlph(a.pdv, b.pdv),
      ellipsis: true,
    },
    {
      title: 'Data Visita',
      dataIndex: 'dt_visit',
      ellipsis: true,
      width: 90,
    },
    {
      title: 'Justificativa',
      dataIndex: 'justify',
      ellipsis: true,
    },
    {
      title: 'Obs',
      dataIndex: 'obs',
      ellipsis: true,
    },
    {
      title: 'Fotos',
      dataIndex: 'faults_image',
      ellipsis: true,
      render: (a: any, b: any) => (
        <>
          {a && JSON.parse(a).length > 0 && (
            <ImageAntd.PreviewGroup>
              {JSON.parse(a).map((d: any, index: number) => (
                <ImageAntd src={d} key={index} style={{maxWidth: 80, maxHeight: 80}}/>
              ))}
            </ImageAntd.PreviewGroup>
          )}
        </>
      ),
    },
    {
      title: '#',
      width: 50,
      align: 'center',
      render: (obj: any, row: any) => {
        if(row.faults_date === null) {
        return (
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: 10, cursor: 'pointer' }} onClick={() => showAproveConfirm(row)}>
              <LikeOutlined style={{ fontSize: 17, color: '#00C49F' }} />
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => setReproveModal({visible: true, row})}>
              <DislikeOutlined style={{ fontSize: 17, color: '#FF8042' }} />
            </div>
          </div>
        )
        } else {
          return (
          <Tooltip title={row.faults_response === 'approve' ? 'Aprovado' : `Reprovado: ${row.faults_denied_observation}`}>
            <div style={{width: 10, height: 10, borderRadius: 5, backgroundColor: row.faults_response === 'approve' ? '#00C49F' : '#FF8042' }}/>
          </Tooltip>
          )
        }
      },
    },
  ]

  const convertUrl = () => {
    let obj: any = qs.parse(location.search.replaceAll('?', ''))
    if (obj && obj.promoter_id) obj.promoter_id = JSON.parse(obj.promoter_id)
    if (obj && obj.pdv_id) obj.pdv_id = JSON.parse(obj.pdv_id)
    if (obj && obj.profile_id) obj.profile_id = JSON.parse(obj.profile_id)
    if (obj && obj.flag_id) obj.flag_id = JSON.parse(obj.flag_id)
    if (obj && obj.network_id) obj.network_id = JSON.parse(obj.network_id)
    if (obj && obj.reason) obj.reason = JSON.parse(obj.reason)
    if (obj && obj.start_date && obj.end_date) obj.date = [obj.start_date, obj.end_date]
    if (obj && obj.date) obj.date = JSON.parse(obj.date)
    if (obj && obj.sub_workspace) obj.sub_workspace = JSON.parse(obj.sub_workspace)
    if (!obj || !obj.date)
      obj.date = [moment().startOf('week').format('YYYY-MM-DD'), moment().endOf('week').format('YYYY-MM-DD')]

    return obj
  }

  const getReasons = async () => {
    const { data } = await api.get(`/faults_options?perpage=100`)
    let options = data.result.map((el: any) => {
      return {
        value: el.id,
        label: el.description,
      }
    })
    setReasonOption(options)
  }

  useEffect(() => {
    searchUser(convertUrl())
    getReasons()
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  const cleanFilter = async () => {
    history.push(`/backoffice/reports/justifyVisits`)
    setUserCheck({ ...userCheck, loading: true })
    setVisible(false)
    setUrlFilter('')
    setFilterTag({})
    formRef!.current!.reset()
    const { data } = await api.get(`/user`)
    setUserCheck({ ...data, loading: false })
  }


  return (
    <ContainerList>
      {loading ? <Loading /> : null}
      <Row gutter={[8, 8]} justify='space-between'>
        <Col span={20}>
          <h1>Visitas justificada</h1>
          <Breadcrumb separator='>' className='breadcumb'>
            <Link to='/backoffice/reports'>
              <Breadcrumb.Item> Relatorios </Breadcrumb.Item>
            </Link>
            <Breadcrumb.Item> Visitas justificada </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col>
          <Tooltip placement='top' title={'Filtrar'}>
            <BigButton onClick={() => setVisible(!visible)} style={{ padding: 9 }}>
              <FilterOutlined />
            </BigButton>
          </Tooltip>
        </Col>
        <Col>
          <Button
            type='primary'
            icon={<CloudDownloadOutlined />}
            onClick={async () => {
              setLoading(true)
              await exportXls({ ...qs.parse(urlFilter), status: ['JUSTIFIED_ABSENCE'] }, 'visits')
              setLoading(false)
            }}
          >
            Exportar
          </Button>
        </Col>
        <Col span={24}>
          <ContainerTagg>
            {filterTag?.promoter_id && (
              <Tag color='geekblue' onClick={() => removeFilter('promoter_id', `${filterTag?.promoter_id.value}`)}>
                {filterTag?.promoter_id.label}
                <CloseOutlined />
              </Tag>
            )}

            {filterTag.pdv_id && (
              <Tag color='geekblue' onClick={() => removeFilter('pdv_id', `${filterTag.pdv_id.value}`)}>
                {filterTag.pdv_id.label}
                <CloseOutlined />
              </Tag>
            )}

            {filterTag.profile_id && (
              <Tag color='geekblue' onClick={() => removeFilter('profile_id', `${filterTag.profile_id.value}`)}>
                {filterTag.profile_id.label}
                <CloseOutlined />
              </Tag>
            )}

            {filterTag.dt_visit && (
              <Tag color='geekblue' onClick={() => removeFilter('dt_visit', `${filterTag.dt_visit}`)}>
                {filterTag.dt_visit}
                <CloseOutlined />
              </Tag>
            )}
            {filterTag.flag_id && (
              <Tag color='geekblue' onClick={() => removeFilter('flag_id', `${filterTag.flag_id.value}`)}>
                {filterTag.flag_id.label}
                <CloseOutlined />
              </Tag>
            )}
            {filterTag.reason && (
              <Tag color='geekblue' onClick={() => removeFilter('reason', `${filterTag.reason.value}`)}>
                {filterTag.reason.label}
                <CloseOutlined />
              </Tag>
            )}
            {filterTag.network_id && (
              <Tag color='geekblue' onClick={() => removeFilter('network_id', `${filterTag.network_id.value}`)}>
                {filterTag.network_id.label}
                <CloseOutlined />
              </Tag>
            )}
            {filterTag.start_date && (
              <Tag color='geekblue' onClick={() => removeFilter('start_date', `${filterTag.start_date}`)}>
                {moment(filterTag.start_date).format('DD/MM/YYYY')}
                <CloseOutlined />
              </Tag>
            )}
            {filterTag.end_date && (
              <Tag color='geekblue' onClick={() => removeFilter('end_date', `${filterTag.end_date}`)}>
                {moment(filterTag.end_date).format('DD/MM/YYYY')}
                <CloseOutlined />
              </Tag>
            )}
              {filterTag.sub_workspace && (
              <Tag color='geekblue' onClick={() => removeFilter('sub_workspace', `${filterTag.sub_workspace.value}`)}>
                {filterTag.sub_workspace.label}
                <CloseOutlined />
              </Tag>
            )}
          </ContainerTagg>
        </Col>
      </Row>
      {loading ? (
        <Loading />
      ) : (
        <>
          {userCheck.result && userCheck.result.length > 0 ? (
            <Table
              columns={columns}
              rowKey={(user) => user.id}
              dataSource={userCheck.result}
              size='middle'
              pagination={{
                current: userCheck.page,
                pageSize: userCheck.perpage,
                total: userCheck.total,
                size: 'small',
                showSizeChanger: true,
                showTotal: () => `Exibindo ${userCheck.result.length} de ${userCheck.total} de registros`,
              }}
              loading={userCheck.loading}
              onChange={handleTable}
            />
          ) : (
            <NoSearch text='Não encontramos nenhum dado aqui.' />
          )}
        </>
      )}

      <DrawerFilter
        visible={visible}
        close={setVisible}
        title='Visitas justificadas'
        footer={
          <ContainerButtonsFootter>
            <ButtonSearch type='button' onClick={() => formRef?.current?.submitForm()}>
              <FileSearchOutlined />
              Pesquisar
            </ButtonSearch>
            <ButtonClear onClick={() => cleanFilter()}>
              <ClearOutlined />
              Limpar
            </ButtonClear>
          </ContainerButtonsFootter>
        }
      >
        <ContainerFormFilter
          tabIndex={0}
          onKeyDown={(evt: any) => {
            if (evt.key === 'Enter') formRef?.current?.submitForm()
          }}
        >
          <Form ref={formRef} className='form' style={{ marginTop: '15px' }} onSubmit={searchUser}>
            <GroupForm>
              <LabelForm>Selecione promotor</LabelForm>
              <Search placeholder='Selecione o promotor' name='promoter_id' path='promoters' isMult={false} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione a rede</LabelForm>
              <Search placeholder='Selecione a rede' name='network_id' path='networks' isMult={false} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione a Bandeira</LabelForm>
              <Search placeholder='Selecione a bandeira' name='flag_id' path='flags' isMult={false} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione o PDV</LabelForm>
              <Search placeholder='Selecione o PDV' name='pdv_id' path='pdvs' isMult={false} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione o perfil</LabelForm>
              <Search placeholder='Selecione o perfil' name='profile_id' path='profiles' isMult={false} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione o motivo</LabelForm>
              <SimpletSelect
                placeholder='Selecione o motivo'
                name='reason'
                path='pdvs'
                isMult={false}
                options={reasonOption}
              />
            </GroupForm>
            <SimpleRangePicker name='date' label='Selecione o periodo:' />
            <GroupForm>
            <LabelForm>Selecione a empresa</LabelForm>
            <Search placeholder='Selecione a empresa' name='sub_workspace' path='sub-workspaces' isMult={false} />           
            </GroupForm>
          </Form>
        </ContainerFormFilter>
      </DrawerFilter>
      <ModalReproveJustify close={() => setReproveModal({visible: false, row: {}})} visible={reproveModal.visible} row={reproveModal.row} reload={() => searchUser(convertUrl())}/>
    </ContainerList>
  )
}

export default VisitsJustify
