import { Tooltip } from 'antd'
import React from 'react'
import { AiFillCheckCircle, AiFillFile, AiOutlineClose } from 'react-icons/ai'
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md'
import { ContextUpload } from '../../context/contextUpload'
import { Body, Container, Header, Item } from './styles'

export const CardUpload = () => {
  const { visible, close, minimizeTab, minimize } = ContextUpload()

  return (
    <>
      {visible && (
        <Container>
          <Header>
            Uploads{' '}
            <div>
              {minimize ? (
                <MdOutlineKeyboardArrowDown onClick={() => minimizeTab(!minimize)} style={{ fontSize: 25 }} />
              ) : (
                <MdOutlineKeyboardArrowUp onClick={() => minimizeTab(!minimize)} style={{ fontSize: 25 }} />
              )}
              <AiOutlineClose onClick={() => close()} />
            </div>
          </Header>
          {minimize && (
            <Body>
              <Item>
                <Tooltip placement='top' title={'MODELO_PROMOTOR (1).xlsx'}>
                  <div className='flex'>
                    <AiFillFile style={{ fontSize: 20, color: '#6e55cc' }} />
                    <p>MODELO_PROMOTOR (1).xlsx</p>
                  </div>
                </Tooltip>
                <AiFillCheckCircle style={{ fontSize: 20, color: '#34a853' }} />
              </Item>
            </Body>
          )}
        </Container>
      )}
    </>
  )
}
