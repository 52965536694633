import React, { useEffect } from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4maps from '@amcharts/amcharts4/maps'

interface DataMap {
  id: string
  value: number
  label: string
}

interface Props {
  valueMap?: DataMap[]
}
function MapDash({ valueMap }: Props) {
  useEffect(() => {
    const mapG = am4core.create('graph-map', am4maps.MapChart)

    mapG.geodataSource.url = 'https://www.amcharts.com/lib/4/geodata/json/brazilLow.json'
    mapG.geodataSource.events.on('parseended', (ev: any) => {
      const data: any = valueMap

      const geo_br: any = {
        AC: [-8.77, -70.55],
        AL: [-9.71, -35.73],
        AM: [-3.07, -61.66],
        AP: [1.41, -51.77],
        BA: [-12.96, -41.0],
        CE: [-3.71, -38.54],
        DF: [-15.83, -47.86],
        ES: [-19.19, -40.34],
        GO: [-16.64, -49.31],
        MA: [-2.55, -44.3],
        MT: [-12.64, -55.42],
        MS: [-20.51, -54.54],
        MG: [-18.1, -44.38],
        PA: [-5.53, -52.29],
        PB: [-7.06, -35.55],
        PR: [-24.89, -51.55],
        PE: [-8.28, -35.07],
        PI: [-8.28, -43.68],
        RJ: [-22.84, -43.15],
        RN: [-5.22, -36.52],
        RO: [-11.22, -62.8],
        RS: [-30.01, -51.22],
        RR: [1.89, -61.22],
        SC: [-27.33, -49.44],
        SE: [-10.9, -37.07],
        SP: [-23.55, -46.64],
        TO: [-10.25, -48.25],
      }

      for (const feature of ev.target.data.features) {
        // let datg = randonValue(1)[0]

        // console.log({
        //   id: feature.id,
        //   value: datg,
        //   label: feature.id,
        // })
        // if (datg !== 0) {
        //   data.push({
        //     id: feature.id,
        //     value: datg,
        //     label: feature.id,
        //   })
        // } else {
        //   data.push({
        //     id: feature.id,
        //     value: 0,
        //     label: feature.id,
        //   })
        // }

        const label: any = labelSeries.mapImages.create()
        const state = feature.id.split('-').pop()
        label.children.getIndex(0).text = state
        label.latitude = geo_br[state][0]
        label.longitude = geo_br[state][1]
      }

      polygonTemplate.propertyFields.fill = 'fill'
      polygonSeries.data = data
    })

    mapG.projection = new am4maps.projections.Mercator()
    mapG.seriesContainer.draggable = false
    mapG.seriesContainer.resizable = false
    mapG.maxZoomLevel = 1
    mapG.chartContainer.wheelable = false

    // Create map polygon series
    const polygonSeries = mapG.series.push(new am4maps.MapPolygonSeries() as any)
    polygonSeries.heatRules.push({
      property: 'fill',
      target: polygonSeries.mapPolygons.template,
      min: am4core.color('#b46aff'),
      max: am4core.color('#6600cd'),
    })

    polygonSeries.useGeodata = true
    // polygonSeries.calculateVisualCenter = true

    const labelSeries = mapG.series.push(new am4maps.MapImageSeries())
    const labelTemplate = labelSeries.mapImages.template.createChild(am4core.Label)
    labelTemplate.horizontalCenter = 'middle'
    labelTemplate.verticalCenter = 'middle'
    labelTemplate.fontSize = 10
    labelTemplate.nonScaling = true
    labelTemplate.interactionsEnabled = false

    // Set up heat legend
    const heatLegend = mapG.createChild(am4maps.HeatLegend)
    heatLegend.series = polygonSeries
    heatLegend.align = 'right'
    heatLegend.width = am4core.percent(25)
    heatLegend.marginRight = am4core.percent(4)
    heatLegend.minValue = 0
    heatLegend.maxValue = 100
    heatLegend.valign = 'bottom'

    // Set up custom heat map legend labels using axis ranges
    const minRange = heatLegend.valueAxis.axisRanges.create()
    minRange.value = heatLegend.minValue
    minRange.label.text = '0%'

    const maxRange = heatLegend.valueAxis.axisRanges.create()
    maxRange.value = heatLegend.maxValue
    maxRange.label.text = '100%'

    // Blank out internal heat legend value axis labels
    heatLegend.valueAxis.renderer.labels.template.adapter.add('text', () => '')

    // Configure series tooltip
    const polygonTemplate = polygonSeries.mapPolygons.template
    polygonTemplate.tooltipText = '{name}:{value}%'
    polygonTemplate.nonScalingStroke = true
    polygonTemplate.strokeWidth = 0.5

    polygonTemplate.events.on('hit', async (ev: any) => {})
  }, [valueMap])

  return <div id='graph-map' style={{ width: '100%', height: '465px' }} />
}

export default MapDash
