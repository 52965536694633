import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(
    to bottom left,
    #B670FC,
    #6D55CC
  );
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Header = styled.div`
  width: 100%;
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 40px;
    margin-top: 22px;
  }

  svg {
    position: fixed;
    left: 30px;
    color: white;
    font-size: 24px;
    cursor: pointer;
  }
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const FormContent = styled.div`
  background: white;
  min-height: 80px;
  border-radius: 8px;
  margin-top: 40px;
  width: 100%;
`

export const FormGroup = styled.div`
  display: flex;
  height: 55px;
  background-color: #f7f7f7;
  border-radius: 5px;
  margin-bottom: 20px;

  input {
    width: 80%;
    border: none;
    font-size: 16px;
    background-color: #f7f7f7;

    &[disabled] {
      cursor: not-allowed;
      background: #fff;
    }
  }
`

export const Icon = styled.div`
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;

  svg {
    color: #6600cc;
  }
`

export const ResetLink = styled.a`
  color: #6f7782;
  font-family: "Open Sans";
  font-size: 16px;
  margin-top: 10px;
`

export const Button = styled.button`
  background: #6600cc;
  color: #fff;
  border-radius: 5px;
  height: 50px;
  border: none;
  width: 100%;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 20px;
  font-weight: bold;
  transition: 0.4 linear;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 150ms;

  :hover {
    opacity: 0.9;
  }

  &[disabled] {
    opacity: 0.9;
    cursor: not-allowed;
  }
`
export const BoxSignIn = styled.div`
  background-color: #fff;
  padding: 3vw;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 5vh;
  form{
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  img{
    width: 100%;
    max-width: 280px;
  }

  form{
    width: 100%;
  }
`

export const Title = styled.p`
  border-top: 2px solid #AEACBF;
  font-size: 25px;
  color: #000;
  font-weight: bold;
  padding-top: 15px;
  margin: 20px 0;
  text-align: center;
  width: 100%;
`

export const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  p{
    color: #ffffff;
    font-family: "Open Sans";
    font-size: 16px;
  }

  img{
    width: 120px;
  }
`