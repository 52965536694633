import React, { useState } from 'react'
import {
  Container,
  ContainerDah,
  ContainerDahDonults,
  ContainerFooter,
  ContainerHeader,
  ContainerOptions,
  ContainerValues,
  ContainerValuesNoTag,
} from './style'
import { RightOutlined } from '@ant-design/icons'
import { BiTag } from 'react-icons/bi'
import ReactApexChart from 'react-apexcharts'
import { AiOutlineArrowUp, AiOutlineArrowDown } from 'react-icons/ai'
import { Tooltip } from 'antd'
import history from '../../routes/history'
import qs from 'querystring'
import moment from 'moment'
import { IDS } from '../../utils/removeUser'
import { getDataUser } from '../../utils/login'

interface PropsCard {
  title: string
  type: string
  children?: any
  valueIndicator?: {
    PERCENT: number
    JUSTIFIED_ABSENCE: number | null
    PENDENT: number
    COMPLETE: number
    TOTAL: number
    IN_PROGRESS?: number | null
  }
  valueDonults?: {
    onlline: number
    offline: number
  }
  tagActive?: boolean
  chartIsActive?: boolean
  off?: boolean
  titleTollTipo: string
  route?: string
  dateToday?: any
  profile_id: any
  state: any
  sub_workspaces: any
}

export const CardChart = ({
  title,
  type,
  valueIndicator,
  valueDonults,
  tagActive,
  chartIsActive,
  off,
  titleTollTipo,
  route,
  children,
  dateToday,
  profile_id,
  state,
  sub_workspaces,
}: PropsCard) => {
  const objPendent: any = {
    status: JSON.stringify({
      value: 'PENDENT',
      label: 'Pendente',
    }),
  }

  const objComplete: any = {
    status: JSON.stringify({
      value: 'COMPLETE',
      label: 'Concluída',
    }),
  }

  const user = getDataUser()
  return (
    <Container>
      <ContainerHeader>
        <h3>{title}</h3>
        <ContainerOptions>
          <Tooltip placement='top' title={titleTollTipo}>
            <RightOutlined
              onClick={() => {
                console.log(dateToday)

                if(!dateToday) return  history.push(
                  IDS.indexOf(user.id) > -1
                    ? ''
                    : route)

                history.push(
                  IDS.indexOf(user.id) > -1
                    ? ''
                    : route
                    ? `${route}?${qs.stringify({
                      date: dateToday,
                    })}${profile_id ? '&' + qs.stringify({ profile_id }) : ''}${
                        state ? '&' + qs.stringify({ state }) : ''
                      }${
                        sub_workspaces ? '&' + qs.stringify({ sub_workspace: sub_workspaces }) : ''
                      }`
                    : ''
                )

              }}
            />
          </Tooltip>
        </ContainerOptions>
      </ContainerHeader>
      {type === 'indicator' && valueIndicator && (
        <>
          <ContainerDah>
            <BiTag color='#b369fe' />
            <h3>{valueIndicator.PERCENT}%</h3>
            {tagActive && <p>Concluídas</p>}
          </ContainerDah>
          <ContainerFooter>
            {tagActive ? (
              <>
                {valueIndicator.JUSTIFIED_ABSENCE != null && (
                  <div
                    onClick={() =>
                      history.push(
                        IDS.indexOf(user.id) > -1
                          ? ''
                          : `${route}${title === 'Tarefas do dia' ? '&' : '?'}status=JUSTIFIED_ABSENCE&${qs.stringify({
                              date: dateToday,
                            })}${profile_id ? '&' + qs.stringify({ profile_id }) : ''}${
                              state ? '&' + qs.stringify({ state }) : ''
                            }${
                              sub_workspaces ? '&' + qs.stringify({ sub_workspace: sub_workspaces }) : ''
                            }`
                      )
                    }
                    style={{ cursor: 'pointer' }}
                  >
                    <div>
                      <BiTag color='rgb(72 222 229)' />
                      <p>{valueIndicator.JUSTIFIED_ABSENCE}</p>
                    </div>
                    <p>Justificada</p>
                  </div>
                )}
                {valueIndicator.IN_PROGRESS != null && (
                  <div
                    onClick={() =>
                      history.push(
                        IDS.indexOf(user.id) > -1
                          ? ''
                          : `${route}${title === 'Tarefas do dia' ? '&' : '?'}status=IN_PROGRESS&${qs.stringify({
                              date: dateToday,
                            })}${profile_id ? '&' + qs.stringify({ profile_id }) : ''}${
                              state ? '&' + qs.stringify({ state }) : ''
                            }${
                              sub_workspaces ? '&' + qs.stringify({ sub_workspace: sub_workspaces }) : ''
                            }`
                      )
                    }
                    style={{ cursor: 'pointer' }}
                  >
                    <div>
                      <BiTag color='#FFBB28' />
                      <p>{valueIndicator.IN_PROGRESS}</p>
                    </div>
                    <p>Em progresso</p>
                  </div>
                )}
                <div
                  onClick={() =>{
                    console.log(dateToday, title, objPendent)
                    history.push(
                      IDS.indexOf(user.id) > -1
                        ? ''
                        : `${route}${title === 'Tarefas do dia' ? '&' : '?'}${
                            title === 'Tarefas do dia' ? qs.stringify(objPendent) : 'status=PENDENT'
                          }&${title !== 'Tarefas do dia' ? qs.stringify({
                            date: dateToday,
                          }) : ''}${profile_id ? '&' + qs.stringify({ profile_id }) : ''}${
                            state ? '&' + qs.stringify({ state }) : ''
                          }${
                            sub_workspaces ? '&' + qs.stringify({ sub_workspace: sub_workspaces }) : ''
                          }`

                    )
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <div>
                    <BiTag color='#000' />
                    <p>{valueIndicator.PENDENT}</p>
                  </div>
                  <p>Pendente</p>
                </div>
                <div
                  onClick={() =>
                    history.push(
                      IDS.indexOf(user.id) > -1
                        ? ''
                        : `${route}${title === 'Tarefas do dia' ? '&' : '?'}${
                            title === 'Tarefas do dia' ? qs.stringify(objComplete) : 'status=COMPLETE'
                          }&$${title !== 'Tarefas do dia' ? qs.stringify({
                            date: dateToday,
                          }) : ''}${profile_id ? '&' + qs.stringify({ profile_id }) : ''}${
                            state ? '&' + qs.stringify({ state }) : ''
                          }${
                            sub_workspaces ? '&' + qs.stringify({ sub_workspace: sub_workspaces }) : ''
                          }`
                    )
                  }
                  style={{ cursor: 'pointer' }}
                >
                  <div>
                    <BiTag color='rgb(94 173 139)' />
                    <p>{valueIndicator.COMPLETE}</p>
                  </div>
                  <p>Concluídas</p>
                </div>
                <div
                  onClick={() => history.push(IDS.indexOf(user.id) > -1 ? '' : `${route}`)}
                  style={{ cursor: 'pointer' }}
                >
                  <div>
                    <BiTag color='#b94fa5' />
                    <p>{valueIndicator.TOTAL}</p>
                  </div>
                  <p>Total</p>
                </div>
              </>
            ) : (
              <>
                {valueIndicator.JUSTIFIED_ABSENCE != null && (
                  <ContainerValuesNoTag>
                    <p>
                      <b style={{ color: 'rgb(72 222 229)' }}>{valueIndicator.JUSTIFIED_ABSENCE} </b> Justificativa
                    </p>
                  </ContainerValuesNoTag>
                )}
                <ContainerValuesNoTag>
                  <p>
                    <b style={{ color: '#000' }}>{valueIndicator.PENDENT} </b> Pendente
                  </p>
                </ContainerValuesNoTag>
                <ContainerValuesNoTag>
                  <p>
                    <b style={{ color: 'rgb(94 173 139)' }}>{valueIndicator.COMPLETE} </b> Concluída
                  </p>
                </ContainerValuesNoTag>
                <ContainerValuesNoTag>
                  <p>
                    <b style={{ color: '#b94fa5' }}>{valueIndicator.TOTAL} </b> Total
                  </p>
                </ContainerValuesNoTag>
              </>
            )}
          </ContainerFooter>
        </>
      )}
      {type === 'donults' && valueDonults && (
        <ContainerDahDonults
          style={{ height: off ? '100%' : 'auto', justifyContent: off ? 'center' : 'space-between' }}
        >
          {off ? (
            <ContainerValues style={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
              <h3 style={{ marginTop: 50 }}>{valueDonults.offline}</h3>
            </ContainerValues>
          ) : (
            <>
              {chartIsActive && (
                <ReactApexChart
                  options={{
                    labels: ['Online'],
                    colors: ['#6702cf'],
                    plotOptions: {
                      radialBar: {
                        hollow: {
                          size: '65%',
                        },
                      },
                    },
                  }}
                  series={[Math.round((valueDonults.onlline * 100) / (valueDonults.offline + valueDonults.onlline))]}
                  type='radialBar'
                  height={150}
                  width={150}
                  style={{ width: '45%' }}
                />
              )}

              <div style={{ display: 'flex', width: '45%', justifyContent: 'space-around' }}>
                <ContainerValues>
                  <div>
                    <AiOutlineArrowUp color='#1ac165' fontSize={35} />
                    <h3>{valueDonults.onlline}</h3>
                  </div>
                  <p>Online</p>
                </ContainerValues>
                <ContainerValues>
                  <div>
                    <AiOutlineArrowDown color='#af2f4e' fontSize={35} />
                    <h3>{valueDonults.offline}</h3>
                  </div>
                  <p>Offline</p>
                </ContainerValues>
              </div>
            </>
          )}
          {children && children}
        </ContainerDahDonults>
      )}
    </Container>
  )
}
