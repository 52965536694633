import { Col, Image, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { LoadingSinglePicture } from '../LoadingSinglePicture'
import { Container, PersonDivider, TextH3, TextH4 } from './style'

interface Props {
  data: any
  formName: PropsName
}

interface PropsName {
  form_before_name: null | string
  form_before_question_name: null | string
  form_after_name: null | string
  form_after_question_name: null | string
}

const CardAfeterBefore = ({ data, formName }: Props) => {
  const [visible, setVisible] = useState(false)
  const [visibleAfter, setVisibleAfter] = useState(false)
  const [currentPicture, setCurrentPicture] = useState(0)
  const [currentPictureAfter, setCurrentPictureAfter] = useState(0)
  const [picturesAfter, setPicturesAfter] = useState([])
  const [picturesBefore, setPicturesBefore] = useState([])

  const getPictureAfter = () => {
    let newData: any = []
    data.after_answer.forEach((el: any, index: number) => el.field_value.forEach((value: any) => newData.push(value)))

    setPicturesAfter(newData)
  }

  const getPictureBefore = () => {
    let newData: any = []
    data.before_answer.forEach((el: any, index: number) => el.field_value.forEach((value: any) => newData.push(value)))

    setPicturesBefore(newData)
  }

  useEffect(() => {
    getPictureAfter()
    getPictureBefore()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Container>
      <TextH3>{data.pdv}</TextH3>
      <TextH3>{data.promoter}</TextH3>
      <Row gutter={[40, 40]} justify='center'>
        <Col span={11}>
          <TextH4>
            {formName &&
              formName.form_before_name &&
              formName.form_before_question_name &&
              `${formName.form_before_name} | ${formName.form_before_question_name}`}
          </TextH4>
          <Row gutter={[10, 10]} justify='center'>
            {picturesBefore.map((el: any, index: number) => (
              <Col
                onClick={() => {
                  setVisible(true)
                  setCurrentPicture(index)
                }}
              >
                <LoadingSinglePicture el={el} />
              </Col>
            ))}
          </Row>
          <div style={{ display: 'none' }}>
            <Image.PreviewGroup
              preview={{ visible, onVisibleChange: (vis) => setVisible(vis), current: currentPicture }}
            >
                {picturesBefore.map((el: any) => (
                  <Image src={el} />
                ))}
            </Image.PreviewGroup>
          </div>
        </Col>
        <Col span={2}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <PersonDivider />
          </div>
        </Col>
        <Col span={11}>
          <TextH4>
            {formName &&
              formName.form_after_name &&
              formName.form_after_question_name &&
              `${formName.form_after_name} | ${formName.form_after_question_name}`}
          </TextH4>
          <Row gutter={[10, 10]} justify='center'>
            {picturesAfter.map((el: any, index: number) => (
              <Col
                onClick={() => {
                  setVisibleAfter(true)
                  setCurrentPictureAfter(index)
                }}
              >
                <LoadingSinglePicture el={el} />
              </Col>
            ))}
          </Row>
          <div style={{ display: 'none' }}>
            <Image.PreviewGroup
              preview={{
                visible: visibleAfter,
                onVisibleChange: (vis) => setVisibleAfter(vis),
                current: currentPictureAfter,
              }}
            >
              {picturesAfter.map((el: any) => (
                <Image src={el} title='teste' />
              ))}
            </Image.PreviewGroup>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default CardAfeterBefore
