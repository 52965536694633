import { DeleteOutlined, EditOutlined, MoreOutlined, DownOutlined } from '@ant-design/icons'
import { Button, Col, Dropdown, Menu, message, Popconfirm, Tooltip } from 'antd'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { DataStatus, StatusInativo, StatusRealizado } from '../../../components/TabsVisits/style'
import { exportXls } from '../../../utils/funcoes'
import { Mixpanel } from '../../../utils/mixpanel'
import DefaultPage from '../../../components/DefaultPage'
import { hasIndex } from '../../../components/Menu/menuHorizonte'
import { ContextUpload } from '../../../context/contextUpload'
import api_v2 from '../../../services/api-v2'

interface Pdv {
  id: string
  name: string
  pdv_code: any
  corporate_name: string
  cnpj: any
  telephone: string
  manager: string
  cep: any
  city: string
  address: string
  state: string
  neighborhood: string
  number: string
  complement: string
  latitude: string
  longitude: string
  is_active: boolean
  prevState: null
}

export interface PdvFilter {
  name?: string
  pdv_code?: string
  cnpj?: string
  cep?: string
  city?: string
  address?: string
}

const PDVListPage: React.FC = () => {
  const { permissions } = ContextUpload()

  const [reload, setReload] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [selecteds, setSelecteds] = useState<any>([])

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      setSelecteds(selectedRows)
    },
    // getCheckboxProps: (record: any) => ({
    //   disabled: !record.is_active, // Column configuration not to be checked
    // }),
  }
  function compareByAlph(a: any, z: any) {
    if (a > z) {
      return -1
    }
    if (a < z) {
      return 1
    }
    return 0
  }

  const getStatus = (status: any) => {
    const listStatus: any = {
      PENDENT: (
        <Tooltip title='Inativa'>
          <StatusInativo />
        </Tooltip>
      ),
      COMPLETE: (
        <Tooltip title='Ativa'>
          <StatusRealizado />
        </Tooltip>
      ),
    }

    if (status === true) {
      return listStatus.COMPLETE
    } else if (status === false) {
      return listStatus.PENDENT
    } else {
      return '-'
    }
  }

  async function handleDelete(body: any) {
    setLoading(true)
    try {
      delete body.flag
      await api_v2.put(`/pdv/${body.id}`, body)
      message.success('PDV Inativado com sucesso')
      Mixpanel.track('Inativado loja', { params: { id: body.id } })
      setReload(true)
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  async function handleDeleteStore(body: any) {
    setLoading(true)
    try {
      await api_v2.delete(`/pdv/store/${body.id}`)
      message.success('PDV Inativado com sucesso')
      Mixpanel.track('Inativado loja', { params: { id: body.id } })
      setReload(true)
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  const deleteMass = async (id: string) => {
    try {
      await api_v2.delete(`/pdv/${id}`)
      Mixpanel.track('Inativou pdv', {
        params: { id: id },
      })
      setSelecteds([])

      message.success('Inativado com sucesso')
    } catch (err) {}
  }

  const deleteMassDB = async (id: string) => {
    try {
      await api_v2.delete(`/pdv/store/${id}`)
      Mixpanel.track('Deletou pdv', {
        params: { id: id },
      })
      setSelecteds([])
      message.success('Deletado com sucesso')
    } catch (err) {}
  }

  return (
    <DefaultPage
      titlePage='PDVs'
      breadcrumb={[{ name: 'Home', url: '/' }, { name: 'PDVs' }]}
      urlPage={`/backoffice/store/pdvs`}
      routeApi={{
        method: 'get',
        route: 'pdv',
        versionApi: 'v2',
        reload,
      }}
      listButtons={{
        buttonCreate: {
          visible: hasIndex(['store.add_pdv'], permissions) > -1,
          type: 'url',
          url: '/backoffice/store/pdvs/create',
        },
        buttonExport: {
          visible: hasIndex(['store.export_pdv'], permissions) > -1,
          type: 'function',
          function: async () => {
            setLoading(true)
            await exportXls({}, 'pdvs')
            setLoading(false)
          },
        },
        buttonimport: {
          visible: hasIndex(['store.import_pdv'], permissions) > -1,
          path: 'PDV',
        },
        buttonFilter: {
          visible: true,
          filters: [
            {
              label: 'Selecione o PDV:',
              name: 'name',
              type: 'search',
              urlSearch: 'pdvs',
              getEnv: 'label',
            },
            {
              label: 'Código do PDV:',
              name: 'pdv_code',
              type: 'text',
            },
            {
              label: 'CNJPJ:',
              name: 'cnpj',
              type: 'text',
            },
            {
              label: 'CEP:',
              name: 'cep',
              type: 'text',
            },
            {
              label: 'Cidade:',
              name: 'CITY',
              type: 'text',
            },
            {
              label: 'Endereço:',
              name: 'address',
              type: 'text',
            },
          ],
        },
        newButton: {
          visible: selecteds.length > 0 ? true : false,
          children: (
            <Col span={5}>
              <Dropdown
                overlay={
                  <Menu>
                    {hasIndex(['store.remove_pdv'], permissions) > -1 && (
                      <Menu.Item key='1' icon={<DeleteOutlined />}>
                        <Popconfirm
                          title='Tem certeza que deseja inativar estes pdvs?'
                          onConfirm={async () => {
                            setLoading(!loading)

                            for await (let pdv of selecteds) {
                              Mixpanel.track('Confirmou inativar pdv', pdv)
                              await deleteMass(pdv.id)
                            }
                            setLoading(!loading)
                            setReload(!reload)
                          }}
                          okText='Sim'
                          cancelText='Não'
                          placement='topRight'
                        >
                          Inativar pdv
                        </Popconfirm>
                      </Menu.Item>
                      
                    )}
                    {hasIndex(['store.remove_pdv'], permissions) > -1 && (
                      <Menu.Item key='1' icon={<DeleteOutlined />}>
                        <Popconfirm
                          title='Tem certeza que deseja deletar estes pdvs?'
                          onConfirm={async () => {
                            setLoading(!loading)

                            for await (let pdv of selecteds) {
                              Mixpanel.track('Confirmou deletar pdv', pdv)
                              await deleteMassDB(pdv.id)
                            }
                            setLoading(!loading)
                            setReload(!reload)
                          }}
                          okText='Sim'
                          cancelText='Não'
                          placement='topRight'
                        >
                          Deletar pdv
                        </Popconfirm>
                      </Menu.Item>
                      
                    )}
                  </Menu>
                }
              >
                <Button>
                  Ações em massa ({selecteds.length}) <DownOutlined />
                </Button>
              </Dropdown>
            </Col>
          ),
        },
      }}
      rowSelection={{
        type: 'checkbox',
        ...rowSelection,
      }}
      load={{ loading, setLoading }}
      columns={[
        {
          title: 'Status',
          dataIndex: 'is_active',
          key: 'status',
          width: 80,
          render: (status: any) => {
            return <DataStatus>{getStatus(status)}</DataStatus>
          },
        },
        {
          title: 'CNPJ',
          dataIndex: 'cnpj',
          width: 100,
          sorter: (a, b) => a.cnpj - b.cnpj,
        },
        {
          title: 'Razão Social',
          dataIndex: 'corporate_name',
          sorter: (a, b) => {
            return a.corporate_name.localeCompare(b.corporate_name)
          },
        },
        {
          title: 'Nome',
          dataIndex: 'name',
          sorter: (a, b) => {
            return a.name.localeCompare(b.name)
          },
        },
        {
          title: 'Endereço',
          render: (obj: Pdv) =>
            `${obj.address ? obj.address + `, ` : ''} ${obj.number ? obj.number + `, ` : ''} ${
              obj.complement ? obj.complement + `, ` : ''
            } - ${obj.neighborhood ? obj.neighborhood + `, ` : ''} ${obj.city ? obj.city + `, ` : ''} ${
              obj.state ? obj.state + `, ` : ''
            } ${obj.cep ? obj.cep : ''}`,
          sorter: (a, b) => compareByAlph(a.address, b.address),
        },
        {
          title: '#',
          width: 50,
          render: (obj: any) => {
            if (hasIndex(['store.change_pdv', 'store.remove_pdv'], permissions) === -1) return undefined
            return (
              <Dropdown
                overlay={
                  <Menu>
                    {hasIndex(['store.remove_pdv'], permissions) > -1 && (
                      <Menu.Item key='1' icon={<DeleteOutlined />}>
                        <Popconfirm
                          onConfirm={() => handleDelete({...obj, is_active: !obj.is_active})}
                          title={`Tem certeza que deseja ${obj.is_active ? 'inativar' : 'ativar'} esta loja ?`}
                          okText='Sim'
                          cancelText='Não'
                          placement='topRight'
                        >
                          <Tooltip placement='top' title={obj.is_active ? 'Inativar loja' : 'Ativar loja'}>
                            {obj.is_active ? 'Inativar loja' : 'Ativar loja'}
                          </Tooltip>
                        </Popconfirm>
                      </Menu.Item>
                    )}
                    {hasIndex(['store.change_pdv'], permissions) > -1 && (
                      <Menu.Item key='2' icon={<EditOutlined />}>
                        <Link to={`/backoffice/store/pdvs/edit/${obj.id}`}>Editar loja</Link>
                      </Menu.Item>
                    )}
                    {hasIndex(['store.remove_pdv'], permissions) > -1 && (
                      <Menu.Item key='1' icon={<DeleteOutlined style={{color: 'red'}}/>}>
                        <Popconfirm
                          onConfirm={() => handleDeleteStore(obj)}
                          title={`Tem certeza que deseja deletar está loja do sistema ?`}
                          okText='Sim'
                          cancelText='Não'
                          placement='topRight'
                        >
                          <Tooltip placement='top' title="Deletar loja">
                            Deletar loja
                          </Tooltip>
                        </Popconfirm>
                      </Menu.Item>
                    )}
                  </Menu>
                }
              >
                <Button size='small' shape='circle' icon={<MoreOutlined />} />
              </Dropdown>
            )
          },
        },
      ]}
    />
  )
}

export default PDVListPage
