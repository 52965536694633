import React, { useState, useEffect, useRef } from 'react'
import {
  Button,
  CardCreate,
  ContainerModal,
  ContainerStep1,
  ContainerStep2,
  LabelCheck,
  CardCreateUpload,
} from './style'
import AddUser from '../../assets/images/addUser.png'
import ImportDoc from '../../assets/images/importDoc.png'
import { CloudUploadOutlined, PlusOutlined, CloudDownloadOutlined } from '@ant-design/icons'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import { message, Modal, DatePicker } from 'antd'
import UploadFile from '../../services/upload'
import { Loading } from '../Loading'
import { BtnExport, ButtonLink } from '../ModalImport/style'
import moment from 'moment'
import api_v2 from '../../services/api-v2'
import { Search } from '../form/SimpleSearch'
import { Mixpanel } from '../../utils/mixpanel'

interface IProps {
  visible: boolean
  close: (boolean: any) => void
  type: string
  stepJump?: boolean
  promoter: any
  reloadApi?: (boolean: any) => void
}

export const ModalCreateVisit = ({ visible, close, type, stepJump, promoter, reloadApi }: IProps) => {
  const formRef = useRef<FormHandles>(null)
  const [step, setStep] = useState(stepJump === true ? 1 : 0)
  const [body, setBody] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)
  let getWorkspace = sessionStorage.getItem('@rocketpdv:workspace')
  let newGetWorkspace = JSON.parse(getWorkspace!.toString())

  async function handleSubmit(data: any) {
    setLoading(true)
    try {
      if (promoter === null) {
        if (!data.pdv_id || !data.promoter_id || !body.dt_visit) {
          message.error('Por favor, preencha todos campos 1')
          setLoading(false)
          return
        }
      } else {

        if (!data.pdv_id || !body.dt_visit) {
          setLoading(false)
          message.error('Por favor, preencha todos campos 2')
          return
        }
      }

      // if (newGetWorkspace.shared_environment) {
      //   if (data.sub_workspaces.length === 0) {
      //     setLoading(false)
      //     message.error('Por favor, selecione ao menos uma empresa')
      //     return
      //   }
      // }

      if(promoter.removal_start_date && promoter.removal_end_date) {
        let start = moment(promoter.removal_start_date).subtract( 1, 'h').toDate()
        let end = moment(promoter.removal_end_date).add(1, 'h').toDate()
        let visit = moment(body.dt_visit).toDate()
        let isBetween = moment(visit).isBetween(start, end)
        
        if(isBetween) {
          message.error('Este promotor está afastado neste período')
          setLoading(false)
          return
        }
      }

      for await (let el of data.pdv_id) {
        let dataPost: any = {}

        if (newGetWorkspace.shared_environment) {
          dataPost = {
            dt_visit: body.dt_visit,
            user_id: promoter === null ? data.promoter_id.value : promoter.id,
            pdv_id: el.value,
            sub_workspaces: data.sub_workspaces && data.sub_workspaces.length > 0 ? data.sub_workspaces.map((el: any) => el.value) : [],
          }
        } else {
          dataPost = {
            dt_visit: body.dt_visit,
            user_id: promoter === null ? data.promoter_id.value : promoter.id,
            pdv_id: el.value,
          }
        }


        await api_v2.post('/visit', dataPost)

        Mixpanel.track('Adicionou visita', dataPost)
      }

      if (reloadApi) {
        reloadApi(true)
      }
      message.success('Visita adicionada com sucesso')
      close(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
      if (err instanceof Yup.ValidationError) {
        message.error('Erro ao adicionar visita!')
      }
    }
    setLoading(false)
  }

  const uploadFile = async (info: any) => {
    if (info.event) await importFile(info.file.originFileObj)
  }

  const uploadFileDrop = async (info: any) => {
    if (info.event) await importFile(info.file.originFileObj)
  }

  const importFile = async (file: any) => {
    setLoading(true)
    if (file) {
      const archive: any = new FormData()
      archive.append('file', file)
      archive.append('typeUser', type)
      try {
        await UploadFile.post('/importFile', archive)
        Modal.success({
          title: 'Sucesso!!',
          content: 'Arquivo enviado com sucesso!!!',
          onOk() {
            window.location.reload()
          },
        })
      } catch (error) {
        Modal.error({
          title: 'Erro',
          content: 'Erro ao enviar arquivo!!!',
        })
      }
    }
    setLoading(false)
  }

  async function onChangeDateIniti(value: any, dateString: any) {
    dateString = dateString.replace('/', '').replace('/', '')
    dateString = dateString.replace('-', '').replace('-', '')

    if (dateString === '') {
      setBody(undefined)
      return
    }

    let day = dateString.substr(0, 2)
    let month = dateString.substr(2, 2)
    let year = dateString.substr(4, 4)
    let date = year + '-' + month + '-' + day

    setBody({ ...body, dt_visit: date })
  }

  const disabledDate = (current: any) => {
    // Can not select days before today and today
    return current && current < moment(new Date()).endOf('day').subtract(1, 'd')
  }

  const functionThatResetsForm = () => {
    formRef.current!.reset()
  }

  useEffect(() => {
    setStep(stepJump === true ? 1 : 0)
    // loadOptions(null, null, {page: 1})
    setBody({})
    if (formRef.current != null) functionThatResetsForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  return (
    <ContainerModal
      visible={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
      width={step === 0 ? 700 : 450}
    >
      {loading && <Loading />}
      {step === 0 && (
        <ContainerStep1>
          <CardCreate style={{ marginRight: 30 }}>
            <img src={AddUser} alt='user' />
            <h4>Cadastrar Manual</h4>
            <p>Selecione aqui se você quer cadastrar uma visita por vez.</p>
            <Button onClick={() => setStep(1)}>
              <PlusOutlined />
              Adicionar visita
            </Button>
          </CardCreate>
          <div>
            <CardCreateUpload onDrop={uploadFileDrop} onChange={uploadFile}>
              <img src={ImportDoc} alt='user' />
              <h4>Importar XLSX</h4>
              <p>Importe várias visitas através de uma planilha de uma vez.</p>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <Button style={{ backgroundColor: '#b56aff', marginTop: 6 }}>
                  <CloudUploadOutlined />
                  Importar Planilha
                </Button>
              </div>
            </CardCreateUpload>
            <ButtonLink>
              <CloudDownloadOutlined />
              <BtnExport href={process.env.REACT_APP_API_URL + `/`} target='_blank'>
                Baixe o modelo
              </BtnExport>
            </ButtonLink>
          </div>
        </ContainerStep1>
      )}
      {step === 1 && (
        <Form className='form' ref={formRef} initialData={{ is_active: true }} onSubmit={handleSubmit}>
          <ContainerStep2>
            <h3>Cadastro de visita</h3>
            <img src={AddUser} alt='user' />
            {promoter === null && (
              <>
                <LabelCheck>Selecione o promotor</LabelCheck>
                <Search placeholder='Selecione o promotor' name='promoter_id' path='promoters' isMult={false} />
              </>
            )}
            <LabelCheck>Selecione a loja</LabelCheck>
            <Search placeholder='Selecione o PDV' name='pdv_id' path='pdvs' isMult={true} />
            {newGetWorkspace && newGetWorkspace.shared_environment && (
              <>
                <LabelCheck>Selecione o sub workspace</LabelCheck>
                <Search
                  placeholder='Selecione o sub workspace'
                  name='sub_workspaces'
                  path='sub-workspaces'
                  isMult={true}
                />
              </>
            )}
            <LabelCheck>Data da visita</LabelCheck>
            <DatePicker
              format='DD/MM/YYYY'
              onChange={onChangeDateIniti}
              name='dt_visit'
              style={{ width: '100%' }}
              disabledDate={disabledDate}
            />
            <Button type='submit' style={{ width: '100%', height: '55px', marginTop: 20 }}>
              Cadastrar
            </Button>
          </ContainerStep2>
        </Form>
      )}
    </ContainerModal>
  )
}
