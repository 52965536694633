import styled from "styled-components"

export const Container = styled.div`
    margin: 20px 0;
`
export const ContainerPicture = styled.div`
    align-items: center;
    justify-content: center;
`
export const ContainerButton = styled.div`
    width: 100px;
    height: 60px;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
`

export const Gradient = styled.button`
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 5px;
`

export const Title = styled.p`
    color: #2e2f34;
    font-size: 16px;
    margin-bottom: 20px;
`

export const ContainerShowPicture = styled.div`
    padding-top: 10px;
    flex-direction: row;
    margin-bottom: 20px;
`
export const ContainerView = styled.div`
    width: 100;
    height: 100;
    border-radius: 5px;
    border-color: #f7f0fe;
    border-width: 2px;
    margin: 10px 6px 0;

`
export const TextError = styled.p`
    width: 100%;
    margin-top: 15px;
    color: #e83f5b;
    text-align: center;
    font-size: 15px;
`