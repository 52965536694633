import { useState, useEffect } from 'react'
import { ContainerModal, Container, Title, Steps, ContainerFlow } from './style'
import { Row, Col, DatePicker, Divider, Tooltip } from 'antd'
import { ButtonBack } from '../../pages/Team/ViewUser/styles'
import { Search } from '../form/SimpleSearch'
import { Form } from '@unform/web'
import { TextBlack, TextLight } from '../CardVisits/style'
import { EditOutlined } from '@ant-design/icons'
import moment from 'moment'
import { Loading } from '../Loading'

interface IProps {
  visible: boolean
  close: (boolean: any) => void
  visit: any
}

export const ModalDuplicateVisit = ({ visible, close, visit }: IProps) => {
  const [loading] = useState(false)
  const [editData, setEditData] = useState<number[]>([])
  const [allVisit, setAllVisits] = useState<any>(visit)
  const [typeExport, setTypeExport] = useState<any>({
    step: 0,
    selectType: 0,
  })

  useEffect(() => {
    setTypeExport({
      step: 0,
      selectType: 0,
    })
  }, [visible]) //eslint-disable-line

  useEffect(() => {
    setAllVisits(visit)
  }, [visit])

  const handleSubmitVisit = async (body: any) => {
    try {
      console.log({ ...body, visits: allVisit })
    } catch (err) {}
  }

  return (
    <ContainerModal
      visible={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
    >
      {loading && <Loading />}
      <Container>
        {typeExport.step === 0 && (
          <>
            <Title style={{ fontSize: 22 }}>DUPLICAR VISITA(S)</Title>
            <Steps>Etapa 1/2</Steps>
            <Row justify='center' style={{ marginTop: 40, marginBottom: 40 }}>
              <Col span={24}>
                <Title style={{ fontWeight: 'normal' }}>Vai modificar a data das visitas ou vai mante-las?</Title>
              </Col>
            </Row>
            <Row justify='center' gutter={[20, 20]}>
              <Col span={12}>
                <ButtonBack style={{ width: '100%' }} onClick={() => setTypeExport({ step: 1, selectType: 1 })}>
                  Modificar
                </ButtonBack>
              </Col>
              <Col span={12}>
                <ButtonBack style={{ width: '100%' }} onClick={() => setTypeExport({ step: 2, selectType: 2 })}>
                  Manter
                </ButtonBack>
              </Col>
            </Row>
          </>
        )}
        {typeExport.step === 1 && typeExport.selectType === 1 && (
          <>
            <Title style={{ fontSize: 22 }}>DUPLICAR VISITA(S)</Title>
            <Steps>Etapa 1/2</Steps>
            <Row justify='center' style={{ marginTop: 40, marginBottom: 40 }}>
              <Col span={24}>
                <Title style={{ fontWeight: 'normal' }}>Para qual data vão essas visitas?</Title>
                <Title style={{ fontSize: 13 }}>Você pode mudar todas de uma vez ou individualmente</Title>
              </Col>
            </Row>
            <Row justify='center' style={{ marginTop: 40, marginBottom: 40 }}>
              <Col>
                <DatePicker
                  onChange={(date, dateString) => {
                    let newVisits = allVisit.map((el: any) => {
                      return { ...el, dt_visit: dateString }
                    })
                    setAllVisits(newVisits)
                  }}
                  style={{ width: 250, height: 50 }}
                  format='DD/MM/YYYY'
                />
              </Col>
            </Row>
            <Row gutter={[20, 20]}>
              <Col span={13}>
                <TextBlack>Loja</TextBlack>
              </Col>
              <Col span={6}>
                <TextBlack>Data</TextBlack>
              </Col>
              <Divider style={{ margin: 0 }} />
            </Row>
            <ContainerFlow>
              <Row gutter={[0, 20]}>
                {allVisit &&
                  allVisit.length > 0 &&
                  allVisit.map((el: any, index: number) => (
                    <>
                      <Col span={12}>
                        <TextLight style={{ fontSize: 14 }}>{el.pdv?.name}</TextLight>
                      </Col>
                      <Col span={10}>
                        {editData.includes(index) ? (
                          <DatePicker
                            onChange={(data, dataString) => {
                              let newVisits = allVisit
                              newVisits[index].dt_visit = moment(dataString).format('DD/MM/YYYY')
                              setAllVisits(newVisits)
                              let edit = editData.splice(index + 1, 1)
                              setEditData(edit)
                            }}
                          />
                        ) : (
                          <TextLight style={{ fontSize: 14 }}>{el.dt_visit}</TextLight>
                        )}
                      </Col>
                      <Col span={2}>
                        <Tooltip placement='topRight' title={'Editar cadastro'}>
                          <EditOutlined
                            style={{ cursor: 'pointer' }}
                            onClick={() => setEditData([...editData, index])}
                          />
                        </Tooltip>
                      </Col>
                      <Divider style={{ margin: 0 }} />
                    </>
                  ))}
              </Row>
            </ContainerFlow>
            <Row justify='center' gutter={[20, 20]} style={{ marginTop: 20 }}>
              <Col span={24}>
                <ButtonBack style={{ width: '100%' }} onClick={() => setTypeExport({ step: 3, selectType: 2 })}>
                  Continuar
                </ButtonBack>
              </Col>
            </Row>
          </>
        )}
        {typeExport.step === 2 && typeExport.selectType === 2 && (
          <>
            <Title style={{ fontSize: 22 }}>DUPLICAR VISITA(S)</Title>
            <Steps>Etapa 1/2</Steps>
            <Row justify='center' style={{ marginTop: 40, marginBottom: 40 }}>
              <Col span={24}>
                <Title style={{ fontWeight: 'normal' }}>
                  Para qual(is) Promotor(es) você deseja duplicar as visitas selecionadas?
                </Title>
              </Col>
            </Row>
            <Row justify='center' gutter={[20, 20]}>
              <Col span={12}>
                <ButtonBack style={{ width: '100%' }} onClick={() => setTypeExport({ step: 3, selectType: 2 })}>
                  Um promotor
                </ButtonBack>
              </Col>
              <Col span={12}>
                <ButtonBack style={{ width: '100%' }} onClick={() => setTypeExport({ step: 3, selectType: 2 })}>
                  Vários promotores
                </ButtonBack>
              </Col>
            </Row>
          </>
        )}
        {typeExport.step === 3 && typeExport.selectType === 2 && (
          <>
            <Title style={{ fontSize: 22 }}>DUPLICAR VISITA(S)</Title>
            <Steps>Etapa 2/2</Steps>
            <Row justify='center' style={{ marginTop: 40, marginBottom: 40 }}>
              <Col span={24}>
                <Title style={{ fontWeight: 'normal' }}>
                  Para qual(is) Promotor(es) você deseja duplicar as visitas selecionadas?
                </Title>
              </Col>
            </Row>
            <Form onSubmit={handleSubmitVisit} translate={'yes'}>
              <Row justify='center' gutter={[20, 20]}>
                <Col span={24}>
                  <Search placeholder='Selecione o promotor' name='id_promoter' path='promoters' isMult={true} />
                </Col>
                <Col span={24}>
                  <ButtonBack style={{ width: '100%' }} onClick={() => setTypeExport({ step: 3, selectType: 2 })}>
                    Duplicar
                  </ButtonBack>
                </Col>
              </Row>
            </Form>
          </>
        )}
      </Container>
    </ContainerModal>
  )
}
