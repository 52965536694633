import { DeleteOutlined, MoreOutlined, DownOutlined } from '@ant-design/icons'
import { Button, Col, Dropdown, Menu, message, Popconfirm, Tooltip } from 'antd'
import { useState } from 'react'
import { useParams } from 'react-router'
import qs from 'querystring'
import useReactRouter from 'use-react-router'
import { exportXls } from '../../../utils/funcoes'
import DefaultPage from '../../../components/DefaultPage'
import { hasIndex } from '../../../components/Menu/menuHorizonte'
import { ContextUpload } from '../../../context/contextUpload'
import { getTypeCollected } from '../../../utils/login'
import api_v2 from '../../../services/api-v2'
import { Mixpanel } from '../../../utils/mixpanel'
import { ModalCreateMixC } from '../../../components/ModalCreateMixC'

function compareByAlph(a: any, z: any) {
  if (a > z) {
    return -1
  }
  if (a < z) {
    return 1
  }
  return 0
}

const MixCategorySubList = () => {
  const { location } = useReactRouter()
  const { permissions } = ContextUpload()

  const [loading, setLoading] = useState(false)
  const [visibleImport, setVisibleImport] = useState<boolean>(false)
  const [reload, setReload] = useState<boolean>(false)
  const [columnsQtd] = useState([
    {
      title: 'Categoria',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: any, b: any) => compareByAlph(a.name, b.name),
    },
    {
      title: 'Contexto',
      dataIndex: 'context',
      key: 'context',
      sorter: (a: any, b: any) => compareByAlph(a.context, b.context),
      render: (type: any) => (
        <>
          {type === 'FLAG' && <p>Bandeira</p>}
          {type === 'NETWORK' && <p>Rede</p>}
          {type === 'PDV' && <p>PDV</p>}
        </>
      ),
    },
    {
      title: 'Referência',
      dataIndex: 'ref_name',
      key: 'ref_name',
      sorter: (a: any, b: any) => compareByAlph(a.ref_name, b.ref_name),
    },
    {
      title: 'Regra',
      dataIndex: 'context',
      key: 'context',
      sorter: (a: any, b: any) => compareByAlph(a.context, b.context),
      render: (type: any) => (
        <>
          {type === 'FLAG' && <p>Bandeira</p>}
          {type === 'NETWORK' && <p>Rede</p>}
          {type === 'PDV' && <p>PDV</p>}
        </>
      ),
    },
    {
      title: '#',
      width: 50,
      render: (obj: any) => {
        if (hasIndex(['catalog.remove_product_mix'], permissions) === -1) return undefined
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key='1' icon={<DeleteOutlined />}>
                  <Popconfirm
                    onConfirm={() => handleDelete(obj)}
                    title='Tem certeza que deseja excluir essa categoria ?'
                    okText='Sim'
                    cancelText='Não'
                    placement='topRight'
                  >
                    <Tooltip placement='top' title={'Deletar categoria'}>
                      Deletar Categoria
                    </Tooltip>
                  </Popconfirm>
                </Menu.Item>
              </Menu>
            }
          >
            <Button size='small' shape='circle' icon={<MoreOutlined />} />
          </Dropdown>
        )
      },
    },
  ])
  const [selecteds, setSelecteds] = useState<any>([])

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      console.log(selectedRows)
      setSelecteds(selectedRows)
    },
    getCheckboxProps: (record: any) => ({
      disabled: !record.product_id, // Column configuration not to be checked
    }),
  }

  const [columns] = useState([
    {
      title: 'Categoria',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: any, b: any) => compareByAlph(a.name, b.name),
    },
    {
      title: 'Contexto',
      dataIndex: 'context',
      key: 'context',
      sorter: (a: any, b: any) => compareByAlph(a.context, b.context),
      render: (type: any) => (
        <>
          {type === 'FLAG' && <p>Bandeira</p>}
          {type === 'NETWORK' && <p>Rede</p>}
          {type === 'PDV' && <p>PDV</p>}
        </>
      ),
    },
    {
      title: 'Referência',
      dataIndex: 'ref_name',
      key: 'ref_name',
      sorter: (a: any, b: any) => compareByAlph(a.ref_name, b.ref_name),
    },
    {
      title: 'Regra',
      dataIndex: 'context',
      key: 'context',
      sorter: (a: any, b: any) => compareByAlph(a.context, b.context),
      render: (type: any) => (
        <>
          {type === 'FLAG' && <p>Bandeira</p>}
          {type === 'NETWORK' && <p>Rede</p>}
          {type === 'PDV' && <p>PDV</p>}
        </>
      ),
    },
    {
      title: '#',
      width: 50,
      render: (obj: any) => {
        if (hasIndex(['catalog.remove_product_mix'], permissions) === -1) return undefined
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key='1' icon={<DeleteOutlined />}>
                  <Popconfirm
                    onConfirm={() => handleDelete(obj)}
                    title='Tem certeza que deseja excluir esse categoria ?'
                    okText='Sim'
                    cancelText='Não'
                    placement='topRight'
                  >
                    <Tooltip placement='top' title={'Deletar categoria'}>
                      Deletar categoria
                    </Tooltip>
                  </Popconfirm>
                </Menu.Item>
              </Menu>
            }
          >
            <Button size='small' shape='circle' icon={<MoreOutlined />} />
          </Dropdown>
        )
      },
    },
  ])

  const { id }: any = useParams()
  const handleDelete = async (category: any) => {
    try {
      await api_v2.put('category/delete-mix/category/' + category.id, { context: category.context })

      message.success('Categoria deletado com sucesso.')
      setReload(!reload)
    } catch (error) {
      message.error('Erro ao deletar categoria.')
    }
  }

  const deleteMass = async (category: any) => {
    try {
      await api_v2.put('category/delete-mix/category/' + category.id, { context: category.context })
      Mixpanel.track('Excluiu categoria de mix', {
        params: { id: id },
      })
      message.success('Categoria inativado com sucesso!')
    } catch (err) {
      message.error('Esse categoria está vinculada a algum outro registro')
    }
  }

  return (
    <>
      <DefaultPage
        titlePage='Mix de Categoria'
        breadcrumb={[
          { name: 'Home', url: '/' },
          { name: 'Mix de Categoria', url: '/backoffice/catalog/mix-category' },
          { name: 'Mix' },
        ]}
        urlPage={`/backoffice/catalog/mix-category/${id}?context=${qs.parse(location.search.replaceAll('?', ''))?.context}`}
        routeApi={{
          method: 'get',
          route: `category/get-mix/${id}`,
          versionApi: 'v2',
          reload,
        }}
        listButtons={{
          buttonCreate: {
            visible: hasIndex(['catalog.add_product_mix'], permissions) > -1,
            type: 'function',
            function: () => setVisibleImport(true),
          },
          buttonExport: {
            visible: hasIndex(['catalog.export_product_mix'], permissions) > -1,
            type: 'function',
            function: async () => {
              setLoading(true)
              await exportXls({}, `category-mix/${id}`)
              setLoading(false)
            },
          },
          buttonTextFilter: {
            visible: true,
            url: `/category-mix/${id}`,
          },
          newButton: {
            visible: selecteds.length > 0 ? true : false,
            children: (
              <Col span={5}>
                <Dropdown
                  overlay={
                    <Menu>
                      {hasIndex(['catalog.remove_product_mix'], permissions) > -1 && (
                        <Menu.Item key='1' icon={<DeleteOutlined />}>
                          <Popconfirm
                            title='Tem certeza que deseja remover essa categoria?'
                            onConfirm={async () => {
                              setLoading(!loading)

                              for await (let category of selecteds) {
                                Mixpanel.track('Confirmou remover categoria', category)
                                await deleteMass(category)
                              }
                              setLoading(!loading)
                              setReload(!reload)
                            }}
                            okText='Sim'
                            cancelText='Não'
                            placement='topRight'
                          >
                            Remover categoria
                          </Popconfirm>
                        </Menu.Item>
                      )}
                    </Menu>
                  }
                >
                  <Button>
                    Ações em massa ({selecteds.length}) <DownOutlined />
                  </Button>
                </Dropdown>
              </Col>
            ),
          },
        }}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        load={{ loading, setLoading }}
        columns={getTypeCollected() === 'Display' ? columnsQtd : columns}
      />
      {visibleImport && (
        <ModalCreateMixC
          id={id}
          context={qs.parse(location.search.replaceAll('?', '')).context}
          visible={visibleImport}
          close={setVisibleImport}
        />
      )}
    </>
  )
}

export default MixCategorySubList
