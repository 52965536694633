import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { Form, Input } from '@rocketseat/unform'
import { Button, Col, Divider, message, Row, Tree } from 'antd'
import { AntTreeNode } from 'antd/lib/tree'
import React from 'react'
import * as Yup from 'yup'
import { Container } from '../../../GlobalStyles'
import api from '../../../services/api'
import api_v2 from '../../../services/api-v2'

const { TreeNode } = Tree

export interface DataNode {
  children?: DataNode[]
  isLeaf?: boolean
  key: string | number
  title?: React.ReactNode
  selectable?: boolean
}

interface PropsAntTree {
  node: AntTreeNode | any
  nativeEvent: MouseEvent
  event: 'select'
  selected?: boolean
  selectedNodes?: DataNode[]
}

export default class CategoryListPage extends React.Component {
  constructor(props: any) {
    super(props)
    this.handleEditCategory = this.handleEditCategory.bind(this)
    this.handleAddSubCategory = this.handleAddSubCategory.bind(this)
  }

  state = {
    category_edit: '',
    categories: [{ title: 'Categoria base', key: '0' }],
    node_selected: {},
    category_selected: {
      title: 'Categoria base',
      key: '0',
      children: undefined,
    },
  }

  getCategories = async (id: string) => {
    const { data } = await api.get('category/' + id)
    const result = data.categories.map((item: any) => ({
      key: item.id,
      title: item.name,
      isLeaf: item.count_childrens === 0,
    }))

    return result
  }

  deleteCategory = async (category: any) => {
    await api_v2.put('category/desable/' + category.key)
    this.getCategories('0')
  }

  onLoadData = async (treeNode: any): Promise<any> => {
    if (treeNode.props.children) return

    const children = await this.getCategories(treeNode.props.eventKey)
    treeNode.props.domRef.children = children
    this.setState({
      node_selected: treeNode,
      categories: [...this.state.categories],
    })
  }

  renderTreeNodes = (data: any): any => {
    return data.map((item: any) => {
      if (item.children) {
        return (
          <TreeNode title={item.title} key={item.key} domRef={item}>
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        )
      }
      return <TreeNode key={item.key} {...item} domRef={item} />
    })
  }

  onSelect = (_selectedKeys: (string | number)[], info: PropsAntTree) => {
    this.setState({
      node_selected: info.node,
      category_selected: info.node.props.domRef,
      category_edit: info.node.props.data.title,
    })
  }

  async handleAddSubCategory(body: any, { resetForm }: any) {
    try {
      await api.post('/category', {
        ...body,
      })

      let treeNode: any = this.state.node_selected
      const children = await this.getCategories(body.parent_id)
      treeNode.props.domRef.children = children
      this.setState({ categories: [...this.state.categories] })
      message.success('Categoria adicionada com sucesso')
      resetForm()
      this.setState({
        category_selected: {
          title: 'Categoria base',
          key: '0',
          children: undefined,
        },
      })
    } catch (err) {
      message.error('Já existe uma categoria com esse nome')
    }
  }

  async handleEditCategory(body: any, { resetForm }: any) {
    try {
      await api.put('/category/' + body.id, {
        ...body,
      })

      this.setState({
        categories: this.state.categories,
        category_selected: {
          title: body.name,
          key: body.id,
          children: undefined,
        },
      })

      message.success('Categoria editada com sucesso')
      window.location.reload()
    } catch (err) {
      console.log(err)
      message.error('Já existe uma categoria com esse nome')
    }
  }

  render() {
    const { category_selected } = this.state

    const schemaEdit = Yup.object().shape({
      name: Yup.string().required('O Campo é obrigatório'),
      id: Yup.string().required('O Campo id é obrigatório'),
    })

    const schemaCreate = Yup.object().shape({
      name: Yup.string().required('O Campo nome é obrigatório'),
      parent_id: Yup.string().required('O Campo id é obrigatório'),
    })

    return (
      <Container>
        <Row>
          <Col span={12}>
            <Tree
              defaultSelectedKeys={['0']}
              defaultExpandAll={true}
              loadData={this.onLoadData}
              onSelect={this.onSelect}
            >
              {this.renderTreeNodes(this.state.categories)}
            </Tree>
          </Col>
          <Col span={12}>
            {category_selected.key !== '0' && (
              <>
                <Form
                  className='form'
                  schema={schemaEdit}
                  onSubmit={this.handleEditCategory}
                  initialData={{
                    id: category_selected.key,
                    name: category_selected.title,
                  }}
                >
                  <Row gutter={[16, 16]} style={{ display: 'flex', alignItems: 'center' }}>
                    <Col span={20}>
                      <Input
                        type='text'
                        label={`Editar categoria ${category_selected.title}`}
                        name='name'
                        value={category_selected.title ? category_selected.title : ''}
                      />
                      <Input name='id' type='hidden' value={category_selected.key} />
                    </Col>
                    <Col span={2}>
                      <Button style={{ marginTop: 10 }} type='primary' htmlType='submit' icon={<EditOutlined />} />
                    </Col>

                    <Col span={2}>
                      <Button
                        style={{ marginTop: 10 }}
                        type='default'
                        htmlType='button'
                        icon={<DeleteOutlined />}
                        onClick={() => this.deleteCategory(category_selected)}
                      />
                    </Col>
                  </Row>
                </Form>
                <Divider />
              </>
            )}
            <Form schema={schemaCreate} className='form' onSubmit={this.handleAddSubCategory}>
              <Row gutter={[16, 32]} style={{ display: 'flex', alignItems: 'center' }}>
                <Col span={20}>
                  <Input type='text' label={`Adicionar subcategoria em "${category_selected.title}"`} name='name' />
                  <Input type='hidden' name='parent_id' value={category_selected.key} />
                </Col>
                <Col span={4}>
                  <Button style={{ marginTop: 10 }} type='primary' htmlType='submit'>
                    +
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    )
  }
}
