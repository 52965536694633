import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;

  &&& .react-select__value-container {
    height: 38px !important;
  }

  &&& .css-1uccc91-singleValue {
    top: 65% !important;
  }
`

export const Error = styled.span`
  color: #ff4d4f;
  font-weight: bold;
`

export const Label = styled.label`
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  > span:first-child {
    color: #ff4d4f;
    margin-top: 10px;
    line-height: 1.3;
    width: 100%;
    border-radius: 4px;
    transform: none;
    animation: fadeIn 350ms ease-in-out 1;
    @keyframes fadeIn {
      from {
        transform: translateY(-20px);
        opacity: 0;
      }
      to {
        transform: translateY(0);
        opacity: 1;
      }
    }
    + input,
    select {
      border-color: #ff4d4f;
      + svg {
        fill: #ff4d4f;
      }
    }
  }
`
