import React, { useState } from 'react'
import { CardStatus, CircleStatus, ContainerStatus, ContainerUser, Count, ImgUser, TextType } from './style'
import { Table } from 'antd'
import { CheckOutlined, MoreOutlined, ShopOutlined } from '@ant-design/icons'
import { IDataKanbam } from '../../pages/MonitoryPage/MonitoringPage'
import ModalKanban from '../ModalKanban'
import { Collapse } from 'antd';
const { Panel } = Collapse;

interface IProps {
  data: IDataKanbam[]
  nameTable: string
}

export const ListStatusKanban = ({ data, nameTable }: IProps) => {
  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState({
    name: '',
    store_complete: 0,
    store_counts: 0,
    id: 0,
  })

  const selectTaks = (name: string, store_complete: number, store_counts: number, id: number) => {
    setSelected({
      name,
      store_complete,
      store_counts,
      id,
    })
    setVisible(!visible)
  }

  const columns = [
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      render: (a: any, b: any) => (
        <ContainerUser>
          <CircleStatus style={{ backgroundColor: nameTable === 'Sem interação' ? '#e9830f' : '#16c266' }} />
          <ImgUser src={`https://via.placeholder.com/150/ff872c/fff/?text=${b.promoter_name.substr(0, 1)}`} />
        </ContainerUser>
      ),
    },
    {
      title: 'NOME',
      dataIndex: 'promoter_name',
      key: 'promoter_name',
      sorter: (a: any, b: any) => a.promoter_name - b.promoter_name,
    },
    {
      title: 'TOTAL LOJAS',
      dataIndex: 'visits_all',
      key: 'visits_all',
      render: (a: any) => (
        <>
          <ContainerUser>
            <ShopOutlined />
            <p>{a} lojas no total</p>
          </ContainerUser>
        </>
      ),
    },
    {
      title: 'TAREFAS FEITAS',
      dataIndex: 'visits_done',
      key: 'visits_done',
      render: (a: any) => (
        <>
          <ContainerUser>
            <CheckOutlined />
            <p>{a} lojas concluídas</p>
          </ContainerUser>
        </>
      ),
    },
    {
      title: 'LOCAL',
      dataIndex: 'store_name',
      key: 'store_name',
      width: '30%',
    },

    {
      title: '',
      key: 'action',
      width: '5%',
      render: (text: any, record: any) => (
        <MoreOutlined
          style={{ fontSize: 30 }}
          onClick={() => selectTaks(record.promoter_name, record.visits_done, record.visits_all, record.promoter_id)}
        />
      ),
    },
  ]
  return (
    <CardStatus>
      <Collapse>
      <Panel header={
         <ContainerStatus>
         <TextType>{nameTable}</TextType>
         <Count>{data.length}</Count>
       </ContainerStatus>
      } key="1">

        <Table columns={columns} dataSource={data} pagination={false} />

        {visible && <ModalKanban visible={visible} close={setVisible} promoter={selected} tag={{}} />}
        </Panel>
      </Collapse>
    </CardStatus>
  )
}
