import React, { useState, useEffect } from 'react'
import { ContainerConfig, Header, CloseConfig, Body, ContainerRequired, ContainerDrop, ContainerPicture } from './style'
import { Switch, Input, Tooltip, message, Upload, Button, Radio } from 'antd'
import { PlusCircleOutlined, UserOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { InputNumber } from 'antd'
import { ContainerUpload } from '../../ModalSubWorkspace/style'
import firebase from '../../../utils/firebase'
import { generateUUID } from '../../../utils/funcao'
import { UploadOutlined } from '@material-ui/icons'

const ConfigQuestion: React.FC<any> = ({ question, close, edit, type, allQUestion }) => {
  const [dataQuestion, setDataQuestion] = useState<any>(question)

  // useEffect(() => {
  //   setDataQuestion(question)
  // }, [question])

  const [active, setActive] = useState<boolean>(true)

  const addOption = () => {
    if (!dataQuestion.options) {
      edit({ ...dataQuestion, options: [''] })
      setDataQuestion({ ...dataQuestion, options: [''] })
    } else {
      edit({ ...dataQuestion, options: [...dataQuestion.options, ''] })
      setDataQuestion({
        ...dataQuestion,
        options: [...dataQuestion.options, ''],
      })
    }
  }

  const removeOption = (index: any) => {
    let newOptions: any = []
    setDataQuestion({})

    dataQuestion.options.forEach((el: any, inde: number) => {
      if ( index !== inde) newOptions.push(el)
    })

    let newDataQuestion = dataQuestion

    newDataQuestion.options = newOptions

    edit({ ...newDataQuestion, options: newOptions })

    setTimeout(() => {
      setDataQuestion(newDataQuestion)
    }, 90)
  }

  const editIndex = (value: any, index: any) => {
    let newOptions = dataQuestion.options.map((option: any, i: any) => {
      if (i === index) {
        return value
      }
      return option
    })
    edit({ ...dataQuestion, options: newOptions })
    setDataQuestion({ ...dataQuestion, options: newOptions })
  }

  useEffect(() => {
    if (type !== dataQuestion?.type) {
      setActive(false)
    }
  }, [type]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setActive(true)
    setDataQuestion(question)
  }, [active]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {active ? (
        <ContainerConfig>
          <Header>
            <h2>Configurar Questão</h2>
            <CloseConfig color='error' fontSize='small' onClick={() => close(false)} />
          </Header>
          <Body>
            {dataQuestion?.is_required ? (
              <ContainerRequired>
                <p>Resposta obrigatória</p>
                <Switch
                  defaultChecked
                  onChange={(check) => {
                    setDataQuestion({ ...dataQuestion, is_required: check })
                    edit({ ...dataQuestion, is_required: check })
                  }}
                />
              </ContainerRequired>
            ) : (
              <ContainerRequired>
                <p>Resposta obrigatória</p>
                <Switch
                  onChange={(check) => {
                    edit({ ...dataQuestion, is_required: check })
                    setDataQuestion({ ...dataQuestion, is_required: check })
                  }}
                />
              </ContainerRequired>
            )}

          <div style={{ marginTop: 30 }}>
            <p>Insira uma foto</p>
            <ContainerUpload>
              <Upload
                beforeUpload={() => {
                  if (dataQuestion.picture && dataQuestion.picture.length > 0) {
                    message.error('Insira apenas uma foto')
                    return false
                  }
                }}
                accept='image/*'
                fileList={dataQuestion.picture ? [
                  {
                  name: 'Foto',
                  uid: generateUUID(),
                  status: 'done',
                  url: dataQuestion.picture, 
                }] : []}
                onRemove={(file) => {
                  edit({ ...dataQuestion, picture: null })
                  setDataQuestion({ ...dataQuestion, picture: null })
                }}
                style={{ width: '100%' }}
                customRequest={async ({ file, onSuccess, onError }: any) => {
                  try {
                    console.log(file)
                    const team: any = sessionStorage.getItem('@rocketpdv:workspace')
                    const fileRef = firebase
                      .storage()
                      .ref(`${JSON.parse(team).slug}/pictures/${generateUUID()}/${file.name}`)
                    await fileRef.put(file)
                    const url = await fileRef.getDownloadURL()
                    console.log(url)       
                    edit({ ...dataQuestion, picture: url })
                    setDataQuestion({ ...dataQuestion, picture: url })
                    // Retorne a URL diretamente
                    message.success('Sucesso ao subir arquivo')
                  } catch (error) {
                    console.log(error)
                    message.error('Erro ao subir arquivo')
                  }
                }}
              >
                <Button icon={<UploadOutlined />}>Upload</Button>
              </Upload>
            </ContainerUpload>
          </div>
            {/* {dataQuestion?.description ? (
              <ContainerRequired>
                <p>Adicionar descrição</p>
                <Switch
                  defaultChecked
                  onChange={(check) => {
                    edit({ ...dataQuestion, description: check })
                    setDataQuestion({ ...dataQuestion, description: check })
                  }}
                />
              </ContainerRequired>
            ) : (
              <ContainerRequired>
                <p>Adicionar descrição</p>
                <Switch
                  onChange={(check) => {
                    edit({ ...dataQuestion, description: check })
                    setDataQuestion({ ...dataQuestion, description: check })
                  }}
                />
              </ContainerRequired>
            )} */}
            {/* {dataQuestion.description ? (
              <Input
                placeholder='Aqui vai a descrição da pergunta'
                value={
                  dataQuestion.description && typeof dataQuestion.description === 'string'
                    ? dataQuestion.description
                    : ''
                }
                onChange={(evt: any) => {
                  setDataQuestion({ ...dataQuestion, description: evt.target.value })
                  edit({ ...dataQuestion, description: evt.target.value })
                }}
              />
            ) : null} */}
            {dataQuestion?.type === 'DB_PRODUCTS' && (
              <>
                <p style={{marginTop: 10}}>Tipo de seleção</p>
                <Radio.Group 
                  options={[{label: 'Múltipla escolha', value: 'MULTIPLE'}, {label: 'Unica escolha', value: 'UNIQUE'}]} 
                  onChange={(evt) => edit({ ...dataQuestion, type_selection: evt.target.value })} 
                  defaultValue={dataQuestion.type_selection && dataQuestion.type_selection == 'UNIQUE' ? 'UNIQUE' : 'MULTIPLE'}
                />
              </>
            )}
            {dataQuestion?.type === 'UNIQUE_CHOICE' || dataQuestion?.type === 'MULTIPLE_CHOICE' ? (
              <>
                {!dataQuestion.options ? (
                  <ContainerDrop style={{ display: 'flex', alignItems: 'center' }}>
                    <Input
                      placeholder='Opção'
                      onBlur={(evt: any) => {
                        edit({ ...dataQuestion, options: [evt.target.value] })
                        setDataQuestion({
                          ...dataQuestion,
                          options: [evt.target.value],
                        })
                      }}
                      prefix={<UserOutlined className='site-form-item-icon' />}
                    />
                    <div style={{ marginLeft: 10 }}>
                      <Tooltip title='Adicionar a lista'>
                        <PlusCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} onClick={() => addOption()} />
                      </Tooltip>
                      {/* <Tooltip title='Remover da lista'>
                        <MinusCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} onClick={() => removeOption()} />
                      </Tooltip> */}
                    </div>
                  </ContainerDrop>
                ) : (
                  <>
                    {dataQuestion.options.map((option: any, index: any) => (
                      <ContainerDrop style={{ display: 'flex', alignItems: 'center' }}>
                        <Input
                          defaultValue={option}
                          onChange={(evt: any) => editIndex(evt.target.value, index)}
                          prefix={<UserOutlined className='site-form-item-icon' />}
                        />
                        <div style={{ marginLeft: 10 }}>
                          <Tooltip title='Adicionar a lista'>
                            <PlusCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} onClick={() => addOption()} />
                          </Tooltip>
                          {dataQuestion.options.length > 1 && (
                            <Tooltip title='Remover da lista'>
                              <MinusCircleOutlined
                                style={{ color: 'rgba(0,0,0,.45)' }}
                                onClick={() => removeOption(index)}
                              />
                            </Tooltip>
                          )}
                        </div>
                      </ContainerDrop>
                    ))}
                  </>
                )}
              </>
            ) : null}


            {dataQuestion?.type === 'MONEY' ? ( // defaultValue={0}
              // formatter={(value) => `R$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              // parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
              <ContainerPicture style={{ borderTop: 'none' }}>
                <div style={{ display: 'flex', marginBottom: '20px' }}>
                  <p>Valor máximo</p>
                  {!dataQuestion.maxMoney ? (
                    <InputNumber
                      onChange={(maxMoney) => {
                        let value = maxMoney
                        if (dataQuestion && dataQuestion.minMoney && value < dataQuestion.minMoney) {
                          message.error('O valor máximo não pode ser menor que o valor mínimo.')
                          return
                        }
                        edit({ ...dataQuestion, maxMoney: value })
                        setDataQuestion({
                          ...dataQuestion,
                          maxMoney: maxMoney,
                        })
                      }}
                      style={{ width: '60%' }}
                    />
                  ) : (
                    <InputNumber
                      defaultValue={dataQuestion.maxMoney}
                      formatter={(value) => `R$ ${value.toLocaleString()}`}
                      parser={(value: any) => parseFloat(value.replace(/\D/g, ''))}
                      onChange={(maxMoney) => {
                        console.log('aqui', maxMoney)
                        let value = maxMoney
                        if (dataQuestion && dataQuestion.minMoney && value < dataQuestion.minMoney) {
                          message.error('O valor máximo não pode ser menor que o valor mínimo.')
                          return
                        }
                        edit({ ...dataQuestion, maxMoney: value })
                        setDataQuestion({
                          ...dataQuestion,
                          maxMoney: maxMoney,
                        })
                      }}
                      style={{ width: '60%' }}
                    />
                  )}
                </div>
                <div style={{ display: 'flex' }}>
                  <p>Valor mínimo</p>
                  {!dataQuestion.minMoney ? (
                    <InputNumber
                      onChange={(minMoney) => {
                        let value = minMoney

                        if (dataQuestion && dataQuestion.maxMoney && value > dataQuestion.maxMoney) {
                          message.error('O valor mínimo não pode ser maior que o valor máximo.')
                          return
                        }
                        edit({ ...dataQuestion, minMoney: value })
                        setDataQuestion({
                          ...dataQuestion,
                          minMoney: minMoney,
                        })
                      }}
                      style={{ width: '60%' }}
                    />
                  ) : (
                    <InputNumber
                      defaultValue={dataQuestion.minMoney}
                      formatter={(value) => `R$ ${value.toLocaleString()}`}
                      parser={(value: any) => parseFloat(value.replace(/\D/g, ''))}
                      onChange={(minMoney) => {
                        let value = minMoney

                        if (dataQuestion && dataQuestion.maxMoney && value > dataQuestion.maxMoney) {
                          message.error('O valor mínimo não pode ser maior que o valor máximo.')
                          return
                        }
                        edit({ ...dataQuestion, minMoney: value })
                        setDataQuestion({
                          ...dataQuestion,
                          minMoney: minMoney,
                        })
                      }}
                      style={{ width: '60%' }}
                    />
                  )}
                </div>
              </ContainerPicture>
            ) : null}
            {dataQuestion?.type === 'PICTURE_CAPTURE' || dataQuestion?.type === 'GALLERY' ? (
              <ContainerPicture style={{ borderTop: 'none' }}>
                <div style={{ display: 'flex', marginBottom: '20px' }}>
                  <p>Quantidade máxima de fotos</p>
                  {
                    !dataQuestion.maxPicture ? (
                      <input
                        type='number'
                        style={{
                          border: '1px solid rgb(0,0,0,0.07)',
                          height: '38px',
                          width: '100%',
                          background: 'transparent',
                        }}
                        min={dataQuestion.minPicture ? dataQuestion.minPicture : 0}
                        onChange={(maxPicture) => {
                          let value = maxPicture.target.value

                          if (dataQuestion && dataQuestion.minPicture && value < dataQuestion.minPicture) {
                            message.error('O valor máximo de fotos não pode ser menos que o valor mínimo.')
                            return
                          }
                          edit({ ...dataQuestion, maxPicture: parseInt(maxPicture.target.value) })
                          setDataQuestion({
                            ...dataQuestion,
                            maxPicture: maxPicture.target.value,
                          })
                        }}
                      />
                    ) : (
                      // <select
                      //   name=''
                      //   id=''
                      //   style={{
                      //     border: '1px solid rgb(0,0,0,0.07)',
                      //     height: '38px',
                      //     width: '100%',
                      //     background: 'transparent',
                      //   }}
                      //   onChange={(maxPicture) => {
                      //     edit({ ...dataQuestion, maxPicture: parseInt(maxPicture.target.value) })
                      //     setDataQuestion({
                      //       ...dataQuestion,
                      //       maxPicture: maxPicture,
                      //     })
                      //   }}
                      // >
                      //   <option value='1'>1</option>
                      //   <option value='2'>2</option>
                      //   <option value='3'>3</option>
                      //   <option value='4'>4</option>
                      //   <option value='5'>5</option>
                      //   <option value='6'>6</option>
                      //   <option value='7'>7</option>
                      //   <option value='8'>8</option>
                      //   <option value='9'>9</option>
                      //   <option value='10'>10</option>
                      // </select>
                      <input
                        type='number'
                        defaultValue={dataQuestion.maxPicture}
                        style={{
                          border: '1px solid rgb(0,0,0,0.07)',
                          height: '38px',
                          width: '100%',
                          background: 'transparent',
                        }}
                        min={dataQuestion.minPicture ? dataQuestion.minPicture : 0}
                        onChange={(maxPicture) => {
                          let value = maxPicture.target.value

                          if (dataQuestion && dataQuestion.minPicture && value < dataQuestion.minPicture) {
                            message.error('O valor máximo de fotos não pode ser menos que o valor mínimo.')
                            return
                          }

                          edit({ ...dataQuestion, maxPicture: parseInt(maxPicture.target.value) })
                          setDataQuestion({
                            ...dataQuestion,
                            maxPicture: maxPicture.target.value,
                          })
                        }}
                      />
                    )
                    // <select
                    //   name=''
                    //   id=''
                    //   style={{
                    //     border: '1px solid rgb(0,0,0,0.07)',
                    //     height: '38px',
                    //     width: '100%',
                    //     background: 'transparent',
                    //   }}
                    //   defaultValue={dataQuestion.maxPicture}
                    //   onChange={(maxPicture) => {
                    //     edit({ ...dataQuestion, maxPicture: parseInt(maxPicture.target.value) })
                    //     setDataQuestion({
                    //       ...dataQuestion,
                    //       maxPicture: maxPicture,
                    //     })
                    //   }}
                    // >
                    //   <option value='1'>1</option>
                    //   <option value='2'>2</option>
                    //   <option value='3'>3</option>
                    //   <option value='4'>4</option>
                    //   <option value='5'>5</option>
                    //   <option value='6'>6</option>
                    //   <option value='7'>7</option>
                    //   <option value='8'>8</option>
                    //   <option value='9'>9</option>
                    //   <option value='10'>10</option>
                    // </select>
                  }
                </div>
                <div style={{ display: 'flex' }}>
                  <p>Quantidade mínima de fotos</p>
                  {!dataQuestion.minPicture ? (
                    <input
                      type='number'
                      style={{
                        border: '1px solid rgb(0,0,0,0.07)',
                        height: '38px',
                        width: '100%',
                        background: 'transparent',
                      }}
                      min={0}
                      max={dataQuestion.maxPicture ? dataQuestion.maxPicture : 0}
                      onChange={(minPicture) => {
                        let value = minPicture.target.value

                        if (dataQuestion && dataQuestion.maxPicture && value > dataQuestion.maxPicture) {
                          message.error('O valor máximo de fotos não pode ser menos que o valor mínimo.')
                          return
                        }

                        edit({ ...dataQuestion, minPicture: parseInt(minPicture.target.value) })
                        setDataQuestion({
                          ...dataQuestion,
                          minPicture: minPicture.target.value,
                        })
                      }}
                    />
                  ) : (
                    // <select
                    //   name=''
                    //   id=''
                    //   style={{
                    //     border: '1px solid rgb(0,0,0,0.07)',
                    //     height: '38px',
                    //     width: '100%',
                    //     background: 'transparent',
                    //   }}
                    //   onChange={(minPIcture) => {
                    //     edit({ ...dataQuestion, minPicture: parseInt(minPIcture.target.value) })
                    //     setDataQuestion({
                    //       ...dataQuestion,
                    //       minPicture: minPIcture,
                    //     })
                    //   }}
                    // >
                    //   <option value='1'>1</option>
                    //   <option value='2'>2</option>
                    //   <option value='3'>3</option>
                    //   <option value='4'>4</option>
                    //   <option value='5'>5</option>
                    //   <option value='6'>6</option>
                    //   <option value='7'>7</option>
                    //   <option value='8'>8</option>
                    //   <option value='9'>9</option>
                    //   <option value='10'>10</option>
                    // </select>
                    // <select
                    //   name=''
                    //   id=''
                    //   defaultValue={dataQuestion.minPicture}
                    //   style={{
                    //     border: '1px solid rgb(0,0,0,0.07)',
                    //     height: '38px',
                    //     width: '100%',
                    //     background: 'transparent',
                    //   }}
                    //   onChange={(minPIcture) => {
                    //     edit({ ...dataQuestion, minPicture: parseInt(minPIcture.target.value) })
                    //     setDataQuestion({
                    //       ...dataQuestion,
                    //       minPicture: minPIcture,
                    //     })
                    //   }}
                    // >
                    //   <option value='1'>1</option>
                    //   <option value='2'>2</option>
                    //   <option value='3'>3</option>
                    //   <option value='4'>4</option>
                    //   <option value='5'>5</option>
                    //   <option value='6'>6</option>
                    //   <option value='7'>7</option>
                    //   <option value='8'>8</option>
                    //   <option value='9'>9</option>
                    //   <option value='10'>10</option>
                    // </select>
                    <input
                      type='number'
                      defaultValue={dataQuestion.minPicture}
                      style={{
                        border: '1px solid rgb(0,0,0,0.07)',
                        height: '38px',
                        width: '100%',
                        background: 'transparent',
                      }}
                      min={0}
                      max={dataQuestion.maxPicture ? dataQuestion.maxPicture : 0}
                      onChange={(minPicture) => {
                        let value = minPicture.target.value

                        if (dataQuestion && dataQuestion.maxPicture && value > dataQuestion.maxPicture) {
                          message.error('O valor máximo de fotos não pode ser menos que o valor mínimo.')
                          return
                        }

                        edit({ ...dataQuestion, minPicture: parseInt(minPicture.target.value) })
                        setDataQuestion({
                          ...dataQuestion,
                          minPicture: minPicture.target.value,
                        })
                      }}
                    />
                  )}
                </div>
              </ContainerPicture>
            ) : null}
            {dataQuestion?.type === 'NUMBER_INTEGER' || dataQuestion?.type === 'NUMBER_DECIMAL' ? (
              <ContainerPicture style={{ borderTop: 'none' }}>
                <div>
                  <p>Valor máximo:</p>
                  {!dataQuestion.maxNumber ? (
                    <InputNumber
                      type='number'
                      onChange={(maxNumber) => {
                        let value = maxNumber

                        if (dataQuestion && dataQuestion.minNumber && value < dataQuestion.minNumber) {
                          message.error('O valor máximo não pode ser menor que o valor mínimo.')
                          return
                        }
                        edit({ ...dataQuestion, maxNumber: value })
                        setDataQuestion({
                          ...dataQuestion,
                          maxNumber: maxNumber,
                        })
                      }}
                      style={{ width: '100%' }}
                    />
                  ) : (
                    <InputNumber
                      type='number'
                      defaultValue={dataQuestion.maxNumber}
                      onChange={(maxNumber) => {
                        let value = maxNumber

                        if (dataQuestion && dataQuestion.minNumber && value < dataQuestion.minNumber) {
                          message.error('O valor máximo não pode ser menor que o valor mínimo.')
                          return
                        }
                        edit({ ...dataQuestion, maxNumber: value })
                        setDataQuestion({
                          ...dataQuestion,
                          maxNumber: maxNumber,
                        })
                      }}
                      style={{ width: '100%' }}
                    />
                  )}
                </div>
                <div>
                  <p>Valor mínimo:</p>
                  {!dataQuestion.minNumber ? (
                    <InputNumber
                      type='number'
                      onChange={(minNumber) => {
                        let value = minNumber

                        if (dataQuestion && dataQuestion.maxNumber && value > dataQuestion.maxNumber) {
                          message.error('O valor mínimo não pode ser maior que o valor máximo.')
                          return
                        }
                        edit({ ...dataQuestion, minNumber: value })
                        setDataQuestion({
                          ...dataQuestion,
                          minNumber: minNumber,
                        })
                      }}
                      style={{ width: '100%' }}
                    />
                  ) : (
                    <InputNumber
                      type='number'
                      defaultValue={dataQuestion.minNumber}
                      onChange={(minNumber) => {
                        let value = minNumber

                        if (dataQuestion && dataQuestion.maxNumber && value > dataQuestion.maxNumber) {
                          message.error('O valor mínimo não pode ser maior que o valor máximo.')
                          return
                        }
                        edit({ ...dataQuestion, minNumber: value })
                        setDataQuestion({
                          ...dataQuestion,
                          minNumber: minNumber,
                        })
                      }}
                      style={{ width: '100%' }}
                    />
                  )}
                </div>
              </ContainerPicture>
            ) : null}
          </Body>
        </ContainerConfig>
      ) : null}
    </>
  )
}

export default ConfigQuestion
