import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { CardUpload } from '../components/CardUpload'
import { hasIndex } from '../components/Menu/menuHorizonte'
import { ModalSearch } from '../components/ModalSearch'
import { ContextUpload } from '../context/contextUpload'
import api_v2 from '../services/api-v2'
import { getDataUser, logout } from '../utils/login'
import { Mixpanel } from '../utils/mixpanel'
import history from './history'

export const allPermissions = [
  'status_day.list_status_day',
  'home.filter',
  'home.add_metric',
  'home.attendance',
  'home.assiduity',
  'home.tasks',
  'home.team_on_off',
  'team.promoter',
  'team.add_promoter',
  'team.import_promoter',
  'team.import_visit_promoter',
  'team.import_script_promoter',
  'team.export_promoter',
  'team.export_script_promoter',
  'team.change_visit_status',
  'team.change_visit_date',
  'team.remove_visit_date',
  'team.add_task',
  'team.remove_task',
  'team.reset_task',
  'group.profile',
  'group.add_profile',
  'group.change_profile',
  'catalog.brand',
  'catalog.add_brand',
  'catalog.change_brand',
  'catalog.remove_brand',
  'catalog.import_brand',
  'catalog.export_brand',
  'catalog.category',
  'catalog.add_category',
  'catalog.change_category',
  'catalog.remove_category',
  'catalog.product',
  'catalog.add_product',
  'catalog.change_product',
  'catalog.remove_product',
  'catalog.import_product',
  'catalog.export_product',
  'catalog.product_mix',
  'catalog.add_product_mix',
  'catalog.change_product_mix',
  'catalog.remove_product_mix',
  'catalog.import_product_mix',
  'catalog.export_product_mix',
  'store.regional',
  'store.add_regional',
  'store.change_regional',
  'store.remove_regional',
  'store.import_regional',
  'store.export_regional',
  'store.network',
  'store.add_network',
  'store.change_network',
  'store.remove_network',
  'store.import_network',
  'store.export_network',
  'store.flag',
  'store.add_flag',
  'store.change_flag',
  'store.remove_flag',
  'store.import_flag',
  'store.export_flag',
  'store.pdv',
  'store.add_pdv',
  'store.change_pdv',
  'store.remove_pdv',
  'store.import_pdv',
  'store.export_pdv',
  'justify.list_justify',
  'justify.add_justify',
  'justify.change_justify',
  'justify.remove_justify',
  'training.list_training',
  'training.add_training',
  'training.change_training',
  'training.remove_training',
  'form.list_form',
  'form.add_form',
  'form.change_form',
  'form.toggle_form',
  'form.add_schedule_form',
  'form.change_schedule_form',
  'form.remove_form_mix',
  'form.import_form_mix',
  'form.export_form_mix',
  'task.list_task',
  'task.export_task',
  'visit.list_visit',
  'visit.add_visit_survey',
  'visit.export_visit',
  'survey.list_survey',
  'justify_report.list_justify_report',
  'justify_report.homolog_justify_report',
  'pictures.list_pictures',
  'pictures.export_pictures_ppt',
  'before_afer.list_before_afer',
  'kanban.list_kanban',
  'plan_exec.list_plan_exec',
  'plan_exec.list_plan_exec',
  'process.list_process_import',
  'process.download_process_import',
  'dash.list_dash',
  'dash.invite_user',
  'time_and_move.view',
  'attendance.view',
  'justify.view',
  'visits_panel.view',
  'time_compare.view',
]

export const notAccess = (permission: any, pathname: string) => {
  if (
    (pathname.indexOf('backoffice/team/users/promoter') > -1 || pathname.indexOf('backoffice/team/users') > -1) &&
    hasIndex(
      [
        'Ver Promotores',
        'team.promoter',
        'Adicionar Promotor',
        'team.add_promoter',
        'Importar Promotor XLSX',
        'team.import_promoter',
        'Importar Visita agendada XLSX',
        'team.import_visit_promoter',
        'Importar Roteiro XLSX',
        'team.import_script_promoter',
        'Exportar Promotores XLSX',
        'team.export_promoter',
        'Exportar Roteiro XLSX',
        'team.export_script_promoter',
        'Alterar status de visita',
        'team.change_visit_status',
        'Alterar data de visita',
        'team.change_visit_date',
        'Remover visita',
        'team.remove_visit_date',
        'Adicionar tarefa',
        'team.add_task',
        'Remover tarefa',
        'team.remove_task',
        'Resetar tarefa',
        'team.reset_task',
      ],
      permission
    ) === -1
  ) {
    return -2
  } else if (
    pathname.indexOf('backoffice/team/profiles') > -1 &&
    hasIndex(
      ['Ver Perfis', 'group.profile', 'Adicionar perfis', 'group.add_profile', 'Editar perfis', 'group.change_profile'],
      permission
    ) === -1
  ) {
    return -2
  } else if (
    pathname.indexOf('backoffice/catalog/brands') > -1 &&
    hasIndex(
      [
        'Visualizar marcas',
        'catalog.brand',
        'Adicionar marcas',
        'catalog.add_brand',
        'Editar marca',
        'catalog.change_brand',
        'Remover marcas',
        'catalog.remove_brand',
        'Importar marcas XLSX',
        'catalog.import_brand',
        'Exportar marcas XLSX',
        'catalog.export_brand',
      ],
      permission
    ) === -1
  ) {
    return -2
  } else if (
    pathname.indexOf('backoffice/catalog/categories') > -1 &&
    hasIndex(
      [
        'Visualizar categorias',
        'catalog.category',
        'Adicionar categorias',
        'catalog.add_category',
        'Editar categoria',
        'catalog.change_category',
        'Remover categorias',
        'catalog.remove_category',
      ],
      permission
    ) === -1
  ) {
    return -2
  } else if (
    pathname.indexOf('backoffice/catalog/products') > -1 &&
    hasIndex(
      [
        'Visualizar produtos',
        'catalog.product',
        'Adicionar produtos',
        'catalog.add_product',
        'Editar produto',
        'catalog.change_product',
        'Remover produtos',
        'catalog.remove_product',
        'Importar produtos XLSX',
        'catalog.import_product',
        'Exportar produtos XLSX',
        'catalog.export_product',
      ],
      permission
    ) === -1
  ) {
    return -2
  } else if (
    pathname.indexOf('backoffice/catalog/mix-products') > -1 &&
    hasIndex(
      [
        'Visualizar Mix produtos',
        'catalog.product_mix',
        'Adicionar Mix produtos',
        'catalog.add_product_mix',
        'Editar Mix produto',
        'catalog.change_product_mix',
        'Remover Mix produtos',
        'catalog.remove_product_mix',
        'Importar Mix produtos XLSX',
        'catalog.import_product_mix',
        'Exportar Mix produtos XLSX',
        'catalog.export_product_mix',
      ],
      permission
    ) === -1
  ) {
    return -2
  } else if (
    pathname.indexOf('backoffice/store/regionals') > -1 &&
    hasIndex(
      [
        'Visualizar regionais',
        'store.regional',
        'Adicionar regionais',
        'store.add_regional',
        'Editar regionais',
        'store.change_regional',
        'Remover regionais',
        'store.remove_regional',
        'Importar regionais XLSX',
        'store.import_regional',
        'Exportar regionais XLSX',
        'store.export_regional',
      ],
      permission
    ) === -1
  ) {
    return -2
  } else if (
    pathname.indexOf('backoffice/store/networks') > -1 &&
    hasIndex(
      [
        'Visualizar redes',
        'store.network',
        'Adicionar redes',
        'store.add_network',
        'Editar rede',
        'store.change_network',
        'Remover redes',
        'store.remove_network',
        'Importar redes XLSX',
        'store.import_network',
        'Exportar redes XLSX',
        'store.export_network',
      ],
      permission
    ) === -1
  ) {
    return -2
  } else if (
    pathname.indexOf('backoffice/store/flags') > -1 &&
    hasIndex(
      [
        'Visualizar bandeiras',
        'store.flag',
        'Adicionar bandeiras',
        'store.add_flag',
        'Editar bandeira',
        'store.change_flag',
        'Remover bandeiras',
        'store.remove_flag',
        'Importar bandeiras XLSX',
        'store.import_flag',
        'Exportar bandeiras XLSX',
        'store.export_flag',
      ],
      permission
    ) === -1
  ) {
    return -2
  } else if (
    pathname.indexOf('backoffice/store/pdvs') > -1 &&
    hasIndex(
      [
        'Visualizar pdvs',
        'store.pdv',
        'Adicionar pdvs',
        'store.add_pdv',
        'Editar pdv',
        'store.change_pdv',
        'Remover pdvs',
        'store.remove_pdv',
        'Importar pdvs XLSX',
        'store.import_pdv',
        'Exportar pdvs XLSX',
        'store.export_pdv',
      ],
      permission
    ) === -1
  ) {
    return -2
  } else if (
    pathname.indexOf('backoffice/register/justification') > -1 &&
    hasIndex(
      [
        'Visualizar justify',
        'justify.list_justify',
        'Adicionar justify',
        'justify.add_justify',
        'Editar justify',
        'justify.change_justify',
        'Remover justify',
        'justify.remove_justify',
      ],
      permission
    ) === -1
  ) {
    return -2
  } else if (
    pathname.indexOf('backoffice/register/training') > -1 &&
    hasIndex(
      [
        'Visualizar treinamento',
        'training.list_training',
        'Adicionar treinamento',
        'training.add_training',
        'Editar treinamento',
        'training.change_training',
        'Remover treinamento',
        'training.remove_training',
      ],
      permission
    ) === -1
  ) {
    return -2
  } else if (
    pathname.indexOf('backoffice/survey/forms') > -1 &&
    hasIndex(
      [
        'Visualizar formulário',
        'form.list_form',
        'Adicionar formulário',
        'form.add_form',
        'Editar formulário',
        'form.change_form',
        'Ativar/Inativar formulário',
        'form.toggle_form',
        'Adicionar agendamento de formulário',
        'form.add_schedule_form',
        'Editar agendamento de formulário',
        'form.change_schedule_form',
        'Remover mix de formulário XLSX',
        'form.remove_form_mix',
        'Importar mix de formulário XLSX',
        'form.import_form_mix',
        'Exportar mix de formulário XLSX',
        'form.export_form_mix',
      ],
      permission
    ) === -1
  ) {
    return -2
  } else if (
    pathname.indexOf('backoffice/survey/tasks') > -1 &&
    hasIndex(
      ['Visualizar atividades', 'task.list_task', 'Exportar atividades XLSX', 'task.export_task'],
      permission
    ) === -1
  ) {
    return -2
  } else if (
    pathname.indexOf('backoffice/survey/visits') > -1 &&
    hasIndex(
      [
        'Visualizar visitas',
        'visit.list_visit',
        'Reportar pesquisa',
        'visit.add_visit_survey',
        'Exportar visitas XLSX',
        'visit.export_visit',
      ],
      permission
    ) === -1
  ) {
    return -2
  } else if (
    pathname.indexOf('backoffice/reports/search') > -1 &&
    hasIndex(['Visualizar Pesquisas', 'survey.list_survey'], permission) === -1
  ) {
    return -2
  } else if (
    pathname.indexOf('backoffice/reports/justifyVisits') > -1 &&
    hasIndex(
      [
        'Visualizar Visitas Justificadas',
        'justify_report.list_justify_report',
        'Homologar Visitas Justificadas',
        'justify_report.homolog_justify_report',
      ],
      permission
    ) === -1
  ) {
    return -2
  } else if (
    pathname.indexOf('backoffice/reports/pictures') > -1 &&
    hasIndex(
      ['Visualizar Fotos', 'pictures.list_pictures', 'Exportar Fotos (PPT)', 'pictures.export_pictures_ppt'],
      permission
    ) === -1
  ) {
    return -2
  } else if (
    pathname.indexOf('backoffice/monitory/pictureLastAndAfter') > -1 &&
    hasIndex(['Visualizar Antes x Depois', 'before_afer.list_before_afer'], permission) === -1
  ) {
    return -2
  } else if (
    pathname.indexOf('backoffice/monitory/monitoring') > -1 &&
    hasIndex(['Visualizar Kanban', 'kanban.list_kanban'], permission) === -1
  ) {
    return -2
  } else if (
    pathname.indexOf('backoffice/monitory/planned') > -1 &&
    hasIndex(['Visualizar Planejado x Realizado', 'plan_exec.list_plan_exec'], permission) === -1
  ) {
    return -2
  } else if (
    pathname.indexOf('backoffice/process') > -1 &&
    hasIndex(
      [
        'Visualizar Importações XLSX',
        'process.list_process_import',
        'Baixar arquivos importados/com erros',
        'process.download_process_import',
      ],
      permission
    ) === -1
  ) {
    return -2
  } else if (
    pathname.indexOf('Analitycs/move') > -1 &&
    hasIndex(['Visualizar Grafícos', 'time_and_move.view'], permission) === -1
  ) {
    return -2
  } else if (
    pathname.indexOf('Analitycs/team/detail') > -1 &&
    hasIndex(['Visualizar Grafícos', 'attendance.view'], permission) === -1
  ) {
    return -2
  } else if (
    pathname.indexOf('Analitycs/justify') > -1 &&
    hasIndex(['Visualizar Grafícos', 'justify.view'], permission) === -1
  ) {
    return -2
  } else if (
    pathname.indexOf('Analitycs/visits') > -1 &&
    hasIndex(['Visualizar Tabela', 'visits_panel.view'], permission) === -1
  ) {
    return -2
  } else if (
    pathname.indexOf('Analitycs/timeComparate') > -1 &&
    hasIndex(['Visualizar Gráficos', 'time_compare.view'], permission) === -1
  ) {
    return -2
  }
}

export default function ScrollToTop({ children }: any) {
  const { pathname } = useLocation()
  const { setPath, setPermissions } = ContextUpload()

  const getWork = async () => {
    try {
      const { data } = await api_v2.get('/user/me')

      // let permissions = data.profile.profile_permissions
      // setPermissions(permissions ? permissions : [])

      // let block = notAccess(permissions ? permissions : [], pathname)

      // if (block === -2) return history.push('/notAcces')

      if (data.workspace_block) {
        return history.push('/block')
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    setPermissions(allPermissions)
    getWork()

    const dataUser = getDataUser()
    if (dataUser && dataUser.id) {
      Mixpanel.people.set({
        $email: dataUser.email,
        $cpf: dataUser.cpf,
        $type: dataUser.type,
        $name: dataUser.name,
      })
      Mixpanel.track(pathname, {})
    } else {
      logout()
    }

    let body = document.getElementById('root')
    if (body) body.scroll(0, 0)
    setPath(pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return (
    <>
      <ModalSearch />
      <CardUpload />
      {children}
    </>
  )
}
