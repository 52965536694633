import React, { useEffect, useState } from 'react'
import LayoutWorkspace from '../../components/LayoutWorkspace'
import history from '../../routes/history'
import { Mixpanel } from '../../utils/mixpanel'
import Backoffice from '../../assets/images/backofficeIcon.png'
import {
  Container,
  WorkspaceList,
  WorkspaceLogo,
  WorkspaceName,
  WorkspaceOption,
  Wrapper,
  Header,
  ContainerFooter,
} from './styles'
import Rocket from '../../assets/images/Rock-At.png'
import Analitycs from '../../assets/images/Analytics11.svg'
import PDVManager from '../../assets/images/PDV.svg'
import Book from '../../assets/apps/book.svg'
import Supervisor from '../../assets/images/supervisor10.svg'
import Pipiline from '../../assets/images/pipeline.svg'
import Incentive from '../../assets/images/incentive2.svg'
import { APP_ROCKET, getToken, getWorkspace, getWorkspaceInfo } from '../../utils/login'
import { ModalMenu } from '../../components/ModalMenu'
import api_v2 from '../../services/api-v2'
import { Loading } from '../../components/Loading'
import { SiSimpleanalytics } from 'react-icons/si'

const AppsPage: React.FC = () => {
  const [listDisabled, setListDisabled] = useState<string[]>([])
  const [visibleModal, setVisibleModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [type, setType] = useState('')

  function handleSelect(team: any, disabled: boolean) {
    if (disabled === true) {
      setType(
        team === 'Analitycs'
          ? 'analytics'
          : team === 'PDV Manager'
          ? 'pdv maneger'
          : team === 'Supervisor'
          ? 'supervisor'
          : team === 'Pipiline'
          ? 'pipeline'
          : 'incentive'
      )
      setVisibleModal(true)
      return
    }
    sessionStorage.removeItem('filterAnalytcs')
    localStorage.setItem(APP_ROCKET, JSON.stringify(team))
    Mixpanel.track('Apps', { apps: team.name })

    // history.push('/backoffice/')
    history.push(`/${team}/`)
  }

  const getList = async () => {
    try {
      setLoading(true)
      let { data } = await api_v2.get('/search/me')
      setListDisabled(data.apps)
      setLoading(false)
    } catch (error) {
      setLoading(true)
      console.log(error)
    }
  }

  useEffect(() => {
    getList()
  }, [])

  return (
    <LayoutWorkspace>
      <Container>
        {loading && <Loading />}
        <Header>
          <Wrapper>
            <h1>App center</h1>
            <p>Selecione um aplicativo a baixo.</p>
          </Wrapper>
        </Header>
        <WorkspaceList>
          <WorkspaceOption
            onClick={() => handleSelect('Backoffice', listDisabled.indexOf('BACKOFFICE') > -1 ? false : true)}
            disabled={listDisabled.indexOf('BACKOFFICE') > -1 ? false : true}
          >
            <WorkspaceLogo src={Backoffice} alt='Backoffice' />
            <WorkspaceName>Backoffice</WorkspaceName>
          </WorkspaceOption>
          <WorkspaceOption
            onClick={() => handleSelect('Analitycs', listDisabled.indexOf('ANALYTICS') > -1 ? false : true)}
            disabled={listDisabled.indexOf('ANALYTICS') > -1 ? false : true}
          >
            <WorkspaceLogo src={Analitycs} alt='Analitycs' />
            <WorkspaceName>Analytics</WorkspaceName>
          </WorkspaceOption>
          <WorkspaceOption
            onClick={async () => {
              setLoading(true)
              let { data } = await api_v2.get('/search/me')
              const workspace: any = getWorkspaceInfo()

              window.open(
                `https://book.rock-at.com/loading?token=${getToken()}&user=${JSON.stringify({
                  "id": workspace.id,
                  "name": workspace.name,
                  "name_user": data.name,
                  "user_id": workspace.user_id,
                  "provider": "PG",
                  "slug": workspace.slug,
                  "shared_environment": workspace.shared_environment,
                  "is_active": workspace.is_active,
                  "client": data?.config?.is_client || false,
                  "sub_workspaces": data?.config?.sub_workspaces || false,
              })}`,
                '_blank'
                )
              setLoading(false)
            }}
            disabled={(listDisabled.indexOf('BACKOFFICE') > -1 || listDisabled.indexOf('BOOK') > -1) ? false : true}
          >
            <WorkspaceLogo src={Book} alt='Book' />
            <WorkspaceName>Book</WorkspaceName>
          </WorkspaceOption>
          {getWorkspace() &&
            (getWorkspace() === 'compartilhado-br' ||
              getWorkspace() === 'ajinomoto' ||
              getWorkspace() === 'aurora-premium' ||
              getWorkspace() === 'bonare' ||
              getWorkspace() === 'da-terrinha' ||
              getWorkspace() === 'diageo' ||
              getWorkspace() === 'enova-foods' ||
              getWorkspace() === 'freixenet' ||
              getWorkspace() === 'gtex' ||
              getWorkspace() === 'italac' ||
              getWorkspace() === 'itamaraty' ||
              getWorkspace() === 'melitta' ||
              getWorkspace() === 'rh-pdv-quality' ||
              getWorkspace() === 'softys' ||
              getWorkspace() === 'urbano' ||
              getWorkspace() === 'vigor' ||
              getWorkspace() === 'damapel' ||
              getWorkspace() === 'bauducco') && (
              <WorkspaceOption
                onClick={() => {
                  window.open(
                    `https://pdvquality.rock-at.com/loading?token=${getToken()}&user=${JSON.stringify(
                      getWorkspaceInfo()
                    )}`,
                    '_blank'
                  )
                }}
                disabled={listDisabled.indexOf('BACKOFFICE') > -1 ? false : true}
              >
                <SiSimpleanalytics color='#7158C1' fontSize='45px' style={{ marginBottom: 20 }} />
                <WorkspaceName>Nativo</WorkspaceName>
              </WorkspaceOption>
            )}
          {/* <WorkspaceOption onClick={() => handleSelect('PDV Manager', true)} disabled={true}>
            <WorkspaceLogo src={PDVManager} alt='PDV Manager' />
            <WorkspaceName>PDV Manager</WorkspaceName>
          </WorkspaceOption>
          <WorkspaceOption onClick={() => handleSelect('Supervisor', true)} disabled={true}>
            <WorkspaceLogo src={Supervisor} alt='Supervisor' />
            <WorkspaceName>Supervisor</WorkspaceName>
          </WorkspaceOption>
          <WorkspaceOption onClick={() => handleSelect('Pipiline', true)} disabled={true}>
            <WorkspaceLogo src={Pipiline} alt='Pipiline' />
            <WorkspaceName>Pipeline</WorkspaceName>
          </WorkspaceOption>
          <WorkspaceOption onClick={() => handleSelect('Incentive', true)} disabled={true}>
            <WorkspaceLogo src={Incentive} alt='Incentive' />
            <WorkspaceName>Incentive</WorkspaceName>
          </WorkspaceOption> */}
        </WorkspaceList>

        <ContainerFooter>
          <p>desenvolvido por</p>
          <img src={Rocket} alt='user' />
        </ContainerFooter>
      </Container>
      <ModalMenu visible={visibleModal} close={setVisibleModal} type={type} />
    </LayoutWorkspace>
  )
}

export default AppsPage
