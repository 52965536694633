import { Alert, Select } from 'antd'
import { TextHeader } from '../TabGeneralUser/style'
import {
  BodyCalendar,
  ContainerDays,
  ContainerScript,
  Content,
  CountScripts,
  DaysWeeK,
  HeaderCalendar,
  HeaderScript,
} from './style'
import moment from 'moment'
import { useEffect, useState } from 'react'
import 'moment/locale/pt-br'
import { CardCalendar } from '../CardCalendar'
import { dayOptions, OptionsFreq } from '../../utils/option-user'
import { Loading } from '../Loading'
import { useParams } from 'react-router'
import api from '../../services/api'
import qs from 'querystring'

export const TabsScript = () => {
  const { id }: any = useParams()
  const [lists, setLists] = useState<any>([])
  const [copyScript, setCopyScript] = useState<any>({})
  const [lists2, setLists2] = useState<any>()
  const [totalVisits, setTotalVisist] = useState(0)
  const [loading, setLoading] = useState<any>()
  const [{ newArr, label }, setNewArr] = useState<any>({
    newArr: [],
  })

  async function loadLists() {
    setLoading(true)
    try {
      const query = qs.stringify({ user: id })
      const { data } = await api.get(`scripts?` + query)

      const ListamFrequency = data.result.map((item: any) => {
        return item.frequency
      })
      getOptions(ListamFrequency.reverse()[0])
      setLists2(ListamFrequency.reverse()[0])
      setLists(data.result)
      setTotalVisist(data.total)
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  const getOptions = (value: any) => {
    console.log(value)
    if (!value.index) {
      const newOp = OptionsFreq.map((item: any) => {
        if (item.value === value) {
          return item
        }
        return null
      })
      let filteredNew = newOp.filter(function (el: null) {
        return el !== null
      })

      value = filteredNew[0]
    }
    if (value.index === 1) {
      setNewArr({
        newArr: [1],
        label: value.value,
      })
    } else if (value.index === 2) {
      setNewArr({
        newArr: [1, 2],
        label: value.value,
      })
    } else if (value.index === 4) {
      setNewArr({
        newArr: [1, 2, 3, 4],
        label: value.value,
      })
    }
    setLists2(value.value)
  }

  async function getFrequency(value: any) {
    getOptions(value)
  }

  function listagn() {
    if (lists2) {
      const newOp = OptionsFreq.map((item: any) => {
        if (item.value === lists2) {
          return item
        }
        return null
      })
      let filteredNew = newOp.filter(function (el: null) {
        return el != null
      })

      getFrequency(filteredNew[0])
    } else {
      getFrequency({ value: 'WEEKLY', label: 'Semanal', index: 1 })
    }
  }
  useEffect(() => {
    listagn()
    loadLists()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {loading && <Loading />}
      <Alert
        message={`As visitas do dia atual (${moment().format(
          'DD/MM/YYYY'
        )}), não serão cadastradas caso o roteiro seja criado após ao horário de corte definido nas configurações do ambiente (16hrs). `}
        type='warning'
        showIcon
        style={{ marginBottom: 10, textAlign: 'center' }}
      />
      <ContainerScript>
        <HeaderScript>
          <TextHeader>Roteiros do Mês</TextHeader>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Select
              className='ant-select'
              value={lists2}
              placeholder='Informe a periocidade'
              options={[
                {
                  value: 'WEEKLY',
                  label: 'Semanal',
                },
                {
                  value: 'FORTNIGHTLY',
                  label: 'Quinzenal',
                },
                {
                  value: 'MONTHLY',
                  label: 'Mensal',
                },
              ]}
              onChange={getOptions}
            />
            <CountScripts>{totalVisits}</CountScripts>
          </div>
        </HeaderScript>
        <BodyCalendar>
          <HeaderCalendar>
            {moment(new Date()).locale('pt-br').format('MMMM')} {moment(new Date()).locale('pt-br').format('YYYY')}
          </HeaderCalendar>

          <>
            {loading ? (
              <Loading />
            ) : (
              <>
                {newArr.map((index: any, week: number) => (
                  <>
                    {lists2 === 'MONTHLY' && (
                      <p style={{ fontSize: 18, fontWeight: 'bold' }}>
                        {week === 0 && 'Primeira semana'}
                        {week === 1 && 'Segunda semana'}
                        {week === 2 && 'Terceira semana'}
                        {week === 3 && 'Quarta semana'}
                      </p>
                    )}

                    {week === 0 && (
                      <Content>
                        <ContainerDays>
                          <DaysWeeK>Domingo</DaysWeeK>
                        </ContainerDays>
                        <ContainerDays>
                          <DaysWeeK>Segunda</DaysWeeK>
                        </ContainerDays>
                        <ContainerDays>
                          <DaysWeeK>Terça</DaysWeeK>
                        </ContainerDays>
                        <ContainerDays>
                          <DaysWeeK>Quarta</DaysWeeK>
                        </ContainerDays>
                        <ContainerDays>
                          <DaysWeeK>Quinta</DaysWeeK>
                        </ContainerDays>
                        <ContainerDays>
                          <DaysWeeK>Sexta</DaysWeeK>
                        </ContainerDays>
                        <ContainerDays>
                          <DaysWeeK>Sábado</DaysWeeK>
                        </ContainerDays>
                      </Content>
                    )}

                    <Content>
                      {dayOptions.map((item: any, day: any) => (
                        <CardCalendar
                          key={day}
                          semana={week}
                          dia={day}
                          list={lists}
                          frequency={label}
                          setterList={setLists}
                          setterList2={setLists2}
                          setLoanding={(value: any) => setLoading(value)}
                          setCopyScript={setCopyScript}
                          copyScript={copyScript}
                        />
                      ))}
                    </Content>
                  </>
                ))}
              </>
            )}
          </>
        </BodyCalendar>
      </ContainerScript>
    </>
  )
}
