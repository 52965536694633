import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;

  &&& .ant-row {
    width: 100%;
  }
`

export const ContainerPaginationHeader = styled.div`
  display: flex;

  p {
    color: #6f7782;
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    margin: 0 10px 0 0;
  }

  &&& .ant-select {
    width: 70px;
  }

  &&& .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: #6f7782 1px solid;
    border-radius: 7px;
  }

  &&& .ant-select-selection-item {
    color: #6f7782;
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 400;
  }

  &&& .ant-select-arrow {
    font-size: 16px;
    color: #6f7782;
  }
`

export const ContainerActions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 800px;
`

export const ContainerSearch = styled.div`
  border-radius: 5px;
  width: 100%;
  max-width: 300px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #eeddfe;
  font-size: 14px;
  color: black;
  padding-right: 10px;
  @media (max-width: 762px) {
    max-width: 100%;
  }
`
export const Search = styled.input`
  height: 100%;
  width: 100%;
  border: none;
  background-color: #eeddfe;
  outline: none;
  border-radius: 5px;
  padding-left: 5px;
  font-size: 14px;
`
export const BigButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6f7782;
  background-color: #fff;
  height: 38px;
  border-radius: 5px;
  border: 1px solid #6f7782;
  background-color: #f9f9f9;
  font-size: 14px;
  font-weight: 400;
  padding: 8px;

`

export const ContainerCards = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

export const ContainerPaginationFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;

  &&& .ant-pagination-item-active {
    border-color: #b56aff !important;
    width: 40px !important;
    height: 40px !important;
    background-color: #b56aff;
  }
  &&& .ant-pagination-item-active a {
    color: #fff !important;
    margin-top: 3px;
  }

  &&& .ant-pagination-item {
    width: 40px !important;
    height: 40px !important;
  }

  &&& .ant-pagination-item a {
    margin-top: 3px;
    color: #6f7782;
  }
`
export const ContainerFilter = styled.div`
    margin-top: 20px;
    max-width: 100%;
    height: 100%;
    /* border: 2px solid rgb(113, 88, 193); */
    /* padding: 20px; */

  &&& .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 12px;
    border: 1px solid #d6d6d6;
    padding:5px;
  }

`

export const LabelForm = styled.p`
    color: #6f7782;
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    margin: 0 10px 0 0;
`

export const ButtonSearch = styled.button`
  padding: 8px;
  color: #fff;
  background-color: #b56aff;
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48%;
  svg {
    margin-right: 5px;
  }

  :hover {
    transition: all 0.3s;
    opacity: 0.6;
  }
`

export const ButtonClear = styled.button`
  padding: 8px;
  color: #b56aff;
  background-color: #fff;
  border: 1px solid #b56aff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48%;
  svg {
    margin-right: 5px;
  }

  :hover {
    transition: all 0.3s;
    background-color: #b56aff;
    color: #fff;
  }
`

export const BigButtonExport = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6f7782;
  background-color: #fff;
  height: 38px;
  border-radius: 5px;
  border: 1px solid #6f7782;
  background-color: #f9f9f9;
  font-size: 14px;
  font-weight: 400;
  padding: 8px;

  svg {
    margin-right: 10px;
  }
`

export const Title = styled.p`
    background-color: #fff;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    width: 100%;
`
