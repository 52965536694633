import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  border-radius: 12px;
  border: 1px solid #d6d6d6;
  background-color: #f9f9f9;
  padding: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const TextH3 = styled.h3`
  width: 100%;
  text-align: center;
  font-size: 19px;
  color: rgb(118 118 118 / 85%);
  text-transform: bold;
`

export const PersonDivider = styled.div`
  height: 100%;
  width: 2px;
  border-left: 1px solid rgba(0, 0, 0, 0.3);
`

export const TextH4 = styled.h4`
  width: 100%;
  text-align: center;
  font-size: 17px;
  color: rgb(118 118 118 / 85%);
  text-transform: bold;
`
