import { Skeleton, Popover, Button, Modal, message } from 'antd'
import React, { useEffect, useState } from 'react'
import LayoutWorkspace from '../../components/LayoutWorkspace'
import history from '../../routes/history'
import api from '../../services/api'
import { Loading } from '../../components/Loading'
import { Mixpanel } from '../../utils/mixpanel'
import {
  Container,
  WorkspaceList,
  WorkspaceLogo,
  WorkspaceName,
  WorkspaceOption,
  Wrapper,
  WrapperAction,
  Header,
  ContainerSearch,
  Search,
  BigButton,
  ContainerOptions,
  ContainerFooter,
} from './styles'
import {
  QuestionCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import { MdSearch, MdAdd, MdFilterList } from 'react-icons/md'
import Rocket from '../../assets/images/Rock-At.png'
import api_v2 from '../../services/api-v2'
import { permissionDelete } from '../../utils/addScript'
import { TOKEN_DATA_USER } from '../../utils/login'
import { RiAdminLine } from 'react-icons/ri'

interface workspaces {
  id: any
  avatar_url: any
  name: string
}
const WorkspacePage: React.FC = () => {
  const [loading, setLoading] = useState<any>(false)
  const [loading2, setLoading2] = useState<any>(false)
  const [workspaces, setWorkspaces] = useState<any>([])
  const [defaultList, setDefaultList] = useState<any>([])
  const [superUSer, setSuperUser] = useState<any>(false)
  const [user, setUser] = useState<any>({})
  const [visible, setVisible] = useState<any>(false)

  async function getWorks() {
    setLoading2(true)
    try {
      const { data } = await api.get('/workspace')
      setWorkspaces(data.result)
      setDefaultList(data.result)
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
    setLoading2(false)
  }

  useEffect(() => {
    setLoading(true)

    async function getSuperUser() {
      setLoading2(true)
      try {
        const { data } = await api_v2.get('user/only-me')
        if(data) localStorage.setItem(TOKEN_DATA_USER, JSON.stringify(data))
        setUser(data)
        setSuperUser(data.is_allow_create_workspace)
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false)
      }
      setLoading2(false)
    }

    getSuperUser()
    getWorks()
  }, [])

  function handleSelect(team: any) {
    // localStorage.setItem('@rocketpdv:workspace', JSON.stringify(team))
    window.sessionStorage.setItem('@rocketpdv:workspace', JSON.stringify(team))
    Mixpanel.track('Workspace', { workspace: team.name })

    // history.push('/backoffice/')
    history.push('/viewsApp/')
  }

  function onSearch(event: any) {
    const search = event.target.value
    const workspacesFilter = defaultList.filter((item: any) => {
      return !item.name.search(new RegExp(search, 'gi'))
    })

    setWorkspaces(workspacesFilter)
  }

  return (
    <LayoutWorkspace>
      {loading2 ? <Loading /> : null}
      <Container>
        <Header>
          <Wrapper>
            <h1>Workspace</h1>
            <p>Selecione um ambiente abaixo</p>
          </Wrapper>
          <WrapperAction>
            <ContainerSearch style={{ marginBottom: 10 }}>
              <Search placeholder='Buscar' onChange={onSearch} />
              <MdSearch size={20} color={'#A56EFC'} />
            </ContainerSearch>
            <Popover
              content={<p onClick={() => setVisible(!visible)}>Close</p>}
              trigger='click'
              visible={visible}
              onVisibleChange={() => setVisible(!visible)}
            >
              <Button
                style={{ display: 'flex', alignItems: 'center', borderRadius: 5, color: '#6f7782', marginBottom: 10 }}
              >
                <MdFilterList size={20} />
                Ordernar
              </Button>
            </Popover>
            <BigButton
              onClick={() => {
                localStorage.setItem(
                  '@rocketpdv:workspace',
                  JSON.stringify({
                    id: 0,
                  })
                )
                history.push('/backoffice/newWorkspace')
              }}
              style={{ marginBottom: 10 }}
            >
              <MdAdd size={20} color='#fff' />
              Adicionar workspace
            </BigButton>
          </WrapperAction>
        </Header>
        {loading ? (
          <Skeleton />
        ) : (
          <>
            <WorkspaceList>
            {superUSer && user && user.id && user.id == 11 && (
                <WorkspaceOption
                  style={{
                    background: '#F9F9F9',
                    border: '2px solid #B670FC',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                  onClick={() => {
                    localStorage.setItem(
                      '@rocketpdv:workspace',
                      JSON.stringify({
                        id: 0,
                      })
                    )
                    history.push('/backoffice/admin')
                  }}
                >
                  <div
                    style={{
                      width: 50,
                      height: 50,
                      borderRadius: 25,
                      backgroundColor: '#EEDDFE',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <RiAdminLine style={{ fontSize: 20, color: '#B670FC' }} />
                  </div>
                  <WorkspaceName>Adiministrativo</WorkspaceName>
                </WorkspaceOption>
              )}
              {superUSer ? (
                <WorkspaceOption
                  style={{
                    background: '#F9F9F9',
                    border: '2px solid #B670FC',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                  onClick={() => {
                    localStorage.setItem(
                      '@rocketpdv:workspace',
                      JSON.stringify({
                        id: 0,
                      })
                    )
                    history.push('/backoffice/newWorkspace')
                  }}
                >
                  <div
                    style={{
                      width: 50,
                      height: 50,
                      borderRadius: 25,
                      backgroundColor: '#EEDDFE',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <PlusCircleOutlined style={{ fontSize: 20, color: '#B670FC' }} />
                  </div>
                  <WorkspaceName>Adicionar</WorkspaceName>
                </WorkspaceOption>
              ) : null}
              {workspaces.map((workspace: workspaces) => (
                <WorkspaceOption key={workspace.id}>
                  <div
                    style={{ width: '80%' }}
                    onClick={() => {
                      window.sessionStorage.setItem('@rocketpdv:workspace', JSON.stringify(workspace))

                      handleSelect(workspace)
                    }}
                  >
                    <WorkspaceLogo
                      src={workspace.avatar_url || "https://firebasestorage.googleapis.com/v0/b/rocketpdv-promoter.appspot.com/o/workspaces%2Frocket-pdv%2Fpp.jpeg?alt=media"}
                      alt={workspace.name} />
                    <WorkspaceName>{workspace.name}</WorkspaceName>
                  </div>
                  {permissionDelete() && (
                    <ContainerOptions>
                      <EditOutlined
                        onClick={() => {
                          window.sessionStorage.setItem('@rocketpdv:workspace', JSON.stringify(workspace))
                          history.push(`/backoffice/newWorkspace/${workspace.id}`)
                        }}
                      />
                      <DeleteOutlined
                        onClick={() => {
                          Modal.confirm({
                            title: 'Deseja excluir?',
                            icon: <ExclamationCircleOutlined />,
                            content: 'Ao confirmar você irá inativar este ambiente com todas as propriedades.',
                            onOk: async () => {
                              try {
                                setLoading(true)
                                Mixpanel.track('Deletou ambiente', { workspace })
                                window.sessionStorage.setItem('@rocketpdv:workspace', JSON.stringify(workspace))
                                // const { data } = await api_v2.delete(`/workspace/${workspace.id}`)
                                message.success('Sucesso ao deletar ambiente.')
                              } catch (error) {
                                message.error('Falha ao deletar ambiente.')
                              } finally {
                                setLoading(false)
                              }
                            },
                            onCancel() {
                              console.log('Cancel')
                            },
                          })
                        }}
                      />
                    </ContainerOptions>
                  )}
                </WorkspaceOption>
              ))}
            </WorkspaceList>
          </>
        )}
        <ContainerFooter>
          <p>desenvolvido por</p>
          <img src={Rocket} alt='user' />
        </ContainerFooter>
      </Container>
    </LayoutWorkspace>
  )
}

export default WorkspacePage
