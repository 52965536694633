import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(90deg, #6600cc 0%, #b56aff 100%);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  z-index: 9;
  padding: 0 40px;
  /* flex-wrap: wrap; */
  p {
    color: #fff;
    width: 100%;
    text-align: right;
    padding: 0.5rem;
    margin: 0 auto;
  }
  button {
    background: transparent;
    color: #fff;
    border: none;
    font-size: 22px;
  }
  @media (min-width: 320px) {
    width: 100%;
  }

  @media (max-width: 600px) {
    padding: 0 10px;
    height: auto;
    min-height: 45px;
    .ocult{
      display: none;
    }

    .maxW {
      width: 100px !important;
    }
    /* flex-direction: column; */
  }
`

export const ContainerMenuH = styled.div`
  width: 100%;
  margin: 0 10px;
  margin-left: 30px;

  &&& .ant-menu-submenu-arrow::before {
    display: none !important;
    color: transparent !important;
  }

  &&& .ant-menu-submenu-expand-icon, .ant-menu-submenu-arrow {
    display: none !important;
    color: transparent !important;
  }

  &&& i {
    display: none !important;
    color: transparent !important;
  }

  &&& .ant-menu-horizontal {
    border-bottom: none !important;
  }

  &&& .ant-menu {
    background: transparent !important;
    color: #fff;
  }

  &&& .ant-menu-overflow-item:hover {
    /* background: rgba(255, 128, 66, 0.5) !important; */
    color: #fff !important;
  }

  &&& .ant-menu-submenu-selected {
    /* background: #37859f !important; */
  }

  &&& .ant-menu-item-selected {
    /* background: #37859f !important; */
  }

  &&& .ant-menu-light .ant-menu-submenu-title:hover {
    /* background: rgba(255, 128, 66, 0.5) !important; */
    color: #fff !important;
  }

  &&& .anticon {
    font-size: 30px !important;
  }

  &&& .ant-menu-overflow {
    justify-content: center;
  }

  &&& .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected {
    color: #fff;
  }

  &&& .ant-menu-horizontal > .ant-menu-item a {
    color: #fff !important;
  }

  &&& .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    border-bottom: 3px solid white !important;
    color: #fff !important;
  }
  &&& .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after {
    border-bottom: 3px solid white !important;
  }

  &&& .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    color: #fff !important;
  }

  &&& li {
    font-size: 13px;
    font-weight: bold;
  }

  &&& .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active {
    color: #fff;
  }

  &&& .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after {
    display: none;
  }

  &&& .menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    color: #fff !important;
  }

  &&& .ant-menu-title-content div span {
    font-size: 10px !important;
    margin-left: 5px;
  }

  &&& .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
    padding: 0 10px !important;
  }
`

export const ContainerLogo = styled.div`
  height: 28px;
  padding-right: 10px;
  /* border-right: 2px solid #fff; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  cursor: pointer;
`

export const Logo = styled.img`
  height: 28px;
`

export const MyMenu = styled.div`
  display: flex;
  align-items: center;
  svg {
    cursor: pointer;
    color: #fff;
  }

  @media (max-width: 770px) {
    > span,
    svg {
      display: none;
    }
  }
`

export const Workspace = styled.div`
  /* height: 100px; */
  align-items: center;
  margin-left: 15px;
  text-align: center;
  justify-content: center;
`

export const Profile = styled.img`
  width: 35px;
  height: 35px;
  border-radius: 100%;
  background: white;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(204, 204, 204);
  border-image: initial;
`
export const Name = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 12px;
  }
  strong {
  }
`

export const Actions = styled.div``

export const ContainerMenus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const HeaderDrawer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 60px;
`

export const LogoDrawer = styled.img`
  max-height: 60px;
  padding-bottom: 20px;
`

export const TextHeader = styled.p`
  border-top: 1px solid #d6d6d6;
  padding-top: 10px;
  width: 210px;
  color: #6f7782;
  text-align: center;
`

export const ContainerMenu = styled.div`
  width: 210px;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const ListSubCategory = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  a {
    width: 100%;
    margin-bottom: 20px;
  }
`

export const ContainerSubCategory = styled.div<any>`
  padding: 10px;
  border-radius: 5px;
  background: ${({ disabled }: any) => (disabled ? '#dfdfdf' : '#fff')};
  cursor: pointer;
  max-width: 350px;
  width: 100%;
  margin: 5px 0;
  border: 1px solid #fff;
  border-color: ${({ active }: any) => (active ? '#6d55cc' : '#fff')};

  :hover {
    background-color: #f7f7f7;
  }
`
export const HeaderSubCategory = styled.div`
  display: flex;
  align-items: center;

  img {
    height: 30px;
    margin-right: 15px;
  }
`

export const TextMenu = styled.p`
  margin-bottom: 0;
  color: #242424;
  font-size: 17px;
  font-weight: bold;
`
export const ContainerLogoImg = styled.div`
  display: flex;
  align-items: center;

  p {
    color: #fff;
    font-size: 20px;
    margin: 0;
    margin-left: 5px;
  }
`
