import React, { useState } from 'react'
import { AddQuestion } from './style'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import TypeQuestion from '../TypeQuestion'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import CardForm from '../CardForm'

const DragDrop: React.FC<any> = ({
  questions,
  editQuestion,
  setOpenConfig,
  setConfigQuestion,
  questionDelete,
  addQuestion,
  refactorOrder,
  jump,
  refactorOrderDragDrop,
  upOrder,
  downOrder
}) => {
  const [open, setOpen] = useState(false)

  const handleClickAway = () => {
    setOpen(false)
  }

  const handleAddQuestion = async () => {
    setOpen((prev) => !prev)
  }
  return (
    <>
      {questions && (
        <div>
          {questions.map((quest: any, index: number) => (
            <CardForm
              quest={quest}
              editQuestion={editQuestion}
              setOpenConfig={setOpenConfig}
              setConfigQuestion={setConfigQuestion}
              questionDelete={questionDelete}
              jump={jump}
              refactorOrderDragDrop={refactorOrderDragDrop}
              questions={questions}
              index={index}
              downOrder={downOrder}
              upOrder={upOrder}
            />
          ))}
        </div>
      )}

      <ClickAwayListener onClickAway={handleClickAway}>
        <AddQuestion onClick={() => handleAddQuestion()}>
          <Tooltip placement='top' title={'Adicionar questão'}>
            <Button type='primary' shape='circle' icon={<PlusOutlined />} />
          </Tooltip>
          {open ? (
            <>
              {questions && questions.subFields ? (
                <TypeQuestion jump={jump} typeQuestion={addQuestion} order={questions.subFields.length + 1} />
              ) : questions ? (
                <TypeQuestion jump={jump} typeQuestion={addQuestion} order={questions.length + 1} />
              ) : (
                <TypeQuestion jump={jump} typeQuestion={addQuestion} order={1} />
              )}
            </>
          ) : null}
        </AddQuestion>
      </ClickAwayListener>
    </>
  )
}

export default DragDrop
