import { Col, Row, Checkbox, Select } from 'antd'
import Input from '../../../../components/form/SimpleInput'
import React, { useEffect, useRef, useState } from 'react'
import { InputCheckbox, LabelCheck, SubContainer, UploadImage } from '../styles'
import { MdFileUpload } from 'react-icons/md'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import{ IDefault } from '../index'
import { useParams } from 'react-router-dom'

export interface IPhoto {
  preview: string
  raw: string
}

interface Checkeds {
  required_check_in: boolean
  required_check_out: boolean
  required_check_auto: boolean
  required_all_rupture: boolean
}

export interface DataStep1 {
  nameWorkspace: string
  image: IPhoto
  checked: Checkeds
  typeColect: string
  type_view_form: 'tabs' | 'all'
  shared_environment: boolean
}

interface IStep1 {
  setStep1: (data: DataStep1) => void
  defaultVal: IDefault
}

const Step1: React.FC<IStep1> = ({ setStep1, defaultVal }: IStep1) => {
  const { id }: any = useParams()

  const formRef = useRef<FormHandles>(null)
  const [image, setImage] = useState<any>({ preview: '', raw: '' })
  const [typeColect, setTypeColect] = useState<string>('Produto')
  const [typeForm, setTypeForm] = useState<'tabs' | 'all'>('tabs')
  const [name, setName] = useState<string>('')
  const [shared_environment, setShared_environment] = useState<boolean>(false)
  const [checked, setChecked] = useState<Checkeds>({
    required_check_in: true,
    required_check_out: false,
    required_check_auto: false,
    required_all_rupture: false,
  })

  const handleChange = (e: any) => {
    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0],
    })
  }

  useEffect(() => {
    setStep1({
      nameWorkspace: name,
      typeColect,
      checked,
      image,
      type_view_form: typeForm,
      shared_environment,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image, typeColect, checked, name, typeForm, shared_environment])

  useEffect(() => {
    if(defaultVal) {
      setImage({
        preview: defaultVal.image,
        raw: undefined,
      })
      setTypeColect(defaultVal.typeColect)
      setTypeForm(defaultVal.type_view_form)
      setName(defaultVal.nameWorkspace)
      setShared_environment(defaultVal.shared_environment)
      setChecked({
        required_all_rupture: defaultVal.checked?.required_all_rupture,
        required_check_auto: defaultVal.checked?.required_check_auto,
        required_check_in: defaultVal.checked?.required_check_in,
        required_check_out: defaultVal.checked?.required_check_out,
      })
    }
  }, [defaultVal])

  return (
    <Form
      translate={undefined}
      className='form'
      ref={formRef}
      initialData={{ check_in_obrigatorio: true }}
      onSubmit={(el: any) => console.log(el)}
    >
      <SubContainer>
        <Row gutter={[0, 50]}>
          <Col span={12}>
            <Input
              type='text'
              label='Nome *'
              name='name'
              placeholder='Nome'
              value={name}
              onChange={(e: any) => setName(e.target.value)}
            />
          </Col>
          <Col span={12}>
            <UploadImage>
              <label htmlFor='upload-button'>
                {image.preview ? (
                  <img src={image.preview} width='300' height='300' alt='img'/>
                ) : (
                  <>
                    <span className='fa-stack fa-2x mt-3 mb-2'>
                      <MdFileUpload size={30} />
                    </span>
                    <h5 className='text-center'>Upload</h5>
                  </>
                )}
              </label>
              <input
                type='file'
                name='avatar_url'
                id='upload-button'
                style={{ display: 'none' }}
                onChange={handleChange}
              />
            </UploadImage>
          </Col>
          {!id &&
          <Col span={6}>
            <LabelCheck>O ambiente será compartilhado?</LabelCheck>
            <InputCheckbox>
              <Checkbox
                name='shared_environment'
                checked={shared_environment}
                defaultChecked={false}
                onChange={(setValue: any) => setShared_environment(setValue.target.checked)}
              />
            </InputCheckbox>
          </Col>
}
          <Col span={6}>
            <LabelCheck>Check-in obrigatório?</LabelCheck>
            <InputCheckbox>
              <Checkbox
                name='check_in_obrigatorio'
                checked={checked.required_check_in}
                defaultChecked={true}
                onChange={(setValue: any) => {
                  setChecked({
                    ...checked,
                    required_check_in: setValue.target.checked,
                  })
                }}
              />
            </InputCheckbox>
          </Col>
          <Col span={6}>
            <LabelCheck>Check-out obrigatório ?</LabelCheck>
            <InputCheckbox>
              <Checkbox
                name='check_out_obrigatorio'
                checked={checked.required_check_out}
                defaultChecked={true}
                onChange={(setValue: any) => {
                  setChecked({
                    ...checked,
                    required_check_out: setValue.target.checked,
                  })
                }}
              />
            </InputCheckbox>
          </Col>
          <Col span={6}>
            <LabelCheck>Check-in / check-out automático ?</LabelCheck>
            <InputCheckbox>
              <Checkbox
                name='check_in_automatico'
                checked={checked.required_check_auto}
                defaultChecked={true}
                onChange={(setValue: any) => {
                  setChecked({
                    ...checked,
                    required_check_auto: setValue.target.checked,
                  })
                }}
              />
            </InputCheckbox>
          </Col>
          <Col span={6}>
            <LabelCheck>Ao sair da loja, adicionar em ruptura os produtos pendentes?</LabelCheck>
            <InputCheckbox>
              <Checkbox
                name='required_all_rupture'
                checked={checked.required_all_rupture}
                defaultChecked={false}
                onChange={(setValue: any) => {
                  setChecked({
                    ...checked,
                    required_all_rupture: setValue.target.checked,
                  })
                }}
              />
            </InputCheckbox>
          </Col>
          <Col span={6}>
            <LabelCheck>Tipo de coleta ?</LabelCheck>
            <Select
              style={{ maxWidth: 200, marginLeft: '25%' }}
              defaultValue={'Produto'}
              value={typeColect}
              onChange={(el) => setTypeColect(el)}
            >
              <Select.Option value={'Produto'}>Produto</Select.Option>
              <Select.Option value={'Display'}>Display</Select.Option>
            </Select>
          </Col>
          <Col span={6}>
            <LabelCheck>Tipo de visualização formulários ?</LabelCheck>
            <Select defaultValue='tabs' onChange={(value: 'all' | 'tabs') => setTypeForm(value)}>
              <Select.Option value='tabs'>Tabs</Select.Option>
              <Select.Option value='all'>Todos</Select.Option>
            </Select>
          </Col>
        </Row>
      </SubContainer>
      {/* <Button type="primary" onClick={() => validateForm()}>Continuar</Button> */}
    </Form>
  )
}
export default Step1
