import React from 'react'
import TextQuestion from './question/textQuestion'
import DateQuestion from './question/dateQuestion'
import MoneyQuestion from './question/moneyQuestion'
import PictureQuestion from './question/pictureQuestion'
import QrQuestion from './question/qrQuestion'
import BooleanQuestion from './question/booleanQuestion'
import NumberQuestion from './question/numberQuestion'
import FloatQuestion from './question/floatQuestion'
import UniqueChoiceQuestion from './question/uniqueChoiceQuestion'
import MultipleChoiceQuestion from './question/multipleChoiceQuestion'
import GalleryQuestion from './question/galleryQuestion'

const ViewQuestion: React.FC<any> = ({ questions, fields }) => {

  return (
    <>
      {questions.map((quest: any, index: any) => (
        <>
          {quest && quest.type === 'TEXT' ? (
            <TextQuestion quest={quest} />
          ) : quest && quest.type === 'DATE' ? (
            <DateQuestion quest={quest} />
          ) : quest && quest.type === 'MONEY' ? (
            <MoneyQuestion quest={quest} />
          ) : quest && quest.type === 'PICTURE_CAPTURE' ? (
            <PictureQuestion quest={quest} />
          ) : quest && quest.type === 'QR_CODE' ? (
            <QrQuestion quest={quest} />
          ) : quest && quest.type === 'BOOLEAN' ? (
            <BooleanQuestion quest={quest} />
          ) : quest && quest.type === 'NUMBER_INTEGER' ? (
            <NumberQuestion quest={quest} />
          ) : quest && quest.type === 'GALLERY' ? (
            <GalleryQuestion quest={quest} />
          ) : quest && quest.type === 'NUMBER_DECIMAL' ? (
            <FloatQuestion quest={quest} />
          ) : quest && quest.type === 'UNIQUE_CHOICE' ? (
            <UniqueChoiceQuestion quest={quest} />
          ) : quest && quest.type === 'MULTIPLE_CHOICE' ? (
            <MultipleChoiceQuestion quest={quest} />
          ) : null}
        </>
      ))}
    </>
  )
}

export default ViewQuestion
