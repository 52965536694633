import React, { useState } from 'react'

const MoneyInput: React.FC = () => {
  const [value, setValue] = useState('')

  const formatMoney = (inputValue: string) => {
    const numericValue = inputValue.replace(/[^\d]/g, '')
    const valueInCents = parseInt(numericValue || '0', 10)
    const formattedValue = (valueInCents / 100).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    })
    return formattedValue
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    const formattedValue = formatMoney(inputValue)
    setValue(formattedValue)
  }

  return (
    <input
      type='text'
      value={value}
      onChange={handleChange}
      placeholder='R$ 0,00'
      style={{
        width: '100%',
        height: 30,
        padding: '0 11px',
        color: 'rgba(0, 0, 0, 0.85)',
        border: '1px solid #d9d9d9',
        borderRadius: '2px',
      }}
    />
  )
}

export default MoneyInput
