import React, { useState } from 'react'
import { IdQuestion, AddQuestion } from './style'
import RLDD from 'react-list-drag-and-drop/lib/RLDD'
import QuestionBuilder from '../QuestionBuilder'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import TypeQuestion from '../TypeQuestion'
import {
  PlusOutlined,
  SlidersTwoTone,
  CopyTwoTone,
  DiffTwoTone,
  CheckCircleTwoTone,
  CameraTwoTone,
  QuestionCircleTwoTone,
  AccountBookTwoTone,
  DatabaseTwoTone,
  AppstoreTwoTone,
  BranchesOutlined,
  QrcodeOutlined,
  CloudTwoTone,
  SnippetsTwoTone
} from '@ant-design/icons'
import { Button, Tooltip } from 'antd'

const DragDrop: React.FC<any> = ({
  questions,
  editQuestion,
  setOpenConfig,
  setConfigQuestion,
  questionDelete,
  addQuestion,
  refactorOrder,
  jump,
}) => {
  const [open, setOpen] = useState(false)

  const handleClickAway = () => {
    setOpen(false)
  }

  const handleAddQuestion = async () => {
    setOpen((prev) => !prev)
  }
  return (
    <>
      {questions ? (
        <RLDD
          items={
            questions.subFields === false
              ? []
              : questions.subFields
                ? questions.subFields === false
                  ? []
                  : questions.subFields
                : questions
          }
          itemRenderer={(quest: any) => {
            return (
              <>
                <QuestionBuilder
                  key={quest.id}
                  question={{...quest}}
                  edit={editQuestion}
                  openConfigQuestion={setOpenConfig}
                  setQuestion={setConfigQuestion}
                  questionDelete={questionDelete}
                  jump={jump}
                />

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  {quest && quest.fields_true ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: '20px',
                      }}
                    >
                      <BranchesOutlined
                        style={{
                          fontSize: '18px',
                          marginRight: '30px',
                          color: '#52c41a',
                        }}
                      />
                      {quest.fields_true
                        ? quest.fields_true.map((questionJump: any) => (
                          <>
                            <IdQuestion>
                              {questionJump.type === 'DATE' ? (
                                <Button
                                  style={{
                                    backgroundColor: '#4FA9B3',
                                  }}
                                >
                                  <CheckCircleTwoTone twoToneColor='#4FA9B3' style={{ fontSize: '23px' }} />
                                  <p>{questionJump.order}</p>
                                </Button>
                              ) : questionJump.type === 'TEXT' ? (
                                <Button
                                  style={{
                                    backgroundColor: '#FABA48',
                                  }}
                                >
                                  <CopyTwoTone twoToneColor='#FABA48' style={{ fontSize: '23px' }} />
                                  <p>{questionJump.order}</p>
                                </Button>
                              ) : questionJump.type === 'MONEY' ? (
                                <Button
                                  style={{
                                    backgroundColor: '#E26D5A',
                                  }}
                                >
                                  <DiffTwoTone twoToneColor='#E26D5A' style={{ fontSize: '23px' }} />
                                  <p>{questionJump.order}</p>
                                </Button>
                                ) : questionJump.type === 'DB_PRODUCTS' ? (
                                  <Button
                                    style={{
                                      backgroundColor: '#4FA9B3',
                                    }}
                                  >
                                    <SnippetsTwoTone twoToneColor='#4FA9B3' style={{ fontSize: '23px' }} />
                                    <p>{questionJump.order}</p>
                                  </Button>
                              ) : questionJump.type === 'PICTURE_CAPTURE' ? (
                                <Button
                                  style={{
                                    backgroundColor: '#A086C3',
                                  }}
                                >
                                  <CameraTwoTone twoToneColor='#A086C3' style={{ fontSize: '23px' }} />
                                  <p>{questionJump.order}</p>
                                </Button>
                              ) : questionJump.type === 'QR_CODE' ? (
                                <Button
                                  style={{
                                    backgroundColor: '#3ca133',
                                  }}
                                >
                                  <QrcodeOutlined twoToneColor='#3ca133' style={{ fontSize: '23px' }} />
                                  <p>{jump.order}</p>
                                </Button>
                              ) : questionJump.type === 'BOOLEAN' ? (
                                <Button
                                  style={{
                                    backgroundColor: '#BF415C',
                                  }}
                                >
                                  <QuestionCircleTwoTone twoToneColor='#BF415C' style={{ fontSize: '23px' }} />
                                  <p>{questionJump.order}</p>
                                </Button>
                              ) : questionJump.type === 'NUMBER_INTEGER' ? (
                                <Button
                                  style={{
                                    backgroundColor: '#E05178',
                                  }}
                                >
                                  <AccountBookTwoTone twoToneColor='#E05178' style={{ fontSize: '23px' }} />
                                  <p>{questionJump.order}</p>
                                </Button>
                              ) : questionJump.type === 'NUMBER_DECIMAL' ? (
                                <Button
                                  style={{
                                    backgroundColor: '#E05178',
                                  }}
                                >
                                  <DiffTwoTone twoToneColor='#E26D5A' style={{ fontSize: '23px' }} />
                                  <p>{questionJump.order}</p>
                                </Button>
                              ) : questionJump.type === 'UNIQUE_CHOICE' ? (
                                <Button
                                  style={{
                                    backgroundColor: '#BAC26C',
                                  }}
                                >
                                  <DatabaseTwoTone twoToneColor='#BAC26C' style={{ fontSize: '23px' }} />
                                  <p>{questionJump.order}</p>
                                </Button>
                              ) : questionJump.type === 'MULTIPLE_CHOICE' ? (
                                <Button style={{ backgroundColor: 'red' }}>
                                  <AppstoreTwoTone twoToneColor='red' style={{ fontSize: '23px' }} />
                                  <p>{questionJump.order}</p>
                                </Button>
                              ) : questionJump.type === 'GALLERY' ? (
                                <Button
                                  style={{
                                    backgroundColor: '#ff19a7',
                                  }}
                                >
                                  <CloudTwoTone twoToneColor='#ff19a7' style={{ fontSize: '23px' }} />
                                  <p>{questionJump.order}</p>
                                </Button>
                              ) : null}
                            </IdQuestion>
                          </>
                        ))
                        : null}
                    </div>
                  ) : null}
                  {quest && quest.fields_false ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingRight: '20px',
                      }}
                    >
                      <SlidersTwoTone
                        twoToneColor='red'
                        style={{
                          fontSize: '18px',
                          marginRight: '30px',
                        }}
                      />
                      {quest.fields_false
                        ? quest.fields_false.map((questionJump: any) => (
                          <>
                            <IdQuestion>
                              {questionJump.type === 'DATE' ? (
                                <Button
                                  style={{
                                    backgroundColor: '#4FA9B3',
                                  }}
                                >
                                  <CheckCircleTwoTone twoToneColor='#4FA9B3' style={{ fontSize: '23px' }} />
                                  <p>{questionJump.order}</p>
                                </Button>
                              ) : questionJump.type === 'TEXT' ? (
                                <Button
                                  style={{
                                    backgroundColor: '#FABA48',
                                  }}
                                >
                                  <CopyTwoTone twoToneColor='#FABA48' style={{ fontSize: '23px' }} />
                                  <p>{questionJump.order}</p>
                                </Button>
                              ) : questionJump.type === 'MONEY' ? (
                                <Button
                                  style={{
                                    backgroundColor: '#E26D5A',
                                  }}
                                >
                                  <DiffTwoTone twoToneColor='#E26D5A' style={{ fontSize: '23px' }} />
                                  <p>{questionJump.order}</p>
                                </Button>
                                 ) : questionJump.type === 'DB_PRODUCTS' ? (
                                  <Button
                                    style={{
                                      backgroundColor: '#4FA9B3',
                                    }}
                                  >
                                    <SnippetsTwoTone twoToneColor='#4FA9B3' style={{ fontSize: '23px' }} />
                                    <p>{questionJump.order}</p>
                                  </Button>
                              ) : questionJump.type === 'PICTURE_CAPTURE' ? (
                                <Button
                                  style={{
                                    backgroundColor: '#A086C3',
                                  }}
                                >
                                  <CameraTwoTone twoToneColor='#A086C3' style={{ fontSize: '23px' }} />
                                  <p>{questionJump.order}</p>
                                </Button>
                              ) : questionJump.type === 'QR_CODE' ? (
                                <Button
                                  style={{
                                    backgroundColor: '#3ca133',
                                  }}
                                >
                                  <QrcodeOutlined twoToneColor='#3ca133' style={{ fontSize: '23px' }} />
                                  <p>{jump.order}</p>
                                </Button>
                              ) : questionJump.type === 'BOOLEAN' ? (
                                <Button
                                  style={{
                                    backgroundColor: '#BF415C',
                                  }}
                                >
                                  <QuestionCircleTwoTone twoToneColor='#BF415C' style={{ fontSize: '23px' }} />
                                  <p>{questionJump.order}</p>
                                </Button>
                              ) : questionJump.type === 'NUMBER_INTEGER' ? (
                                <Button
                                  style={{
                                    backgroundColor: '#E05178',
                                  }}
                                >
                                  <AccountBookTwoTone twoToneColor='#E05178' style={{ fontSize: '23px' }} />
                                  <p>{questionJump.order}</p>
                                </Button>
                              ) : questionJump.type === 'NUMBER_DECIMAL' ? (
                                <Button
                                  style={{
                                    backgroundColor: '#E05178',
                                  }}
                                >
                                  <DiffTwoTone twoToneColor='#E26D5A' style={{ fontSize: '23px' }} />
                                  <p>{questionJump.order}</p>
                                </Button>
                              ) : questionJump.type === 'UNIQUE_CHOICE' ? (
                                <Button
                                  style={{
                                    backgroundColor: '#BAC26C',
                                  }}
                                >
                                  <DatabaseTwoTone twoToneColor='#BAC26C' style={{ fontSize: '23px' }} />
                                  <p>{questionJump.order}</p>
                                </Button>
                              ) : questionJump.type === 'MULTIPLE_CHOICE' ? (
                                <Button style={{ backgroundColor: 'red' }}>
                                  <AppstoreTwoTone twoToneColor='red' style={{ fontSize: '23px' }} />
                                  <p>{questionJump.order}</p>
                                </Button>
                              ) : questionJump.type === 'GALLERY' ? (
                                <Button
                                  style={{
                                    backgroundColor: '#ff19a7',
                                  }}
                                >
                                  <CloudTwoTone twoToneColor='#ff19a7' style={{ fontSize: '23px' }} />
                                  <p>{questionJump.order}</p>
                                </Button>
                              ) : null}
                            </IdQuestion>
                          </>
                        ))
                        : null}
                    </div>
                  ) : null}
                </div>
              </>
            )
          }}
          onChange={refactorOrder}
        />
      ) : null}

      <ClickAwayListener onClickAway={handleClickAway}>
        <AddQuestion onClick={() => {
          handleAddQuestion()
        }}>
          <Tooltip placement='top' title={'Adicionar questão'}>
            <Button type='primary' shape='circle' icon={<PlusOutlined />} />
          </Tooltip>
          {open ? (
            <>
              {questions && questions.length > 0 && questions.subFields ? (
                <TypeQuestion jump={jump} typeQuestion={addQuestion} order={questions.subFields.length + 1} />
              ) : questions && questions.length > 0 ? (
                <TypeQuestion jump={jump} typeQuestion={addQuestion} order={questions.length + 1} />
              ) : (
                <TypeQuestion jump={jump} typeQuestion={addQuestion} order={1} />
              )}
            </>
          ) : null}
        </AddQuestion>
      </ClickAwayListener>
    </>
  )
}

export default DragDrop
