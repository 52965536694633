import React, { useState } from 'react'
import { ContainerModal } from '../ModalEditUser/style'
// import { Loading } from '../Loading'
import { Steps } from 'antd'
import { ContainerFilter } from '../../pages/Team/Promoter/style'
import { Step2 } from './Step2'

interface IProps {
  visible: boolean
  close: (boolean: any) => void
  body: any
}

export const ModalReportSurveyEdit = ({ visible, close, body }: IProps) => {
  // const [loading, setLoading] = useState<boolean>(false)
  return (
    <ContainerModal
      visible={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
      width={800}
      style={{
        top: 20,
        height: '90vh',
      }}
    >
      <div
        style={{
          padding: 20,
          height: '90%',
        }}
      >
        {/* {loading && <Loading />} */}

        <ContainerFilter
          style={{
            borderRadius: 10,
            height: '100%',
          }}
        >
          {body?.form_id && (
            <Step2
              formId={body.form_id}
              body={body}
              close={close}
              
            />
          )}{' '}
        </ContainerFilter>
      </div>
    </ContainerModal>
  )
}
