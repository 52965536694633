import React, { useRef, useState } from 'react'
import { ContainerScript, TextScript } from './style'
import { DeleteTwoTone } from '@ant-design/icons'
import { Button, Modal, Tooltip, message } from 'antd'
import moment from 'moment'
import { useDrag } from 'react-dnd'
import { Mixpanel } from '../../utils/mixpanel'
import { ModalViewSubWorkspace } from '../ModalViewSubWorkspace'
import api_v2 from '../../services/api-v2'
import { BiCopy } from 'react-icons/bi'
import { RxCross2 } from 'react-icons/rx'
import { ContainerStep2, LabelCheck } from '../ModalSubWorkspace/style'
import AddUser from '../../assets/images/addUser.png'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import Select from '../form/SimpleSelect'
import api from '../../services/api'
import { addIsScriptIsPermited } from '../../utils/addScript'
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai'
import { Item } from '../CardUpload/styles'

export const CardItem: React.FC<any> = ({
  item,
  index,
  loadLists,
  setLoading2,
  DateDays,
  setCopyScript,
  copyScript,
  setLoanding,
  Arr,
  semana,
  frequency,
  total,
  allItems
}: any) => {
  const [openModalSubWorkspaces, setOpenModalSubWorkspaces] = useState(false)
  const [openCopy, setOpenCopy] = useState<any>(false)
  const formRef = useRef<FormHandles>(null)

  async function handleDelete(id: any, date: any, pdv: any, user: any) {
    if (!addIsScriptIsPermited()) return message.error('Você não tem autorização para mexer no roteiro.')
    setLoading2(true)
    try {
      const date_american_formatted: any = moment(date).format('YYYY-MM-DD')
      const id_user: any = user

      const pdv_user: any = pdv

      const param: any = {
        date: date_american_formatted,
        user: id_user,
        pdv: pdv_user,
      }
      await api_v2.delete(`/script/${id}`, {
        params: param,
      })
      Mixpanel.track('Apagar roteiro tarefa', {
        params: param,
        script_id: id,
      })
      message.success('Deletado com sucesso')
      loadLists()
    } catch (err) {
      message.error('Não foi possivel deletar')
    }
    setLoading2(true)
  }

  const [{ isDragging }, dragRef] = useDrag(() => ({
    type: 'CARD',
    item: { index, item, date: DateDays },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: !addIsScriptIsPermited(),
  }))

  let getWorkspace = sessionStorage.getItem('@rocketpdv:workspace')
  let newGetWorkspace = JSON.parse(getWorkspace!.toString())

  function getNextDayOfWeek(weekdayName: string): string {
    const weekdays: string[] = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY']
    const today: Date = new Date()
    const currentWeekday: number = today.getDay() // 0 (Domingo) a 6 (Sábado)
    const targetWeekday: number = weekdays.indexOf(weekdayName.toUpperCase())

    const daysUntilTarget: number = (targetWeekday - currentWeekday + 7) % 7

    if (daysUntilTarget === 0) {
      return today.toISOString().substr(0, 10) // Retorna a data de hoje (formato YYYY-MM-DD)
    } else {
      const nextDate: Date = new Date(today)
      nextDate.setDate(today.getDate() + daysUntilTarget)
      return nextDate.toISOString().substr(0, 10)
    }
  }

  const insertCopyScript = async (body: any) => {
    try {
      setLoanding(true)
      const { days } = body

      for await (let dia of days) {
        let data: any = {
          user: item.user.id,
          weekDay: dia,
          weekNumber: Arr[semana],
          pdv: [item.pdv.id],
          frequency,
          dt_visit: getNextDayOfWeek(dia),
          order: index + 1,
        }

        if (newGetWorkspace.shared_environment) {
          data = {
            ...data,
            sub_workspaces: item.sub_workspaces,
          }
        }
        await api_v2.post('/script', { ...data, copy: true })
      }
      await loadLists()
      setCopyScript({})
      message.success('Sucesso ao copiar')
    } catch (error) {
      message.error('Erro ao copiar roteiro')
    } finally {
      setLoanding(false)
    }
  }

  const handleOrderDown = async () => {
    if (!addIsScriptIsPermited()) return message.error('Você não tem autorização para mexer no roteiro.')

    try {
      setLoanding(true)

      let findIndex = allItems.findIndex((el: any) => el.id == item.id)

      await api_v2.post('/script/order', {
        up: {
          id: item.id,
          order: index + 2,
          name: item.pdv.name
        },
        down: {
          id: allItems[findIndex + 1].id,
          order: findIndex + 1,
          name: allItems[findIndex + 1].pdv.name
        }
       })

       await loadLists()

    } catch (error) {
      message.error('Erro ao mudar ordem')
    } finally {
      setLoanding(false)
    }

  }

  const handleOrderUp = async () => {
    if (!addIsScriptIsPermited()) return message.error('Você não tem autorização para mexer no roteiro.')
    try {
      setLoanding(true)

      let findIndex = allItems.findIndex((el: any) => el.id == item.id)

      await api_v2.post('/script/order', {
        up: {
          id: item.id,
          order: index,
          name: item.pdv.name
        },
        down: {
          id: allItems[findIndex - 1].id,
          order: findIndex + 1,
          name: allItems[findIndex - 1].pdv.name
        }
       })

       await loadLists()

    } catch (error) {
      message.error('Erro ao mudar ordem')
    } finally {
      setLoanding(false)
    }
  }

  return (
    <>
      {/*<ContainerScript ref={dragRef} isDragging={isDragging}>*/}
        <ContainerScript ref={dragRef} isDragging={isDragging}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          width: 50,
          gap: 3
        }}>
            <AiOutlineArrowDown
              style={{
                color: index < total - 1 ? "red" : 'lightgray',
                fontSize: '17px',
                cursor: index < total - 1 ? 'pointer' : 'initial'
              }}
              onClick={() => index < total - 1 ? handleOrderDown() : null}
            />

            <AiOutlineArrowUp
              style={{
                color: index > 0 ? "green" : 'lightgray',
                fontSize: '17px',
                cursor: index > 0 ? 'pointer' : 'initial'
              }}
              onClick={() => index > 0 ? handleOrderUp() : null}
            />

        </div>
        <TextScript
          onClick={() => {
            if (!addIsScriptIsPermited()) return message.error('Você não tem autorização para mexer no roteiro.')
            setOpenModalSubWorkspaces(!openModalSubWorkspaces)
          }}
        >
          {item.pdv.name}
        </TextScript>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {copyScript && Object.keys(copyScript).length > 0 && copyScript.id === item.id && (
            <Tooltip placement='top' title='Cancelar'>
              <RxCross2
                onClick={() => setCopyScript({})}
                size={15}
                style={{
                  cursor: 'pointer',
                }}
              />
            </Tooltip>
          )}
          {!copyScript ||
            (Object.keys(copyScript).length === 0 && (
              <Tooltip placement='top' title='Copiar'>
                <BiCopy
                  onClick={() => {
                    if (!addIsScriptIsPermited())
                      return message.error('Você não tem autorização para mexer no roteiro.')
                    setCopyScript(item)
                    setOpenCopy(true)
                  }}
                  size={15}
                  style={{
                    cursor: 'pointer',
                  }}
                />
              </Tooltip>
            ))}
          <Tooltip placement='top' title='Apagar'>
            <DeleteTwoTone
              twoToneColor='#e9830f'
              onClick={() => handleDelete(item.id, DateDays, item.pdv.id, item.user.id)}
              size={15}
              style={{
                cursor: 'pointer',
                marginLeft: 10,
              }}
            />
          </Tooltip>
        </div>
      </ContainerScript>
      {newGetWorkspace.shared_environment && (
        <ModalViewSubWorkspace
          close={setOpenModalSubWorkspaces}
          visible={openModalSubWorkspaces}
          visit={item}
          type='script'
        />
      )}

      <Modal
        visible={openCopy}
        footer={null}
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
        onCancel={() => {
          setCopyScript({})
          setOpenCopy(!openCopy)
        }}
        closable={true}
        destroyOnClose={true}
      >
        <ContainerStep2>
          <h3>Copiar roteiro</h3>
          <img src={AddUser} alt='user' />
          <Form
            translate={undefined}
            className='form'
            ref={formRef}
            initialData={{ is_active: true }}
            onSubmit={insertCopyScript}
            style={{ width: '100%' }}
          >
            <LabelCheck style={{ textAlign: 'left' }}>Selecione os dias para copiar</LabelCheck>
            <Select
              name='days'
              placeholder='Selecione os dias'
              options={[
                { value: 'MONDAY', label: 'Segunda-feira' },
                { value: 'TUESDAY', label: 'Terça-feira' },
                { value: 'WEDNESDAY', label: 'Quarta-feira' },
                { value: 'THURSDAY', label: 'Quinta-feira' },
                { value: 'FRIDAY', label: 'Sexta-feira' },
                { value: 'SATURDAY', label: 'Sábado' },
                { value: 'SUNDAY', label: 'Domingo' },
              ]}
              isMulti={true}
            />
            <Button htmlType='submit' style={{ width: '100%', height: '35px', marginTop: 20 }}>
              Adicionar
            </Button>
          </Form>
        </ContainerStep2>
      </Modal>
    </>
  )
}
