import { Button, Col, Dropdown, Menu, message, Popconfirm } from 'antd'
import React, { useState } from 'react'
import DefaultPage, { getStatusProduct } from '../../../components/DefaultPage'
import { isActiveSelect, optionsProductType } from '../../../utils/option-user'
import { DeleteOutlined, EditOutlined, MoreOutlined, DownOutlined } from '@ant-design/icons'
import { DataStatus } from '../../../components/TabsVisits/style'
import { compareByAlph, exportXls } from '../../../utils/funcoes'
import { Link } from 'react-router-dom'
import { Mixpanel } from '../../../utils/mixpanel'
import { ContextUpload } from '../../../context/contextUpload'
import { hasIndex } from '../../../components/Menu/menuHorizonte'
import api_v2 from '../../../services/api-v2'
import { MdCheck } from 'react-icons/md'
import { storedBody  } from '../../../../src/components/DefaultPage'

export const ProductListPage = () => {
  const { permissions } = ContextUpload()
  const [loading, setLoading] = useState(false)
  const [reload, setReload] = useState(false)
  const [selecteds, setSelecteds] = useState<any>([])
  let getSharement = sessionStorage.getItem('@rocketpdv:workspace')
  let newGetWorkspace: any = {}
  if (getSharement) newGetWorkspace = JSON.parse(getSharement)

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      setSelecteds(selectedRows)
    },
    getCheckboxProps: (record: any) => ({
      disabled: !record.is_active, // Column configuration not to be checked
    }),
  }

  const inactiveProduct = async (id: string) => {
    setLoading(true)
    try {
      await api_v2.delete('product/' + id)
      Mixpanel.track('Inativou produto', {
        params: { id: id },
      })
      message.success('Produto inativado com sucesso!')
      setReload(!reload)
    } catch (err) {
      message.error('Esse produto está vinculada a algum outro registro')
    }
    setLoading(false)
  }

  const inactiveProductMass = async (id: string) => {
    try {
      await api_v2.delete('product/' + id)
      Mixpanel.track('Inativou produto', {
        params: { id: id },
      })
      message.success('Produto inativado com sucesso!')
    } catch (err) {
      message.error('Esse produto está vinculada a algum outro registro')
    }
  }

  const activeProduct = async (id: string) => {
    setLoading(true)
    try {
      await api_v2.put('product/active/' + id)
      Mixpanel.track('Ativou produto', {
        params: { id: id },
      })
      message.success('Produto ativado com sucesso!')
      setReload(!reload)
    } catch (err) {
      message.error('Esse produto está vinculada a algum outro registro')
    }
    setLoading(false)
  }

  const activeProductMass = async (id: string) => {
    try {
      await api_v2.put('product/active/' + id)
      Mixpanel.track('Ativou produto', {
        params: { id: id },
      })
      message.success('Produto ativou com sucesso!')
    } catch (err) {
      message.error('Esse produto está vinculada a algum outro registro')
    }
  }

  const deleteProduct = async (id: string) => {
    setLoading(true)
    try {
      await api_v2.delete('product/delete/' + id)
      Mixpanel.track('Inativou produto', {
        params: { id: id },
      })
      message.success('Produto deletado com sucesso!')
      setReload(!reload)
    } catch (err) {
      message.error('Esse produto está vinculada a algum outro registro')
    }
    setLoading(false)
  }

  const deleteProductMass = async (id: string) => {
    try {
      await api_v2.delete('product/delete/' + id)
      Mixpanel.track('Inativou produto', {
        params: { id: id },
      })
      message.success('Produto inativado com sucesso!')
      setReload(!reload)
    } catch (err) {
      message.error('Esse produto está vinculada a algum outro registro')
    }
  }

  return (
    <DefaultPage
      titlePage='Produtos'
      breadcrumb={[{ name: 'Home', url: '/' }, { name: 'Produtos' }]}
      urlPage='/backoffice/catalog/products'
      routeApi={{
        method: 'get',
        route: 'product',
        versionApi: 'v2',
        reload,
      }}
      listButtons={{
        buttonCreate: {
          visible: hasIndex(['catalog.add_product'], permissions) > -1,
          type: 'url',
          url: '/backoffice/catalog/products/create',
        },
        buttonExport: {
          visible: hasIndex(['catalog.export_product'], permissions) > -1,
          type: 'function',
          function: async () => {
            setLoading(true)
            exportXls({
              storedBody
            }, 'products')
            setLoading(false)
          },
        },
        buttonimport: {
          visible: hasIndex(['catalog.import_product'], permissions) > -1,
          path: 'PRODUCT',
        },
        buttonFilter: {
          visible: true,
          filters: [
            {
              label: 'Selecione o nome:',
              name: 'name',
              type: 'search',
              urlSearch: 'products',
              getEnv: 'label',

            },
            {
              label: 'Selecione a marca:',
              name: 'brand',
              type: 'search',
              urlSearch: 'brands',
              getEnv: 'value',
            },
            {
              label: 'Selecione a categoria:',
              name: 'category',
              type: 'search',
              urlSearch: 'categories',
              getEnv: 'value',
            },
            {
              label: 'Selecione o tipo',
              name: 'product_type',
              type: 'select',
              options: optionsProductType,
              getEnv: 'value',
            },
            {
              label: 'Selecione o status',
              name: 'is_active',
              type: 'select',
              options: isActiveSelect,
              getEnv: 'value',
            },
            {
              label: 'Selecione a empresa',
              urlSearch: 'sub-workspaces',
              name: 'sub_workspace',
              type: 'search',             
              getEnv: 'value',
              invisible:!newGetWorkspace.shared_environment

            },
          ],
        },
        newButton: {
          visible: selecteds.length > 0 ? true : false,
          children: (
            <Col span={5}>
              <Dropdown
                overlay={
                  <Menu>
                    {hasIndex(['catalog.remove_product'], permissions) > -1 && (
                      <Menu.Item key='1' icon={<DeleteOutlined />}>
                        <Popconfirm
                          title={`Tem certeza que deseja inativar estes produto?`}
                          onConfirm={async () => {
                            setLoading(!loading)

                            for await (let product of selecteds) {
                              Mixpanel.track('Confirmou inativar produto', product)
                              await inactiveProductMass(product.id)
                            }
                            setLoading(!loading)
                            setReload(!reload)
                          }}
                          okText='Sim'
                          cancelText='Não'
                          placement='topRight'
                        >
                          Inativar produto
                        </Popconfirm>
                      </Menu.Item>
                    )}
                    {hasIndex(['catalog.remove_product'], permissions) > -1 && (
                      <Menu.Item key='1' icon={<MdCheck />}>
                        <Popconfirm
                          title={`Tem certeza que deseja ativar estes produto?`}
                          onConfirm={async () => {
                            setLoading(!loading)

                            for await (let product of selecteds) {
                              Mixpanel.track('Confirmou ativar produto', product)
                              await activeProductMass(product.id)
                            }
                            setLoading(!loading)
                            setReload(!reload)
                          }}
                          okText='Sim'
                          cancelText='Não'
                          placement='topRight'
                        >
                          Inativar produto
                        </Popconfirm>
                      </Menu.Item>
                    )}
                    {hasIndex(['catalog.remove_product'], permissions) > -1 && (
                      <Menu.Item key='1' icon={<DeleteOutlined />}>
                        <Popconfirm
                          title='Tem certeza que deseja deletar estes produto?'
                          onConfirm={async () => {
                            setLoading(!loading)

                            for await (let product of selecteds) {
                              Mixpanel.track('Confirmou deletar produto', product)
                              await deleteProductMass(product.id)
                            }
                            setLoading(!loading)
                            setReload(!reload)
                          }}
                          okText='Sim'
                          cancelText='Não'
                          placement='topRight'
                        >
                          Deletar produto
                        </Popconfirm>
                      </Menu.Item>
                    )}
                  </Menu>
                }
              >
                <Button>
                  Ações em massa ({selecteds.length}) <DownOutlined />
                </Button>
              </Dropdown>
            </Col>
          ),
        },
      }}
      load={{ loading, setLoading }}
      rowSelection={{
        type: 'checkbox',
        ...rowSelection,
      }}
      columns={[
        {
          title: 'Status',
          dataIndex: 'is_active',
          key: 'status',
          width: 80,
          render: (status: any) => {
            return <DataStatus>{getStatusProduct(status)}</DataStatus>
          },
        },
        {
          title: 'Nome',
          dataIndex: 'name',
          sorter: (a, b) => compareByAlph(a.brand.name, b.brand.name),
        },
        {
          title: 'Marca',
          dataIndex: ['brand', 'name'],
          sorter: (a, b) => compareByAlph(a.brand.name, b.brand.name),
        },
        {
          title: 'Categoria',
          dataIndex: ['category', 'name'],
          sorter: (a, b) => compareByAlph(a.category.name, b.category.name),
        },
        {
          title: 'Tipo',
          sorter: (a, b) => compareByAlph(a.type, b.type),
          render: (obj: any) => (obj.product_type === 'OWN' ? 'Próprio' : 'Concorrente'),
        },
        {
          title: '#',
          width: 50,
          render: (obj: any) => {
            if (hasIndex(['catalog.remove_product', 'catalog.change_product'], permissions) === -1) return undefined
            return (
              <Dropdown
                overlay={
                  <Menu>
                    {hasIndex(['catalog.remove_product'], permissions) > -1 && (
                      <Menu.Item key='1' icon={obj.is_active ? <MdCheck /> : <DeleteOutlined />}>
                        <Popconfirm
                          title={`Tem certeza que deseja ${obj.is_active ? 'inativar' : 'ativar'} este produto?`}
                          onConfirm={() => {
                            Mixpanel.track(`Confirmou ${obj.is_active ? 'inativar' : 'ativar'} produto`, obj)
                            if (obj.is_active) {
                              inactiveProduct(obj.id)
                            } else {
                              activeProduct(obj.id)
                            }
                          }}
                          okText='Sim'
                          cancelText='Não'
                          placement='topRight'
                        >
                          {obj.is_active ? 'Inativar' : 'Ativar'} produto
                        </Popconfirm>
                      </Menu.Item>
                    )}
                    {hasIndex(['catalog.remove_product'], permissions) > -1 && (
                      <Menu.Item key='1' icon={<DeleteOutlined />}>
                        <Popconfirm
                          title='Tem certeza que deseja excluir este produto?'
                          onConfirm={() => {
                            Mixpanel.track('Confirmou excluir produto', obj)
                            deleteProduct(obj.id)
                          }}
                          okText='Sim'
                          cancelText='Não'
                          placement='topRight'
                        >
                          Deletar produto
                        </Popconfirm>
                      </Menu.Item>
                    )}
                    {hasIndex(['catalog.change_product'], permissions) > -1 && (
                      <Menu.Item key='2' icon={<EditOutlined />}>
                        <Link to={`/backoffice/catalog/products/edit/${obj.id}`}>Editar produto</Link>
                      </Menu.Item>
                    )}
                  </Menu>
                }
              >
                <Button size='small' shape='circle' icon={<MoreOutlined />} />
              </Dropdown>
            )
          },
        },
      ]}
    />
  )
}
