import { Breadcrumb, Col, Collapse, Row, Tag, Tooltip } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { DrawerFilter } from '../../../components/DrawerFilter'
import { Loading } from '../../../components/Loading'
import { BigButton, ContainerButtonsFootter, ContainerFormFilter, customStyles, GroupForm } from '../../../GlobalStyles'
import { ContainerTagg } from '../../Survey/FormListPage/styles'
import { ButtonClear, ButtonSearch, LabelForm } from '../../Team/Promoter/style'
import {
  FileSearchOutlined,
  FilterOutlined,
  CloseOutlined,
  ClearOutlined,
  MinusOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { FormHandles } from '@unform/core'
import history from '../../../routes/history'
import { Form } from '@unform/web'
import SimpleRangePicker from '../../../components/form/SimpleRangePicker'
import moment from 'moment'
import qs from 'querystring'
import useReactRouter from 'use-react-router'
import CardGraphStatic from '../../../components_analytcs/CardGraphStatic'
import { BiTimeFive } from 'react-icons/bi'
import { HiOutlineIdentification } from 'react-icons/hi'
import { ContainerCardTop, ContainerFullGraph } from './style'
import ColumnDash from '../../../components_analytcs/ColumnsDash'
import MapDash from '../../../components_analytcs/MapDash'
import { Link } from 'react-router-dom'
import api_v2 from '../../../services/api-v2'
import { Search } from '../../../components/form/SimpleSearch'
import Select from '../../../components/form/SimpleSelect'
import { optionUF } from '../../../utils/option-user'
import { ContextUpload } from '../../../context/contextUpload'
import { hasIndex } from '../../../components/Menu/menuHorizonte'
import { notAccess } from '../../../routes/ScrollToTop'

interface PropsChart {
  categories: any[]
  data: any[]
}
export const names = [
  'Aaren',
  'Aarika',
  'Abagael',
  'Abagail',
  'Abbe',
  'Abbey',
  'Abbi',
  'Abbie',
  'Abby',
  'Abbye',
  'Abigael',
  'Abigail',
  'Abigale',
  'Abra',
  'Ada',
  'Adah',
  'Adaline',
  'Adan',
  'Adara',
  'Adda',
  'Addi',
  'Addia',
  'Addie',
  'Addy',
  'Adel',
  'Adela',
  'Adelaida',
  'Adelaide',
  'Adele',
  'Adele',
]

export const date = [
  '09:28:09',
  '09:28:10',
  '09:28:12',
  '09:28:12',
  '09:28:58',
  '09:29:04',
  '09:29:05',
  '09:29:08',
  '09:33:43',
  '09:40:08',
  '09:40:12',
  '09:40:12',
  '09:41:25',
  '09:41:56',
  '09:42:26',
  '09:42:46',
  '09:42:53',
  '09:43:23',
  '09:43:26',
  '09:43:29',
  '09:43:29',
  '09:57:37',
  '10:00:35',
  '10:00:48',
  '10:06:18',
  '10:06:22',
  '10:06:23',
  '10:08:00',
  '10:08:00',
  '10:08:00',
]

export const UFS = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO',
]

function Move() {
  const formRef = useRef<FormHandles>(null)
  const { permissions } = ContextUpload()
  const { location } = useReactRouter()
  const [filterTag, setFilterTag] = useState<any>({})
  const [visible, setVisible] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [expanded, setExpanded] = useState<string>('1')
  const [dailyHour, setDailyHour] = useState<string>('-1')
  const [dailyHourPdv, setDailyHourPdv] = useState<string>('-1')
  const [dailyHourTransit, setDailyHourTransit] = useState<string>('-1')
  const [totalPromoters, setTotalPromoters] = useState<string>('-1')
  const [totalPdvs, setTotalPdvs] = useState<string>('-1')
  const [visitRegional, setVisitRegional] = useState<PropsChart>({
    categories: [],
    data: [],
  })
  const [visitState, setVisitState] = useState<PropsChart>({
    categories: [],
    data: [],
  })
  const [visitHoursPromoter, setVisitHoursPromoter] = useState<PropsChart>({
    categories: [],
    data: [],
  })
  const [visitHoursFlag, setVisitHoursFlag] = useState<PropsChart>({
    categories: [],
    data: [],
  })

  async function search(body: any, setter: any, url: any, type: any) {
    try {
      let { states_ids, date, flags_ids, promoters_ids, networks_ids, pdvs_ids, regions_ids, status, uf, week } = body
      let filters: any = {
        filterQuery: {},
        dataTag: {},
        filterQueryUrl: {},
      }

      if (promoters_ids && promoters_ids.length > 0) {
        let text = ''
        promoters_ids.forEach((el: any) => (text = text === '' ? `${el.value}` : text + `,${el.value}`))
        filters.filterQuery.promoters_ids = text
        filters.dataTag = { ...filters.dataTag, promoters_ids }
        filters.filterQueryUrl = { ...filters.filterQueryUrl, promoters_ids: JSON.stringify(promoters_ids) }
      }

      if (states_ids && states_ids.length > 0) {
        let text = ''
        states_ids.forEach((el: any) => (text = text === '' ? `${el}` : text + `,${el}`))
        filters.filterQuery.states_ids = text
        filters.dataTag = { ...filters.dataTag, states_ids }
        filters.filterQueryUrl = { ...filters.filterQueryUrl, states_ids: JSON.stringify(states_ids) }
      }

      if (pdvs_ids && pdvs_ids.length > 0) {
        let text = ''
        pdvs_ids.forEach((el: any) => (text = text === '' ? `${el.value}` : text + `,${el.value}`))
        filters.filterQuery.pdvs_ids = text
        filters.dataTag = { ...filters.dataTag, pdvs_ids }
        filters.filterQueryUrl = { ...filters.filterQueryUrl, pdvs_ids: JSON.stringify(pdvs_ids) }
      }

      if (networks_ids && networks_ids.length > 0) {
        let text = ''
        networks_ids.forEach((el: any) => (text = text === '' ? `${el.value}` : text + `,${el.value}`))
        filters.filterQuery.networks_ids = text
        filters.dataTag = { ...filters.dataTag, networks_ids }
        filters.filterQueryUrl = { ...filters.filterQueryUrl, networks_ids: JSON.stringify(networks_ids) }
      }

      if (regions_ids && regions_ids.length > 0) {
        let text = ''
        regions_ids.forEach((el: any) => (text = text === '' ? `${el.value}` : text + `,${el.value}`))

        filters.filterQuery.regions_ids = text
        filters.dataTag = { ...filters.dataTag, regions_ids }
        filters.filterQueryUrl = { ...filters.filterQueryUrl, regions_ids: regions_ids }
      }

      if (status) {
        filters.filterQuery.status = status
        filters.dataTag = { ...filters.dataTag, status }
        filters.filterQueryUrl = { ...filters.filterQueryUrl, status: status }
      }

      if (uf) {
        filters.filterQuery.uf = uf
        filters.dataTag = { ...filters.dataTag, uf }
        filters.filterQueryUrl = { ...filters.filterQueryUrl, uf: uf }
      }

      if (week) {
        filters.filterQuery.week = week
        filters.dataTag = { ...filters.dataTag, week }
        filters.filterQueryUrl = { ...filters.filterQueryUrl, week: week }
      }

      if (flags_ids && flags_ids.length > 0) {
        let text = ''
        flags_ids.forEach((el: any) => (text = text === '' ? `${el.value}` : text + `,${el.value}`))

        filters.filterQuery.flags_ids = text
        filters.dataTag = { ...filters.dataTag, flags_ids }
        filters.filterQueryUrl = { ...filters.filterQueryUrl, flags_ids: JSON.stringify(flags_ids) }
      }

      if (date) {
        filters.filterQueryUrl = { ...filters.filterQueryUrl, date: JSON.stringify(date) }
        if (date[0]) {
          filters.filterQuery.start_date = date[0]
          filters.dataTag = { ...filters.dataTag, start_date: date[0] }
        }

        if (date[1]) {
          filters.filterQuery.end_date = date[1]
          filters.dataTag = { ...filters.dataTag, end_date: date[1] }
        }
      } else {
        filters.filterQuery = {
          ...filters.filterQuery,
          end_date: moment().format('YYYY-MM-DD'),
          start_date: moment().format('YYYY-MM-DD'),
        }
        filters.dataTag = {
          ...filters.dataTag,
          end_date: moment().format('YYYY-MM-DD'),
          start_date: moment().format('YYYY-MM-DD'),
        }
      }

      const queryFilter = qs.stringify({ ...filters.filterQuery })

      // setUrlFilter(queryFilter)
      const { data } = await api_v2.get(`${url}?${queryFilter}`)
      if (type === 1) {
        setter(data.result)
      } else {
        setter(data)
      }

      setFilterTag(filters.dataTag)
      setVisible(false)
      // history.push(`/Analitycs/move?${qs.stringify({ ...filters.filterQueryUrl })}`)
    } catch (error) {
      if (type === 1) {
        setter('-1')
      }
    }
  }

  const removeFilter = async (typeRemove: string, value: string) => {
    let newObjTagg = filterTag

    if (typeRemove === 'promoters_ids') {
      newObjTagg.promoters_ids = newObjTagg.promoters_ids.filter((el: any) => el.value !== value)
      if (newObjTagg.promoters_ids.length === 0) delete newObjTagg.promoters_ids
    }

    if (typeRemove === 'states_ids') {
      newObjTagg.states_ids = newObjTagg.states_ids.filter((el: any) => el !== value)
      if (newObjTagg.states_ids.length === 0) delete newObjTagg.states_ids
    }

    if (typeRemove === 'flags_ids') {
      newObjTagg.flags_ids = newObjTagg.flags_ids.filter((el: any) => el.value !== value)
      if (newObjTagg.flags_ids.length === 0) delete newObjTagg.flags_ids
    }

    if (typeRemove === 'pdvs_ids') {
      newObjTagg.pdvs_ids = newObjTagg.pdvs_ids.filter((el: any) => el.value !== value)
      if (newObjTagg.pdvs_ids.length === 0) delete newObjTagg.pdvs_ids
    }

    if (typeRemove === 'regions_ids') {
      newObjTagg.regions_ids = newObjTagg.regions_ids.filter((el: any) => el.value !== value)
      if (newObjTagg.regions_ids.length === 0) delete newObjTagg.regions_ids
    }

    if (typeRemove === 'networks_ids') {
      newObjTagg.networks_ids = newObjTagg.networks_ids.filter((el: any) => el.value !== value)
      if (newObjTagg.networks_ids.length === 0) delete newObjTagg.networks_ids
    }

    if (typeRemove === 'start_date') {
      newObjTagg.date = [
        moment().format('YYYY-MM-DD'),
        filterTag.end_date ? filterTag.end_date : moment().format('YYYY-MM-DD'),
      ]
    }

    if (typeRemove === 'end_date') {
      newObjTagg.date = [
        filterTag.start_date ? filterTag.start_date : moment().format('YYYY-MM-DD'),

        moment().format('YYYY-MM-DD'),
      ]
    }
    setLoading(true)
    // const { data } = await api_v2.get(`/visit?${queryFilter}`)
    // setForms({ ...data, loading: false })
    updateUrl(newObjTagg)
    setFilterTag(newObjTagg)
    await search(newObjTagg, setDailyHour, '/times-movements/daily-hours', 1)
    await search(newObjTagg, setVisitState, '/times-movements/visits-state', 0)
    await search(newObjTagg, setVisitRegional, '/times-movements/visits-regional', 0)
    await search(newObjTagg, setVisitHoursPromoter, '/times-movements/visits-hours-promoters', 0)
    await search(newObjTagg, setVisitHoursFlag, '/times-movements/visits-hours-flags', 0)
    await search(newObjTagg, setDailyHourPdv, '/times-movements/daily-hours-pdvs', 1)
    await search(newObjTagg, setDailyHourTransit, '/times-movements/daily-hours-transit', 1)
    await search(newObjTagg, setTotalPromoters, '/times-movements/total-promoters', 1)
    await search(newObjTagg, setTotalPdvs, '/times-movements/total-pdvs', 1)
    setLoading(false)
    // history.push(`/Analitycs/move?${queryFilter}`)
  }

  const cleanFilter = async () => {
    window.sessionStorage.removeItem('filterAnalytcs')

    history.push(`/Analitycs/move`)
    setVisible(false)
    setFilterTag({})
    formRef!.current!.reset()
  }

  const updateUrl = (obj: any) => {
    window.sessionStorage.setItem('filterAnalytcs', JSON.stringify(obj))

    let url: any = {}
    if (obj && obj.date) {
      url.date = JSON.stringify(obj.date)
    } else {
      url.date = [moment().startOf('week').format('YYYY-MM-DD'), moment().endOf('week').format('YYYY-MM-DD')]
    }

    if (obj && obj.pdvs_ids) {
      url.pdvs_ids = JSON.stringify(obj.pdvs_ids)
    }

    if (obj && obj.networks_ids) {
      url.networks_ids = JSON.stringify(obj.networks_ids)
    }

    if (obj && obj.states_ids) {
      url.states_ids = JSON.stringify(obj.states_ids)
    }

    if (obj && obj.regions_ids) {
      url.regions_ids = JSON.stringify(obj.regions_ids)
    }

    if (obj && obj.flags_ids) {
      url.flags_ids = JSON.stringify(obj.flags_ids)
    }

    if (obj && obj.promoters_ids) {
      url.promoters_ids = JSON.stringify(obj.promoters_ids)
    }
    history.push(`/Analitycs/move?${qs.stringify(url)}`)
  }

  const getStatusWithFilter = async (data?: any) => {
    let block = notAccess(permissions, window.location.pathname)
    if (block === -2) return history.push('/notAcces')

    let obj: any = { ...qs.parse(location.search.replaceAll('?', '')), ...data }
    if (obj && obj.promoters_ids && typeof obj.promoters_ids === 'string')
      obj.promoters_ids = JSON.parse(obj.promoters_ids)

    if (obj && obj.states_ids && typeof obj.states_ids === 'string') obj.states_ids = JSON.parse(obj.states_ids)

    if (obj && obj.flags_ids && typeof obj.flags_ids === 'string') obj.flags_ids = JSON.parse(obj.flags_ids)
    if (obj && obj.regions_ids && typeof obj.regions_ids === 'string') obj.regions_ids = JSON.parse(obj.regions_ids)
    if (obj && obj.networks_ids && typeof obj.networks_ids === 'string') obj.networks_ids = JSON.parse(obj.networks_ids)
    if (obj && obj.pdvs_ids && typeof obj.pdvs_ids === 'string') obj.pdvs_ids = JSON.parse(obj.pdvs_ids)
    // if (obj && obj.wek) obj.wek = JSON.parse(obj.wek)
    // if (obj && obj.uf) obj.uf = JSON.parse(obj.uf)
    if (obj && obj.date && typeof obj.date === 'string') obj.date = JSON.parse(obj.date)

    if (!obj || !obj.date)
      obj.date = [moment().startOf('week').format('YYYY-MM-DD'), moment().endOf('week').format('YYYY-MM-DD')]
    updateUrl(obj)
    setLoading(true)
    await search(obj, setDailyHour, '/times-movements/daily-hours', 1)
    await search(obj, setVisitState, '/times-movements/visits-state', 0)
    await search(obj, setVisitRegional, '/times-movements/visits-regional', 0)
    await search(obj, setVisitHoursPromoter, '/times-movements/visits-hours-promoters', 0)
    await search(obj, setVisitHoursFlag, '/times-movements/visits-hours-flags', 0)
    await search(obj, setDailyHourPdv, '/times-movements/daily-hours-pdvs', 1)
    await search(obj, setDailyHourTransit, '/times-movements/daily-hours-transit', 1)
    await search(obj, setTotalPromoters, '/times-movements/total-promoters', 1)
    await search(obj, setTotalPdvs, '/times-movements/total-pdvs', 1)
    setLoading(false)
  }

  useEffect(() => {
    let getValue = window.sessionStorage.getItem('filterAnalytcs')
    if (getValue !== undefined && getValue !== null && getValue !== 'undefined') {
      getStatusWithFilter(JSON.parse(getValue))
    } else {
      getStatusWithFilter()
    }
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div style={{ padding: '0 10px' }}>
      <Row gutter={[8, 8]} justify='space-between'>
        <Col span={20}>
          <h1>Tempos e movimentos</h1>
          <Breadcrumb className='breadcumb'>
            <Link to='/Analitycs/move'>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
            </Link>
            <Breadcrumb.Item>
              <Breadcrumb.Item>Tempos e movimentos</Breadcrumb.Item>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col>
          <Tooltip placement='top' title={'Expandir todos gráficos'}>
            <BigButton
              onClick={() => {
                setLoading(true)
                setExpanded('1')
                setTimeout(() => {
                  setLoading(false)
                }, 200)
              }}
              style={{ padding: 9 }}
            >
              <PlusOutlined />
            </BigButton>
          </Tooltip>
        </Col>
        <Col>
          <Tooltip placement='top' title={'Ocultar todos gráficos'}>
            <BigButton
              onClick={() => {
                setLoading(true)
                setExpanded('0')
                setTimeout(() => {
                  setLoading(false)
                }, 200)
              }}
              style={{ padding: 9 }}
            >
              <MinusOutlined />
            </BigButton>
          </Tooltip>
        </Col>
        <Col>
          <Tooltip placement='top' title={'Filtrar'}>
            <BigButton onClick={() => setVisible(!visible)} style={{ padding: 9 }}>
              <FilterOutlined />
            </BigButton>
          </Tooltip>
        </Col>
        <Col span={24}>
          <ContainerTagg>
            {filterTag.start_date && (
              <Tag color='geekblue' onClick={() => removeFilter('start_date', `${filterTag.start_date}`)}>
                {moment(filterTag.start_date).format('DD/MM/YYYY')}
                <CloseOutlined />
              </Tag>
            )}
            {filterTag.end_date && (
              <Tag color='geekblue' onClick={() => removeFilter('end_date', `${filterTag.end_date}`)}>
                {moment(filterTag.end_date).format('DD/MM/YYYY')}
                <CloseOutlined />
              </Tag>
            )}

            {filterTag.week && (
              <Tag color='geekblue' onClick={() => removeFilter('week', filterTag.week)}>
                {filterTag.week} <CloseOutlined />
              </Tag>
            )}
            {filterTag.uf && (
              <Tag color='geekblue' onClick={() => removeFilter('uf', filterTag.uf)}>
                {filterTag.uf} <CloseOutlined />
              </Tag>
            )}
            {filterTag.status && (
              <Tag color='geekblue' onClick={() => removeFilter('status', filterTag.status)}>
                {filterTag.status} <CloseOutlined />
              </Tag>
            )}
            {filterTag.flags_ids &&
              filterTag.flags_ids.map((el: any) => (
                <Tag color='geekblue' onClick={() => removeFilter('flags_ids', el.value)}>
                  {el.label} <CloseOutlined />
                </Tag>
              ))}
            {filterTag.states_ids &&
              filterTag.states_ids.map((el: any) => (
                <Tag color='geekblue' onClick={() => removeFilter('states_ids', el)}>
                  {el} <CloseOutlined />
                </Tag>
              ))}
            {filterTag.regions_ids &&
              filterTag.regions_ids.map((el: any) => (
                <Tag color='geekblue' onClick={() => removeFilter('regions_ids', el.value)}>
                  {el.label} <CloseOutlined />
                </Tag>
              ))}
            {filterTag.promoters_ids &&
              filterTag.promoters_ids.map((el: any) => (
                <Tag color='geekblue' onClick={() => removeFilter('promoters_ids', el.value)}>
                  {el.label} <CloseOutlined />
                </Tag>
              ))}
            {filterTag.networks_ids &&
              filterTag.networks_ids.map((el: any) => (
                <Tag color='geekblue' onClick={() => removeFilter('networks_ids', el.value)}>
                  {el.label} <CloseOutlined />
                </Tag>
              ))}
            {filterTag.pdvs_ids &&
              filterTag.pdvs_ids.map((el: any) => (
                <Tag color='geekblue' onClick={() => removeFilter('pdvs_ids', el.value)}>
                  {el.label} <CloseOutlined />
                </Tag>
              ))}
          </ContainerTagg>
        </Col>
      </Row>
      {hasIndex(['time_and_move.view'], permissions) > -1 && (
        <>
          <ContainerCardTop>
            <CardGraphStatic
              icon={<BiTimeFive size={25} color='#fff' />}
              value='07:20:00'
              description='MÉDIA CARGA HORARIA IDEAL
'
            />
            <CardGraphStatic
              icon={<BiTimeFive size={25} color='#fff' />}
              value={dailyHour}
              description='CARGA DIÁRIA'
            />
            <CardGraphStatic
              icon={<BiTimeFive size={25} color='#fff' />}
              value={dailyHour}
              description='CARGA HORARIA S/ ALMOÇO'
            />
            <CardGraphStatic
              icon={<BiTimeFive size={25} color='#fff' />}
              value={dailyHourPdv}
              description='JORNADA EM LOJA'
            />
            <CardGraphStatic
              icon={<BiTimeFive size={25} color='#fff' />}
              value={dailyHourTransit}
              description='DESLOCAMENTO DIÁRIO'
            />
            <CardGraphStatic icon={<BiTimeFive size={25} color='#fff' />} value='00:00:00' description='TEMPO ALMOÇO' />
            <CardGraphStatic
              icon={<HiOutlineIdentification size={25} color='#fff' />}
              value={totalPromoters}
              description='PROMOTORES'
            />
            <CardGraphStatic
              icon={<HiOutlineIdentification size={25} color='#fff' />}
              value={totalPdvs}
              description="PDV's"
            />
          </ContainerCardTop>
        </>
      )}
      {loading ? (
        <Loading />
      ) : (
        <>
          <Row gutter={[20, 20]}>
            <Col span={12}>
              <ContainerFullGraph>
                <Collapse bordered={false} defaultActiveKey={[expanded]}>
                  <Collapse.Panel header='Visitas Realizadas por regional' key='1'>
                    <ColumnDash categories={visitRegional.categories} series={[{ data: visitRegional.data }]} />
                  </Collapse.Panel>
                </Collapse>
              </ContainerFullGraph>
            </Col>

            <Col span={12}>
              <ContainerFullGraph>
                <Collapse bordered={false} defaultActiveKey={[expanded]}>
                  <Collapse.Panel header='Média de Tempo em Lojas por Bandeira vs visitas realizadas' key='1'>
                    <MapDash
                      valueMap={visitState.categories.map((el: any, index: number) => {
                        return {
                          id: `BR-${el}`,
                          value: visitState.data[index],
                          label: `BR-${el}`,
                        }
                      })}
                    />
                  </Collapse.Panel>
                </Collapse>
              </ContainerFullGraph>
            </Col>
          </Row>
          <ContainerFullGraph>
            <Collapse bordered={false} defaultActiveKey={[expanded]}>
              <Collapse.Panel header='Média de Tempo trabalhado por colaborador' key='1'>
                <ColumnDash
                  loading={loading}
                  id='full-bar-dash'
                  categories={visitHoursPromoter.categories}
                  series={[
                    {
                      data: visitHoursPromoter.data.map((time: any) => {
                        if (!!!time) return '0'
                        return time.replaceAll(':', '')
                      }),
                    },
                  ]}
                  type='time'
                  legend={false}
                />
              </Collapse.Panel>
            </Collapse>
          </ContainerFullGraph>
          <ContainerFullGraph>
            <Collapse bordered={false} defaultActiveKey={[expanded]}>
              <Collapse.Panel header='Média de Tempo em Lojas por Bandeira vs visitas realizadas' key='1'>
                <ColumnDash
                  id='full-bar-dash'
                  categories={visitHoursFlag.categories}
                  series={[
                    {
                      data: visitHoursFlag.data.map((time: any) => {
                        if (!!!time) return '0'
                        return time.replaceAll(':', '')
                      }),
                    },
                  ]}
                  legend={false}
                  type='time'
                />
              </Collapse.Panel>
            </Collapse>
          </ContainerFullGraph>
        </>
      )}
      <DrawerFilter
        visible={visible}
        close={setVisible}
        title='Tempos e movimentos'
        footer={
          <ContainerButtonsFootter>
            <ButtonSearch type='button' onClick={() => formRef?.current?.submitForm()}>
              <FileSearchOutlined />
              Pesquisar
            </ButtonSearch>
            <ButtonClear onClick={() => cleanFilter()}>
              <ClearOutlined />
              Limpar
            </ButtonClear>
          </ContainerButtonsFootter>
        }
      >
        <ContainerFormFilter
          tabIndex={0}
          onKeyDown={(evt: any) => {
            if (evt.key === 'Enter') formRef?.current?.submitForm()
          }}
        >
          <Form ref={formRef} className='form' style={{ marginTop: '15px' }} onSubmit={getStatusWithFilter}>
            <SimpleRangePicker
              name='date'
              label='Selecione o periodo:'
              defaultValueInput={[moment().subtract(1, 'd'), moment().subtract(1, 'd')]}
              ranges={{
                Ontem: [moment().subtract('d', 1), moment().subtract('d', 1)],
                Hoje: [moment(), moment()],
                Amanhã: [moment().add('d', 1), moment().add('d', 1)],
                'Esta semana': [moment().startOf('week'), moment().endOf('week')],
                'Este mês': [moment().startOf('month'), moment().endOf('month')],
              }}
            />
            <GroupForm>
              <LabelForm>Selecione a promotor</LabelForm>
              <Search placeholder='Selecione o promotor' name='promoters_ids' path='promoters' isMult={true} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione a bandeira</LabelForm>
              <Search placeholder='Selecione a bandeira' name='flags_ids' path='flags' isMult={true} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione a rede</LabelForm>
              <Search placeholder='Selecione a rede' name='networks_ids' path='networks' isMult={true} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione o pdv</LabelForm>
              <Search placeholder='Selecione o pdv' name='pdvs_ids' path='pdvs' isMult={true} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione a região</LabelForm>
              <Search placeholder='Selecione a região' name='regions_ids' path='regions' isMult={true} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione o estado</LabelForm>
              <Select
                styles={customStyles}
                className='pre-select'
                placeholder='Selecione o status'
                options={optionUF}
                isMulti={true}
                name='states_ids'
              />
            </GroupForm>
          </Form>
        </ContainerFormFilter>
      </DrawerFilter>
    </div>
  )
}

export default Move
