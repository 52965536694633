import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding-left: 50px;
  padding-right: 50px;
  overflow: auto;
  display: flex;
  flex-direction: column;
`
export const Preview = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ContainerQuestion = styled.div`
  margin-top: 20px;
  width: 100%;
  margin-bottom: 50px;
  border-radius: 15px;
  box-shadow: rgb(0 0 0 / 30%) 0px 2px 4px;
  background: rgb(245 245 245);
  padding: 20px;

  &&& .ant-form-item-label{
    display: none;
  }
`
export const Description = styled.div`
  margin-bottom: 20px;
  font-size: 16px;
  letter-spacing: 1.5px;
`

export const TitleText = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  font-size: 20px;
  color: rgb(0, 0, 0, 0.7);
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  h1{
    margin-right: 20px;
    color: #FABA48;
    font-size: 23px;
    margin-bottom: 0;
  }
`
export const TitleDATE = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  font-size: 20px;
  color: rgb(0, 0, 0, 0.7);
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  h1{
    margin-right: 20px;
    color: #4FA9B3;
    font-size: 23px;
    margin-bottom: 0;
  }
`
export const TitleMoney = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  font-size: 20px;
  color: rgb(0, 0, 0, 0.7);
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  h1{
    margin-right: 20px;
    color: #E26D5A;
    font-size: 23px;
    margin-bottom: 0;
  }
`
export const TitlePicture = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  font-size: 20px;
  color: rgb(0, 0, 0, 0.7);
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  h1{
    margin-right: 20px;
    color: #A086C3;
    font-size: 23px;
    margin-bottom: 0;
  }
`
export const TitleBoolean = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  font-size: 20px;
  color: rgb(0, 0, 0, 0.7);
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  h1{
    margin-right: 20px;
    color: #BF415C;
    font-size: 23px;
    margin-bottom: 0;
  }
`
export const TitleInteger = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  font-size: 20px;
  color: rgb(0, 0, 0, 0.7);
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  h1{
    margin-right: 20px;
    color: #E05178;
    font-size: 23px;
    margin-bottom: 0;
  }
`
export const TitleDecimal = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  font-size: 20px;
  color: rgb(0, 0, 0, 0.7);
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  h1{
    margin-right: 20px;
    color: #E26D5A;
    font-size: 23px;
    margin-bottom: 0;
  }
`
export const TitleChoise = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  font-size: 20px;
  color: rgb(0, 0, 0, 0.7);
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  h1{
    margin-right: 20px;
    color: #BAC26C;
    font-size: 23px;
    margin-bottom: 0;
  }
`
export const TitleMultiple = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  font-size: 20px;
  color: rgb(0, 0, 0, 0.7);
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  h1{
    margin-right: 20px;
    color: red;
    font-size: 23px;
    margin-bottom: 0;
  }
`

export const ButtonPerson = styled.button`
  background-color: #7157C1;
  border: none;
  width: 90px;
  height: 45px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 15px;
  font-weight: bold;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  &:hover{
    transition: all 0.3s;
    border: #7157C1 2px solid;
    background-color: white;
    color: #7157C1;
  }
`