import {
  StopOutlined,
  CheckCircleOutlined,
  ForkOutlined,
  MoreOutlined,
  DeleteOutlined,
  CalendarOutlined,
  CopyOutlined,
} from '@ant-design/icons'
import { MdEdit, MdPlaylistAddCheck } from 'react-icons/md'
import { Tooltip, Popconfirm, message, Dropdown, Menu, Button } from 'antd'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import useReactRouter from 'use-react-router'
import { compareByAlph } from '../../../utils/funcoes'
import api_v2 from '../../../services/api-v2'
import { ModalImportGeneral } from '../../../components/ModalImportGeneral'
import { ModalMixForm } from '../../../components/ModalMixForm'
import { DataStatus, StatusInativo, StatusRealizado } from '../../../components/TabsVisits/style'
import { ModalRemoveMixForm } from '../../../components/ModalRemoveMixForm'
import { Mixpanel } from '../../../utils/mixpanel'
import DefaultPage from '../../../components/DefaultPage'
import { isActiveSelect } from '../../../utils/option-user'
import { DrawerScheduleTask } from '../../../components/DrawerScheduleTask'
import { ContextUpload } from '../../../context/contextUpload'
import { hasIndex } from '../../../components/Menu/menuHorizonte'
import { ModalCopyForm } from '../../../components/ModalCopyForm'

export interface FormInformation {
  id: string
  name: string
  is_active: boolean
  created_at: string
  is_all_visits: boolean
}

const FormListPage = () => {
  const { location } = useReactRouter()
  const { permissions } = ContextUpload()
  const [reload, setReload] = useState(false)
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openModalRemove, setOpenModalRemove] = useState<boolean>(false)
  const [openModalCopyForm, setOpenModalCopyForm] = useState<boolean>(false)
  const [idModal, setIdModal] = useState<any>(0)
  const [infoForm, setInfoForm] = useState<any>({})
  const [formSelected, setFormSelected] = useState<any>({})
  const [visibleImport, setVisibleImport] = useState<boolean>(false)
  const [openSchedule, setOpenSchedule] = useState({
    visible: false,
    id: -1,
    context: '',
  })
  let getSharement = sessionStorage.getItem('@rocketpdv:workspace')
  let newGetWorkspace: any = {}
  if (getSharement) newGetWorkspace = JSON.parse(getSharement)

  const enableForm = async (id: string) => {
    setLoading(true)
    try {
      await api_v2.put(`/form/enable/${id}`)
      message.info('O status do formulário foi alteradao!')
      Mixpanel.track('Ativou formulário', {
        params: {
          id: id,
        },
      })
      setReload(true)
    } catch (err) {
      message.error('Não foi possivel ativar o formulário')
    }
    setLoading(false)
  }

  const disableForm = async (id: any) => {
    setLoading(true)
    try {
      await api_v2.put(`/form/disable/${id}`)
      message.info('O status do formulário foi alteradao!')
      Mixpanel.track('Desativou formulário', {
        params: {
          id: id,
        },
      })
      setReload(true)
    } catch (err) {
      message.error('Não foi possivel inativar o formulário')
    }
    setLoading(false)
  }

  const copyForm = async (id: any) => {
    setLoading(true)
    try {
      await api_v2.put(`/form/copy/${id}`)
      message.info('Formulário copiado com sucesso!')
      Mixpanel.track('Copiou formulário', {
        params: {
          id: id,
        },
      })
      setReload(true)
    } catch (err) {
      message.error('Não foi possivel copiar o formulário')
    }
    setLoading(false)
  }

  const getStatus = (status: any) => {
    const listStatus: any = {
      PENDENT: (
        <Tooltip title='Inativo'>
          <StatusInativo />
        </Tooltip>
      ),
      COMPLETE: (
        <Tooltip title='Ativo'>
          <StatusRealizado />
        </Tooltip>
      ),
    }

    if (status === true) {
      return listStatus.COMPLETE
    } else if (status === false) {
      return listStatus.PENDENT
    } else {
      return '-'
    }
  }

  const deleteForm = async (id: string) => {
    setLoading(true)
    try {
      const { data } = await api_v2.delete('form/delete/' + id)
      Mixpanel.track('Deletou formulário', {
        params: { id: id },
      })

      message.success(data.msg ? data.msg : 'Formulário deletado com sucesso!')
      setReload(!reload)
    } catch (err) {
      message.error('Erro ao deletar formulário')
    }
    setLoading(false)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  return (
    <>
      <DefaultPage
        titlePage='Formulários'
        breadcrumb={[{ name: 'Home', url: '/' }, { name: 'Formulários' }]}
        urlPage='/backoffice/survey/forms'
        routeApi={{
          method: 'get',
          route: 'form',
          versionApi: 'v2',
          reload,
        }}
        listButtons={{
          buttonCreate: {
            visible: hasIndex(['form.add_form'], permissions) > -1,
            type: 'url',
            url: '/backoffice/survey/forms/create',
          },
          buttonFilter: {
            visible: true,
            filters: [
              {
                label: 'Selecione o formulário:',
                name: 'name',
                type: 'search',
                urlSearch: 'forms',
                getEnv: 'label',
              },
              {
                label: 'Selecione o status',
                name: 'is_active',
                type: 'select',
                options: isActiveSelect,
                getEnv: 'value',
              },
              {
                label: 'Selecione a empresa',
                urlSearch: 'sub-workspaces',
                name: 'sub_workspace',
                type: 'search',             
                getEnv: 'value',
                invisible:!newGetWorkspace.shared_environment

              },
            ],
          },
        }}
        load={{ loading, setLoading }}
        columns={[
          {
            title: 'Status',
            dataIndex: 'is_active',
            key: 'status',
            width: 80,
            render: (status: any) => {
              return <DataStatus>{getStatus(status)}</DataStatus>
            },
          },
          {
            title: 'Nome',
            dataIndex: 'name',
            width: '50%',
            sorter: (a: any, b: any) => compareByAlph(a.name, b.name),
          },
          {
            title: 'Criado em',
            render: (text: any) => {
              return <p>{moment(text.created_at).format('DD/MM/YYYY')}</p>
            },
          },
          {
            title: '#',
            width: 50,
            render: (obj: any) => {
              return (
                <Dropdown
                  overlay={
                    <Menu>
                      {hasIndex(['form.toggle_form'], permissions) > -1 && (
                        <Menu.Item key='1' icon={obj.is_active ? <StopOutlined /> : <CheckCircleOutlined />}>
                          {obj.is_active ? (
                            obj.is_active === true && (
                              <Popconfirm
                                title='Tem certeza que deseja inativar esta formulário?'
                                onConfirm={() => {
                                  Mixpanel.track('Inativou formulário', obj)
                                  disableForm(obj.id)
                                }}
                                okText='Sim'
                                cancelText='Não'
                                placement='topRight'
                              >
                                Inativar formulário
                              </Popconfirm>
                            )
                          ) : (
                            <Popconfirm
                              title='Tem certeza que deseja ativar esta formulário?'
                              onConfirm={() => {
                                Mixpanel.track('Ativou formulário', obj)
                                enableForm(obj.id)
                              }}
                              okText='Sim'
                              cancelText='Não'
                              placement='topRight'
                            >
                              Ativar formulário
                            </Popconfirm>
                          )}
                        </Menu.Item>
                      )}
                      {hasIndex(['form.toggle_form'], permissions) > -1 && (
                        <Menu.Item key='1' icon={<CopyOutlined />}>
                          <Popconfirm
                            title='Tem certeza que deseja duplicar esta formulário?'
                            onConfirm={() => {
                              Mixpanel.track('Duplicou formulário', obj)
                              copyForm(obj.id)
                            }}
                            okText='Sim'
                            cancelText='Não'
                            placement='topRight'
                          >
                            Duplicar formulário
                          </Popconfirm>
                        </Menu.Item>
                      )}
                      <Menu.Item
                        key='3'
                        icon={<CalendarOutlined color={'#7158c1'} />}
                        onClick={() => {
                          setInfoForm(obj)
                          setOpenModalCopyForm(!openModal)
                        }}
                      >
                        Copiar formulário para outro ambiente
                      </Menu.Item>
                      {hasIndex(['form.toggle_form'], permissions) > -1 && (
                        <Menu.Item key='1' icon={<DeleteOutlined />}>
                          <Popconfirm
                            title='Tem certeza que deseja deletar este formulário?'
                            onConfirm={async () => {
                              setLoading(!loading)

                              Mixpanel.track('Confirmou deletar formulário', obj)
                              await deleteForm(obj.id)

                              setLoading(!loading)
                              setReload(!reload)
                            }}
                            okText='Sim'
                            cancelText='Não'
                            placement='topRight'
                          >
                            Deletar formulario
                          </Popconfirm>
                        </Menu.Item>
                      )}
                      {hasIndex(['form.change_form'], permissions) > -1 && (
                        <Menu.Item key='2' icon={<MdEdit />} onClick={() => Mixpanel.track('Editar formulario', obj)}>
                          <Link to={`/backoffice/survey/forms/create/${obj.id}`}>Editar formulário</Link>
                        </Menu.Item>
                      )}

                      <Menu.Item
                        key='3'
                        icon={<CalendarOutlined color={'#7158c1'} />}
                        onClick={() => {
                          setOpenSchedule({ visible: true, id: obj.id, context: obj.context })
                        }}
                      >
                        Visualizar agendamento de tarefa
                      </Menu.Item>

                      {!obj.is_all_visits && hasIndex(['form.import_form_mix'], permissions) > -1 && (
                        <Menu.Item
                          key='3'
                          icon={<MdPlaylistAddCheck color={'#7158c1'} />}
                          onClick={() => {
                            setFormSelected(obj)
                            setVisibleImport(true)
                          }}
                        >
                          Criar mix de formulários
                        </Menu.Item>
                      )}
                      {obj.context === 'CATEGORY' && obj.category_type === 'SOME' && (
                        <Menu.Item
                          key='3'
                          icon={<MdPlaylistAddCheck color={'#7158c1'} />}
                          onClick={() => {
                            setFormSelected(obj)
                            setVisibleImport(true)
                          }}
                        >
                          Criar mix de categorias
                        </Menu.Item>
                      )}
                      {!obj.is_all_visits && (
                        <Menu.Item
                          icon={<ForkOutlined />}
                          onClick={() => {
                            setIdModal(obj.id)
                            setOpenModal(!openModal)
                          }}
                        >
                          Visualizar mix
                        </Menu.Item>
                      )}
                      {!obj.is_all_visits && hasIndex(['form.remove_form_mix'], permissions) > -1 && (
                        <Menu.Item
                          icon={<DeleteOutlined />}
                          onClick={() => {
                            setInfoForm(obj)
                            setOpenModalRemove(!openModal)
                          }}
                        >
                          Deletar mix
                        </Menu.Item>
                      )}
                    </Menu>
                  }
                >
                  <Button size='small' shape='circle' icon={<MoreOutlined />} />
                </Dropdown>
              )
            },
          },
        ]}
      />
      {visibleImport && (
        <ModalImportGeneral
          visible={visibleImport}
          close={setVisibleImport}
          title='Importar mix de formulários'
          path='MIX_FORM'
          config={{ form_id: formSelected.id }}
        />
      )}
      {openModal && <ModalMixForm visible={openModal} close={setOpenModal} id={idModal} />}

      <ModalRemoveMixForm visible={openModalRemove} close={setOpenModalRemove} form={infoForm} />
      <ModalCopyForm visible={openModalCopyForm} close={setOpenModalCopyForm} form={infoForm} />
      <DrawerScheduleTask
        visible={openSchedule.visible}
        close={() => setOpenSchedule({ visible: false, id: -1, context: '' })}
        id={openSchedule.id}
        context={openSchedule.context}
      />
    </>
  )
}

export default FormListPage
