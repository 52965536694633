import { Breadcrumb, Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import api from '../../../services/api'
import { Loading } from '../../../components/Loading'
import { ContentDivider } from './styles'
import DetailSurvey from '../../../components/DetailSurvey'
import api_v2 from '../../../services/api-v2'
import { IAnswer, IDetailVisit } from '../../Survey/VisitsDetail'
import { ButtonBack } from '../../Team/ViewUser/styles'
import { LeftOutlined } from '@ant-design/icons'
import { ContainerList } from '../../../GlobalStyles'
import DetailSurvey2 from '../../../components/DetailSurvey2'

const SearchViewPage: React.FC = () => {
  const { form_id, user_id, visit_id }: any = useParams()
  const historyBack = useHistory()

  const [user, setUser] = useState<boolean>(false)
  const [detailVisit, setDetailVisit] = useState<IDetailVisit>({} as IDetailVisit)
  const [viewAnswers, setViewAnswers] = useState<any>({})
  const [loading, setLoading] = useState(true)

  const loadPerfis = async () => {
    const { data } = await api.get(`/user/${user_id}`)
    setUser(data)
  }

  const loadVisit = async () => {
    const { data } = await api_v2.get(`/visit/${visit_id}`)
    setDetailVisit(data)
  }

  const loadAnswer = async () => {
    const { data } = await api_v2.get(`/visit/${visit_id}/answers?form_id=${form_id}`)
    const newList = []

    data.forEach((item) => {
       newList.push({
          ...item,
          product_name: item.product.name,
          product_id: item.product.id,
          form_name: item.form.name,
          form_context: item.form.context,
          form_id: item.form.id,
          answer_id: item.id,
          answer: [item.answers.map((el) => ({
            ...el,
            product_name: item.product.name,
            product_id: item.product.id,
            form_name: item.form.name,
            form_id: item.form.id,
            answer_id: item.id,
            form_context: item.form.context,
          }))],
        })
    })

    console.log(newList, 'data')
    setViewAnswers(newList)
  }

  const getAllRotes = async () => {
    setLoading(true)
    await loadAnswer()
    await loadVisit()
    await loadPerfis()
    setLoading(false)
  }
  useEffect(() => {
    getAllRotes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ContainerList style={{ padding: 0 }}>
      {loading ? <Loading /> : null}
      <Row gutter={[8, 8]} justify='space-between' style={{ padding: '26px 32px' }}>
        <Col>
          <Breadcrumb>
            <Link to='/backoffice/reports/search'>
              <Breadcrumb.Item>Relatórios</Breadcrumb.Item>
            </Link>
            <Breadcrumb.Item>Pesquisa</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col>
          <ButtonBack onClick={() => {
            historyBack.goBack()
          }}>
            <LeftOutlined />
            Voltar
          </ButtonBack>
        </Col>
      </Row>

      <ContentDivider>
        {loading ? <Loading /> : <DetailSurvey2 user={user} detailVisit={detailVisit} answer={viewAnswers} />}
      </ContentDivider>
    </ContainerList>
  )
}
export default SearchViewPage
