import React, { useState, useRef, useEffect } from 'react';
import { Label } from './styles';
import CurrencyInput from 'react-currency-input-field';
import { useField } from '@unform/core'

interface Iinput {
  label?: string;
  name: any;
  children?: any;
  type?: string;
  placeholder?: any;
  value?: any;
}

type InputProps = JSX.IntrinsicElements['input'] & Iinput;

export default function InputNumberDefault({
  label,
  name,
  type,
  children,
  placeholder,
  value,
  ...rest
}: InputProps) {
  const { fieldName, registerField, defaultValue, error } = useField(name)

  const format = (value) => {
    console.log(value)
    if(!value) return undefined

    const valorEmTexto = value;

    // Remova o símbolo "R$", os pontos de milhar e substitua a vírgula por um ponto.
    const valorLimpo = valorEmTexto.replace(/[^0-9,]/g, '').replace(',', '.');

    // Converta o valor em um número de ponto flutuante (float).
    const valorFloat = parseFloat(valorLimpo);
    console.log(valorLimpo)
    return valorFloat
  }


  const inputRef = useRef({ value: format(defaultValue) });
  const [inputValue, setInputValue] = useState(format(defaultValue));

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = inputValue;
    }
  }, [inputValue]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
      setValue: (_, value: string) => {
        setInputValue(format(value));
      },
      clearValue: () => {
        setInputValue(null);
      }
    });
  }, [fieldName, registerField])


  useEffect(() => {
    setInputValue(format(defaultValue))
  }, [defaultValue])
 
  return (
    <Label htmlFor={name}>
      <CurrencyInput
        name={name}
        defaultValue={defaultValue}
        value={inputValue}
        onValueChange ={(value: any, valu2) => setInputValue(value)}
        intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
        decimalsLimit={2}
        allowNegativeValue={false}
        decimalSeparator="," 
        groupSeparator="." 
        disableGroupSeparators={false}
      />
    </Label>
  );
}
