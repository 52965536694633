import React, { ReactElement } from 'react'
import { Body, Container, Header } from './style'
import { LoadingOutlined } from '@ant-design/icons'

interface Props {
  value: string
  icon: ReactElement
  description: string
}

const CardGraphStatic = ({ value, icon, description }: Props) => {
  return (
    // <Col flex='190px'>
    <Container>
      <Header>
        {value === '-1' ? <LoadingOutlined /> : <p>{value} </p>}
        {icon}
      </Header>
      <Body>
        <p>{description}</p>
      </Body>
    </Container>
    // </Col>
  )
}

export default CardGraphStatic
