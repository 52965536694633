import React, { useState, useRef } from 'react'
import { Button, ContainerModal, ContainerStep2, LabelCheck } from './style'
import AddUser from '../../assets/images/addUser.png'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import { message } from 'antd'
import { Loading } from '../Loading'
import { Search } from '../form/SimpleSearch'
import api_v2 from '../../services/api-v2'

interface IProps {
  visible: boolean
  close: (boolean: any) => void
  visit: any
  reloadApi?: (boolean: any) => void
  type: string
}

export const ModalAddSubWorkspaceVisit = ({ visible, close, visit, reloadApi, type }: IProps) => {
  const formRef = useRef<FormHandles>(null)
  const [loading, setLoading] = useState<boolean>(false)

  async function handleSubmit(body: any) {
    setLoading(true)
    try {
      const { sub_workspace_id } = body
      if (sub_workspace_id.length === 0) {
        message.error('Seleciona ao menos uma empresa')
        return
      }

      const { data } = await api_v2.post(`/workspace-sub/${type}/${visit.id}`, {
        sub_workspace_id: sub_workspace_id.map((list: any) => list.value),
      })
      setLoading(false)
      if (data.msg) {
        message.error(data.msg)
        return
      }
      if (reloadApi) {
        reloadApi(true)
      }
      message.success(`Subworkspace adicionada com sucesso`)
      close(false)
    } catch (err) {
      setLoading(false)
      if (err instanceof Yup.ValidationError) {
        message.error('Erro ao adicionar Subworkspace!')
      }
    }
    setLoading(false)
  }

  return (
    <ContainerModal
      visible={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
      destroyOnClose={true}
    >
      {loading && <Loading />}
      <Form className='form' ref={formRef} initialData={{ is_active: true }} onSubmit={handleSubmit}>
        <ContainerStep2>
          <h3>Adicionar de Sub Workspace</h3>
          <img src={AddUser} alt='user' />
          <LabelCheck>Selecione a empresa</LabelCheck>
          <Search placeholder='Selecione o empresa' name='sub_workspace_id' path='sub-workspaces' isMult={true} />
          <Button type='submit' style={{ width: '100%', height: '55px', marginTop: 20 }}>
            Adicionar
          </Button>
        </ContainerStep2>
      </Form>
    </ContainerModal>
  )
}
