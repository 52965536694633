import { Form, Input } from '@rocketseat/unform'
import { message } from 'antd'
import React, { useState } from 'react'
import { MdLocalPostOffice, MdNoEncryption } from 'react-icons/md'
import history from '../../routes/history'
import api from '../../services/api'
import { TOKEN_DATA_USER, TOKEN_KEY, TOKEN_USER } from '../../utils/login'
import { Mixpanel } from '../../utils/mixpanel'
import { Loading } from '../../components/Loading'
import Logo from '../../assets/images/logo-backoffice.png'
import LogoWhite from '../../assets/images/rocket-white.png'
import { Button, Container, FormContent, FormGroup, Icon, ResetLink, BoxSignIn, Title, Footer } from './styles'
import { ModalForgatPassword } from '../../components/ModalForgatPassword'

const SignInPage: React.FC = () => {
  const [loading, setLoading] = useState<any>(false)
  const [password, setPassword] = useState<any>(false)
  async function handleSubmit(body: any) {
    setLoading(true)
    try {
      body.email = body.email?.toLowerCase()

      const { data } = await api.post('/auth/login', { ...body })
      const { id, name, email, cpf, type, created_at } = data.user

      if (type !== 'PROMOTER' && type !== 'SUPERVISOR') {
        localStorage.setItem(TOKEN_KEY, data.token)
        localStorage.setItem(TOKEN_USER, data.user.name)
        localStorage.setItem(TOKEN_DATA_USER, JSON.stringify(data.user))
        Mixpanel.identify(id.toString())
        Mixpanel.people.set({
          $email: email,
          $cpf: cpf,
          $type: type,
          $name: name,
          created_at,
        })
        history.push('/backoffice/workspace')
      } else {
        return message.error('Esse usuário não possui acesso, verifique os acessos com seu superior')
      }
    } catch (err) {
      message.error('Usuário ou senha incorreto!')
    }
    setLoading(false)
  }

  return (
    <Container>
      {loading ? <Loading /> : null}
      <BoxSignIn>
        <img src={Logo} alt='logo' />
        <Form onSubmit={handleSubmit}>
          <Title>Faça login</Title>
          <FormContent>
            <FormGroup>
              <Icon>
                <MdLocalPostOffice />
              </Icon>
              <Input type='email' name='email' placeholder='meu@email.com' />
            </FormGroup>
            <FormGroup>
              <Icon>
                <MdNoEncryption />
              </Icon>
              <Input name='password' type='password' placeholder='senha' />
            </FormGroup>
          </FormContent>
          <Button type='submit'>Entrar</Button>
          <ResetLink onClick={() => setPassword(!password)}>Esqueci minha senha</ResetLink>
        </Form>
      </BoxSignIn>
      <Footer>
        <p>desenvolvido por</p>
        <img src={LogoWhite} alt='logo' />
      </Footer>
      <ModalForgatPassword visible={password} close={(close) => setPassword(close)} />
    </Container>
  )
}

export default SignInPage
