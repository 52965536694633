import { Breadcrumb, Col, Collapse, Row, Select as SelectAntd, Table, Tag, Tooltip } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { DrawerFilter } from '../../../components/DrawerFilter'
import { Loading } from '../../../components/Loading'
import { BigButton, ContainerButtonsFootter, ContainerFormFilter, customStyles, GroupForm } from '../../../GlobalStyles'
import { ContainerTagg } from '../../Survey/FormListPage/styles'
import { ButtonClear, ButtonSearch, LabelForm } from '../../Team/Promoter/style'
import {
  FileSearchOutlined,
  FilterOutlined,
  PlusOutlined,
  MinusOutlined,
  CloseOutlined,
  ClearOutlined,
} from '@ant-design/icons'
import { FormHandles } from '@unform/core'
import history from '../../../routes/history'
import { Form } from '@unform/web'
import { Search } from '../../../components/form/SimpleSearch'
import SimpleRangePicker from '../../../components/form/SimpleRangePicker'
import Select from '../../../components/form/SimpleSelect'
import { isActiveSelectVisita } from '../../../utils/option-user'
import moment from 'moment'
import qs from 'querystring'
import useReactRouter from 'use-react-router'
import { createRandomDataTable } from '../../../components_analytcs/Table'
import { ContainerFullGraph } from '../move/style'
import MapDash from '../../../components_analytcs/MapDash'
import DonultsDash from '../../../components_analytcs/DonultsDash'
import { randonValue } from '../../../components_analytcs/ColumnsDash'
import { SubContainer } from './style'
import { ContainerTable, ContainerPorcent } from '../../../components_analytcs/Table/style'
import { CirculeStatus, ContainerStatus } from '../justify/style'
import { Link } from 'react-router-dom'
const { Column, ColumnGroup } = Table

function RankingPage() {
  const formRef = useRef<FormHandles>(null)
  const { location } = useReactRouter()
  const [filterTag, setFilterTag] = useState<any>({})
  const [visible, setVisible] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [filters, setFilters] = useState<any>({})
  const [urlFilter, setUrlFilter] = useState<string>('')
  const [expanded, setExpanded] = useState<string>('1')

  async function searchRanking(body: any) {
    let { date, flag, promoter, regional, status, uf, week } = body

    let filters: any = {
      filterQuery: {},
      dataTag: {},
      filterQueryUrl: {},
    }

    if (promoter && promoter.value) {
      filters.filterQuery.promoter = promoter.value
      filters.dataTag = { ...filters.dataTag, promoter }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, promoter: JSON.stringify(promoter) }
    }

    if (regional) {
      filters.filterQuery.regional = regional
      filters.dataTag = { ...filters.dataTag, regional }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, regional: regional }
    }

    if (status) {
      filters.filterQuery.status = status
      filters.dataTag = { ...filters.dataTag, status }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, status: status }
    }

    if (uf) {
      filters.filterQuery.uf = uf
      filters.dataTag = { ...filters.dataTag, uf }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, uf: uf }
    }

    if (week) {
      filters.filterQuery.week = week
      filters.dataTag = { ...filters.dataTag, week }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, week: week }
    }

    if (flag && flag.value) {
      filters.filterQuery.flag = flag.value
      filters.dataTag = { ...filters.dataTag, flag }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, flag: JSON.stringify(flag) }
    }

    if (date) {
      filters.filterQueryUrl = { ...filters.filterQueryUrl, date: JSON.stringify(date) }
      if (date[0]) {
        filters.filterQuery.start_date = date[0]
        filters.dataTag = { ...filters.dataTag, start_date: date[0] }
      }

      if (date[1]) {
        filters.filterQuery.end_date = date[1]
        filters.dataTag = { ...filters.dataTag, end_date: date[1] }
      }
    } else {
      filters.filterQuery = {
        ...filters.filterQuery,
        end_date: moment().format('YYYY-MM-DD'),
        start_date: moment().format('YYYY-MM-DD'),
      }
      filters.dataTag = {
        ...filters.dataTag,
        end_date: moment().format('YYYY-MM-DD'),
        start_date: moment().format('YYYY-MM-DD'),
      }
    }
    const queryFilter = qs.stringify({ ...filters.filterQuery })

    setUrlFilter(queryFilter)
    // const { data } = await api_v2.get(`/visit?${queryFilter}`)
    setLoading(false)
    setFilterTag(filters.dataTag)
    setVisible(false)
    history.push(`/Analitycs/team/ranking?${qs.stringify({ ...filters.filterQueryUrl })}`)
  }

  const removeFilter = async (typeRemove: string, value: string) => {
    let newUrl: any = qs.parse(urlFilter)
    let newObjTagg = filterTag

    if (typeRemove === 'promoter') {
      delete newObjTagg.promoter
      delete newUrl.promoter
    }
    if (typeRemove === 'flag') {
      delete newObjTagg.flag
      delete newUrl.flag
    }
    if (typeRemove === 'week') {
      delete newObjTagg.week
      delete newUrl.week
    }
    if (typeRemove === 'uf') {
      delete newObjTagg.uf
      delete newUrl.uf
    }
    if (typeRemove === 'status') {
      delete newObjTagg.status
      delete newUrl.status
    }
    if (typeRemove === 'regional') {
      delete newObjTagg.regional
      delete newUrl.regional
    }
    const queryFilter = qs.stringify({ ...newUrl })

    setLoading(true)
    // const { data } = await api_v2.get(`/visit?${queryFilter}`)
    // setForms({ ...data, loading: false })
    setUrlFilter(queryFilter)
    setFilterTag(newObjTagg)
    setLoading(false)
    history.push(`/Analitycs/team/ranking?${queryFilter}`)
  }

  const cleanFilter = async () => {
    history.push(`/Analitycs/team/ranking`)
    setVisible(false)
    setFilterTag({})
    formRef!.current!.reset()
  }

  const getStatusWithFilter = () => {
    let obj: any = qs.parse(location.search.replaceAll('?', ''))
    if (obj && obj.promoter) obj.promoter = JSON.parse(obj.promoter)
    if (obj && obj.flag) obj.flag = JSON.parse(obj.flag)
    if (obj && obj.status) obj.status = JSON.parse(obj.status)
    if (obj && obj.regional) obj.regional = JSON.parse(obj.regional)
    if (obj && obj.wek) obj.wek = JSON.parse(obj.wek)
    if (obj && obj.uf) obj.uf = JSON.parse(obj.uf)
    if (obj && obj.date) obj.date = JSON.parse(obj.date)

    if (!obj || !obj.date)
      obj.date = [moment().startOf('week').format('YYYY-MM-DD'), moment().endOf('week').format('YYYY-MM-DD')]
    searchRanking(obj)
  }

  useEffect(() => {
    getStatusWithFilter()
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div style={{ padding: '0 10px' }}>
      <Row gutter={[8, 8]} justify='space-between'>
        <Col span={20}>
          <h1>Ranking Atendimento</h1>
          <Breadcrumb className='breadcumb'>
            <Link to='/Analitycs'>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
            </Link>
            <Breadcrumb.Item>
              <Breadcrumb.Item>Ranking Atendimento</Breadcrumb.Item>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col>
          <Tooltip placement='top' title={'Expandir todos gráficos'}>
            <BigButton
              onClick={() => {
                setLoading(true)
                setExpanded('1')
                setTimeout(() => {
                  setLoading(false)
                }, 200)
              }}
              style={{ padding: 9 }}
            >
              <PlusOutlined />
            </BigButton>
          </Tooltip>
        </Col>
        <Col>
          <Tooltip placement='top' title={'Ocultar todos gráficos'}>
            <BigButton
              onClick={() => {
                setLoading(true)
                setExpanded('0')
                setTimeout(() => {
                  setLoading(false)
                }, 200)
              }}
              style={{ padding: 9 }}
            >
              <MinusOutlined />
            </BigButton>
          </Tooltip>
        </Col>
        <Col>
          <Tooltip placement='top' title={'Filtrar'}>
            <BigButton onClick={() => setVisible(!visible)} style={{ padding: 9 }}>
              <FilterOutlined />
            </BigButton>
          </Tooltip>
        </Col>
        <Col span={24}>
          <ContainerTagg>
            {filterTag.start_date && (
              <Tag color='geekblue' onClick={() => removeFilter('start_date', `${filterTag.start_date}`)}>
                {moment(filterTag.start_date).format('DD/MM/YYYY')}
                <CloseOutlined />
              </Tag>
            )}
            {filterTag.end_date && (
              <Tag color='geekblue' onClick={() => removeFilter('end_date', `${filterTag.end_date}`)}>
                {moment(filterTag.end_date).format('DD/MM/YYYY')}
                <CloseOutlined />
              </Tag>
            )}
            {filterTag.flag && (
              <Tag color='geekblue' onClick={() => removeFilter('flag', filterTag.flag)}>
                {filterTag.flag.label} <CloseOutlined />
              </Tag>
            )}
            {filterTag.week && (
              <Tag color='geekblue' onClick={() => removeFilter('week', filterTag.week)}>
                {filterTag.week} <CloseOutlined />
              </Tag>
            )}
            {filterTag.uf && (
              <Tag color='geekblue' onClick={() => removeFilter('uf', filterTag.uf)}>
                {filterTag.uf} <CloseOutlined />
              </Tag>
            )}
            {filterTag.status && (
              <Tag color='geekblue' onClick={() => removeFilter('status', filterTag.status)}>
                {filterTag.status} <CloseOutlined />
              </Tag>
            )}
            {filterTag.regional && (
              <Tag color='geekblue' onClick={() => removeFilter('regional', filterTag.regional)}>
                {filterTag.regional} <CloseOutlined />
              </Tag>
            )}
            {filterTag.promoter && (
              <Tag color='geekblue' onClick={() => removeFilter('promoter', filterTag.promoter)}>
                {filterTag.promoter.label} <CloseOutlined />
              </Tag>
            )}
          </ContainerTagg>
        </Col>
      </Row>

      {loading ? (
        <Loading />
      ) : (
        <>
          <Row>
            <Col span={12}>
              <ContainerFullGraph>
                <Collapse bordered={false} defaultActiveKey={[expanded]}>
                  <Collapse.Panel header='Assiduidade e atendimento' key='1'>
                    <Row>
                      <Col span={12}>
                        <SubContainer>
                          <h3>Assiduidade</h3>
                          <DonultsDash categories={['Previsto', 'Realizado']} series={randonValue(1)} legend={true} />
                        </SubContainer>
                      </Col>
                      <Col span={12}>
                        <SubContainer>
                          <h3>Atendimento</h3>
                          <DonultsDash categories={['Previsto', 'Realizado']} series={randonValue(1)} legend={true} />
                        </SubContainer>
                      </Col>
                      <Col span={12}>
                        <SubContainer>
                          <h3>Justificativa</h3>
                          <DonultsDash categories={['Previsto', 'Realizado']} series={randonValue(1)} legend={true} />
                        </SubContainer>
                      </Col>
                      <Col span={12}>
                        <SubContainer>
                          <h3>Ausência não justificada</h3>
                          <DonultsDash categories={['Previsto', 'Real']} series={randonValue(1)} legend={true} />
                        </SubContainer>
                      </Col>
                    </Row>
                  </Collapse.Panel>
                </Collapse>
              </ContainerFullGraph>
            </Col>
            <Col span={12}>
              <ContainerFullGraph>
                <Collapse bordered={false} defaultActiveKey={[expanded]}>
                  <Collapse.Panel header='Atendimento realizado por UF' key='1'>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                      {/* <Switch checkedChildren='Assiduidade' unCheckedChildren='Atendimento' defaultChecked /> */}
                      <SelectAntd
                        style={{ width: 120 }}
                        defaultValue='Assiduidade'
                        options={[
                          { value: 'Assiduidade', label: 'Assiduidade' },
                          { value: 'Atendimendo', label: 'Atendimendo' },
                        ]}
                      />
                    </div>

                    <MapDash />
                  </Collapse.Panel>
                </Collapse>
              </ContainerFullGraph>
            </Col>
          </Row>
          <ContainerFullGraph style={{ marginTop: '100px' }}>
            <Collapse bordered={false} defaultActiveKey={[expanded]}>
              <Collapse.Panel header='Ranking superiores' key='1'>
                <ContainerTable fullY={true}>
                  <Table
                    dataSource={createRandomDataTable(
                      {
                        colaborate: 'name',
                        attendance_prev: 'randon_value',
                        attendance_real: 'randon_value',
                        attendance_percent: 'randon_value',
                        assiduity_prev: 'randon_value',
                        assiduity_real: 'randon_value',
                        assiduity_percent: 'randon_value',
                        justify_prev: 'randon_value',
                        justify_real: 'randon_value',
                        justify_percent: 'randon_value',
                        absence_without_justify_prev: 'randon_value',
                        absence_without_justify_real: 'randon_value',
                        absence_without_justify_percent: 'randon_value',
                        points: 'randon_value',
                      },
                      15
                    )}
                    bordered={true}
                    scroll={{ x: 1200, y: 400 }}
                  >
                    <Column
                      title='Colaborador Superior'
                      dataIndex='colaborate'
                      key='colaborate'
                      width='250px'
                      fixed='left'
                    />
                    <ColumnGroup title='Atendimento'>
                      <Column
                        title='PREV'
                        dataIndex='attendance_prev'
                        key='attendance_prev'
                        width='100px'
                        align='center'
                      />
                      <Column
                        title='REAL'
                        dataIndex='attendance_real'
                        key='attendance_real'
                        width='100px'
                        align='center'
                      />
                      <Column
                        title='%'
                        dataIndex='attendance_percent'
                        key='attendance_percent'
                        width='100px'
                        align='center'
                        render={(a: any) => (
                          <ContainerPorcent
                            color={a > 50 ? 'rgba(0, 196, 159, 0.5)' : 'rgba(255, 128, 66, 0.5)'}
                            percent={a}
                          >
                            <ContainerStatus>
                              <CirculeStatus color={a > 50 ? 'rgb(0, 196, 159)' : 'rgb(255, 128, 66)'} />
                              {a} %
                            </ContainerStatus>
                          </ContainerPorcent>
                        )}
                      />
                    </ColumnGroup>
                    <ColumnGroup title='Assiduidade'>
                      <Column
                        title='PREV'
                        dataIndex='assiduity_prev'
                        key='assiduity_prev'
                        width='100px'
                        align='center'
                      />
                      <Column
                        title='REAL'
                        dataIndex='assiduity_real'
                        key='assiduity_real'
                        width='100px'
                        align='center'
                      />
                      <Column
                        title='%'
                        dataIndex='assiduity_percent'
                        key='assiduity_percent'
                        width='100px'
                        align='center'
                        render={(a: any) => (
                          <ContainerPorcent
                            color={a > 50 ? 'rgba(0, 196, 159, 0.5)' : 'rgba(255, 128, 66, 0.5)'}
                            percent={a}
                          >
                            <ContainerStatus>
                              <CirculeStatus color={a > 50 ? 'rgb(0, 196, 159)' : 'rgb(255, 128, 66)'} />
                              {a} %
                            </ContainerStatus>
                          </ContainerPorcent>
                        )}
                      />
                    </ColumnGroup>
                    <ColumnGroup title='Justificativa'>
                      <Column title='PREV' dataIndex='justify_prev' key='justify_prev' width='100px' align='center' />
                      <Column title='REAL' dataIndex='justify_real' key='justify_real' width='100px' align='center' />
                      <Column
                        title='%'
                        dataIndex='justify_percent'
                        key='justify_percent'
                        width='100px'
                        align='center'
                        render={(a: any) => (
                          <ContainerPorcent
                            color={a > 50 ? 'rgba(0, 196, 159, 0.5)' : 'rgba(255, 128, 66, 0.5)'}
                            percent={a}
                          >
                            <ContainerStatus>
                              <CirculeStatus color={a > 50 ? 'rgb(0, 196, 159)' : 'rgb(255, 128, 66)'} />
                              {a} %
                            </ContainerStatus>
                          </ContainerPorcent>
                        )}
                      />
                    </ColumnGroup>
                    <ColumnGroup title='Justificativa'>
                      <Column
                        title='PREV'
                        dataIndex='absence_without_justify_prev'
                        key='absence_without_justify_prev'
                        width='100px'
                        align='center'
                      />
                      <Column
                        title='REAL'
                        dataIndex='absence_without_justify_real'
                        key='absence_without_justify_real'
                        width='100px'
                        align='center'
                      />
                      <Column
                        title='%'
                        dataIndex='absence_without_justify_percent'
                        key='absence_without_justify_percent'
                        width='100px'
                        align='center'
                        render={(a: any) => (
                          <ContainerPorcent
                            color={a > 50 ? 'rgba(0, 196, 159, 0.5)' : 'rgba(255, 128, 66, 0.5)'}
                            percent={a}
                          >
                            <ContainerStatus>
                              <CirculeStatus color={a > 50 ? 'rgb(0, 196, 159)' : 'rgb(255, 128, 66)'} />
                              {a} %
                            </ContainerStatus>
                          </ContainerPorcent>
                        )}
                      />
                    </ColumnGroup>
                    <ColumnGroup title='Ranking'>
                      <Column
                        title='Pontos'
                        dataIndex='points'
                        key='points'
                        width='100px'
                        align='center'
                        render={(a: any) => (
                          <ContainerPorcent
                            color={a > 50 ? 'rgba(0, 196, 159, 0.5)' : 'rgba(255, 128, 66, 0.5)'}
                            percent={a}
                          >
                            <ContainerStatus>
                              <CirculeStatus color={a > 50 ? 'rgb(0, 196, 159)' : 'rgb(255, 128, 66)'} />
                              {a} %
                            </ContainerStatus>
                          </ContainerPorcent>
                        )}
                      />
                    </ColumnGroup>
                  </Table>
                </ContainerTable>
              </Collapse.Panel>
            </Collapse>
          </ContainerFullGraph>
          <ContainerFullGraph style={{ marginTop: '100px' }}>
            <Collapse bordered={false} defaultActiveKey={[expanded]}>
              <Collapse.Panel header='Ranking Promotores' key='1'>
                <ContainerTable fullY={true}>
                  <Table
                    dataSource={createRandomDataTable(
                      {
                        colaborate: 'name',
                        attendance_prev: 'randon_value',
                        attendance_real: 'randon_value',
                        attendance_percent: 'randon_value',
                        assiduity_prev: 'randon_value',
                        assiduity_real: 'randon_value',
                        assiduity_percent: 'randon_value',
                        justify_prev: 'randon_value',
                        justify_real: 'randon_value',
                        justify_percent: 'randon_value',
                        absence_without_justify_prev: 'randon_value',
                        absence_without_justify_real: 'randon_value',
                        absence_without_justify_percent: 'randon_value',
                        points: 'randon_value',
                      },
                      15
                    )}
                    bordered={true}
                    scroll={{ x: 1200, y: 400 }}
                  >
                    <Column
                      title='Colaborador Superior'
                      dataIndex='colaborate'
                      key='colaborate'
                      width='250px'
                      fixed='left'
                    />
                    <ColumnGroup title='Atendimento'>
                      <Column
                        title='PREV'
                        dataIndex='attendance_prev'
                        key='attendance_prev'
                        width='100px'
                        align='center'
                      />
                      <Column
                        title='REAL'
                        dataIndex='attendance_real'
                        key='attendance_real'
                        width='100px'
                        align='center'
                      />
                      <Column
                        title='%'
                        dataIndex='attendance_percent'
                        key='attendance_percent'
                        width='100px'
                        align='center'
                        render={(a: any) => (
                          <ContainerPorcent
                            color={a > 50 ? 'rgba(0, 196, 159, 0.5)' : 'rgba(255, 128, 66, 0.5)'}
                            percent={a}
                          >
                            <ContainerStatus>
                              <CirculeStatus color={a > 50 ? 'rgb(0, 196, 159)' : 'rgb(255, 128, 66)'} />
                              {a} %
                            </ContainerStatus>
                          </ContainerPorcent>
                        )}
                      />
                    </ColumnGroup>
                    <ColumnGroup title='Assiduidade'>
                      <Column
                        title='PREV'
                        dataIndex='assiduity_prev'
                        key='assiduity_prev'
                        width='100px'
                        align='center'
                      />
                      <Column
                        title='REAL'
                        dataIndex='assiduity_real'
                        key='assiduity_real'
                        width='100px'
                        align='center'
                      />
                      <Column
                        title='%'
                        dataIndex='assiduity_percent'
                        key='assiduity_percent'
                        width='100px'
                        align='center'
                        render={(a: any) => (
                          <ContainerPorcent
                            color={a > 50 ? 'rgba(0, 196, 159, 0.5)' : 'rgba(255, 128, 66, 0.5)'}
                            percent={a}
                          >
                            <ContainerStatus>
                              <CirculeStatus color={a > 50 ? 'rgb(0, 196, 159)' : 'rgb(255, 128, 66)'} />
                              {a} %
                            </ContainerStatus>
                          </ContainerPorcent>
                        )}
                      />
                    </ColumnGroup>
                    <ColumnGroup title='Justificativa'>
                      <Column title='PREV' dataIndex='justify_prev' key='justify_prev' width='100px' align='center' />
                      <Column title='REAL' dataIndex='justify_real' key='justify_real' width='100px' align='center' />
                      <Column
                        title='%'
                        dataIndex='justify_percent'
                        key='justify_percent'
                        width='100px'
                        align='center'
                        render={(a: any) => (
                          <ContainerPorcent
                            color={a > 50 ? 'rgba(0, 196, 159, 0.5)' : 'rgba(255, 128, 66, 0.5)'}
                            percent={a}
                          >
                            <ContainerStatus>
                              <CirculeStatus color={a > 50 ? 'rgb(0, 196, 159)' : 'rgb(255, 128, 66)'} />
                              {a} %
                            </ContainerStatus>
                          </ContainerPorcent>
                        )}
                      />
                    </ColumnGroup>
                    <ColumnGroup title='Justificativa'>
                      <Column
                        title='PREV'
                        dataIndex='absence_without_justify_prev'
                        key='absence_without_justify_prev'
                        width='100px'
                        align='center'
                      />
                      <Column
                        title='REAL'
                        dataIndex='absence_without_justify_real'
                        key='absence_without_justify_real'
                        width='100px'
                        align='center'
                      />
                      <Column
                        title='%'
                        dataIndex='absence_without_justify_percent'
                        key='absence_without_justify_percent'
                        width='100px'
                        align='center'
                        render={(a: any) => (
                          <ContainerPorcent
                            color={a > 50 ? 'rgba(0, 196, 159, 0.5)' : 'rgba(255, 128, 66, 0.5)'}
                            percent={a}
                          >
                            <ContainerStatus>
                              <CirculeStatus color={a > 50 ? 'rgb(0, 196, 159)' : 'rgb(255, 128, 66)'} />
                              {a} %
                            </ContainerStatus>
                          </ContainerPorcent>
                        )}
                      />
                    </ColumnGroup>
                    <ColumnGroup title='Ranking'>
                      <Column
                        title='Pontos'
                        dataIndex='points'
                        key='points'
                        width='100px'
                        align='center'
                        render={(a: any) => (
                          <ContainerPorcent
                            color={a > 50 ? 'rgba(0, 196, 159, 0.5)' : 'rgba(255, 128, 66, 0.5)'}
                            percent={a}
                          >
                            <ContainerStatus>
                              <CirculeStatus color={a > 50 ? 'rgb(0, 196, 159)' : 'rgb(255, 128, 66)'} />
                              {a} %
                            </ContainerStatus>
                          </ContainerPorcent>
                        )}
                      />
                    </ColumnGroup>
                  </Table>
                </ContainerTable>
              </Collapse.Panel>
            </Collapse>
          </ContainerFullGraph>
        </>
      )}
      <DrawerFilter
        visible={visible}
        close={setVisible}
        title='Ranking Atendimento'
        footer={
          <ContainerButtonsFootter>
            <ButtonSearch type='button' onClick={() => formRef?.current?.submitForm()}>
              <FileSearchOutlined />
              Pesquisar
            </ButtonSearch>
            <ButtonClear onClick={() => cleanFilter()}>
              <ClearOutlined />
              Limpar
            </ButtonClear>
          </ContainerButtonsFootter>
        }
      >
        <ContainerFormFilter
          tabIndex={0}
          onKeyDown={(evt: any) => {
            if (evt.key === 'Enter') formRef?.current?.submitForm()
          }}
        >
          <Form ref={formRef} className='form' style={{ marginTop: '15px' }} onSubmit={searchRanking}>
            <SimpleRangePicker
              name='date'
              label='Selecione o periodo:'
              defaultValueInput={[moment().startOf('week'), moment().endOf('week')]}
              ranges={{
                Ontem: [moment().subtract('d', 1), moment().subtract('d', 1)],
                Hoje: [moment(), moment()],
                Amanhã: [moment().add('d', 1), moment().add('d', 1)],
                'Esta semana': [moment().startOf('week'), moment().endOf('week')],
                'Este mês': [moment().startOf('month'), moment().endOf('month')],
              }}
            />
            <GroupForm>
              <LabelForm>Selecione a promotor</LabelForm>
              <Search placeholder='Selecione o promotor' name='promoter' path='promoters' isMult={false} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione a bandeira</LabelForm>
              <Search placeholder='Selecione a bandeira' name='flag' path='flags' isMult={false} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione o status</LabelForm>
              <Select
                styles={customStyles}
                className='pre-select'
                placeholder='Selecione o status'
                options={isActiveSelectVisita}
                name='status'
                defaultValue={filters && filters.status ? filters.status : ''}
                onChange={(value: any) => {
                  if (value && value.value)
                    setFilters({
                      ...filters,
                      status: value.value,
                    })
                }}
              />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione a região</LabelForm>
              <Select
                styles={customStyles}
                className='pre-select'
                placeholder='Selecione a região'
                options={isActiveSelectVisita}
                name='regional'
                defaultValue={filters && filters.regional ? filters.regional : ''}
                onChange={(value: any) => {
                  if (value && value.value)
                    setFilters({
                      ...filters,
                      regional: value.value,
                    })
                }}
              />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione a semana</LabelForm>
              <Select
                styles={customStyles}
                className='pre-select'
                placeholder='Selecione a semana'
                options={isActiveSelectVisita}
                name='week'
                defaultValue={filters && filters.week ? filters.week : ''}
                onChange={(value: any) => {
                  if (value && value.value)
                    setFilters({
                      ...filters,
                      week: value.value,
                    })
                }}
              />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione o estado</LabelForm>
              <Select
                styles={customStyles}
                className='pre-select'
                placeholder='Selecione o estado'
                options={isActiveSelectVisita}
                name='uf'
                defaultValue={filters && filters.uf ? filters.uf : ''}
                onChange={(value: any) => {
                  if (value && value.value)
                    setFilters({
                      ...filters,
                      uf: value.value,
                    })
                }}
              />
            </GroupForm>
          </Form>
        </ContainerFormFilter>
      </DrawerFilter>
    </div>
  )
}

export default RankingPage
