import { CSSProperties } from 'react';

export const storeTaskStyle: CSSProperties = {
    position: 'relative',
    padding: '12px 16px',
    paddingRight: '40px',
    display: 'flex',
    color: 'rgba(0, 0, 0, 0.85)',
    margin: 0,
    boxSizing: 'border-box',
    borderRadius: '0 0 2px 2px' ,
    borderBottom: '1px solid #d9d9d9',
    
  };