export const estadosBrasileiros = [
    { nome: 'Acre', sigla: 'AC', latitude: -9.0479, longitude: -70.5262 },
    { nome: 'Alagoas', sigla: 'AL', latitude: -9.5713, longitude: -36.7819 },
    { nome: 'Amapá', sigla: 'AP', latitude: 0.9020, longitude: -52.0035 },
    { nome: 'Amazonas', sigla: 'AM', latitude: -3.4168, longitude: -65.8561 },
    { nome: 'Bahia', sigla: 'BA', latitude: -12.9714, longitude: -38.5014 },
    { nome: 'Ceará', sigla: 'CE', latitude: -3.7172, longitude: -38.5433 },
    { nome: 'Distrito Federal', sigla: 'DF', latitude: -15.7801, longitude: -47.9292 },
    { nome: 'Espírito Santo', sigla: 'ES', latitude: -20.3155, longitude: -40.3128 },
    { nome: 'Goiás', sigla: 'GO', latitude: -16.6864, longitude: -49.2643 },
    { nome: 'Maranhão', sigla: 'MA', latitude: -5.7945, longitude: -35.2110 },
    { nome: 'Mato Grosso', sigla: 'MT', latitude: -15.6010, longitude: -56.0974 },
    { nome: 'Mato Grosso do Sul', sigla: 'MS', latitude: -20.4428, longitude: -54.6464 },
    { nome: 'Minas Gerais', sigla: 'MG', latitude: -18.9180, longitude: -44.1000 },
    { nome: 'Pará', sigla: 'PA', latitude: -1.4550, longitude: -48.5024 },
    { nome: 'Paraíba', sigla: 'PB', latitude: -7.1219, longitude: -36.724 },
    { nome: 'Paraná', sigla: 'PR', latitude: -25.2521, longitude: -52.0215 },
    { nome: 'Pernambuco', sigla: 'PE', latitude: -8.0476, longitude: -34.8770 },
    { nome: 'Piauí', sigla: 'PI', latitude: -7.7111, longitude: -42.5297 },
    { nome: 'Rio de Janeiro', sigla: 'RJ', latitude: -22.9068, longitude: -43.1729 },
    { nome: 'Rio Grande do Norte', sigla: 'RN', latitude: -5.7945, longitude: -35.2110 },
    { nome: 'Rio Grande do Sul', sigla: 'RS', latitude: -30.0328, longitude: -51.2302 },
    { nome: 'Rondônia', sigla: 'RO', latitude: -11.5057, longitude: -63.5806 },
    { nome: 'Roraima', sigla: 'RR', latitude: 1.8990, longitude: -61.1749 },
    { nome: 'Santa Catarina', sigla: 'SC', latitude: -27.5954, longitude: -48.5480 },
    { nome: 'São Paulo', sigla: 'SP', latitude: -23.5505, longitude: -46.6333 },
    { nome: 'Sergipe', sigla: 'SE', latitude: -10.9472, longitude: -37.0731 },
    { nome: 'Tocantins', sigla: 'TO', latitude: -10.9472, longitude: -37.0731 }
  ];
  