import React from 'react'
import {
  ContainerQuestion,
  TitlePicture,
  Description,
} from '../style'
import { Upload, Button, Form } from 'antd'
import ViewPicture from './viewPicture'

const PictureQuestion: React.FC<any> = ({ quest, fields }) => {

  return (
    <>
      <ContainerQuestion>
        <TitlePicture>
          <h1>
            {quest.is_required ? '*' : null} {quest.order} →{' '}
          </h1>{' '}
          {quest.name}
        </TitlePicture>
        {quest.description ? <Description>{quest.description}</Description> : null}
        {quest.maxPicture || quest.minPicture ? (
          <div style={{ marginBottom: '20px;' }}>
            {quest.maxPicture ? (
              <p style={{ fontSize: '17px' }}>
                Máximo de fotos: <b>{quest.maxPicture}</b>{' '}
              </p>
            ) : null}
            {quest.minPicture ? (
              <p style={{ fontSize: '17px' }}>
                Mínimo de fotos: <b>{quest.minPicture}</b>{' '}
              </p>
            ) : null}
          </div>
        ) : null}
        {quest.picture && typeof quest.picture == 'string' &&  <ViewPicture picture={quest.picture} />}
        <Form.Item name='PICTURE_CAPTURE' label='Foto' rules={[{ required: quest.is_required ? true : false }]}>
          <Upload name='logo' action='/upload.do' listType='picture' multiple>
            <Button>Adicionar foto</Button>
          </Upload>
        </Form.Item>
      </ContainerQuestion>
    </>
  )
}

export default PictureQuestion
