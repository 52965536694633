import styled from 'styled-components'
import { Modal } from 'antd'

export const ContainerModal = styled(Modal)`
  top: 20 !important;

  h3 {
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: #8f61e2;
  }

  p {
    margin-bottom: 10px;
  }

  &&& .ant-modal {
    top: 20 !important;
  }

  &&& .ant-modal-content {
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  }

  &&& .ant-modal-body {
    border-radius: 12px;
  }

  &&& .ant-modal-close-x {
    color: red;
  }

  &&& .ant-upload.ant-upload-drag {
    width: auto !important;
    height: auto !important;
    padding: 28px 5px;
  }
`
