import { Col, Row } from 'antd'
import React from 'react'
import ManutencaoImg from '../assets/images/block.png'
import { ContainerLogo } from '../components/Menu/styles'
import { ConatinerTeste, ContainerDescription, ContainerModal, SmallText } from '../components/ModalMenu/style'
import LogoWorkspace from '../assets/images/Rock-At.png'
import { Title } from './Team/Supervisor/style'

export const Block = () => {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: '#f8f8f8',
      }}
    >
      <ContainerModal
        visible={true}
        footer={null}
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
        closable={false}
        width={'80vw'}
      >
        <Row gutter={[20, 20]}>
          <Col span={12}>
            <ContainerDescription>
              <img src={ManutencaoImg} style={{ width: 250, height: 250 }} alt="block" />
            </ContainerDescription>
          </Col>
          <Col span={12}>
            <ConatinerTeste>
              <Title>Atenção!</Title>
              <SmallText>Seu acesso está restrito! </SmallText>
              <SmallText>
                Entre em contato com nosso setor administrativo para continua utilizando a plataforma.
              </SmallText>
              <ContainerLogo style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img src={LogoWorkspace} alt='IncentiveLogo' style={{ width: 250 }} />
                {/* <p>
                  by <b>Rock-at.</b>
                </p> */}
              </ContainerLogo>
            </ConatinerTeste>
          </Col>
        </Row>
      </ContainerModal>

      {/* <h2 style={{ color: '#fff', fontSize: 45, fontWeight: 'bold' }}>Em manutenção...</h2>
      <p style={{ color: '#fff', fontSize: 25 }}>
        Urgências mandar email para <a href='mailto:suporte@rock-at.com'>suporte@rock-at.com</a>
      </p> */}
      {/* <p style={{ color: '#fff', fontSize: 25, maxWidth: 1000, textAlign: 'center' }}>Acesso bloqueado!</p> */}
      {/* <p style={{ color: '#fff', fontSize: 25 }}>Volte por favor dentro de alguns minutos ..</p>*/}
      {/* <p style={{ color: '#fff', fontSize: 25 }}>Por favor entre em contato com a Rock-at.</p> */}
    </div>
  )
}
