import React, { useEffect, useState } from 'react'
import { Container, Title, ContainerRadio, TextError } from './styles'
import { Radio } from 'antd'
import { PictureMass } from '../Picture'
import { IntMass } from '../Int'
import { UniqueChoiceMass } from '../UniqueChoices'
import { MultipleChoiseMass } from '../MultipleChoise'
import { MoneyMass } from '../Money'
import { TextMass } from '../Text'
import { DateFormMass } from '../DateForm'

interface IQuestion {
  id: number
  is_required: boolean
  name: string
  order: string
  type: string
  fields_true?: any
  fields_false?: any
}
interface IDataQuestion {
  dataQuestion: IQuestion
  handleAnswers: (value: any, dataQuestion: IQuestion) => void
  error?: boolean
  loading: any
  listError: any
  body: any
  checkRequired: (value: any) => void
}

export const BooleanMass = ({
  dataQuestion,
  handleAnswers,
  error,
  loading,
  checkRequired,
  listError,
  body,
}: IDataQuestion) => {
  const [value, setValue] = useState()
  const [isRender, setIsRender] = useState(false)

  const handleValue = (value: any) => {
    setValue(value.target.value)
    handleAnswers([value.target.value], dataQuestion)
  }

  useEffect(() => {
    if (dataQuestion.is_required && !isRender) {
      setIsRender(true)
      checkRequired(dataQuestion.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <Title>
        {dataQuestion.order} - {dataQuestion.name} {dataQuestion.is_required && '*'}
      </Title>
      <ContainerRadio>
        <Radio.Group onChange={handleValue} value={value}>
          <Radio value={'SIM'}>Sim</Radio>
          <Radio value={'NÃO'}>Não</Radio>
        </Radio.Group>
      </ContainerRadio>
      {error && !value && <TextError>Campo obrigatório</TextError>}
      {value === 'SIM' && (
        <>
          {dataQuestion.fields_true &&
            dataQuestion.fields_true.map((question: any, index: number) => (
              <>
                {question.type === 'PICTURE_CAPTURE' && (
                  <PictureMass
                    body={body}
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    handleAnswers={handleAnswers}
                    key={index}
                    loading={loading}
                    error={listError.indexOf(question.id) > -1}
                  />
                )}
                {question.type === 'NUMBER_INTEGER' && (
                  <IntMass
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    handleAnswers={handleAnswers}
                    key={index}
                    error={listError.indexOf(question.id) > -1}
                  />
                )}
                {question.type === 'UNIQUE_CHOICE' && (
                  <UniqueChoiceMass
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    handleAnswers={handleAnswers}
                    key={index}
                    loading={loading}
                    body={body}
                    error={listError.indexOf(question.id) > -1}
                    listError={listError}
                  />
                )}
                {question.type === 'BOOLEAN' && (
                  <BooleanMass
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    handleAnswers={handleAnswers}
                    key={index}
                    body={body}
                    loading={loading}
                    error={listError.indexOf(question.id) > -1}
                    listError={listError}
                  />
                )}
                {question.type === 'TEXT' && (
                  <TextMass
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    handleAnswers={handleAnswers}
                    key={index}
                    error={listError.indexOf(question.id) > -1}
                  />
                )}
                {question.type === 'MULTIPLE_CHOICE' && (
                  <MultipleChoiseMass
                    body={body}
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    handleAnswers={handleAnswers}
                    key={index}
                    loading={loading}
                    error={listError.indexOf(question.id) > -1}
                    listError={listError}
                  />
                )}
                {question.type === 'MONEY' && (
                  <MoneyMass
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    handleAnswers={handleAnswers}
                    key={index}
                    error={listError.indexOf(question.id) > -1}
                  />
                )}
                {question.type === 'DATE' && (
                  <DateFormMass
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    handleAnswers={handleAnswers}
                    key={index}
                    error={listError.indexOf(question.id) > -1}
                  />
                )}
              </>
            ))}
        </>
      )}
      {value === 'NÃO' && (
        <>
          {dataQuestion.fields_false &&
            dataQuestion.fields_false.map((question: any, index: number) => (
              <div key={question.id}>
                {question.type === 'PICTURE_CAPTURE' && (
                  <PictureMass
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    handleAnswers={handleAnswers}
                    loading={loading}
                    body={body}
                    error={listError.indexOf(question.id) > -1}
                  />
                )}
                {question.type === 'NUMBER_INTEGER' && (
                  <IntMass
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    handleAnswers={handleAnswers}
                    error={listError.indexOf(question.id) > -1}
                  />
                )}
                {question.type === 'UNIQUE_CHOICE' && (
                  <UniqueChoiceMass
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    handleAnswers={handleAnswers}
                    loading={loading}
                    body={body}
                    error={listError.indexOf(question.id) > -1}
                    listError={listError}
                  />
                )}
                {question.type === 'BOOLEAN' && (
                  <BooleanMass
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    handleAnswers={handleAnswers}
                    loading={loading}
                    body={body}
                    error={listError.indexOf(question.id) > -1}
                    listError={listError}
                  />
                )}
                {question.type === 'TEXT' && (
                  <TextMass
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    handleAnswers={handleAnswers}
                    error={listError.indexOf(question.id) > -1}
                  />
                )}
                {question.type === 'MULTIPLE_CHOICE' && (
                  <MultipleChoiseMass
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    body={body}
                    handleAnswers={handleAnswers}
                    loading={loading}
                    error={listError.indexOf(question.id) > -1}
                    listError={listError}
                  />
                )}
                {question.type === 'MONEY' && (
                  <MoneyMass
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    handleAnswers={handleAnswers}
                    error={listError.indexOf(question.id) > -1}
                  />
                )}
                {question.type === 'DATE' && (
                  <DateFormMass
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    handleAnswers={handleAnswers}
                    key={index}
                    error={listError.indexOf(question.id) > -1}
                  />
                )}
              </div>
            ))}
        </>
      )}
    </Container>
  )
}
