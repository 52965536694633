import React, { useEffect, useState } from 'react'
import { Container, Title, TextError } from './styles'
import { Select } from 'antd'
import { PictureMass } from '../Picture'
import { IntMass } from '../Int'
import { TextMass } from '../Text'
import { UniqueChoiceMass } from '../UniqueChoices/'
import { MoneyMass } from '../Money'
import { BooleanMass } from '../Boolean'
import { DateFormMass } from '../DateForm'
interface IQuestion {
  id: number
  is_required: boolean
  name: string
  order: string
  type: string
  fields?: any
  options?: any
}
interface IDataQuestion {
  dataQuestion: IQuestion
  handleAnswers: (value: any, dataQuestion: IQuestion) => void
  error?: boolean
  loading: any
  checkRequired: (value: any) => void
  listError: any
  body: any
}

export const MultipleChoiseMass = ({
  dataQuestion,
  handleAnswers,
  error,
  loading,
  checkRequired,
  listError,
  body,
}: IDataQuestion) => {
  const [checked, setChecked] = useState<number[]>([])
  const [isRender, setIsRender] = useState(false)
  const [value, setValue] = useState([])

  const handleValue = (check: any, index: any) => {
    setValue(check)
    let indexs: number[] = []
    check.forEach((el: any) => {
      indexs.push(dataQuestion.options.indexOf(el))
    })

    setChecked(indexs)
  }

  useEffect(() => {
    const answers = () => {
      handleAnswers(value, dataQuestion)
    }

    answers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked])

  useEffect(() => {
    if (dataQuestion.is_required && !isRender) {
      setIsRender(true)
      checkRequired(dataQuestion.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <Title>
        {dataQuestion.order} - {dataQuestion.name} {dataQuestion.is_required && '*'}
      </Title>
      <Select mode='multiple' allowClear style={{ width: '100%' }} onChange={handleValue}>
        {dataQuestion.options.map((el: any, index: any) => (
          <Select.Option key={index} value={el}>
            {el}
          </Select.Option>
        ))}
      </Select>
      {checked ? (
        <>
          {dataQuestion &&
            dataQuestion.fields &&
            dataQuestion.fields.length > 0 &&
            dataQuestion.fields.map((question: any, indexP: number) => (
              <div key={indexP}>
                {question &&
                  question.subFields.length > 0 &&
                  question.subFields.map((quest: any, index: number) => (
                    <div key={index}>
                      {quest.type === 'PICTURE_CAPTURE' && checked.indexOf(indexP) > -1 && (
                        <PictureMass
                          body={body}
                          checkRequired={checkRequired}
                          dataQuestion={quest}
                          handleAnswers={handleAnswers}
                          loading={loading}
                          error={listError.indexOf(question.id) > -1}
                        />
                      )}
                      {quest.type === 'NUMBER_INTEGER' && checked.indexOf(indexP) > -1 && (
                        <IntMass
                          checkRequired={checkRequired}
                          dataQuestion={quest}
                          handleAnswers={handleAnswers}
                          error={listError.indexOf(question.id) > -1}
                        />
                      )}
                      {quest.type === 'UNIQUE_CHOICE' && checked.indexOf(indexP) > -1 && (
                        <UniqueChoiceMass
                          body={body}
                          checkRequired={checkRequired}
                          dataQuestion={quest}
                          handleAnswers={handleAnswers}
                          loading={loading}
                          error={listError.indexOf(question.id) > -1}
                          listError={listError}
                        />
                      )}
                      {quest.type === 'BOOLEAN' && checked.indexOf(indexP) > -1 && (
                        <BooleanMass
                          checkRequired={checkRequired}
                          dataQuestion={quest}
                          body={body}
                          handleAnswers={handleAnswers}
                          loading={loading}
                          error={listError.indexOf(question.id) > -1}
                          listError={listError}
                        />
                      )}
                      {quest.type === 'TEXT' && checked.indexOf(indexP) > -1 && (
                        <TextMass
                          checkRequired={checkRequired}
                          dataQuestion={quest}
                          handleAnswers={handleAnswers}
                          error={listError.indexOf(question.id) > -1}
                        />
                      )}
                      {quest.type === 'MULTIPLE_CHOICE' && checked.indexOf(indexP) > -1 && (
                        <MultipleChoiseMass
                          checkRequired={checkRequired}
                          dataQuestion={quest}
                          body={body}
                          handleAnswers={handleAnswers}
                          loading={loading}
                          error={listError.indexOf(question.id) > -1}
                          listError={listError}
                        />
                      )}
                      {quest.type === 'MONEY' && checked.indexOf(indexP) > -1 && (
                        <MoneyMass
                          checkRequired={checkRequired}
                          dataQuestion={question}
                          handleAnswers={handleAnswers}
                          error={listError.indexOf(question.id) > -1}
                        />
                      )}
                      {question.type === 'DATE' && checked.indexOf(indexP) > -1 && (
                        <DateFormMass
                          checkRequired={checkRequired}
                          dataQuestion={question}
                          handleAnswers={handleAnswers}
                          key={index}
                          error={listError.indexOf(question.id) > -1}
                        />
                      )}
                    </div>
                  ))}
              </div>
            ))}
        </>
      ) : null}
      {error && checked.length === 0 && <TextError>Campo obrigatório</TextError>}
    </Container>
  )
}
