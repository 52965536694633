import React, { useEffect, useState } from 'react'
// import api from '../../../services/api'
import { Button, Col, Divider, Form, Modal, Switch, TimePicker } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

interface IDaysProps {
  name: string
  nameProps: string
  form: any
  copy: any
  defaultValue: any
  setCopy: (values: any) => void
}
export const DaysJorney = ({ name, nameProps, form, copy, setCopy, defaultValue }: IDaysProps) => {
  const [status, setStatus] = useState<any>(copy)
  const format = 'HH:mm'

  useEffect(() => {
    if (defaultValue) {
      let obj: any = {}

      obj[`tolerance_end_${nameProps}`] = defaultValue[`tolerance_end_${nameProps}`]
      obj[`tolerance_start_${nameProps}`] = defaultValue[`tolerance_start_${nameProps}`]
      obj[`${nameProps}_start`] = defaultValue[`${nameProps}_start`]
      obj[`${nameProps}_start_lunch`] = defaultValue[`${nameProps}_start_lunch`]
      obj[`${nameProps}_end_lunch`] = defaultValue[`${nameProps}_end_lunch`]
      obj[`${nameProps}_end`] = defaultValue[`${nameProps}_end`]
      obj[`${nameProps}_block`] = defaultValue[`${nameProps}_block`]
      setStatus(obj)
    }
  }, [defaultValue, nameProps])

  useEffect(() => {
    setStatus(copy)
  }, [copy])

  const resetField = () => {
    let getValues: any = {
      name: form.getFieldValue('name'),
      description: form.getFieldValue('description'),
      active: form.getFieldValue('active'),
      monday_start: form.getFieldValue('monday_start'),
      monday_start_lunch: form.getFieldValue('monday_start_lunch'),
      monday_end_lunch: form.getFieldValue('monday_end_lunch'),
      monday_end: form.getFieldValue('monday_end'),
      monday_block: form.getFieldValue('monday_block'),
      tuesday_start: form.getFieldValue('tuesday_start'),
      tuesday_start_lunch: form.getFieldValue('tuesday_start_lunch'),
      tuesday_end_lunch: form.getFieldValue('tuesday_end_lunch'),
      tuesday_end: form.getFieldValue('tuesday_end'),
      tuesday_block: form.getFieldValue('tuesday_block'),
      wednesday_start: form.getFieldValue('wednesday_start'),
      wednesday_start_lunch: form.getFieldValue('wednesday_start_lunch'),
      wednesday_end_lunch: form.getFieldValue('wednesday_end_lunch'),
      wednesday_end: form.getFieldValue('wednesday_end'),
      wednesday_block: form.getFieldValue('wednesday_block'),
      thursday_start: form.getFieldValue('thursday_start'),
      thursday_start_lunch: form.getFieldValue('thursday_start_lunch'),
      thursday_end_lunch: form.getFieldValue('thursday_end_lunch'),
      thursday_end: form.getFieldValue('thursday_end'),
      thursday_block: form.getFieldValue('thursday_block'),
      friday_start: form.getFieldValue('friday_start'),
      friday_start_lunch: form.getFieldValue('friday_start_lunch'),
      friday_end_lunch: form.getFieldValue('friday_end_lunch'),
      friday_end: form.getFieldValue('friday_end'),
      friday_block: form.getFieldValue('friday_block'),
      saturday_start: form.getFieldValue('saturday_start'),
      saturday_start_lunch: form.getFieldValue('saturday_start_lunch'),
      saturday_end_lunch: form.getFieldValue('saturday_end_lunch'),
      saturday_end: form.getFieldValue('saturday_end'),
      saturday_block: form.getFieldValue('saturday_block'),
      sunday_start: form.getFieldValue('sunday_start'),
      sunday_start_lunch: form.getFieldValue('sunday_start_lunch'),
      sunday_end_lunch: form.getFieldValue('sunday_end_lunch'),
      sunday_end: form.getFieldValue('sunday_end'),
      sunday_block: form.getFieldValue('sunday_block'),
      tolerance_start_monday: form.getFieldValue('tolerance_start_monday'),
      tolerance_end_monday: form.getFieldValue('tolerance_end_monday'),
      tolerance_start_tuesday: form.getFieldValue('tolerance_start_tuesday'),
      tolerance_end_tuesday: form.getFieldValue('tolerance_end_tuesday'),
      tolerance_start_wednesday: form.getFieldValue('tolerance_start_wednesday'),
      tolerance_end_wednesday: form.getFieldValue('tolerance_end_wednesday'),
      tolerance_start_thursday: form.getFieldValue('tolerance_start_thursday'),
      tolerance_end_thursday: form.getFieldValue('tolerance_end_thursday'),
      tolerance_start_friday: form.getFieldValue('tolerance_start_friday'),
      tolerance_end_friday: form.getFieldValue('tolerance_end_friday'),
      tolerance_start_saturday: form.getFieldValue('tolerance_start_saturday'),
      tolerance_end_saturday: form.getFieldValue('tolerance_end_saturday'),
      tolerance_start_sunday: form.getFieldValue('tolerance_start_sunday'),
      tolerance_end_sunday: form.getFieldValue('tolerance_end_sunday'),

    }

    setStatus({})

    form.resetFields()
    delete getValues[`tolerance_start_${nameProps}`]
    delete getValues[`tolerance_end_${nameProps}`]
    delete getValues[`${nameProps}_start`]
    delete getValues[`${nameProps}_start_lunch`]
    delete getValues[`${nameProps}_end_lunch`]
    delete getValues[`${nameProps}_end`]
    delete getValues[`${nameProps}_block`]
    form.setFieldsValue(getValues)
  }

  return (
    <>
      <Col span={24}>
        <h3 style={{ color: '#637282', fontWeight: 'bold' }}>{name}</h3>
        <Divider style={{ marginBottom: 10 }} />
      </Col>
      <Col md={3}>
        <Form.Item
          label={<p style={{ color: '#637282' }}>Início</p>}
          name={`${nameProps}_start`}
          style={{ display: 'block' }}
          
        >
          <TimePicker
            style={{ width: '100%' }}
            format={format}
            onChange={(evt) => {
              let newObj = status
              newObj[`${nameProps}_start`] = evt
              setStatus({ ...newObj })
            }}
          />
        </Form.Item>
      </Col>
      <Col md={3}>
        <Form.Item
          label={<p style={{ color: '#637282' }}>Tolerância início</p>}
          name={`tolerance_start_${nameProps}`}
          style={{ display: 'block' }}
          
        >
          <TimePicker
            style={{ width: '100%' }}
            format={format}
            onChange={(evt) => {
              let newObj = status
              newObj[`tolerance_start_${nameProps}`] = evt
              setStatus({ ...newObj })
            }}
          />
        </Form.Item>
      </Col>
      <Col md={3}>
        <Form.Item
          label={<p style={{ color: '#637282' }}>Saída para intervalo</p>}
          name={`${nameProps}_start_lunch`}
          style={{ display: 'block' }}
          
        >
          <TimePicker
            style={{ width: '100%' }}
            disabled={!status[`${nameProps}_start`]}
            format={format}
            onChange={(evt) => {
              let newObj = status
              newObj[`${nameProps}_start_lunch`] = evt
              setStatus({ ...newObj })
            }}
          />
        </Form.Item>
      </Col>
      <Col md={3}>
        <Form.Item
          label={<p style={{ color: '#637282' }}>Retorno do intervalo</p>}
          name={`${nameProps}_end_lunch`}
          style={{ display: 'block' }}
          
        >
          <TimePicker
            onChange={(evt) => {
              let newObj = status
              newObj[`${nameProps}_end_lunch`] = evt
              setStatus({ ...newObj })
            }}
            format={format}
            style={{ width: '100%' }}
            disabled={!status[`${nameProps}_start_lunch`]}
          />
        </Form.Item>
      </Col>
      <Col md={3}>
        <Form.Item
          label={<p style={{ color: '#637282' }}>Término</p>}
          name={`${nameProps}_end`}
          style={{ display: 'block' }}
          
        >
          <TimePicker
            format={format}
            onChange={(evt) => {
              let newObj = status
              newObj[`${nameProps}_end`] = evt
              setStatus({ ...newObj })
            }}
            style={{ width: '100%' }}
            disabled={!status[`${nameProps}_start`]}
          />
        </Form.Item>
      </Col>
      <Col md={3}>
        <Form.Item
          label={<p style={{ color: '#637282' }}>Tolerância fim</p>}
          name={`tolerance_end_${nameProps}`}
          style={{ display: 'block' }}
          
        >
          <TimePicker
            style={{ width: '100%' }}
            format={format}
            onChange={(evt) => {
              let newObj = status
              newObj[`tolerance_end_${nameProps}`] = evt
              setStatus({ ...newObj })
            }}
          />
        </Form.Item>
      </Col>
      <Col md={2}>
        <Form.Item
          label={<p style={{ color: '#637282' }}>Bloqueio de acesso</p>}
          name={`${nameProps}_block`}
          style={{ display: 'block' }}
          rules={[{ required: false }]}
        >
          <Switch />
        </Form.Item>
      </Col>
      <Col md={2}>
        <Button type='primary' htmlType='button' onClick={() => resetField()} disabled={!status[`${nameProps}_start`]}>
          Limpar
        </Button>
      </Col>
      <Col md={2}>
        <Button
          type='primary'
          htmlType='button'
          disabled={
            !status[`${nameProps}_start_lunch`] ||
            !status[`${nameProps}_start`] ||
            !status[`${nameProps}_end_lunch`] ||
            !status[`${nameProps}_end`]
          }
          onClick={() => {
            Modal.confirm({
              title: 'Deseja copiar ?',
              icon: <ExclamationCircleOutlined />,
              content: 'Ao confirmar você irá replicar os mesmo valores a todos os outros dias.',
              onOk() {
                let getValues: any = {
                  monday_start: status[`${nameProps}_start`],
                  monday_start_lunch: status[`${nameProps}_start_lunch`],
                  monday_end_lunch: status[`${nameProps}_end_lunch`],
                  monday_end: status[`${nameProps}_end`],
                  monday_block: status[`${nameProps}_block`],
                  tuesday_start: status[`${nameProps}_start`],
                  tuesday_start_lunch: status[`${nameProps}_start_lunch`],
                  tuesday_end_lunch: status[`${nameProps}_end_lunch`],
                  tuesday_end: status[`${nameProps}_end`],
                  tuesday_block: status[`${nameProps}_block`],
                  wednesday_start: status[`${nameProps}_start`],
                  wednesday_start_lunch: status[`${nameProps}_start_lunch`],
                  wednesday_end_lunch: status[`${nameProps}_end_lunch`],
                  wednesday_end: status[`${nameProps}_end`],
                  wednesday_block: status[`${nameProps}_block`],
                  thursday_start: status[`${nameProps}_start`],
                  thursday_start_lunch: status[`${nameProps}_start_lunch`],
                  thursday_end_lunch: status[`${nameProps}_end_lunch`],
                  thursday_end: status[`${nameProps}_end`],
                  thursday_block: status[`${nameProps}_block`],
                  friday_start: status[`${nameProps}_start`],
                  friday_start_lunch: status[`${nameProps}_start_lunch`],
                  friday_end_lunch: status[`${nameProps}_end_lunch`],
                  friday_end: status[`${nameProps}_end`],
                  friday_block: status[`${nameProps}_block`],
                  saturday_start: status[`${nameProps}_start`],
                  saturday_start_lunch: status[`${nameProps}_start_lunch`],
                  saturday_end_lunch: status[`${nameProps}_end_lunch`],
                  saturday_end: status[`${nameProps}_end`],
                  saturday_block: status[`${nameProps}_block`],
                  sunday_start: status[`${nameProps}_start`],
                  sunday_start_lunch: status[`${nameProps}_start_lunch`],
                  sunday_end_lunch: status[`${nameProps}_end_lunch`],
                  sunday_end: status[`${nameProps}_end`],
                  sunday_block: status[`${nameProps}_block`],
                }

                form.setFieldsValue(getValues)
                setCopy(getValues)
              },
              onCancel() {
                console.log('Cancel')
              },
            })
          }}
        >
          {copy && Object.keys(copy).length > 0 ? 'Colar' : 'Copiar'}
        </Button>
      </Col>
    </>
  )
}
