import React, { useState } from 'react'
import { ContainerType, TypeLeft, TypeRight, ListTypes, InfoType, TextInfo } from './style'
import {
  CopyTwoTone,
  DiffTwoTone,
  CheckCircleTwoTone,
  CameraTwoTone,
  QuestionCircleTwoTone,
  AccountBookTwoTone,
  DatabaseTwoTone,
  AppstoreTwoTone,
  QrcodeOutlined,
  CloudTwoTone,
  SnippetsTwoTone
} from '@ant-design/icons'
import moment from 'moment'

const TypeQuestion: React.FC<any> = ({ typeQuestion, order, jump }) => {
  const [typeHover, setTypeHover] = useState<any>('multipleChoise')

  const selectType = (type: any) => {
    if (jump === 0) {
      let BASE_QUESTION = {
        id: moment().unix(),
        name: '',
        is_required: true,
        order: `${order}`,
        type: type,
      }

      if (type === 'MULTIPLE_CHOICE' || type === 'UNIQUE_CHOICE') {
        BASE_QUESTION = Object.assign(BASE_QUESTION, { options: ['...'] })
      }

      typeQuestion(BASE_QUESTION)
    } else {
      let BASE_QUESTION = {
        id: moment().unix(),
        name: '',
        is_required: true,
        order: `${jump}.${order}`,
        type: type,
      }

      if (type === 'MULTIPLE_CHOICE' || type === 'UNIQUE_CHOICE') {
        BASE_QUESTION = Object.assign(BASE_QUESTION, { options: ['...'] })
      }

      typeQuestion(BASE_QUESTION)
    }
  }
  return (
    <ContainerType>
      <TypeLeft>
        <p className='Title'>Escolha um tipo de questão</p>
        <ListTypes>
          <ul>
            <li onClick={() => selectType('DATE')} onMouseEnter={() => setTypeHover('DATE')}>
              <CheckCircleTwoTone twoToneColor='#4FA9B3' style={{ fontSize: '23px' }} />
              <p>Data</p>
            </li>
            <li onClick={() => selectType('TEXT')} onMouseEnter={() => setTypeHover('TEXT')}>
              <CopyTwoTone twoToneColor='#FABA48' style={{ fontSize: '23px' }} />
              <p>Texto curto</p>
            </li>
            <li onClick={() => selectType('MONEY')} onMouseEnter={() => setTypeHover('MONEY')}>
              <DiffTwoTone twoToneColor='#E26D5A' style={{ fontSize: '23px' }} />
              <p>Dinheiro</p>
            </li>
            <li onClick={() => selectType('PICTURE_CAPTURE')} onMouseEnter={() => setTypeHover('PICTURE_CAPTURE')}>
              <CameraTwoTone twoToneColor='#A086C3' style={{ fontSize: '23px' }} />
              <p>Foto</p>
            </li>
            <li onClick={() => selectType('QR_CODE')} onMouseEnter={() => setTypeHover('QR_CODE')}>
              <QrcodeOutlined twoToneColor='#3ca133' style={{ fontSize: '23px' }} />
              <p>QR / Bar code</p>
            </li>
            <li onClick={() => selectType('BOOLEAN')} onMouseEnter={() => setTypeHover('BOOLEAN')}>
              <QuestionCircleTwoTone twoToneColor='#BF415C' style={{ fontSize: '23px' }} />
              <p>Sim / Não</p>
            </li>
            <li onClick={() => selectType('NUMBER_INTEGER')} onMouseEnter={() => setTypeHover('NUMBER_INTEGER')}>
              <AccountBookTwoTone twoToneColor='#E05178' style={{ fontSize: '23px' }} />
              <p>Número inteiro</p>
            </li>
            <li onClick={() => selectType('NUMBER_DECIMAL')} onMouseEnter={() => setTypeHover('NUMBER_DECIMAL')}>
              <DiffTwoTone twoToneColor='#E26D5A' style={{ fontSize: '23px' }} />
              <p>Número decimal</p>
            </li>
            <li onClick={() => selectType('UNIQUE_CHOICE')} onMouseEnter={() => setTypeHover('UNIQUE_CHOICE')}>
              <DatabaseTwoTone twoToneColor='#BAC26C' style={{ fontSize: '23px' }} />
              <p>Única escolha</p>
            </li>
            <li onClick={() => selectType('MULTIPLE_CHOICE')} onMouseEnter={() => setTypeHover('MULTIPLE_CHOICE')}>
              <AppstoreTwoTone twoToneColor='RED' style={{ fontSize: '23px' }} />
              <p>Mútipla escolha</p>
            </li>
            <li onClick={() => selectType('GALLERY')} onMouseEnter={() => setTypeHover('GALLERY')}>
              <CloudTwoTone twoToneColor='#ff19a7' style={{ fontSize: '23px' }} />
              <p>Galeria</p>
            </li>
            <li onClick={() => selectType('DB_PRODUCTS')} onMouseEnter={() => setTypeHover('DB_PRODUCTS')}>
              <SnippetsTwoTone twoToneColor='#4FA9B3' style={{ fontSize: '23px' }} />
              <p>Produtos</p>
            </li>
          </ul>
        </ListTypes>
      </TypeLeft>
      <TypeRight>
        {typeHover === 'DATE' ? (
          <InfoType>
            <h3>Data</h3>
            <TextInfo>
              <p>Bom para saber o dia de alguma especificação.</p>
            </TextInfo>
          </InfoType>
        ) : typeHover === 'TEXT' ? (
          <InfoType>
            <h3>Texto pequeno</h3>
            <TextInfo>
              <p>Bom para respostas curtas, como nomes e o que você comeu no café da manhã.</p>
            </TextInfo>
          </InfoType>
          ) : typeHover === 'DB_PRODUCTS' ? (
            <InfoType>
              <h3>Produtos</h3>
              <TextInfo>
                <p>Bom para quando quer colocar as opções de produtos.</p>
              </TextInfo>
            </InfoType>
        ) : typeHover === 'MONEY' ? (
          <InfoType>
            <h3>Dinheiro</h3>
            <TextInfo>
              <p>Bom para saber o valor de terminado item.</p>
            </TextInfo>
          </InfoType>
        ) : typeHover === 'PICTURE_CAPTURE' ? (
          <InfoType>
            <h3>Foto</h3>
            <TextInfo>
              <p>Deixe uma opção de imagem para mostrar a identidade visual.</p>
            </TextInfo>
          </InfoType>
        ) : typeHover === 'QR_CODE' ? (
          <InfoType>
            <h3>QR / Bar Code</h3>
            <TextInfo>
              <p>Deixe uma opção para scanear os codigos dos produtos.</p>
            </TextInfo>
          </InfoType>
        ) : typeHover === 'BOOLEAN' ? (
          <InfoType>
            <h3>Sim ou Não</h3>
            <TextInfo>
              <p>Deixe apenas duas opções, sendo de forma direta e rapida.</p>
            </TextInfo>
          </InfoType>
        ) : typeHover === 'NUMBER_INTEGER' ? (
          <InfoType>
            <h3>Número Inteiro</h3>
            <TextInfo>
              <p>Aceita apenas respostas como números inteiros.</p>
            </TextInfo>
          </InfoType>
        ) : typeHover === 'NUMBER_DECIMAL' ? (
          <InfoType>
            <h3>Número Decimal</h3>
            <TextInfo>
              <p>Aceita apenas respostas como números quebrados.</p>
            </TextInfo>
          </InfoType>
        ) : typeHover === 'UNIQUE_CHOICE' ? (
          <InfoType>
            <h3>Única escolha</h3>
            <TextInfo>
              <p>Agrupe perguntas relacionadas para evitar repetir a mesma coisa.</p>
            </TextInfo>
          </InfoType>
        ) : typeHover === 'MULTIPLE_CHOICE' ? (
          <InfoType>
            <h3>Mútipla escolha</h3>
            <TextInfo>
              <p>Agrupe perguntas relacionadas para evitar repetir a mesma coisa.</p>
            </TextInfo>
          </InfoType>
        ) : typeHover === 'GALLERY' ? (
          <InfoType>
            <h3>Galeria</h3>
            <TextInfo>
              <p>Selecione fotos da galeria do celular.</p>
            </TextInfo>
          </InfoType>
        ) : null}
      </TypeRight>
    </ContainerType>
  )
}

export default TypeQuestion
