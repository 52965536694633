import { Table, Form, Input } from 'antd'
import React, { useContext, useEffect, useRef, useState } from 'react'
import type { FormInstance } from 'antd/lib/form'
import { ContainerTable } from './style'

interface Item {
  key: string
  name: string
  age: string
  address: string
}

interface EditableCellProps {
  title: React.ReactNode
  editable: boolean
  children: React.ReactNode
  dataIndex: keyof Item
  record: Item
  handleSave: (record: Item) => void
}

interface EditableRowProps {
  index: number
}

export const TableEdit = ({ productEdit, update }: any) => {
  const [dataSource, setDataSource] = useState<any>([])

  useEffect(() => {
    if (productEdit && productEdit.custom_fields) {
      let newData = Object.getOwnPropertyNames(productEdit.custom_fields).map((el: any, index: number) => {
        return {
          chave: el,
          key: index,
          valor: productEdit.custom_fields[el],
        }
      })
      setDataSource(newData)
    }
  }, [productEdit])

  const column = [
    {
      title: 'Chave',
      dataIndex: 'chave',
    },
    {
      title: 'Valor',
      dataIndex: 'valor',
      editable: true,
    },
  ]

  const handleSave = (row: any) => {
    const newData = [...dataSource]
    const index = newData.findIndex((item: any) => row.key === item.key)
    const item = newData[index]

    newData.splice(index, 1, {
      ...item,
      ...row,
    })
    update(newData)
  }

  const columns = column.map((col: any) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    }
  })

  const EditableCell: React.FC<EditableCellProps> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false)
    const inputRef = useRef<any>(null)
    const form = useContext(EditableContext)!

    useEffect(() => {
      if (editing) {
        inputRef.current!.focus()
      }
    }, [editing])

    const toggleEdit = () => {
      setEditing(!editing)
      form.setFieldsValue({ [dataIndex]: record[dataIndex] })
    }

    const save = async () => {
      try {
        const values = await form.validateFields()
        toggleEdit()
        handleSave({ ...record, ...values })
      } catch (errInfo) {
        console.log('Save failed:', errInfo)
      }
    }

    let childNode = children

    if (editable) {
      childNode = editing ? (
        <Form.Item style={{ margin: 0 }} name={dataIndex}>
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : (
        <div className='editable-cell-value-wrap' style={{ paddingRight: 24 }} onClick={toggleEdit}>
          {children}
        </div>
      )
    }

    return <td {...restProps}>{childNode}</td>
  }

  const EditableContext = React.createContext<FormInstance<any> | null>(null)

  const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm()
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    )
  }

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  }

  return (
    <ContainerTable>
      {productEdit && (
        <Table
          style={{ width: '100%' }}
          components={components}
          rowClassName={() => 'editable-row'}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
        />
      )}
    </ContainerTable>
  )
}
