import React, { useState } from 'react'
import { ContainerModal, ContainerStep2 } from './style'
import AddUser from '../../assets/images/addUser.png'
import { Upload, Button, message } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { Loading } from '../Loading'
import firebase from '../../utils/firebase'
import api_v2 from '../../services/api-v2'
import { Mixpanel } from '../../utils/mixpanel'

interface IProps {
  visible: boolean
  close: (boolean: any) => void
  reloadApi?: (boolean: any) => void
  folder: any
}

export const ModalUploadFile = ({ visible, close, reloadApi, folder }: IProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [pictures, setPictures] = useState<any>([])

  const handleChange = ({ file, fileList }: any) => {
    const team: any = sessionStorage.getItem('@rocketpdv:workspace')
    uploadImage({
      uri: file.originFileObj,
      slug: JSON.parse(team).slug,
      fileList: file,
      all: fileList,
      status: 'done',
    })
  }

  const uploadImage = async (urls: any) => {
    try {
      setLoading(true)
      let file = firebase.storage().ref(`${urls.slug}/trainning/${urls.uri.name}`)

      await file.put(urls.uri)
      const url = await file.getDownloadURL()

      let newPictures: any = []

      urls.all.forEach((el: any) => {
        if (el.uid === urls.fileList.uid) {
          newPictures.push({ ...urls.fileList, status: 'done', url: url })
        } else {
          newPictures.push({ ...el, status: 'done' })
        }
      })

      console.log(urls, newPictures)

      setPictures(newPictures)

      for await (let item of newPictures) {
        await api_v2.post('/trainings-file', {
          folder_id: parseInt(folder.id),
          extension: item.name.split('.')[1],
          name: item.name.split('.')[0],
          file_path: item.url,
        })
      }

      sendPicture()
      message.success('Sucesso ao subir arquivo')
    } catch (error) {
      console.log(error)
      setLoading(false)
      message.error('Erro ao subir arquivo')
    }
  }

  const sendPicture = async () => {
    try {
      setLoading(true)
      // for await (let item of pictures) {
      //   await api_v2.post('/trainings-file', {
      //     folder_id: parseInt(folder.id),
      //     extension: item.name.split('.')[1],
      //     name: item.name.split('.')[0],
      //     file_path: item.url,
      //   })
      // }

      // Mixpanel.track('Criou arquivo de treinamento', {
      //   params: {
      //     pictures: pictures,
      //     folder_id: folder.id,
      //   },
      // })
      setLoading(false)
      close(!visible)
      if (reloadApi) reloadApi(true)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  return (
    <ContainerModal
      visible={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={sendPicture}
      closable={true}
      width={450}
    >
      {loading && <Loading />}

      <ContainerStep2>
        <h3>Upload</h3>

        {/* <h3>Cadastro de {type === 'PROMOTOR' ? 'Promotor' : type === 'GESTOR' ? 'Gestor' : null}</h3> */}
        <img src={AddUser} alt='user' />

        <Upload
          action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
          listType='picture'
          onChange={handleChange}
          fileList={pictures}
          style={{ width: '100%' }}
          beforeUpload={(file: any) => {
            const isPNG = file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'application/pdf'
            if (!isPNG) {
              message.error(`${file.name} não é imagem ou PDF.`)
            }
            return isPNG || Upload.LIST_IGNORE
          }}
        >
          <Button icon={<UploadOutlined />} style={{ width: '100%' }}>
            Upload
          </Button>
        </Upload>
      </ContainerStep2>
    </ContainerModal>
  )
}
