import styled from "styled-components"

export const RemoveIndex = styled.div`
  &&& .ant-drawer {
    z-index: 0 !important;
  }
`

export const ContainerCard = styled.div`
  width: 100%;
  height: 160px;
  border-radius: 4px;
  border: solid 1px #f0f2f3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  .containerFirst {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: space-between;
  }

  .containerIcon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    cursor: pointer;
  }

  .containerFirst .containerIcon p {
    text-align: center;
    width: 100%;
    color: #98a2ac;
    font-size: 13px;
  }

  .containerIcon:hover p {
    transition: all 0.3s;
    color: #aaa3d6;
  }

  .containerIcon:hover svg {
    transition: all 0.3s;
    color: #aaa3d6 !important;
  }

  .containerFirst p {
    text-align: center;
    width: 100%;
    color: #6c7a89;
    font-size: 17px;
  }

  .containerSecond{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 53px;
    border-top: solid 1px #f0f2f3;
  }

  .containerSecond p {
    text-align: center;
    color: #98a2ac;
    font-size: 17px;
    margin-left: 10px;
  }

  :hover {
    transition: all 0.3s;
    border: solid 1px #b46aff;

    .containerSecond{
      transition: all 0.3s;
      border-top: solid 1px #b46aff;
    }
  }

  &&& .ant-switch-handle{
    width: 15px;
    height: 15px;
    top: 3px;
  }

  &&& .ant-switch-checked {
    background-color: #b46aff;
  }
`