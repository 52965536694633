import { Breadcrumb, Button, Col, Dropdown, Menu, Row, Table, Tag, Tooltip } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { BigButton, ContainerButtonsFootter, ContainerFormFilter, ContainerList, GroupForm } from '../../GlobalStyles'
import { FilterOutlined, FileSearchOutlined, ClearOutlined, EyeOutlined, MoreOutlined } from '@ant-design/icons'
import { DrawerFilter } from '../../components/DrawerFilter'
import { ButtonClear, ButtonSearch, LabelForm } from '../Team/Promoter/style'
import history from '../../routes/history'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { Search } from '../../components/form/SimpleSearch'
import { INIT_GLOBAL_RESPONSE } from '../../utils/global-response'
import { NoSearch } from '../../components/NoSearch'
import { ColumnsType } from 'antd/lib/table'
import { DataStatus, StatusInativo, StatusRealizado } from '../../components/TabsVisits/style'
import { compareByAlph } from '../../utils/funcoes'
import api_v2 from '../../services/api-v2'
import qs from 'querystring'
import { ContextUpload } from '../../context/contextUpload'
import { hasIndex } from '../../components/Menu/menuHorizonte'
import { ContainerTagg } from '../Survey/FormListPage/styles'
import { CloseOutlined } from '@material-ui/icons'

export const ListDash = () => {
  const formRef = useRef<FormHandles>(null)
  const { permissions } = ContextUpload()

  const [visible, setVisible] = useState(false)
  const [dash, setDash] = useState<any>(INIT_GLOBAL_RESPONSE)
  const [filter, setFilter] = useState<any>({})

  const cleanFilter = async () => {
    history.push(`/analitycs`)
    formRef!.current!.reset()
  }

  const searchDash = async (body: any) => {
    try {
      setFilter(body)
      setDash({ ...dash, subLoading: true })
      const query = qs.stringify({
        names: body?.dash?.label
      })

      const { data } = await api_v2.get('/analytics/custom-dashboards?' + query)
      setDash({ ...data, subLoading: false })
    } catch (error) {
      console.log(error)
    }


  }

  const handleTableChange = async (queryParams: any) => {
    setDash({ ...dash, subLoading: true })

    const query = qs.stringify({
      perpage: queryParams.pageSize,
      page: queryParams.current,
    })
    const { data } = await api_v2.get('/analytics/custom-dashboards?' + query)
    setDash({ ...data, subLoading: false })
  }

  const getStatus = (status: any) => {
    const listStatus: any = {
      PENDENT: (
        <Tooltip title='Inativo'>
          <StatusInativo />
        </Tooltip>
      ),
      COMPLETE: (
        <Tooltip title='Ativo'>
          <StatusRealizado />
        </Tooltip>
      ),
    }

    if (status === true) {
      return listStatus.COMPLETE
    } else if (status === false) {
      return listStatus.PENDENT
    } else {
      return '-'
    }
  }

  const columns: ColumnsType<any> = [
    {
      title: 'Status',
      dataIndex: 'is_active',
      key: 'status',
      width: 80,
      render: (status: any) => {
        return <DataStatus>{getStatus(status)}</DataStatus>
      },
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      sorter: (a, b) => compareByAlph(a.name, b.name),
    },
    {
      title: '#',
      width: 50,
      render: (obj: any) => {
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  key='1'
                  icon={<EyeOutlined />}
                  onClick={() => history.push(`/analitycs/view/0&${qs.stringify({ frame: obj.frame })}`)}
                >
                  Visualizar
                </Menu.Item>
              </Menu>
            }
          >
            <Button size='small' shape='circle' icon={<MoreOutlined />} />
          </Dropdown>
        )
      },
    },
  ]

  const getDash = async () => {
    try {
      let { data } = await api_v2.get('/analytics/custom-dashboards')
      setDash({ ...data, loading: false })
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getDash()
  }, [])

  const removeFilter = async (typeRemove: string, value: any) => {
    setFilter({})
    getDash()
  }

  return (
    <ContainerList>
      <Row gutter={[8, 8]} justify='space-between'>
        <Col span={12}>
          <h1>Dashboard</h1>
          <Breadcrumb className='breadcumb'>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col>
          <div style={{display: 'flex', alignItems: 'flex-end', flexDirection: 'column'}}>
            <Tooltip placement='top' title={'Filtrar'}>
              <BigButton onClick={() => setVisible(!visible)} style={{ padding: 9 }}>
                <FilterOutlined />
              </BigButton>
            </Tooltip>
            <ContainerTagg>
              {filter.dash &&
                <Tag color='geekblue' onClick={() => removeFilter('dash', filter.dash.value)}>
                  {filter.dash.label} <CloseOutlined />
                </Tag>
              }
            </ContainerTagg>
          </div>
        </Col>
      </Row>
      {hasIndex(['dash.list_dash'], permissions) > -1 && (
        <>
          {dash.result && dash.result.length > 0 ? (
            <Table
              columns={columns}
              rowKey={(product) => product.id}
              dataSource={dash.result}
              size='middle'
              pagination={{
                // position: 'both',
                current: dash.page,
                pageSize: dash.perpage,
                total: dash.total,
                size: 'small',
                showSizeChanger: true,
                showTotal: () => `Exibindo ${dash.result.length} de ${dash.total} de registros`,
              }}
              loading={dash.loading}
              onChange={handleTableChange}
            />
          ) : (
            <NoSearch text='Não encontramos nenhum dado aqui.' />
          )}
        </>
      )}
      <DrawerFilter
        visible={visible}
        close={setVisible}
        title='Produtos'
        footer={
          <ContainerButtonsFootter>
            <ButtonSearch type='button' onClick={() => formRef?.current?.submitForm()}>
              <FileSearchOutlined />
              Pesquisar
            </ButtonSearch>
            <ButtonClear onClick={() => cleanFilter()}>
              <ClearOutlined />
              Limpar
            </ButtonClear>
          </ContainerButtonsFootter>
        }
      >
        <ContainerFormFilter
          tabIndex={0}
          onKeyDown={(evt: any) => {
            if (evt.key === 'Enter') formRef?.current?.submitForm()
          }}
        >
          <Form ref={formRef} className='form' style={{ marginTop: '15px' }} onSubmit={searchDash}>
            <GroupForm>
              <LabelForm>Selecione o nome</LabelForm>
              <Search placeholder='Selecione o nome' name='dash' path='dash' isMult={false} />
            </GroupForm>
          </Form>
        </ContainerFormFilter>
      </DrawerFilter>
    </ContainerList>
  )
}
