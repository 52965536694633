import { Container } from "./style"
import Img from '../../assets/images/undraw_notify_re_65on.svg'

export const NoSearch = ({text}: any) => {
    return (
        <Container>
            <h3>
            {text}
            </h3>
            <img src={Img} alt="Map"/>
        </Container>
    )
}