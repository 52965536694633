import 'antd/dist/antd.css'
import dotenv from 'dotenv'
import dotenvExpand from 'dotenv-expand'
import React from 'react'

import { GlobalStyles } from './GlobalStyles'
import Routes from './routes'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { getWorkspace, isAuthenticated, isAuthenticatedaPP } from './utils/login'
import history from './routes/history'
import { UploadProvide } from './context/contextUpload'
// import api from './services/api'
// import api_v2 from './services/api-v2'
// import { Manutencao } from './pages/manutencao'

const env = dotenv.config()
dotenvExpand(env)

const App: React.FC = () => {
  //   return <Manutencao />
  // }
  if (isAuthenticated() && getWorkspace() && !isAuthenticatedaPP()) {
    history.push('/viewsApp')
  }
  const getUrl = async () => {
    if (getWorkspace() === '') {
      return history.push('/backoffice/workspace')
    }
    if (window.location.pathname.indexOf('Analitycs') > -1 && isAuthenticatedaPP() !== '"Analitycs"')
      return history.push('/viewsApp')
    if (
      (window.location.pathname.indexOf('backoffice') > -1 || window.location.pathname === '/') &&
      isAuthenticatedaPP() !== '"Backoffice"'
    )
      return history.push('/viewsApp')
    if (window.location.pathname.indexOf('PDVManager') > -1 && isAuthenticatedaPP() !== '"PDV Manager"')
      return history.push('/viewsApp')
    if (window.location.pathname.indexOf('Supervisor') > -1 && isAuthenticatedaPP() !== '"Supervisor"')
      return history.push('/viewsApp')
    if (window.location.pathname.indexOf('Pipiline') > -1 && isAuthenticatedaPP() !== '"Pipiline"')
      return history.push('/viewsApp')
    if (window.location.pathname.indexOf('Incentive') > -1 && isAuthenticatedaPP() !== '"Incentive"')
      return history.push('/viewsApp')
  }

  getUrl()
  return (
    <DndProvider backend={HTML5Backend}>
      <GlobalStyles />
      <UploadProvide>
        <Routes />
      </UploadProvide>
    </DndProvider>
  )
}

export default App
