import React, { useEffect, useRef, useState } from 'react'
import DragDrop from '../DragDrop'

import {
  InfoCircleOutlined,
  CopyTwoTone,
  DiffTwoTone,
  CameraTwoTone,
  QuestionCircleTwoTone,
  AccountBookTwoTone,
  DatabaseTwoTone,
  DeleteTwoTone,
  ExclamationCircleOutlined,
  AppstoreTwoTone,
  CheckCircleTwoTone,
  QrcodeOutlined,
  CloudTwoTone,
  SnippetsTwoTone
} from '@ant-design/icons'
import { ContainerQuestion, IdQuestion, ButtonStyle, Forms, ContainerModal } from './style'
import { Input, Tooltip, Modal, Tabs } from 'antd'

const { TabPane } = Tabs
const { confirm } = Modal

const QuestionBuilder: React.FC<any> = ({
  isDragging,
  question,
  edit,
  openConfigQuestion,
  setQuestion,
  questionDelete,
  jump,
}) => {
  const inputRef = useRef<any>(null)
  const [modalJump, setModalJump] = useState<boolean>(false)
  
  useEffect(() => {
    if (question && question.focus === true) {
      inputRef.current!.focus({
        cursor: 'start',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleConfig = async () => {
    setQuestion(question)
    openConfigQuestion(true)
  }

  const handleDelete = (question: any) => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: `Tem certeza que gostaria de excluir a questão: ${question.name}, na ordem: ${question.order}, ao excluir a questão ela não irá mais aparecer na extração.`,
      onOk() {
        questionDelete(question)
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  const removeTrue = async (questionDelete: any) => {
    let newQuestionListen: any = []
    let index = 0
    question.fields_true.forEach((obj: any, i: any) => {
      if (questionDelete.id !== obj.id) {
        if (index === 1) {
          newQuestionListen.push({
            ...obj,
            order: `${parseInt(obj.order) - 1} `,
          })
        } else {
          newQuestionListen.push(obj)
        }
      } else {
        index = 1
      }
    })

    edit({
      ...question,
      fields_true: newQuestionListen,
    })
  }

  const includeTrue = (fields_true: any) => {
    edit({
      ...question,
      fields_true: !question.fields_true ? [fields_true] : [...question.fields_true, fields_true],
    })
  }

  const includeFalse = (fields_false: any) => {
    edit({
      ...question,
      fields_false: !question.fields_false ? [fields_false] : [...question.fields_false, fields_false],
    })
  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const includeField = async (fields: any, opt: any, indexOpt: any) => {
    let data = Object.assign(fields, { opt: opt })
    let notHave: any = {
      subFields: [],
    }

    if (!question.fields) {
      question.options.forEach((jump: any, indexs: any) => {
        notHave[indexs] = { subFields: [] }

        if (indexs === indexOpt) {
          let insert: any = []
          if (question.fields && question.fields[indexs] && question.fields[indexs] && question.fields[indexs].subFields && question.fields[indexs].subFields.length > 0) {
            question.fields[indexs].subFields.forEach((field: any) => {
              if (field && field.length > 0) field.forEach((inser: any) => insert.push(inser))
            })
          }
          insert.push(data)
          notHave[indexOpt].subFields = insert
        } else {
          if (question.fields && question.fields[indexs]) notHave[indexs].subFields = question.fields[indexs].subFields

        }
      })

      edit({
        ...question,
        fields: notHave,
      })
    } else {
      let newPush = question.fields
      

      if (newPush[indexOpt] && newPush[indexOpt]?.subFields) {
        newPush[indexOpt].subFields.push(fields)

        edit({
          ...question,
          fields: newPush,
        })
      } else {
        newPush[indexOpt] = {
          subFields: [fields],
        }

        edit({
          ...question,
          fields: newPush,
        })
      }
    }
  }

  const removeField = (questionDelete: any, indexOpt: any) => {
    let newQuestionListen: any = []

    Object.keys(question.fields).forEach((opts: any, i: any) => {
      let data: any = []
      if (opts !== 'subFields') {
        if (question.fields[opts] && question.fields[opts].subFields) {
          question.fields[opts].subFields.forEach((field: any) => {
            if (questionDelete.id !== field.id) {
              data.push(field)
            }
          })
        }
      }
      newQuestionListen.push({
        subFields: data,
      })
    })

    edit({
      ...question,
      fields: newQuestionListen,
    })
  }

  const removeFalse = (questionDelete: any) => {
    let newQuestionListen: any = []
    let index = 0
    question.fields_false.forEach((obj: any, i: any) => {
      if (questionDelete.id !== obj.id) {
        if (index === 1) {
          newQuestionListen.push({
            ...obj,
            order: `${parseInt(obj.order) - 1}`,
          })
        } else {
          newQuestionListen.push(obj)
        }
      } else {
        index = 1
      }
    })

    edit({
      ...question,
      fields_false: newQuestionListen,
    })
  }

  const refactorOrder = async (orderQuestion: any) => {
    const newQuestionOrder = orderQuestion.map((b: any, index: any) => {
      return { ...b, order: `${parseInt(index) + 1}` }
    })
    edit({
      ...question,
      fields_true: !question.fields_true ? newQuestionOrder : newQuestionOrder,
    })
  }

  const refactorOrderFalse = async (orderQuestion: any) => {
    const newQuestionOrder = orderQuestion.map((b: any, index: any) => {
      return { ...b, order: `${parseInt(index) + 1}` }
    })
    edit({
      ...question,
      fields_false: !question.fields_false ? newQuestionOrder : newQuestionOrder,
    })
  }

  const refactorOrderField = async (orderQuestion: any, indexOpt: any) => {
    let questionClone = { ...question }; 
    
    if(questionClone.fields[indexOpt] && questionClone.fields[indexOpt].subFields) questionClone.fields[indexOpt].subFields = orderQuestion

    edit({
      ...questionClone,
    })

  }
  const editName = async (valueName: any) => {
    question.name = valueName
    edit({ ...question, name: valueName })
  }

  return (
    <>
      <ContainerQuestion isDragging={isDragging}>
        <IdQuestion>
          {question.type === 'DATE' ? (
            <ButtonStyle onClick={() => handleConfig()} style={{ backgroundColor: '#4FA9B3' }}>
              <CheckCircleTwoTone twoToneColor='#4FA9B3' style={{ fontSize: '23px' }} />
              <p>{question.order}</p>
            </ButtonStyle>
          ) : question.type === 'TEXT' ? (
            <ButtonStyle onClick={() => handleConfig()} style={{ backgroundColor: '#FABA48' }}>
              <CopyTwoTone twoToneColor='#FABA48' style={{ fontSize: '23px' }} />
              <p>{question.order}</p>
            </ButtonStyle>
          ) : question.type === 'MONEY' ? (
            <ButtonStyle onClick={() => handleConfig()} style={{ backgroundColor: '#E26D5A' }}>
              <DiffTwoTone twoToneColor='#E26D5A' style={{ fontSize: '23px' }} />
              <p>{question.order}</p>
            </ButtonStyle>
          ) : question.type === 'PICTURE_CAPTURE' ? (
            <ButtonStyle onClick={() => handleConfig()} style={{ backgroundColor: '#A086C3' }}>
              <CameraTwoTone twoToneColor='#A086C3' style={{ fontSize: '23px' }} />
              <p>{question.order}</p>
            </ButtonStyle>
             ) : question.type === 'DB_PRODUCTS' ? (
              <ButtonStyle onClick={() => handleConfig()} style={{ backgroundColor: '#4FA9B3' }}>
                <SnippetsTwoTone twoToneColor='#4FA9B3' style={{ fontSize: '23px' }} />
                <p>{question.order}</p>
              </ButtonStyle>
          ) : question.type === 'QR_CODE' ? (
            <ButtonStyle onClick={() => handleConfig()} style={{ backgroundColor: '#3ca133' }}>
              <QrcodeOutlined twoToneColor='#3ca133' style={{ fontSize: '23px' }} />
              <p>{question.order}</p>
            </ButtonStyle>
          ) : question.type === 'BOOLEAN' ? (
            <ButtonStyle onClick={() => handleConfig()} style={{ backgroundColor: '#BF415C' }}>
              <QuestionCircleTwoTone twoToneColor='#BF415C' style={{ fontSize: '23px' }} />
              <p>{question.order}</p>
            </ButtonStyle>
          ) : question.type === 'NUMBER_INTEGER' ? (
            <ButtonStyle onClick={() => handleConfig()} style={{ backgroundColor: '#E05178' }}>
              <AccountBookTwoTone twoToneColor='#E05178' style={{ fontSize: '23px' }} />
              <p>{question.order}</p>
            </ButtonStyle>
          ) : question.type === 'NUMBER_DECIMAL' ? (
            <ButtonStyle onClick={() => handleConfig()} style={{ backgroundColor: '#E05178' }}>
              <DiffTwoTone twoToneColor='#E26D5A' style={{ fontSize: '23px' }} />
              <p>{question.order}</p>
            </ButtonStyle>
          ) : question.type === 'UNIQUE_CHOICE' ? (
            <ButtonStyle onClick={() => handleConfig()} style={{ backgroundColor: '#BAC26C' }}>
              <DatabaseTwoTone twoToneColor='#BAC26C' style={{ fontSize: '23px' }} />
              <p>{question.order}</p>
            </ButtonStyle>
          ) : question.type === 'MULTIPLE_CHOICE' ? (
            <ButtonStyle onClick={() => handleConfig()} style={{ backgroundColor: 'red' }}>
              <AppstoreTwoTone twoToneColor='red' style={{ fontSize: '23px' }} />
              <p>{question.order}</p>
            </ButtonStyle>
          ) : question.type === 'GALLERY' ? (
            <ButtonStyle onClick={() => handleConfig()} style={{ backgroundColor: '#ff19a7' }}>
              <CloudTwoTone twoToneColor='#ff19a7' style={{ fontSize: '23px' }} />
              <p>{question.order}</p>
            </ButtonStyle>
          ) : null}
        </IdQuestion>
        <Forms>
          <Input
            placeholder='Título da pergunta'
            onChange={(evt: any) => editName(evt.target.value)}
            style={{ textTransform: 'uppercase' }}
            value={question.name}
            ref={inputRef}
            suffix={
              <Tooltip title='Aqui vai o titulo da pergunta'>
                <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
              </Tooltip>
            }
          />
        </Forms>
        <Tooltip title='Deletar questão'>
          <DeleteTwoTone
            twoToneColor='red'
            style={{ fontSize: '19px', cursor: 'pointer' }}
            onClick={() => handleDelete(question)}
          />
        </Tooltip>
        {question?.type === 'UNIQUE_CHOICE' || question?.type === 'MULTIPLE_CHOICE' || question?.type === 'BOOLEAN' ? (
          <Tooltip title='Inserir order jump'>
            <CheckCircleTwoTone
              twoToneColor='violet'
              style={{
                fontSize: '18px',
                marginLeft: '20px',
              }}
              onClick={() => setModalJump(true)}
            />
          </Tooltip>
        ) : null}
      </ContainerQuestion>
      {/* {question.description ? (
        <FormsDescri>
          <Input
            placeholder='Aqui vai a descrição da pergunta'
            onChange={(evt: any) => edit({ ...question, description: evt.target.value })}
            autoFocus={true}
            suffix={
              <Tooltip title='Aqui vai a descrição da pergunta'>
                <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
              </Tooltip>
            }
          />
        </FormsDescri>
      ) : null} */}

      <Modal
        title={question?.name}
        visible={modalJump}
        onOk={() => setModalJump(false)}
        onCancel={() => setModalJump(false)}
        width={1000}
        bodyStyle={{ minHeight: '700px', zIndex: 1 }}
      >
        <ContainerModal>
          {question?.type === 'BOOLEAN' ? (
            <>
              <Tabs
                defaultActiveKey='1'
                style={{ width: '100%', height: '100%' }}
              >
                <TabPane tab='Se sim' key='1'>
                  <DragDrop
                    jump={jump + 1}
                    questions={question.fields_true}
                    editQuestion={edit}
                    questionDelete={removeTrue}
                    addQuestion={includeTrue}
                    refactorOrder={refactorOrder}
                    setConfigQuestion={setQuestion}
                    setOpenConfig={openConfigQuestion}
                  // openConfigQuestion={openConfigQuestion}
                  />
                </TabPane>
                <TabPane tab='Se não' key='2'>
                  <DragDrop
                    jump={1}
                    questions={question.fields_false}
                    editQuestion={edit}
                    questionDelete={removeFalse}
                    addQuestion={includeFalse}
                    refactorOrder={refactorOrderFalse}
                    setConfigQuestion={setQuestion}
                    setOpenConfig={openConfigQuestion}

                  // openConfigQuestion={openConfigQuestion}
                  />
                </TabPane>
              </Tabs>
            </>
          ) : question?.type === 'UNIQUE_CHOICE' || question?.type === 'MULTIPLE_CHOICE' ? (
            <>
              <Tabs
                defaultActiveKey='1'
                style={{ width: '100%', height: '100%' }}
              >
                {question?.options?.map((opt: any, indexOpt: any) => (
                  <TabPane tab={opt} key={indexOpt}>
                    <DragDrop
                      jump={1}
                      questions={question.fields ? question.fields[indexOpt] : []}
                      editQuestion={edit}
                      questionDelete={(fields: any) => removeField(fields, indexOpt)}
                      addQuestion={(fields: any) => includeField(fields, opt, indexOpt)}
                      refactorOrder={(quest: any) => refactorOrderField(quest, indexOpt)}
                      setConfigQuestion={setQuestion}
                      setOpenConfig={openConfigQuestion}

                    // openConfigQuestion={openConfigQuestion}
                    />
                  </TabPane>
                ))}
              </Tabs>
            </>
          ) : null}
        </ContainerModal>
      </Modal>
    </>
  )
}

export default QuestionBuilder
