import React, { useEffect, useState } from 'react'
import { ButtonSearch } from '../../../pages/Team/Promoter/style'
import { FileSearchOutlined } from '@ant-design/icons'
import Api from '../../../services/api'
import { ViewForm } from './Aswers'
import api_v2 from '../../../services/api-v2'
import { Loading } from '../../Loading'
import { Modal, Radio, message } from 'antd'
import { ContainerRadio } from './Aswers/Boolean/styles'
import { ContainerAnswer, TitleAnswer } from '../../ModalReportSurvey/Step2/CardAnswerProduct/style'
import { Mixpanel } from '../../../utils/mixpanel'

interface IProps {
  close: (value: boolean) => void
  formId: number
  body: any
}

export const Step2 = ({ formId, body, close }: IProps) => {
  const [form, setForm] = useState<any>({})
  const [dataAnswer, setDataAnswer] = useState<any>([])
  const [teste, setTeste] = useState([])
  const [error, setError] = useState<number[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [answer, setAnswer] = useState<any>([])
  const [checked, setChecked] = useState<any>()

  const checks = (id: any) => {
    let ids: any = teste
    ids.push(id)
    setTeste(ids)
  }

  const handleSetAnswers = (answers: any) => {
    setDataAnswer((prevDataAnswer) => {
      let exist = false;
      const newDataAnswer = prevDataAnswer.map((answer: any) => {
        if (answer.id === answers.id) {
          exist = true;
          return { ...answer, field_value: answers.field_value };
        } else {
          return answer;
        }
      });
  
      if (!exist) {
        newDataAnswer.push(answers);
      }
  
      return [...newDataAnswer];
    });
  };
  

  const getForm = async () => {
    setLoading(true)
    const { data } = await Api.get(`/form/form_id/${formId}`)
    setForm(data.firebase_body)
    setLoading(false)
  }

  const finish = (evt: any) => {
    evt.preventDefault()
  }

  const searchQuestion = (total: any) => {
    let ids: any = []
    total.forEach((field: any, index: number) => {
      let exist = false
      dataAnswer.forEach((element: any) => {
        if (element.id === field.id) {
          if (element && element.field_value && element.field_value.length > 0) exist = true
        }
      })
      if (!exist) {
        if (field.is_required) ids.push(field.id)
      } else {

        if (field.type === 'GALLERY' || field.type === 'PICTURE_CAPTURE') {
          let value
          dataAnswer.forEach((element: any) => {
            if (element.id === field.id) value = element.field_value
          })
          if (field.minPicture && value.length < field.minPicture) {
            exist = false
            ids.push(field.id)
          }

          if (field.maxPicture && value.length > field.maxPicture) {
            exist = false
            ids.push(field.id)
          }
        }

        if (field.type === 'NUMBER_INTEGER' || field.type === 'NUMBER_DECIMAL') {
          let value

          dataAnswer.forEach((element: any) => {
            if (element.id === field.id) value = element.field_value
          })


          if (field.minNumber && parseFloat(value) < field.minNumber) {
            exist = false
            ids.push(field.id)
            return Modal.error({
              title: 'Valor mínimo',
              content: `O valor mínimo na questão ${field.field_order} é ${field.minNumber}.`,
              onOk() {
                close(false)
              },
            })

          
          }

          if (field.maxNumber && parseFloat(value) > field.maxNumber) {
            exist = false
            ids.push(field.id)
            return Modal.error({
              title: 'Valor máximo',
              content: `O valor máximo na questão ${field.field_order} é ${field.maxNumber}.`,
              onOk() {
                close(false)
              },
            })
          }
        }
        
        if (field.type === 'BOOLEAN') {
          let value
          dataAnswer.forEach((element: any) => {
            if (element.id === field.id) value = element.field_value[0]
          })

          if (value === 'SIM' && field.fields_true) {
            let oldId = ids
            let newId: any = searchQuestion(field.fields_true)
            ids = [...oldId, ...newId]
          }

          if (value === 'NÃO' && field.fields_false) {
            let oldId = ids
            let newId: any = searchQuestion(field.fields_false)
            ids = [...oldId, ...newId]
          }

        }

        if (field.type === 'UNIQUE_CHOICE') {
          let value
          dataAnswer.forEach((element: any) => {
            if (element.id === field.id) value = element.field_value
          })

          if (field.fields) {
            let byIndex = -1
            if (value.length > 0) {
              field.options.forEach((el, index) => {
                if (el === value[0]) byIndex = index
              })

              if (byIndex > -1) {
                if (Object.keys(field.fields[byIndex]).length > 0) {
                  if (field.fields[byIndex].subFields && field.fields[byIndex].subFields.length > 0) {
                    let oldId = ids
                    let newId: any = searchQuestion(field.fields[byIndex].subFields)
                    ids = [...oldId, ...newId]
                  }
                }
              }
            }
          }
        }
      }
    })

    return ids
  }

  const handleCheckAnswer = async () => {
    try {
      console.log(form.fields)
      let idsObrigatorios = searchQuestion(form.fields)
      if (idsObrigatorios.length > 0) {
        setError(idsObrigatorios)
        return
      }

      let clearAnswer: any = []

      const getClearAnser = (formBody: any) => {
        formBody.forEach((el: any) => {
          dataAnswer.forEach((al: any) => {
            if (al.id === el.id) {
              if (
                el.fields_true &&
                el.fields_true.length > 0 &&
                el.type === 'BOOLEAN' &&
                al.field_value[0] === 'SIM' &&
                Array.isArray(el.fields_true)
              ) {
                getClearAnser(el.fields_true)
              }
              if (
                el.fields_false &&
                el.fields_false.length > 0 &&
                el.type === 'BOOLEAN' &&
                al.field_value[0] === 'NÃO' &&
                Array.isArray(el.fields_false)
              ) {
                getClearAnser(el.fields_false)
              }

              if (al.field_type === 'UNIQUE_CHOICE') {
                let getIndex = el.options.indexOf(al.field_value[0])
                if (
                  el.fields &&
                  el.fields[getIndex] &&
                  el.fields[getIndex]?.subFields &&
                  Array.isArray(el.fields[getIndex]?.subFields)
                )
                  getClearAnser(el.fields[getIndex]?.subFields)
              }

              if (al.field_type === 'MULTIPLE_CHOICE') {
                if (al.field_value && al.field_value.length > 0) {
                  al.field_value.forEach((ma: any) => {
                    let getIndex = el.options.indexOf(ma)
                    if (
                      el.fields &&
                      el.fields[getIndex] &&
                      el.fields[getIndex]?.subFields &&
                      Array.isArray(el.fields[getIndex]?.subFields)
                    )
                      getClearAnser(el.fields[getIndex]?.subFields)
                  })
                }
              }

              clearAnswer.push(al)
            }
          })
        })
      }

      getClearAnser(form.fields)

      setLoading(true)
      const answer_id = body.all[0].answer_id
      let answers = []

      if(form.context == 'PRODUCT') {
        
        answers.push(answer.find((item) => {
          console.log(item, 'item')
          return item.id == 0
        }))
        answers = [...answers, ...dataAnswer]

      } else {
        answers = dataAnswer
      }

      await api_v2.put(`/answer/${answer_id}`, {
        answers: answers,
      })

      Mixpanel.track('Editou resposta', {
        params: { new_answers: answers, id: answer_id, old_answers: body.all },
      })
 
      message.success('Resposta processada com sucesso.')
      setTimeout(() => {
        window.location.reload()
      }, 1500);
      close(false)
      setLoading(false)
    } catch (error) {
      console.log(error)
      message.error('Erro ao processar resposta.')
      close(false)
      setLoading(false)
    }
  }

  useEffect(() => {
    getForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId])

  const checkAnswe = (index: any) => {
    index = index.target.value

    if (index === 'Não') {
      setAnswer([{
        field_name: 'O produto está presente na loja?',
        field_order: 0,
        field_type: 'BOOLEAN',
        field_value: ['NÃO'],
        id: 0,
      }])

      
    } else if (index === 'Sim') {
      setAnswer([{
        field_name: 'O produto acima está presente na loja ?',
        field_order: '0',
        field_type: 'BOOLEAN',
        field_value: ['sim'],
        id: 0,
      }])

    }
    setChecked(index)
  }

  useEffect(() => {
    if(body.all && form.context == 'PRODUCT') {
      let answer = body.all.map((item) => {
        delete item.product_name
        delete item.product_id
        return item
      })

      setAnswer(answer)
      let val = body.all.filter((item) => item.id == 0)[0].field_value[0].toLowerCase()
      checkAnswe({
        target: {
          value: val == 'sim' ?  'Sim' : 'Não' ,
        },
      })
    }
  }, [body, form])

  return (
    <form onSubmit={finish} style={{ height: '100%' }}>
      {loading && <Loading />}
      <>
        <h3
          style={{
            fontWeight: 'bold',
            fontSize: 17,
            textAlign: 'center',
          }}
        >
          {form.name}
        </h3>
        {form.context == 'PRODUCT' ? (
          <>
            <ContainerAnswer>
              <TitleAnswer>O produto está presente na loja ?</TitleAnswer>
              <ContainerRadio>
                <Radio.Group onChange={checkAnswe} value={checked}>
                  <Radio value={'Sim'}>Sim</Radio>
                  <Radio value={'Não'}>Não</Radio>
                </Radio.Group>
              </ContainerRadio>
            </ContainerAnswer>
            {checked === 'Sim' && (
              <ViewForm
                body={body}
                product={{
                  name: body.all.product_name,
                  id: body.all.product_id,
                }}
                data={form ? form : []}
                handleSetAnswers={handleSetAnswers}
                checkRequired={(a: any) => checks(a)}
                error={error}
              />
            )}
          </>
        ) : (
          <ViewForm
            data={form}
            handleSetAnswers={handleSetAnswers}
            checkRequired={(a: any) => checks(a)}
            error={error}
            body={body}
          />
        )}
      </>

      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <ButtonSearch type='button' style={{ width: 150 }} onClick={() => handleCheckAnswer()}>
          <FileSearchOutlined />
          Editar
        </ButtonSearch>
      </div>
    </form>
  )
}
