import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;

  h1,
  p {
    padding: 0px 10px;
  }
  form {
    margin-top: 30px;
    font-weight: 600;
  }
  button {
    text-align: center;
    margin-top: 10px;
  }
  label {
    font-weight: 600;
  }
  input,
  select {
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    height: 38px;
    padding: 0 15px;
    color: rgba(0, 0, 0, 0.65);
    margin: 0 0 10px;
    width: 100%;
    display: inline-block;

    &::placeholder {
      color: #d9d9d9;
    }
    &:hover,
    &:focus {
      border-color: #1890ff;
    }
  }

  /* .DateAnt {
    background: none;
    border: none;
    width: 100%;
    padding: 0;

    &:focus {
      border-color: none;
    }
  }
  .ant-picker-focused {
    box-shadow: none;
  } */

  .pre-select {
    white-space: nowrap;
  }

  &&& .css-g1d714-ValueContainer{
    height: 39px;
  }

  &&& .css-yk16xz-control{
    height: 39px;
  }
`

export const ContainerControl = styled.div`
  float: right;

  button {
    margin: 0 15px;
  }
`

export const BtnExport = styled.a`
  padding: 10px;
  color: #fff;

  &:hover {
    color: #fff;
  }
`
