import React, { useState } from 'react'
import { exportXls } from '../../../utils/funcoes'
import DefaultPage from '../../../components/DefaultPage'
import qs from 'querystring'
import moment from 'moment'
import useReactRouter from 'use-react-router'
import { Tooltip } from 'antd'
import { truncateString } from '../../../utils/funcao'

const PointListPage: React.FC = () => {
  const { location } = useReactRouter()

  const [reload] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  function convertMinutesToTime(minutes) {
    let hours = Math.floor(minutes / 60);
    let remainingMinutes = Math.floor(minutes % 60);
    let seconds = Math.floor((minutes % 1) * 60);

    let formattedTime = `${hours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

    return formattedTime;
  }

  let convertUrlToExportXls = (defaultUrl?: any) => {
    try {
      let getUrl: any = qs.parse(location.search.replaceAll('?', ''))

      let filterQuery: any = {}
      for (var el in getUrl) {
        if (el === 'date') {
          filterQuery['start_date'] = JSON.parse(getUrl[el])[0]
          filterQuery['end_date'] = JSON.parse(getUrl[el])[1]
        } else {
          let convert = JSON.parse(getUrl[el])
          if (convert && convert.length > 0) {
            filterQuery[el] = convert.map((e: any) => e.value)
          } else {
            filterQuery[el] = JSON.parse(getUrl[el]).value
          }
        }
      }

      if (!filterQuery['start_date'] || !filterQuery['end_date']) {
        if (Object.keys(defaultUrl).length > 0) {
          Object.keys(defaultUrl).forEach((el: any) => {
            if (el === 'date' && defaultUrl[el].length === 2 && !filterQuery.date) {
              filterQuery['start_date'] = defaultUrl[el][0]
              filterQuery['end_date'] = defaultUrl[el][1]
            }
          })
        }
      }


      return filterQuery
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <DefaultPage
      titlePage='Relátorio de ponto'
      breadcrumb={[{ name: 'Home', url: '/' }, { name: 'Relátorio de ponto' }]}
      urlPage={`/backoffice/point`}

      routeApi={{
        method: 'get',
        route: 'kanban/promoter-points',
        versionApi: 'v2',
        reload,
      }}
      listButtons={{

        buttonExport: {
          visible: true,
          type: 'function',
          function: async () => {
            setLoading(true)
            await exportXls(convertUrlToExportXls(), 'promoter-points')
            setLoading(false)
          },
        },

        buttonFilter: {
          visible: true,
          filters: [
            {
              label: 'Selecione o promotor:',
              name: 'promoters_ids',
              type: 'search',
              urlSearch: 'promoters',
              getEnv: 'value',
            },
            {
              label: 'Selecione um período',
              name: 'date',
              type: 'rangePicker',
            },
          ],
        },

      }}
      load={{ loading, setLoading }}
      columns={[
        {
          title: 'Dia Referência',
          dataIndex: 'dt_visit',
          width: 120,
          render: (value) => {
            let date = ''
            if (value) date = moment(value).format('DD/MM/YYYY')
            return date
          }
        },
        {
          title: 'Colaborador',
          dataIndex: 'name',
          render: (value) => <Tooltip placement="topLeft" title={value}>
            {truncateString(value, 15)}
          </Tooltip>
        },
        {
          title: 'Carga Horária',
          dataIndex: 'carga_horaria',

        },
        {
          title: 'Início da Jornada',
          dataIndex: 'ini_jornada',

        },
        {
          title: 'Término da Jornada',
          dataIndex: 'fim_jornada',
        },
        {
          title: 'Total Laborial',
          dataIndex: 'total_laboral',
        },
        {
          title: 'Intervalo',
          dataIndex: 'intervalo_almoco',
        },
        {
          title: 'Total da Jornada',
          dataIndex: 'total_jornada',
        },
        {
          title: 'Produtividade (%)',
          dataIndex: 'produtividade',
        },
        {
          title: 'Saldo',
          dataIndex: 'saldo',
        },
      ]}
    />
  )
}

export default PointListPage
