import React, { useState } from 'react'
import {
  ContainerQuestion,
  TitleChoise,
  Description,
} from '../style'
import { Radio, Form } from 'antd'
import ViewQuestion from '..'
import ViewPicture from './viewPicture'

const radioStyle = {
  display: 'block',
  height: '60px',
  lineHeight: '30px',
  width: '100%',
  borderRadius: '15px',
  border: '2px solid #7157C1',
  marginBottom: '30px',
  paddingTop: '12px',
  paddingLeft: '10px',
  backgroundColor: 'rgb(113, 87, 193, 0.1)',
}

const UniqueChoiceQuestion: React.FC<any> = ({ quest }) => {
  const [value, setValue] = useState<any>()
  const [valueText, setValueText] = useState<any>()

  const getIndex = (e: any) => {
    let newIndex = undefined
    setValueText(e)
    quest.options.forEach((el: any, index: number) => {
      if (el === e) newIndex = index
    })

    setValue(newIndex)
  }

  return (
    <>
      <ContainerQuestion>
        <TitleChoise>
          <h1>
            {quest.is_required ? '*' : null} {quest.order} →{' '}
          </h1>{' '}
          {quest.name}
        </TitleChoise>
        {quest.description ? <Description>{quest.description}</Description> : null}
        {quest.picture && typeof quest.picture == 'string' &&  <ViewPicture picture={quest.picture} />}
        <Form initialValues={{
          'UNIQUE_CHOICE': valueText
        }}>
          <Form.Item
            name='UNIQUE_CHOICE'
            label='Única escolha'
            rules={[{ required: quest.is_required ? true : false }]}
          >
            <Radio.Group onChange={(e) => getIndex(e.target.value)} style={{ width: '100%' }}>
              {quest.options &&
                quest.options.map((option: any) => (
                  <Radio style={radioStyle} value={option}>
                    {option}
                  </Radio>
                ))}
            </Radio.Group>
          </Form.Item>
        </Form>
      </ContainerQuestion>
      {quest && quest.fields && Object.keys(quest.fields).length > 0 && value > -1 && quest.fields[value] && quest.fields[value].subFields && quest.fields[value].subFields.length > 0 && <ViewQuestion questions={quest.fields[value].subFields} />}
    </>
  )
}

export default UniqueChoiceQuestion
