import { useEffect, useState } from 'react'
import moment from 'moment'
import qs from 'querystring'
import 'react-image-lightbox/style.css'
import { ContainerModal, Header, ContainerDate, TextDate } from './style'
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import api from '../../services/api'
import { Loading } from '../Loading'
import api_v2 from '../../services/api-v2'
import Geocode from 'react-geocode'
import { MapKanbam } from '../MapKanbam'
import { TimeLine } from './TimeLine'
import { MapKanbam2 } from '../MapKanbam2'
interface IProps {
  visible: boolean
  close: (boolean: any) => void
  promoter: StatusTAsks
  tag: any
}

export interface StatusTAsks {
  name: string
  store_complete: number
  store_counts: number
  id: number
  nameTable?: string
}

const ModalKanban = ({ visible, close, promoter, tag }: IProps) => {
  const [data, setData] = useState<any>([])
  const [dataAll, setDataAll] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [store, setStore] = useState<string[]>([])
  const [location, setLocation] = useState<any>([])
  const [total, setTotal] = useState<any>(0)
  const [adress, setAdress] = useState<any>({})
  const [date, setDate] = useState<any>(moment(new Date()).format('YYYY-MM-DD'))
  const [user, setUser] = useState({
    document: '',
    workspace: '',
    id: 0,
    state: ''
  })

  const getUser = async () => {
    setLoading(true)
    try {
      const { data } = await api_v2.get(`/promoter/${promoter.id}`)
      let body: any = {}
      if (data && data.length > 0) body = data[0]

      console.log(body, 'body')
      if (body.cep || body.ms_cep) {
        try {
          let cep = body.cep ? body.cep : body.ms_cep ? body.ms_cep : ''
          let state = body.state ? body.state : body.ms_state ? body.ms_state : ''

          let house = await Geocode.fromAddress(`${cep.replaceAll('-', '')}, ${body.city} - ${state}`)
          if (house.results && house.results[0]?.geometry?.location) setAdress(house.results[0].geometry.location)
        } catch (error) {
          console.log(error)
        }
      }

      let getWorkspace = sessionStorage.getItem('@rocketpdv:workspace')
      let newGetWorkspace = JSON.parse(getWorkspace!.toString())

      setUser({
        document: body.cpf,
        workspace: newGetWorkspace.name,
        id: promoter.id,
        state: body.state
      })
      await loadVisits(promoter.id)

      setLoading(false)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  async function loadVisits(id: number) {
    try {

      const queryFilter = qs.stringify({
        status: ['IN_PROGRESS', 'COMPLETE', 'JUSTIFIED_ABSENCE'],
        check_in_date: [moment(date).format('YYYY-MM-DD').toString(), moment(date).format('YYYY-MM-DD').toString()],
        user: id,
      })

      const { data: in_progress } = await api_v2.get(`visit?promoter_id=${id}&status=IN_PROGRESS&start_date=${moment(date).format('YYYY-MM-DD').toString()}&end_date=${moment(date).format('YYYY-MM-DD').toString()}`)
      const { data: pendent } = await api_v2.get(`visit?promoter_id=${id}&status=PENDENT&start_date=${moment(date).format('YYYY-MM-DD').toString()}&end_date=${moment(date).format('YYYY-MM-DD').toString()}`)
      const { data: complete } = await api_v2.get(`visit?promoter_id=${id}&status=COMPLETE&start_date=${moment(date).format('YYYY-MM-DD').toString()}&end_date=${moment(date).format('YYYY-MM-DD').toString()}`)
      const { data: justified } = await api_v2.get(`visit?promoter_id=${id}&status=JUSTIFIED_ABSENCE&start_date=${moment(date).format('YYYY-MM-DD').toString()}&end_date=${moment(date).format('YYYY-MM-DD').toString()}`)

/*
      const { data } = await api.get(`/visit?${queryFilter}`)
*/
      const { data: visit } = await api_v2.get(
        `/total/visits/${id}?start_date=${moment(date).format('YYYY-MM-DD').toString()}&end_date=${moment(date)
          .format('YYYY-MM-DD')
          .toString()}`
      )

      setTotal(visit.total)
      const location = await api_v2.get(
        `/user/checkLocation?user_id=${promoter.id}&start_date=${moment(date).format('YYYY-MM-DD')}&end_date=${moment(
          date
        ).format('YYYY-MM-DD')}`
      )

      let sort = location.data.sort(function (a: any, b: any) {
        return new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      })
      setLocation(sort)

      getStore([...in_progress.result, ...complete.result, ...justified.result])
      setData([...in_progress.result, ...complete.result, ...justified.result, ...pendent.result])
      setDataAll([...in_progress.result, ...complete.result, ...justified.result])
    } catch (error) {
      console.log(error)
    }
  }

  const getStore = (data: any) => {
    let nameStores: any = []

    data.forEach((el: any) => {
      let exist = false
      nameStores.forEach((task: any) => {
        if (el.pdv.name === task) exist = true
      })

      if (!exist) {
        nameStores.push(el.pdv.name)
      }
    })

    setStore(nameStores)
  }

  useEffect(() => {
    getUser()
  }, [promoter, date]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (tag && tag.ref_date && date == moment(new Date()).format('YYYY-MM-DD')) setDate(moment(tag.ref_date, 'YYYY-MM-DD').format('YYYY-MM-DD'))
  }, [tag])
  return (
    <ContainerModal
      visible={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      bodyStyle={{ height: '92vh' }}
      width={'90vw'}
      style={{ top: '2vh' }}
      onCancel={() => close(!visible)}
      closable={true}
    >
      <>
        <Header>
          <ContainerDate>
            <CaretLeftOutlined onClick={() => setDate(moment(date).subtract(1, 'd'))} />
            <TextDate>{moment(date).format('DD.MM.YYYY')}</TextDate>
            <CaretRightOutlined onClick={() => setDate(moment(date).add(1, 'd'))} />
          </ContainerDate>
        </Header>
        <Row gutter={[40, 40]} style={{ height: '82%', flexWrap: 'wrap' }}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ height: '100%' }}>
            <TimeLine
              data={data}
              dataAll={dataAll}
              date={date}
              promoter={promoter}
              setData={setData}
              store={store}
              user={user}
              total={total}
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            className='overflow-kanban'
            style={{ overflow: 'auto', overflowX: 'scroll' }}
          >
            <MapKanbam data={data} adress={adress} location={location} state={user.state} />
          </Col>
        </Row>
      </>
      {loading && <Loading />}
    </ContainerModal>
  )
}

export default ModalKanban
