import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Page = styled.div`
  flex: 1;
  padding: 50px 12px 40px;

  h1 {
    font-size: 16px;
    font-weight: 600;
    color: #637282;
  }

  h2 {
    font-size: 14px;
    font-weight: 400;
    color: #637282;
  }
`

export const Header = styled.header`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9;
`
export const Content = styled.main`
  display: flex;
  width: 98%;
  margin: 0 auto;
  padding-bottom: 15px;

  @media (max-width: 767px) {
    width: 100%;
    margin: 0;
    justify-content: center;
    align-items: center;
  }
`
