import styled from 'styled-components'

export const Container = styled.div`
  h1,
  p {
    padding: 0px 10px;
  }

  form {
    margin-top: 30px;
    font-weight: 600;
  }

  button {
    text-align: center;
  }

  label {
    padding: 5px 10px;
    font-weight: 600;
  }

  input,
  select {
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    height: 38px;
    padding: 0 15px;
    color: rgba(0, 0, 0, 0.65);
    margin: 0 10px 10px;
    width: 100%;
    display: inline-block;

    &::placeholder {
      color: #d9d9d9;
    }
    &:hover,
    &:focus {
      border-color: #1890ff;
    }
  }
`

export const LabelCheck = styled.div`
  text-align: center;
  font-weight: bold;
  margin: 10px 0;
`

export const InputCheckbox = styled.div`
  label,
  span {
    display: flex;
    margin: 0 auto;
  }
`
