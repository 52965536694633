import React, { useRef, useState, useMemo } from 'react'
import { Select, Spin, SelectProps } from 'antd'
import debounce from 'lodash/debounce'
import api_v2 from '../../../services/api-v2'
import { Container, Label } from './style'

function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }: any) {
  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState([])
  const fetchRef = useRef(0)

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: any) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)
      fetchOptions(value).then((newOptions: any) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return
        }

        setOptions(newOptions)
        setFetching(false)
      })
    }

    return debounce(loadOptions, debounceTimeout)
  }, [fetchOptions, debounceTimeout])

  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching && <Spin size='small' />}
      {...props}
      options={options}
      style={{
        border: '1px solid #d9d9d9',
        borderRadius: 4,
        color: 'rgba(0,0,0,0.65)',
        height: '38px',
      }}
    />
  )
} // Usage of DebounceSelect
async function fetchUserList(value: string, path: string, config?: string) {
  try {
    let { data } = await api_v2.get(`/search/${path}?search=${value}${config ? config : ''}`)
    return data
  } catch (error) {
    console.log(error)
  }
}

interface Props {
  label?: string
  path: string
  isMult?: boolean
  config?: string
  next?: (boolean: any) => void
}
type InputProps = SelectProps<any> & Props
export const SimpleSearchWithoutForm: React.FC<InputProps> = ({ path, isMult, config, next, ...rest }) => {
  return (
    <Container>
      <Label>
        {isMult ? (
          <DebounceSelect
            mode='multiple'
            fetchOptions={(text: string) => fetchUserList(text, path, config)}
            style={{
              width: '100%',
              border: '1px solid red',
              borderRadius: 4,
              color: 'rgba(0,0,0,0.65)',
              height: '38px',
            }}
            {...rest}
          />
        ) : (
          <DebounceSelect
            showSearch
            fetchOptions={(text: string) => fetchUserList(text, path, config)}
            style={{
              width: '100%',
            }}
            {...rest}
          />
        )}
      </Label>
    </Container>
  )
}
