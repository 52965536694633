import React from 'react'
import {
  ContainerQuestion,
  TitleText,
  Description,
} from '../style'
import { Input, Form } from 'antd'
import ViewPicture from './viewPicture'

const TextQuestion: React.FC<any> = ({ quest }) => {

  return (
    <>

      <ContainerQuestion>
        <TitleText>
          <h1>
            {quest.is_required ? '*' : null} {quest.order} →{' '}
          </h1>{' '}
          {quest.name}
        </TitleText>

        {quest.description ? <Description>{quest.description}</Description> : null}
        {quest.picture && typeof quest.picture == 'string' &&  <ViewPicture picture={quest.picture} />}
        <Form.Item name='TEXT' label='Texto' rules={[{ required: quest.is_required ? true : false }]}>
          <Input.TextArea style={{ width: '100%' }} />
        </Form.Item>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '20px',
          }}
        >
        </div>
      </ContainerQuestion>
    </>
  )
}

export default TextQuestion
