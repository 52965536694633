import { Button, message, Steps } from 'antd'
import { FormHandles } from '@unform/core'
import React, { useState, useRef, useEffect } from 'react'
import LayoutWorkspace from '../../../components/LayoutWorkspace'
import { Loading } from '../../../components/Loading'
import { Container, Wrapper } from './styles'
import { ArrowLeftOutlined } from '@ant-design/icons'
import * as Yup from 'yup'
import history from '../../../routes/history'
import Step1, { DataStep1 } from './steps/step1'
import Step2, { DataStep2 } from './steps/step2'
import Step3, { ISubWorkspace } from './steps/step3'
import api from '../../../services/api'
import firebase from '../../../utils/firebase'
import { Link, useParams } from 'react-router-dom'
import { getToken } from '../../../utils/login'
import axios from 'axios'
import api_v2 from '../../../services/api-v2'

export interface IDefault extends DataStep1, DataStep2 {}

const NewWorkspace: React.FC = () => {
  const { id }: any = useParams()

  const formRef = useRef<FormHandles>(null)
  const [loading, setLoading] = useState<any>(false)
  const [current, setCurrent] = useState<number>(0)
  const [defaultVal, setDefaultVal] = useState<IDefault>({} as IDefault)
  const [dataStep1, setDataStep1] = useState<DataStep1>({} as DataStep1)
  const [dataStep2, setDataStep2] = useState<DataStep2>({
    msgFinishForm: 'Você tem certeza que deseja finalizar está pesquisa?',
    msgRupturaForm: 'Confirma ruptura em todos produtos abaixo?',
    msgAddVisit: 'Você tem certeza que deseja adicionar uma visita á {nome_loja}',
  } as DataStep2)
  const [dataStep3, setDataSTep3] = useState<ISubWorkspace[]>([])
  const noShared = [
    {
      title: 'Workspace',
      content: (
        <Step1
          defaultVal={defaultVal}
          setStep1={(data: DataStep1) => {
            console.log(data)
            setDataStep1(data)
          }}
        />
      ),
    },
    {
      title: 'Menssagens padronizadas',
      content: <Step2 setData2={(data: DataStep2) => setDataStep2(data)} defaultVal={defaultVal} />,
    },
  ]

  const shared = [
    {
      title: 'Workspace',
      content: (
        <Step1
          defaultVal={defaultVal}
          setStep1={(data: DataStep1) => {
            console.log(data)
            setDataStep1(data)
          }}
        />
      ),
    },
    {
      title: 'Menssagens padronizadas',
      content: <Step2 setData2={(data: DataStep2) => setDataStep2(data)} defaultVal={defaultVal}/>,
    },
    {
      title: 'SubWorksapces',
      content: <Step3 setData3={(data: ISubWorkspace[]) => setDataSTep3(data)} />,
    },
  ]
  // const [steps, setSteps] = useState<any>([
  //   {
  //     title: 'Workspace',
  //     content: (
  //       <Step1
  //         defaultVal={defaultVal}
  //         setStep1={(data: DataStep1) => {
  //           console.log(data)
  //           setDataStep1(data)
  //         }}
  //       />
  //     ),
  //   },
  //   {
  //     title: 'Menssagens padronizadas',
  //     content: <Step2 setData2={(data: DataStep2) => setDataStep2(data)} />,
  //   },
  // ])

  const getWorkspace = async () => {
    try {
      const { data } = await api_v2.get(`workspace/${id}`)
      console.log(data.result.name)
      setDefaultVal({
        nameWorkspace: data.result.name,
        image: data.result.avatar_url,
        checked: {
          required_check_in: data.result.required_check_in,
          required_check_out: data.result.required_check_out,
          required_check_auto: data.result.required_check_auto,
          required_all_rupture: data.result.required_all_rupture,
        },
        typeColect: data.result.type_colect,
        type_view_form: data.result.type_view_form,
        shared_environment: data.result.shared_environment,
        msgFinishForm: data.result.msg_finish_form,
        msgRupturaForm: data.result.msg_ruptura,
        msgAddVisit: data.result.msg_add_visit,
      })
    } catch (error) {}
  }

  useEffect(() => {
    if (id) {
      getWorkspace()
    }
  }, [id])

  const uploadImg = async (uri: any) => {
    let originalName = uri.name.split('.')[0] + '.' + uri.name.split('.')[1].toLowerCase()
    let paste = 'workspace-img'
    let file = firebase.storage().ref(`${paste}/${originalName}`)

    await file.put(uri)
    const url = await file.getDownloadURL()

    return url
  }

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const { nameWorkspace, image, checked, typeColect, type_view_form, shared_environment } = dataStep1
      const { msgFinishForm, msgRupturaForm, msgAddVisit } = dataStep2
      let listSubWorks: any = []

      if (dataStep3.length > 0) {
        for await (let el of dataStep3) {
          listSubWorks.push({
            name: el.name,
            avatar_url: await uploadImg(el.photo.raw),
          })
        }
      }

      let urlAvatar = ''
      if(image.preview && !image.raw) {
        urlAvatar = image.preview
      } else if (image.raw) {
        urlAvatar = await uploadImg(image.raw)
      }

  

      if(id) {

        const { data } = await api_v2.put(`/workspace/${id}`, {
          name: nameWorkspace,
          ...checked,
          type_view_form,
          type_colect: typeColect,
          msg_add_visit: msgAddVisit,
          msg_ruptura: msgRupturaForm,
          msg_finish_form: msgFinishForm,
          avatar_url: urlAvatar,
          subWorksapces: listSubWorks,
          shared_environment,
        }, {
          headers: {
            workspace: 'rocket-pdv',
            workspaceId: 3
          }
        })
        
      } else {
        const { data } = await api.post(`/workspace`, {
          name: nameWorkspace,
          ...checked,
          type_view_form,
          type_colect: typeColect,
          msg_add_visit: msgAddVisit,
          msg_ruptura: msgRupturaForm,
          msg_finish_form: msgFinishForm,
          avatar_url: urlAvatar,
          subWorksapces: listSubWorks,
          shared_environment,
        })
        const api_v2 = axios.create({
          baseURL: process.env.REACT_APP_API_V2_URL,
          // baseURL: 'http://localhost:9000/',
          headers: {
            'Content-Type': 'application/json',
          },
        })

        api_v2.interceptors.request.use((config: any) => {
          const token = getToken()
          const headers = { ...config.headers }
          if (token) headers.Authorization = `Bearer ${token}`
          headers.workspace = data.newWorkspace.slug
          headers.workspaceId = data.newWorkspace.id

          return { ...config, headers }
        })

        await api_v2.post('/profile', {
          name: 'PROMOTOR',
          description: 'PROMOTOR',
          context: 'PROMOTER',
          is_active: true,
          profile_permissions: [
            'home.add_visit',
            'home.launch',
            'home.training',
            'visits.reset_visit',
            'check_in.required_geolocation',
            'check_in.required_picture',
            'survey.justify_after_check_in',
            'survey.reset_survey',
            'check_out.required_geolocation',
            'check_out.required_picture',
          ],
        })

        await api_v2.post('/profile', {
          name: 'GESTOR',
          description: 'GESTOR',
          context: 'GESTOR',
          is_active: true,
          profile_permissions: [
            'home.filter',
            'home.add_metric',
            'home.attendance',
            'home.assiduity',
            'home.tasks',
            'home.team_on_off',
            'team.promoter',
            'team.add_promoter',
            'team.import_promoter',
            'team.import_visit_promoter',
            'team.import_script_promoter',
            'team.export_promoter',
            'team.export_script_promoter',
            'team.change_visit_status',
            'team.change_visit_date',
            'team.remove_visit_date',
            'team.add_task',
            'team.remove_task',
            'team.reset_task',
            'group.profile',
            'group.add_profile',
            'group.change_profile',
            'time_compare.view',
            'visits_panel.view',
            'justify.view',
            'attendance.view',
            'time_and_move.view',
            'dash.list_dash',
            'dash.invite_user',
            'process.list_process_import',
            'process.download_process_import',
            'plan_exec.list_plan_exec',
            'kanban.list_kanban',
            'before_afer.list_before_afer',
            'pictures.list_pictures',
            'pictures.export_pictures_ppt',
            'justify_report.list_justify_report',
            'justify_report.homolog_justify_report',
            'survey.list_survey',
            'visit.list_visit',
            'visit.add_visit_survey',
            'visit.export_visit',
            'task.list_task',
            'task.export_task',
            'form.list_form',
            'form.add_form',
            'form.change_form',
            'form.toggle_form',
            'form.add_schedule_form',
            'form.change_schedule_form',
            'form.remove_form_mix',
            'form.import_form_mix',
            'form.export_form_mix',
            'training.list_training',
            'training.add_training',
            'training.change_training',
            'training.remove_training',
            'justify.list_justify',
            'justify.add_justify',
            'justify.change_justify',
            'justify.remove_justify',
            'store.pdv',
            'store.add_pdv',
            'store.change_pdv',
            'store.remove_pdv',
            'store.import_pdv',
            'store.export_pdv',
            'store.flag',
            'store.add_flag',
            'store.change_flag',
            'store.remove_flag',
            'store.import_flag',
            'store.export_flag',
            'store.network',
            'store.add_network',
            'store.change_network',
            'store.remove_network',
            'store.import_network',
            'store.export_network',
            'store.regional',
            'store.add_regional',
            'store.change_regional',
            'store.remove_regional',
            'store.import_regional',
            'store.export_regional',
            'catalog.product_mix',
            'catalog.add_product_mix',
            'catalog.change_product_mix',
            'catalog.remove_product_mix',
            'catalog.import_product_mix',
            'catalog.export_product_mix',
            'catalog.product',
            'catalog.add_product',
            'catalog.change_product',
            'catalog.remove_product',
            'catalog.import_product',
            'catalog.export_product',
            'catalog.category',
            'catalog.add_category',
            'catalog.change_category',
            'catalog.remove_category',
            'catalog.brand',
            'catalog.add_brand',
            'catalog.change_brand',
            'catalog.remove_brand',
            'catalog.import_brand',
            'catalog.export_brand',
          ],
        })
      }


      
      message.success(`Workspace ${id ? 'atualizado' : 'cadastrado'} com sucesso`)
      sessionStorage.removeItem('@rocketpdv:workspace')
      history.push('/backoffice/workspace')
    } catch (err) {
      console.log(err)
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((el: any) => {
          formRef.current!.setFieldError(el.path, el.message)
        })
      } else {
        message.error('Já existe um workspace com essas informações!')
      }
    }
    setLoading(false)
  }

  // useEffect(() => {
  //   if (dataStep1.shared_environment) {
  //     let has = steps.filter((el: any) => el.title === 'SubWorksapces')
  //     if (has.length > 0) return
  //     let step3 = {
  //       title: 'SubWorksapces',
  //       content: <Step3 setData3={(data: ISubWorkspace[]) => setDataSTep3(data)} />,
  //     }
  //     setSteps([...steps, step3])
  //   } else {
  //     let newSteps = steps.filter((el: any) => el.title !== 'SubWorksapces')
  //     setSteps(newSteps)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dataStep1])

  const next = () => {
    if (current === 0) {
      if (!dataStep1.nameWorkspace) return message.error('Nome do workspace é obrigatório')
    }
    if (current === 1) {
      if (!dataStep2.msgRupturaForm) return message.error('Todos os campos de mensagens são obrigatórios')
      if (!dataStep2.msgAddVisit) return message.error('Todos os campos de mensagens são obrigatórios')
      if (!dataStep2.msgFinishForm) return message.error('Todos os campos de mensagens são obrigatórios')
    }
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  return (
    <LayoutWorkspace>
      {loading ? <Loading /> : null}
      <Container>
        <Wrapper style={{ display: 'flex', justifyContent: 'start' }}>
          <Link to='/backoffice/workspace'>
            <ArrowLeftOutlined style={{ fontSize: 25, cursor: 'pointer', marginRight: 30, color: '#7158c1' }} />
          </Link>
          <h1>{id ? 'Editar' : 'Cadastrar'} workspace</h1>
        </Wrapper>
        {dataStep1.shared_environment ? (
          <>
            <Steps current={current}>
              {shared.map((item: any) => (
                <Steps.Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <div className='steps-content'>{shared[current].content}</div>
            <div className='steps-action'>
              {current < shared.length - 1 && (
                <Button type='primary' onClick={() => next()}>
                  Continuar
                </Button>
              )}
              {current === shared.length - 1 && (
                <Button type='primary' onClick={() => handleSubmit()}>
                  Cadastrar
                </Button>
              )}
              {current > 0 && (
                <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                  Voltar
                </Button>
              )}
            </div>
          </>
        ) : (
          <>
            <Steps current={current}>
              {noShared.map((item: any) => (
                <Steps.Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <div className='steps-content'>{noShared[current].content}</div>
            <div className='steps-action'>
              {current < noShared.length - 1 && (
                <Button type='primary' onClick={() => next()}>
                  Continuar
                </Button>
              )}
              {current === noShared.length - 1 && (
                <Button type='primary' onClick={() => handleSubmit()}>
                  Cadastrar
                </Button>
              )}
              {current > 0 && (
                <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                  Voltar
                </Button>
              )}
            </div>
          </>
        )}
      </Container>
    </LayoutWorkspace>
  )
}

export default NewWorkspace
