import React, { useEffect, useRef, useState } from 'react'
import { ButtonSearch, LabelForm } from '../../../pages/Team/Promoter/style'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import { Search } from '../../form/SimpleSearch'
import { FileSearchOutlined } from '@ant-design/icons'
import { message } from 'antd'
import * as Yup from 'yup'

interface IProps {
  next: (stage: number) => void
  value: (body: any) => void
}

interface User {
  avatar_url: string
  check_photo: boolean
  created_at: string
  id: number
  manage_shelf: boolean
  msg_add_visit: string
  msg_finish_form: string
  msg_ruptura: string
  multiple_ean: boolean
  name: string
  provider: string
  required_all_rupture: boolean
  required_check_auto: boolean
  required_check_in: boolean
  required_check_out: boolean
  shared_environment: boolean
  slug: string
  type_colect: string
  type_view_form: string
  updated_at: string
}

export const Step1 = ({ next, value }: IProps) => {
  const [user, setUser] = useState<User>({} as User)
  // const [select1, setSelect1] = useState<boolean>(true)
  // const [select2, setSelect2] = useState<boolean>(false)
  // const [select3, setSelect3] = useState<boolean>(false)
  // const [select4, setSelect4] = useState<boolean>(false)
  const formRef = useRef<FormHandles>(null)

  async function searchIn(body: any) {
    try {
      if (body.pdv_id) body.pdv_id = body.pdv_id.value
      if (body.promoter_id) body.promoter_id = body.promoter_id.value
      if (body.form_id) body.form_id = body.form_id.value
      if (body.sub_workspace_id) body.sub_workspace_id = body.sub_workspace_id.value

      const schema = Yup.object().shape({
        pdv_id: Yup.number().required('* Selecione um PDV'),
        promoter_id: Yup.number().required('* Selecione um promotor'),
        form_id: Yup.number().required('* Selecione um formulário'),
        sub_workspace_id:
          user && user.shared_environment ? Yup.number().required('* Selecione um sub workspace') : Yup.number(),
      })

      await schema.validate(body, {
        abortEarly: false,
      })
      value(body)
      next(1)
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((el: any) => {
          formRef.current!.setFieldError(el.path, el.message)
        })
      } else {
        message.error('Já existe um registro com essas informações!')
      }
    }
  }

  useEffect(() => {
    let getWorkspace: any = sessionStorage.getItem('@rocketpdv:workspace')
    setUser(JSON.parse(getWorkspace))
  }, [])

  // useEffect(() => {
  //   console.log('select1', select1)
  //   console.log('select2', select2)
  //   console.log('select3', select3)
  // }, [select1, select2, select3])
  return (
    <Form ref={formRef} className='form' style={{ marginTop: '15px', height: '100%' }} onSubmit={searchIn}>
      <div style={{ margin: '20px 0' }}>
        <LabelForm>Selecione a loja*</LabelForm>
        <Search
          placeholder='Selecione o PDV'
          name='pdv_id'
          path='pdvs'
          isMult={false}
          // next={(data: any) => {
          //   setSelect1(false)
          //   setTimeout(() => {
          //     setSelect2(true)
          //   }, 300)
          // }}
        />
      </div>
      <div style={{ margin: '20px 0' }}>
        <LabelForm>Selecione o promotor*</LabelForm>
        <Search
          placeholder='Selecione o promotor'
          name='promoter_id'
          path='promoters'
          isMult={false}
          // autoFocus={select2}
        />
      </div>
      <div style={{ margin: '20px 0' }}>
        <LabelForm>Selecione o formulário*</LabelForm>
        <Search placeholder='Selecione o formulário' name='form_id' path='forms' isMult={false} />
      </div>
      {user && user.shared_environment && (
        <div style={{ margin: '20px 0' }}>
          <LabelForm>Selecione o subworkspace*</LabelForm>
          <Search
            placeholder='Selecione o subworkspace'
            name='sub_workspace_id'
            path='sub-workspaces'
            isMult={false}
            // autoFocus={select4}
          />
        </div>
      )}

      <ButtonSearch type='submit' style={{ width: 200 }}>
        <FileSearchOutlined />
        Avançar
      </ButtonSearch>
    </Form>
  )
}
