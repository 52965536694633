import { Col, Row, message, Upload, Button } from 'antd'
import React, { useState } from 'react'
import { Body, ContainerLogo, ContainerModal } from './style'
import { BulbOutlined, UploadOutlined } from '@ant-design/icons'
import { BigButton } from '../../GlobalStyles'
import firebase from '../../utils/firebase'
import api_v2 from '../../services/api-v2'
import { Loading } from '../Loading'

interface IProps {
  visible: boolean
  close: (boolean: any) => void
}

export const ModalEditWorkspace = ({ visible, close }: IProps) => {
  const [pictures, setPictures] = useState<any>([])
  const [urls, setUrls] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)
  const editWorkspace = async () => {
    try {
      if (urls.length === 0) {
        message.error('Insira um template para continuar.')
        return
      }
      setLoading(true)
      await api_v2.put(`workspace/template-ppt`, { template_ppt: urls[0] })
      message.success('Sucesso ao atualizar workspace.')
      setLoading(false)
    } catch (error) {
      message.error('Erro ao atualizar workspace.')
      setLoading(false)
    }
  }

  const handleChange = ({ file, fileList }: any) => {
    setPictures(fileList)
    const team: any = sessionStorage.getItem('@rocketpdv:workspace')
    uploadImage({
      slug: JSON.parse(team).slug,
      fileList: file,
      all: fileList,
    })
  }

  const uploadImage = async (urls: any) => {
    try {
      let file = firebase.storage().ref(`${urls.slug}/template-ppt/${urls.slug}.pptx`)
      console.log(urls)
      await file.put(urls.fileList.originFileObj, {
        contentType: 'application/vnd.ms-powerpoint',
      })
      const url = await file.getDownloadURL()
      let newPictures: any = []

      urls.all.forEach((el: any) => {
        if (el.uid === urls.fileList.uid) {
          newPictures.push({ ...urls.fileList, status: 'done', url: url })
        } else {
          newPictures.push({ ...el, status: 'done' })
        }
      })

      let listUrl = newPictures.map((el: any) => el.url)
      setPictures(newPictures)
      setUrls(listUrl)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <ContainerModal
      visible={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
    >
      {loading && <Loading />}
      <Body>
        <ContainerLogo>
          <BulbOutlined />
        </ContainerLogo>
        <h2>Editar workspace</h2>
        <h3>Edite seu workspace.</h3>
        <Row style={{ width: '100%', marginTop: 15 }} gutter={[20, 20]} justify='center'>
          <Col span={24}>
            <p style={{ color: '#6f7783', marginBottom: 5 }}>Template de PPT:</p>
            <Upload
              action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
              listType='picture'
              maxCount={1}
              fileList={pictures}
              onChange={handleChange}
              style={{ margin: 'auto' }}
            >
              <Button icon={<UploadOutlined />}>Upload (Max: 1)</Button>
            </Upload>
          </Col>
          <Col span={24}>
            <BigButton
              onClick={() => editWorkspace()}
              style={{
                width: '100%',
                backgroundColor: '#6d55cc',
                color: '#fff',
                height: 50,
                marginTop: 20,
                fontSize: 15,
                fontWeight: 'bold',
              }}
            >
              Enviar
            </BigButton>
          </Col>
        </Row>
      </Body>
    </ContainerModal>
  )
}
