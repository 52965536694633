import { Col, Drawer, Row, Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import { DivGroupTextIcon } from '../../GlobalStyles'
import { ContainerCard } from './style'
import { BsFillBarChartLineFill } from 'react-icons/bs'
import { FaChartPie } from 'react-icons/fa'
import { BiLineChart } from 'react-icons/bi'
import { AiOutlineClose } from 'react-icons/ai'
import { ContainerExpandGraph, ContainerIconExpand, ContainerTitle, GraphContainerExpan } from '../GraphDash/style'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

interface IProps {
  visible: boolean
  close: (boolean: any) => void
  title: string
  listData: any
  setListData: any
}

const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
]

const chart = [
  { name: 'Pendente', value: 30 },
  { name: 'Completa', value: 40 },
  { name: 'Em Andamento', value: 10 },
  { name: 'Justificada', value: 5 },
]

export const DrawerGraph = ({ visible, close, title, listData, setListData }: IProps) => {
  const { width, height } = getWindowDimensions()
  const [contentTitle, setContentTitle] = useState<string[]>([])
  const [details, setDetails] = useState<any>({
    visible: false,
    type: '',
    title: '',
    data: [],
  })

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042']
  const RADIAN = Math.PI / 180

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return percent * 100 > 0 ? (
      <text x={x} y={y} fill='white' textAnchor={x > cx ? 'start' : 'end'} dominantBaseline='central'>
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    ) : (
      <></>
    )
  }

  const onClose = () => {
    close(false)
  }

  const editList = (insert: boolean, name: string, type: string) => {
    if (insert) {
      let newDataInsert: any = {
        title: name,
        type: type,
        data: type === 'PIE' ? chart : data,
        keysName: type === 'PIE' ? ['Pendente', 'Completa', 'Em Andamento', 'Justificada'] : ['uv', 'pv', 'amt'],
        size: 6,
      }
      setListData([...listData, newDataInsert])
    } else {
      let filter = listData.filter((el: any) => el.title !== name)
      setListData(filter)
    }
  }

  useEffect(() => {
    let titles = listData.map((el: any) => el.title)
    setContentTitle(titles)
  }, [listData])

  return (
    <Drawer
      title={
        <DivGroupTextIcon>
          <p style={{ fontWeight: 600, letterSpacing: 2 }}>{title}</p>
        </DivGroupTextIcon>
      }
      placement='right'
      closable={true}
      onClose={onClose}
      visible={visible}
      key='right'
      destroyOnClose={true}
      width={width * 0.6}
    >
      <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Row gutter={[30, 30]} justify='space-around'>
          <Col span={6}>
            <ContainerCard>
              <div className='containerFirst'>
                <div
                  className='containerIcon'
                  onClick={() => setDetails({ visible: true, title: 'Alertas do dia', type: 'BAR', data })}
                >
                  <BsFillBarChartLineFill color='#98a2ac' size={45} />
                  <p>Visualizar</p>
                </div>
                <p>Alertas do dia</p>
              </div>
              <div className='containerSecond'>
                <Switch
                  defaultChecked={contentTitle.indexOf('Alertas do dia') > -1 ? true : false}
                  onChange={(value: any) => editList(value, 'Alertas do dia', 'BAR')}
                  style={{ width: 30, height: 20 }}
                />
                <p>Visível</p>
              </div>
            </ContainerCard>
          </Col>
          <Col span={6}>
            <ContainerCard>
              <div className='containerFirst'>
                <div
                  className='containerIcon'
                  onClick={() =>
                    setDetails({ visible: true, title: 'Justificativa de visita', type: 'PIE', data: chart })
                  }
                >
                  <FaChartPie color='#98a2ac' size={45} />
                  <p>Visualizar</p>
                </div>
                <p>Justificativa de visita</p>
              </div>
              <div className='containerSecond'>
                <Switch
                  defaultChecked={contentTitle.indexOf('Justificativa de visita') > -1 ? true : false}
                  onChange={(value: any) => editList(value, 'Justificativa de visita', 'PIE')}
                  style={{ width: 30, height: 20 }}
                />
                <p>Visível</p>
              </div>
            </ContainerCard>
          </Col>
          <Col span={6}>
            <ContainerCard>
              <div className='containerFirst'>
                <div
                  className='containerIcon'
                  onClick={() => setDetails({ visible: true, title: 'Hitórico de fotos', type: 'LINEAR', data })}
                >
                  <BiLineChart color='#98a2ac' size={45} />
                  <p>Visualizar</p>
                </div>
                <p>Hitórico de fotos</p>
              </div>
              <div className='containerSecond'>
                <Switch
                  defaultChecked={contentTitle.indexOf('Hitórico de fotos') > -1 ? true : false}
                  onChange={(value: any) => editList(value, 'Hitórico de fotos', 'LINEAR')}
                  style={{ width: 30, height: 20 }}
                />
                <p>Visível</p>
              </div>
            </ContainerCard>
          </Col>
          <Col span={6}>
            <ContainerCard>
              <div className='containerFirst'>
                <div
                  className='containerIcon'
                  onClick={() => setDetails({ visible: true, title: 'Tempo trabalhado', type: 'PIE', data: chart })}
                >
                  <FaChartPie color='#98a2ac' size={45} />
                  <p>Visualizar</p>
                </div>
                <p>Tempo trabalhado</p>
              </div>
              <div className='containerSecond'>
                <Switch
                  defaultChecked={contentTitle.indexOf('Tempo trabalhado') > -1 ? true : false}
                  onChange={(value: any) => editList(value, 'Tempo trabalhado', 'PIE')}
                  style={{ width: 30, height: 20 }}
                />
                <p>Visível</p>
              </div>
            </ContainerCard>
          </Col>
          <Col span={6}>
            <ContainerCard>
              <div className='containerFirst'>
                <div
                  className='containerIcon'
                  onClick={() => setDetails({ visible: true, title: 'Minutos em PDV', type: 'BAR', data })}
                >
                  <BsFillBarChartLineFill color='#98a2ac' size={45} />
                  <p>Visualizar</p>
                </div>
                <p>Minutos em PDV</p>
              </div>
              <div className='containerSecond'>
                <Switch
                  defaultChecked={contentTitle.indexOf('Minutos em PDV') > -1 ? true : false}
                  onChange={(value: any) => editList(value, 'Minutos em PDV', 'BAR')}
                  style={{ width: 30, height: 20 }}
                />
                <p>Visível</p>
              </div>
            </ContainerCard>
          </Col>
          <Col span={6}>
            <ContainerCard>
              <div className='containerFirst'>
                <div
                  className='containerIcon'
                  onClick={() => setDetails({ visible: true, title: 'Faltas', type: 'PIE', data: chart })}
                >
                  <FaChartPie color='#98a2ac' size={45} />
                  <p>Visualizar</p>
                </div>
                <p>Faltas</p>
              </div>
              <div className='containerSecond'>
                <Switch
                  defaultChecked={contentTitle.indexOf('Faltas') > -1 ? true : false}
                  onChange={(value: any) => editList(value, 'Faltas', 'PIE')}
                  style={{ width: 30, height: 20 }}
                />
                <p>Visível</p>
              </div>
            </ContainerCard>
          </Col>
          <Col span={6}>
            <ContainerCard>
              <div className='containerFirst'>
                <div
                  className='containerIcon'
                  onClick={() => setDetails({ visible: true, title: 'Cumprimento de visitas', type: 'LINEAR', data })}
                >
                  <BiLineChart color='#98a2ac' size={45} />
                  <p>Visualizar</p>
                </div>
                <p>Cumprimento de visitas</p>
              </div>
              <div className='containerSecond'>
                <Switch
                  defaultChecked={contentTitle.indexOf('Cumprimento de visitas') > -1 ? true : false}
                  onChange={(value: any) => editList(value, 'Cumprimento de visitas', 'LINEAR')}
                  style={{ width: 30, height: 20 }}
                />
                <p>Visível</p>
              </div>
            </ContainerCard>
          </Col>
          <Col span={6}>
            <ContainerCard>
              <div className='containerFirst'>
                <div
                  className='containerIcon'
                  onClick={() => setDetails({ visible: true, title: 'Promotores online', type: 'BAR', data })}
                >
                  <BsFillBarChartLineFill color='#98a2ac' size={45} />
                  <p>Visualizar</p>
                </div>
                <p>Promotores online</p>
              </div>
              <div className='containerSecond'>
                <Switch
                  defaultChecked={contentTitle.indexOf('Promotores online') > -1 ? true : false}
                  onChange={(value: any) => editList(value, 'Promotores online', 'BAR')}
                  style={{ width: 30, height: 20 }}
                />
                <p>Visível</p>
              </div>
            </ContainerCard>
          </Col>
          <Col span={6}>
            <ContainerCard>
              <div className='containerFirst'>
                <div
                  className='containerIcon'
                  onClick={() => setDetails({ visible: true, title: 'Minutos em PDV', type: 'BAR', data })}
                >
                  <BsFillBarChartLineFill color='#98a2ac' size={45} />
                  <p>Visualizar</p>
                </div>
                <p>Minutos em PDV</p>
              </div>
              <div className='containerSecond'>
                <Switch
                  defaultChecked={contentTitle.indexOf('Minutos em PDV') > -1 ? true : false}
                  onChange={(value: any) => editList(value, 'Minutos em PDV', 'BAR')}
                  style={{ width: 30, height: 20 }}
                />
                <p>Visível</p>
              </div>
            </ContainerCard>
          </Col>
          <Col span={6}>
            <ContainerCard>
              <div className='containerFirst'>
                <div
                  className='containerIcon'
                  onClick={() => setDetails({ visible: true, title: 'Faltas', type: 'PIE', data: chart })}
                >
                  <FaChartPie color='#98a2ac' size={45} />
                  <p>Visualizar</p>
                </div>
                <p>Faltas</p>
              </div>
              <div className='containerSecond'>
                <Switch
                  defaultChecked={contentTitle.indexOf('Faltas') > -1 ? true : false}
                  onChange={(value: any) => editList(value, 'Faltas', 'PIE')}
                  style={{ width: 30, height: 20 }}
                />
                <p>Visível</p>
              </div>
            </ContainerCard>
          </Col>
          <Col span={6}>
            <ContainerCard>
              <div className='containerFirst'>
                <div
                  className='containerIcon'
                  onClick={() => setDetails({ visible: true, title: 'Cumprimento de visitas', type: 'LINEAR', data })}
                >
                  <BiLineChart color='#98a2ac' size={45} />
                  <p>Visualizar</p>
                </div>
                <p>Cumprimento de visitas</p>
              </div>
              <div className='containerSecond'>
                <Switch
                  defaultChecked={contentTitle.indexOf('Cumprimento de visitas') > -1 ? true : false}
                  onChange={(value: any) => editList(value, 'Cumprimento de visitas', 'LINEAR')}
                  style={{ width: 30, height: 20 }}
                />
                <p>Visível</p>
              </div>
            </ContainerCard>
          </Col>
          <Col span={6}>
            <ContainerCard>
              <div className='containerFirst'>
                <div
                  className='containerIcon'
                  onClick={() => setDetails({ visible: true, title: 'Promotores online', type: 'BAR', data })}
                >
                  <BsFillBarChartLineFill color='#98a2ac' size={45} />
                  <p>Visualizar</p>
                </div>
                <p>Promotores online</p>
              </div>
              <div className='containerSecond'>
                <Switch
                  defaultChecked={contentTitle.indexOf('Promotores online') > -1 ? true : false}
                  onChange={(value: any) => editList(value, 'Promotores online', 'BAR')}
                  style={{ width: 30, height: 20 }}
                />
                <p>Visível</p>
              </div>
            </ContainerCard>
          </Col>
        </Row>
      </div>
      {details.visible && (
        <ContainerExpandGraph>
          <ContainerTitle>
            <p>{details.title}</p>
          </ContainerTitle>
          <ContainerIconExpand>
            <AiOutlineClose color='#FF5959' onClick={() => setDetails({ ...details, visible: false })} />
          </ContainerIconExpand>
          <GraphContainerExpan>
            {details.type === 'LINEAR' && (
              <div style={{ width: '100%', height: height * 0.7 }}>
                <ResponsiveContainer>
                  <LineChart data={details.data}>
                    <CartesianGrid strokeDasharray='10 10' />
                    <Tooltip />
                    <Legend />
                    <Line type='monotone' dataKey='pv' stroke='#6600cc' activeDot={{ r: 8 }} strokeWidth={4} />
                    <Line type='monotone' dataKey='uv' stroke='#00ccf2' activeDot={{ r: 8 }} strokeWidth={4} />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            )}
            {details.type === 'PIE' && (
              <div style={{ width: '100%', height: height * 0.7 }}>
                <ResponsiveContainer>
                  <PieChart>
                    <Pie
                      data={details.data}
                      cx='50%'
                      cy='50%'
                      labelLine={false}
                      label={renderCustomizedLabel}
                      outerRadius={80}
                      fill='#8884d8'
                      dataKey='value'
                    >
                      {details.data.map((entry: any, index: number) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </div>
            )}
            {details.type === 'BAR' && (
              <div style={{ width: '100%', height: height * 0.7 }}>
                <ResponsiveContainer>
                  <BarChart data={details.data}>
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis dataKey='name' />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey='uv' fill={COLORS[0]} />
                    <Bar dataKey='pv' fill={COLORS[1]} />
                    <Bar dataKey='amt' fill={COLORS[2]} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            )}
          </GraphContainerExpan>
        </ContainerExpandGraph>
      )}
    </Drawer>
  )
}
