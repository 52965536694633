import React, { useState, useEffect, useRef } from 'react'
import { Button, ContainerModal, ContainerStep2, LabelCheck } from './style'
import AddUser from '../../assets/images/addUser.png'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { Modal, message } from 'antd'
import { Loading } from '../Loading'
import api_v2 from '../../services/api-v2'
import { Mixpanel } from '../../utils/mixpanel'
import SimpleRangePicker from '../form/SimpleRangePicker'
import { GroupForm } from '../../GlobalStyles'
import moment from 'moment'
import { Search } from '../form/SimpleSearch'
import * as Yup from 'yup'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { getDataUser } from '../../utils/login'

interface IProps {
  visible: boolean
  close: (boolean: any) => void
  promoter: any
  reloadApi?: (boolean: any) => void
}

export const ModalPutAway = ({ visible, close, promoter, reloadApi }: IProps) => {
  const formRef = useRef<FormHandles>(null)
  const [loading, setLoading] = useState<boolean>(false)

  async function handleSubmit(body: any) {
    let dataBody = {
      ...body,
      removal_by:  getDataUser().id
    }
    if (dataBody.removal) dataBody.removal = dataBody.removal.value
    if (!dataBody.range) {
      return message.error('Erro por favor, preencha o período!')
    }
    setLoading(true)
    try {
      const schema = Yup.object().shape({
        removal: Yup.number().required('* Motivo é obrigatório'),
      })
      await schema.validate(dataBody, {
        abortEarly: false,
      })
      
      const { data } = await api_v2.post(`user/exist-visit/${promoter.id}`, dataBody)

      if(data.visits && data.visits.length > 0) {
        Modal.confirm({
          title: 'Atenção',
          icon: <ExclamationCircleOutlined />,
          content: `Esse promotor tem ${data.visits.length} visitas agendadas, ao confirmar você irá apagar essas visitas.`,
          async onOk() {
            await api_v2.post(`user/removal/${promoter.id}`, dataBody)

            Mixpanel.track('Afastou promotor e confirmou apagar visitas', {dataBody, promoter: promoter.id})
      
            if (reloadApi) {
              reloadApi(true)
            }
            message.success('Promotor afastado com sucesso')
            close(false)
            setTimeout(() => {
              window.location.reload()
            }, 1500);
          },
          onCancel() {
            console.log('Cancel')
          },
        })
      } else {
        await api_v2.post(`user/removal/${promoter.id}`, dataBody)

        Mixpanel.track('Afastou promotor ', {})
  
        if (reloadApi) {
          reloadApi(true)
        }
        message.success('Promotor afastado com sucesso')
        close(false)
      }
    } catch (error) {
      setLoading(false)
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((el: any) => {
          formRef.current!.setFieldError(el.path, el.message)
        })
      } else {
        message.error('Erro ao afastar promotor!')
      }
    }
    setLoading(false)
  }

  const functionThatResetsForm = () => {
    formRef.current!.reset()
  }

  useEffect(() => {
    if (formRef.current != null) functionThatResetsForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const disabledDate = (current: any) => {
    // Can not select days before today and today
    return current && current < moment(new Date()).endOf('day').subtract(1, 'd')
  }

  return (
    <ContainerModal
      visible={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
      destroyOnClose={true}
    >
      {loading && <Loading />}

      <Form className='form' ref={formRef} initialData={{ is_active: true }} onSubmit={handleSubmit}>
        <ContainerStep2>
          <h3>Afastar promotor</h3>
          <img src={AddUser} alt='user' />
          <GroupForm>
            <LabelCheck>Selecione o motivo de afastamento*</LabelCheck>
            <Search placeholder='Selecione o motivo' name='removal' path='removals' isMult={false} />
          </GroupForm>
          <GroupForm>
            <LabelCheck>Selecione o período de afastamento*</LabelCheck>
            <SimpleRangePicker name='range' style={{ width: '100%' }} />
          </GroupForm>
          {/* <GroupForm>
            <LabelCheck>Observação</LabelCheck>
            <Input name='obs' />
          </GroupForm> */}
          <Button type='submit' style={{ width: '100%', height: '55px', marginTop: 20 }}>
            Afastar
          </Button>
        </ContainerStep2>
      </Form>
    </ContainerModal>
  )
}
