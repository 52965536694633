import styled from "styled-components";

export const SubContainer = styled.div`
  width: 100%;
  max-width: 272px;
  height: 233px;

  h3{
    width: 100%;
    text-align: center;
    color: #637282;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }
`
