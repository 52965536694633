import React, { useEffect, useState } from 'react'
import { ContainerModal, ContainerStep2 } from './style'
import { UserAddOutlined, DeleteOutlined } from '@ant-design/icons'
import { ContainerLogo } from '../ModalSugestion/style'
import { ContainerBodyModal } from '../ModalSchedule/style'
import { Col, Divider, Input, message, Row, Select } from 'antd'
import { BigButton, Label } from '../../GlobalStyles'
import api from '../../services/api'
import { SimpleSearchWithoutForm } from '../form/SimpleSearchWithoutForm'
import api_v2 from '../../services/api-v2'

interface IProps {
  visible: boolean
  close: (boolean: any) => void
}

export const ModalInvateUser = ({ visible, close }: IProps) => {
  const [workspace, setWorkspace] = useState([])
  const [data, setData] = useState([
    {
      name: '',
      email: '',
      password: '',
      workspace: [],
      profile: null,
    },
  ])

  const getWorkspace = async () => {
    try {
      const { data } = await api.get('workspace')
      setWorkspace(data.result)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getWorkspace()
    setData([
      {
        name: '',
        email: '',
        password: '',
        workspace: [],
        profile: null,
      },
    ])
  }, [visible])

  const changeValue = (index: number, value: any, prop: any) => {
    let newData: any = data
    newData[index][prop] = value
    setData([...newData])
  }

  const handleSubmit = async () => {
    try {
      let required = false

      data.forEach((el: any) => {
        if (!el.email) required = true
        if (!el.profile) required = true
        if (!el.password) required = true
        if (!el.name) required = true
        if (!el.workspace || el.workspace.length === 0) required = true
      })

      if (required) {
        message.error('Todos os campos são obrigatório')
        return
      }

      for await (let user of data) {
        await api_v2.post('user/invite', user)
      }
      message.success('Usuários convidado com sucesso.')
      close(true)
    } catch (error) {
      message.error('Erro ao convidar usuário')
      console.log(error)
      close(true)
    }
  }

  const pushValue = () => {
    let newData: any = data

    let required = false
    let diferent = false

    newData.forEach((el: any) => {
      if (!el.email) required = true
      if (!el.profile) required = true
      if (!el.password) required = true
      if (!el.confirm_password) required = true
      if (!el.name) required = true
      if (!el.workspace || el.workspace.length === 0) required = true

      if (el.confirm_password !== el.password) diferent = true
    })

    if (required) {
      message.error('Todos os campos são obrigatório')
      return
    }

    if (diferent) {
      message.error('As senhas devem ser iguais.')
      return
    }

    newData.push({
      name: '',
      email: '',
      password: '',
      workspace: [],
      profile: null,
    })
    setData([...newData])
  }

  return (
    <ContainerModal
      visible={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
      destroyOnClose={true}
      width={700}
    >
      <ContainerStep2>
        <h3>Convidar usuário</h3>
        <ContainerLogo style={{ marginBottom: 10 }}>
          <UserAddOutlined />
        </ContainerLogo>
        <ContainerBodyModal>
          <Row style={{ marginTop: 50 }} gutter={[30, 30]} justify='space-between'>
            {data.map((el: any, index: number) => (
              <>
                <Col span={22}>
                  <Row gutter={[10, 10]}>
                    <Col span={8}>
                      <Label>Nome do usuário*</Label>
                      <Input
                        placeholder='Nome do usuário'
                        onChange={(e) => changeValue(index, e.target.value, 'name')}
                        value={el.name}
                        type='text'
                        style={{
                          border: '1px solid #d9d9d9',
                          borderRadius: 4,
                          color: 'rgba(0,0,0,0.65)',
                          height: '38px',
                        }}
                      />
                    </Col>
                    <Col span={8}>
                      <Label>Endereço de email*</Label>
                      <Input
                        placeholder='Endereço de email'
                        onChange={(e) => changeValue(index, e.target.value, 'email')}
                        value={el.email}
                        type='email'
                        style={{
                          border: '1px solid #d9d9d9',
                          borderRadius: 4,
                          color: 'rgba(0,0,0,0.65)',
                          height: '38px',
                        }}
                      />
                    </Col>
                    <Col span={8}>
                      <Label>Digite a senha*</Label>
                      <Input
                        placeholder='Digite a senha'
                        onChange={(e) => changeValue(index, e.target.value, 'password')}
                        value={el.password}
                        type='password'
                        style={{
                          border: '1px solid #d9d9d9',
                          borderRadius: 4,
                          color: 'rgba(0,0,0,0.65)',
                          height: '38px',
                        }}
                      />
                    </Col>
                    <Col span={8}>
                      <Label>Confirme a senha*</Label>
                      <Input
                        placeholder='Confirme a senha'
                        onChange={(e) => changeValue(index, e.target.value, 'confirm_password')}
                        value={el.confirm_password}
                        type='password'
                        style={{
                          border: '1px solid #d9d9d9',
                          borderRadius: 4,
                          color: 'rgba(0,0,0,0.65)',
                          height: '38px',
                        }}
                      />
                    </Col>
                    <Col span={8}>
                      <Label>Selecionar workspace*</Label>
                      <Select
                        mode='multiple'
                        showSearch
                        placeholder='Selecione o workspace'
                        onChange={(e) => changeValue(index, e, 'workspace')}
                        value={el.workspace}
                        filterOption={(input: any, option: any) =>
                          (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                        }
                        style={{
                          border: '1px solid #d9d9d9',
                          borderRadius: 4,
                          color: 'rgba(0,0,0,0.65)',
                          height: '38px !important',
                          width: '100%',
                        }}
                      >
                        {workspace &&
                          workspace.map((el: any) => <Select.Option value={el.id}>{el.name}</Select.Option>)}
                      </Select>
                    </Col>
                    <Col span={8}>
                      <Label>Selecione o perfil*</Label>
                      <SimpleSearchWithoutForm
                        placeholder='Selecione o perfil'
                        path='profiles'
                        onChange={(e) => changeValue(index, e.value, 'profile')}
                        isMult={false}
                        value={el.profile}
                      />
                      {/* <Select
                        placeholder='Selecione o perfil'
                        onChange={(e) => changeValue(index, e, 'profile')}
                        value={el.profile}
                        style={{
                          border: '1px solid #d9d9d9',
                          borderRadius: 4,
                          color: 'rgba(0,0,0,0.65)',
                          height: '38px !important',
                          width: '100%',
                        }}
                      >
                        <Select.Option value='GESTOR'>Gestor</Select.Option>
                      </Select> */}
                    </Col>
                  </Row>
                </Col>
                {data.length > 0 && (
                  <Col
                    span={1}
                    onClick={() => {
                      let newData: any = []
                      data.forEach((elDelete: any, indexDelete: number) => {
                        if (indexDelete !== index) newData.push(elDelete)
                      })

                      setData([...newData])
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <DeleteOutlined style={{ fontSize: 20, color: 'red', cursor: 'pointer' }} />
                    </div>
                  </Col>
                )}
                {index !== data.length - 1 && <Divider style={{ margin: 0 }} />}
              </>
            ))}
          </Row>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <p style={{ width: 150, color: '#6d6d7c', cursor: 'pointer' }} onClick={() => pushValue()}>
              + Adicionar outro
            </p>
            <Divider style={{ minWidth: 'auto' }} />
          </div>
          <Row justify='center' style={{ marginTop: 40 }}>
            <Col>
              <BigButton
                onClick={() => handleSubmit()}
                style={{ width: 300, height: 50, backgroundColor: '#6e55cc', color: '#fff', fontSize: 17 }}
              >
                Convidar
              </BigButton>
            </Col>
          </Row>
        </ContainerBodyModal>
      </ContainerStep2>
    </ContainerModal>
  )
}
