import styled from 'styled-components'

export const Container = styled.div`
  margin: 10px 0;
`

export const Title = styled.p`
  color: #2e2f34;
  font-size: 16px;
`
export const ContainerCheckBox = styled.div`
  flex-direction: row;
  padding-bottom: 20px;
`
export const TextError = styled.p`
  width: 100%;
  margin-top: 15px;
  color: #e83f5b;
  text-align: center;
  font-size: 15px;
`
