import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    border: 1px solid #d6d6d6;
    border-radius: 20px;
    margin: 20px 0;

    h3{
        color: #b56aff;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    img{
        height: 70%;
    }
`