import { useField } from '@rocketseat/unform'
import { useEffect, useRef } from 'react'
import { Label } from './styles'
interface Iinput {
  label?: string
  name: any
  children?: any
  type?: string
  placeholder?: any
  value?: any
}

type InputProps = JSX.IntrinsicElements['input'] & Iinput

export default function InputElement({ label, name, type, children, placeholder, value, ...rest }: InputProps) {
  const ref = useRef<HTMLInputElement>(null)
  const { fieldName, registerField, defaultValue, error } = useField(name)

  const renderLabel = label || name

  useEffect(() => {
    if (!ref.current) return
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'value',
    })
  }, [ref.current, fieldName]) //eslint-disable-line

  return (
    <Label htmlFor={fieldName}>
      {error && <span>{error}</span>}
      <input
        name={fieldName}
        placeholder={placeholder}
        ref={ref}
        id={fieldName}
        type={type}
        aria-label={fieldName}
        defaultValue={defaultValue}
        {...rest}
      />
      <span>{renderLabel}</span>
    </Label>
  )
}
