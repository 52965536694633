import styled from 'styled-components'
import { Modal } from 'antd'

export const ContainerModal = styled(Modal)`
  &&& .searchBox {
    border: 1px solid #a365e5;
    color: #d3d8dc;
    display: flex;
    align-items: center;
    border-radius: 7px;
    background: rgba(99, 76, 185, 0.7);
    padding-left: 7px;
  }

  &&& .ant-modal-mask {
    background-color: rgba(122, 99, 195, 0.5) !important;
  }

  &&& .inputSearch {
    width: 100%;
    color: #fff;
    font-size: 22px;
    outline: none;
    /* border: 1px solid #a365e5; */
    border: none;
    border-radius: 7px;
    background-color: transparent !important;
    padding: 5px 25px;
  }

  &&& .inputSearch::placeholder {
    color: #d3d8dc;
  }

  &&& .ant-modal-content {
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    background-color: transparent !important;
    box-shadow: none !important;
  }

  &&& .ant-modal-body {
    /* background: rgba(99, 76, 185, 0.54); */
    background: transparent !important;
    border-radius: 16px;
    /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
    box-shadow: none !important;
    /* backdrop-filter: blur(5.7px);
    -webkit-backdrop-filter: blur(5.7px); */
  }

  &&& .ant-select-selector {
    align-items: center !important;
  }

  &&& .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: transparent !important;
    /* border-color: #a365e5 !important; */
    border: none !important;
    border-radius: 7px;
    height: 40px !important;
    padding: ;
    outline: none !important;
  }

  &&& .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    outline: none !important;
  }

  &&& .ant-selected-focused {
    border: none !important;
  }

  &&& .ant-select {
    font-size: 22px !important;
    color: #fff !important;
  }

  &&& .ant-select-dropdown {
    background-color: rgba(99, 76, 185, 0.54) !important;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
    backdrop-filter: blur(5.7px) !important;
    -webkit-backdrop-filter: blur(5.7px) !important;
  }

  &&& .ant-select-dropdown {
    background-color: rgba(99, 76, 185, 0.54) !important;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
    backdrop-filter: blur(5.7px) !important;
    -webkit-backdrop-filter: blur(5.7px) !important;
  }

  &&& .ant-select-item-option-content {
    color: #fff !important;
  }

  &&& .ant-select-item-option-active {
    color: #000 !important;
  }

  &&& .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    color: #000 !important;
  }

  &&& .ant-select-item {
    color: #fff !important;
  }
`
