import React, { useEffect, useState } from 'react'
import { Container, Title, ContainerRadio, TextError } from './styles'
import { Picture } from '../Picture'
import { Int } from '../Int'
import { UniqueChoice } from '../UniqueChoices'
import { MultipleChoise } from '../MultipleChoise'
import { Money } from '../Money'
import { Text } from '../Text'
import { DateForm } from '../DateForm'
import { Radio } from 'antd'
interface IQuestion {
  id: number
  is_required: boolean
  name: string
  order: string
  type: string
  fields_true?: any
  fields_false?: any
}
interface IDataQuestion {
  dataQuestion: IQuestion
  handleAnswers: (value: any, dataQuestion: IQuestion) => void
  error?: boolean
  listError: any
  checkRequired: (value: any) => void
  body: any
  defaultVal?: any
}

export const Boolean = ({ dataQuestion, handleAnswers, error, checkRequired, listError, body, defaultVal }: IDataQuestion) => {
  const [value, setValue] = useState()
  const [isRender, setIsRender] = useState(false)

  const handleValue = (value: any) => {
    setValue(value.target.value)
    handleAnswers([value.target.value], dataQuestion)
  }

  useEffect(() => {
    if (dataQuestion.is_required && !isRender) {
      setIsRender(true)
      checkRequired(dataQuestion.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const val = defaultVal && defaultVal[0] ? defaultVal[0].toLowerCase() : undefined
    if(val && val == 'sim') {
      handleValue({
        target: {
          value: 'SIM'
        }
      })
    } else {
      handleValue({
        target: {
          value: 'NÃO'
        }
      })
    }
  }, [defaultVal])

  return (
    <Container>
      <Title>
        {dataQuestion.order} - {dataQuestion.name} {dataQuestion.is_required && '*'}
      </Title>
      <ContainerRadio>
        <Radio.Group onChange={handleValue} value={value}>
          <Radio value={'SIM'}>Sim</Radio>
          <Radio value={'NÃO'}>Não</Radio>
        </Radio.Group>
      </ContainerRadio>
      {error && !value && <TextError>Campo obrigatório</TextError>}
      {value === 'SIM' && (
        <>
          {dataQuestion.fields_true &&
            dataQuestion.fields_true.map((question: any, index: number) => (
              <>
                {question.type === 'PICTURE_CAPTURE' && (
                  <Picture
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    handleAnswers={handleAnswers}
                    key={index}
                    error={listError.indexOf(question.id) > -1}
                    body={body}
                    defaultVal={body.all.find((item: any) => item.id === question.id)?.field_value}

                  />
                )}
                {question.type === 'NUMBER_INTEGER' && (
                  <Int
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    handleAnswers={handleAnswers}
                    key={index}
                    error={listError.indexOf(question.id) > -1}
                    defaultVal={body.all.find((item: any) => item.id === question.id)?.field_value}

                  />
                )}
                { question.type === 'NUMBER_DECIMAL' && (
                  <Int
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    handleAnswers={handleAnswers}
                    key={index}
                    error={listError.indexOf(question.id) > -1}
                    defaultVal={body.all.find((item: any) => item.id === question.id)?.field_value}

                  />
                )}
                {question.type === 'UNIQUE_CHOICE' && (
                  <UniqueChoice
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    handleAnswers={handleAnswers}
                    key={index}
                    error={listError.indexOf(question.id) > -1}
                    listError={listError}
                    body={body}
                    defaultVal={body.all.find((item: any) => item.id === question.id)?.field_value}

                  />
                )}
                {question.type === 'BOOLEAN' && (
                  <Boolean
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    handleAnswers={handleAnswers}
                    key={index}
                    error={listError.indexOf(question.id) > -1}
                    listError={listError}
                    body={body}
                    defaultVal={body.all.find((item: any) => item.id === question.id)?.field_value}

                  />
                )}
                {question.type === 'TEXT' && (
                  <Text
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    handleAnswers={handleAnswers}
                    key={index}
                    error={listError.indexOf(question.id) > -1}
                    defaultVal={body.all.find((item: any) => item.id === question.id)?.field_value}

                  />
                )}
                {question.type === 'MULTIPLE_CHOICE' && (
                  <MultipleChoise
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    handleAnswers={handleAnswers}
                    key={index}
                    error={listError.indexOf(question.id) > -1}
                    listError={listError}
                    body={body}
                    defaultVal={body.all.find((item: any) => item.id === question.id)?.field_value}

                  />
                )}
                {question.type === 'MONEY' && (
                  <Money
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    handleAnswers={handleAnswers}
                    key={index}
                    error={listError.indexOf(question.id) > -1}
                    defaultVal={body.all.find((item: any) => item.id === question.id)?.field_value}

                  />
                )}
                {question.type === 'DATE' && (
                  <DateForm
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    handleAnswers={handleAnswers}
                    key={index}
                    error={listError.indexOf(question.id) > -1}
                    defaultVal={body.all.find((item: any) => item.id === question.id)?.field_value}

                  />
                )}
              </>
            ))}
        </>
      )}
      {value === 'NÃO' && (
        <>
          {dataQuestion.fields_false &&
            dataQuestion.fields_false.map((question: any, index: number) => (
              <div key={question.id}>
                {question.type === 'PICTURE_CAPTURE' && (
                  <Picture
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    handleAnswers={handleAnswers}
                    error={listError.indexOf(question.id) > -1}
                    body={body}
                  />
                )}
                {question.type === 'NUMBER_INTEGER' && (
                  <Int
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    handleAnswers={handleAnswers}
                    error={listError.indexOf(question.id) > -1}
                  />
                )}
                {question.type === 'NUMBER_DECIMAL' && (
                  <Int
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    handleAnswers={handleAnswers}
                    error={listError.indexOf(question.id) > -1}
                  />
                )}
                {question.type === 'UNIQUE_CHOICE' && (
                  <UniqueChoice
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    handleAnswers={handleAnswers}
                    error={listError.indexOf(question.id) > -1}
                    listError={listError}
                    body={body}
                  />
                )}
                {question.type === 'BOOLEAN' && (
                  <Boolean
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    handleAnswers={handleAnswers}
                    error={listError.indexOf(question.id) > -1}
                    listError={listError}
                    body={body}
                  />
                )}
                {question.type === 'TEXT' && (
                  <Text
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    handleAnswers={handleAnswers}
                    error={listError.indexOf(question.id) > -1}
                  />
                )}
                {question.type === 'MULTIPLE_CHOICE' && (
                  <MultipleChoise
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    handleAnswers={handleAnswers}
                    error={listError.indexOf(question.id) > -1}
                    listError={listError}
                    body={body}
                  />
                )}
                {question.type === 'MONEY' && (
                  <Money
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    handleAnswers={handleAnswers}
                    error={listError.indexOf(question.id) > -1}
                  />
                )}
                {question.type === 'DATE' && (
                  <DateForm
                    checkRequired={checkRequired}
                    dataQuestion={question}
                    handleAnswers={handleAnswers}
                    key={index}
                    error={listError.indexOf(question.id) > -1}
                  />
                )}
              </div>
            ))}
        </>
      )}
    </Container>
  )
}
