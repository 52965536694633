import React, { useEffect, useState } from 'react'
import { CardCreate, ContainerModal } from './style'
import { DeleteOutlined } from '@ant-design/icons'
import { Checkbox, message } from 'antd'
import { Loading } from '../Loading'
import { ContainerLogo } from '../ModalSugestion/style'
import { ButtonBack } from '../../pages/Team/ViewUser/styles'
import api_v2 from '../../services/api-v2'
import { Mixpanel } from '../../utils/mixpanel'

interface IProps {
  visible: boolean
  close: (boolean: any) => void
  form: any
}

export const ModalRemoveMixForm = ({ visible, close, form }: IProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [checked, setChecked] = useState<boolean>(true)

  const handleSubmit = async () => {
    try {
      setLoading(true)
      await api_v2.put(`/form/mix/reset/${form.id}`, { delete_tasks: checked })
      Mixpanel.track('Resetou mix de formulário', {
        params: {
          id: form.id,
          delete_tasks: checked,
        },
      })
      message.success('Sucesso ao resetar mix.')
      Mixpanel.track('Removeu mix de formulário', form)

      setLoading(false)
      close(!visible)
    } catch (error) {
      setLoading(false)
      message.error('Erro ao deletar informação!')
    }
  }

  useEffect(() => {
    setChecked(true)
  }, [visible])

  return (
    <ContainerModal
      visible={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
      destroyOnClose={true}
    >
      {loading && <Loading />}
      <CardCreate style={{ width: '100%', height: '100%' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '45%',
          }}
        >
          <h4>Remover Mix do formulário {form.name}</h4>
          <ContainerLogo style={{ marginTop: 20, marginBottom: 50 }}>
            <DeleteOutlined />
          </ContainerLogo>
        </div>
        <div>
          <Checkbox
            onChange={(e: any) => {
              setChecked(e.target.checked)
            }}
            defaultChecked={true}
            value={checked}
          >
            Deseja apagar as tarefas já lançadas nesse mix?
          </Checkbox>
          <ButtonBack
            style={{ width: '100%', backgroundColor: '#b56aff', color: '#fff', margin: 0, marginTop: 10 }}
            type='button'
            onClick={() => handleSubmit()}
          >
            Remover mix
          </ButtonBack>
        </div>
      </CardCreate>
    </ContainerModal>
  )
}
