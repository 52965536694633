import styled from 'styled-components'
import CloseIcon from '@material-ui/icons/Close';

export const ContainerItems = styled.div`
  width: 60px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const IconMenu = styled.span`
  margin: 5px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;

  .anticon{
    width: 100%;
    height: 100%;
  }
  svg{
    width: 100%;
    height: 100%;
  }

  &:hover{
    transition: all 0.2s;
    background:#F0F0F0;
  }
`
export const ContainerConfig = styled.div`
  width: 600px;
  height: 100%;
  position: relative;
  border-left: 1px solid rgba(0, 0, 0, 0.07);
  padding: 15px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  h2{
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    width: 100%;
    font-weight: bold;
    padding-bottom: 20px;
  }
`

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;

  label{
    color: black;
    font-size: 16px;
  }

  input{
    outline: none;
    height: 40px;
    padding-left: 10px;
    border-radius: 10px;
    margin-top: 10px;
    border: 1px solid rgba(0, 0, 0, 0.4);
  }
`

export const FormGroupCheck = styled.div`
  label{
    color: black;
    font-size: 16px;
    margin-right: 20px;
  }
`
export const FormGroupTextArea = styled.div`
  display: flex;
  flex-direction: column;

  label{
    color: black;
    font-size: 16px;
  }

  textarea{
    width: 100%;
    height: 100px;
    max-height: 100px;
    min-height: 100px;
    min-width: 100%;
    max-width: 100%;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 10px;
  }
`
export const FormGroupSelect = styled.div`
  display: flex;
  flex-direction: column;

  label{
    color: black;
    font-size: 16px;
  }
  select{
    height: 40px;
    border-radius: 10px;
    background: none;
    border: 1px solid rgba(0, 0, 0, 0.4);
    padding-left: 10px;
  }

`

export const Button = styled.button`
  width: 120px;
  height: 40px;
  background: #7157C1;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
`
export const CloseConfig = styled(CloseIcon)`
  cursor: pointer;
`
export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`

export const CardJump = styled.div`
  width: 100%;
  border-radius: 15px;
  border-radius: 15px;
  box-shadow: rgb(0 0 0 / 30%) 0px 2px 4px;
  background: rgb(245 245 245);
  padding: 20px;
  margin-bottom: 20px;
  p{
    margin-top: 10px;
    margin-right: 10px;
  }
  b{
    color: red;
  }

  .subText{
    margin-top: 0;
  }
`
export const ContainerConfigJump = styled.div`
  width: calc(100vw - 660px);
  position: absolute;
  z-index: 9999999999;
  background: #FFFFFF;
  height: calc(100vh - 70px);
  left: 660px;
  border-left: 1px solid rgba(0, 0, 0, 0.07);
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ContainerConfigJumpQuest = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 600px;
  height: 100%;
  position: relative;
  border-left: 1px solid rgba(0, 0, 0, 0.07);
  padding: 15px;
  padding-bottom: 30px;
  h2{
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    width: 100%;
    font-weight: bold;
    padding-bottom: 20px;
  }
`
export const ContainerOptions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 30px;
  p{
    margin: 0;
    margin-left: 15px;
    font-size: 17px;
  }
`