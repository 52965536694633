import React, { useEffect, useRef, useState, useMemo } from 'react'
import { Select, Spin, SelectProps } from 'antd'
import debounce from 'lodash/debounce'
import api_v2 from '../../../services/api-v2'
import { useField } from '@unform/core'
import { Container, ContainerOption, Error, Label, StatusOptions } from './style'
const { Option } = Select

function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }: any) {
  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState([])
  const fetchRef = useRef(0)

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: any) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)
      fetchOptions(value).then((newOptions: any) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return
        }

        setOptions(newOptions)
        setFetching(false)
      })
    }

    return debounce(loadOptions, debounceTimeout)
  }, [fetchOptions, debounceTimeout])

  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching && <Spin size='small' />}
      {...props}
      // options={options}
      style={{
        height: 'auto',
      }}
    >
      {options.map((opt: any) => (
        <Option value={opt.value} key={opt.label} label={opt.label}>
          <ContainerOption>
            <StatusOptions is_active={opt.is_active} />
            <p>{opt.label}</p>
          </ContainerOption>
        </Option>
      ))}
    </Select>
  )
} // Usage of DebounceSelect

interface Props {
  name: string
  label?: string
  config?: string
  path: string
  isMult?: boolean
  next?: (boolean: any) => void
  blur?: any
}
type InputProps = SelectProps<any> & Props
export const Search: React.FC<InputProps> = ({ name, path, isMult, config, next, blur, ...rest }) => {
  const { fieldName, registerField, error, defaultValue } = useField(name)
  const selectRef = useRef(null)
  const [value, setValue] = useState<any>([])
  const [configUrl, setConfigUrl] = useState<any>(undefined)
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      setValue: (ref, value) => {
        console.log(value, ref, 'aquiiii')
        if (value && typeof value === 'object') setValue(isMult ? [value] : value)
        ref?.select?.setValue(value)
      },
      clearValue: (ref) => {
        setValue(undefined)
      },
      getValue: (ref) => {
        return value
      },
    })
  }, [fieldName, registerField, value])

  const fetchUserList = async (text: string) => {
    try {
      let { data } = await api_v2.get(`/search/${path}?search=${text.trim()}${configUrl ? configUrl : ''}`)
      return data
    } catch (error) {
      console.log(error)
      return []
    }
  }

  useEffect(() => {
    if (next && value && value.length > 0) next(true)
    if (next && value && typeof value === 'object' && Object.keys(value).length > 0) next(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    if (config) setConfigUrl(config)
  }, [config])

  return (
    <Container>
      <Label htmlFor={fieldName}>
        {isMult ? (
          <DebounceSelect
            mode='multiple'
            value={value}
            ref={selectRef}
            fetchOptions={(text: string) => fetchUserList(text)}
            onChange={(a: any) => {
              let select = a.map((el: any) => {
                return {
                  ...el,
                  label: el.key,
                }
              })
              setValue(select)
              if (blur) blur(select)
            }}
            style={{
              width: '100%',
            }}
            {...rest}
          />
        ) : (
          <DebounceSelect
            showSearch
            value={value}
            ref={selectRef}
            defaultValue={defaultValue}
            fetchOptions={(text: string) => fetchUserList(text)}
            onChange={(a: any) => {
              setValue({ ...a, label: a.key })
              if (blur) blur({ ...a, label: a.key })
            }}
            style={{
              width: '100%',
            }}
            {...rest}
          />
        )}
        {error && <Error>{error}</Error>}
      </Label>
    </Container>
  )
}
