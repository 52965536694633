import React, { useMemo, useRef, useState } from 'react'
import { ContainerModal } from './style'
import { ContextUpload } from '../../context/contextUpload'
import { Select, SelectProps, Spin } from 'antd'
import { debounce } from 'lodash'
import './style.css'
import history from '../../routes/history'
import { SearchOutlined } from '@ant-design/icons'
import { removeCaracter } from '../../utils/funcoes'
import { hasIndex } from '../Menu/menuHorizonte'
import moment from 'moment'

const backoffice = [
  { value: '/', label: 'Indicadores' },
  { value: '/backoffice/team/users/promoter', label: 'Time > Promotores' },
  { value: '/backoffice/team/users', label: 'Time > Usuários' },
  { value: '/backoffice/team/profiles', label: 'Time > Grupos & Permissões' },
  { value: '/backoffice/catalog/brands', label: 'Cadastro > Produtos > Marcas' },
  { value: '/backoffice/catalog/categories', label: 'Cadastro > Produtos > Categorias' },
  { value: '/backoffice/catalog/products', label: 'Cadastro > Produtos > Produtos' },
  { value: '/backoffice/catalog/mix-products', label: 'Cadastro > Produtos > Mix de produtos' },
  { value: '/backoffice/store/regionals', label: 'Cadastro > Loja > Regionais' },
  { value: '/backoffice/store/networks', label: 'Cadastro > Loja > Redes' },
  { value: '/backoffice/store/flags', label: 'Cadastro > Loja > Bandeiras' },
  { value: '/backoffice/store/pdvs', label: 'Cadastro > Loja > PDVS' },
  { value: '/backoffice/register/justification', label: 'Cadastro > Justificativa de falta' },
  { value: '/backoffice/register/training', label: 'Cadastro > Treinamento' },
  { value: '/backoffice/survey/forms', label: 'Pesquisa > Formulários' },
  { value: `/backoffice/survey/tasks?date=%5B"${moment().format('YYYY-MM-DD')}"%2C"${moment().format('YYYY-MM-DD')}"%5D`, label: 'Pesquisa > Atividades' },
  { value: '/backoffice/survey/visits', label: 'Pesquisa > Visitas' },
  { value: '/backoffice/reports/search', label: 'Relatórios > Pesquisas' },
  { value: '/backoffice/reports/justifyVisits', label: 'Relatórios > Visitas Justificadas' },
  { value: '/backoffice/reports/editVisits', label: 'Relatórios > Histórico status de visitas' },
  { value: '/backoffice/lunch', label: 'Relatórios > Almoço' },
  { value: '/backoffice/reports/pictures', label: 'Relatórios > Fotos' },
  { value: '/backoffice/monitory/pictureLastAndAfter', label: 'Relatórios > Antes x Depois' },
  { value: '/backoffice/monitory/monitoring', label: 'Monitoramento > Kanban' },
  { value: '/backoffice/monitory/planned', label: 'Monitoramento > Planejado X Realizado' },
  { value: '/backoffice/process', label: 'Processamento > Importação XLSX' },
]

const analytcs = [
  { value: '/Analitycs/', label: 'Dashboard' },
  { value: '/Analitycs/move', label: 'Tempo e movimento' },
  { value: '/Analitycs/team/detail', label: 'Atendimento' },
  { value: '/Analitycs/justify', label: 'Justificativas' },
  { value: '/Analitycs/visits', label: 'Painel de visitas' },
  { value: '/Analitycs/timeComparate', label: 'Comparativo de tempo' },
]

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>
  debounceTimeout?: number
}

function DebounceSelect<ValueType extends { key?: string; label: React.ReactNode; value: string | number } = any>({
  fetchOptions,
  debounceTimeout = 200,
  ...props
}: DebounceSelectProps<ValueType>) {
  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState([])
  const fetchRef = useRef(0)

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: any) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)
      fetchOptions(value).then((newOptions: any) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return
        }

        setOptions(newOptions)
        setFetching(false)
      })
    }

    return debounce(loadOptions, debounceTimeout)
  }, [fetchOptions, debounceTimeout])

  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching && <Spin size='small' />}
      {...props}
      options={options}
      style={{
        height: 'auto',
      }}
    />
  )
}

const verifyPermissionBackoffice = (permissions: any) => {
  let list: any = []

  backoffice.forEach((el: any) => {
    if (el.label === 'Time > Grupos & Permissões' &&
      hasIndex([
        "group.profile",
        "group.change_profile",
      ], permissions) > -1) {
      list.push(el)
    } else if (
      el.label === 'Indicadores' &&
      hasIndex([
        "home.filter",
        "home.add_metric",
        "home.attendance",
        "home.assiduity",
        "home.tasks",
        "home.team_on_off"
      ], permissions) > -1) {
      list.push(el)
    } else if (
      el.label === 'Time > Promotores' &&
      hasIndex([
        "team.promoter",
        "team.add_promoter",
        "team.import_promoter",
        "team.import_visit_promoter",
        "team.import_script_promoter",
        "team.export_promoter",
        "team.export_script_promoter",
        "team.change_visit_status",
        "team.change_visit_date",
        "team.remove_visit_date",
        "team.add_task",
        "team.remove_task",
        "team.reset_task"
      ], permissions) > -1) {
      list.push(el)
    } else if (
      el.label === 'Time > Usuários' &&
      hasIndex([
        "team.promoter",
        "team.add_promoter",
        "team.import_promoter",
        "team.import_visit_promoter",
        "team.import_script_promoter",
        "team.export_promoter",
        "team.export_script_promoter",
        "team.change_visit_status",
        "team.change_visit_date",
        "team.remove_visit_date",
        "team.add_task",
        "team.remove_task",
        "team.reset_task"
      ], permissions) > -1) {
      list.push(el)
    } else if (
      el.label === 'Cadastro > Produtos > Marcas' &&
      hasIndex([
        "catalog.brand",
        "catalog.add_brand",
        "catalog.change_brand",
        "catalog.remove_brand",
        "catalog.import_brand",
        "catalog.export_brand"
      ], permissions) > -1) {
      list.push(el)
    } else if (
      el.label === "Cadastro > Produtos > Categorias" &&
      hasIndex([
        "catalog.category",
        "catalog.add_category",
        "catalog.change_category",
        "catalog.remove_category"
      ], permissions) > -1) {
      list.push(el)
    } else if (
      el.label === "Cadastro > Produtos > Produtos" &&
      hasIndex([
        "catalog.product",
        "catalog.add_product",
        "catalog.change_product",
        "catalog.remove_product",
        "catalog.import_product",
        "catalog.export_product"
      ], permissions) > -1) {
      list.push(el)
    } else if (
      el.label === "Cadastro > Produtos > Mix de produtos" &&
      hasIndex([
        "catalog.product_mix",
        "catalog.add_product_mix",
        "catalog.change_product_mix",
        "catalog.remove_product_mix",
        "catalog.import_product_mix",
        "catalog.export_product_mix"
      ], permissions) > -1) {
      list.push(el)
    } else if (
      el.label === "Cadastro > Loja > Regionais" &&
      hasIndex([
        "store.regional",
        "store.add_regional",
        "store.change_regional",
        "store.remove_regional",
        "store.import_regional",
        "store.export_regional"
      ], permissions) > -1) {
      list.push(el)
    } else if (
      el.label === "Cadastro > Loja > Redes" &&
      hasIndex([
        "store.network",
        "store.add_network",
        "store.change_network",
        "store.remove_network",
        "store.import_network",
        "store.export_network"
      ], permissions) > -1) {
      list.push(el)
    } else if (
      el.label === "Cadastro > Loja > Bandeiras" &&
      hasIndex([
        "store.flag",
        "store.add_flag",
        "store.change_flag",
        "store.remove_flag",
        "store.import_flag",
        "store.export_flag"
      ], permissions) > -1) {
      list.push(el)
    } else if (
      el.label === "Cadastro > Loja > PDVS" &&
      hasIndex([
        "store.pdv",
        "store.add_pdv",
        "store.change_pdv",
        "store.remove_pdv",
        "store.import_pdv",
        "store.export_pdv"
      ], permissions) > -1) {
      list.push(el)
    } else if (
      el.label === "Cadastro > Justificativa de falta" &&
      hasIndex([
        "justify.list_justify",
        "justify.add_justify",
        "justify.change_justify",
        "justify.remove_justify"
      ], permissions) > -1) {
      list.push(el)
    } else if (
      el.label === "Cadastro > Treinamento" &&
      hasIndex([
        "training.list_training",
        "training.add_training",
        "training.change_training",
        "training.remove_training"
      ], permissions) > -1) {
      list.push(el)
    } else if (
      el.label === "Pesquisa > Formulários" &&
      hasIndex([
        "form.list_form",
        "form.add_form",
        "form.change_form",
        "form.toggle_form",
        "form.add_schedule_form",
        "form.change_schedule_form",
        "form.remove_form_mix",
        "form.import_form_mix",
        "form.export_form_mix"
      ], permissions) > -1) {
      list.push(el)
    } else if (
      el.label === "Pesquisa > Atividades" &&
      hasIndex([
        "task.list_task",
        "task.export_task"
      ], permissions) > -1) {
      list.push(el)
    } else if (
      el.label === "Pesquisa > Visitas" &&
      hasIndex([
        "visit.list_visit",
        "visit.add_visit_survey",
        "visit.export_visit"
      ], permissions) > -1) {
      list.push(el)
    } else if (
      el.label === "Relatórios > Pesquisas" &&
      hasIndex([
        "survey.list_survey"
      ], permissions) > -1) {
      list.push(el)
    } else if (
      el.label === "Relatórios > Visitas Justificadas" &&
      hasIndex([
        "justify_report.list_justify_report",
        "justify_report.homolog_justify_report"
      ], permissions) > -1) {
      list.push(el)
    } else if (
      el.label === "Relatórios > Fotos" &&
      hasIndex([
        "pictures.list_pictures",
        "pictures.export_pictures_ppt"
      ], permissions) > -1) {
      list.push(el)
    } else if (
      el.label === "Relatórios > Antes x Depois" &&
      hasIndex([
        "before_afer.list_before_afer"
      ], permissions) > -1) {
      list.push(el)
    } else if (
      el.label === "Monitoramento > Kanban" &&
      hasIndex([
        "kanban.list_kanban"
      ], permissions) > -1) {
      list.push(el)
    } else if (
      el.label === "Monitoramento > Planejado X Realizado" &&
      hasIndex([
        "plan_exec.list_plan_exec"
      ], permissions) > -1) {
      list.push(el)
    } else if (
      el.label === "Processamento > Importação XLSX" &&
      hasIndex([
        "process.list_process_import",
        "process.download_process_import"
      ], permissions) > -1) {
      list.push(el)
    }

  })
  return list
}

const verifyPermissionAnalytcs = (permissions: any) => {
  let list: any = []

  analytcs.forEach((el: any) => {
    if (el.label === 'Dashboard' &&
      hasIndex([
        "Visualizar Dashs",
        "dash.list_dash",
        "Convidar usuário",
        "dash.invite_user",
      ], permissions) > -1) {
      list.push(el)
    } else if (
      el.label === "Tempo e movimento" &&
      hasIndex([
        "Visualizar Grafícos",
        "time_and_move.view"
      ], permissions) > -1) {
      list.push(el)
    } else if (
      el.label === "Atendimento" &&
      hasIndex([
        "Visualizar Grafícos",
        "attendance.view"
      ], permissions) > -1) {
      list.push(el)
    } else if (
      el.label === 'Justificativas' &&
      hasIndex([
        "Visualizar Grafícos",
        "justify.view"
      ], permissions) > -1) {
      list.push(el)
    } else if (
      el.label === "Painel de visitas" &&
      hasIndex([
        "Visualizar Tabela",
        "visits_panel.view"
      ], permissions) > -1) {
      list.push(el)
    } else if (
      el.label === "Comparativo de tempo" &&
      hasIndex([
        "Visualizar Gráficos",
        "time_compare.view"
      ], permissions) > -1) {
      list.push(el)
    }

  })

  return list
}

async function fetchUserList(value: string, permissions: any) {
  let filter: any = []
  if (window.location.pathname.indexOf('Analitycs') > -1) {
    filter = verifyPermissionAnalytcs(permissions)
  } else {
    filter = verifyPermissionBackoffice(permissions)
  }
  // let filter = backoffice.filter((r) => r.label.toLowerCase().includes(value.toLowerCase()))
  return filter.filter((r: any) => removeCaracter(r.label).toLowerCase().includes(removeCaracter(value).toLowerCase()))
}

export const ModalSearch = () => {
  const { openSearch, setOpenSearch } = ContextUpload()
  const { permissions } = ContextUpload()


  return (
    <ContainerModal
      visible={openSearch}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(122, 99, 195, 0.5)' }}
      onCancel={() => setOpenSearch(!openSearch)}
      closable={false}
      destroyOnClose={true}
      // centered={true}
      style={{ top: 80 }}
    >
      <div className='searchBox' style={{ display: 'flex', alignItems: 'center' }}>
        <SearchOutlined style={{ fontSize: 20, color: '#fff' }} />
        <DebounceSelect
          autoFocus={true}
          showSearch
          fetchOptions={(text: string) => fetchUserList(text, permissions)}
          onChange={(e: any) => {
            history.push(e.value)
            setOpenSearch(!openSearch)
          }}
          placeholder='Buscar telas'
          style={{
            width: '100%',
            color: '#FFFFFF',
          }}
          dropdownRender={(menu) => (
            <React.Fragment>
              <div className='teste' style={{ color: '#FFFFFF !important' }}>
                {menu}
              </div>
            </React.Fragment>
          )}
          dropdownClassName='dropdownBoxSearch'
          dropdownStyle={{
            backgroundColor: 'rgba(99, 76, 185, 0.54)',
            backdropFilter: 'blur(5.7px)',
            color: '#fff !important',
          }}
        />
      </div>
    </ContainerModal>
  )
}
