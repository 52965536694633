import { Breadcrumb, Col, Collapse, Row, Tag, Tooltip } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { DrawerFilter } from '../../../components/DrawerFilter'
import { Loading } from '../../../components/Loading'
import { BigButton, ContainerButtonsFootter, ContainerFormFilter, customStyles, GroupForm } from '../../../GlobalStyles'
import { ContainerTagg } from '../../Survey/FormListPage/styles'
import { ButtonClear, ButtonSearch, LabelForm } from '../../Team/Promoter/style'
import {
  FileSearchOutlined,
  FilterOutlined,
  MinusOutlined,
  PlusOutlined,
  CloseOutlined,
  ClearOutlined,
} from '@ant-design/icons'
import { FormHandles } from '@unform/core'
import history from '../../../routes/history'
import { Form } from '@unform/web'
import SimpleRangePicker from '../../../components/form/SimpleRangePicker'
import moment from 'moment'
import qs from 'querystring'
import useReactRouter from 'use-react-router'
import { TableAnalytcs } from '../../../components_analytcs/Table'
import { ContainerFullGraph } from '../move/style'
import ColumnDash from '../../../components_analytcs/ColumnsDash'
import { ContainerTable, ContainerPorcent } from '../../../components_analytcs/Table/style'
import { CirculeStatus, ContainerStatus } from '../justify/style'
import { Link } from 'react-router-dom'
import api_v2 from '../../../services/api-v2'
import { Search } from '../../../components/form/SimpleSearch'
import Select from '../../../components/form/SimpleSelect'
import { optionUF } from '../../../utils/option-user'
import { ContextUpload } from '../../../context/contextUpload'
import { hasIndex } from '../../../components/Menu/menuHorizonte'
import { notAccess } from '../../../routes/ScrollToTop'

interface ChartProps {
  categories: string[]
  series: any[]
}
function DetailPage() {
  const formRef = useRef<FormHandles>(null)
  const { location } = useReactRouter()
  const { permissions } = ContextUpload()
  const [filterTag, setFilterTag] = useState<any>({})
  const [visible, setVisible] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [expanded, setExpanded] = useState<string>('1')
  const [week, setWeek] = useState<ChartProps>({
    categories: [],
    series: [],
  })
  const [uf, setUf] = useState<ChartProps>({
    categories: [],
    series: [],
  })
  const [chartDate, setChartDate] = useState<ChartProps>({
    categories: [],
    series: [],
  })
  const [data, setData] = useState<any>([])
  const [dataAttendency, setDataAtendency] = useState<any>({
    data: [],
    header: [],
  })
  async function searchRanking(body: any, url: any, setter: any) {
    let { states_ids, date, flags_ids, promoters_ids, networks_ids, pdvs_ids, regions_ids, status, uf, week } = body
    let filters: any = {
      filterQuery: {},
      dataTag: {},
      filterQueryUrl: {},
    }

    if (promoters_ids && promoters_ids.length > 0) {
      let text = ''
      promoters_ids.forEach((el: any) => (text = text === '' ? `${el.value}` : text + `,${el.value}`))
      filters.filterQuery.promoters_ids = text
      filters.dataTag = { ...filters.dataTag, promoters_ids }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, promoters_ids: JSON.stringify(promoters_ids) }
    }

    if (states_ids && states_ids.length > 0) {
      let text = ''
      states_ids.forEach((el: any) => (text = text === '' ? `${el}` : text + `,${el}`))
      filters.filterQuery.states_ids = text
      filters.dataTag = { ...filters.dataTag, states_ids }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, states_ids: JSON.stringify(states_ids) }
    }

    if (pdvs_ids && pdvs_ids.length > 0) {
      let text = ''
      pdvs_ids.forEach((el: any) => (text = text === '' ? `${el.value}` : text + `,${el.value}`))
      filters.filterQuery.pdvs_ids = text
      filters.dataTag = { ...filters.dataTag, pdvs_ids }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, pdvs_ids: JSON.stringify(pdvs_ids) }
    }

    if (networks_ids && networks_ids.length > 0) {
      let text = ''
      networks_ids.forEach((el: any) => (text = text === '' ? `${el.value}` : text + `,${el.value}`))
      filters.filterQuery.networks_ids = text
      filters.dataTag = { ...filters.dataTag, networks_ids }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, networks_ids: JSON.stringify(networks_ids) }
    }

    if (regions_ids && regions_ids.length > 0) {
      let text = ''
      regions_ids.forEach((el: any) => (text = text === '' ? `${el.value}` : text + `,${el.value}`))

      filters.filterQuery.regions_ids = text
      filters.dataTag = { ...filters.dataTag, regions_ids }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, regions_ids: regions_ids }
    }

    if (status) {
      filters.filterQuery.status = status
      filters.dataTag = { ...filters.dataTag, status }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, status: status }
    }

    if (uf) {
      filters.filterQuery.uf = uf
      filters.dataTag = { ...filters.dataTag, uf }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, uf: uf }
    }

    if (week) {
      filters.filterQuery.week = week
      filters.dataTag = { ...filters.dataTag, week }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, week: week }
    }

    if (flags_ids && flags_ids.length > 0) {
      let text = ''
      flags_ids.forEach((el: any) => (text = text === '' ? `${el.value}` : text + `,${el.value}`))

      filters.filterQuery.flags_ids = text
      filters.dataTag = { ...filters.dataTag, flags_ids }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, flags_ids: JSON.stringify(flags_ids) }
    }

    if (date) {
      filters.filterQueryUrl = { ...filters.filterQueryUrl, date: JSON.stringify(date) }
      if (date[0]) {
        filters.filterQuery.start_date = date[0]
        filters.dataTag = { ...filters.dataTag, start_date: date[0] }
      }

      if (date[1]) {
        filters.filterQuery.end_date = date[1]
        filters.dataTag = { ...filters.dataTag, end_date: date[1] }
      }
    } else {
      filters.filterQuery = {
        ...filters.filterQuery,
        end_date: moment().format('YYYY-MM-DD'),
        start_date: moment().format('YYYY-MM-DD'),
      }
      filters.dataTag = {
        ...filters.dataTag,
        end_date: moment().format('YYYY-MM-DD'),
        start_date: moment().format('YYYY-MM-DD'),
      }
    }

    const queryFilter = qs.stringify({ ...filters.filterQuery })
    // setUrlFilter(queryFilter)
    const { data } = await api_v2.get(`/${url}?${queryFilter}`)
    setter(data)
    setFilterTag(filters.dataTag)
    setVisible(false)
  }

  const removeFilter = async (typeRemove: string, value: string) => {
    let newObjTagg = filterTag

    if (typeRemove === 'promoters_ids') {
      newObjTagg.promoters_ids = newObjTagg.promoters_ids.filter((el: any) => el.value !== value)
      if (newObjTagg.promoters_ids.length === 0) delete newObjTagg.promoters_ids
    }

    if (typeRemove === 'states_ids') {
      newObjTagg.states_ids = newObjTagg.states_ids.filter((el: any) => el !== value)
      if (newObjTagg.states_ids.length === 0) delete newObjTagg.states_ids
    }

    if (typeRemove === 'flags_ids') {
      newObjTagg.flags_ids = newObjTagg.flags_ids.filter((el: any) => el.value !== value)
      if (newObjTagg.flags_ids.length === 0) delete newObjTagg.flags_ids
    }

    if (typeRemove === 'pdvs_ids') {
      newObjTagg.pdvs_ids = newObjTagg.pdvs_ids.filter((el: any) => el.value !== value)
      if (newObjTagg.pdvs_ids.length === 0) delete newObjTagg.pdvs_ids
    }

    if (typeRemove === 'regions_ids') {
      newObjTagg.regions_ids = newObjTagg.regions_ids.filter((el: any) => el.value !== value)
      if (newObjTagg.regions_ids.length === 0) delete newObjTagg.regions_ids
    }

    if (typeRemove === 'networks_ids') {
      newObjTagg.networks_ids = newObjTagg.networks_ids.filter((el: any) => el.value !== value)
      if (newObjTagg.networks_ids.length === 0) delete newObjTagg.networks_ids
    }

    if (typeRemove === 'start_date') {
      newObjTagg.date = [
        moment().format('YYYY-MM-DD'),
        filterTag.end_date ? filterTag.end_date : moment().format('YYYY-MM-DD'),
      ]
    }

    if (typeRemove === 'end_date') {
      newObjTagg.date = [
        filterTag.start_date ? filterTag.start_date : moment().format('YYYY-MM-DD'),

        moment().format('YYYY-MM-DD'),
      ]
    }

    setLoading(true)

    setFilterTag(newObjTagg)
    updateUrl(newObjTagg)
    await searchRanking(newObjTagg, 'kanban/promoter-visits-resume', setData)
    await searchRanking(newObjTagg, 'attendance/week', setWeek)
    await searchRanking(newObjTagg, 'attendance/state', setUf)
    await searchRanking(newObjTagg, 'attendance/days', setChartDate)
    await searchRanking(newObjTagg, 'attendance/days-table', setDataAtendency)
    setLoading(false)
  }

  const cleanFilter = async () => {
    window.sessionStorage.removeItem('filterAnalytcs')

    history.push(`/Analitycs/team/detail`)
    setVisible(false)
    setFilterTag({})
    formRef!.current!.reset()
  }

  const updateUrl = (obj: any) => {
    window.sessionStorage.setItem('filterAnalytcs', JSON.stringify(obj))

    let url: any = {}
    if (obj && obj.date) {
      url.date = JSON.stringify(obj.date)
    } else {
      url.date = [moment().startOf('week').format('YYYY-MM-DD'), moment().endOf('week').format('YYYY-MM-DD')]
    }

    if (obj && obj.pdvs_ids) {
      url.pdvs_ids = JSON.stringify(obj.pdvs_ids)
    }

    if (obj && obj.networks_ids) {
      url.networks_ids = JSON.stringify(obj.networks_ids)
    }

    if (obj && obj.states_ids) {
      url.states_ids = JSON.stringify(obj.states_ids)
    }

    if (obj && obj.regions_ids) {
      url.regions_ids = JSON.stringify(obj.regions_ids)
    }

    if (obj && obj.flags_ids) {
      url.flags_ids = JSON.stringify(obj.flags_ids)
    }

    if (obj && obj.promoters_ids) {
      url.promoters_ids = JSON.stringify(obj.promoters_ids)
    }
    history.push(`/Analitycs/team/detail?${qs.stringify(url)}`)
  }

  const getStatusWithFilter = async (data?: any) => {
    let block = notAccess(permissions, window.location.pathname)
    if (block === -2) return history.push('/notAcces')

    let obj: any = { ...qs.parse(location.search.replaceAll('?', '')), ...data }
    if (obj && obj.promoters_ids && typeof obj.promoters_ids === 'string')
      obj.promoters_ids = JSON.parse(obj.promoters_ids)

    if (obj && obj.states_ids && typeof obj.states_ids === 'string') obj.states_ids = JSON.parse(obj.states_ids)

    if (obj && obj.flags_ids && typeof obj.flags_ids === 'string') obj.flags_ids = JSON.parse(obj.flags_ids)
    if (obj && obj.regions_ids && typeof obj.regions_ids === 'string') obj.regions_ids = JSON.parse(obj.regions_ids)
    if (obj && obj.networks_ids && typeof obj.networks_ids === 'string') obj.networks_ids = JSON.parse(obj.networks_ids)
    if (obj && obj.pdvs_ids && typeof obj.pdvs_ids === 'string') obj.pdvs_ids = JSON.parse(obj.pdvs_ids)
    // if (obj && obj.wek) obj.wek = JSON.parse(obj.wek)
    // if (obj && obj.uf) obj.uf = JSON.parse(obj.uf)
    if (obj && obj.date && typeof obj.date === 'string') obj.date = JSON.parse(obj.date)

    if (!obj || !obj.date)
      obj.date = [moment().startOf('week').format('YYYY-MM-DD'), moment().endOf('week').format('YYYY-MM-DD')]
    updateUrl(obj)
    setLoading(true)
    await searchRanking(obj, 'kanban/promoter-visits-resume', setData)
    await searchRanking(obj, 'attendance/week', setWeek)
    await searchRanking(obj, 'attendance/state', setUf)
    await searchRanking(obj, 'attendance/days', setChartDate)
    await searchRanking(obj, 'attendance/days-table', setDataAtendency)
    setLoading(false)
  }

  useEffect(() => {
    let getValue = window.sessionStorage.getItem('filterAnalytcs')
    if (getValue !== undefined && getValue !== null && getValue !== 'undefined') {
      getStatusWithFilter(JSON.parse(getValue))
    } else {
      getStatusWithFilter()
    }
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columnsAssiduidade: any = [
    {
      title: 'Promotor',
      dataIndex: 'name',
      key: 'name',
      width: '250px',
    },
    {
      title: 'Atendimento previsto',
      dataIndex: 'plan',
      key: 'plan',
      width: '100px',
      align: 'center',
    },
    {
      title: 'Atendimento realizado',
      dataIndex: 'complete',
      width: '100px',
      key: 'complete',
      align: 'center',
    },
    {
      title: 'Justificado',
      dataIndex: 'justify',
      width: '100px',
      key: 'justify',
      align: 'center',
    },
    {
      title: 'Atendimento',
      dataIndex: 'attendance',
      width: '100px',
      key: 'attendance',
      render: (a: any) => (
        <ContainerPorcent color={a > 50 ? 'rgba(0, 196, 159, 0.5)' : 'rgba(255, 128, 66, 0.5)'} percent={a}>
          <ContainerStatus>
            <CirculeStatus color={a > 50 ? 'rgb(0, 196, 159)' : 'rgb(255, 128, 66)'} />
            {a} %
          </ContainerStatus>
        </ContainerPorcent>
      ),
    },
    {
      title: 'Assiduidade',
      dataIndex: 'assiduity',
      width: '100px',
      key: 'assiduity',
      render: (a: any) => (
        <ContainerPorcent color={a > 50 ? 'rgba(0, 196, 159, 0.5)' : 'rgba(255, 128, 66, 0.5)'} percent={a}>
          <ContainerStatus>
            <CirculeStatus color={a > 50 ? 'rgb(0, 196, 159)' : 'rgb(255, 128, 66)'} />
            {a} %
          </ContainerStatus>
        </ContainerPorcent>
      ),
    },
  ]

  return (
    <div style={{ padding: '0 10px' }}>
      <Row gutter={[8, 8]} justify='space-between'>
        <Col span={20}>
          <h1>Atendimento detalhado</h1>
          <Breadcrumb className='breadcumb'>
            <Link to='/Analitycs'>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
            </Link>
            <Breadcrumb.Item>
              <Breadcrumb.Item>Atendimento detalhado</Breadcrumb.Item>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col>
          <Tooltip placement='top' title={'Expandir todos gráficos'}>
            <BigButton
              onClick={() => {
                setLoading(true)
                setExpanded('1')
                setTimeout(() => {
                  setLoading(false)
                }, 200)
              }}
              style={{ padding: 9 }}
            >
              <PlusOutlined />
            </BigButton>
          </Tooltip>
        </Col>
        <Col>
          <Tooltip placement='top' title={'Ocultar todos gráficos'}>
            <BigButton
              onClick={() => {
                setLoading(true)
                setExpanded('0')
                setTimeout(() => {
                  setLoading(false)
                }, 200)
              }}
              style={{ padding: 9 }}
            >
              <MinusOutlined />
            </BigButton>
          </Tooltip>
        </Col>
        <Col>
          <Tooltip placement='top' title={'Filtrar'}>
            <BigButton onClick={() => setVisible(!visible)} style={{ padding: 9 }}>
              <FilterOutlined />
            </BigButton>
          </Tooltip>
        </Col>
        <Col span={24}>
          <ContainerTagg>
            {filterTag.start_date && (
              <Tag color='geekblue' onClick={() => removeFilter('start_date', `${filterTag.start_date}`)}>
                {moment(filterTag.start_date).format('DD/MM/YYYY')}
                <CloseOutlined />
              </Tag>
            )}
            {filterTag.end_date && (
              <Tag color='geekblue' onClick={() => removeFilter('end_date', `${filterTag.end_date}`)}>
                {moment(filterTag.end_date).format('DD/MM/YYYY')}
                <CloseOutlined />
              </Tag>
            )}

            {filterTag.week && (
              <Tag color='geekblue' onClick={() => removeFilter('week', filterTag.week)}>
                {filterTag.week} <CloseOutlined />
              </Tag>
            )}
            {filterTag.uf && (
              <Tag color='geekblue' onClick={() => removeFilter('uf', filterTag.uf)}>
                {filterTag.uf} <CloseOutlined />
              </Tag>
            )}
            {filterTag.status && (
              <Tag color='geekblue' onClick={() => removeFilter('status', filterTag.status)}>
                {filterTag.status} <CloseOutlined />
              </Tag>
            )}
            {filterTag.flags_ids &&
              filterTag.flags_ids.map((el: any) => (
                <Tag color='geekblue' onClick={() => removeFilter('flags_ids', el.value)}>
                  {el.label} <CloseOutlined />
                </Tag>
              ))}
            {filterTag.states_ids &&
              filterTag.states_ids.map((el: any) => (
                <Tag color='geekblue' onClick={() => removeFilter('states_ids', el)}>
                  {el} <CloseOutlined />
                </Tag>
              ))}
            {filterTag.regions_ids &&
              filterTag.regions_ids.map((el: any) => (
                <Tag color='geekblue' onClick={() => removeFilter('regions_ids', el.value)}>
                  {el.label} <CloseOutlined />
                </Tag>
              ))}
            {filterTag.promoters_ids &&
              filterTag.promoters_ids.map((el: any) => (
                <Tag color='geekblue' onClick={() => removeFilter('promoters_ids', el.value)}>
                  {el.label} <CloseOutlined />
                </Tag>
              ))}
            {filterTag.networks_ids &&
              filterTag.networks_ids.map((el: any) => (
                <Tag color='geekblue' onClick={() => removeFilter('networks_ids', el.value)}>
                  {el.label} <CloseOutlined />
                </Tag>
              ))}
            {filterTag.pdvs_ids &&
              filterTag.pdvs_ids.map((el: any) => (
                <Tag color='geekblue' onClick={() => removeFilter('pdvs_ids', el.value)}>
                  {el.label} <CloseOutlined />
                </Tag>
              ))}
          </ContainerTagg>
        </Col>
      </Row>
      {hasIndex(['attendance.view'], permissions) > -1 && (
        <>
          {loading ? (
            <Loading />
          ) : (
            <>
              <Row>
                <Col span={12}>
                  <ContainerFullGraph>
                    <Collapse bordered={false} defaultActiveKey={[expanded]}>
                      <Collapse.Panel header='Atendimento por UF' key='1'>
                        <ColumnDash categories={uf.categories} series={uf.series} legend={true} />
                      </Collapse.Panel>
                    </Collapse>
                  </ContainerFullGraph>
                </Col>
                <Col span={12}>
                  <ContainerFullGraph>
                    <Collapse bordered={false} defaultActiveKey={[expanded]}>
                      <Collapse.Panel header='Atendimento por semana' key='1'>
                        <ColumnDash categories={week.categories} series={week.series} legend={true} />
                      </Collapse.Panel>
                    </Collapse>
                  </ContainerFullGraph>
                </Col>
              </Row>
              <ContainerFullGraph>
                <Collapse bordered={false} defaultActiveKey={[expanded]}>
                  <Collapse.Panel header='Comparativo de atendimento por data' key='1'>
                    <ColumnDash
                      id='full-bar-dash'
                      categories={chartDate.categories}
                      series={chartDate.series}
                      // type='time'
                      tooltip={true}
                    />
                  </Collapse.Panel>
                </Collapse>
              </ContainerFullGraph>
              <ContainerFullGraph style={{ marginTop: '100px' }}>
                <Collapse bordered={false} defaultActiveKey={[expanded]}>
                  <Collapse.Panel header='Atendimento e assiduidade geral' key='1'>
                    <ContainerTable>
                      <TableAnalytcs
                        columns={columnsAssiduidade}
                        // expandable={{
                        //   ...rowSelectionAssiduidade,
                        // }}
                        pagination={false}
                        fullY={true}
                        noScrool={true}
                        data={data}
                      />
                    </ContainerTable>
                  </Collapse.Panel>
                </Collapse>
              </ContainerFullGraph>
              <ContainerFullGraph style={{ marginTop: '100px' }}>
                <Collapse bordered={false} defaultActiveKey={[expanded]}>
                  <Collapse.Panel header='Atendimento diário' key='1'>
                    <ContainerTable>
                      <TableAnalytcs
                        columns={
                          dataAttendency &&
                          dataAttendency.header.length > 0 &&
                          dataAttendency.header.map((el: any, index: number) => {
                            if (index !== 0) {
                              return {
                                ...el,
                                width: '150px',
                                render: (a: any) => (
                                  <>
                                    {a === undefined ? (
                                      <Tooltip placement='top' title={'Sem Visita'}>
                                        <ContainerPorcent color={'rgba(248,186,186, 1)'} percent={100}>
                                          <ContainerStatus>
                                            <CirculeStatus color={'rgb(248,0,0)'} />
                                            S/V
                                          </ContainerStatus>
                                        </ContainerPorcent>
                                      </Tooltip>
                                    ) : (
                                      <ContainerPorcent
                                        color={a > 50 ? 'rgba(0, 196, 159, 0.5)' : 'rgba(255, 128, 66, 0.5)'}
                                        percent={a}
                                      >
                                        <ContainerStatus>
                                          <CirculeStatus color={a > 50 ? 'rgb(0, 196, 159)' : 'rgb(255, 128, 66)'} />
                                          {a} %
                                        </ContainerStatus>
                                      </ContainerPorcent>
                                    )}
                                  </>
                                ),
                              }
                            }
                            return {
                              fixed: 'left',
                              width: 250,
                              ...el,
                            }
                          })
                        }
                        // expandable={{
                        //   ...rowSelectionGeral,
                        // }}
                        pagination={false}
                        data={dataAttendency.data}
                        fullY={true}
                        noScrool={true}
                      />
                    </ContainerTable>
                  </Collapse.Panel>
                </Collapse>
              </ContainerFullGraph>
            </>
          )}
        </>
      )}
      <DrawerFilter
        visible={visible}
        close={setVisible}
        title='Atendimento detalhado'
        footer={
          <ContainerButtonsFootter>
            <ButtonSearch type='button' onClick={() => formRef?.current?.submitForm()}>
              <FileSearchOutlined />
              Pesquisar
            </ButtonSearch>
            <ButtonClear onClick={() => cleanFilter()}>
              <ClearOutlined />
              Limpar
            </ButtonClear>
          </ContainerButtonsFootter>
        }
      >
        <ContainerFormFilter
          tabIndex={0}
          onKeyDown={(evt: any) => {
            if (evt.key === 'Enter') formRef?.current?.submitForm()
          }}
        >
          <Form ref={formRef} className='form' style={{ marginTop: '15px' }} onSubmit={getStatusWithFilter}>
            <SimpleRangePicker
              name='date'
              label='Selecione o periodo:'
              defaultValueInput={[moment().startOf('week'), moment().endOf('week')]}
              ranges={{
                Ontem: [moment().subtract('d', 1), moment().subtract('d', 1)],
                Hoje: [moment(), moment()],
                Amanhã: [moment().add('d', 1), moment().add('d', 1)],
                'Esta semana': [moment().startOf('week'), moment().endOf('week')],
                'Este mês': [moment().startOf('month'), moment().endOf('month')],
              }}
            />
            <GroupForm>
              <LabelForm>Selecione a promotor</LabelForm>
              <Search placeholder='Selecione o promotor' name='promoters_ids' path='promoters' isMult={true} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione a bandeira</LabelForm>
              <Search placeholder='Selecione a bandeira' name='flags_ids' path='flags' isMult={true} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione a rede</LabelForm>
              <Search placeholder='Selecione a rede' name='networks_ids' path='networks' isMult={true} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione o pdv</LabelForm>
              <Search placeholder='Selecione o pdv' name='pdvs_ids' path='pdvs' isMult={true} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione a região</LabelForm>
              <Search placeholder='Selecione a região' name='regions_ids' path='regions' isMult={true} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione o estado</LabelForm>
              <Select
                styles={customStyles}
                className='pre-select'
                placeholder='Selecione o status'
                options={optionUF}
                isMulti={true}
                name='states_ids'
              />
            </GroupForm>
          </Form>
        </ContainerFormFilter>
      </DrawerFilter>
    </div>
  )
}

export default DetailPage
