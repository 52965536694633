import { useEffect, useState } from 'react'
import { Col, Row, Table, Input, Checkbox } from 'antd'
import qs from 'querystring'
import { debounce } from 'lodash'
import { INIT_GLOBAL_RESPONSE } from '../../utils/global-response'
import api from '../../services/api'
export const TableSubWorkspace = ({ dataSource, pagination, loading, handleTable, setter, onChange }: any) => {
  const [dataTableWorkspace, setDataTableWorkspace] = useState<any>(INIT_GLOBAL_RESPONSE)
  const [selecteds, setSelecteds] = useState<any>([])
  const [namePdv, setNamePdv] = useState<any>('')
  const [loadingSubMix, setLoadingSubMix] = useState<boolean>(false)

  const columnsPdv = [
    {
      title: 'Nome',
      dataIndex: 'name',
      ellipsis: true,
    },
    {
      title: 'PDV',
      dataIndex: 'pdv_code',
      ellipsis: true,
      width: 100,
    },
    {
      title: 'CNPJ',
      dataIndex: 'cnpj',
      ellipsis: true,
      width: 100,
    },
    {
      title: 'Telefone',
      dataIndex: 'telephone',
      ellipsis: true,
    },
    {
      title: 'Gerente',
      dataIndex: 'manager',
      ellipsis: true,
    },
    {
      title: 'CEP',
      dataIndex: 'cep',
      ellipsis: true,
    },
    {
      title: 'Estado',
      dataIndex: 'state',
      ellipsis: true,
    },
    {
      title: 'Cidade',
      dataIndex: 'city',
      ellipsis: true,
    },
    {
      title: 'Endereço',
      dataIndex: 'address',
      ellipsis: true,
    },
  ]

  const debouncedSave = debounce(async () => {
    setDataTableWorkspace({ ...dataTableWorkspace, subLoading: true })
    let filterQuery: any = {}
    if (namePdv) filterQuery.name = namePdv
    const queryFilter = qs.stringify({ ...filterQuery })
    if (namePdv === '') {
      const { data } = await api.get(`/listAndFind?${queryFilter}`)
      setDataTableWorkspace({ ...data, subLoading: false })
    } else {
      const { data } = await api.get(`/listAndFind?${queryFilter}`)
      setDataTableWorkspace({ ...data, subLoading: false })
    }
  }, 1300)

  const removeSelected = (type: any, subWorksapces: any) => {
    setLoadingSubMix(true)
    let newSelecte: any = []

    selecteds.forEach((element: any, index: number) => {
      if (element.pdv === type.id) {
        let newListProduct: any = selecteds[index].subWorksapces.filter((el: any) => {
          return el !== subWorksapces.id
        })
        newSelecte.push({
          pdv: element.pdv,
          subWorksapces: newListProduct,
        })
      } else {
        newSelecte.push(element)
      }
    })

    setSelecteds(newSelecte)
    setter(newSelecte)
    setTimeout(() => {
      setLoadingSubMix(false)
    }, 500)
  }

  const addSelected = (type: any, subWorksapces: any) => {
    setLoadingSubMix(true)
    let newSelecte: any = selecteds
    let haveSub = false

    selecteds.forEach((element: any) => {
      if (element.pdv === type.id) {
        haveSub = true
      }
    })

    newSelecte.forEach((element: any) => {
      if (element.pdv === type.id) {
        let newListSubWorkspace = element.subWorksapces
        newListSubWorkspace.push(subWorksapces.id)
        return {
          pdv: element.pdv,
          subWorksapces: newListSubWorkspace,
        }
      }
    })

    if (!haveSub) {
      newSelecte.push({
        pdv: type.id,
        subWorksapces: [subWorksapces.id],
      })
    }

    setSelecteds(newSelecte)
    setter(newSelecte)
    setTimeout(() => {
      setLoadingSubMix(false)
    }, 500)
  }

  const handleTableChangeWorkspace = async (queryParams?: any) => {
    console.log(queryParams, 'queryParams')
    setDataTableWorkspace({ ...dataTableWorkspace, subLoading: true })

    const query = qs.stringify({
      perpage: queryParams.pageSize,
      page: queryParams.current,
    })
    const { data } = await api.get('/listAndFind?' + query)
    setDataTableWorkspace({ ...data, subLoading: false })
  }

  function compareByAlph(a: any, z: any) {
    if (a > z) {
      return -1
    }
    if (a < z) {
      return 1
    }
    return 0
  }

  const listWorkspaces = async () => {
    setDataTableWorkspace({ ...dataTableWorkspace, loading: true })
    const { data } = await api.get('listAndFind')
    setDataTableWorkspace({ ...data, loading: false })
  }

  useEffect(() => {
    listWorkspaces()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    debouncedSave()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [namePdv])

  return (
    <Table
      columns={columnsPdv}
      rowKey={(item) => item.id}
      dataSource={dataSource}
      expandable={{
        expandedRowRender: (record) => (
          <>
            <Row gutter={[8, 8]} style={{ marginBottom: 20 }}>
              <Col span={6}>
                <p style={{ marginBottom: 5, color: '#637282' }}>Busque pelo nome do workspace</p>
                <Input value={namePdv} placeholder={`Nome do workspace`} onChange={(e) => setNamePdv(e.target.value)} />
              </Col>
            </Row>
            <Table
              style={{
                padding: '25px',
                border: '1px solid rgb(0 0 0 / 25%)',
                boxShadow: '0px 2px 10px lightgrey',
              }}
              columns={[
                {
                  title: 'Selecione',
                  width: '100px',
                  render: (obj: any) => {
                    let checked = false

                    selecteds.forEach((element: any) => {
                      if (element.pdv === record.id) {
                        element.subWorksapces.forEach((el: any) => {
                          if (el === obj.id) checked = true
                        })
                      }
                    })

                    return (
                      <Checkbox
                        onChange={(evt) => {
                          if (evt.target.checked) return addSelected(record, obj)
                          removeSelected(record, obj)
                        }}
                        checked={checked}
                      />
                    )
                  },
                },
                {
                  title: 'Nome',
                  dataIndex: 'name',
                  key: 'name',
                  sorter: (a: any, b: any) => compareByAlph(a.name, b.name),
                },
              ]}
              rowKey={(item) => item.id}
              dataSource={dataTableWorkspace.result}
              pagination={{
                current: dataTableWorkspace.page,
                pageSize: dataTableWorkspace.perpage,
                total: dataTableWorkspace.total,
                size: 'small',
                showSizeChanger: true,
                showTotal: () =>
                  `Exibindo ${dataTableWorkspace.result.length} de ${dataTableWorkspace.total} de registros`,
              }}
              loading={loadingSubMix}
              onChange={handleTableChangeWorkspace}
            />
          </>
        ),
      }}
      pagination={pagination}
      loading={loading}
      onChange={onChange}
    />
  )
}
