import {
  FileSearchOutlined,
  FilterOutlined,
  CloseOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
  ClearOutlined,
  CloudDownloadOutlined,
} from '@ant-design/icons'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { Breadcrumb, Col, Row, Tag, Tooltip, Pagination, DatePicker } from 'antd'
import qs from 'querystring'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  BigButton,
  ContainerButtonsFootter,
  ContainerFormFilter,
  ContainerList,
  GroupForm,
} from '../../../GlobalStyles'
import { ContainerTagg } from '../../Survey/FormListPage/styles'
import { ButtonClear, ButtonSearch, ContainerPaginationFooter, LabelForm } from '../../Team/Promoter/style'
import 'moment/locale/pt-br'
import api_v2 from '../../../services/api-v2'
import useReactRouter from 'use-react-router'
import { Search } from '../../../components/form/SimpleSearch'
import { optionUF } from '../../../utils/option-user'
import { CardPicture } from '../../../components/CardPicture'
import { INIT_GLOBAL_RESPONSE } from '../../../utils/global-response'
import { Loading } from '../../../components/Loading'
import { DrawerFilter } from '../../../components/DrawerFilter'
import history from '../../../routes/history'
import locale from 'antd/es/date-picker/locale/pt_BR'
import { NoSearch } from '../../../components/NoSearch'
import { AddButton } from '../../../components/DefaultPage/style'
import { ModalExportPPT } from '../../../components/ModalExportPPT'
import { ContextUpload } from '../../../context/contextUpload'
import { hasIndex } from '../../../components/Menu/menuHorizonte'
import SimpleSelectObject from '../../../components/form/SimpleSelectObject'
import { GroupSearch } from '../../../components/form/GroupSearch'
const dateFormat = 'DD/MM/YYYY'

export interface SearchFilters {
  id?: string
  description?: string
  date?: string
  form?: number
  pdv?: number
  user?: number
  dt_visit?: string
  start_date?: string
  end_date?: string
  sub_workspace?: number
}

const Pictures: React.FC = () => {
  const { location } = useReactRouter()
  const { permissions } = ContextUpload()

  const [visible, setVisible] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [openPPT, setOpenPPT] = useState<boolean>(false)
  const [filterTag, setFilterTag] = useState<any>({})
  const [param, setParam] = useState<any>({})
  const [selectQuestion, setSelectQuestion] = useState<any>('')
  const [dataPictures, setDataPicture] = useState<any>(INIT_GLOBAL_RESPONSE)
  const [filterUrl, setFilterUrl] = useState<any>('')
  const [dateRange, setDateRange] = useState<any>([
    moment(new Date(), dateFormat).subtract(7, 'd'),
    moment(new Date(), dateFormat),
  ])

  let getWorkspace = sessionStorage.getItem('@rocketpdv:workspace')
  let newGetWorkspace = JSON.parse(getWorkspace!.toString())

  const formRef = useRef<FormHandles>(null)

  const removeFilter = async (typeRemove: string, value: any) => {
    let newObjTagg = filterTag
    if (typeRemove === 'pdv_id') {
      newObjTagg.pdv_id = newObjTagg.pdv_id.filter((el: any) => el.value !== value)
      if (newObjTagg.pdv_id.length === 0) delete newObjTagg.pdv_id
    }
    if (typeRemove === 'sub_workspaces') {
      newObjTagg.sub_workspaces = newObjTagg.sub_workspaces.filter((el: any) => el.value !== value)
      if (newObjTagg.sub_workspaces.length === 0) delete newObjTagg.sub_workspaces
    }
    if (typeRemove === 'promoter_id') {
      newObjTagg.promoter_id = newObjTagg.promoter_id.filter((el: any) => el.value !== value)
      if (newObjTagg.promoter_id.length === 0) delete newObjTagg.promoter_id
    }
    if (typeRemove === 'form_id') {
      newObjTagg.form_id = newObjTagg.form_id.filter((el: any) => el.value !== value)
      if (newObjTagg.form_id.length === 0) delete newObjTagg.form_id
    }
    if (typeRemove === 'question_id') {
      newObjTagg.question_id = newObjTagg.question_id.filter((el: any) => el.value !== value)
      if (newObjTagg.question_id.length === 0) delete newObjTagg.question_id
    }
    if (typeRemove === 'flag_id') {
      newObjTagg.flag_id = newObjTagg.flag_id.filter((el: any) => el.value !== value)
      if (newObjTagg.flag_id.length === 0) delete newObjTagg.flag_id
    }

    if (typeRemove === 'network_id') {
      newObjTagg.network_id = newObjTagg.network_id.filter((el: any) => el.value !== value)
      if (newObjTagg.network_id.length === 0) delete newObjTagg.network_id
    }

    if (typeRemove === 'state') {
      newObjTagg.state = newObjTagg.state.filter((el: any) => el !== value)
      if (newObjTagg.state.length === 0) delete newObjTagg.state
    }
    if (typeRemove === 'start_date') delete newObjTagg.start_date
    if (typeRemove === 'end_date') delete newObjTagg.end_date

    setLoading(true)
    setFilterTag(newObjTagg)
    setLoading(false)
    getDataPictures(newObjTagg)
  }

  const getDataPictures = async (dataParam: any) => {
    try {
      const { flag_id, promoter_id, pdv_id, network_id, form_id, question_id, sub_workspaces, state } = dataParam
      setParam(dataParam)
      setDataPicture(INIT_GLOBAL_RESPONSE)
      setLoading(true)
      let dataTag = {}
      let filterQueryUrl: any = {}

      let obj: any = {
        perpage: dataPictures.perpage,
        page: dataPictures.page,
      }

      if (flag_id && flag_id.length > 0) {
        let text = ''
        flag_id.forEach((el: any) => (text = text === '' ? `${el.value}` : text + `,${el.value}`))
        obj = { ...obj, flag: text }
        dataTag = { ...dataTag, flag_id }
        filterQueryUrl.flag_id = JSON.stringify(flag_id)
      }

      if (promoter_id && promoter_id.length > 0) {
        let text = ''
        promoter_id.forEach((el: any) => (text = text === '' ? `${el.value}` : text + `,${el.value}`))
        obj = { ...obj, promoter: text }
        dataTag = { ...dataTag, promoter_id }
        filterQueryUrl.promoter_id = JSON.stringify(promoter_id)
      }

      if (pdv_id && pdv_id.length > 0) {
        let text = ''
        pdv_id.forEach((el: any) => (text = text === '' ? `${el.value}` : text + `,${el.value}`))
        obj = { ...obj, pdv: text }
        dataTag = { ...dataTag, pdv_id }
        filterQueryUrl.pdv_id = JSON.stringify(pdv_id)
      }

      if (sub_workspaces && sub_workspaces.length > 0) {
        let text = ''
        sub_workspaces.forEach((el: any) => (text = text === '' ? `${el.value}` : text + `,${el.value}`))
        obj = { ...obj, sub_workspaces: text }
        dataTag = { ...dataTag, sub_workspaces }
        filterQueryUrl.sub_workspaces = JSON.stringify(sub_workspaces)
      }

      if (network_id && network_id.length > 0) {
        let text = ''
        network_id.forEach((el: any) => (text = text === '' ? `${el.value}` : text + `,${el.value}`))
        obj = { ...obj, network: text }
        dataTag = { ...dataTag, network_id }
        filterQueryUrl.network_id = JSON.stringify(network_id)
      }

      if (form_id && form_id.length > 0) {
        let text = ''
        form_id.forEach((el: any) => (text = text === '' ? `${el.value}` : text + `,${el.value}`))
        obj = { ...obj, form: text }
        dataTag = { ...dataTag, form_id }
        filterQueryUrl.form_id = JSON.stringify(form_id)
      }
      if (question_id && question_id.length > 0) {
        let text = ''
        question_id.forEach((el: any) => (text = text === '' ? `${el.value}` : text + `,${el.value}`))
        obj = { ...obj, question: text }
        dataTag = { ...dataTag, question_id }
        filterQueryUrl.question_id = JSON.stringify(question_id)
      }
      if (state && state.length > 0) {
        let text = ''
        state.forEach((el: any) => (text = text === '' ? `${el}` : text + `,${el}`))
        obj = { ...obj, state: text }
        dataTag = { ...dataTag, state }
        filterQueryUrl.state = JSON.stringify(state)
      }

      if (dateRange) {
        let start_date = moment(dateRange[0]).format('YYYY-MM-DD').toString()
        let end_date = moment(dateRange[1]).format('YYYY-MM-DD').toString()
        obj = { ...obj, start_date, end_date }
        dataTag = { ...dataTag, start_date: start_date, end_date: end_date }
        filterQueryUrl.dateRange = JSON.stringify(dateRange)
      }

      const query = qs.stringify(obj)
      const queryFilterUrl = qs.stringify({ ...filterQueryUrl })

      const { data } = await api_v2.get(`/pictures?${query}`)
      setFilterUrl(obj)
      setDataPicture({ ...data, loading: false })
      setVisible(false)
      setFilterTag(dataTag)
      setLoading(false)
      history.push(`/backoffice/reports/pictures?${queryFilterUrl}`)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    let obj: any = qs.parse(location.search.replaceAll('?', ''))
    if (obj && obj.flag_id) obj.flag_id = JSON.parse(obj.flag_id)
    if (obj && obj.promoter_id) obj.promoter_id = JSON.parse(obj.promoter_id)
    if (obj && obj.pdv_id) obj.pdv_id = JSON.parse(obj.pdv_id)
    if (obj && obj.sub_workspaces) obj.sub_workspaces = JSON.parse(obj.sub_workspaces)
    if (obj && obj.network_id) obj.network_id = JSON.parse(obj.network_id)
    if (obj && obj.form_id) obj.form_id = JSON.parse(obj.form_id)
    if (obj && obj.question_id) obj.question_id = JSON.parse(obj.question_id)
    if (obj && obj.state) obj.state = JSON.parse(obj.state)

    getDataPictures(obj)

    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [location.search])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePage = async (current: any, perpage: any) => {
    try {
      setLoading(true)
      let obj: any = {
        ...filterUrl,
        perpage: perpage,
        page: current,
      }

      const query = qs.stringify(obj)
      const { data } = await api_v2.get(`/pictures?${query}`)
      setDataPicture({ ...data, loading: false })
      setLoading(false)

      window.scrollTo(0, 0)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const itemRender = (current: any, type: any, originalElement: any) => {
    if (type === 'prev') {
      return (
        <div style={{ display: 'flex', alignItems: 'center', color: '#6f7782' }}>
          <CaretLeftOutlined style={{ marginRight: 10 }} />
          <p style={{ margin: 0 }}>anterior</p>
        </div>
      )
    }
    if (type === 'next') {
      return (
        <div style={{ display: 'flex', alignItems: 'center', color: '#6f7782' }}>
          <p style={{ margin: 0 }}>próximo</p>
          <CaretRightOutlined style={{ marginRight: 10 }} />
        </div>
      )
    }
    return originalElement
  }

  const cleanFilter = async () => {
    history.push(`/backoffice/reports/pictures`)
    setDateRange([
      moment(new Date(), dateFormat).subtract(7, 'd'),
      moment(new Date(), dateFormat),
    ])
    setVisible(false)
    setFilterTag({})
    formRef!.current!.reset()
    getDataPictures({})
  }


  return (
    <ContainerList>
      {dataPictures.loading && <Loading />}
      <Row gutter={[8, 8]} justify='space-between'>
        {loading ? <Loading /> : null}
        <Col span={18}>
          <h1>Fotos</h1>
          <Breadcrumb className='breadcrumb'>
            <Link to='/backoffice/reports'>
              <Breadcrumb.Item>Relatórios</Breadcrumb.Item>
            </Link>
            <Breadcrumb.Item>Fotos</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        {hasIndex(['pictures.export_pictures_ppt'], permissions) > -1 && (
          <Col>
            <AddButton onClick={() => setOpenPPT(true)}>
              <CloudDownloadOutlined style={{ fontSize: 20 }} />
              Exportar PPT
            </AddButton>
          </Col>
        )}
        <Col>
          <Tooltip placement='top' title={'Filtrar'}>
            <BigButton onClick={() => setVisible(!visible)} style={{ padding: 9 }}>
              <FilterOutlined />
            </BigButton>
          </Tooltip>
        </Col>
        <Col span={24}>
          <ContainerTagg>
            {filterTag.pdv_id &&
              filterTag.pdv_id.map((el: any) => (
                <Tag color='geekblue' onClick={() => removeFilter('pdv_id', el.value)}>
                  {el.label} <CloseOutlined />
                </Tag>
              ))}

            {filterTag.promoter_id &&
              filterTag.promoter_id.map((el: any) => (
                <Tag color='geekblue' onClick={() => removeFilter('promoter_id', el.value)}>
                  {el.label} <CloseOutlined />
                </Tag>
              ))}

            {filterTag.sub_workspaces &&
              filterTag.sub_workspaces.map((el: any) => (
                <Tag color='geekblue' onClick={() => removeFilter('sub_workspaces', el.value)}>
                  {el.label} <CloseOutlined />
                </Tag>
              ))}
            {filterTag.flag_id &&
              filterTag.flag_id.map((el: any) => (
                <Tag color='geekblue' onClick={() => removeFilter('flag_id', el.value)}>
                  {el.label} <CloseOutlined />
                </Tag>
              ))}
            {filterTag.network_id &&
              filterTag.network_id.map((el: any) => (
                <Tag color='geekblue' onClick={() => removeFilter('network_id', el.value)}>
                  {el.label} <CloseOutlined />
                </Tag>
              ))}
            {filterTag.state &&
              filterTag.state.map((el: any) => (
                <Tag color='geekblue' onClick={() => removeFilter('state', el)}>
                  {el} <CloseOutlined />
                </Tag>
              ))}

            {filterTag.start_date && (
              <Tag color='geekblue' onClick={() => removeFilter('start_date', `${filterTag.start_date}`)}>
                {moment(filterTag.start_date).format('DD/MM/YYYY')}
                <CloseOutlined />
              </Tag>
            )}
            {filterTag.end_date && (
              <Tag color='geekblue' onClick={() => removeFilter('end_date', `${filterTag.end_date}`)}>
                {moment(filterTag.end_date).format('DD/MM/YYYY')}
                <CloseOutlined />
              </Tag>
            )}
            {filterTag.form_id &&
              filterTag.form_id.map((el: any) => (
                <Tag color='geekblue' onClick={() => removeFilter('form_id', el.value)}>
                  {el.label} <CloseOutlined />
                </Tag>
              ))}
            {filterTag.question_id &&
              filterTag.question_id.map((el: any) => (
                <Tag color='geekblue' onClick={() => removeFilter('question_id', el.value)}>
                  {el.label} <CloseOutlined />
                </Tag>
              ))}
          </ContainerTagg>
        </Col>
      </Row>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          marginTop: 30,
          paddingLeft: 30,
        }}
      >
        {dataPictures.result && dataPictures.result.length > 0 ? (
          <Row gutter={[30, 30]} justify='center'>
            {dataPictures.result.length > 0 &&
              dataPictures.result.map((el: any) => (
                <Col>
                  <CardPicture data={el} />
                </Col>
              ))}
            <Col span={24}>
              <ContainerPaginationFooter>
                <Pagination
                  defaultCurrent={dataPictures.page}
                  total={dataPictures.total}
                  onChange={handlePage}
                  itemRender={itemRender}
                />
              </ContainerPaginationFooter>
            </Col>
          </Row>
        ) : (
          <NoSearch text='Não encontramos nenhum dado aqui.' />
        )}
      </div>

      {loading && <Loading />}

      <DrawerFilter
        visible={visible}
        close={() => {
          setVisible(!visible)
          setSelectQuestion('')
        }}
        title='Fotos'
        footer={
          <ContainerButtonsFootter>
            <ButtonSearch type='button' onClick={() => formRef?.current?.submitForm()}>
              <FileSearchOutlined />
              Pesquisar
            </ButtonSearch>
            <ButtonClear onClick={() => cleanFilter()}>
              <ClearOutlined />
              Limpar
            </ButtonClear>
          </ContainerButtonsFootter>
        }
      >
        <ContainerFormFilter
          tabIndex={0}
          onKeyDown={(evt: any) => {
            if (evt.key === 'Enter') formRef?.current?.submitForm()
          }}
        >
          <Form ref={formRef} className='form' style={{ marginTop: '15px' }} onSubmit={getDataPictures}>
            <GroupForm>
              <LabelForm>Selecione a loja</LabelForm>
              <Search placeholder='Selecione o PDV' name='pdv_id' path='pdvs' isMult={true} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione o promotor</LabelForm>
              <Search placeholder='Selecione o promotor' name='promoter_id' path='promoters' isMult={true} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione a bandeira</LabelForm>
              <Search placeholder='Selecione a bandeira' name='flag_id' path='flags' isMult={true} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione a rede</LabelForm>
              <Search placeholder='Selecione a rede' name='network_id' path='networks' isMult={true} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione o formulário</LabelForm>
              <Search placeholder='Selecione o formulário' name='form_id' path='forms-pictures' isMult={true} blur={(e: any) => {
                let text = ''
                e.forEach((el: any) => (text = text === '' ? `${el.value}` : text + `,${el.value}`))
                setSelectQuestion(text)
                }}/>
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione a questão</LabelForm>
              <GroupSearch placeholder='Selecione a questão' name='question_id' path='questions' isMult={true} config={`${selectQuestion ?  `&forms=${selectQuestion}` : ''}&start_date=${moment(dateRange[0]).format('YYYY-MM-DD').toString()}&end_date=${moment(dateRange[1]).format('YYYY-MM-DD').toString()}`} />
            </GroupForm>
            {newGetWorkspace && newGetWorkspace.shared_environment && (
              <>
                <LabelForm>Selecione o sub workspace</LabelForm>
                <Search
                  placeholder='Selecione o sub workspace'
                  name='sub_workspaces'
                  path='sub-workspaces'
                  isMult={true}
                />
              </>
            )}
            <GroupForm>
              <LabelForm>Selecione os estados</LabelForm>
              <SimpleSelectObject placeholder={'Selecione o estado'} name='state' options={optionUF} isMulti={true}/>
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione o periodo </LabelForm>
              <DatePicker.RangePicker
                locale={locale}
                defaultValue={dateRange}
                format={dateFormat}
                ranges={{
                  Hoje: [moment(), moment()],
                  'Esta semana': [moment().startOf('week'), moment().endOf('week')],
                  'Este mês': [moment().startOf('month'), moment().endOf('month')],
                }}
                onChange={(val: any) => setDateRange(val)}
              />
            </GroupForm>
          </Form>
        </ContainerFormFilter>
      </DrawerFilter>
      <ModalExportPPT visible={openPPT} close={setOpenPPT} dataParam={{ ...param, dateRange }} />
    </ContainerList>
  )
}

export default Pictures
