export const names = [
  'Aaren',
  'Aarika',
  'Abagael',
  'Abagail',
  'Abbe',
  'Abbey',
  'Abbi',
  'Abbie',
  'Abby',
  'Abbye',
  'Abigael',
  'Abigail',
  'Abigale',
  'Abra',
  'Ada',
  'Adah',
  'Adaline',
  'Adan',
  'Adara',
  'Adda',
  'Addi',
  'Addia',
  'Addie',
  'Addy',
  'Adel',
  'Adela',
  'Adelaida',
  'Adelaide',
  'Adele',
  'Adele',
  'Raymundo'
]

export const scripts = [
  'Semanal',
  'Quinzenal',
  'Mensal',
  'Diário',
]

let network = [
  'KALUNGA',
  'MAUAMI',
  'MIAMOR',
  'LIVRARIA DA VILA',
  'PAPELARIA',
  'LOJAS AMERICANAS',
  'KALUNGA',
  'KALUNGA',
  'KALUNGA',
  'KALUNGA',
  'KALUNGA',
  'KALUNGA',
  'KALUNGA',
  'KALUNGA',
  'KALUNGA',
  'KALUNGA',
  'LEBISCUIT - BRASIL',
  'GPA - BRASIL',
  'LASA - BR',
  'MAGAZINE LUIZA BRASIL',
  'VIA VAREJO - BRASIL',
  'WAL-MART - BRASIL',
  'CARREFOUR - BRASIL',
  'TESTE',
  'BIG BRASIL',
  'VAMO VE',
  'ASSAI',
  'ATACADAO',
  'COOP',
  'INTERVALO - HORÁRIO DE ALMOCO - SPC',
]

let storePoint = [
"KIT EPI - QUESTIONÁRIO NORDESTE",
"SAMS CLUB - AVENIDA DAO SILVEIRA",
"CLUB PHARMA - AVENIDA ENGENEHIRO ROBERTO FREIRE, 550",
"PAGUE MENOS - AV. ENGENHEIRO ROBERTO FREIRE,340 LOJA 21",
"DROGARIA GLOBO - AVENIDA ENGENHEIRO ROBERTO FREIRE, 630",
"EXTRAFARMA - ENGENHEIRO ROBERTO FREIRE, 1026",
"PAGUE MENOS - ENGENHEIRO ROBERTO FREIRE, 1508",
"INTERVALO - NORDESTE",
"PAGUE MENOS - ENGENHEIRO ROBERTO FREIRE, 8966",
"DROGASIL - PRAIA DE PONTA NEGRA, 8936",
"FARMACIARIA - AVENIDA PRAIA DE PONTA NEGRA,8964",
"PAGUE MENOS - ENGENHEIRO ROBERTO FREIRE, 3091",
"PAGUE MENOS - PROFESSOR MANOEL VILAR, 27",
"PAGUE MENOS - AVENIDA SALGADO FILHO, 2234 LOJA 139",
"EXTRAFARMA - SENADOR SALGADO FILHO, 2234",
"CARREFOUR HIPER - ROD BR 101, S/N KM 02",
"KIT EPI - QUESTIONÁRIO SUDESTE",
"DROGAO SUPER - AVENIDA CAMPOS SALLES, 778",
"DROGARIA FAMILIAR - AVENIDA CAMPOS SALLES, 517",
"DROGASIL - RUA CONCEICAO, 664",
"CARREFOUR HIPER - AVENIDA ENG ANTONIO F DE PAULA SOUZA, 3900",
"DROGARIA CAPELA DE VINHEDO - RUA NOSSA SENHORA DE LOURDES, 90",
"FUNAYAMA & SCUDELLARI - RUA NICOLAU VON ZUBEM 400",
"RAIA - AVENIDA BRASIL, 910",
"DROGARIAS MAESTRA - AVENIDA DOS IMIGRANTES, 214",
"INTERVALO - SUDESTE",
"DSP - AVENIDA DOS IMIGRANTES, 299",
"DROGAL - AVENIDA DOS IMIGRANTES, 465",
"DROGARIA BARRA FUNDA - AVENIDA INDEPENDENCIA 5538",
"DROGASIL - PC SANT'ANA, 10",
"KIT EPI - QUESTIONÁRIO SUL",
"NISSEI - SENADOR ABILON DE SOUZA NAVES, 300",
"RAIA - BRASIL, 1071",
"ATACADAO - RUA FERNAO DIAS, 300",
"NISSEI - AVENIDA COLOMBO, 317",
"NISSEI - MORANGUEIRA, 1578",
"RAIA - OURO PRETO, 36",
"INTERVALO - SUL",
"NISSEI - PEDRO TAQUES, 1916",
"ATACADAO - AV. COLOMBO, 67",
"KIT EPI - QUESTIONÁRIO SUL",
"DROGARIA CATARINENSE - RUA DOUTOR JOAO COLIN, 2950",
"ANGELONI - RUA DOUTOR JOAO COLIN, 2500",
"RAIA - RUA DOUTOR JOAO COLIN, 2641",
"DROGARIA CATARINENSE - RUA DOUTOR JOAO COLIN, 1865",
"SESI - DOUTOR JOAO COLIN, 1146",
"SESI - RUA DR JOAO COLIN, 222",
"PANVEL - RUA DO PRINCIPE, 177",
"INTERVALO - SUL",
"ATACADAO - ROD BR 101,",
"DROGARIA CATARINENSE - RUA BLUMENAU, 138",
"DROGARIA CATARINENSE - RUA NOVE DE MARCO, 638",
"KIT EPI - QUESTIONÁRIO NORTE",
"PAGUE MENOS - AVENIDA VISC DE SOUZA FRANCO, 1185",
"DROGASIL - AVENIDA VISCONDE DE SOUZA FRANCO, 863",
"MAXI POPULAR - RUA ANTONIO BARRETO, 127",
"PAGUE MENOS - RUA ANTÔNIO BARRETO, 186",
"EXTRAFARMA - RUA ANTONIO BARRETO, 127 B",
"INTERVALO - NORTE",
"EXTRAFARMA - AVENIDA ANTONIO BARRETO, 1320",
"EXTRAFARMA - AVENIDA SENADOR LEMOS, 175",
"EXTRAFARMA - AVENIDA VISCONDE DE SOUZA FRANCO, 776",
"EXTRAFARMA - AVENIDA VISCONDE DE SOUZA FRANCO, 558",
"DROGARIA GLOBO - AVENIDA VISCONDE DE SOUZA FRANCO, 1001",
"KIT EPI - QUESTIONÁRIO SUDESTE"
]

let typePdv = [
  "FARMA RED",
  "INTERVALO",
  "PROTEÇÃO",
  "PREVENÇÃO",
  "CASH & CARRY"
]

let pdv_chanel = [
  "DIRETO",
  "INDIRETO"
]

let cidades = [
  "Acrelândia",
  "Assis Brasil",
  "Brasiléia",
  "Bujari",
  "Capixaba",
  "Cruzeiro do Sul",
  "Epitaciolândia",
  "Feijó",
  "Jordão",
  "Mâncio Lima",
  "Manoel Urbano",
  "Marechal Thaumaturgo",
  "Plácido de Castro",
  "Porto Acre",
  "Porto Walter",
  "Rio Branco",
  "Rodrigues Alves",
  "Santa Rosa do Purus",
  "Sena Madureira",
  "Senador Guiomard",
  "Tarauacá",
  "Xapuri",
  "Água Branca",
  "Anadia",
  "Arapiraca",
  "Atalaia",
  "Barra de Santo Antônio",
  "Barra de São Miguel",
  "Batalha",
  "Belém",
  "Belo Monte",
  "Boca da Mata",
  "Branquinha",
  "Cacimbinhas",
  "Cajueiro",
  "Campestre",
  "Campo Alegre",
  "Campo Grande",
  "Canapi",
  "Capela",
  "Carneiros",
  "Chã Preta",
  "Coité do Nóia",
  "Colônia Leopoldina",
  "Coqueiro Seco",
  "Coruripe",
  "Craíbas",
  "Delmiro Gouveia",
  "Dois Riachos",
  "Estrela de Alagoas",
  "Feira Grande",
  "Feliz Deserto",
  "Flexeiras",
  "Girau do Ponciano",
  "Ibateguara",
  "Igaci",
  "Igreja Nova",
  "Inhapi",
  "Jacaré dos Homens",
  "Jacuípe",
  "Japaratinga",
  "Jaramataia",
  "Jequiá da Praia",
  "Joaquim Gomes",
  "Jundiá",
  "Junqueiro",
  "Lagoa da Canoa",
  "Limoeiro de Anadia",
  "Maceió",
  "Major Isidoro",
  "Mar Vermelho",
  "Maragogi",
  "Maravilha",
  "Marechal Deodoro",
  "Maribondo",
  "Mata Grande",
  "Matriz de Camaragibe",
  "Messias",
  "Minador do Negrão",
  "Monteirópolis",
  "Murici",
  "Novo Lino",
  "Olho d'Água das Flores",
  "Olho d'Água do Casado",
  "Olho d'Água Grande",
  "Olivença",
  "Ouro Branco",
  "Palestina",
  "Palmeira dos Índios",
  "Pão de Açúcar",
  "Pariconha",
  "Paripueira",
  "Passo de Camaragibe",
  "Paulo Jacinto",
  "Penedo",
  "Piaçabuçu",
  "Pilar",
  "Pindoba",
  "Piranhas",
  "Poço das Trincheiras",
  "Porto Calvo",
  "Porto de Pedras",
  "Porto Real do Colégio",
  "Quebrangulo",
  "Rio Largo",
  "Roteiro",
  "Santa Luzia do Norte",
  "Santana do Ipanema",
  "Santana do Mundaú",
  "São Brás",
  "São José da Laje",
  "São José da Tapera",
  "São Luís do Quitunde",
  "São Miguel dos Campos",
  "São Miguel dos Milagres",
  "São Sebastião",
  "Satuba",
  "Senador Rui Palmeira",
  "Tanque d'Arca",
  "Taquarana",
  "Teotônio Vilela",
  "Traipu",
  "União dos Palmares",
  "Viçosa"
]

const UFS = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO',
]

const time = [
  '09:28:09',
  '09:28:10',
  '09:28:12',
  '09:28:12',
  '09:28:58',
  '09:29:04',
  '09:29:05',
  '09:29:08',
  '09:33:43',
  '09:40:08',
  '09:40:12',
  '09:40:12',
  '09:41:25',
  '09:41:56',
  '09:42:26',
  '09:42:46',
  '09:42:53',
  '09:43:23',
  '09:43:26',
  '09:43:29',
  '09:43:29',
  '09:57:37',
  '10:00:35',
  '10:00:48',
  '10:06:18',
  '10:06:22',
  '10:06:23',
  '10:08:00',
  '10:08:00',
  '10:08:00',
]

export const randonNameTable = () => {
  let value = Math.floor(Math.random() * ((names.length - 1) - 1 + 1) + 1)
  return names[value]
}

export const randonScriptsTable = () => {
  let value = Math.floor(Math.random() * ((scripts.length - 1) - 1 + 1) + 1)
  return scripts[value]
}

export const randonNetworkTable = () => {
  let value = Math.floor(Math.random() * ((network.length - 1) - 1 + 1) + 1)
  return network[value]
}

export const randonIdTable = () => {
  return Math.floor(Math.random() * (10000 - 1000 + 1) + 1000)
}

export const randonStorePointTable = () => {
  let value = Math.floor(Math.random() * ((storePoint.length - 1) - 1 + 1) + 1)
  return storePoint[value]
}

export const randonTypePdvTable = () => {
  let value = Math.floor(Math.random() * ((typePdv.length - 1) - 1 + 1) + 1)
  return typePdv[value]
}

export const randonPdvChanelTable = () => {
  let value = Math.floor(Math.random() * ((pdv_chanel.length - 1) - 1 + 1) + 1)
  return pdv_chanel[value]
}

export const randonCitiesTable = () => {
  let value = Math.floor(Math.random() * ((cidades.length - 1) - 1 + 1) + 1)
  return cidades[value]
}

export const randonUfTable = () => {
  let value = Math.floor(Math.random() * ((UFS.length - 1) - 1 + 1) + 1)
  return UFS[value]
}

export const randonTimeTable = () => {
  let value = Math.floor(Math.random() * ((time.length - 1) - 1 + 1) + 1)
  return time[value]
}
