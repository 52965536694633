import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    border-radius: 12px;
    border: 1px solid #d6d6d6;
    background-color: #f9f9f9;
    padding: 21px;
`

export const ContainerHeader = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const ContainerUser = styled.div`
    display: flex;
    align-items: center;

    img {
        margin-right: 13px;
        width: 36px;
        height: 36px;
        border-radius: 20px;
    }

    .giftFlag {
        width: 32px;
        height: 32px;
    }
`

export const ContainerStatus = styled.div``

export const TextBlack = styled.p`
    color: #242424;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 600;
    margin-right: 20px;
`
export const ContainerOff = styled.div`
    display: flex;
    align-items: center;
`

export const CirculeStatus = styled.div`
    width: 10px;
    height: 10px;
    background-color: #e9830f;
    border-radius: 5px;
`

export const TextOff = styled.p`
    color: #6f7782;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: italic;
    margin-bottom: 0;
    margin-left: 10px;
`
export const ContainerTasks = styled.div`
    width: 100%;
    margin-top: 20px;
`

export const Tasks = styled.div`
    display: flex;
    padding: 5px;
    margin-top: 5px;
`

export const TextTask = styled.p`
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 0;
`

export const TextPdv = styled.p`
    margin-bottom: 0;
    color: #6f7782;
    font-family: "Open Sans";
    font-size: 16px;
`

export const StatusRealizado = styled.div`
  width: 87px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid #00C49F;
  background-color: rgba(0, 196, 159, 0.5);
  color: #00C49F;
  font-family: "Open Sans";
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`

export const StatusInProgress = styled.div`
  color: #FFBB28;
  font-family: "Open Sans";
  font-size: 12px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid #FFBB28;
  background-color: rgba(255, 187, 40, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`

export const StatusPendente = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  color: #0088FE;
  font-family: "Open Sans";
  font-size: 12px;
  width: 82px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid #0088FE;
  background-color: rgba(0, 136, 254, 0.21);
`
export const StatusExpired = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  color: #FF8042;
  font-family: "Open Sans";
  font-size: 12px;
  width: 64px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid #FF8042;
  background-color: rgba(233, 131, 15, 0.27);
`

export const TextLight = styled.p`
    color: #6f7782;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 400;
`