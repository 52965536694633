import React, { useEffect, useState } from 'react'
import { CardCreate, ContainerModal } from './style'
import { CopyFilled } from '@ant-design/icons'
import { Select, message } from 'antd'
import { Loading } from '../Loading'
import { ContainerLogo } from '../ModalSugestion/style'
import { ButtonBack } from '../../pages/Team/ViewUser/styles'
import api_v2 from '../../services/api-v2'
import { Mixpanel } from '../../utils/mixpanel'
import api from '../../services/api'

interface IProps {
  visible: boolean
  close: (boolean: any) => void
  form: any
}

export const ModalCopyForm = ({ visible, close, form }: IProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [selecteds, setSelecteds] = useState<any[]>([])
  const [options, setOptions] = useState<any[]>([])

  const handleSubmit = async () => {
    try {
      setLoading(true)
      await api_v2.put(`/form/copy-form/${form.id}`, selecteds)
      Mixpanel.track('Copiou formularios', {
        params: {
          id: form.id,
          ambientes: selecteds,
        },
      })
      message.success('Sucesso ao copiar formulário.')

      setLoading(false)
      close(!visible)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    async function getWorks() {
      try {
        const { data } = await api.get('/workspace')
        setOptions(data.result.map((item: any) => ({ label: item.name, value: item.id })))
      } catch (err) {
        console.log(err)
      } finally {
      }
    }
    getWorks()
    setSelecteds([])
  }, [visible])

  return (
    <ContainerModal
      visible={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
      destroyOnClose={true}
    >
      {loading && <Loading />}
      <CardCreate style={{ width: '100%', height: '100%' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '45%',
          }}
        >
          <h4>Copiar formulário {form.name}</h4>
          <ContainerLogo style={{ marginTop: 20, marginBottom: 50 }}>
            <CopyFilled />
          </ContainerLogo>
        </div>
        <div>
          <p>Deseja copiar esse formulário para quais ambientes ?</p>
          <Select
            mode='multiple'
            allowClear
            style={{ width: '100%' }}
            onChange={(values) => setSelecteds(values)}
            options={options}
            filterOption={(input, option) => (option?.label.toUpperCase() ?? '').includes(input.toUpperCase())}
          />
          <ButtonBack
            style={{ width: '100%', backgroundColor: '#b56aff', color: '#fff', margin: 0, marginTop: 10 }}
            type='button'
            onClick={() => handleSubmit()}
          >
            Copiar
          </ButtonBack>
        </div>
      </CardCreate>
    </ContainerModal>
  )
}
