import React, { useState } from 'react'
import { ContainerModal, ContainerStep2, ContainerBodyModal, ContainerAvatar } from './style'
import { ContainerLogo } from '../ModalSugestion/style'
import { WarningOutlined, MoreOutlined, ExceptionOutlined, PlusOutlined } from '@ant-design/icons'
import { Alert, Avatar, Button, Dropdown, Menu, Tabs, Tooltip, message } from 'antd'
import { ModalCreateVisit } from '../ModalCreateVisit'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { addIsVisit } from '../../utils/addScript'

interface IProps {
  visible: boolean
  close: (boolean: any) => void
  users: any[]
  usersScripts: any[]
  reloadApi: any
  defaultStep?: string
}

export const ModalUsersNoTask = ({ visible, close, users, reloadApi, defaultStep, usersScripts }: IProps) => {
  const [openModalVisit, setOpenModalVisit] = useState(false)
  const [selectUser, setSelectUser] = useState<number>(0)

  return (
    <ContainerModal
      visible={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
      destroyOnClose={true}
    >
      <Tabs defaultActiveKey={defaultStep}>
        <Tabs.TabPane tab="Visitas" key="VISITS">
          <ContainerStep2>
            <h3>Visualizar usuário sem visitas para hoje</h3>
            <ContainerLogo style={{ marginBottom: 10, width: 60, height: 60, borderRadius: 30 }}>
              <WarningOutlined />
            </ContainerLogo>
            <ContainerBodyModal>
              {users &&
                users.length > 0 &&
                users.map((el: any) => (
                  <ContainerAvatar>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Avatar size={34} style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
                        {el.name.substring(0, 1)}
                      </Avatar>
                      <h3 style={{ marginLeft: 10, fontSize: 16, marginBottom: 0 }}>{el.name}</h3>
                    </div>
                    <Tooltip placement='topRight' title={'Ações'}>
                      <Dropdown
                        overlay={
                          <Menu>
                            <Menu.Item
                              key='0'
                              icon={<PlusOutlined />}
                              onClick={() => {
                                if(!addIsVisit()) return message.error('Sem autorização')
                                setSelectUser(el)
                                setOpenModalVisit(true)
                              }}
                            >
                              Adicionar visita
                            </Menu.Item>
                            <Link
                              to={`/backoffice/teamById/${el.id}?date_start=${moment().format(
                                'YYYY-MM-DD'
                              )}&date_end=${moment().format('YYYY-MM-DD')}`}
                              onClick={() =>
                                window.sessionStorage.setItem(
                                  'tabRocket',
                                  JSON.stringify({ user: el.id.toString(), key: '3' })
                                )
                              }
                            >
                              <Menu.Item key='2' icon={<ExceptionOutlined />}>
                                Roteiros
                              </Menu.Item>
                            </Link>
                          </Menu>
                        }
                      >
                        <Button size='small' shape='circle' icon={<MoreOutlined />} />
                      </Dropdown>
                    </Tooltip>
                  </ContainerAvatar>
                ))}
            </ContainerBodyModal>
          </ContainerStep2>
          <ModalCreateVisit
            visible={openModalVisit}
            close={setOpenModalVisit}
            type={'PROMOTER'}
            stepJump={true}
            promoter={selectUser}
            reloadApi={(bool: any) => reloadApi(bool)}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Roteiro" key="SCRIPTS">
        <ContainerStep2>
            <h3>Visualizar usuário sem roteiro para hoje</h3>
            <ContainerLogo style={{ marginBottom: 10, width: 60, height: 60, borderRadius: 30 }}>
              <WarningOutlined />
            </ContainerLogo>
            <Alert message="Usuários que possuem visita avulsa, mas não possuem roteiro irão aparecer nessa lista." type="warning" />

            <ContainerBodyModal>
              {usersScripts &&
                usersScripts.length > 0 &&
                usersScripts.map((el: any) => (
                  <ContainerAvatar>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Avatar size={34} style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
                        {el.name.substring(0, 1)}
                      </Avatar>
                      <h3 style={{ marginLeft: 10, fontSize: 16, marginBottom: 0 }}>{el.name}</h3>
                    </div>
                    <Tooltip placement='topRight' title={'Ações'}>
                      <Dropdown
                        overlay={
                          <Menu>
                            <Link
                              to={`/backoffice/teamById/${el.id}?date_start=${moment().format(
                                'YYYY-MM-DD'
                              )}&date_end=${moment().format('YYYY-MM-DD')}`}
                              onClick={() =>
                                window.sessionStorage.setItem(
                                  'tabRocket',
                                  JSON.stringify({ user: el.id.toString(), key: '3' })
                                )
                              }
                            >
                              <Menu.Item key='2' icon={<ExceptionOutlined />}>
                                Roteiros
                              </Menu.Item>
                            </Link>
                          </Menu>
                        }
                      >
                        <Button size='small' shape='circle' icon={<MoreOutlined />} />
                      </Dropdown>
                    </Tooltip>
                  </ContainerAvatar>
                ))}
            </ContainerBodyModal>
          </ContainerStep2>
        </Tabs.TabPane>
      </Tabs>

    </ContainerModal>
  )
}
