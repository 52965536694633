import React from 'react'
import {
  Container,
  ContainerHeader,
  ContainerUser,
  ContainerStatus,
  Name,
  ContainerOff,
  CirculeStatus,
  TextOff,
  ContainerTasks,
  Tasks,
  TextTask,
  TextPdv,
} from './style'
import { Divider, Tooltip } from 'antd'
import { MdStoreMallDirectory, MdCheck } from 'react-icons/md'

interface IProps {
  data: any
  status: string
  open: () => void
}

export const CardStatusKanban = ({ data, status, open }: IProps) => {
  return (
    // <Container onClick={() => window.open(`/backoffice/teamById/${data.promoter_id}`, '_blank')}>
    <Container onClick={() => open()}>
      <ContainerHeader>
        <ContainerUser>
          <img
            src={`https://via.placeholder.com/150/ff872c/fff/?text=${data.promoter_name?.substr(0, 1)}`}
            alt='name'
          />
          <ContainerStatus>
            <Name>{data.promoter_name}</Name>
            <Tooltip title='Ultima sincronização'>
              <p style={{ fontSize: 11, fontStyle: 'italic' }}>
                {data.promoter_last_change !== null && data.promoter_last_change}
              </p>
            </Tooltip>
            {status === 'Sem Interação' ? (
              <ContainerOff>
                <CirculeStatus />
                <TextOff>Offline</TextOff>
              </ContainerOff>
            ) : (
              <ContainerOff>
                <CirculeStatus style={{ backgroundColor: '#16c266' }} />
                <TextOff>Online</TextOff>
              </ContainerOff>
            )}
          </ContainerStatus>
        </ContainerUser>
        {/* <Button
          style={{
            display: 'flex',
            alignItems: 'center',
            borderRadius: 5,
            color: '#6f7782',
          }}
        >
          <MdMoreHoriz size={20} />
        </Button> */}
      </ContainerHeader>
      <ContainerTasks>
        <Tasks>
          <MdStoreMallDirectory size={20} />
          <TextTask>{data.visits_all} Lojas</TextTask>
        </Tasks>
        <Tasks>
          <MdCheck size={20} />
          <TextTask>{data.visits_done} Concluídas</TextTask>
        </Tasks>
      </ContainerTasks>
      {status === 'Em loja' && (
        <>
          <Divider />
          <TextPdv>{data.pdv_name}</TextPdv>
        </>
      )}
    </Container>
  )
}
