import styled from 'styled-components'
import { Switch as SwitchEdit } from 'antd'

interface PropsSwitch {
  color: string
}

export const Switch = styled(SwitchEdit)<PropsSwitch>`
  &&& .ant-switch {
    background: #b570fc !important;
  }

  &&& .ant-switch-handle::before {
    background-color: ${({ color }) => color};
  }
`

export const Label = styled.div`
  font-size: 20px;
  font-weight: bold;
`

export const GroupForm = styled.div`
  background-color: #eee;
  border: 1px solid #ededed;
  padding: 10px;
  border-radius: 5px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100px;
  box-shadow: #cbcbcb -1px 0px 10px;
`

export const Container = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;

  h1,
  p {
    padding: 0px 10px;
  }

  form {
    margin-top: 30px;
    font-weight: 600;
  }
  button {
    text-align: center;
    margin-top: 10px;
  }
  label {
    padding: 5px 0px;
    font-weight: 600;
  }
  input,
  select {
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    height: 38px;
    padding: 0 15px;
    color: rgba(0, 0, 0, 0.65);
    margin: 0 0 10px;
    width: 100%;
    display: inline-block;

    &::placeholder {
      color: #d9d9d9;
    }
    &:hover,
    &:focus {
      border-color: #1890ff;
    }
  }
`

export const ButtonDesiner = styled.div`
  button {
    display: flex;
    align-items: center;
  }
  svg {
    margin-right: 8px;
  }
`

export const BtnExport = styled.a`
  padding: 10px;
  color: #fff;

  &:hover {
    color: #fff;
  }
`
export const ContainerPaginationFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;

  &&& .ant-pagination-item-active {
    border-color: #b56aff !important;
    width: 40px !important;
    height: 40px !important;
    background-color: #b56aff;
  }
  &&& .ant-pagination-item-active a {
    color: #fff !important;
    margin-top: 3px;
  }

  &&& .ant-pagination-item {
    width: 40px !important;
    height: 40px !important;
  }

  &&& .ant-pagination-item a {
    margin-top: 3px;
    color: #6f7782;
  }
`

export const ContainerSelect = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  &&& .ant-select {
    width: 70px;
  }

  &&& .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: #6f7782 1px solid;
    border-radius: 7px;
  }

  &&& .ant-select-selection-item {
    color: #6f7782;
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 400;
  }

  &&& .ant-select-arrow {
    font-size: 16px;
    color: #6f7782;
  }
`

export const ContainerForm = styled.div`
  &&& .ant-modal-close-x {
    color: #ef4926 !important;
  }

  &&& .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none !important;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.65);
    height: 38px;
  }

  &&& .ant-select {
    height: 38px !important;
  }
`

export const ContainerIconPrimary = styled.div`
  margin: 15px auto;
  border-radius: 50%;
  width: 72px;
  height: 72px;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: #5c07b7;
    font-size: 25px;
  }
`

export const Title = styled.p`
  background-color: #fff;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  width: 100%;
`

export const ContainerTagg = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 30px;

  &&& .ant-tag,
  .ant-tag a,
  .ant-tag a:hover {
    padding: 5px;
    cursor: pointer;

    svg {
      margin-left: 10px;
      color: red;
    }
  }
`
