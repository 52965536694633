import styled from 'styled-components'
import { Modal } from 'antd'

export const ContainerModal = styled(Modal)`
    &&& .ant-modal-content{
        border-radius: 12px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    }

    &&& .ant-modal-body{
        border-radius: 12px;
    }

    &&& .ant-modal-close-x{
        color: red;
    }
`
export const Container = styled.div`
    width: 100%;
    height: 100%;
    padding: 20px;

    &&& .ant-radio-wrapper{
        font-size: 16px !important;
    }
`

export const Title = styled.p`
    padding: 0 15px;
    background-color: #fff;
    font-weight: bold;
    font-size: 18px;
    width: 100%;
    text-align: center;
`
export const Steps = styled.p`
    color: #6600cc;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    width: 100%;
`

export const ContainerFlow = styled.div`
    max-height: 300px;
    overflow-y: auto;

    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #b56aff;
        border: 3px solid #b56aff;
        border-radius: 5px;
    }
`

export const ContainerSelect = styled.div`
  width: 100%;

  &&& .react-select__value-container {
    height: 38px !important;
  }

  &&& .css-1uccc91-singleValue {
    top: 65% !important;
  }
`

export const ContainerDatePick = styled.div`
  height: 38px;
  input {
    box-sizing: border-box;
    margin: 0;
    margin-bottom: 10px;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum', 'tnum';
    position: relative;
    display: inline-block;
    width: 100%;
    height: 38px;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 1.5;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;

    border-radius: 4px;
    transition: all 0.4s;

    &:hover {
      border-color: #40a9ff;
      border-right-width: 1px;
    }

    &:focus {
      border-color: #40a0ff;
      border-right-width: 1px;
      outline: 0;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
  }

  &&& .ant-picker {
    height: 38px;
  }

  &&& .ant-picker-input {
    height: 35px;

    input {
      height: 35px;
      margin:0;
    }
  }

`