import { Breadcrumb, Col, Collapse, Row, Tag, Tooltip } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { DrawerFilter } from '../../../components/DrawerFilter'
import { Loading } from '../../../components/Loading'
import { BigButton, ContainerButtonsFootter, ContainerFormFilter, customStyles, GroupForm } from '../../../GlobalStyles'
import { ContainerTagg } from '../../Survey/FormListPage/styles'
import { ButtonClear, ButtonSearch, LabelForm } from '../../Team/Promoter/style'
import {
  FileSearchOutlined,
  FilterOutlined,
  CloseOutlined,
  PlusOutlined,
  MinusOutlined,
  ClearOutlined,
} from '@ant-design/icons'
import { FormHandles } from '@unform/core'
import history from '../../../routes/history'
import { Form } from '@unform/web'
import { Search } from '../../../components/form/SimpleSearch'
import SimpleRangePicker from '../../../components/form/SimpleRangePicker'
import Select from '../../../components/form/SimpleSelect'
import { isActiveSelectVisita } from '../../../utils/option-user'
import moment from 'moment'
import qs from 'querystring'
import useReactRouter from 'use-react-router'
import ColumnDash, { randonValue, sequenceValueTimeStamp } from '../../../components_analytcs/ColumnsDash'
import { LinearCard } from '../../../components_analytcs/LinearCard'
import { ContainerFullGraph } from '../move/style'
import { names, UFS } from '../move'
import { Link } from 'react-router-dom'

function ComparateTimePage() {
  const formRef = useRef<FormHandles>(null)
  const { location } = useReactRouter()
  const [filterTag, setFilterTag] = useState<any>({})
  const [expanded, setExpanded] = useState<string>('1')
  const [visible, setVisible] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [filters, setFilters] = useState<any>({})
  const [urlFilter, setUrlFilter] = useState<string>('')

  async function searchTime(body: any) {
    let { date, flag, promoter, regional, status, uf, week } = body
    let filters: any = {
      filterQuery: {},
      dataTag: {},
      filterQueryUrl: {},
    }

    if (promoter && promoter.value) {
      filters.filterQuery.promoter = promoter.value
      filters.dataTag = { ...filters.dataTag, promoter }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, promoter: JSON.stringify(promoter) }
    }

    if (regional) {
      filters.filterQuery.regional = regional
      filters.dataTag = { ...filters.dataTag, regional }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, regional: regional }
    }

    if (status) {
      filters.filterQuery.status = status
      filters.dataTag = { ...filters.dataTag, status }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, status: status }
    }

    if (uf) {
      filters.filterQuery.uf = uf
      filters.dataTag = { ...filters.dataTag, uf }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, uf: uf }
    }

    if (week) {
      filters.filterQuery.week = week
      filters.dataTag = { ...filters.dataTag, week }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, week: week }
    }

    if (flag && flag.value) {
      filters.filterQuery.flag = flag.value
      filters.dataTag = { ...filters.dataTag, flag }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, flag: JSON.stringify(flag) }
    }

    if (date) {
      filters.filterQueryUrl = { ...filters.filterQueryUrl, date: JSON.stringify(date) }
      if (date[0]) {
        filters.filterQuery.start_date = date[0]
        filters.dataTag = { ...filters.dataTag, start_date: date[0] }
      }

      if (date[1]) {
        filters.filterQuery.end_date = date[1]
        filters.dataTag = { ...filters.dataTag, end_date: date[1] }
      }
    } else {
      filters.filterQuery = {
        ...filters.filterQuery,
        end_date: moment().format('YYYY-MM-DD'),
        start_date: moment().format('YYYY-MM-DD'),
      }
      filters.dataTag = {
        ...filters.dataTag,
        end_date: moment().format('YYYY-MM-DD'),
        start_date: moment().format('YYYY-MM-DD'),
      }
    }
    const queryFilter = qs.stringify({ ...filters.filterQuery })

    setUrlFilter(queryFilter)
    // const { data } = await api_v2.get(`/visit?${queryFilter}`)
    setLoading(false)
    setFilterTag(filters.dataTag)
    setVisible(false)
    history.push(`/Analitycs/team/comparative?${qs.stringify({ ...filters.filterQueryUrl })}`)
  }

  const removeFilter = async (typeRemove: string, value: string) => {
    let newUrl: any = qs.parse(urlFilter)
    let newObjTagg = filterTag

    if (typeRemove === 'promoter') {
      delete newObjTagg.promoter
      delete newUrl.promoter
    }
    if (typeRemove === 'flag') {
      delete newObjTagg.flag
      delete newUrl.flag
    }
    if (typeRemove === 'week') {
      delete newObjTagg.week
      delete newUrl.week
    }
    if (typeRemove === 'uf') {
      delete newObjTagg.uf
      delete newUrl.uf
    }
    if (typeRemove === 'status') {
      delete newObjTagg.status
      delete newUrl.status
    }
    if (typeRemove === 'regional') {
      delete newObjTagg.regional
      delete newUrl.regional
    }
    const queryFilter = qs.stringify({ ...newUrl })

    setLoading(true)
    // const { data } = await api_v2.get(`/visit?${queryFilter}`)
    // setForms({ ...data, loading: false })
    setUrlFilter(queryFilter)
    setFilterTag(newObjTagg)
    setLoading(false)
    history.push(`/Analitycs/team/comparative?${queryFilter}`)
  }

  const cleanFilter = async () => {
    history.push(`/Analitycs/team/comparative`)
    setVisible(false)
    setFilterTag({})
    formRef!.current!.reset()
  }

  const getStatusWithFilter = () => {
    let obj: any = qs.parse(location.search.replaceAll('?', ''))
    if (obj && obj.promoter) obj.promoter = JSON.parse(obj.promoter)
    if (obj && obj.flag) obj.flag = JSON.parse(obj.flag)
    if (obj && obj.status) obj.status = JSON.parse(obj.status)
    if (obj && obj.regional) obj.regional = JSON.parse(obj.regional)
    if (obj && obj.wek) obj.wek = JSON.parse(obj.wek)
    if (obj && obj.uf) obj.uf = JSON.parse(obj.uf)
    if (obj && obj.date) obj.date = JSON.parse(obj.date)

    if (!obj || !obj.date)
      obj.date = [moment().startOf('week').format('YYYY-MM-DD'), moment().endOf('week').format('YYYY-MM-DD')]
    searchTime(obj)
  }

  useEffect(() => {
    getStatusWithFilter()
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div style={{ padding: '0 10px' }}>
      <Row gutter={[8, 8]} justify='space-between'>
        <Col span={20}>
          <h1>Comparativo de atendimento</h1>
          <Breadcrumb className='breadcumb'>
            <Link to='/Analitycs'>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
            </Link>
            <Breadcrumb.Item>
              <Breadcrumb.Item>Comparativo de atendimento</Breadcrumb.Item>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col>
          <Tooltip placement='top' title={'Expandir todos gráficos'}>
            <BigButton
              onClick={() => {
                setLoading(true)
                setExpanded('1')
                setTimeout(() => {
                  setLoading(false)
                }, 200)
              }}
              style={{ padding: 9 }}
            >
              <PlusOutlined />
            </BigButton>
          </Tooltip>
        </Col>
        <Col>
          <Tooltip placement='top' title={'Ocultar todos gráficos'}>
            <BigButton
              onClick={() => {
                setLoading(true)
                setExpanded('0')
                setTimeout(() => {
                  setLoading(false)
                }, 200)
              }}
              style={{ padding: 9 }}
            >
              <MinusOutlined />
            </BigButton>
          </Tooltip>
        </Col>
        <Col>
          <Tooltip placement='top' title={'Filtrar'}>
            <BigButton onClick={() => setVisible(!visible)} style={{ padding: 9 }}>
              <FilterOutlined />
            </BigButton>
          </Tooltip>
        </Col>
        <Col span={24}>
          <ContainerTagg>
            {filterTag.start_date && (
              <Tag color='geekblue' onClick={() => removeFilter('start_date', `${filterTag.start_date}`)}>
                {moment(filterTag.start_date).format('DD/MM/YYYY')}
                <CloseOutlined />
              </Tag>
            )}
            {filterTag.end_date && (
              <Tag color='geekblue' onClick={() => removeFilter('end_date', `${filterTag.end_date}`)}>
                {moment(filterTag.end_date).format('DD/MM/YYYY')}
                <CloseOutlined />
              </Tag>
            )}
            {filterTag.flag && (
              <Tag color='geekblue' onClick={() => removeFilter('flag', filterTag.flag)}>
                {filterTag.flag.label} <CloseOutlined />
              </Tag>
            )}
            {filterTag.week && (
              <Tag color='geekblue' onClick={() => removeFilter('week', filterTag.week)}>
                {filterTag.week} <CloseOutlined />
              </Tag>
            )}
            {filterTag.uf && (
              <Tag color='geekblue' onClick={() => removeFilter('uf', filterTag.uf)}>
                {filterTag.uf} <CloseOutlined />
              </Tag>
            )}
            {filterTag.status && (
              <Tag color='geekblue' onClick={() => removeFilter('status', filterTag.status)}>
                {filterTag.status} <CloseOutlined />
              </Tag>
            )}
            {filterTag.regional && (
              <Tag color='geekblue' onClick={() => removeFilter('regional', filterTag.regional)}>
                {filterTag.regional} <CloseOutlined />
              </Tag>
            )}
            {filterTag.promoter && (
              <Tag color='geekblue' onClick={() => removeFilter('promoter', filterTag.promoter)}>
                {filterTag.promoter.label} <CloseOutlined />
              </Tag>
            )}
          </ContainerTagg>
        </Col>
      </Row>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Row gutter={[20, 20]}>
            <Col span={12}>
              <ContainerFullGraph>
                <Collapse bordered={false} defaultActiveKey={[expanded]}>
                  <Collapse.Panel header='Comparativo mensal de atendimento' key='1'>
                    <LinearCard
                      categories={sequenceValueTimeStamp(6, 'DD/MMM')}
                      series={[
                        {
                          name: 'Previsto',
                          data: randonValue(6),
                        },
                        {
                          name: 'Realizado',
                          data: randonValue(6),
                        },
                      ]}
                    />
                  </Collapse.Panel>
                </Collapse>
              </ContainerFullGraph>
            </Col>
            <Col span={12}>
              <ContainerFullGraph>
                <Collapse bordered={false} defaultActiveKey={[expanded]}>
                  <Collapse.Panel header='Comparativo semanal de atendimento' key='1'>
                    <ColumnDash
                      categories={['SEMANA 1', 'SEMANA 2', 'SEMANA 3', 'SEMANA 4', 'SEMANA 5']}
                      series={[
                        { name: 'Previsto', data: randonValue(4) },
                        { name: 'Realizado', data: randonValue(4) },
                        { name: 'Justificado', data: randonValue(4) },
                      ]}
                      legend={true}
                    />
                  </Collapse.Panel>
                </Collapse>
              </ContainerFullGraph>
            </Col>
          </Row>
          <Row gutter={[20, 20]}>
            <Col span={12}>
              <ContainerFullGraph>
                <Collapse bordered={false} defaultActiveKey={[expanded]}>
                  <Collapse.Panel header='Comparativo mensal de assiduidade' key='1'>
                    <LinearCard
                      categories={sequenceValueTimeStamp(6, 'DD/MM')}
                      series={[
                        {
                          name: 'Previsto',
                          data: randonValue(6),
                        },
                        {
                          name: 'Realizado',
                          data: randonValue(6),
                        },
                      ]}
                    />
                  </Collapse.Panel>
                </Collapse>
              </ContainerFullGraph>
            </Col>
            <Col span={12}>
              <ContainerFullGraph>
                <Collapse bordered={false} defaultActiveKey={[expanded]}>
                  <Collapse.Panel header='Comparativo semanal de assiduidade' key='1'>
                    <ColumnDash
                      categories={['SEMANA 1', 'SEMANA 2', 'SEMANA 3', 'SEMANA 4', 'SEMANA 5']}
                      series={[
                        { name: 'Previsto', data: randonValue(4) },
                        { name: 'Realizado', data: randonValue(4) },
                        { name: 'Justificado', data: randonValue(4) },
                      ]}
                      legend={true}
                    />
                  </Collapse.Panel>
                </Collapse>
              </ContainerFullGraph>
            </Col>
          </Row>
          {/* <ContainerFullGraph>
            <Collapse bordered={false} defaultActiveKey={[expanded]}>
              <Collapse.Panel header='Comparativo de atendimento por supervisor' key='1'>
                <ColumnDash
                  id='full-bar-dash'
                  categories={names}
                  series={[
                    { name: 'Kalunga', data: randonValue(names.length - 1) },
                    { name: 'Americanas', data: randonValue(names.length - 1) },
                    { name: 'Carrefour', data: randonValue(names.length - 1) },
                  ]}
                  type='time'
                  tooltip={true}
                  legend={true}
                />
              </Collapse.Panel>
            </Collapse>
          </ContainerFullGraph>
          <ContainerFullGraph>
            <Collapse bordered={false} defaultActiveKey={[expanded]}>
              <Collapse.Panel header='Comparativo de assiduidade por supervisor' key='1'>
                <ColumnDash
                  id='full-bar-dash'
                  categories={names}
                  series={[
                    { name: 'Kalunga', data: randonValue(names.length - 1) },
                    { name: 'Americanas', data: randonValue(names.length - 1) },
                    { name: 'Carrefour', data: randonValue(names.length - 1) },
                  ]}
                  type='time'
                  tooltip={true}
                  legend={true}
                />
              </Collapse.Panel>
            </Collapse>
          </ContainerFullGraph> */}
          <ContainerFullGraph>
            <Collapse bordered={false} defaultActiveKey={[expanded]}>
              <Collapse.Panel header='Comparativo por UF de atendimento' key='1'>
                <ColumnDash
                  id='full-bar-dash'
                  categories={UFS}
                  series={[
                    { name: 'Kalunga', data: randonValue(names.length - 1) },
                    { name: 'Americanas', data: randonValue(names.length - 1) },
                    { name: 'Carrefour', data: randonValue(names.length - 1) },
                  ]}
                  type='time'
                  tooltip={true}
                  legend={true}
                />
              </Collapse.Panel>
            </Collapse>
          </ContainerFullGraph>

          <ContainerFullGraph>
            <Collapse bordered={false} defaultActiveKey={[expanded]}>
              <Collapse.Panel header='Comparativo por UF de assiduidade' key='1'>
                <ColumnDash
                  id='full-bar-dash'
                  categories={UFS}
                  series={[
                    { name: 'Kalunga', data: randonValue(names.length - 1) },
                    { name: 'Americanas', data: randonValue(names.length - 1) },
                    { name: 'Carrefour', data: randonValue(names.length - 1) },
                  ]}
                  type='time'
                  tooltip={true}
                  legend={true}
                />
              </Collapse.Panel>
            </Collapse>
          </ContainerFullGraph>
        </>
      )}
      <DrawerFilter
        visible={visible}
        close={setVisible}
        title='Comparativo de atendimento'
        footer={
          <ContainerButtonsFootter>
            <ButtonSearch type='button' onClick={() => formRef?.current?.submitForm()}>
              <FileSearchOutlined />
              Pesquisar
            </ButtonSearch>
            <ButtonClear onClick={() => cleanFilter()}>
              <ClearOutlined />
              Limpar
            </ButtonClear>
          </ContainerButtonsFootter>
        }
      >
        <ContainerFormFilter
          tabIndex={0}
          onKeyDown={(evt: any) => {
            if (evt.key === 'Enter') formRef?.current?.submitForm()
          }}
        >
          <Form ref={formRef} className='form' style={{ marginTop: '15px' }} onSubmit={searchTime}>
            <SimpleRangePicker
              name='date'
              label='Selecione o periodo:'
              defaultValueInput={[moment().startOf('week'), moment().endOf('week')]}
              ranges={{
                Ontem: [moment().subtract('d', 1), moment().subtract('d', 1)],
                Hoje: [moment(), moment()],
                Amanhã: [moment().add('d', 1), moment().add('d', 1)],
                'Esta semana': [moment().startOf('week'), moment().endOf('week')],
                'Este mês': [moment().startOf('month'), moment().endOf('month')],
              }}
            />
            <GroupForm>
              <LabelForm>Selecione a promotor</LabelForm>
              <Search placeholder='Selecione o promotor' name='promoter' path='promoters' isMult={false} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione a bandeira</LabelForm>
              <Search placeholder='Selecione a bandeira' name='flag' path='flags' isMult={false} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione o status</LabelForm>
              <Select
                styles={customStyles}
                className='pre-select'
                placeholder='Selecione o status'
                options={isActiveSelectVisita}
                name='status'
                defaultValue={filters && filters.status ? filters.status : ''}
                onChange={(value: any) => {
                  if (value && value.value)
                    setFilters({
                      ...filters,
                      status: value.value,
                    })
                }}
              />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione a região</LabelForm>
              <Select
                styles={customStyles}
                className='pre-select'
                placeholder='Selecione a região'
                options={isActiveSelectVisita}
                name='regional'
                defaultValue={filters && filters.regional ? filters.regional : ''}
                onChange={(value: any) => {
                  if (value && value.value)
                    setFilters({
                      ...filters,
                      regional: value.value,
                    })
                }}
              />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione a semana</LabelForm>
              <Select
                styles={customStyles}
                className='pre-select'
                placeholder='Selecione a semana'
                options={isActiveSelectVisita}
                name='week'
                defaultValue={filters && filters.week ? filters.week : ''}
                onChange={(value: any) => {
                  if (value && value.value)
                    setFilters({
                      ...filters,
                      week: value.value,
                    })
                }}
              />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione o estado</LabelForm>
              <Select
                styles={customStyles}
                className='pre-select'
                placeholder='Selecione o estado'
                options={isActiveSelectVisita}
                name='uf'
                defaultValue={filters && filters.uf ? filters.uf : ''}
                onChange={(value: any) => {
                  if (value && value.value)
                    setFilters({
                      ...filters,
                      uf: value.value,
                    })
                }}
              />
            </GroupForm>
          </Form>
        </ContainerFormFilter>
      </DrawerFilter>
    </div>
  )
}

export default ComparateTimePage
