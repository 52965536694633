import styled from 'styled-components'

interface Props {
  color: string
}

export const ContainerChart = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const ContainerStatusChart = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 20px;
  padding-top: 10px;
`

export const ContainerDataChart = styled.div<Props>`
  display: flex;
  align-items: center;
  color: #242424;
  font-family: 'Open Sans';
  font-size: 13px;
  font-weight: 400;

  div {
    width: 16px;
    height: 16px;
    background-color: ${(props) => (props.color ? props.color : '#8884d8')};
    margin-right: 10px;
    border-radius: 50%;
  }
`

export const ContainerVisit = styled.div`
  border-radius: 12px;
  border: 1px solid #d6d6d6;
  width: 100%;
  margin-top: 27px;
  padding: 26px 32px;
`

export const ColumnVisits = styled.div`
  width: 322px;
  margin-right: 90px;
`

export const ColumnCheckIn = styled.div`
  width: 100%;
`

export const ContainerShowVisits = styled.div`
  margin-top: 15px;
  max-height: 100%;
  overflow-x: auto;

  @media (max-width: 600px) {
    max-width: 80vw;
  }

  @media (max-width: 900px) {
    max-width: 85vw;
  }

    @media (max-width: 900px) {
    max-width: 85vw;
  }

  /* max-width: 100%; */
  /* overflow: auto; */

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #ebebeb;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #666666;
    border-radius: 20px;
    border: 3px solid #ebebeb;
  }

  &&& .ant-table {
    background-color: transparent;
  }

  &&& .ant-table-thead > tr > th {
    background-color: transparent;
    border-bottom: 2px solid #d6d6d6;
  }

  &&& .ant-table-tbody > tr > td {
    background-color: transparent;
    border-bottom: 2px solid #d6d6d6;
  }
`

export const DataStatus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StatusRealizado = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 16px;
  text-align: center;
  background-color: #00c49f;
`

export const StatusInativo = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 16px;
  text-align: center;
  background-color: #f75c4c;
`

export const StatusMobile = styled.div`
  color: #037b92;
  font-family: 'Open Sans';
  font-size: 12px;
  width: 67px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid #037b92;
  background-color: rgba(0, 204, 242, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`

export const StatusRoteiro = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  color: #cd17b0;
  font-family: 'Open Sans';
  font-size: 12px;
  width: 71px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid #cd17b0;
  background-color: rgba(205, 23, 176, 0.27);
`

export const StatusPendente = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 16px;
  text-align: center;
  background-color: #0088fe;
`

export const StatusJustify = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 16px;
  text-align: center;
  background-color: #ff8042;
`

export const StatusAvulsa = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  color: #d06300;
  font-family: 'Open Sans';
  font-size: 12px;
  width: 64px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid #ff7a00;
  background-color: rgba(233, 131, 15, 0.27);
`

export const DataFinish = styled.div`
  display: flex;
`

export const ContainerTasks = styled.div`
  width: 100%;
  height: 33px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6600cc;
  font-family: Poppins;
  font-size: 13px;
  padding-left: 7px;
  margin-top: 11px;
  margin-bottom: 23px;
  flex-direction: column;
  cursor: pointer;

  div {
    display: flex;
  }

  svg {
    margin-right: 10px;
    font-size: 20px;
  }
`

export const TextCheckIn = styled.p`
  color: #242424;
  font-family: 'Open Sans';
  font-size: 14px;
`

export const TextDataCheckIn = styled.p`
  color: #242424;
  font-family: 'Open Sans';
  font-size: 16px;
`

export const CountVisits = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: #b56aff;
  display: flex;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-family: 'Open Sans';
  font-size: 16px;
`

export const TableName = styled.p`
  color: #6f7782;
  font-family: 'Open Sans';
  font-size: 16px;
`

export const ImgUser = styled.img`
  margin-right: 13px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
`
