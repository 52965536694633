import React from 'react'
import { ContainerQuestion, TitleMoney, Description } from '../style'
import { Form } from 'antd'
import MoneyInput from './moneyInput'
import ViewPicture from './viewPicture'

const MoneyQuestion: React.FC<any> = ({ quest }) => {
  return (
    <>
      <ContainerQuestion>
        <TitleMoney>
          <h1>
            {quest.is_required ? '*' : null} {quest.order} →{' '}
          </h1>{' '}
          {quest.name}
        </TitleMoney>
        {quest.description ? <Description>{quest.description}</Description> : null}

        {quest.maxMoney || quest.minMoney ? (
          <div style={{ marginBottom: '20px;' }}>
            {quest.maxMoney ? (
              <p style={{ fontSize: '17px' }}>
                Valor máximo: <b>R${quest.maxMoney},00</b>{' '}
              </p>
            ) : null}
            {quest.minMoney ? (
              <p style={{ fontSize: '17px' }}>
                Valor mínimo: <b>R${quest.minMoney},00</b>{' '}
              </p>
            ) : null}
          </div>
        ) : null}
        {quest.picture && typeof quest.picture == 'string' &&  <ViewPicture picture={quest.picture} />}
        <Form.Item name='MONEY' label='Campo dinheiro' rules={[{ required: quest.is_required ? true : false }]}>
          <MoneyInput />
        </Form.Item>
      </ContainerQuestion>
    </>
  )
}

export default MoneyQuestion
