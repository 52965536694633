import React, { useState, useRef } from 'react'
import { CardCreate, ContainerModal } from './style'
import { ApartmentOutlined } from '@ant-design/icons'
import { FormHandles } from '@unform/core'
import Input from '../form/SimpleInput'
import * as Yup from 'yup'
import { message } from 'antd'
import { Form } from '@unform/web'
import { Loading } from '../Loading'
import { ContainerLogo } from '../ModalSugestion/style'
import { GroupForm } from '../../GlobalStyles'
import SimpleSelect from '../form/SimpleSelect'
import { ButtonBack } from '../../pages/Team/ViewUser/styles'
import { LabelForm } from '../../pages/Team/Promoter/style'
import api_v2 from '../../services/api-v2'

interface IProps {
  visible: boolean
  close: (boolean: any) => void
  reloadApi?: (boolean: any) => void
  route?: string
  name?: string
}

export const ModalCreateMix = ({ visible, close, reloadApi, route, name }: IProps) => {
  const formRef = useRef<FormHandles>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const handleSubmit = async (body: any) => {
    try {
      setLoading(true)
      const schema = Yup.object().shape({
        name: Yup.string().required('* Insira o nome'),
        context: Yup.string().required('* O contexto é obrigatória'),
      })
      await schema.validate(body, {
        abortEarly: false,
      })
      const {data} = await api_v2.post(route ? route : 'product-mix/create', body)
      if(data.err) {
        message.error(data.err)
        setLoading(false)
        return
      }
      if (reloadApi) reloadApi(true)
      close(true)
      message.success('Mix criado com sucesso!')
      setLoading(false)
    } catch (error) {
      setLoading(false)
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((el: any) => {
          formRef.current!.setFieldError(el.path, el.message)
        })
      } else {
        message.error('Já existe um registro com essas informações!')
      }
    }
  }

  return (
    <ContainerModal
      visible={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
      destroyOnClose={true}
    >
      {loading && <Loading />}
      <CardCreate style={{ width: '100%', height: 'auto' }}>
        <h4>Criar Mix de {name ? name : 'produto'}</h4>
        <ContainerLogo style={{ marginBottom: 10 }}>
          <ApartmentOutlined />
        </ContainerLogo>
        <p>Adicione o nome do mix a ser criado, e o seu contexto</p>
        <Form
          translate={undefined}
          className='form'
          ref={formRef}
          initialData={{ is_active: true }}
          onSubmit={handleSubmit}
          style={{ width: '100%', marginTop: 20 }}
        >
          <GroupForm>
            <LabelForm style={{ textAlign: 'left' }}>Nome do mix:*</LabelForm>
            <Input name='name' placeholder='Diga o nome do mix' />
          </GroupForm>
          <GroupForm>
            <LabelForm style={{ textAlign: 'left' }}>Selecione o contexto:*</LabelForm>
            <SimpleSelect
              name='context'
              placeholder='Selecione o contexto'
              options={[
                { value: 'PDV', label: 'PDV' },
                { value: 'FLAG', label: 'Bandeira' },
                { value: 'NETWORK', label: 'Rede' },
              ]}
              isMulti={false}
            />
          </GroupForm>
          <ButtonBack
            style={{ width: '100%', backgroundColor: '#b56aff', color: '#fff', margin: 0, marginTop: 10 }}
            type='submit'
          >
            Criar mix
          </ButtonBack>
        </Form>
      </CardCreate>
    </ContainerModal>
  )
}
