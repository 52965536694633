import { Check, Form } from '@rocketseat/unform'
import { Breadcrumb, Button, Col, message, Row } from 'antd'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import Input from '../../../components/form/Input'
import { Container } from '../../../GlobalStyles'
import history from '../../../routes/history'
import api from '../../../services/api'
import { Loading } from '../../../components/Loading'
import { Mixpanel } from '../../../utils/mixpanel'
const schema = Yup.object().shape({
  name: Yup.string().required('* É necessario informar o nome da regional'),
})

const RegionalCreatePage: React.FC = () => {
  const [loading, setLoading] = useState<any>(false)
  async function handleSubmit(body: any) {
    setLoading(true)
    try {
      await api.post('/regional', {
        ...body,
      })
      Mixpanel.track('Criou regional', {
        params: {
          ...body,
        },
      })
      message.success('regional cadastrada com sucesso')
      history.push('/backoffice/store/regionals')
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }
  return (
    <Container>
      {loading ? <Loading /> : null}
      <Row>
        <Col span={12}>
          <h1>Cadastro de regionais</h1>
          <p>Insira os dados para realizar o cadastro</p>
          <Breadcrumb separator='>' className='breadcumb'>
            <Link to='/store/regionals'>
              <Breadcrumb.Item> Regionais </Breadcrumb.Item>
            </Link>
            <Breadcrumb.Item> Cadastro de regional </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Form className='form' onSubmit={handleSubmit} initialData={{ is_active: true }} schema={schema}>
        <Row gutter={8}>
          <Col span={20}>
            <Input type='text' label='Nome *' name='name' />
          </Col>
          <Col span={2} className='checkbox'>
            <Check name='is_active' label='Ativo' />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button type='primary' htmlType='submit'>
              Cadastrar
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  )
}
export default RegionalCreatePage
