import styled, { css } from 'styled-components'

export const Container = styled.div`
  height: 100%;
  flex: 0 0 260px;
  background-color: '#f9f9f9';
  border: 1px solid #d6d6d6;
  margin-right: 4px;
  margin-bottom: 4px;
  padding: 8px;

  &&& .ant-modal-footer {
    text-align: left !important;
  }
`

export const ContainerForm = styled.div`
  width: 100%;
  background-color: #f9f9f9;
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d6d6d6;
  height: 50px;
`

export const Day = styled.p`
  color: #272d3b;
  font-family: 'Open Sans';
  font-size: 13px;
`

export const Count = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: #d6d6d6;
  color: #272d3b;
  font-family: 'Open Sans';
  font-size: 13px;
`

export const BodyVisits = styled.div`
  padding: 10px 3px;
  height: 230px;
  width: 100%;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #b56aff;
    border: 3px solid #b56aff;
    border-radius: 5px;
  }
`

interface Props {
  isDragging: boolean
}

export const ContainerScript = styled.div<Props>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 7px 0;
  cursor: grabbing;

  ${(props: any) =>
    props.isDragging &&
    css`
      border: 2px dashed rgba(0, 0, 0, 0.2);
      .isDraggingGraph {
        opacity: 0.05;
      }
    `}
`

export const TextScript = styled.p`
  color: #6f7782;
  font-family: 'Open Sans';
  font-size: 12px;
  text-transform: uppercase;
  width: 100%;
`

export const Footer = styled.div`
  width: 100%;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ButtonAdd = styled.button`
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #6600cc;
  color: #6600cc;
  font-family: 'Open Sans';
  font-size: 16px;
  background-color: transparent;
`

export const ContainerTableModal = styled.div`
  &&& .ant-table-cell {
    height: 70px !important;
    font-size: 14px !important;
  }
`
