import { Container, Content, ContentBuilder, ContentPreview, Header, Menu, Flex, FormGroup } from './styles'
import SiderMenu from '../../components/CreateForms/Menu'
import './style.css'
import history from '../../routes/history'
import { Loading } from '../../components/Loading'
import QuestionPreview from '../../components/CreateForms/QuestionPreview'
import ConfigQuestion from '../../components/CreateForms/ConfigQuestion'
import DragDrop from '../../components/CreateForms/DragDrop2'
import { useState, useEffect } from 'react'
import Api from '../../services/api'
import { Modal, Row, Col, Input, Select, Drawer, DatePicker, Checkbox, Radio, Alert, Button, message } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { Mixpanel } from '../../utils/mixpanel'
import api_v2 from '../../services/api-v2'
import { setTimeout } from 'timers'
const { Option } = Select

interface IQuestion {
  name: string
  id: number
  is_required: boolean
  focus?: boolean
  order: string
  type: string
  fields_true?: IQuestion[]
  fields_false?: IQuestion[]
  fields?: any[]
}

const FormCreatePage = () => {
  const historyBack = useHistory()

  const [questions, setQuestion] = useState<any>([])
  const [openConfig, setOpenConfig] = useState(false)
  const [cofigQuestion, setConfigQuestion] = useState<any>()
  const [previewOrder] = useState<any>()
  const [visible, setVisible] = useState<boolean>(true)
  const [publicar, setPublicar] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [listWorkspaces, setListWorkspaces] = useState([])
  const [form, setForm] = useState<any>({
    checkApp: false,
    is_all_visits: false,
    sub_workspaces_id: [],
  })
  const { id }: any = useParams()
  let getWorkspace = sessionStorage.getItem('@rocketpdv:workspace')
  let newGetWorkspace = JSON.parse(getWorkspace!.toString())

  useEffect(() => {
    async function loadForm() {
      const { data } = await Api.get(`/form/form_id/${id}`)
      setForm({
        ...data.firebase_body,
        is_all_visits: data.is_all_visits,
        // sub_workspaces_id: data.sub_workspaces_id,
      })

      let order = adjustOrder(data.firebase_body.fields)
      setQuestion(order)
    }

    async function loadWorkspaces() {
      setLoading(true)
      try {
        const { data } = await api_v2.get(`/workspace-sub/all`)
        if (!data) return setLoading(false)
        const result = data.result.map((item: any) => ({
          value: item.id,
          label: item.name,
        }))
        setLoading(false)
        setListWorkspaces(result)
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
      setLoading(false)
    }
    loadWorkspaces()
    loadForm()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const searchQuestion = (total: IQuestion[], questionEdit: IQuestion) => {
    let newBodyForm: any =
      total &&
      total.length > 0 &&
      total.map((el) => {
        if (questionEdit.id === el.id) {
          return questionEdit
        }

        if (el.fields) {
          Object.keys(el.fields).forEach((quest: any) => {
            if (el.fields && el.fields[quest]) {
              if (Array.isArray(el.fields[quest])) {
                // Se for um array, converter para objeto com subFields
                el.fields[quest] = { subFields: el.fields[quest] };
                el.fields[quest].subFields = el.fields[quest].subFields === false ? [] : searchQuestion(el.fields[quest].subFields, questionEdit);
              } else if (el.fields[quest].hasOwnProperty('subFields')) {
                // Se o subFields existir e não for nulo, processar normalmente
                el.fields[quest].subFields = el.fields[quest].subFields === false ? [] : searchQuestion(el.fields[quest].subFields, questionEdit);
              }
            }
          });
        }



        return {
          ...el,
          fields_true: el.fields_true ? searchQuestion(el.fields_true, questionEdit) : null,
          fields_false: el.fields_false ? searchQuestion(el.fields_false, questionEdit) : null,
          fields: el.fields,
        }
      })

    return newBodyForm
  }

  function adjustOrder(total, baseOrder = '') {

    let newBodyForm: any =
      total &&
      total.length > 0 &&
      total.map((el, index) => {
        el.order = baseOrder ? `${baseOrder}.${index + 1}` : `${index + 1}`;

        if (el.fields) {
          const subKeys = Object.keys(el.fields);
          if (subKeys.length > 0) {
            subKeys.forEach((subKey, subIndex) => {
              const subField = el.fields[subKey];
              const subFieldOrder = `${el.order}.${subIndex + 1}`;
              subField.order = subFieldOrder;
              if (subField.subFields && subField.subFields.length > 0) {
                adjustOrder(subField.subFields, subFieldOrder);
              }
            });
          }

        }


        return {
          ...el,
          fields_true: el.fields_true ? adjustOrder(el.fields_true, el.order) : null,
          fields_false: el.fields_false ? adjustOrder(el.fields_false, el.order) : null,
          fields: el.fields,
        }
      })

    return newBodyForm

  }

  const editQuestion = (objectQuestion: any) => {
    let edit = searchQuestion([...questions], { ...objectQuestion })



    let refind = adjustOrder([...edit])

    setQuestion([...refind])
    setConfigQuestion(objectQuestion)
  }

  const editConfig = async (objectForm: any) => {
    setForm(objectForm)
  }

  const addQuestion = async (objectQuestion: any) => {
    setQuestion([])
    let newQuestions = questions
    newQuestions.push({ ...objectQuestion, focus: true })
    // setTimeout(() => {
    setQuestion([...newQuestions])
    // }, 50);
  }

  const searchQuestionOrder = (total: IQuestion[], sub: string) => {
    let newBodyForm: any =
      total &&
      total.length > 0 &&
      total.map((el, index) => {
        let order = sub.split('.')
        let newOrder: any = sub === '' ? `${index + 1}` : sub
        if (sub === '') {
          newOrder = `${index + 1}`
        } else {
          if (order.length === 1) {
            newOrder = `${order[0]}.${index + 1}`
          }
          if (order.length > 1) {
            newOrder = `${sub}.${index + 1}`
          }
        }

        if (el.fields) {
          Object.keys(el.fields).forEach((quest: any) => {
            if (quest !== 'subFields') {
              if (el.fields && !isNaN(quest)) {
                if (el.fields[quest]) {
                  el.fields[quest].subFields =
                    el.fields[quest].subFields === false
                      ? []
                      : searchQuestionOrder(el.fields[quest].subFields, newOrder)
                }
              }
            }
          })
        }

        return {
          ...el,
          order: newOrder,
          fields_true: el.fields_true ? searchQuestionOrder(el.fields_true, newOrder) : null,
          fields_false: el.fields_false ? searchQuestionOrder(el.fields_false, newOrder) : null,
          fields: el.fields,
        }
      })

    return newBodyForm
  }

  const refactorOrder = async (orderQuestion: any) => {
    setQuestion([])
    // setQuestion(searchQuestionOrder(orderQuestion, ''))
    let newOrder = adjustOrder(orderQuestion)
    setQuestion([...newOrder])
  }

  const findAnswer = async (quest: IQuestion[], hover: IQuestion, down: IQuestion) => {
    let newBodyForm: any =
      quest &&
      quest.length > 0 &&
      quest.map((el: any) => {
        if (hover.id === el.id) {
          return down
        }

        if (down.id === el.id) {
          return hover
        }

        if (el.fields) {
          Object.keys(el.fields).forEach((quest: any) => {
            if (quest !== 'subFields') {
              if (el.fields && !isNaN(quest)) {
                if (el.fields[quest]) {
                  el.fields[quest].subFields =
                    el.fields[quest].subFields === false ? [] : findAnswer(el.fields[quest].subFields, hover, down)
                }
              }
            }
          })
        }

        return {
          ...el,
          fields_true: el.fields_true ? findAnswer(el.fields_true, hover, down) : null,
          fields_false: el.fields_false ? findAnswer(el.fields_false, hover, down) : null,
          fields: el.fields,
        }
      })

    return newBodyForm
  }

  const refactorIndex = (quest: IQuestion[], order?: any) => {
    let newBodyForm: any =
      quest &&
      quest.length > 0 &&
      quest.map((el: any, index) => {
        if (el.fields) {
          Object.keys(el.fields).forEach((quest: any) => {
            if (quest !== 'subFields') {
              if (el.fields && !isNaN(quest)) {
                if (el.fields[quest]) {
                  el.fields[quest].subFields =
                    el.fields[quest].subFields === false
                      ? []
                      : refactorIndex(el.fields[quest].subFields, order ? `${order}.${index}` : `${index + 1}`)
                }
              }
            }
          })
        }

        return {
          ...el,
          order: order ? `${order}.${index + 1}` : `${index + 1}`,
          fields_true: el.fields_true
            ? refactorIndex(el.fields_true, order ? `${order}.${index + 1}` : `${index + 1}`)
            : null,
          fields_false: el.fields_false
            ? refactorIndex(el.fields_false, order ? `${order}.${index + 1}` : `${index + 1}`)
            : null,
          fields: el.fields,
        }
      })

    return newBodyForm
  }

  const refactorOrderDragDrop = async (hover: any, down: any) => {
    let newList = [...questions]
    setQuestion([])
    let newOrder = await findAnswer(newList, hover, down)
    let newIndex = refactorIndex(newOrder)
    setQuestion([...newIndex])
    // setTimeout(() => {
    //   setQuestion([...newIndex])
    // }, 10);
  }

  const questionDelete = async (questionDelete: any) => {
    setQuestion([])
    let newQuestionListen: any = []
    let index = 0
    questions.forEach((obj: any, i: any) => {
      if (questionDelete.id !== obj.id) {
        if (index === 1) {
          newQuestionListen.push({ ...obj, order: `${obj.order - 1}` })
        } else {
          newQuestionListen.push(obj)
        }
      } else {
        index = 1
      }
    })
    // setTimeout(() => {
    setQuestion([...newQuestionListen])
    // }, 50)
  }

  const openCof = (b: any) => {
    setOpenConfig(b)
  }

  const upOrder = (quest: any) => {
    const findAnswer = async (quest: IQuestion[], hover: IQuestion, down: IQuestion) => {
      let newBodyForm: any =
        quest &&
        quest.length > 0 &&
        quest.map((el: any) => {
          if (hover.id === el.id) {
            return down
          }

          if (down.id === el.id) {
            return hover
          }

          if (el.fields) {
            Object.keys(el.fields).forEach((quest: any) => {
              if (quest !== 'subFields') {
                if (el.fields && !isNaN(quest)) {
                  if (el.fields[quest]) {
                    el.fields[quest].subFields =
                      el.fields[quest].subFields === false ? [] : findAnswer(el.fields[quest].subFields, hover, down)
                  }
                }
              }
            })
          }

          return {
            ...el,
            fields_true: el.fields_true ? findAnswer(el.fields_true, hover, down) : null,
            fields_false: el.fields_false ? findAnswer(el.fields_false, hover, down) : null,
            fields: el.fields,
          }
        })

      return newBodyForm
    }

    // findAnswer(questions)
  }
  const downOrder = (quest: any) => {
    console.log(quest)
  }

  const publicQuestion = async () => {
    let header: any = []

    let publishForm = form
    if (publishForm.context === 'PRODUCT') header.push({ key: 0, label: 'O produto está presente na loja?' })

    const searchHeader = (total: IQuestion[]) => {
      let newBodyForm: any = total.map((el) => {
        header.push({ label: el.name, key: el.id })

        return {
          ...el,
          fields_true: el.fields_true ? searchHeader(el.fields_true) : null,
          fields_false: el.fields_false ? searchHeader(el.fields_false) : null,
          fields: el.fields
            ? Object.keys(el.fields).forEach((quest: any) => {
              if (quest !== 'subFields')
                if (el.fields) {
                  if (el.fields[quest]) {
                    if (el.fields[quest].subFields && el.fields[quest].subFields.length > 0) {
                      return {
                        subFields: searchHeader(el.fields[quest].subFields),
                      }
                    }
                  }
                }
            })
            : null,
        }
      })

      return newBodyForm
    }

    searchHeader(questions)

    function adjustOrder(total, baseOrder = '') {

      let newBodyForm: any =
        total &&
        total.length > 0 &&
        total.map((el, index) => {
          el.order = baseOrder ? `${baseOrder}.${index + 1}` : `${index + 1}`;

          if (el.fields) {
            const subKeys = Object.keys(el.fields);
            if (subKeys.length > 0) {
              subKeys.forEach((subKey, subIndex) => {
                const subField = el.fields[subKey];
                const subFieldOrder = `${el.order}.${subIndex + 1}`;
                subField.order = subFieldOrder;
                if (subField.subFields && subField.subFields.length > 0) {
                  adjustOrder(subField.subFields, subFieldOrder);
                }
              });
            }

          }


          return {
            ...el,
            fields_true: el.fields_true ? adjustOrder(el.fields_true, el.order) : null,
            fields_false: el.fields_false ? adjustOrder(el.fields_false, el.order) : null,
            fields: el.fields,
          }
        })

      return newBodyForm

    }

    const adjustedQuestions = adjustOrder(questions);

    if(publishForm.sub_workspace_is_all == 'EXCEPT') {
      let ids = []
      listWorkspaces.forEach(item => {
        if(publishForm.except_sub && Array.isArray(publishForm.except_sub) && !publishForm.except_sub.includes(item.value)) ids.push(item.value) 
      })

      publishForm.sub_workspaces_id = ids
    }

    if (publishForm.context === 'RUPTURE' && !id) {
      delete publishForm?.is_all_visits
      let body = { ...publishForm, fields: adjustedQuestions, header: header }
      try {
        const { data } = await api_v2.post('/form/ruptura', body)

        if (data.length > 0) return message.error('Já existe um formulário de ruptura')
      } catch (error) {
        message.error('Erro ao criar formulário de ruptura')
        return
      }
    }

    console.log(publishForm)

    try {
      setLoading(true)
      if (!id) {
        Mixpanel.track('Confirmou a publicação do formulario', { params: { header } })
        let body = { ...publishForm, fields: adjustedQuestions, header: header }
        // await Api.post('/v2/form', body)
        await api_v2.post('/form/create', body)
        Mixpanel.track('Criou formulário', {
          params: { header },
        })
      } else {
        let body = { ...publishForm, fields: adjustedQuestions, header: header }
        Mixpanel.track('Confirmou a publicação do formulario (EDIÇÃO)', { params: { header, form_id: publishForm.id } })
        // await Api.put(`/form/editForm/${publishForm.id}`, { ...data, header })
        await api_v2.put(`/form/updateForm/${id}`, body)

        Mixpanel.track('Editou formulário', {
          params: { header, form_id: publishForm.id },
        })
      }

      setLoading(false)
      Modal.success({
        content: 'Formulário criado com sucesso.',
        onOk() {
          history.push('/backoffice/survey/forms')
        },
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      {loading ? <Loading /> : null}
      <Container>
        <Header>
          <div style={{ padding: 5 }} onClick={() => historyBack.goBack()}>
            <ArrowLeftOutlined style={{ fontSize: '20px', color: '#7158c1' }} />
          </div>
          <p>{form && form.name ? form.name : null}</p>
          {/* <button onClick={() => setPublicar(true)}>publicar</button> */}
          <button onClick={() => publicQuestion()}>Salvar</button>
        </Header>
        <Flex>
          <Menu>
            <SiderMenu question={questions} configForm={form} editConfig={editConfig} />
          </Menu>
          <Content>
            {openConfig && (
              <Drawer
                width={620}
                placement='left'
                closable={false}
                onClose={() => setOpenConfig(false)}
                visible={openConfig}
                getContainer={false}
                style={{
                  position: 'absolute',
                  zIndex: 999999999999999999999999999999999999999999999999999999999999999999999,
                }}
              >
                <ConfigQuestion
                  question={cofigQuestion}
                  edit={editQuestion}
                  close={setOpenConfig}
                  type={cofigQuestion?.type}
                  allQUestion={questions}
                />
              </Drawer>
            )}
            <ContentBuilder id='your_div'>
              <DragDrop
                jump={0}
                questions={questions}
                editQuestion={editQuestion}
                setOpenConfig={openCof}
                setConfigQuestion={setConfigQuestion}
                questionDelete={questionDelete}
                addQuestion={addQuestion}
                refactorOrder={refactorOrder}
                refactorOrderDragDrop={refactorOrderDragDrop}
                upOrder={upOrder}
                downOrder={downOrder}
              />
            </ContentBuilder>
            <ContentPreview>
              <QuestionPreview questions={questions} order={previewOrder} />
            </ContentPreview>
          </Content>
        </Flex>
        <Modal
          title='Configurar formulário'
          centered
          closable={false}
          visible={visible}
          onOk={() => {
            if (form.sub_workspace_is_all === 'SELECTEDS' && !form.sub_workspaces_id) return Modal.warning({
              title: 'Canpos obrigatórios',
              content: 'Selecione as empresas.'
            })

            if (!form.name || !form.context) {
              Modal.warning({
                title: 'Campos obrigratórios',
                content: 'Por favor preencha todos os campos...',
              })
            } else {
              setVisible(false)
            }
          }}
          onCancel={() => {
            history.push('/backoffice/survey/forms')
          }}
          width={1000}
          footer={[
            <Button
              key='back'
              onClick={() => {
                history.push('/backoffice/survey/forms')
              }}
            >
              Voltar
            </Button>,
            <Button
              key='submit'
              type='primary'
              onClick={() => {
                if (form.sub_workspace_is_all === 'SELECTEDS' && (!form.sub_workspaces_id || form.sub_workspaces_id.length == 0)) return Modal.warning({
                  title: 'Campos obrigratórios',
                  content: 'Selecione a empresa',
                })
                if (!form.name || !form.context) {
                  return Modal.warning({
                    title: 'Campos obrigratórios',
                    content: 'Por favor preencha todos os campos...',
                  })
                } else {
                  if (!id) {
                    Mixpanel.track('Confirmou a configuração do formulario', form)
                  } else {
                    Mixpanel.track('Confirmou a configuração do formulario (EDIÇÃO!)', form)
                  }
                  setVisible(false)
                }
              }}
            >
              Continuar
            </Button>,
          ]}
        >
          <Row>
            <Col xl={12}>
              <FormGroup>
                <p>*Nome do formulário</p>
                <Input onChange={(e: any) => setForm({ ...form, name: e.target.value })} value={form?.name} required />
              </FormGroup>
              {form?.context !== 'RUPTURE' && (
                <FormGroup>
                  <p>Vincular formulário em:</p>
                  <Radio.Group
                    onChange={(e: any) =>
                      setForm({
                        ...form,
                        is_all_visits: e.target.value,
                      })
                    }
                    value={form?.is_all_visits}
                  >
                    <Radio value={true}>Todas as lojas</Radio>
                    <Radio value={false}>Visitas selecionadas</Radio>
                  </Radio.Group>
                </FormGroup>
              )}
              {form?.context === 'STORE' && (
                <FormGroup>
                  <p>Formulário recorrente ?</p>
                  <Radio.Group
                    onChange={(e: any) =>
                      setForm({
                        ...form,
                        recorrent: e.target.value,
                      })
                    }
                    value={form?.recorrent}
                  >
                    <Radio value={true}>Sim</Radio>
                    <Radio value={false}>Não</Radio>
                  </Radio.Group>
                </FormGroup>
              )}
            </Col>
            <Col xl={12}>
              <FormGroup>
                <p>*Qual tema do formulário:</p>
                <Select onChange={(e: any) => setForm({ ...form, context: e })} value={form?.context}>
                  <Option value='PRODUCT'>Produto</Option>
                  <Option value='STORE'>Loja</Option>
                  <Option value='CATEGORY'>Categoria</Option>
                  <Option value='BRAND'>Marca</Option>
                  <Option value='CHECK_IN'>Check-in</Option>
                  <Option value='RUPTURE'>Ruptura</Option>
                </Select>
              </FormGroup>
              {form && form.context === 'PRODUCT' && (
                <>
                  <FormGroup>
                    <p>Tipo de formulário:</p>
                    <Select onChange={(e: any) => setForm({ ...form, product_type: e })} value={form?.product_type}>
                      <Option value='OWN'>Próprio</Option>
                      <Option value='CONCURRENT'>Concorrente</Option>
                      <Option value='ALL'>Todos</Option>
                    </Select>
                  </FormGroup>
                  <FormGroup>
                    <p>Fluxo de formulário:</p>
                    <Select onChange={(e: any) => setForm({ ...form, rule_view: e })} value={form?.rule_view}>
                      <Option value='PRODUCT'>Produto</Option>
                      <Option value='CATEGORY-PRODUCT'>Categoria {'>'} Produtos</Option>
                      {/* <Option value='CATEGORY-SUPERCATEGORY-PRODUCT'>Categoria {'>'} Super Categoria | Produtos</Option> */}
                    </Select>
                  </FormGroup>
                </>
              )}
              {newGetWorkspace.shared_environment && (
                <FormGroup>
                  <p>Empresas</p>
                  <Select
                    onChange={(e: any) => setForm({ ...form, sub_workspace_is_all: e, sub_workspaces_id: [], except_sub: [] })}
                    value={form.sub_workspace_is_all}
                  >
                    <Option value={'ALL'}>Para todos</Option>
                    <Option value={'SELECTEDS'}>Para alguns</Option>
                    <Option value={'EXCEPT'}>Exceto</Option>
                  </Select>
                </FormGroup>
              )}
              {form.sub_workspace_is_all === 'SELECTEDS' && (
                <FormGroup>
                  <p>Selecione as empresas</p>
                  <Select
                    mode='multiple'
                    onChange={(e: any) => setForm({ ...form, sub_workspaces_id: e })}
                    value={form.sub_workspaces_id}
                    filterOption={(input: any, option: any) =>
                      (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                    }                  >
                    {listWorkspaces && listWorkspaces.map((el: any) => <Option value={el.value}>{el.label}</Option>)}
                  </Select>
                </FormGroup>
              )}

              {form.sub_workspace_is_all === 'EXCEPT' && (
                <FormGroup>
                  <p>Selecione as empresas que não devem ter</p>
                  <Select
                    mode='multiple'
                    onChange={(e: any) => setForm({ ...form, except_sub: e })}
                    value={form.except_sub}
                    filterOption={(input: any, option: any) =>
                      (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                    }                  >
                    {listWorkspaces && listWorkspaces.map((el: any) => <Option value={el.value}>{el.label}</Option>)}
                  </Select>
                </FormGroup>
              )}
            </Col>
            <Col xl={24}>
              <Alert
                message={
                  'Atenção! Ao selecionar a opção "Todas as lojas", o formulário será replicado para todas visitas pendentes e em progresso.'
                }
                type='error'
              />
            </Col>
          </Row>
        </Modal>
        <Modal
          title='Publicar formulário'
          centered
          closable={false}
          visible={publicar}
          onOk={() => {
            publicQuestion()
            setPublicar(false)
          }}
          onCancel={() => {
            setPublicar(false)
          }}
          width={1000}
        >
          <Row>
            <Col xl={10}>
              <FormGroup>
                <Checkbox onChange={(evt: any) => console.log(evt)}>*Deseja publicar agora ?</Checkbox>
              </FormGroup>
            </Col>
            <Col xl={4}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                }}
              >
                <b>OU</b>
              </div>
            </Col>
            <Col xl={10}>
              <FormGroup>
                <p>Agendar publicação:</p>
                <DatePicker onChange={(value) => console.log(value)} />
              </FormGroup>
            </Col>
          </Row>
        </Modal>
      </Container>
    </>
  )
}

export default FormCreatePage
