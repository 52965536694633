import { useEffect, useState } from 'react'
import { Breadcrumb, Col, Row, Table, Tooltip } from 'antd'
import { FileExcelOutlined, CloudUploadOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import qs from 'querystring'
import { ContainerList } from '../../../GlobalStyles'
import { Loading } from '../../../components/Loading'
import { StatusInativo, StatusRealizado } from '../../../components/TabsVisits/style'
import api_v2 from '../../../services/api-v2'
import { NoSearch } from '../../../components/NoSearch'
import history from '../../../routes/history'
import moment from 'moment'

export const getStatus = (status: string, justify?: any) => {
  const listStatus: any = {
    PENDENT: (
      <Tooltip title='Pendente'>
        <StatusInativo style={{ backgroundColor: 'rgb(0, 136, 254)' }} />
      </Tooltip>
    ),
    PENDING: (
      <Tooltip title='Pendente'>
        <StatusInativo style={{ backgroundColor: 'rgb(0, 136, 254)' }} />
      </Tooltip>
    ),
    COMPLETE: (
      <Tooltip title='Concluída'>
        <StatusRealizado />
      </Tooltip>
    ),
    COMPLETED: (
      <Tooltip title='Concluída'>
        <StatusRealizado />
      </Tooltip>
    ),
    IN_PROGRESS: (
      <Tooltip title='Em progresso'>
        <StatusRealizado style={{ backgroundColor: 'rgb(255, 187, 40)' }} />
      </Tooltip>
    ),
    IN_PROCESS: (
      <Tooltip title='Em progresso'>
        <StatusRealizado style={{ backgroundColor: 'rgb(255, 187, 40)' }} />
      </Tooltip>
    ),
  }

  return listStatus[status] || '-'
}

const ProcessPagePPT = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [process, setProcess] = useState<any>({
    pagination: {
      page: 1,
      perpage: 10,
      total: 1,
    },
    loading: true,
  })

  async function SearchProcess() {
    try {
      setLoading(true)
      const { data } = await api_v2.get(`/export-ppt`)
      setProcess({ ...data, loading: false })
      setLoading(false)
      history.push(`/backoffice/processPPT`)
    } catch (error) {
      setLoading(false)
    }
  }

  const handleTableChange = async (queryParams: any) => {
    setProcess({ ...process, loading: true })

    let newObj = {
      perpage: queryParams.pageSize,
      page: queryParams.current,
    }
    const query = qs.stringify(newObj)

    const { data } = await api_v2.get(`/export-ppt?${query}`)
    setProcess({ ...data, loading: false })
  }

  const columns: any = [
    {
      title: '#',
      width: 60,
      render: (a: any) => (
        <>
          {a.is_finally && (
            <Tooltip title='Concluída'>
              <StatusRealizado />
            </Tooltip>
          )}
          {!a.is_finally && a.is_running && (
            <Tooltip title='Em progresso'>
              <StatusRealizado style={{ backgroundColor: 'rgb(255, 187, 40)' }} />
            </Tooltip>
          )}
          {!a.is_running && !a.is_finally && (
            <Tooltip title='Pendente'>
              <StatusInativo style={{ backgroundColor: 'rgb(0, 136, 254)' }} />
            </Tooltip>
          )}
        </>
      ),
    },
    {
      title: 'Contexto',
      dataIndex: 'context',
      key: 'context',
      render: (a: any, b: any) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p>{a === 'ANSWERS' ? 'Respostas' : 'Check-in / Check-out'}</p>
        </div>
      ),
    },
    {
      title: 'Nome do usuário',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'URL',
      dataIndex: 'file_path',
      key: 'file_path',
      ellipsis: true,
      width: 60,
      render: (a: any, b: any) => (
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {a ? (
            <Tooltip placement='top' title={'Arquivo enviado'}>
              <FileExcelOutlined
                style={{ cursor: 'pointer', fontSize: 20, color: '#00C49F' }}
                onClick={() => window.open(a, '_blank')}
              />
            </Tooltip>
          ) : (
            '-'
          )}
        </div>
      ),
    },
    {
      title: 'Data de criação',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (a: any) => <p>{moment(a).format('DD/MM/YYYY HH:mm:ss')}</p>,
    },
    {
      title: 'Início / Fim',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (a: any, b: any) => (
        <div>
          {b.is_running && (
            <Tooltip placement='top' title={'Início processamento'}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CloudUploadOutlined style={{ fontSize: 17, marginRight: 10, color: '#6e55cd' }} />
                {moment(b.created_at).format('DD/MM/YYYY HH:mm:ss')}
              </div>
            </Tooltip>
          )}
          {b.is_finally && (
            <Tooltip placement='top' title={'Fim do processamento'}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CheckCircleOutlined style={{ fontSize: 17, marginRight: 10, color: '#00C49F' }} />
                {moment(b.updated_at).format('DD/MM/YYYY HH:mm:ss')}
              </div>
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      title: 'Total de fotos',
      dataIndex: 'total_pictures',
      key: 'total_pictures',
    },
    {
      title: 'Baixado',
      dataIndex: 'download_pictures',
      key: 'download_pictures',
    },
  ]

  useEffect(() => {
    SearchProcess()
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const timer = setInterval(() => SearchProcess(), 10000)
    return () => {
      clearInterval(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [process])

  return (
    <ContainerList>
      <Row gutter={[8, 8]} justify='space-between'>
        <Col span={8}>
          <h1>Processamento PPT</h1>
          <Breadcrumb separator='>' className='breadcumb'>
            <Link to='/backoffice'>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
            </Link>
            <Breadcrumb.Item>Processamento PPT</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      {process.result && process.result.length > 0 ? (
        <Table
          rowKey='id'
          dataSource={process.result}
          columns={columns}
          onChange={handleTableChange}
          loading={process.loading || false}
          pagination={{
            current: process.page,
            pageSize: process.perpage,
            total: process.total,
            size: 'small',
            showSizeChanger: true,
            showTotal: () => `Exibindo ${process.result.length} de ${process.total} de registros`,
          }}
        />
      ) : (
        <NoSearch text='Não encontramos nenhum dado aqui.' />
      )}
      {loading ? <Loading /> : null}
    </ContainerList>
  )
}

export default ProcessPagePPT
