import React, { useState } from 'react'
import { ContainerModal } from '../ModalEditUser/style'
// import { Loading } from '../Loading'
import { Steps } from 'antd'
import { Step1 } from './Step1'
import { ContainerFilter } from '../../pages/Team/Promoter/style'
import { Step2 } from './Step2'
import { StepTask } from './stepTask'
const { Step } = Steps

interface IProps {
  visible: boolean
  close: (boolean: any) => void
  visitId?: number
  context?: any
  type?: any

}

export const ModalReportSurvey = ({ visible, close, visitId, context, type}: IProps) => {
  // const [loading, setLoading] = useState<boolean>(false)
  const [current, setCurrent] = useState(0)
  const [body, setBody] = useState<any>()
  const [forms, setForm] = useState<any>()

  const steps = [
    {
      title: '',
      content: <Step1 next={setCurrent} value={setBody} />,
    },
    {
      title: '',
      content: (
        <>
          {body?.form_id && (
            <Step2
              next={setCurrent}
              value={(value: any) => setBody({ ...body, value })}
              formId={body.form_id}
              body={body}
              close={close}
              context={context}    
            />
          )}

        </>
      ),
    },
  ]

  const steps1 = [
    {
      title: '',
      content: (
        <>  
            <StepTask
              next={setCurrent}
              visitId={visitId || 0}  
              value={setBody} 
            />       
        </>
      ),
    },
    {
      title: '',
      content: (
        <>
          {body?.form_id && (
            <Step2
              next={setCurrent}
              value={(value: any) => setBody({ ...body, value })}
              formId={body.form_id}
              type={body.type}
              body={body}
              close={close}
              context={context}         
            />
          )}
        </>
      ),
    },
  ]


  return (
    <ContainerModal
      visible={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
      width={800}
      style={{
        top: 20,
        height: '90vh',
      }}
    >
      {context == 'task' ? 
      <div
      style={{
        padding: 20,
        height: '90%',
      }}
    >
      {/* {loading && <Loading />} */}

      <Steps current={current}>
        {steps1.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <ContainerFilter
        style={{
          borderRadius: 10,
          height: '100%',
        }}
      >
        {steps1[current].content}
      </ContainerFilter>
    </div>
    :
      <div
        style={{
          padding: 20,
          height: '90%',
        }}
      >
        {/* {loading && <Loading />} */}

        <Steps current={current}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <ContainerFilter
          style={{
            borderRadius: 10,
            height: '100%',
          }}
        >
          {steps[current].content}
        </ContainerFilter>
      </div>   
} 
    </ContainerModal>
  )
}
