import { FileAddOutlined, DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons'
import { Breadcrumb, Col, Pagination, Row, Dropdown, Menu, Typography, Popconfirm, Tooltip, Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { BigButton, ContainerList } from '../../../GlobalStyles'
import { Loading } from '../../../components/Loading'
import 'moment/locale/pt-br'
import { WorkspaceName, WorkspaceOption, ContainerIcon } from '../../WorkspacePage/styles'
import { FilePptOutlined } from '@ant-design/icons'
import history from '../../../routes/history'
import { ContainerPaginationFooter } from '../../Team/Promoter/style'
import { ModalUploadFile } from '../../../components/ModalUploadFile'
import api_v2 from '../../../services/api-v2'
import { INIT_GLOBAL_RESPONSE } from '../../../utils/global-response'
import { Mixpanel } from '../../../utils/mixpanel'
import { ContextUpload } from '../../../context/contextUpload'
import { hasIndex } from '../../../components/Menu/menuHorizonte'

export interface SearchFilters {
  id?: string
  description?: string
  date?: string
  form?: number
  pdv?: number
  user?: number
  dt_visit?: string
  start_date?: string
  end_date?: string
  sub_workspace?: number
}

const TrainingFile: React.FC = () => {
  const { id }: any = useParams()
  const { permissions } = ContextUpload()

  const [uploadFile, setUploadFile] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [files, setFiles] = useState<any>(INIT_GLOBAL_RESPONSE)

  const searchFiles = async () => {
    try {
      console.log('chamou')
      setLoading(true)
      const { data } = await api_v2.get(`/trainings-file?folder_id=${id}&is_deleted=false`)
      setFiles({...data})
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const changePagination = async (page?: any) => {
    try {
      setLoading(true)
      const { data } = await api_v2.get(`/trainings-file?folder_id=${id}&is_deleted=false&page=${page}`)
      setFiles(data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const handleDeleteFile = async (id: any) => {
    try {
      setLoading(true)
      await api_v2.delete(`/trainings-file/${id}`)
      Mixpanel.track('Deletou arquivo de treinamento', {
        params: {
          id: id,
        },
      })
      searchFiles()
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    searchFiles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ContainerList>
      <Row gutter={[8, 8]} justify='space-between'>
        {loading ? <Loading /> : null}
        <Col span={18}>
          <h1>Treinamento</h1>
          <Breadcrumb className='breadcrumb'>
            <Link to='/backoffice/reports'>
              <Breadcrumb.Item>Relatórios</Breadcrumb.Item>
            </Link>
            <Breadcrumb.Item>Treinamento</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col>
          <BigButton style={{ width: 120 }} onClick={() => history.push('/backoffice/register/training')}>
            Voltar
          </BigButton>
        </Col>
        {hasIndex(['training.add_training'], permissions) > -1 && (
          <Col>
            <BigButton
              style={{
                color: '#fff',
                backgroundColor: '#6600cc',
                border: 'none',
              }}
              onClick={() => setUploadFile(!uploadFile)}
            >
              Upload de arquivo
            </BigButton>
          </Col>
        )}
      </Row>

      <Row gutter={[25, 25]} style={{ marginTop: 30 }}>
        {hasIndex(['training.add_training'], permissions) > -1 && (
          <Col span={4}>
            <WorkspaceOption
              style={{
                background: 'rgb(239 239 239)',
                border: '2px solid #B670FC',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                width: '100%',
              }}
              onClick={() => setUploadFile(!uploadFile)}
            >
              <div style={{ width: '80%' }}>
                <FileAddOutlined />
                <WorkspaceName>Upload de arquivo</WorkspaceName>
              </div>
            </WorkspaceOption>
          </Col>
        )}
        {files &&
          files.result.length > 0 &&
          files.result.map((el: any) => (
            <Col span={4}>
              <WorkspaceOption style={{ width: '100%', cursor: 'initial' }}>
                <div style={{ width: '80%' }}>
                  <FilePptOutlined />
                  <WorkspaceName style={{ fontSize: '13px', wordBreak: 'break-all' }}>{el.name}</WorkspaceName>
                  <Typography.Text ellipsis={true}>
                    <WorkspaceName style={{ fontSize: 12, wordBreak: 'break-all' }}>
                      {el.name}.{el.extension}
                    </WorkspaceName>
                  </Typography.Text>
                </div>
                <ContainerIcon>
                  <Dropdown
                    overlay={
                      <Menu>
                        {hasIndex(['training.remove_training'], permissions) > -1 && (
                          <Popconfirm
                            onConfirm={() => handleDeleteFile(el.id)}
                            title={`Tem certeza que deseja excluir o arquivo ${el.name} ?`}
                            okText='Sim'
                            cancelText='Não'
                            placement='topRight'
                          >
                            <Menu.Item key='1' icon={<DeleteOutlined />}>
                              <Tooltip placement='top' title={'Deletar arquivo'}>
                                Deletar arquivo
                              </Tooltip>
                            </Menu.Item>
                          </Popconfirm>
                        )}
                        <Menu.Item key='2' icon={<EditOutlined />} onClick={() => window.open(el.file_path, '_blank')}>
                          Visualizar arquivo
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <Button size='small' shape='circle' icon={<MoreOutlined />} />
                  </Dropdown>
                </ContainerIcon>
              </WorkspaceOption>
            </Col>
          ))}
      </Row>

      <ContainerPaginationFooter>
        <Pagination current={files.page} total={files.total} onChange={changePagination} />
      </ContainerPaginationFooter>

      {uploadFile && (
        <ModalUploadFile
          folder={{ id: id }}
          visible={uploadFile}
          close={setUploadFile}
          reloadApi={(bool: any) => searchFiles()}
        />
      )}
      {loading && <Loading />}
    </ContainerList>
  )
}

export default TrainingFile
