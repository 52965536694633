import React from 'react'
import {
  Active,
  CirculeStatus,
  ContainerHistory,
  ContainerOff,
  ContainerPdv,
  ContainerPointer,
  ContainerStatus,
  ContainerTasks,
  ContainerUser,
  DataHistory,
  DatasUser,
  HR,
  HeaderInfo,
  HeaderUser,
  Info,
  Name,
  Point,
  TextHistory,
  TextHour,
  TextOff,
  TextPdv,
} from '../style'
import { Dropdown, Menu, Select } from 'antd'
import { MdAssignmentInd, MdCardTravel, MdCheck, MdMoreHoriz, MdStoreMallDirectory } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { CloseOutlined } from '@material-ui/icons'
import moment from 'moment'
import { StatusTAsks } from '..'
import Checkeble from './checkleble'

interface IProps {
  promoter: StatusTAsks
  date: string
  data: any
  setData: any
  user: {
    document: string
    workspace: string
    id: number
  }
  dataAll: any
  store: string[]
  total: number
}

export const TimeLine = ({ total, promoter, date, data, user, setData, dataAll, store }: IProps) => {
  const menu = (
    <Menu>
      <Menu.Item>
        <Link
          to={`/backoffice/teamById/${promoter.id}?date_start=${moment(date).format('YYYY-MM-DD')}&date_end=${moment(
            date
          ).format('YYYY-MM-DD')}`}
          onClick={() =>
            window.sessionStorage.setItem('tabRocket', JSON.stringify({ user: promoter.id.toString(), key: '1' }))
          }
        >
          Visão geral
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          to={`/backoffice/teamById/${promoter.id}?date_start=${moment(date).format('YYYY-MM-DD')}&date_end=${moment(
            date
          ).format('YYYY-MM-DD')}`}
          onClick={() =>
            window.sessionStorage.setItem('tabRocket', JSON.stringify({ user: promoter.id.toString(), key: '2' }))
          }
        >
          Visitas
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          to={`/backoffice/teamById/${promoter.id}?date_start=${moment(date).format('YYYY-MM-DD')}&date_end=${moment(
            date
          ).format('YYYY-MM-DD')}`}
          onClick={() =>
            window.sessionStorage.setItem('tabRocket', JSON.stringify({ user: promoter.id.toString(), key: '3' }))
          }
        >
          Roteiros
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          to={`/backoffice/teamById/${promoter.id}?date_start=${moment(date).format('YYYY-MM-DD')}&date_end=${moment(
            date
          ).format('YYYY-MM-DD')}`}
          onClick={() =>
            window.sessionStorage.setItem('tabRocket', JSON.stringify({ user: promoter.id.toString(), key: '5' }))
          }
        >
          Pesquisas
        </Link>
      </Menu.Item>
    </Menu>
  )

  const handleChangePdv = (value: string) => {
    let newData = dataAll.filter((el: any) => el.pdv.name === value)
    setData(newData)
  }

  return (
    <div style={{ height: '100%' }}>
      <ContainerUser>
        <HeaderUser>
          <DatasUser>
            <img src={`https://via.placeholder.com/150/ff872c/fff/?text=${promoter.name.substring(0, 1)}`} alt='user' />
            <ContainerStatus>
              <Name>{promoter.name}</Name>
              {promoter.nameTable != 'Sem Interação' ? (
                <ContainerOff>
                  <CirculeStatus style={{ backgroundColor: '#16c266' }} />
                  <TextOff>Online</TextOff>
                </ContainerOff>
              ) : (
                <ContainerOff>
                  <CirculeStatus />
                  <TextOff>Offline</TextOff>
                </ContainerOff>
              )}
            </ContainerStatus>
            <Active>ATIVO</Active>
          </DatasUser>
          <Dropdown overlay={menu} placement='bottomLeft'>
            <MdMoreHoriz size={20} style={{ cursor: 'pointer' }} />
          </Dropdown>
        </HeaderUser>
        <HeaderInfo>
          <Info>
            <MdAssignmentInd />
            {user.document}
          </Info>
          <Info>
            <MdCardTravel />
            {user.workspace}
          </Info>
        </HeaderInfo>
      </ContainerUser>
      <ContainerTasks>
        <div style={{ marginRight: 30 }}>
          <MdStoreMallDirectory />
          {total} lojas total
        </div>
        <div>
          <MdCheck />
          {data && data.length > 0 && data.filter((item) => item.status === 'COMPLETE').length} lojas concluídas
        </div>
      </ContainerTasks>
      {/* <Checkeble tagsData={['Check-in', 'Check-out', 'Bateria', 'Formulário', 'Sincronização']} /> */}
      {data.length > 0 ? (
        <>
          <ContainerPdv>
            {data.length > 0 && (
              <>
                <Select defaultValue={data[0].pdv.name} onChange={handleChangePdv}>
                  {store.map((el: any, index: number) => (
                    <Select.Option value={el} key={index}>
                      {el}
                    </Select.Option>
                  ))}
                </Select>
                <CloseOutlined
                  style={{ color: 'red', fontSize: 20, cursor: 'pointer' }}
                  onClick={() => setData(dataAll)}
                />
              </>
            )}
            <TextPdv>Hora</TextPdv>
          </ContainerPdv>
          <ContainerHistory>
            {data.length > 0 &&
              data.filter((item: any) => item.status != 'PENDENT').map((el: any, index: number) => (
                <DataHistory key={index}>
                  {el.status === 'IN_PROGRESS' && (
                    <>
                      <TextHistory>
                        <ContainerPointer>
                          <Point />
                          <HR />
                        </ContainerPointer>
                        <p>
                          Fez check-in no PDV <b>{el.pdv.name}</b>
                        </p>
                      </TextHistory>
                      <TextHour>{el.check_in}</TextHour>
                    </>
                  )}

                  {el.status === 'COMPLETE' && (
                    <>
                      <TextHistory>
                        <ContainerPointer>
                          <Point />
                          <HR />
                        </ContainerPointer>
                        <p>
                          Fez check-in no PDV <b>{el.pdv.name}</b>
                        </p>
                      </TextHistory>
                      <TextHour>{el.check_in}</TextHour>
                    </>
                  )}

                  {el.status === 'JUSTIFIED_ABSENCE' && (
                    <>
                      <TextHistory>
                        <ContainerPointer>
                          <Point />
                          <HR />
                        </ContainerPointer>
                        <p>
                          Fez check-in no PDV <b>{el.pdv.name}</b>
                        </p>
                      </TextHistory>
                      <TextHour>{el.check_in}</TextHour>
                    </>
                  )}
                  {el.status === 'COMPLETE' && (
                    <>
                      <TextHistory>
                        <ContainerPointer>
                          <Point />
                          <HR />
                        </ContainerPointer>
                        <p>
                          Fez check-out no PDV <b>{el.pdv.name}</b>
                        </p>
                      </TextHistory>
                      <TextHour>{el.check_out}</TextHour>
                    </>
                  )}
                </DataHistory>
              ))}
          </ContainerHistory>
        </>
      ) : (
        <h3>Sem histórico de ações</h3>
      )}
    </div>
  )
}
