import React, { useState } from 'react'
import { exportXls } from '../../../utils/funcoes'
import DefaultPage from '../../../components/DefaultPage'
import qs from 'querystring'
import moment from 'moment'
import useReactRouter from 'use-react-router'

const LunchListPage: React.FC = () => {
  const { location } = useReactRouter()

  const [reload] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  function convertMinutesToTime(minutes) {
    let hours = Math.floor(minutes / 60);
    let remainingMinutes = Math.floor(minutes % 60);
    let seconds = Math.floor((minutes % 1) * 60);

    let formattedTime = `${hours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    
    return formattedTime;
  }

  let convertUrlToExportXls = (defaultUrl?: any) => {
    try {
      let getUrl: any = qs.parse(location.search.replaceAll('?', ''))

      let filterQuery: any = {}
      for (var el in getUrl) {
        if (el === 'date') {
          filterQuery['start_date'] = JSON.parse(getUrl[el])[0]
          filterQuery['end_date'] = JSON.parse(getUrl[el])[1]
        } else {
          let convert = JSON.parse(getUrl[el])
          if (convert && convert.length > 0) {
            filterQuery[el] = convert.map((e: any) => e.value)
          } else {
            filterQuery[el] = JSON.parse(getUrl[el]).value
          }
        }
      }

      if (!filterQuery['start_date'] || !filterQuery['end_date']) {
        if (Object.keys(defaultUrl).length > 0) {
          Object.keys(defaultUrl).forEach((el: any) => {
            if (el === 'date' && defaultUrl[el].length === 2 && !filterQuery.date) {
              filterQuery['start_date'] = defaultUrl[el][0]
              filterQuery['end_date'] = defaultUrl[el][1]
            }
          })
        }
      }


      return filterQuery
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <DefaultPage
      titlePage='Almoço'
      breadcrumb={[{ name: 'Home', url: '/' }, { name: 'Almoço' }]}
      urlPage={`/backoffice/lunch`}
      routeApi={{
        method: 'get',
        route: 'lunch',
        versionApi: 'v2',
        reload,
      }}
      listButtons={{

        buttonExport: {
          visible: true,
          type: 'function',
          function: async () => {
            setLoading(true)
            await exportXls(convertUrlToExportXls(), 'lunch')
            setLoading(false)
          },
        },

        buttonFilter: {
          visible: true,
          filters: [
            {
              label: 'Selecione o promotor:',
              name: 'promoter',
              type: 'search',
              urlSearch: 'promoters',
              getEnv: 'label',
            },
            {
              label: 'Selecione um período',
              name: 'date',
              type: 'rangePicker',
            },
          ],
        },

      }}
      load={{ loading, setLoading }}
      columns={[
        {
          title: 'Dia',
          dataIndex: 'dt_ref',
          render: (value) => {
            let date = ''
            if(value) date = moment(value).format('DD/MM/YYYY')
            return date
          }
        },
        {
          title: 'Promotor',
          dataIndex: 'name',
        },
        {
          title: 'Início',
          dataIndex: 'lunch_check_in',
          render: (value) => {
            let date = ''
            if(value) date = moment(value).format('HH:mm:ss')
            return date
          }
        },
        {
          title: 'Término',
          dataIndex: 'lunch_check_out',
          render: (value) => {
            let date = ''
            if(value) date = moment(value).format('HH:mm:ss')
            return date
          }
        },
        {
          title: 'Tempo total',
          dataIndex: 'lunch_total_time',
          render: (value) => {
            let date = ''
            if(value) date = convertMinutesToTime(value)
            return date
          }
        },
       
      ]}
    />
  )
}

export default LunchListPage
