import React, { useState, useRef } from 'react'
import { Button, ContainerModal, ContainerStep2, LabelCheck } from './style'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import { message, Spin, Table, Button as ButtonATD, Input, Space } from 'antd'
import { Loading } from '../Loading'
import { Search } from '../form/SimpleSearch'
import api_v2 from '../../services/api-v2'
import { SearchOutlined } from '@ant-design/icons'
import type { FilterConfirmProps } from 'antd/es/table/interface'
import type { ColumnsType, ColumnType } from 'antd/es/table'

interface IProps {
  visible: boolean
  close: (boolean: any) => void
  visit: any
  reloadApi?: (boolean: any) => void
}

interface DataType {
  key: number
  name: string
}

type DataIndex = keyof DataType

export const ModalAddTask = ({ visible, close, visit, reloadApi }: IProps) => {
  const formRef = useRef<FormHandles>(null)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingTable, setLoadingTable] = useState<boolean>(false)
  const [dataProduct, setDataProduct] = useState<DataType[]>([])
  const [loadingForm, setLoadingForm] = useState<boolean>(false)
  const [form, setForm] = useState<any>({})
  const [config, setConfig] = useState<any>(undefined)
  let getWorkspace = sessionStorage.getItem('@rocketpdv:workspace')
  let newGetWorkspace = JSON.parse(getWorkspace!.toString())
  const searchInput = useRef<any>(null)

  async function handleSubmit(body: any) {
    setLoading(true)
    try {
      if (newGetWorkspace.shared_environment && !body.sub_workspace_id)
        return message.error('É necessário selecionar a empresa')

      let data: any = {
        form_id: form.id,
        visit_id: visit.id,
      }
      if (body.sub_workspace_id) data.sub_workspace_id = body.sub_workspace_id.value
      if (form.context === 'PRODUCT') data.product = selectedRowKeys
      await api_v2.post(`task/create-task`, data)
      message.success(`Formulário adicionada com sucesso`)
      close(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
      if (err instanceof Yup.ValidationError) {
        message.error('Erro ao adicionar Formulário!')
      }
    }
    setLoading(false)
  }

  const getProducts = async (sub_workspaces?: number[]) => {
    const { data } = await api_v2.post(
      `product/all${sub_workspaces ? `?sub_workspaces=${JSON.stringify(sub_workspaces)}` : ''}`
    )

    setDataProduct(data)
  }

  const getForm = async (selectedForm: any) => {
    setLoadingForm(true)
    const { data } = await api_v2.get(`/form/${selectedForm.value}`)
    setForm(data)
    if (data.firebase_body?.sub_workspace_is_all === 'SELECTEDS' && data.firebase_body?.sub_workspaces_id) {
      if (data.context === 'PRODUCT') getProducts(data.firebase_body.sub_workspaces_id)
      setConfig(`&sub_workspaces=${JSON.stringify(data.firebase_body.sub_workspaces_id)}`)
    } else {
      if (data.context === 'PRODUCT') getProducts()
      setConfig(undefined)
    }

    if (data.context !== 'PRODUCT') setDataProduct([])

    setLoadingForm(false)
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log(newSelectedRowKeys)
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm()
    console.log(dataIndex)
  }
  const handleReset = (clearFilters: () => void) => {
    clearFilters()
  }

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`nome do produto`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <ButtonATD
            type='primary'
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Filtrar
          </ButtonATD>
          <ButtonATD onClick={() => clearFilters && handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Limpar
          </ButtonATD>
        </Space>
      </div>
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
  })

  const columns: ColumnsType<DataType> = [
    {
      title: 'Name do produto',
      dataIndex: 'name',
      ...getColumnSearchProps('name'),
    },
  ]

  const start = () => {
    setLoadingTable(true)
    setTimeout(() => {
      setSelectedRowKeys([])
      setLoadingTable(false)
    }, 1000)
  }

  const hasSelected = selectedRowKeys.length > 0

  return (
    <ContainerModal
      visible={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
      destroyOnClose={true}
      width={form.context === 'PRODUCT' ? '800px' : undefined}
      style={{
        top: 20,
      }}
    >
      {loading && <Loading />}
      <Form className='form' ref={formRef} initialData={{ is_active: true }} onSubmit={handleSubmit}>
        <ContainerStep2>
          <h3>Adicionar de formulário</h3>
          <LabelCheck>Selecione o formulário</LabelCheck>
          <Search placeholder='Selecione o formulário' name='form_id' path='forms' isMult={false} blur={getForm} />
          {loadingForm ? (
            <div
              style={{
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <Spin />
            </div>
          ) : (
            <>
              {newGetWorkspace.shared_environment && Object.keys(form).length > 0 && (
                <div
                  style={{
                    marginTop: 10,
                    width: '100%',
                  }}
                >
                  <LabelCheck>Selecione a empresa</LabelCheck>
                  <Search
                    placeholder='Selecione o empresa'
                    name='sub_workspace_id'
                    path='sub-workspaces'
                    isMult={false}
                    config={config}
                  />
                </div>
              )}
              {form && form.context === 'PRODUCT' && (
                <div
                  style={{
                    marginTop: 10,
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginTop: 10,
                    }}
                  >
                    <ButtonATD
                      onClick={() => {
                        let all = dataProduct.map((product) => product.key)
                        setSelectedRowKeys(all)
                      }}
                      loading={loading}
                    >
                      Selecionar todos
                    </ButtonATD>
                    <ButtonATD onClick={start} disabled={!hasSelected} loading={loading}>
                      Limpar
                    </ButtonATD>
                  </div>
                  {/* <Search
                    placeholder='Selecione o produto'
                    name='product_id'
                    path='products'
                    isMult={true}
                    config={config}
                  /> */}
                  <Table
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={dataProduct}
                    loading={loadingTable}
                  />
                </div>
              )}
            </>
          )}
          <Button type='submit' style={{ width: '100%', height: '55px', marginTop: 20 }}>
            Adicionar
          </Button>
        </ContainerStep2>
      </Form>
    </ContainerModal>
  )
}
