import styled, { createGlobalStyle } from 'styled-components'
import theme from './styles/theme'

export const GlobalStyles = createGlobalStyle`

  @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;1,700&display=swap');
  * {
    font-family: 'Open Sans', sans-serif !important;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none !important;
  }
  a {
    text-decoration: none;
  }

  p{
    margin-bottom: 0;
  }

  .ant-pagination .mini .ant-table-pagination .ant-table-pagination-right {
    max-width: 450px !important;
  }

  .ant-popover-inner-content{
    cursor: initial !important;
  }

  .ant-switch-checked {
    background-color: #6e55cc !important;
  }

  .ant-menu-overflow-item .ant-menu-overflow-item-rest {
    z-index: 9999 !important;
  }

  
  body {
    scroll-behavior: smooth;
    font-family: AtlasGrotesk,sans-serif !important;
    text-rendering: optimizelegibility !important;
    -webkit-font-smoothing: antialiased !important;
    background: #F8F8F8;
    color: #666;
  }

  body, html, #root {
    height: 100%;
    position: relative;
    overflow-x: hidden;
  }

  button {
    cursor: pointer;
  }

  .circle-bg {
    fill: none;
    stroke: #c9d3dd;
    stroke-width: 2.8;
  }

  .ril__captionContent {
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
  }

  /* .ant-picker-input{
    border: none !important;
  } */

  .full-bar-dash {
    overflow-x: scroll;
  }

  .full-bar-dash::-webkit-scrollbar {
    width: 1px;
    height: 7px;
  }

  .full-bar-dash::-webkit-scrollbar-thumb {
    background-color: #b56aff;
    border: 1px solid #b56aff;
    border-radius: 5px;
    height: 2px;
    width: 2px;
  }

  /* .searchBox >  .ant-select-dropdown {
    background-color: rgba(99, 76, 185, 0.54) !important;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
    backdrop-filter: blur(5.7px) !important;
    -webkit-backdrop-filter: blur(5.7px) !important;
  } */

  .circle {
    fill: none;
    stroke-width: 1.8;
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
    stroke: #7158c1;
  }

  .ant-select-selector{
    height: auto !important;
  }

  .ant-select-show-search{
    height: auto !important;
  }

  .ant-select {
    height: auto !important;
  }

  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    height: auto !important;
  }

  .layout-align-start-end {
    display: none !important;
  }

  .layout-row {
    display: none !important;
  }

  .noScrool {
    position: absolute;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 99999999;
  }


  .ant-card-body {
    padding: 10px !important;
  }

  .ant-btn-primary{
    background-color: #6d55cc;
    border-color: #6d55cc;
  }

  .ant-table-cell {
    color: #85868b;
  }

  .ant-modal-footer{
    text-align: left !important;
  }

  .unchecked-tag {
    border: 1px solid #6d55cc;
    color: #6d55cc;
  }
  
  .checked-tag {
    border: 1px solid #6d55cc;
    background-color: #6d55cc;
    color: #fff;
  }


    .site-description-item-profile-wrapper {
      margin-bottom: 7px;
      color: rgba(0, 0, 0, 0.65);
      font-size: 14px;
      line-height: 1.5715;
    }

    [data-theme='compact'] .site-description-item-profile-wrapper {
      font-size: 12px;
      line-height: 1.66667;
    }

    .ant-drawer-body p.site-description-item-profile-p {
      display: block;
      margin-bottom: 16px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 16px;
      line-height: 1.5715;
    }

    [data-theme='compact'] .ant-drawer-body p.site-description-item-profile-p {
      font-size: 14px;
      line-height: 1.66667;
    }

    .site-description-item-profile-p-label {
      display: inline-block;
      margin-right: 8px;
      color: rgba(0, 0, 0, 0.85);
    }


  .ant-select {
    width: 100%;
  }
  .ant-picker-range{
    width: 100%;
  }
  .ant-picker-input {
    width: 100%;
    input{
      border: none !important;
    }
  }
  .ant-modal-content{
        border-radius: 15px !important;
    }

  .ant-picker.ant-picker-borderless {
    padding: 0;
  }
  /* Borda dos inputs com erro. */
  .has-error .ant-input-affix-wrapper .ant-input, .has-error .ant-select-selection {
    border-color: #ff7070;
  }

  /* Mensagem de erro */
  .has-error .ant-form-explain, .has-error .ant-form-split {
    color: #ff5962;
    font-size: 12px;
  }

  /* Tabs */

  .ant-tabs-ink-bar {
    background-color:${theme.colors.purple};
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    color: ${theme.colors.purple};
    font-weight: 600;
  }

  .ant-tabs-nav .ant-tabs-tab:hover {
    color: ${theme.colors.purple};
  }


  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #6600cc !important;
    min-width: 80px;
    font-size: 16px;
    text-align: center;
  }

  .ant-tabs-tab-btn {
    min-width: 80px;
    font-size: 16px;
    text-align: center;
    color: #6f7782;
  }

  .ant-table-cell-fix-right {
    background: none !important;
  }

  .ant-table{
      background-color: #f8f8f8 !important;
  }

  .ant-table-thead > tr > th{
      border-bottom: 2px solid #d6d6d6 !important;
  }

  .ant-table-tbody > tr > td{
      border-bottom: 2px solid #d6d6d6 !important;
  }
@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

@media (max-width: 600px) {
    .ocultXs{
      display: none;
    }

    .centerXs {
      text-align: center;
    }
}

  .ant-btn-danger {
    color: white !important;
    background: #f5222d !important;
    border-color: #f5222d !important;

    :hover {
      opacity: .7;
    }
  }

  /* Modal antd */

  .ant-modal-body {
    /* max-height: 300px;
    overflow: auto;
    /* padding: 20px 0;
    margin: 0 20px; */
  }

  .ant-modal-header {
    background: ${theme.colors.purple};
  }

  .ant-modal-title {
    font-weight: 600;
    color: #fff;
  }

  /* .ant-modal-close-x {
    color: #fff;
    font-weight: 600;
  } */

  /* Formulário antd */

  .ant-form-item-label {
    line-height: unset;
    text-transform: uppercase;

    label {
      font-size: 11px;
      font-weight: 700;
      color: ${theme.colors.purple};
    }
  }

  .ant-form-item-required::before {
    font-size: 10px;
    color: ${theme.colors.purple};
  }

  option {
    z-index: 999999999999;
  }

  .highlightDrag {
    opacity: 0.3;
  }

  .form {

    textarea {
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    height: 38px;
    padding: 0 15px;
    color: rgba(0, 0, 0, 0.65);
    margin: 0 0 10px;
    width: 100%;
    display: inline-block;
    }

.ant-select-selector {
  min-height: 38px !important;
  display: flex !important;
  align-items: center !important;
}

.input-file {
  background: none;
  border: none;
  padding:0
}

    input {
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    height: 38px;
    padding: 0 15px;
    color: rgba(0, 0, 0, 0.65);
    /* margin: 0 0 10px; */
    width: 100%;
    display: inline-block;

    &::placeholder {
      color: #d9d9d9;
    }
    &:hover,
    &:focus {
      border-color: #1890ff;
    }
    }

    .checkbox {
      input[type="checkbox"] {
       height: 15px;
      }
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
    }

    .rowScop .ant-checkbox-wrapper {
      width: 100%;
      /* display: flex; */
      /* justify-content: space-between; */
      /* flex-direction: row-reverse; */

      font-size: 15px;

      input[type="checkbox"] {
        width: 15px;
      }
    }

    .rowScop .ant-checkbox-wrapper::after {
      display: none;
    }

    .choice {

      input[type="checkbox"] {
        width: 15px;
      }


      label {
        float: left;
        width: 97%;

      }
    }
  }
`
export const customStyles = {
  control: (base: any) => ({
    ...base,
    height: '36px',
    minHeight: '39px',
    whiteSpace: 'nowrap',
    '&:hover': {
      borderColor: '#1890ff',
      color: '#000',
    },
  }),
  option: (provided: any) => ({
    ...provided,
    height: 32,
    whiteSpace: 'nowrap',
    '&:hover': {
      background: '#1890ff',
      color: '#000',
    },
  }),
  input: (provided: any) => ({
    ...provided,
    height: '36px',
    fontSize: '14px',
    whiteSpace: 'nowrap',
    color: '#808080',
    // position: 'absolute',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: 'hsl(262, 19%, 88%)',
    position: 'sticky',
    whiteSpace: 'nowrap',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    whiteSpace: 'nowrap',
    position: 'sticky',
    color: '#808080',
  }),
}

export const Container = styled.div`
  width: 100%;

  &&& .ant-collapse-borderless {
    background-color: transparent !important;
  }

  h1,
  p {
    padding: 0px 10px;
  }

  .breadcumb {
    padding: 0px 10px;
  }

  form {
    margin-top: 15px;
    font-weight: 600;
    .ant-row {
      padding: 0.6rem;
    }
    button {
      text-align: center;
    }
    label {
      font-weight: 600;
      margin-bottom: 5px;
    }
    input {
      background: #fff;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      color: rgba(0, 0, 0, 0.65);
      width: 100%;
      display: inline-block;
    }
    select {
      background: #fff;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      margin: 0.2rem;

      padding: 0 5px;
      color: rgba(0, 0, 0, 0.65);
      width: 100%;
      display: inline-block;

      &::placeholder {
        color: #d9d9d9;
      }
      &:hover,
      &:focus {
        border-color: #1890ff;
      }
    }
  }
`
export const Label = styled.label`
  font-size: 14px;
  font-weight: 600;
`

export const BigButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6f7782;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #6f7782;
  background-color: #f9f9f9;
  font-size: 13px;
  font-weight: 400;
  padding: 5px;

  :hover {
    transition: all 0.3s;
    opacity: 0.6;
  }
`

export const ContainerList = styled.div`
  border-radius: 12px;
  border: 1px solid #d6d6d6;
  width: 100%;
  margin-top: 27px;
  padding: 26px 32px;

  .ocult {
    display: none;
  }
  
  h1 {
    color: #272d3b !important;
    font-family: 'Open Sans';
    font-size: 15px !important;
    font-weight: 600 !important;
    font-style: normal !important;
  }

  &&& .ant-table-thead > tr > th {
    background: #f8f8f8 !important;
  }
`

export const ContainerTableDefault = styled.div<any>`
  @media (max-width: 850px) {
    max-width: 100%;
    width: ${({ width }) => (width ? `calc(${width}px - 10vw)` : '100%')};
    overflow: auto;
  }
`

export const ContainerButtonsFootter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ContainerFormFilter = styled.div`
  height: 0%;
`

export const GroupForm = styled.div`
  margin-bottom: 15px;
  width: 100%;
`

export const DivGroupPopOver = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-height: 30px;
  cursor: pointer;

  svg {
    margin-right: 10px;
  }
  p {
    font-size: 14px;
  }
`

export const AlignCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const DivGroupTextIcon = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-height: 30px;
  justify-content: center;
  color: #6702cc;

  svg {
    margin-right: 10px;
  }
  p {
    font-size: 14px;
    text-transform: uppercase;
  }
`
