import moment from 'moment'
import { useState } from 'react'
import DefaultPage from '../../../components/DefaultPage'
import { exportXls } from '../../../utils/funcoes'
import qs from 'querystring'
import useReactRouter from 'use-react-router'

export interface ProductFilter {
  name?: string
  brand?: string
  product_type?: string
  category?: string
  is_active?: any
}

const StatusDay = () => {
  const { location } = useReactRouter()
  const [loading, setLoading] = useState(false)
  const [reload] = useState(false)

  let convertUrlToExportXls = (defaultUrl?: any) => {
    try {
      let getUrl: any = qs.parse(location.search.replaceAll('?', ''))

      let filterQuery: any = {}
      for (var el in getUrl) {
        if (el === 'date') {
          filterQuery['start_date'] = JSON.parse(getUrl[el])[0]
          filterQuery['end_date'] = JSON.parse(getUrl[el])[1]
        } else {
          let convert = JSON.parse(getUrl[el])
          if (convert && convert.length > 0) {
            filterQuery[el] = convert.map((e: any) => e.value)
          } else {
            filterQuery[el] = JSON.parse(getUrl[el]).value
          }
        }
      }

      if (!filterQuery['start_date'] || !filterQuery['end_date']) {
        if (Object.keys(defaultUrl).length > 0) {
          Object.keys(defaultUrl).forEach((el: any) => {
            if (el === 'date' && defaultUrl[el].length === 2 && !filterQuery.date) {
              filterQuery['start_date'] = defaultUrl[el][0]
              filterQuery['end_date'] = defaultUrl[el][1]
            }
          })
        }
      }

      console.log(filterQuery)

      return filterQuery
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <DefaultPage
      titlePage='Status Day'
      breadcrumb={[{ name: 'Home', url: '/' }, { name: 'Status Day' }]}
      urlPage='/backoffice/monitory/status-day'
      routeApi={{
        method: 'get',
        route: 'status-day',
        versionApi: 'v2',
        reload,
      }}
      listButtons={{
        buttonFilter: {
          visible: true,
          filters: [
            {
              label: 'Selecione um período',
              name: 'date',
              type: 'rangePicker',
            },
            {
              label: 'Selecione o promotor',
              name: 'user_id',
              type: 'search',
              urlSearch: 'promoters',
              getEnv: 'value',
            },
            {
              label: 'Selecione a cidade',
              name: 'city',
              type: 'search',
              urlSearch: 'city',
              getEnv: 'value',
            },
            {
              label: 'Selecione o estado',
              type: 'select',
              name: 'state',
              getEnv: 'value',
              options: [
                { value: 'AC', label: 'AC' },
                { value: 'AL', label: 'AL' },
                { value: 'AP', label: 'AP' },
                { value: 'AM', label: 'AM' },
                { value: 'BA', label: 'BA' },
                { value: 'CE', label: 'CE' },
                { value: 'DF', label: 'DF' },
                { value: 'ES', label: 'ES' },
                { value: 'GO', label: 'GO' },
                { value: 'MA', label: 'MA' },
                { value: 'MT', label: 'MT' },
                { value: 'MS', label: 'MS' },
                { value: 'MG', label: 'MG' },
                { value: 'PA', label: 'PA' },
                { value: 'PB', label: 'PB' },
                { value: 'PR', label: 'PR' },
                { value: 'PE', label: 'PE' },
                { value: 'PI', label: 'PI' },
                { value: 'RJ', label: 'RJ' },
                { value: 'RN', label: 'RN' },
                { value: 'RS', label: 'RS' },
                { value: 'RO', label: 'RO' },
                { value: 'RR', label: 'RR' },
                { value: 'SC', label: 'SC' },
                { value: 'SP', label: 'SP' },
                { value: 'SE', label: 'SE' },
                { value: 'TO', label: 'TO' },
              ],
            },
          ],
        },
        buttonExport: {
          visible: true,
          type: 'function',
          function: async () => {
            setLoading(true)
            exportXls(
              convertUrlToExportXls({
                date: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
              }),
              'status-day'
            )
            setLoading(false)
          },
        },
      }}
      load={{ loading, setLoading }}
      columns={[
        {
          title: 'Nome',
          dataIndex: 'colaborador',
        },
        {
          title: 'Dia de referência',
          dataIndex: 'dia_referencia',
          render(value) {
            return moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY')
          },
        },
        {
          title: 'Ativo mobile?',
          dataIndex: 'mobile_ativo',
        },
        {
          title: 'Logou mobile?',
          dataIndex: 'logou_mobile',
        },
        {
          title: 'Respondeu pesquisa?',
          dataIndex: 'total_de_pesquisas_respondidas',
          render(value) {
            return value ? 'Sim' : 'Não'
          },
        },
        {
          title: 'Tem roteiro?',
          dataIndex: 'tem_roteiro',
        },
        {
          title: 'Total de visitas',
          dataIndex: 'total_de_visitas',
        },
      ]}
    />
  )
}

export default StatusDay
