import React, { useEffect, useState } from 'react'
import { Container, TextError, Title } from './styles'
import moment from 'moment'
import 'moment/locale/pt-br'
import { DatePicker } from 'antd'

moment.locale('pt-BR')

interface IQuestion {
  id: number
  is_required: boolean
  name: string
  order: string
  type: string
}
interface IDataQuestion {
  dataQuestion: IQuestion
  handleAnswers: (value: any, dataQuestion: IQuestion) => void
  error?: boolean
  checkRequired: (value: any) => void
  defaultVal?: any
}

export const DateForm = ({ dataQuestion, handleAnswers, error, checkRequired, defaultVal }: IDataQuestion) => {
  const [value, setValue] = useState<any>()
  const [isRender, setIsRender] = useState(false)

  const handleValue = (value: any, dateString: string) => {

    setValue(dateString)
    handleAnswers([dateString], dataQuestion)
  }

  useEffect(() => {
    if (dataQuestion.is_required && !isRender) {
      setIsRender(true)
      checkRequired(dataQuestion.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    const val = defaultVal && defaultVal[0] ? defaultVal[0] : undefined
    if(val) {
      handleValue('', val)
    }
  }, [defaultVal])

  return (
    <Container>
      <Title>
        {dataQuestion.order} - {dataQuestion.name} {dataQuestion.is_required && '*'}
      </Title>

      <DatePicker onChange={handleValue} format='DD/MM/YYYY' style={{ width: '100%' }} value={value ? moment(value, 'DD/MM/YYYY') : undefined}/>
      {error && !value && <TextError>Campo obrigatório</TextError>}
    </Container>
  )
}
