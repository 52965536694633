import React, { useEffect, useRef, useState } from 'react'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import LayoutWorkspace from '../components/LayoutWorkspace'
import { getDataUser } from '../utils/login'
import { Container } from '../GlobalStyles'
import { Wrapper } from './WorkspacePage/styles'
import history from '../routes/history'
import api_v2 from '../services/api-v2'
import { Active } from '../components/ModalKanban/style'
import { SearchOutlined } from '@ant-design/icons';
import { Button, Dropdown, Input, Menu, message, Popconfirm, Space, Table, Modal, Row, Col, Spin } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { DeleteOutlined, MoreOutlined, } from '@ant-design/icons'
import { Mixpanel } from '../utils/mixpanel'
import { FaPen } from 'react-icons/fa'
import { IoMdInformationCircleOutline } from 'react-icons/io'
import moment from 'moment'

const AdminWork: React.FC = () => {
  const [response, setResponse] = useState([])
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingInfo, setLoadingInfo] = useState<boolean>(false)
  const [info, setInfo] = useState<boolean>(false)
  const [dataInfo, setDataInfo] = useState<any>({})
  const [totalPromoters, setTotalPromotes] = useState<number>(0)
  const [select, setSelect] = useState<any>({})
  const searchInput = useRef<any>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: any) => void,
    dataIndex: any,
  ) => {
    confirm();
  };

  const getData = async () => {
    try {
      setLoading(true)

      const { data } = await api_v2.get(`/admin`)
      setResponse(data)
    } catch (error) {

    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const getUser = getDataUser()
    if (getUser.id != 11) history.push('/backoffice/workspace')
    getData()
  }, [])

  const renderBoolean = (value) => {
    if (value) {
      return (
        <Active>Sim</Active>
      )
    } else {
      return <Active style={{ color: '#e9830f', borderColor: '#e9830f', backgroundColor: '#edbf95' }}>Não</Active>
    }
  }

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={`${selectedKeys[0] || ''}`}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Pesquisar
          </Button>
          <Button
            onClick={() => {
              clearFilters()
              confirm()
            }}
            size="small"
            style={{ width: 90 }}
          >
            Resetar
          </Button>


        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    }
  });

  const columns: ColumnsType<any> = [
    {
      title: 'Nome',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Ambiente bloqueado',
      dataIndex: 'is_block',
      sorter: (a, b) => a.is_block.localeCompare(b.is_block),
      render: (value) => renderBoolean(value)
    },
    {
      title: 'Possui tela de ruptura',
      dataIndex: 'view_rupture',
      sorter: (a, b) => a.view_rupture.localeCompare(b.view_rupture),
      render: (value) => renderBoolean(!value)
    },
    {
      title: 'Ambiente compartilhado',
      dataIndex: 'shared_environment',
      sorter: (a, b) => a.shared_environment.localeCompare(b.shared_environment),
      render: (value) => renderBoolean(value)
    },
    {
      title: 'Não adicionar empresa em visita',
      dataIndex: 'not_add_subworkspace_visit',
      sorter: (a, b) => a.not_add_subworkspace_visit.localeCompare(b.not_add_subworkspace_visit),
      render: (value) => renderBoolean(value)
    },
    {
      title: 'Adicionar visita',
      dataIndex: 'is_add_visit',
      sorter: (a, b) => a.is_add_visit.localeCompare(b.is_add_visit),
      render: (value) => renderBoolean(value)
    },
    {
      title: 'Check-in obrigatório',
      dataIndex: 'required_check_in',
      sorter: (a, b) => a.required_check_in.localeCompare(b.required_check_in),
      render: (value) => renderBoolean(value)
    },
    {
      title: 'Check-out obrigatório',
      dataIndex: 'required_check_out',
      sorter: (a, b) => a.required_check_out.localeCompare(b.required_check_out),
      render: (value) => renderBoolean(value)
    },
    {
      title: 'Possui horário de almoço',
      dataIndex: 'has_lunch',
      sorter: (a, b) => a.has_lunch.localeCompare(b.has_lunch),
      render: (value) => renderBoolean(value)
    },
    {
      title: '#',
      width: 50,
      render: (obj: any) => {
        return (
          <Dropdown
            overlay={
              <Menu>

                <Menu.Item key='1' icon={<DeleteOutlined />}>
                  <Popconfirm
                    title={`Tem certeza que deseja deletar a empresa ${obj.name} `}
                    onConfirm={async () => {
                      Mixpanel.track('Confirmou deletar ambiente administrativo,', { params: { obj } })
                      // const { data } = await api_v2.delete(`/workspace/${obj.id}`)
                      message.success('Sucesso ao deletar ambiente.')
                      await getData()

                    }}
                    okText='Sim'
                    cancelText='Não'
                    placement='topRight'
                  >
                    Deletar empresa
                  </Popconfirm>
                </Menu.Item>
                <Menu.Item key='2' icon={<FaPen />}>
                  <Popconfirm
                    title={`Tem certeza que deseja ${obj.is_block ? 'desbloquear' : 'bloquear'} a empresa ${obj.name} `}
                    onConfirm={async () => {
                      Mixpanel.track('Confirmou bloquear ambiente administrativo,', { params: { obj } })
                      await api_v2.post(`/admin/update/${obj.id}`, {
                        is_block: !obj.is_block
                      }, {
                        headers: {
                          workspace: obj.slug,
                          workspaceId: obj.id
                        }
                      })
                      message.success(`Sucesso ao ${obj.is_block ? 'desbloquear' : 'bloquear'} ambiente.`)
                      await getData()

                    }}
                    okText='Sim'
                    cancelText='Não'
                    placement='topRight'
                  >
                    {obj.is_block ? 'Desbloquear' : 'Bloquear'} empresa
                  </Popconfirm>
                </Menu.Item>
                <Menu.Item key='2' icon={<FaPen />}>
                  <Popconfirm
                    title={`Tem certeza que deseja ${!obj.view_rupture ? 'remover' : 'adicionar'} tela de ruptura da empresa ${obj.name} `}
                    onConfirm={async () => {
                      Mixpanel.track('Confirmou view_rupture ambiente administrativo,', { params: { obj } })
                      await api_v2.post(`/admin/update/${obj.id}`, {
                        view_rupture: !obj.view_rupture,
                        updated_at: new Date()
                      }, {
                        headers: {
                          workspace: obj.slug,
                          workspaceId: obj.id
                        }
                      })
                      message.success(`Sucesso ao ${!obj.view_rupture ? 'remover' : 'adicionar'} tela de ruptura da empresa.`)
                      await getData()

                    }}
                    okText='Sim'
                    cancelText='Não'
                    placement='topRight'
                  >
                    {!obj.view_rupture ? 'Remover' : 'Adicionar'} tela de ruptura
                  </Popconfirm>
                </Menu.Item>

                <Menu.Item key='2' icon={<FaPen />}>
                  <Popconfirm
                    title={`${obj.not_add_subworkspace_visit ? 'Não' : ''} permitir adicionar subworkspace na visita da empresa ${obj.name} `}
                    onConfirm={async () => {
                      Mixpanel.track('Confirmou not_add_subworkspace_visit ambiente administrativo,', { params: { obj } })
                      await api_v2.post(`/admin/update/${obj.id}`, {
                        not_add_subworkspace_visit: !obj.not_add_subworkspace_visit,
                        updated_at: new Date()
                      }, {
                        headers: {
                          workspace: obj.slug,
                          workspaceId: obj.id
                        }
                      })
                      message.success(`${obj.not_add_subworkspace_visit ? 'Não' : ''} permitir adicionar subworkspace na visita da empresa ${obj.name} `)
                      await getData()

                    }}
                    okText='Sim'
                    cancelText='Não'
                    placement='topRight'
                  >
                    {obj.not_add_subworkspace_visit ? 'Não' : ''} adicionar subworkspace na visita
                  </Popconfirm>
                </Menu.Item>

                <Menu.Item key='2' icon={<FaPen />}>
                  <Popconfirm
                    title={`${obj.is_add_visit ? 'Não' : ''} permitir adicionar visita na empresa ${obj.name} `}
                    onConfirm={async () => {
                      Mixpanel.track('Confirmou is_add_visit ambiente administrativo,', { params: { obj } })
                      await api_v2.post(`/admin/update/${obj.id}`, {
                        is_add_visit: !obj.is_add_visit,
                        updated_at: new Date()
                      }, {
                        headers: {
                          workspace: obj.slug,
                          workspaceId: obj.id
                        }
                      })
                      message.success(`${obj.is_add_visit ? 'Não' : ''} adicionar na visita empresa ${obj.name} `)
                      await getData()

                    }}
                    okText='Sim'
                    cancelText='Não'
                    placement='topRight'
                  >
                    {obj.is_add_visit ? 'Não' : ''} adicionar visita
                  </Popconfirm>
                </Menu.Item>

                <Menu.Item key='2' icon={<FaPen />}>
                  <Popconfirm
                    title={`Check-in ${obj.required_check_in ? 'não' : ''} obrigatório na empresa ${obj.name} `}
                    onConfirm={async () => {
                      Mixpanel.track('Confirmou required_check_in ambiente administrativo,', { params: { obj } })
                      await api_v2.post(`/admin/update/${obj.id}`, {
                        required_check_in: !obj.required_check_in,
                        updated_at: new Date()
                      }, {
                        headers: {
                          workspace: obj.slug,
                          workspaceId: obj.id
                        }
                      })
                      message.success(`Check-in ${obj.required_check_in ? 'não' : ''} obrigatório na empresa ${obj.name} `)
                      await getData()

                    }}
                    okText='Sim'
                    cancelText='Não'
                    placement='topRight'
                  >
                    Check-in {obj.required_check_in ? 'não' : ''} obrigatório
                  </Popconfirm>
                </Menu.Item>

                <Menu.Item key='2' icon={<FaPen />}>
                  <Popconfirm
                    title={`Check-out ${obj.required_check_out ? 'não' : ''} obrigatório na empresa ${obj.name} `}
                    onConfirm={async () => {
                      Mixpanel.track('Confirmou required_check_out ambiente administrativo,', { params: { obj } })
                      await api_v2.post(`/admin/update/${obj.id}`, {
                        required_check_out: !obj.required_check_out,
                        updated_at: new Date()
                      }, {
                        headers: {
                          workspace: obj.slug,
                          workspaceId: obj.id
                        }
                      })
                      message.success(`Check-out ${obj.required_check_out ? 'não' : ''} obrigatório na empresa ${obj.name} `)
                      await getData()

                    }}
                    okText='Sim'
                    cancelText='Não'
                    placement='topRight'
                  >
                    Check-out {obj.required_check_out ? 'não' : ''} obrigatório
                  </Popconfirm>
                </Menu.Item>

                <Menu.Item key='2' icon={<FaPen />}>
                  <Popconfirm
                    title={`${obj.has_lunch ? 'Não possui' : 'Possui'} horário de almoço na empresa ${obj.name} `}
                    onConfirm={async () => {
                      Mixpanel.track('Confirmou has_lunch ambiente administrativo,', { params: { obj } })
                      await api_v2.post(`/admin/update/${obj.id}`, {
                        has_lunch: !obj.has_lunch,
                        updated_at: new Date()
                      }, {
                        headers: {
                          workspace: obj.slug,
                          workspaceId: obj.id
                        }
                      })
                      message.success(`${obj.has_lunch ? 'Não possui' : 'Possui'} hoŕario de almoço na empresa ${obj.name} `)
                      await getData()

                    }}
                    okText='Sim'
                    cancelText='Não'
                    placement='topRight'
                  >
                    {obj.has_lunch ? 'Não possui' : 'Possui'} horário de almoço
                  </Popconfirm>
                </Menu.Item>

                <Menu.Item key='2' onClick={() => {
                  setSelect(obj)
                  setInfo(true)
                }} icon={<IoMdInformationCircleOutline />}>
                  Informações
                </Menu.Item>
              </Menu>
            }
          >
            <Button size='small' shape='circle' icon={<MoreOutlined />} />
          </Dropdown>
        )
      },
    },
  ]

  const getDataInfo = async () => {
    try {
      if (!select) return
      setLoadingInfo(true)
      const start = moment().startOf('month').format('YYYY-MM-DD')
      const end = moment().endOf('month').format('YYYY-MM-DD')
      const { data } = await api_v2.get(`times-movements/total-promoters?start_date=${start}&end_date=${end}`, {
        headers: {
          workspace: select.slug,
          workspaceId: select.id
        }
      })

      const dataInfo = await api_v2.get(`admin/info/${select.id}`, {
        headers: {
          workspace: select.slug,
          workspaceId: select.id
        }
      })
      setDataInfo(dataInfo.data)
      setTotalPromotes(data.result)
    } catch (error) {

    } finally {
      setLoadingInfo(false)
    }
  }

  useEffect(() => {
    if (select) getDataInfo()
  }, [select])

  return (
    <LayoutWorkspace>
      <Container>
        <Wrapper style={{ display: 'flex', justifyContent: 'start' }}>
          <Link to='/backoffice/workspace'>
            <ArrowLeftOutlined style={{ fontSize: 25, cursor: 'pointer', marginRight: 30, color: '#7158c1' }} />
          </Link>
          <h1> Ambiente administrativo</h1>
        </Wrapper>

        <Table
          rowKey='id'
          dataSource={response}
          columns={columns}
          loading={loading}
          onChange={getData}
          pagination={{
            total: response.length,
            size: 'small',
            showSizeChanger: true,
            showTotal: (total, range) => `Exibindo ${range[1]} de ${total} de registros`,
          }}
        />
      </Container>
      <Modal
        visible={info}
        footer={null}
        width={700}
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
        onCancel={() => {
          setInfo(false)
          setSelect({})
          setDataInfo({})
          setTotalPromotes(0)
        }}
        closable={true}
        destroyOnClose={true}>
        <h3 style={{ width: '100%', fontWeight: 'bold', textAlign: 'center' }}>Informações do ambiente {select?.name}</h3>
        <Row style={{ marginTop: 10 }} gutter={20}>
          <Col span={12}>
            <p>Total de promotores usando no mês atual:</p>
            {loadingInfo ?
              <Spin /> :
              <b>{totalPromoters}</b>
            }
          </Col>
          <Col span={12}>
            <p>Total de visitas no mês atual:</p>
            {loadingInfo ?
              <Spin /> :
              <b>{dataInfo?.total_visit}</b>
            }
          </Col>
          <Col span={12}>
            <p>Total de visitas realizadas no mês atual:</p>
            {loadingInfo ?
              <Spin /> :
              <b>{dataInfo?.total_visit_used}</b>
            }
          </Col>
          <Col span={12}>
            <p>Total de promotores ativos:</p>
            {loadingInfo ?
              <Spin /> :
              <b>{dataInfo?.totalPromoters}</b>
            }
          </Col>
          <Col span={12}>
            <p>Total de gestores:</p>
            {loadingInfo ?
              <Spin /> :
              <b>{dataInfo?.totalGestores}</b>
            }
          </Col>
        </Row>
      </Modal>
    </LayoutWorkspace>
  )
}

export default AdminWork
