import { useState, useEffect } from 'react'
import { ContainerModal, Container, Title, Steps } from './style'
import { Row, Col, Select } from 'antd'
// import { Loading } from '../Loading'
import { IUserInfo } from '../CardUser'
import { ButtonBack } from '../../pages/Team/ViewUser/styles'
import SimpleSelect from '../form/SimpleSelect'
import SimpleSelectObject from '../form/SimpleSelect'

import { Search } from '../form/SimpleSearch'
import { Form } from '@unform/web'
import SimpleRangePicker from '../form/SimpleRangePicker'
import { IoChevronBackOutline } from 'react-icons/io5'
import FileSaver from 'file-saver'
import api_v2 from '../../services/api-v2'
import { exportXls as exportXlsDefault } from '../../utils/funcoes'
import moment from 'moment'
import { Mixpanel } from '../../utils/mixpanel'
interface IProps {
  visible: boolean
  close: (boolean: any) => void
  promotor?: IUserInfo
  type: 'promoter' | 'gestor' | 'supervisor' | 'visits'
  loading?: any
}

interface IBody {
  city: any
  date: any
  flag: any
  name: any
  network: any
  pdv: any
  type?: any
  status: any
  uf: any
}

export const ModalExportVisit = ({ visible, close, promotor, type, loading }: IProps) => {
  let getWorkspace = sessionStorage.getItem('@rocketpdv:workspace')
  let newGetWorkspace = JSON.parse(getWorkspace!.toString())

  const [typeExport, setTypeExport] = useState<any>({
    step: 0,
    selectType: 0,
  })

  const handleSubmitVisit = async (body: IBody) => {
    try {
      const { date, flag, name, pdv, status } = body
      let data: any = {}
      if (date && date.length === 2) {
        data.start_date = date[0]
        data.end_date = date[1]
      } else {
        data.start_date = moment().format('YYYY-MM-DD')
        data.end_date = moment().format('YYYY-MM-DD')
      }

      if (name && name.length > 0) {
        data.promoter = name.map((el: any) => el.value)
      }

      if (flag && flag.length > 0) {
        data.flag_id = flag.map((el: any) => el.value)
      }

      if (pdv && pdv.length > 0) {
        data.pdv_id = pdv.map((el: any) => el.value)
      }

      if (status && status.length > 0) {
        data.status = status.map((el: any) => el)
      }

      if (promotor) data.promoter = [promotor.id]
      loading(true)

      await exportXlsDefault(data, 'visits')
      loading(false)
    } catch (err) {}
  }

  const handleSubmitCheckIn = async (body: IBody) => {
    try {
      let data: any = { ...body }
      if (promotor) data = { ...body, promoter: [promotor.id] }
      console.log(data)
    } catch (err) {}
  }

  const handleSubmitRoteiro = async (body: IBody) => {
    try {
      let data: any = { ...body }
      if (promotor) data = { ...body, promoter: [{ value: promotor.id }] }

      if (data.promoter && data.promoter.length > 0) data.promoter = data.promoter.map((el: any) => el.value)
      if (data.pdv_id && data.pdv_id.length > 0) data.pdv_id = data.pdv_id.map((el: any) => el.value)
      if (data.sub_workspace && data.sub_workspace.length > 0)
        data.sub_workspace = data.sub_workspace.map((el: any) => el.value)
      loading(true)

      // if (body.type && body.type === 'imported') {
      await exportXlsDefault(data, 'scripts')
      // } else {
      //   await exportXlsDefault(data, 'scripts-report')
      // }

      Mixpanel.track('Exportando arquivo', {
        params: `type: script`,
      })
      loading(false)
      close(false)
    } catch (err) {
      console.log(err, 'erro')
    }
  }

  useEffect(() => {
    setTypeExport({
      step: 0,
      selectType: 0,
    })
  }, [visible]) //eslint-disable-line

  const exportXls = async (route?: string) => {
    loading(true)
    await api_v2
      .post(
        `/export-xlsx/${
          route
            ? route
            : type === 'gestor'
            ? 'managers'
            : type === 'supervisor'
            ? 'supervisor'
            : type === 'visits'
            ? 'visits'
            : 'promoters'
        }`,
        {},
        {
          responseType: 'arraybuffer',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(async (response) => {
        const blob = new Blob([response.data], {
          type: 'application/xlsx',
        })

        FileSaver.saveAs(
          blob,
          `${
            type === 'gestor'
              ? 'gestor'
              : type === 'supervisor'
              ? 'supervisor'
              : type === 'visits'
              ? 'visitas'
              : 'promotores'
          }.xlsx`
        )
      })
      .catch((error) => console.log(error))

    Mixpanel.track('Exportando arquivo', {
      params: `type: ${
        type === 'gestor'
          ? 'managers'
          : type === 'supervisor'
          ? 'supervisor'
          : type === 'visits'
          ? 'visits'
          : 'promoters'
      }`,
    })
    loading(false)
    close(!visible)
  }

  return (
    <ContainerModal
      visible={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
      width={890}
    >
      {/* {loading && <Loading />} */}
      <Container>
        {typeExport.step === 0 && (
          <>
            <Title style={{ fontSize: 22 }}>Exportar</Title>
            <Steps>Etapa 1/3</Steps>
            {promotor && (
              <Row gutter={[10, 10]} style={{ marginTop: 20, marginBottom: 60 }}>
                <Col>
                  <img
                    src={`https://via.placeholder.com/150/ff872c/fff/?text=${promotor?.name?.substr(0, 1)}`}
                    alt='user'
                    style={{ width: 50, height: 50, borderRadius: '50%' }}
                  />
                </Col>
                <Col>
                  <Title>{promotor.name}</Title>
                </Col>
              </Row>
            )}
            <Row justify='center' gutter={[20, 20]}>
              <Col>
                <ButtonBack style={{ width: 120 }} onClick={() => setTypeExport({ step: 1, selectType: 1 })}>
                  Visitas
                </ButtonBack>
              </Col>
              {/* <Col>
                <ButtonBack style={{ width: 120 }} onClick={() => setTypeExport({ step: 1, selectType: 2 })}>
                  Check in/out
                </ButtonBack>
              </Col> */}
              <Col>
                <ButtonBack style={{ width: 120 }} onClick={() => setTypeExport({ step: 1, selectType: 3 })}>
                  Roteiro
                </ButtonBack>
              </Col>
              {!promotor && type === 'supervisor' && (
                <Col>
                  <ButtonBack style={{ width: 120 }} onClick={() => exportXls()}>
                    Supervisor
                  </ButtonBack>
                </Col>
              )}
              {!promotor && type === 'gestor' && (
                <Col>
                  <ButtonBack style={{ width: 120 }} onClick={() => exportXls()}>
                    Gestor
                  </ButtonBack>
                </Col>
              )}
              {!promotor && type === 'visits' && (
                <Col>
                  <ButtonBack style={{ width: 120 }} onClick={() => exportXls()}>
                    Visitas
                  </ButtonBack>
                </Col>
              )}
              {!promotor && type === 'promoter' && (
                <Col>
                  <ButtonBack style={{ width: 120 }} onClick={() => exportXls()}>
                    Promotores
                  </ButtonBack>
                </Col>
              )}
              {/* {!promotor && type === 'promoter' && (
                <Col>
                  <ButtonBack style={{ width: 180 }} onClick={() => exportXls('collaborator')}>
                    Promotores detalhado
                  </ButtonBack>
                </Col>
              )} */}
            </Row>
          </>
        )}
        {typeExport.step === 1 && typeExport.selectType === 1 && (
          <>
            <Title style={{ fontSize: 22 }}>Exportar visitas</Title>
            <Steps>Etapa 2/2</Steps>
            {promotor && (
              <div
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <img
                  src={`https://via.placeholder.com/150/ff872c/fff/?text=${promotor?.name?.substr(0, 1)}`}
                  alt='user'
                  style={{ width: 50, height: 50, borderRadius: '50%' }}
                />
                <Title>{promotor.name}</Title>
              </div>
            )}
            <Form onSubmit={handleSubmitVisit} translate={'yes'}>
              <Row gutter={[20, 10]}>
                {!promotor && (
                  <Col span={24}>
                    <Search placeholder='Selecione o promotor' name='name' path='promoters' isMult={true} />
                  </Col>
                )}
                <Col span={17}>
                  <SimpleRangePicker name='date' label='Selecione o periodo:' />
                </Col>
                <Col span={7}>
                  <SimpleSelectObject
                    label='Status:'
                    name='status'
                    options={[
                      { value: 'COMPLETE', label: 'Concluído' },
                      { value: 'PENDENT', label: 'Pendente' },
                      { value: 'IN_PROGRESS', label: 'Em progresso' },
                    ]}
                    isMulti={true}
                  />
                </Col>
                <Col span={24}>
                  <Search placeholder='Selecione a bandeira' name='flag' path='flags' isMult={true} />
                </Col>
                <Col span={24}>
                  <Search placeholder='Selecione o pdv' name='pdv' path='pdvs' isMult={true} />
                </Col>
                <Col span={8}>
                  <ButtonBack
                    style={{ width: '100%', backgroundColor: '#6f7783', color: '#fff', margin: 0 }}
                    onClick={() => setTypeExport({ step: 0, selectType: 0 })}
                  >
                    <IoChevronBackOutline />
                    Voltar
                  </ButtonBack>
                </Col>
                <Col span={16}>
                  <ButtonBack
                    style={{ width: '100%', backgroundColor: '#b56aff', color: '#fff', margin: 0 }}
                    type='submit'
                  >
                    Exportar
                  </ButtonBack>
                </Col>
              </Row>
            </Form>
          </>
        )}
        {typeExport.step === 1 && typeExport.selectType === 2 && (
          <>
            <Title style={{ fontSize: 22 }}>Exportar Check in/out</Title>
            <Steps>Etapa 2/2</Steps>
            {promotor && (
              <div
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <img
                  src={`https://via.placeholder.com/150/ff872c/fff/?text=${promotor?.name?.substr(0, 1)}`}
                  alt='user'
                  style={{ width: 50, height: 50, borderRadius: '50%' }}
                />
                <Title>{promotor.name}</Title>
              </div>
            )}
            <Form onSubmit={handleSubmitCheckIn} translate={'yes'}>
              <Row gutter={[20, 10]}>
                {!promotor && (
                  <Col span={24}>
                    <Search placeholder='Selecione o promotor' name='name' path='promoters' isMult={true} />
                  </Col>
                )}
                <Col span={17}>
                  <SimpleRangePicker name='date' label='Selecione o periodo:' />
                </Col>
                <Col span={7}>
                  <SimpleSelect
                    label='Status:'
                    name='status'
                    options={[
                      { value: 'COMPLETE', label: 'Concluído' },
                      { value: 'PENDENT', label: 'Pendente' },
                      { value: 'IN_PROGRESS', label: 'Em progresso' },
                    ]}
                    isMulti={true}
                  />
                </Col>
                <Col span={24}>
                  <Search placeholder='Selecione o pdv' name='pdv' path='pdvs' isMult={true} />
                </Col>
                <Col span={8}>
                  <ButtonBack
                    style={{ width: '100%', backgroundColor: '#6f7783', color: '#fff', margin: 0 }}
                    onClick={() => setTypeExport({ step: 0, selectType: 0 })}
                  >
                    <IoChevronBackOutline />
                    Voltar
                  </ButtonBack>
                </Col>
                <Col span={16}>
                  <ButtonBack
                    style={{ width: '100%', backgroundColor: '#b56aff', color: '#fff', margin: 0 }}
                    type='submit'
                  >
                    Exportar
                  </ButtonBack>
                </Col>
              </Row>
            </Form>
          </>
        )}
        {typeExport.step === 1 && typeExport.selectType === 3 && (
          <>
            <Title style={{ fontSize: 22 }}>Exportar roteiro</Title>
            <Steps>Etapa 2/2</Steps>
            {promotor && (
              <div
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <img
                  src={`https://via.placeholder.com/150/ff872c/fff/?text=${promotor?.name?.substr(0, 1)}`}
                  alt='user'
                  style={{ width: 50, height: 50, borderRadius: '50%' }}
                />
                <Title>{promotor.name}</Title>
              </div>
            )}
            <Form onSubmit={handleSubmitRoteiro} translate={'yes'}>
              <Row gutter={[20, 10]}>
                {!promotor && (
                  <Col span={24}>
                    <Search placeholder='Selecione o promotor' name='promoter' path='promoters' isMult={true} />
                  </Col>
                )}
                {/* <Col span={17}>
                  <SimpleRangePicker name='date' label='Selecione o periodo:' />
                </Col> */}
                <Col span={24}>
                  <Search placeholder='Selecione o pdv' name='pdv_id' path='pdvs' isMult={true} />
                </Col>
                {newGetWorkspace.shared_environment && (
                  <Col span={24}>
                    <Search
                      placeholder='Selecione o sub workspace'
                      name='sub_workspace'
                      path='sub-workspaces'
                      isMult={true}
                    />
                  </Col>
                )}
                {/* <Col span={24}>
                  <SimpleSelect
                    name='type'
                    defaultValue={{ label: 'Importação', value: 'imported' }}
                    options={[
                      { label: 'Importação', value: 'imported' },
                      { label: 'Relatório', value: 'report' },
                    ]}
                  />
                </Col> */}
                <Col span={8}>
                  <ButtonBack
                    style={{ width: '100%', backgroundColor: '#6f7783', color: '#fff', margin: 0 }}
                    onClick={() => setTypeExport({ step: 0, selectType: 0 })}
                  >
                    <IoChevronBackOutline />
                    Voltar
                  </ButtonBack>
                </Col>
                <Col span={16}>
                  <ButtonBack
                    style={{ width: '100%', backgroundColor: '#b56aff', color: '#fff', margin: 0 }}
                    type='submit'
                  >
                    Exportar
                  </ButtonBack>
                </Col>
              </Row>
            </Form>
          </>
        )}
      </Container>
    </ContainerModal>
  )
}
