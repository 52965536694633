import React from 'react'
import { Container } from './styles'
import { BooleanMass } from './Boolean/index'
import { UniqueChoiceMass } from './UniqueChoices'
import { TextMass } from './Text'
import { MoneyMass } from './Money'
import { MultipleChoiseMass } from './MultipleChoise'
import { IntMass } from './Int'
import { PictureMass } from './Picture'
import { useState } from 'react'
import { DateFormMass } from './DateForm'
interface Props {
  data: any
  product?: any
  handleSetAnswers: (value: any) => void
  error: number[]
  checkRequired: (value: any) => void
  body: any
}

interface IQuestion {
  id: number
  is_required: boolean
  name: string
  order: string
  type: string
}

export const FormMass = ({ data, handleSetAnswers, checkRequired, error, product, body }: Props) => {
  const [loading, setLoading] = useState(false)
  
  const hadleAnswers = (answers: any, dataQuestion: IQuestion) => {
    let data = {
      id: dataQuestion.id,
      field_name: dataQuestion.name,
      field_order: dataQuestion.order,
      field_type: dataQuestion.type,
      field_value: answers,
    }
    // if(dataQuestion.type === 'PICTURE_CAPTURE'){
    //   data.field_value = data.field_value.map((item: any) =>{
    //     return `https://firebasestorage.googleapis.com/v0/b/rocketpdv-dev.appspot.com/o/${user.workspace.slug}%2F${store.id}%2Ftasks%2F${form.forms.id}%2F${item.split('/')[item.split('/').length -1]}?alt=media`
    //   })
    // }

    handleSetAnswers(data)
  }


  return (
    <>
      <Container>
        {data?.fields &&
          data.fields.map((question: any, index: number) => (
            <div key={question.id}>
              {question.type === 'PICTURE_CAPTURE' && (
                <PictureMass
                  body={body}
                  dataQuestion={question}
                  handleAnswers={hadleAnswers}
                  checkRequired={checkRequired}
                  error={error.indexOf(question.id) > -1}
                  loading={setLoading}
                />
              )}
              {question.type === 'NUMBER_INTEGER' && (
                <IntMass
                  dataQuestion={question}
                  handleAnswers={hadleAnswers}
                  checkRequired={checkRequired}
                  error={error?.indexOf(question.id) > -1}
                />
              )}
              {question.type === 'UNIQUE_CHOICE' && (
                <UniqueChoiceMass
                  body={body}
                  dataQuestion={question}
                  handleAnswers={hadleAnswers}
                  checkRequired={checkRequired}
                  error={error?.indexOf(question.id) > -1}
                  loading={setLoading}
                  listError={error}
                />
              )}
              {question.type === 'BOOLEAN' && (
                <BooleanMass
                  body={body}
                  dataQuestion={question}
                  handleAnswers={hadleAnswers}
                  error={error?.indexOf(question.id) > -1}
                  loading={setLoading}
                  checkRequired={checkRequired}
                  listError={error}
                />
              )}
              {question.type === 'TEXT' && (
                <TextMass
                  dataQuestion={question}
                  checkRequired={checkRequired}
                  handleAnswers={hadleAnswers}
                  error={error?.indexOf(question.id) > -1}
                />
              )}
              {question.type === 'MULTIPLE_CHOICE' && (
                <MultipleChoiseMass
                  body={body}
                  dataQuestion={question}
                  checkRequired={checkRequired}
                  handleAnswers={hadleAnswers}
                  error={error?.indexOf(question.id) > -1}
                  loading={setLoading}
                  listError={error}
                />
              )}
              {question.type === 'MONEY' && (
                <>
                  <MoneyMass
                    dataQuestion={question}
                    checkRequired={checkRequired}
                    handleAnswers={hadleAnswers}
                    error={error?.indexOf(question.id) > -1}
                  />
                </>
              )}
              {question.type === 'DATE' && (
                <DateFormMass
                  dataQuestion={question}
                  handleAnswers={hadleAnswers}
                  checkRequired={checkRequired}
                  key={index}
                />
              )}
            </div>
          ))}
      </Container>
    </>
  )
}
