import { Breadcrumb, Col, DatePicker, Input, message, Row, Select } from 'antd'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { SimpleSearchWithoutForm } from '../../../components/form/SimpleSearchWithoutForm'
import { Loading } from '../../../components/Loading'
import { BigButton, Label } from '../../../GlobalStyles'
import history from '../../../routes/history'
import { ContainerForm, Switch } from './styles'
import { AddButtonWhite } from '../../../components/DefaultPage/style'
import { AiOutlineRollback } from 'react-icons/ai'
import './styles'
// import './changeLess.less' // Import Ant Design styles by less entry

const SchedulingTaskCreate = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<any>({
    active: true,
  })
  const sendScheduling = async () => {
    try {
      if (!data.form || !data.name || !data.start_date || !data.frequency) {
        message.error('Por favor preencha todos os campos.')
        return
      }
      setLoading(true)
      // const res = await api_v2.post('/task/schedule/add', {
      //   is_active: data.active,
      //   day_week: data.day_week,
      //   form_id: data.form.value,
      //   name: data.name,
      //   frequency: data.frequency,
      //   start_date: moment(data.start_date).format('YYYY-MM-DD'),
      //   end_date: data?.end_date ? moment(data.end_date).format('YYYY-MM-DD') : null,
      // })

      message.success('Sucesso ao criar tarefa')
      setTimeout(() => {
        history.push('/backoffice/survey/scheduling_task')
      }, 1000)
      setLoading(false)
    } catch (error) {
      console.log(error)
      message.error('Erro ao criar tarefa')
      setLoading(false)
    }
  }

  return (
    <ContainerForm>
      {loading && <Loading />}
      <Row gutter={[8, 8]} justify='space-between'>
        <Col span={20}>
          <h1 style={{ color: '#272d3b' }}>Agendamento de Tarefas</h1>
          <Breadcrumb separator='>' className='breadcumb'>
            <Link to='/backoffice'>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
            </Link>
            <Link to='/backoffice/survey/scheduling_task'>
              <Breadcrumb.Item>Agendamento de Tarefas</Breadcrumb.Item>
            </Link>
            <Breadcrumb.Item>Novo agendamento de Tarefas</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col onClick={() => history.push('/backoffice/survey/scheduling_task')}>
          <AddButtonWhite>
            <AiOutlineRollback style={{ fontSize: 20 }} />
            Voltar
          </AddButtonWhite>
        </Col>
      </Row>
      <Row gutter={[30, 30]} style={{ marginTop: 50 }}>
        <Col span={8}>
          <Label>Status agendamento*</Label>
          <Row style={{ marginTop: 20 }} gutter={[20, 20]} align='middle'>
            <Col span={5}>
              <p style={{ fontSize: 17 }}>{data.active ? 'Ativo' : 'Inativo'}</p>
            </Col>
            <Col>
              <Switch color={'#b570fc'} checked={data.active} onChange={(e) => setData({ ...data, active: e })} />
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Label>Qual o formulário*</Label>
          <SimpleSearchWithoutForm
            path='forms'
            config='&is_active=true'
            placeholder='Selecione o formulário'
            onChange={(e) => setData({ ...data, form: e })}
          />
        </Col>
        <Col span={8}>
          <Label>Nome do agendamento*</Label>
          <Input
            placeholder='Nome do agendamento'
            onChange={(e) => setData({ ...data, name: e.target.value })}
            style={{
              border: '1px solid #d9d9d9',
              borderRadius: 4,
              color: 'rgba(0,0,0,0.65)',
              height: '38px',
            }}
          />
        </Col>

        <Col span={8}>
          <Label>Data do início*</Label>
          <DatePicker
            placeholder='Selecione a data de início'
            format='DD/MM/YYYY'
            onChange={(e) => setData({ ...data, start_date: e })}
            style={{
              border: '1px solid #d9d9d9',
              borderRadius: 4,
              color: 'rgba(0,0,0,0.65)',
              height: '38px',
              width: '100%',
            }}
          />
        </Col>
        <Col span={8}>
          <Label>Data de término</Label>
          <DatePicker
            placeholder='Selecione a data de término'
            format='DD/MM/YYYY'
            onChange={(e) => setData({ ...data, end_date: e })}
            style={{
              border: '1px solid #d9d9d9',
              borderRadius: 4,
              color: 'rgba(0,0,0,0.65)',
              height: '38px',
              width: '100%',
            }}
          />
        </Col>
        <Col span={8}>
          <Label>Qual a frequência do envio de tarefas?*</Label>
          <Select
            placeholder='Selecione a frequência de envio'
            onChange={(e) => setData({ ...data, frequency: e })}
            style={{
              border: '1px solid #d9d9d9',
              borderRadius: 4,
              color: 'rgba(0,0,0,0.65)',
              height: '38px',
              width: '100%',
            }}
          >
            <Select.Option value='DAILY'>Diária</Select.Option>
            <Select.Option value='WEEKLY'>Semanal</Select.Option>
            <Select.Option value='BIWEEKLY'>Quinzenal</Select.Option>
            <Select.Option value='MONTHLY'>Mensal</Select.Option>
          </Select>
        </Col>
        <Col span={8}>
          <Label>Qual o dia da semana?</Label>
          <Select
            placeholder='Selecione a frequência de envio'
            onChange={(e) => setData({ ...data, day_week: e })}
            mode='multiple'
            style={{
              border: '1px solid #d9d9d9',
              borderRadius: 4,
              color: 'rgba(0,0,0,0.65)',
              height: '38px',
              width: '100%',
            }}
          >
            <Select.Option value='MONDAY'>Segunda</Select.Option>
            <Select.Option value='TUESDAY'>Terça</Select.Option>
            <Select.Option value='WEDNESDAY'>Quarta</Select.Option>
            <Select.Option value='THURSDAY'>Quinta</Select.Option>
            <Select.Option value='FRIDAY'>Sexta</Select.Option>
            <Select.Option value='SATURDAY'>Sabado</Select.Option>
            <Select.Option value='SUNDAY'>Domingo</Select.Option>
          </Select>
        </Col>
      </Row>
      <Row justify='center' style={{ marginTop: 40 }}>
        <Col>
          <BigButton
            onClick={() => sendScheduling()}
            style={{ width: 300, height: 50, backgroundColor: '#6e55cc', color: '#fff', fontSize: 17 }}
          >
            Cadastrar
          </BigButton>
        </Col>
      </Row>
    </ContainerForm>
  )
}

export default SchedulingTaskCreate
