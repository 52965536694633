import { useState, useEffect, useMemo } from 'react'
import { Row, Col, Breadcrumb, Tabs, message } from 'antd'
import { MdSearch } from 'react-icons/md'
import { debounce } from 'lodash'
import { CloudUploadOutlined, PlusOutlined } from '@ant-design/icons'

import api_v2 from '../../../services/api-v2'
import CardUser from '../../../components/CardUser'
import { Loading } from '../../../components/Loading'
import { ModalCreateUser } from '../../../components/ModalCreateUser'
import { CloudDownloadOutlined } from '@material-ui/icons'
import { Container, ContainerSearch, Search, BigButton, ContainerCards } from './style'
import { ModalExportVisit } from '../../../components/ModalExportVisit'
import { ModalImport } from '../../../components/ModalImport'
import { NoSearch } from '../../../components/NoSearch'
import { ContextUpload } from '../../../context/contextUpload'
import { hasIndex } from '../../../components/Menu/menuHorizonte'
import { addIsScriptIsPermited, addIsVisit } from '../../../utils/addScript'

export interface IUserInfo {
  id: number
  name: string
  cpf: string
  email: string | undefined
  is_active: boolean
  state: string
  type: string
  phone: string | undefined
  is_removal: boolean
}

export const WorkProfilePagePromoter = () => {
  const { permissions } = ContextUpload()
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openModalExport, setOpenModalExport] = useState(false)
  const [openModalImport, setOpenModalImport] = useState(false)
  const [userInfo, setUser] = useState<IUserInfo[]>([])
  const [userInfoActive, setUserActive] = useState<IUserInfo[]>([])
  const [userInfoInactive, setUserInactive] = useState<IUserInfo[]>([])
  const [userInfoRemoval, setUserRemoval] = useState<IUserInfo[]>([])

  const onSearch = async (event: any) => {
    let searchString = event.target.value

    const query = searchString && searchString.length > 0 ? `name=${searchString}` : ''
    const { data } = await api_v2.get(`/promoter?${query}`)
    setUser(data.result)
  }

  const debouncedChangeHandler = useMemo(() => debounce(onSearch, 300), [])

  const loadPerfis = async () => {
    setLoading(true)
    const { data } = await api_v2.get(`/promoter`)

    setUser(data.result)
    setLoading(false)
  }

  useEffect(() => {
    loadPerfis()
  }, [])

  const restart = async (res: boolean) => {
    if (res) {
      loadPerfis()
    }
  }

  useEffect(() => {
    const active = userInfo.filter((user) => user.is_active && !user.is_removal)
    const inactive = userInfo.filter((user) => !user.is_active && !user.is_removal)
    const removal = userInfo.filter((user) => user.is_removal)
    setUserActive(active)
    setUserInactive(inactive)
    setUserRemoval(removal)
  }, [userInfo])

  return (
    <Container>
      <Row style={{ marginBottom: 20 }} justify='space-between'>
        <Col xs={24} sm={24} md={4}>
          <Breadcrumb className='centerXs'>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>Time</Breadcrumb.Item>
            <Breadcrumb.Item>Promotores</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col xs={24} sm={24} md={18}>
          <Row justify='end' gutter={[10, 10]}>
            <Col xs={24} sm={8} md={13} lg={9} xl={7}>
              <ContainerSearch>
                <Search placeholder='Buscar por nome, CPF ou Estado' onChange={debouncedChangeHandler} />
                <MdSearch size={20} color={'#A56EFC'} />
              </ContainerSearch>
            </Col>
            {hasIndex(
              ['team.import_promoter', 'team.import_visit_promoter', 'team.import_script_promoter'],
              permissions
            ) > -1 && (
              <Col xs={12} sm={8} md={5} lg={4} xl={3}>
                <BigButton
                  style={{
                    color: '#fff',
                    backgroundColor: '#b56aff',
                    border: 'none',
                  }}
                  onClick={() => {
                    if(!addIsVisit()) return message.error('Sem autorização')
                    setOpenModalImport(true)
                  }}
                >
                  <CloudDownloadOutlined style={{ fontSize: 20, marginRight: 10 }} />
                  Importar
                </BigButton>
              </Col>
            )}
            {hasIndex(['team.export_promoter', 'team.export_script_promoter'], permissions) > -1 && (
              <Col xs={12} sm={8} md={5} lg={4} xl={3}>
                <BigButton
                  style={{
                    color: '#fff',
                    backgroundColor: '#b56aff',
                    border: 'none',
                  }}
                  onClick={() => setOpenModalExport(true)}
                >
                  <CloudUploadOutlined style={{ fontSize: 20, marginRight: 10 }} />
                  Exportar
                </BigButton>
              </Col>
            )}
            {permissions.indexOf('team.add_promoter') > -1 && (
              <Col xs={24} sm={8} md={8} lg={7} xl={4}>
                <BigButton
                  style={{
                    color: '#fff',
                    backgroundColor: '#6600cc',
                    border: 'none',
                    width: '100%',
                    maxWidth: 206,
                  }}
                  onClick={() => {
                    if(!addIsVisit()) return message.error('Sem autorização')
                    setOpenModal(true)
                  }}
                >
                  <PlusOutlined style={{ fontSize: 20 }} />
                  Adicionar Promotor
                </BigButton>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      {loading ? (
        <Loading />
      ) : (
        <Tabs>
          <Tabs.TabPane tab={`(${userInfoActive.length}) Ativos`} key='item-1'>
            <ContainerCards>
              {userInfoActive && userInfoActive.length > 0 ? (
                <Row gutter={[16, 16]}>
                  {userInfoActive.map((el, index: number) => (
                    <Col span={8} key={index}>
                      <CardUser data={el} />
                    </Col>
                  ))}
                </Row>
              ) : (
                <NoSearch text='Não encontramos nenhum dado aqui.' />
              )}
            </ContainerCards>
          </Tabs.TabPane>
          <Tabs.TabPane tab={`(${userInfoInactive.length}) Inativos`} key='item-2'>
            <ContainerCards>
              {userInfoInactive && userInfoInactive.length > 0 ? (
                <Row gutter={[16, 16]}>
                  {userInfoInactive.map((el, index: number) => (
                    <Col span={8} key={index}>
                      <CardUser data={el} />
                    </Col>
                  ))}
                </Row>
              ) : (
                <NoSearch text='Não encontramos nenhum dado aqui.' />
              )}
            </ContainerCards>
          </Tabs.TabPane>
          <Tabs.TabPane tab={`(${userInfoRemoval.length}) Afastados`} key='item-3'>
            <ContainerCards>
              {userInfoRemoval && userInfoRemoval.length > 0 ? (
                <Row gutter={[16, 16]}>
                  {userInfoRemoval.map((el, index: number) => (
                    <Col span={8} key={index}>
                      <CardUser data={el} />
                    </Col>
                  ))}
                </Row>
              ) : (
                <NoSearch text='Não encontramos nenhum dado aqui.' />
              )}
            </ContainerCards>
          </Tabs.TabPane>
        </Tabs>
      )}

      <ModalCreateUser
        visible={openModal}
        close={setOpenModal}
        type='PROMOTER'
        reloadApi={(bool: any) => restart(bool)}
      />
      <ModalImport visible={openModalImport} close={setOpenModalImport} type='PROMOTER' />
      <ModalExportVisit loading={setLoading} type='promoter' visible={openModalExport} close={setOpenModalExport} />
    </Container>
  )
}
