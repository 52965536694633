import React, { useState } from 'react'
import { ContainerModal } from './style'
import { CloudUploadOutlined, CloudDownloadOutlined } from '@ant-design/icons'
import { Button, Modal, message } from 'antd'
import { Loading } from '../Loading'
import { BtnExport, ButtonLink, CardCreateUpload, ContainerType, Description, Title } from '../ModalImportGeneral/style'
import api_v2 from '../../services/api-v2'
import ImportDoc from '../../assets/images/importDoc.png'
import { Mixpanel } from '../../utils/mixpanel'
import * as XLSX from 'xlsx'

interface IProps {
  visible: boolean
  close: (boolean: any) => void
  reloadApi?: (boolean: any) => void
  id: any
  context: any
}

export const ModalCreateMixProduct = ({ visible, close, reloadApi, id, context }: IProps) => {
  const [loading, setLoading] = useState<boolean>(false)

  const checkFile = (list: any) => {
    let isWrong = false

    if (list && list.length > 0) {
      switch (context) {
        case 'NETWORK':
          if (
            list[0][0] !== 'Mix por Rede*' ||
            list[1][0] !== 'Rede*' 
          ) {
            isWrong = true
          }
          break
        case 'FLAG':
          if (
            list[0][0] !== 'Mix por Bandeira*' ||
            list[1][0] !== 'Bandeira*' 
          ) {
            isWrong = true
          }
          break
        case 'PDV':
          if (
            list[0][0] !== 'Mix por PDV*' ||
            list[1][0] !== 'PDV*' 
          ) {
            isWrong = true
          }
          break
        default:
          break
      }
    }
    return isWrong
  }
  
  return (
    <ContainerModal
      visible={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
      destroyOnClose={true}
    >
      {loading && <Loading />}
      <ContainerType>
        <Title>Importar</Title>
        <Description>Importe sua planilha automaticamente.</Description>
      </ContainerType>
      <CardCreateUpload
        customRequest={async (file: any) => {
          setLoading(true)
          if (file) {
            const f = file.file
            const reader = new FileReader()
  
            let isWrong: any = false
  
            reader.onload = async function (e: any) {
              var data = e.target.result
              let readedData = XLSX.read(data, { type: 'binary' })
              const wsname = readedData.SheetNames[0]
              const ws = readedData.Sheets[wsname]
  
              /* Convert array to json*/
              const dataParse: any = XLSX.utils.sheet_to_json(ws, { header: 1 })
              isWrong = checkFile(dataParse)
              if (isWrong) {
                message.error('Esse arquivo não é o modelo de importação correto.')
                return
              }


            const archive: any = new FormData()
            archive.append('file', file.file)
            archive.append('mix_product_id', id)
            archive.append('context', context)
            archive.append('config', JSON.stringify({ id: id }))
            try {
              await api_v2.post(`/import-xlsx/MIX_PRODUCT`, archive, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              })

              Mixpanel.track('Importou mix de produto', {
                params: {
                  mix_product_id: id,
                  context: context,
                },
              })

              Modal.success({
                title: 'Arquivo importado com sucesso!',
                content: 'Seu arquivo será processado, e ao finalizar você receberá um e-mail.',
                onOk() {
                  close(false)
                },
              })
              
            } catch (error) {
              Modal.error({
                title: 'Ops!',
                content: 'Ocorreu um erro ao enviar arquivo, tente novamente mais tarde!',
                onOk() {
                  close(false)
                },
              })
            }
          }
          reader.readAsBinaryString(f)
          }

          setLoading(false)
        }}
      >
        <img src={ImportDoc} alt='user' />
        <h4>Importar XLSX</h4>
        <p>Importe planilha.</p>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Button style={{ backgroundColor: '#b56aff', marginTop: 6 }}>
            <CloudUploadOutlined />
            Importar
          </Button>
        </div>
      </CardCreateUpload>
      {context === 'NETWORK' && (
        <ButtonLink>
          <CloudDownloadOutlined />
          <BtnExport
            href={
              'https://firebasestorage.googleapis.com/v0/b/rocketpdv-dev.appspot.com/o/rock-at-config%2Fmodelos%2FMODELO_MIX_REDE.xlsx?alt=media&token=a894e4ec-4c17-4417-b778-8ca6f34fcd7b'
            }
            target='_blank'
          >
            Baixe o modelo
          </BtnExport>
        </ButtonLink>
      )}
      {context === 'FLAG' && (
        <ButtonLink>
          <CloudDownloadOutlined />
          <BtnExport
            href={
              'https://firebasestorage.googleapis.com/v0/b/rocketpdv-dev.appspot.com/o/rock-at-config%2Fmodelos%2FMODELO_MIX_BANDEIRA.xlsx?alt=media&token=1204d8eb-7c07-4c0f-84e7-7843f0c5c4bf'
            }
            target='_blank'
          >
            Baixe o modelo
          </BtnExport>
        </ButtonLink>
      )}
      {context === 'PDV' && (
        <ButtonLink>
          <CloudDownloadOutlined />
          <BtnExport
            href={
              'https://firebasestorage.googleapis.com/v0/b/rocketpdv-dev.appspot.com/o/rock-at-config%2Fmodelos%2FMODELO_MIX_PDV.xlsx?alt=media&token=ddc6d32a-cdda-4f2f-b314-d27cbd0d38d6'
            }
            target='_blank'
          >
            Baixe o modelo
          </BtnExport>
        </ButtonLink>
      )}
    </ContainerModal>
  )
}
