import { useField } from '@unform/core'
import React, { useEffect, useRef } from 'react'
import SelectMult, { OptionTypeBase, Props as SelectProps } from 'react-select'
import { Container, Error, Label } from './styles'

interface Props extends SelectProps<OptionTypeBase> {
  name: string
  label?: string
  isMulti?: boolean | undefined
}
const Select: React.FC<Props> = ({ name, label, isMulti, ...rest }) => {
  const selectRef = useRef(null)
  const { fieldName, registerField, error, defaultValue } = useField(name)
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: undefined,
      setValue: (ref: any, value: any) => {
        ref.select.setValue(value)
      },
      clearValue: (ref: { select: { clearValue: () => void } }) => {
        ref.select.clearValue()
      },
      getValue: (ref: any) => {
        if (isMulti) {
          if (!ref.state.value) {
            return []
          }
          return ref.state.value.map((option: OptionTypeBase) => option.value)
        } else {
          if (!ref.state.value) {
            return ''
          }
          return ref.state.value
        }
      },
    })
  }, [fieldName, registerField, isMulti])

  const reactSelectTheme = (error: any) => (theme: any) => {
    const errorStyling = error
      ? {
          primary: '#ff4d4f',
          neutral10: '#ff4d4f',
          neutral30: '#ff4d4f',
          neutral20: '#ff4d4f',
          neutral60: '#ff4d4f',
        }
      : {}

    return {
      ...theme,
      colors: {
        ...theme.colors,
        ...errorStyling,
      },
    }
  }

  return (
    <Container>
      <Label htmlFor={fieldName}>
        {error && <Error>{error}</Error>}
        <SelectMult
          id={name}
          theme={reactSelectTheme(error)}
          defaultValue={defaultValue}
          ref={selectRef}
          classNamePrefix='react-select'
          cacheOptions
          isMulti={isMulti}
          {...rest}
        />

        <span> {label} </span>
      </Label>
    </Container>
  )
}
export default Select
