import React, { useEffect, useState, useRef } from 'react'
import { SubContainer } from '../styles'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import * as Yup from 'yup'
import TextArea from '../../../../components/form/SimpleTextArea'
import{ IDefault } from '../index'

export interface DataStep2 {
  msgFinishForm: string
  msgRupturaForm: string
  msgAddVisit: string
}

interface Props {
  setData2: (data: DataStep2) => void
  // current: (data: number) => void
  defaultVal: IDefault
}

const schema = Yup.object().shape({
  msgFinishForm: Yup.string().required('É necessário ter uma messagem ao terminar o formulário.'),
  msgRupturaForm: Yup.string().required('É necessário ter uma messagem ao adicionar produto em ruptura.'),
  msgAddVisit: Yup.string().required('É necessário ter uma messagem ao uma visita.'),
})

const Step2: React.FC<Props> = ({ setData2, defaultVal }: Props) => {
  const formRef = useRef<FormHandles>(null)
  const [msgFinishForm, setMsgFinishForm] = useState<any>(
    'Você tem certeza que todos os dados solicitado na pesquisa foram preenchidos?'
  )
  const [msgRupturaForm, setMsgRupturaForm] = useState<any>('Confirma ruptura em todos produtos abaixo?')
  const [msgAddVisit, setMsgAddVisit] = useState<any>('Você tem certeza que deseja adicionar uma visita á {nome_loja}')

  const validateForm = async (form: any) => {
    try {
      await schema.validate(form, {
        abortEarly: false,
      })
      // current(2)
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((el: any) => {
          formRef.current!.setFieldError(el.path, el.message)
        })
      }
    }
  }

  useEffect(() => {
    setData2({
      msgFinishForm,
      msgRupturaForm,
      msgAddVisit,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [msgFinishForm, msgRupturaForm, msgAddVisit])

  useEffect(() => {
    if(defaultVal) {
      console.log(defaultVal)
      setMsgAddVisit(defaultVal.msgAddVisit)
      setMsgFinishForm(defaultVal.msgFinishForm)
      setMsgRupturaForm(defaultVal.msgRupturaForm)
      formRef.current?.setFieldValue('msgFinishForm', defaultVal.msgFinishForm)
      formRef.current?.setFieldValue('msgRupturaForm', defaultVal.msgRupturaForm)
      formRef.current?.setFieldValue('msgAddVisit', defaultVal.msgAddVisit)
    }
  }, [defaultVal])

  return (
    <Form
      translate={undefined}
      className='form'
      ref={formRef}
      initialData={{ check_in_obrigatorio: true }}
      onSubmit={validateForm}
    >
      <SubContainer>
        <h3 style={{ marginTop: 20, fontSize: 15 }}>Alteração de mensagens padronizadas</h3>
        <TextArea
          label=''
          name='msgFinishForm'
          value={msgFinishForm}
          onChange={(el) => setMsgFinishForm(el.target.value)}
        />
        <h3 style={{ marginTop: 20, fontSize: 15 }}>Mensagem de finalização de formulário</h3>
        <TextArea
          label=''
          name='msgRupturaForm'
          value={msgAddVisit}
          onChange={(el) => setMsgAddVisit(el.target.value)}
        />
        <h3 style={{ marginTop: 20, fontSize: 15 }}>Mensagem de ruptura</h3>
        <TextArea
          label=''
          name='msgAddVisit'
          value={msgRupturaForm}
          onChange={(el) => setMsgRupturaForm(el.target.value)}
        />
      </SubContainer>
      {/* <Button htmlType="button" onClick={() => current(0)} style={{marginRight: 20}}>Voltar</Button>
      <Button type="primary" htmlType="submit">Continuar</Button> */}
    </Form>
  )
}

export default Step2
