import React, { useState, useEffect, useRef } from 'react'
import { ContainerModal, ContainerStep2, Switch, ContainerBodyModal } from './style'
import { message, Row, Col, Input, DatePicker, Select, Checkbox } from 'antd'
import { Loading } from '../Loading'
import api_v2 from '../../services/api-v2'
import { ContainerLogo } from '../ModalSugestion/style'
import { FormOutlined } from '@ant-design/icons'
import { BigButton, Label } from '../../GlobalStyles'
import moment from 'moment'
import { Mixpanel } from '../../utils/mixpanel'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { Search } from '../form/SimpleSearch'

interface IProps {
  visible: boolean
  close: (boolean: any) => void
  form_id: number
  reloadApi?: (boolean: any) => void
  defaultValue?: any
  context: string
}

export const ModalSchedule = ({ visible, close, reloadApi, form_id, defaultValue, context }: IProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<any>({
    is_active: true,
  })

  const formRef = useRef<FormHandles>(null)

  async function handleSubmit(body: any) {
    try {
      if (!data.name || !data.start_date || !data.frequency) {
        message.error('Por favor preencha todos os campos.')
        return
      }
      setLoading(true)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars

      let profiles = []

      if(data.profile_ids) {
        data.profile_ids.forEach((item) => {
          if(item.value) {
            profiles.push(item.value)
          } else {
            profiles.push(item)
          }
        })
      }

      let sendBody: any = {
        is_active: data.is_active,
        week_days: JSON.stringify(data.day_week),
        form_id,
        name: data.name,
        frequency: data.frequency,
        profile_ids: JSON.stringify(profiles),
        is_all_products: data.is_all_products || false,
        start_date:
          typeof data.start_date === 'string'
            ? moment(data.start_date, 'DD/MM/YYYY').format('YYYY-MM-DD')
            : moment(data.start_date).format('YYYY-MM-DD'),
        end_date: data?.end_date
          ? typeof data.end_date === 'string'
            ? moment(data.end_date, 'DD/MM/YYYY').format('YYYY-MM-DD')
            : moment(data.end_date).format('YYYY-MM-DD')
          : null,
      }

      if (data.mix_id) {
        sendBody = { ...sendBody, mix_id: data.mix_id.id ? data.mix_id.id : data.mix_id }
      }

      if (defaultValue) sendBody.id = defaultValue.id

      console.log(sendBody)
      await api_v2[defaultValue ? 'put' : 'post']('/task/schedule/add', sendBody)

      Mixpanel.track(defaultValue ? 'Editou agendamento' : 'Criou agendamento de tarefa', {
        params: {
          is_active: data.is_active,
          week_days: data.day_week,
          form_id,
          // product_mix: body.product_mix ? body.product_mix.value : null,
          name: data.name,
          frequency: data.frequency,
          start_date: moment(data.start_date).format('YYYY-MM-DD'),
          end_date: data?.end_date ? moment(data.end_date).format('YYYY-MM-DD') : null,
        },
      })
      message.success('Sucesso ao criar agendamento tarefa')
      setLoading(false)
      if (reloadApi) reloadApi(true)
      close(true)
    } catch (error) {
      message.error('Erro ao criar tarefa')
      setLoading(false)
    }
  }

  const getMix = async (id: number) => {
    const { data } = await api_v2.get('/product-mix?perpage=100')
    let obj: any = {
      label: '',
      value: id,
    }
    if (data.result.length > 0) {
      data.result.forEach((mix: any) => {
        if (mix.id === id)
          return (obj = {
            label: mix.name,
            id,
          })
      })
    }
    return obj
  }

  const getProfiles = async (id: number[]) => {
    const { data } = await api_v2.get('/profile?perpage=1000&page=1')

    if (!data.result) return []

    return data.result
      .filter((item) => id.includes(item.id))
      .map((item) => ({
        label: item.name,
        value: item.id,
      }))
  }

  const desfaultValues = async () => {
    if (defaultValue) {
      if (!!!defaultValue.end_date) delete defaultValue.end_date
      if (defaultValue.mix_id) defaultValue.mix_id = await getMix(defaultValue.mix_id)
      if (defaultValue.profile_ids) defaultValue.profile_ids = await getProfiles(defaultValue.profile_ids)
      if (defaultValue.week_days) {
        defaultValue.day_week = defaultValue.week_days
        delete defaultValue.week_days
      }
      setData({ ...defaultValue })
    } else {
      setData({
        is_active: true,
      })
    }
  }
  useEffect(() => {
    desfaultValues()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  return (
    <ContainerModal
      visible={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
      destroyOnClose={true}
      width={800}
      className='modalOnTop'
      style={{ top: 20 }}
      zIndex={999999999999999999999999999999999}
    >
      {loading && <Loading />}
      <Form className='form' ref={formRef} onSubmit={handleSubmit}>
        <ContainerStep2>
          <h3>Tarefa</h3>
          <ContainerLogo style={{ marginBottom: 10 }}>
            <FormOutlined />
          </ContainerLogo>
          <ContainerBodyModal>
            <Row gutter={[30, 30]} style={{ marginTop: 50 }}>
              <Col span={8}>
                <Label>Status agendamento*</Label>
                <Row style={{ marginTop: 20 }} gutter={[20, 20]} align='middle'>
                  <Col span={12}>
                    <p style={{ fontSize: 17 }}>{data.is_active ? 'Ativo' : 'Inativo'}</p>
                  </Col>
                  <Col>
                    <Switch
                      color={'#b570fc'}
                      checked={data.is_active}
                      onChange={(e) => setData({ ...data, is_active: e })}
                    />
                  </Col>
                </Row>
              </Col>

              <Col span={8}>
                <Label>Nome do agendamento*</Label>
                <Input
                  placeholder='Nome do agendamento'
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                  value={data.name}
                  style={{
                    border: '1px solid #d9d9d9',
                    borderRadius: 4,
                    color: 'rgba(0,0,0,0.65)',
                    height: '38px',
                  }}
                />
              </Col>

              <Col span={8}>
                <Label>Data do início*</Label>
                <DatePicker
                  placeholder='Selecione a data de início'
                  format='DD/MM/YYYY'
                  value={data.start_date ? moment(data.start_date, 'DD/MM/YYYY') : undefined}
                  // defaultValue={data.start_date ? moment(data.start_date, 'DD/MM/YYYY') : undefined}
                  onChange={(e) => setData({ ...data, start_date: e })}
                  style={{
                    border: '1px solid #d9d9d9',
                    borderRadius: 4,
                    color: 'rgba(0,0,0,0.65)',
                    height: '38px',
                    width: '100%',
                  }}
                />
              </Col>
              <Col span={8}>
                <Label>Data de término</Label>
                <DatePicker
                  placeholder='Selecione a data de término'
                  format='DD/MM/YYYY'
                  defaultValue={data.end_date === undefined ? undefined : moment(data.end_date, 'DD/MM/YYYY')}
                  onChange={(e) => setData({ ...data, end_date: e })}
                  style={{
                    border: '1px solid #d9d9d9',
                    borderRadius: 4,
                    color: 'rgba(0,0,0,0.65)',
                    height: '38px',
                    width: '100%',
                  }}
                />
              </Col>
              <Col span={8}>
                <Label>Frequência do envio de tarefas?*</Label>
                <Select
                  placeholder='Selecione a frequência de envio'
                  onChange={(e) => setData({ ...data, frequency: e })}
                  value={data.frequency}
                  style={{
                    border: '1px solid #d9d9d9',
                    borderRadius: 4,
                    color: 'rgba(0,0,0,0.65)',
                    height: '38px !important',
                    width: '100%',
                  }}
                >
                  <Select.Option value='DAILY'>Diária</Select.Option>
                  <Select.Option value='WEEKLY'>Semanal</Select.Option>
                  <Select.Option value='BIWEEKLY'>Quinzenal</Select.Option>
                  <Select.Option value='MONTHLY'>Mensal</Select.Option>
                </Select>
              </Col>
              <Col span={8}>
                <Label>Qual o dia da semana?</Label>
                <Select
                  placeholder='Selecione a frequência de envio'
                  onChange={(e) => setData({ ...data, day_week: e })}
                  mode='multiple'
                  value={data.day_week}
                  style={{
                    border: '1px solid #d9d9d9',
                    borderRadius: 4,
                    color: 'rgba(0,0,0,0.65)',
                    height: '38px',
                    width: '100%',
                  }}
                >
                  <Select.Option value='MONDAY'>Segunda</Select.Option>
                  <Select.Option value='TUESDAY'>Terça</Select.Option>
                  <Select.Option value='WEDNESDAY'>Quarta</Select.Option>
                  <Select.Option value='THURSDAY'>Quinta</Select.Option>
                  <Select.Option value='FRIDAY'>Sexta</Select.Option>
                  <Select.Option value='SATURDAY'>Sabado</Select.Option>
                  <Select.Option value='SUNDAY'>Domingo</Select.Option>
                </Select>
              </Col>
              <Col span={8}>
                <Label>Qual o perfil?</Label>
                <Search
                  className='removeBorder'
                  placeholder='Selecione o perfil'
                  name='profile_ids'
                  path='profiles'
                  value={data.profile_ids}
                  onChange={(dataSelected) => {
                    console.log(dataSelected)
                    setData({ ...data, profile_ids: dataSelected.map((item) => item.value) })
                  }}
                  isMult={true}
                />
              </Col>
              {context === 'PRODUCT' && (
                <Col span={8}>
                  <div style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <Checkbox onChange={(e) => {
                      let checked = e.target.checked

                      let newData = { ...data, is_all_products: checked }
                      if(checked) {
                        newData.mix_id = null
                      }
                      setData({ ...data, is_all_products: checked })

                    }} checked={data.is_all_products}>Todos os produtos ?</Checkbox>
                  </div>
                </Col>
              )}
              {context === 'PRODUCT' && (
                <Col span={8}>
                  <Label>Qual o mix de produto?</Label>
                  <Search
                    className='removeBorder'
                    placeholder='Selecione o mix de produto'
                    name='mix_id'
                    path='products-mix'
                    value={data.mix_id}
                    disabled={data.is_all_products}
                    onChange={(dataSelected) => setData({ ...data, mix_id: dataSelected.value })}
                    isMult={false}
                  />
                </Col>
              )}

              {/* {context === 'CATEGORY' && (
                <Col span={8}>
                  <Label>Qual o mix da categoria?</Label>
                  <Search
                    className='removeBorder'
                    placeholder='Selecione o mix de categoria'
                    name='category_mix_id'
                    path='category-mix'
                    value={data.category_mix_id}
                    onChange={(dataSelected) => setData({ ...data, category_mix_id: dataSelected.value })}
                    isMult={false}
                  />
                </Col>
              )} */}
            </Row>
            <Row justify='center' style={{ marginTop: 40 }}>
              <Col>
                <BigButton
                  type='submit'
                  style={{ width: 300, height: 50, backgroundColor: '#6e55cc', color: '#fff', fontSize: 17 }}
                >
                  {defaultValue ? 'Editar' : 'Cadastrar'}
                </BigButton>
              </Col>
            </Row>
          </ContainerBodyModal>
        </ContainerStep2>
      </Form>
    </ContainerModal>
  )
}
