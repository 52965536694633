import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 0 3%;
  margin-top: 60px;
  align-items: center;

  svg{
      font-size: 26px;
      color: #242424;
      cursor: pointer;
  }
`

export const ImgWorkspace = styled.img`
    width: 56px;
    height: 56px;
    border-radius: 32px;
    margin-right: 16px;
    border: 1px solid lightgray;
`
export const NameWoskapce = styled.p`
    color: #242424;
    font-family: "Open Sans";
    font-size: 33px;
    margin: 0;
    margin-right: 12px;
`