import mixpanel, { Dict } from 'mixpanel-browser'
import { getDataUser, getWorkspace } from './login'

const { REACT_APP_MIX_PANEL } = process.env
if (REACT_APP_MIX_PANEL) mixpanel.init('87fa117361098ae387548cc14b7721af', { debug: true, ignore_dnt: true })

let actions = {
  identify: (id: string) => {
    mixpanel.identify(id)
  },
  alias: (id: string) => {
    mixpanel.alias(id)
  },
  track: (name: string, props: Dict) => {
    const dataUser = getDataUser()
    Mixpanel.identify(dataUser.id.toString())
    let workspace = getWorkspace()
    mixpanel.track(name, { ...props, workspace: workspace === '' ? '' : workspace })
  },
  people: {
    set: (props: any) => {
      mixpanel.people.set(props)
    },
  },
}

export let Mixpanel = actions
