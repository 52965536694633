import { useEffect, useRef, useState, Key } from 'react'
import {
  Breadcrumb,
  Button,
  Col,
  Row,
  Table,
  Tag,
  Menu,
  Dropdown,
  Skeleton,
  Tooltip,
  Popover,
  Divider,
  Pagination,
  message,
  Modal,
  DatePicker,
} from 'antd'
import {
  FileSearchOutlined,
  FilterOutlined,
  MoreOutlined,
  DeleteOutlined,
  EyeOutlined,
  CloseOutlined,
  PlusOutlined,
  CalendarOutlined,
  CopyOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined,
  CheckSquareOutlined,
  HighlightOutlined,
  CaretRightOutlined,
  ExclamationCircleOutlined,
  DownOutlined,
  CaretLeftOutlined,
  InfoCircleOutlined,
  UploadOutlined,
  AppstoreAddOutlined,
  ClearOutlined,
  CloudUploadOutlined,
  PlusSquareOutlined,
  AppstoreOutlined,
  UnorderedListOutlined,
  CameraOutlined,
  SettingOutlined,
  FormOutlined,
} from '@ant-design/icons'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import { Link } from 'react-router-dom'
import qs from 'querystring'
import Select from '../../../components/form/SimpleSelect'
import AddUser from '../../../assets/images/addUser.png'
import {
  BigButton,
  ContainerButtonsFootter,
  ContainerFormFilter,
  ContainerList,
  customStyles,
  GroupForm,
} from '../../../GlobalStyles'
import { isActivePromoter, isActiveSelectVisita } from '../../../utils/option-user'
import { Loading } from '../../../components/Loading'
import { ContainerTagg } from '../FormListPage/styles'
import { ButtonClear, ButtonSearch, ContainerPaginationFooter, LabelForm } from '../../Team/Promoter/style'
import { ModalCreateVisit } from '../../../components/ModalCreateVisit'
import { ButtonBack } from '../../Team/ViewUser/styles'
import {
  ContainerTasks,
  CountVisits,
  DataStatus,
  StatusInativo,
  StatusRealizado,
} from '../../../components/TabsVisits/style'
import { ContainerPopover } from '../../MonitoryPage/MonitoringPage/styles'
import { CardVisits } from '../../../components/CardVisits'
import api_v2 from '../../../services/api-v2'
import { NoSearch } from '../../../components/NoSearch'
import useReactRouter from 'use-react-router'
import history from '../../../routes/history'
import { ModalImportGeneral } from '../../../components/ModalImportGeneral'
import SimpleRangePicker from '../../../components/form/SimpleRangePicker'
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/pt_BR'
import { Search } from '../../../components/form/SimpleSearch'
import { ModalReportSurvey } from '../../../components/ModalReportSurvey'
import { DrawerFilter } from '../../../components/DrawerFilter'
import { ModalEditStatus } from '../../../components/ModalEditStatus'
import { ModalCreateTask } from '../../../components/ModalCreateTask'
import { Mixpanel } from '../../../utils/mixpanel'
import { ModalViewSubWorkspace } from '../../../components/ModalViewSubWorkspace'
import { exportXls } from '../../../utils/funcoes'
import { ModalExportPPTCheckIn } from '../../../components/ModalExportPPTCheckIn'
import { CardCreate } from '../../../components/ModalCreateVisit/style'
import { ContextUpload } from '../../../context/contextUpload'
import { hasIndex } from '../../../components/Menu/menuHorizonte'
import { ModalAddTask } from '../../../components/ModalAddTask'
import SimpleSelectObject from '../../../components/form/SimpleSelectObject'
import { addIsVisit } from '../../../utils/addScript'
import { ModalMoveVisits } from '../../../components/ModalMoveVisits'
import { IoBagRemoveSharp } from 'react-icons/io5'

const dateFormat = 'DD/MM/YYYY'

export const getStatus = (status: string, justify?: any, reason?: any) => {
  const listStatus: any = {
    PENDENT: (
      <Tooltip title='Pendente'>
        <StatusInativo style={{ backgroundColor: 'rgb(0, 136, 254)' }} />
      </Tooltip>
    ),
    COMPLETE: (
      <Tooltip title='Concluída'>
        <StatusRealizado />
      </Tooltip>
    ),
    true: (
      <Tooltip title='Concluída'>
        <StatusRealizado />
      </Tooltip>
    ),
    IN_PROGRESS: (
      <Tooltip title='Em progresso'>
        <StatusRealizado style={{ backgroundColor: 'rgb(255, 187, 40)' }} />
      </Tooltip>
    ),
    JUSTIFIED_ABSENCE: (
      <Tooltip title={`Justificativa: ${justify ? justify : ''} ${reason ? ` - ${reason}` : ''}`}>
        <StatusRealizado style={{ backgroundColor: 'rgb(255, 128, 66)' }} />
      </Tooltip>
    ),
    false: (
      <Tooltip title={`Justificativa: ${justify ? justify : ''} ${reason ? ` - ${reason}` : ''}`}>
        <StatusRealizado style={{ backgroundColor: 'rgb(255, 128, 66)' }} />
      </Tooltip>
    ),
  }

  return listStatus[status] || '-'
}

const VisitsPage = () => {
  const { location } = useReactRouter()
  const { permissions } = ContextUpload()
  const [visibleImport, setVisibleImport] = useState<boolean>(false)
  const [visibleImportEdit, setVisibleImportEdit] = useState<boolean>(false)
  const [newVisible, setNewVisible] = useState<any>(false)
  const [openPPT, setOpenPPT] = useState<boolean>(false)
  const [visibleButtonImport, setVisibleButtonImport] = useState<boolean>(false)
  const [filters, setFilters] = useState<any>({})
  const [typeView, setTypeView] = useState(1)
  const [openModalTask, setOpenModalTask] = useState(false)
  const [openModalSubWorkspaces, setOpenModalSubWorkspaces] = useState(false)
  const [param, setParam] = useState<any>({})
  const [dateSelected, setDateSelected] = useState<any>()
  const [visibleAddForm, setVisibleAddForm] = useState(false)
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [visibleReport, setVisibleReport] = useState<boolean>(false)
  const [filterTag, setFilterTag] = useState<any>({})
  const [urlFilter, setUrlFilter] = useState<string>('')
  const [openModalVisit, setOpenModalVisit] = useState(false)
  const [selectedVisits, setSelectedVisits] = useState<Key[]>([])
  const [visibleEditDate, setVisibleEditDate] = useState(false)
  const [visibleEditStatus, setVisibleEditStatus] = useState(false)
  const [selectVisit, setSelectVisit] = useState<any>({})
  const [openModalMove, setOpenModalMove] = useState(false)

  let getSharement = sessionStorage.getItem('@rocketpdv:workspace')
  let newGetWorkspace: any = {}
  if (getSharement) newGetWorkspace = JSON.parse(getSharement)

  const [forms, setForms] = useState<any>({
    pagination: {
      page: 1,
      perpage: 10,
      total: 1,
    },
    loading: true,
  })
  const formRef = useRef<FormHandles>(null)

  async function loadForms() {
    setLoading(true)
    const { data } = await api_v2.get(`visit`)
    setForms({ ...data, loading: false })
    setLoading(false)
  }

  const restart = async (res: boolean) => {
    if (res) {
      loadForms()
    }
  }

  const alterDateUnique = async () => {
    try {
      if (!dateSelected) {
        message.error('Selecione uma data válida.')
        return
      }

      await api_v2.put(`/visit/${selectVisit.id}/change_date`, { dt_visit: moment(dateSelected).format('YYYY-MM-DD') })
      Mixpanel.track('Alterou data de visita tela de visita', {
        params: { visit_id: selectVisit.id, dt_visit: moment(dateSelected).format('YYYY-MM-DD') },
      })
      message.success('Data de visita alterada com sucesso')
      setVisibleEditDate(!visibleEditDate)
      setSelectVisit({})
      setDateSelected(null)
    } catch (error) {
      console.log(error)
      message.error('Erro ao alterar data de visita')
    }
  }

  const itemRender = (current: any, type: any, originalElement: any) => {
    if (type === 'prev') {
      return (
        <div style={{ display: 'flex', alignItems: 'center', color: '#6f7782' }}>
          <CaretLeftOutlined style={{ marginRight: 10 }} />
          <p style={{ margin: 0 }}>anterior</p>
        </div>
      )
    }
    if (type === 'next') {
      return (
        <div style={{ display: 'flex', alignItems: 'center', color: '#6f7782' }}>
          <p style={{ margin: 0 }}>próximo</p>
          <CaretRightOutlined style={{ marginRight: 10 }} />
        </div>
      )
    }
    return originalElement
  }

  async function SearchForms(body: any, { reset }: any) {
    try {
      setLoading(true)
      let { promoter_id, pdv_id, flag_id, status, dt_visit, date, network_id, status_promoter, profile_id, state, sub_workspace} =
        body
      setParam(body)
      let filterQuery: any = {}
      let filterQueryUrl: any = {}
      let dataTag = {}
      if (promoter_id && promoter_id.value) {
        // let text = ''
        // promoter_id.forEach((el: any) => (text = text === '' ? `${el.value}` : text + `,${el.value}`))
        filterQuery.promoter_id = promoter_id.value
        dataTag = { ...dataTag, promoter_id }
        filterQueryUrl = { ...filterQueryUrl, promoter_id: JSON.stringify(promoter_id) }
      }
      if (pdv_id && pdv_id.value) {
        // let text = ''
        // pdv_id.forEach((el: any) => (text = text === '' ? `${el.value}` : text + `,${el.value}`))
        filterQuery.pdv_id = pdv_id.value
        dataTag = { ...dataTag, pdv_id }
        filterQueryUrl = { ...filterQueryUrl, pdv_id: JSON.stringify(pdv_id) }
      }

      if (flag_id && flag_id.value) {
        // let text = ''
        // flag_id.forEach((el: any) => (text = text === '' ? `${el.value}` : text + `,${el.value}`))
        filterQuery.flag_id = flag_id.value
        dataTag = { ...dataTag, flag_id }
        filterQueryUrl = { ...filterQueryUrl, flag_id: JSON.stringify(flag_id) }
      }

      if (profile_id && profile_id.value) {
        // let text = ''
        // profile_id.forEach((el: any) => (text = text === '' ? `${el.value}` : text + `,${el.value}`))
        filterQuery.profile_id = profile_id.value
        dataTag = { ...dataTag, profile_id }
        filterQueryUrl = { ...filterQueryUrl, profile_id: JSON.stringify(profile_id) }
      }

      if (state && state.value) {
        // let text = ''
        // state.forEach((el: any) => (text = text === '' ? `${el.value}` : text + `,${el.value}`))
        filterQuery.state = state.value
        dataTag = { ...dataTag, state }
        filterQueryUrl = { ...filterQueryUrl, state: JSON.stringify(state) }
      }

      if (network_id && network_id.value) {
        // let text = ''
        // network_id.forEach((el: any) => (text = text === '' ? `${el.value}` : text + `,${el.value}`))
        filterQuery.network_id = network_id.value
        dataTag = { ...dataTag, network_id }
        filterQueryUrl = { ...filterQueryUrl, network_id: JSON.stringify(network_id) }
        
      }

      console.log(sub_workspace)
    
      if(sub_workspace && sub_workspace.value){
        filterQuery.sub_workspace = sub_workspace.value
        dataTag = { ...dataTag, sub_workspace}
        filterQueryUrl = { ...filterQueryUrl, sub_workspace: JSON.stringify(sub_workspace) }
        console.log(filterQueryUrl)
      }
      if (dt_visit) {
        filterQuery.dt_visit = dt_visit.trim()
        dataTag = { ...dataTag, dt_visit }
        filterQueryUrl = { ...filterQueryUrl, dt_visit }
      }

      if (status) {
        filterQuery.status = status
        dataTag = { ...dataTag, status }
        filterQueryUrl = { ...filterQueryUrl, status }
      }
      if (status_promoter === true || status_promoter === false) {
        filterQuery.status_promoter = status_promoter
        dataTag = { ...dataTag, status_promoter }
        filterQueryUrl = { ...filterQueryUrl, status_promoter }
      }
      if (date) {
        filterQueryUrl = { ...filterQueryUrl, date: JSON.stringify(date) }
        if (date[0]) {
          filterQuery.start_date = date[0]
          dataTag = { ...dataTag, start_date: date[0] }
        }

        if (date[1]) {
          filterQuery.end_date = date[1]
          dataTag = { ...dataTag, end_date: date[1] }
        }
      } else {
        filterQuery = {
          ...filterQuery,
          end_date: moment().format('YYYY-MM-DD'),
          start_date: moment().format('YYYY-MM-DD'),
        }
        dataTag = { ...dataTag, end_date: moment().format('YYYY-MM-DD'), start_date: moment().format('YYYY-MM-DD') }
      }
      const queryFilter = qs.stringify({ ...filterQuery })
      const queryFilterUrl = qs.stringify({ ...filterQueryUrl })
      setUrlFilter(queryFilter)
      const { data } = await api_v2.get(`/visit?${queryFilter}`)
      setForms({ ...data, loading: false })
      setLoading(false)
      setFilterTag(dataTag)
      setNewVisible(false)
      history.push(`/backoffice/survey/visits?${queryFilterUrl}`)
    } catch (error) {
      setLoading(false)
    }
  }

  const cleanFilter = async () => {
    history.push(`/backoffice/survey/visits`)
    setForms({ ...forms, loading: true })
    setNewVisible(false)
    setUrlFilter('')
    setFilterTag({})
    const { data } = await api_v2.get(`/visit`)
    setForms({ ...data, loading: false })
    formRef!.current!.reset()
  }

  const removeFilter = async (typeRemove: string, value: string) => {
    let newUrl: any = qs.parse(urlFilter)
    let newObjTagg = filterTag
    if (typeRemove === 'promoter_id') {
      delete newObjTagg.promoter_id
      delete newUrl.promoter_id
      formRef.current?.clearField('promoter_id')
    }

    if (typeRemove === 'pdv_id') {
      delete newObjTagg.pdv_id
      delete newUrl.pdv_id
      formRef.current?.clearField('pdv_id')
    }

    if (typeRemove === 'flag_id') {
      delete newObjTagg.flag_id
      delete newUrl.flag_id
      formRef.current?.clearField('flag_id')
    }

    if (typeRemove === 'profile_id') {
      delete newObjTagg.profile_id
      delete newUrl.profile_id
      formRef.current?.clearField('profile_id')
    }

    if (typeRemove === 'state') {
      delete newObjTagg.state
      delete newUrl.state
      formRef.current?.clearField('state')
    }

    if (typeRemove === 'network_id') {
      delete newObjTagg.network_id
      delete newUrl.network_id
      formRef.current?.clearField('network_id')
    }

    if (typeRemove === 'dt_visit') {
      delete newObjTagg.dt_visit
      delete newUrl.dt_visit
      formRef.current?.clearField('dt_visit')
    }
    if (typeRemove === 'status') {
      delete newObjTagg.status
      delete newUrl.status
      formRef.current?.clearField('status')
    }
    if (typeRemove === 'status_promoter') {
      delete newObjTagg.status_promoter
      delete newUrl.status_promoter
      formRef.current?.clearField('status_promoter')
    }
    if (typeRemove === 'start_date') {
      delete newObjTagg.start_date
      delete newUrl.start_date
    }
    if (typeRemove === 'end_date') {
      delete newObjTagg.end_date
      delete newUrl.end_date
    }
    if (typeRemove === 'sub_workspace') {
      delete newObjTagg.sub_workspace
      delete newUrl.sub_workspace
      formRef.current?.clearField('sub_workspace')
    }
    

    const queryFilter = qs.stringify({ ...newUrl })

    setLoading(true)
    const { data } = await api_v2.get(`/visit?${queryFilter}`)
    setForms({ ...data, loading: false })
    setUrlFilter(queryFilter)
    setFilterTag(newObjTagg)
    setLoading(false)
    history.push(`/backoffice/survey/visits?${queryFilter}`)
  }

  const handleTableChange = async (queryParams: any) => {
    setForms({ ...forms, loading: true })

    let newObj = {
      perpage: queryParams.pageSize,
      page: queryParams.current,
    }
    const query = qs.stringify(newObj)

    const { data } = await api_v2.get(`/visit?${query}${urlFilter ? '&' + urlFilter : ''}`)
    setForms({ ...data, loading: false })
    // setFilterTag(newObj)

    // history.push(`/backoffice/survey/visits??${query}`)
    // setUrlFilter(query)
  }

  const handleTableChangePagination = async (queryParams: any) => {
    setForms({ ...forms, loading: true })

    const query = qs.stringify({
      page: queryParams,
    })
    const { data } = await api_v2.get(`/visit?${query}${urlFilter ? '&' + urlFilter : ''}`)
    setForms({ ...data, loading: false })
  }

  const deleteVisit = async (ids: any[]) => {
    return Modal.confirm({
      title: 'Deletar visitas',
      icon: <ExclamationCircleOutlined />,
      content: 'Tem certeza que deseja deletar essas visitas ?',
      async onOk() {
        try {
          setLoading(true)

          if (ids.length === 0) {
            message.error('Selecione pelo menos uma visita para continuar')
            setLoading(false)
            return
          }

          for await (let el of ids) {
            await api_v2.delete(`/visit/${el}`)
            Mixpanel.track('Deletou visita tela de visitas', { id_visit: el })
          }

          message.success('Visita deletada com sucesso')
          setSelectedVisits([])
          let obj: any = qs.parse(location.search.replaceAll('?', ''))
          if (obj && obj.promoter_id) obj.promoter_id = JSON.parse(obj.promoter_id)
          if (obj && obj.pdv_id) obj.pdv_id = JSON.parse(obj.pdv_id)
          if (obj && obj.flag_id) obj.flag_id = JSON.parse(obj.flag_id)
          if (obj && obj.network_id) obj.network_id = JSON.parse(obj.network_id)
          if (obj && obj.date) obj.date = JSON.parse(obj.date)
          if (!obj || !obj.date) obj.date = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
          if(obj && obj.sub_workspace) obj.sub_workspace = JSON.parse(obj.sub_workspace)
          SearchForms(obj, {})
          setLoading(false)
        } catch (error) {
          console.log(error)
          setLoading(false)
          message.error('Erro ao deletar visita')
        }
      },
      onCancel() {
        setSelectedVisits([])
      },
    })
  }

  const disabledDate = (current: any) => {
    return current && current < moment(new Date()).subtract(1, 'd').endOf('day')
  }

  const columns: any = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 80,
      render: (status: any, all: any) => {
        return <DataStatus>{getStatus(status, all.justify, all.reason)}</DataStatus>
      },
    },
    {
      title: 'Nome Promotor',
      dataIndex: ['promoter', 'name'],
      key: 'pdv_name',
      ellipsis: true,
    },
    {
      title: 'Data',
      width: 120,
      dataIndex: 'dt_visit',
      key: 'dt_visit',
      render: (data: any) => <p>{data}</p>,
    },
    {
      title: 'Check-in/out',
      key: 'check_in',
      render: (data: any) => (
        <>
          {data.check_in !== '-' && (
            <Tooltip title='Check-in' placement='left'>
              <div style={{ color: '#00cec9', textAlign: 'center' }}>
                <ArrowRightOutlined /> {data.check_in}
              </div>
            </Tooltip>
          )}
          {data.check_out !== '-' && (
            <Tooltip title='Check-out' placement='left'>
              <div style={{ color: '#ff7675', textAlign: 'center' }}>
                <ArrowLeftOutlined /> {data.check_out}
              </div>
            </Tooltip>
          )}
        </>
      ),
    },
    {
      title: 'Tarefas',
      width: 180,
      render: (data: any, b: any) => {
        return (
          <ContainerTasks>
            {moment
              .duration(
                moment(moment(data.dt_visit, 'DD/MM/YYYY')).diff(moment(moment().format('DD/MM/YYYY'), 'DD/MM/YYYY'))
              )
              .asDays() > 0 && data.task_count === 0 ? (
              <Tooltip placement='top' title='As terefas serão criadas apenas no dia da visita.'>
                <div style={{ marginRight: 30, display: 'flex', alignItems: 'center', color: 'rgb(255 172 66)' }}>
                  <SettingOutlined style={{ color: 'rgb(255 172 66)' }} />
                  Processando
                </div>
              </Tooltip>
            ) : (
              <div
                style={{ marginRight: 30 }}
                onClick={() => {
                  setSelectVisit(data)
                  setOpenModalTask(true)
                }}
              >
                <CheckSquareOutlined />
                {data.task_count} Tarefas
              </div>
            )}
          </ContainerTasks>
        )
      },
    },
    {
      title: 'Nome',
      dataIndex: ['pdv', 'name'],
      key: 'pdv_name',
      // width: 350,
      ellipsis: true,
    },
    {
      title: <CaretRightOutlined />,
      fixed: 'right',
      width: 60,
      render: (data: any, b: any) => {
        return (
          <div style={{ width: '100%', height: '100%', fontSize: 18, backgroundColor: 'transparent' }}>
            <Link to={`/backoffice/survey/visitsDetails/${b.promoter.id}/${data.id}`}>
              <InfoCircleOutlined />
            </Link>
          </div>
        )
      },
    },
    {
      title: '#',
      width: 50,
      render: (a: any) => {
        return (
          <>
            {a.status === 'PENDENT' ? (
              <Dropdown
                overlay={
                  <Menu>
                    {newGetWorkspace.shared_environment && (
                      <Menu.Item
                        key='8'
                        icon={<AppstoreOutlined />}
                        onClick={() => {
                          setSelectVisit(a)
                          setOpenModalSubWorkspaces(true)
                        }}
                      >
                        Visualizar empresas
                      </Menu.Item>
                    )}
                    {hasIndex(['team.change_visit_status'], permissions) > -1 && (
                      <Menu.Item
                        key='0'
                        icon={<FormOutlined />}
                        onClick={() => {
                          setSelectVisit(a)
                          setVisibleAddForm(!visibleAddForm)
                        }}
                      >
                        Adicionar formulário
                      </Menu.Item>
                    )}
                    <Menu.Item
                      key='0'
                      icon={<HighlightOutlined />}
                      onClick={() => {
                        setSelectVisit(a)
                        setVisibleEditStatus(!visibleEditStatus)
                      }}
                    >
                      Alterar status
                    </Menu.Item>
                    <Menu.Item
                      key='1'
                      icon={<CalendarOutlined />}
                      onClick={() => {
                        setSelectVisit(a)
                        setVisibleEditDate(!visibleEditDate)
                      }}
                    >
                      Alterar data
                    </Menu.Item>
                    {/* <Menu.Item key='3' icon={<SwapOutlined />}>
                      Transferir
                    </Menu.Item> */}
                    <Menu.Item
                      key='5'
                      icon={<PlusSquareOutlined />}
                      onClick={() => {
                        setSelectVisit(a)
                        setOpenModalTask(true)
                      }}
                    >
                      Visualizar tarefas
                    </Menu.Item>
                    <Menu.Item key='4' icon={<DeleteOutlined />} danger={true} onClick={() => deleteVisit([a.id])}>
                      Excluir
                    </Menu.Item>
                    {hasIndex(['team.remove_task'], permissions) > -1 && (
                      <Menu.Item key='5' icon={<IoBagRemoveSharp />} onClick={() => {
                        setSelectVisit(a)
                        setOpenModalMove(true)
                      }}>
                        Mover visita
                      </Menu.Item>
                    )}
                  </Menu>
                }
              >
                <Button size='small' shape='circle' icon={<MoreOutlined />} />
              </Dropdown>
            ) : a.status === 'IN_PROGRESS' ? (
              <Dropdown
                overlay={
                  <Menu>
                    {newGetWorkspace.shared_environment && (
                      <Menu.Item
                        key='8'
                        icon={<AppstoreOutlined />}
                        onClick={() => {
                          setSelectVisit(a)
                          setOpenModalSubWorkspaces(true)
                        }}
                      >
                        Visualizar empresas
                      </Menu.Item>
                    )}
                    {hasIndex(['team.change_visit_status'], permissions) > -1 && (
                      <Menu.Item
                        key='0'
                        icon={<FormOutlined />}
                        onClick={() => {
                          setSelectVisit(a)
                          setVisibleAddForm(!visibleAddForm)
                        }}
                      >
                        Adicionar formulário
                      </Menu.Item>
                    )}
                    <Menu.Item
                      key='0'
                      icon={<HighlightOutlined />}
                      onClick={() => {
                        setSelectVisit(a)
                        setVisibleEditStatus(!visibleEditStatus)
                      }}
                    >
                      Alterar status
                    </Menu.Item>
                    <Menu.Item
                      key='5'
                      icon={<PlusSquareOutlined />}
                      onClick={() => {
                        setSelectVisit(a)
                        setOpenModalTask(true)
                      }}
                    >
                      Visualizar tarefas
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button size='small' shape='circle' icon={<MoreOutlined />} />
              </Dropdown>
            ) : (
              <Dropdown
                overlay={
                  <Menu>
                    {newGetWorkspace.shared_environment && (
                      <Menu.Item
                        key='8'
                        icon={<AppstoreOutlined />}
                        onClick={() => {
                          setSelectVisit(a)
                          setOpenModalSubWorkspaces(true)
                        }}
                      >
                        Visualizar empresas
                      </Menu.Item>
                    )}
                    <Menu.Item
                      key='0'
                      icon={<HighlightOutlined />}
                      onClick={() => {
                        setSelectVisit(a)
                        setVisibleEditStatus(!visibleEditStatus)
                      }}
                    >
                      Alterar status
                    </Menu.Item>
                    <Menu.Item
                      key='5'
                      icon={<PlusSquareOutlined />}
                      onClick={() => {
                        setSelectVisit(a)
                        setOpenModalTask(true)
                      }}
                    >
                      Visualizar tarefas
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button size='small' shape='circle' icon={<MoreOutlined />} />
              </Dropdown>
            )}
          </>
        )
      },
    },
  ]

  const getStatusWithFilter = () => {
    let obj: any = qs.parse(location.search.replaceAll('?', ''))
    if (obj && obj.promoter_id) obj.promoter_id = JSON.parse(obj.promoter_id)
    if (obj && obj.pdv_id) obj.pdv_id = JSON.parse(obj.pdv_id)
    if (obj && obj.flag_id) obj.flag_id = JSON.parse(obj.flag_id)
    if (obj && obj.profile_id) obj.profile_id = JSON.parse(obj.profile_id)
    if (obj && obj.state) obj.state = JSON.parse(obj.state)
    if (obj && obj.network_id) obj.network_id = JSON.parse(obj.network_id)
    if (obj && obj.sub_workspace) obj.sub_workspace = JSON.parse(obj.sub_workspace)
    if (obj && obj.date) obj.date = JSON.parse(obj.date)
    if (!obj || !obj.date) obj.date = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
    /*     SearchForms(obj, {})
     */
  }

  const convertUrl = () => {
    let obj: any = qs.parse(location.search.replaceAll('?', ''))
    if (obj && obj.promoter_id) obj.promoter_id = JSON.parse(obj.promoter_id)
    if (obj && obj.pdv_id) obj.pdv_id = JSON.parse(obj.pdv_id)
    if (obj && obj.flag_id) obj.flag_id = JSON.parse(obj.flag_id)
    if (obj && obj.profile_id) obj.profile_id = JSON.parse(obj.profile_id)
    if (obj && obj.state) obj.state = JSON.parse(obj.state)
    if (obj && obj.network_id) obj.network_id = JSON.parse(obj.network_id)
    if (obj && obj.sub_workspace) obj.sub_workspace = JSON.parse(obj.sub_workspace)
    if (obj && obj.date) obj.date = JSON.parse(obj.date)
    if (!obj || !obj.date) obj.date = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]

    return obj
  }

  useEffect(() => {
    SearchForms(convertUrl(), {})
    getStatusWithFilter()
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const createFilterExport = () => {
    let obj: any = qs.parse(location.search.replaceAll('?', ''))
    if (obj && obj.promoter_id) {
      obj.promoter = [JSON.parse(obj.promoter_id).value]
      delete obj.promoter_id
    }
    if (obj && obj.pdv_id) obj.pdv_id = [JSON.parse(obj.pdv_id).value]
    if (obj && obj.flag_id) {
      obj.flag_id = [JSON.parse(obj.flag_id).value]
    }
    if (obj && obj.profile_id) {
      obj.profile_id = [JSON.parse(obj.profile_id).value]
    }

    if (obj && obj.state) {
      obj.state = JSON.parse(obj.state).value
    }
    if (obj && obj.sub_workspaces) obj.sub_workspaces = [JSON.parse(obj.sub_workspaces).value]

    if (obj && obj.network_id) obj.network_id = [JSON.parse(obj.network_id).value]
    if (obj && obj.sub_workspace) obj.sub_workspace = [JSON.parse(obj.sub_workspace).value]
    if (obj && obj.status) obj.status = [obj.status]
    if (obj && obj.date) {
      obj.start_date = JSON.parse(obj.date)[0]
      obj.end_date = JSON.parse(obj.date)[1]
    }
    if (!obj || !obj.date) {
      obj.start_date = moment().format('YYYY-MM-DD')
      obj.end_date = moment().format('YYYY-MM-DD')
    }

    delete obj.date

    return obj
  }

  return (
    <>
      {hasIndex(['visit.add_visit_survey'], permissions) > -1 && (
        <Row justify='end' style={{ marginTop: -100, marginBottom: 50, zIndex: 0 }}>
          <Col>
            <ButtonBack
              style={{
                backgroundColor: '#6600cc',
                color: '#fff',
                width: 156,
              }}
              onClick={() => {
                if(!addIsVisit()) return message.error('Sem autorização')
                setOpenModalVisit(true)
              }}
            >
              <PlusOutlined style={{ fontSize: 20 }} />
              Adicionar visita
            </ButtonBack>
          </Col>
        </Row>
      )}
      <ContainerList>
        <Row gutter={[8, 8]} justify='space-between'>
          <Col span={8}>
            <h1>Visitas</h1>
            <Breadcrumb separator='>' className='breadcumb'>
              <Link to='/backoffice'>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
              </Link>
              <Link to='/backoffice/survey'>
                <Breadcrumb.Item>Pesquisas</Breadcrumb.Item>
              </Link>
              <Breadcrumb.Item>Visita</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col></Col>
          <Col>
            <Button
              style={{
                display: 'flex',
                alignItems: 'center',
                borderRadius: 5,
                color: '#6f7782',
                padding: 8,
              }}
              onClick={() => setVisibleReport(!visibleReport)}
            >
              <AppstoreAddOutlined size={20} />
              Reportar pesquisa
            </Button>
          </Col>
          <Col>
            <Popover
              content={
                <ContainerPopover>
                  <p
                    style={{ cursor: 'pointer', color: typeView === 1 ? '#0984e3' : '' }}
                    onClick={() => {
                      setTypeView(1)
                      setVisible(!visible)
                    }}
                  >
                    Tabelas
                  </p>
                  <Divider />
                  <p
                    style={{ cursor: 'pointer', color: typeView === 0 ? '#0984e3' : '' }}
                    onClick={() => {
                      setTypeView(0)
                      setVisible(!visible)
                    }}
                  >
                    Cards
                  </p>
                </ContainerPopover>
              }
              trigger='hover'
              visible={visible}
              onVisibleChange={() => setVisible(!visible)}
              placement='left'
            >
              <Button
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  borderRadius: 5,
                  color: '#6f7782',
                  padding: 8,
                }}
              >
                <EyeOutlined size={20} />
                Modo de visualização
              </Button>
            </Popover>
          </Col>
          <Col>
            {selectedVisits.length > 0 && (
              <Dropdown
                overlay={
                  <Menu>
                    {/* <Menu.Item key='1' icon={<CalendarOutlined />}>
                      Alterar data
                    </Menu.Item> */}
                    <Menu.Item key='2' icon={<CopyOutlined />}>
                      Duplicar
                    </Menu.Item>
                    {/* <Menu.Item key='3' icon={<SwapOutlined />}>
                      Transferir
                    </Menu.Item> */}
                    <Menu.Item
                      key='4'
                      icon={<DeleteOutlined />}
                      danger={true}
                      onClick={() => deleteVisit(selectedVisits)}
                    >
                      Excluir
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button>
                  Ações em massa ({selectedVisits.length}) <DownOutlined />
                </Button>
              </Dropdown>
            )}
          </Col>
          <Col>
            <Popover
              content={
                <ContainerPopover>
                  <p style={{ cursor: 'pointer' }} onClick={() => setVisibleImport(true)}>
                    Importar visitas
                  </p>
                  <Divider />
                  <p
                    style={{ cursor: 'pointer', color: typeView === 0 ? '#0984e3' : '' }}
                    onClick={() => setVisibleImportEdit(true)}
                  >
                    Importar edição de visitas
                  </p>
                </ContainerPopover>
              }
              trigger='hover'
              visible={visibleButtonImport}
              onVisibleChange={() => setVisibleButtonImport(!visibleButtonImport)}
              placement='left'
            >
              <Button icon={<UploadOutlined />}>Importar</Button>
            </Popover>
          </Col>
          {hasIndex(['visit.add_visit_survey'], permissions) > -1 && (
            <Col>
              <Dropdown
                overlay={
                  <Menu>
                    {/* <Menu.Item key='1' icon={<CameraOutlined />} onClick={() => setOpenPPT(true)}>
                      Exportar PPT
                    </Menu.Item> */}
                    <Menu.Item
                      key='4'
                      icon={<UnorderedListOutlined />}
                      onClick={async () => {
                        setLoading(true)
                        await exportXls(createFilterExport(), 'visits')
                        setLoading(false)
                      }}
                    >
                      Exportar XLSX
                    </Menu.Item>
                  </Menu>
                }
              >
                <ButtonBack
                  style={{
                    backgroundColor: '#b56aff',
                    color: '#fff',
                    width: 126,
                    height: 35,
                  }}
                >
                  <CloudUploadOutlined style={{ fontSize: 20 }} />
                  Exportar
                </ButtonBack>
              </Dropdown>
            </Col>
          )}
          <Col>
            <Tooltip placement='top' title={'Filtrar'}>
              <BigButton onClick={() => setNewVisible(!newVisible)} style={{ padding: 9 }}>
                <FilterOutlined />
              </BigButton>
            </Tooltip>
          </Col>
          <Col>
            <CountVisits style={{ width: 52 }}>{forms.total}</CountVisits>
          </Col>
          <Col span={24}>
            <ContainerTagg>
              {filterTag?.promoter_id && (
                <Tag color='geekblue' onClick={() => removeFilter('promoter_id', `${filterTag?.promoter_id.value}`)}>
                  {filterTag?.promoter_id.label}
                  <CloseOutlined />
                </Tag>
              )}

              {filterTag.pdv_id && (
                <Tag color='geekblue' onClick={() => removeFilter('pdv_id', `${filterTag.pdv_id.value}`)}>
                  {filterTag.pdv_id.label}
                  <CloseOutlined />
                </Tag>
              )}
              {filterTag.dt_visit && (
                <Tag color='geekblue' onClick={() => removeFilter('dt_visit', `${filterTag.dt_visit}`)}>
                  {filterTag.dt_visit}
                  <CloseOutlined />
                </Tag>
              )}

              {filterTag.flag_id && (
                <Tag color='geekblue' onClick={() => removeFilter('flag_id', `${filterTag.flag_id.value}`)}>
                  {filterTag.flag_id.label}
                  <CloseOutlined />
                </Tag>
              )}
              {filterTag.profile_id && (
                <Tag color='geekblue' onClick={() => removeFilter('profile_id', `${filterTag.profile_id.value}`)}>
                  {filterTag.profile_id.label}
                  <CloseOutlined />
                </Tag>
              )}
              {filterTag.state && (
                <Tag color='geekblue' onClick={() => removeFilter('state', `${filterTag.state.value}`)}>
                  {filterTag.state.label}
                  <CloseOutlined />
                </Tag>
              )}
              {filterTag.network_id && (
                <Tag color='geekblue' onClick={() => removeFilter('network_id', `${filterTag.network_id.value}`)}>
                  {filterTag.network_id.label}
                  <CloseOutlined />
                </Tag>
              )}
              {filterTag.status && (
                <Tag color='geekblue' onClick={() => removeFilter('status', `${filterTag.status}`)}>
                  {filterTag.status === 'PENDENT' && 'Pendente'}
                  {filterTag.status === 'IN_PROGRESS' && 'Em progresso'}
                  {filterTag.status === 'COMPLETE' && 'Concluído'}
                  {filterTag.status === 'JUSTIFIED_ABSENCE' && 'Justificada'}
                  <CloseOutlined />
                </Tag>
              )}
              {(filterTag.status_promoter === true || filterTag.status_promoter === false) && (
                <Tag color='geekblue' onClick={() => removeFilter('status_promoter', `${filterTag.status_promoter}`)}>
                  {filterTag.status_promoter === true && 'Ativo'}
                  {filterTag.status_promoter === false && 'Inativo'}
                  <CloseOutlined />
                </Tag>
              )}
              {filterTag.start_date && (
                <Tag color='geekblue' onClick={() => removeFilter('start_date', `${filterTag.start_date}`)}>
                  {moment(filterTag.start_date).format('DD/MM/YYYY')}
                  <CloseOutlined />
                </Tag>
              )}
              {filterTag.end_date && (
                <Tag color='geekblue' onClick={() => removeFilter('end_date', `${filterTag.end_date}`)}>
                  {moment(filterTag.end_date).format('DD/MM/YYYY')}
                  <CloseOutlined />
                </Tag>
              )}
              {filterTag.sub_workspace && (
                <Tag color='geekblue' onClick={() => removeFilter('sub_workspace', `${filterTag.sub_workspace.value}`)}>
                  {filterTag.sub_workspace.label}
                  <CloseOutlined />
                </Tag>
              )}
            </ContainerTagg>
          </Col>
        </Row>
        {forms.forms ? (
          <Skeleton></Skeleton>
        ) : (
          <>
            {loading ? (
              <Loading />
            ) : (
              <>
                {typeView === 1 ? (
                  <>
                    {forms.result && forms.result.length > 0 ? (
                      <Table
                        rowSelection={{
                          selectedRowKeys: selectedVisits,
                          onChange: (selectedRowKeys) => setSelectedVisits(selectedRowKeys),
                        }}
                        rowKey='id'
                        dataSource={forms.result}
                        columns={columns}
                        onChange={handleTableChange}
                        loading={forms.loading || false}
                        pagination={{
                          current: forms.page,
                          pageSize: forms.perpage,
                          total: forms.total,
                          size: 'small',
                          showSizeChanger: true,
                          showTotal: () => `Exibindo ${forms.result.length} de ${forms.total} de registros`,
                        }}
                      />
                    ) : (
                      <NoSearch text='Não encontramos nenhum dado aqui.' />
                    )}
                  </>
                ) : (
                  <>
                    {forms.result && forms.result.length > 0 ? (
                      <>
                        <Row gutter={[25, 25]}>
                          {forms.result.map((el: any, index: any) => (
                            <Col span={8} key={index}>
                              <CardVisits data={el} user={el.promoter} />
                            </Col>
                          ))}
                        </Row>
                        <ContainerPaginationFooter>
                          <Pagination
                            defaultCurrent={forms.page}
                            total={forms.total}
                            onChange={handleTableChangePagination}
                            itemRender={itemRender}
                          />
                        </ContainerPaginationFooter>
                      </>
                    ) : (
                      <NoSearch text='Não encontramos nenhum dado aqui.' />
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
        {loading ? <Loading /> : null}
        {openModalVisit && (
          <ModalCreateVisit
            visible={openModalVisit}
            close={setOpenModalVisit}
            type={'PROMOTER'}
            stepJump={true}
            promoter={null}
            reloadApi={(bool: any) => restart(bool)}
          />
        )}
      </ContainerList>
      <DrawerFilter
        visible={newVisible}
        close={setNewVisible}
        title='Visitas'
        footer={
          <ContainerButtonsFootter>
            <ButtonSearch type='button' onClick={() => formRef?.current?.submitForm()}>
              <FileSearchOutlined />
              Pesquisar
            </ButtonSearch>
            <ButtonClear onClick={() => cleanFilter()}>
              <ClearOutlined />
              Limpar
            </ButtonClear>
          </ContainerButtonsFootter>
        }
      >
        <ContainerFormFilter
          tabIndex={0}
          onKeyDown={(evt: any) => {
            const isAntdSelect = evt.target.id.indexOf('select')

            if (evt.key === 'Enter' && isAntdSelect === -1) {
              formRef?.current?.submitForm()
            }
          }}
        >
          <Form ref={formRef} className='form' style={{ marginTop: '15px' }} onSubmit={SearchForms}>
            <GroupForm>
              <LabelForm>Selecione promotor</LabelForm>
              <Search placeholder='Selecione o promotor' name='promoter_id' path='promoters' isMult={false} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Promotor ativo ou inativo</LabelForm>
              <Select
                styles={customStyles}
                className='pre-select'
                placeholder='Selecione o status do promotor'
                options={isActivePromoter}
                name='status_promoter'
                defaultValue={filters && filters.status_promoter ? filters.status_promoter : ''}
                onChange={(value: any) => {
                  if (value && value.value)
                    setFilters({
                      ...filters,
                      status_promoter: value.value,
                    })
                }}
              />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione o status</LabelForm>
              <Select
                styles={customStyles}
                className='pre-select'
                placeholder='Selecione o status'
                options={isActiveSelectVisita}
                name='status'
                defaultValue={filters && filters.status ? filters.status : ''}
                onChange={(value: any) => {
                  if (value && value.value)
                    setFilters({
                      ...filters,
                      status: value.value,
                    })
                }}
              />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione a rede</LabelForm>
              <Search placeholder='Selecione a rede' name='network_id' path='networks' isMult={false} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione a Bandeira</LabelForm>
              <Search placeholder='Selecione a bandeira' name='flag_id' path='flags' isMult={false} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione o PDV</LabelForm>
              <Search placeholder='Selecione o PDV' name='pdv_id' path='pdvs' isMult={false} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione o perfil</LabelForm>
              <Search placeholder='Selecione o perfil' name='profile_id' path='profiles' isMult={false} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione o estado</LabelForm>
              <SimpleSelectObject
                placeholder='Selecione o estado'
                name='state'
                isMult={false}
                options={[
                  { value: 'AC', label: 'AC' },
                  { value: 'AL', label: 'AL' },
                  { value: 'AP', label: 'AP' },
                  { value: 'AM', label: 'AM' },
                  { value: 'BA', label: 'BA' },
                  { value: 'CE', label: 'CE' },
                  { value: 'DF', label: 'DF' },
                  { value: 'ES', label: 'ES' },
                  { value: 'GO', label: 'GO' },
                  { value: 'MA', label: 'MA' },
                  { value: 'MT', label: 'MT' },
                  { value: 'MS', label: 'MS' },
                  { value: 'MG', label: 'MG' },
                  { value: 'PA', label: 'PA' },
                  { value: 'PB', label: 'PB' },
                  { value: 'PR', label: 'PR' },
                  { value: 'PE', label: 'PE' },
                  { value: 'PI', label: 'PI' },
                  { value: 'RJ', label: 'RJ' },
                  { value: 'RN', label: 'RN' },
                  { value: 'RS', label: 'RS' },
                  { value: 'RO', label: 'RO' },
                  { value: 'RR', label: 'RR' },
                  { value: 'SC', label: 'SC' },
                  { value: 'SP', label: 'SP' },
                  { value: 'SE', label: 'SE' },
                  { value: 'TO', label: 'TO' },
                ]}
              />
            </GroupForm>
            {newGetWorkspace.shared_environment  ? (
            <GroupForm >
            <LabelForm>Selecione a empresa</LabelForm>
            <Search placeholder='Selecione a empresa' name='sub_workspace' path='sub-workspaces' isMult={false} />    
            </GroupForm>
             ) : null}
            <SimpleRangePicker name='date' label='Selecione o periodo:' />
          </Form>
        </ContainerFormFilter>
      </DrawerFilter>
      {visibleImport && (
        <ModalImportGeneral visible={visibleImport} close={setVisibleImport} title='Importar visitas' path='VISIT' />
      )}
      {visibleImportEdit && (
        <ModalImportGeneral
          visible={visibleImportEdit}
          close={setVisibleImportEdit}
          title='Importar edição de visitas'
          path='EDIT_VISIT'
        />
      )}
      {visibleReport && <ModalReportSurvey visible={visibleReport} close={setVisibleReport} />}
      <ModalEditStatus
        resetVisit={getStatusWithFilter}
        visible={visibleEditStatus}
        close={setVisibleEditStatus}
        visit={selectVisit}
      />
      <ModalAddTask visit={selectVisit} close={setVisibleAddForm} visible={visibleAddForm} />
      <ModalCreateTask visible={openModalTask} close={setOpenModalTask} visit={selectVisit} />
      {openModalSubWorkspaces &&
        <ModalViewSubWorkspace close={setOpenModalSubWorkspaces} visible={openModalSubWorkspaces} visit={selectVisit} />
      }
      <ModalExportPPTCheckIn visible={openPPT} close={setOpenPPT} dataParam={{ ...param }} />
      {visibleEditDate && (
        <Modal
          visible={visibleEditDate}
          footer={null}
          maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
          onCancel={() => setVisibleEditDate(!visibleEditDate)}
          closable={true}
          width={300}
        >
          <CardCreate style={{ width: '100%' }}>
            <img src={AddUser} alt='user' />
            <p>Alterar data da visita de</p>
            {selectVisit && <b>{selectVisit.dt_visit} </b>}
            <p>para</p>
            <DatePicker
              style={{ width: '100%' }}
              disabledDate={disabledDate}
              locale={locale}
              onChange={setDateSelected}
              format={dateFormat}
            />
            <ButtonBack
              style={{
                backgroundColor: '#6600cc',
                color: '#fff',
                width: 156,
                marginTop: 30,
              }}
              onClick={() => alterDateUnique()}
            >
              <PlusOutlined style={{ fontSize: 20 }} />
              Alterar data
            </ButtonBack>
          </CardCreate>
        </Modal>
      )}
      <ModalMoveVisits close={setOpenModalMove} visible={openModalMove} visit={selectVisit} />

    </>
  )
}

export default VisitsPage
