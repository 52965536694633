/* eslint-disable react-hooks/exhaustive-deps */
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import Input from '../../../components/form/SimpleInput'
import { Breadcrumb, Button, Col, message, Row, Checkbox, Upload } from 'antd'
import React, { useState, useRef, useEffect } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { Container, Label } from '../../../GlobalStyles'
import history from '../../../routes/history'
import { Loading } from '../../../components/Loading'
import { InputCheckbox, LabelCheck } from './styles'
import { Search } from '../../../components/form/SimpleSearch'
import { Mixpanel } from '../../../utils/mixpanel'
import firebase from '../../../utils/firebase'
import api_v2 from '../../../services/api-v2'
import { UploadOutlined } from '@material-ui/icons'
import { generateUUID } from '../../../utils/funcao'
const FlagEditPage: React.FC = () => {
  const formRef = useRef<FormHandles>(null)
  const [data, setData] = useState<any>({})
  const [checked, setChecked] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [fileUrls, setFileUrls] = useState<any[]>([]);

  const { id }: any = useParams()

  async function handleSubmit(data: any) {

    setLoading(true)
    try {
      const { name, network } = data

      const schema = Yup.object().shape({
        name: Yup.string().required('* Informe o nome da bandeira'),
      })

      await schema.validate(data, {
        abortEarly: false,
      })
      data.network = data.network.value

      await api_v2.put(`/flag/${id}`, {
        name,
        network: network?.value,
        is_active: checked,
        fileUrls: fileUrls.map((item) => item.url)
      })

      Mixpanel.track('Editou bandeira', {
        params: {
          id: id,
          name,
          network: network?.value,
          is_active: checked,
        },
      })

      message.success('As informações foram atualizadas')
      history.push('/backoffice/store/flags')
    } catch (err) {
      message.error('Verique as informações')
    }
    setLoading(false)
  }

  useEffect(() => {
    async function loadFlag() {
      setLoading(true)
      const { data } = await api_v2.get(`/flag/${id}`)
      setData({ ...data, loading: false })
      setChecked(data.is_active)
      if (data.network_id) {
        formRef.current!.setFieldValue('network', {
          value: data.network_id,
          label: data.name_network,
        })
      }

      if (data.planograma) {
        // Formate a lista no formato esperado pelo componente de foto
        const pictureList = data.planograma.map((url, index) => ({
          uid: index,
          status: 'done',
          url,
          
        }));

        setFileUrls(pictureList);
      }
      setLoading(false)
    }
    loadFlag()
  }, [id])


  return (
    <Container>
      {loading ? <Loading /> : null}
      <h1>Atualizar as informações da bandeira</h1>
      <p>Insira os dados corretamente fazer a atualização.</p>
      <Breadcrumb separator='>' className='breadcumb'>
        <Link to='/backoffice/store/flags'>
          <Breadcrumb.Item> Bandeiras </Breadcrumb.Item>
        </Link>
        <Breadcrumb.Item> Edição de bandeira </Breadcrumb.Item>
      </Breadcrumb>
      <Form onSubmit={handleSubmit} className='form' initialData={data} ref={formRef}>
        <Row justify='end'>
          <Col>
            <Button type='primary' htmlType='submit'>
              Atualizar
            </Button>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Input name='name' type='text' placeholder='Informe o nome completo' label='Nome da bandeira' />
          </Col>
          <Col span={12}>
            <Label> Redes </Label>
            <Search
              placeholder='Selecione a rede'
              name='network'
              path='networks'
              isMult={false}
              defaultValue={{ value: 'te', label: 'teste' }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Label> Planograma </Label>
            <Upload
              listType='picture'
              style={{ width: '100%' }}
              fileList={fileUrls}
              onRemove={(file) => {
                let newFiles = fileUrls.filter((item) => item.url != file.url)
                setFileUrls([...newFiles])
              }}
              customRequest={async ({ file, onSuccess, onError }: any) => {
                try {
                  const team: any = sessionStorage.getItem('@rocketpdv:workspace')
                  const fileRef = firebase
                    .storage()
                    .ref(`${JSON.parse(team).slug}/planograma/${generateUUID()}${file.name}`)
                  await fileRef.put(file)
                  const url = await fileRef.getDownloadURL()
                  const pictureList = {
                    uid: generateUUID(),
                    status: 'done',
                    url, 
                  }

                  let newList = fileUrls


                  newList.push(pictureList)
                  
                  console.log(newList)
                  setFileUrls([...newList]);
                  // Retorne a URL diretamente
                  message.success('Sucesso ao subir arquivo')
                } catch (error) {
                  console.log(error)
                  message.error('Erro ao subir arquivo')
                }
              }}
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
          </Col>

          <Col span={2}>
            <LabelCheck>Está ativo ?</LabelCheck>
            <InputCheckbox>
              <Checkbox
                name='is_active'
                checked={checked}
                defaultChecked={true}
                onChange={(e: any) => {
                  setChecked(e.target.checked)
                }}
              />
            </InputCheckbox>
          </Col>
        </Row>
      </Form>
    </Container>
  )
}
export default FlagEditPage
