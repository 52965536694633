import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 200px;
  border-radius: 20px;
  padding: 10px;
  border: 1px solid #d6d6d6;
  display: flex;
  justify-content: center;
  flex-direction: column;
  :hover {
    transition: all 0.3s;
    border: 1px solid #b369fe;
    box-shadow: 3px 3px 10px #ebebeb;
  }
`

export const ContainerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  position: absolute;
  top: 15px;
  width: 85%;

  h3 {
    font-size: 17px;
    font-weight: bold;
    margin: 0;
  }
`

export const ContainerOptions = styled.div`
  height: auto;
  border: solid 1px #a6a6a6;
  display: flex;
  border-radius: 3px;
  justify-content: space-between;
  padding: 3px;
  svg {
    font-size: 0.93rem;
    margin: 0 3px;
    cursor: pointer;
  }

  svg:hover {
    background-color: #f1f1f1;
  }
`

export const ContainerDah = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 20px 0;
  p {
    font-size: 15px;
    margin: 0;
    color: #000;
  }

  h3 {
    font-weight: bold;
    font-size: 17px;
    margin: 0 10px;
  }

  svg {
    font-size: 17px;
    transform: rotate(180deg);
  }
`

export const ContainerDahDonults = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 20px 0;
  flex-wrap: wrap;
  justify-content: center;
  p {
    font-size: 15px;
    margin: 0;
    color: #000;
  }

  h3 {
    font-weight: bold;
    font-size: 17px;
    margin: 0 10px;
  }
`

export const ContainerFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  div {
    padding: 0 5px;
    p {
      font-size: 12px;
      color: #000;
      margin: 0;
      text-align: center;
    }
  }

  div > div {
    min-width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 15px;
      transform: rotate(180deg);
      margin-right: 5px;
    }

    p {
      font-size: 15px;
      font-weight: bold;
      color: #000;
      margin: 0;
    }
  }
`

export const ContainerValues = styled.div`
  div {
    display: flex;
    align-items: center;

    h3 {
      font-size: 1.8vw;
      font-weight: bold;
      color: #000;
      margin: 0;
    }

    @media (max-width: 767px) {
      h3 {
        font-size: 37px;
      }

      p {
        font-size: 37px !important;
      }
    }
  }

  p {
    font-size: 1.2vw;
    margin: 0;
    text-align: center;
  }

  @media (max-width: 767px) {
      p {
        font-size: 14px !important;
      }
    }
`

export const ContainerValuesNoTag = styled.div`
  width: 50%;
  p {
    font-size: 0.95vw;
    color: #000;
    margin: 0;
    text-align: left !important;
  }
`
