import React, { useState, useEffect, useRef } from 'react'
import { Button, ContainerModal, ContainerStep2, LabelCheck } from './style'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { Alert, message } from 'antd'
import { Loading } from '../Loading'
import { Mixpanel } from '../../utils/mixpanel'
import * as Yup from 'yup'
import api_v2 from '../../services/api-v2'
import { Search } from '../form/SimpleSearch'
import history from '../../routes/history'

interface IProps {
  visible: boolean
  close: (boolean: any) => void
  promoter: any
  reloadApi?: (boolean: any) => void
}

export const ModalMigrate = ({ visible, close, promoter, reloadApi }: IProps) => {
  const formRef = useRef<FormHandles>(null)
  const [loading, setLoading] = useState<boolean>(false)

  async function handleSubmit(data: any) {
    setLoading(true)
    try {
      if (!data.workspace_id) {
        return message.error('É necessário selecionar a empresa')
      } data.workspace_id = data.workspace_id.value

      await api_v2.put(`promoter/migrate/${promoter.id}`, data)

      Mixpanel.track('Mudou senha do promotor promotor ', {
        params: {promoter_id: promoter.id, workspace_id: data.workspace_id},
      })

      if (reloadApi) {
        reloadApi(true)
      }
      message.success('Promotor migrado com sucesso')
      close(false)
      history.push('/backoffice/team/users/promoter')

    } catch (err) {
      setLoading(false)
        message.error('Erro ao migrar promotor!')
    }
    setLoading(false)
  }

  const functionThatResetsForm = () => {
    formRef.current!.reset()
  }

  useEffect(() => {
    if (formRef.current != null) functionThatResetsForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  return (
    <ContainerModal
      visible={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
      destroyOnClose={true}
    >
      {loading && <Loading />}

      <Form className='form' ref={formRef} onSubmit={handleSubmit}>
        <ContainerStep2>
          <h3>Migrar promotor</h3>
          <LabelCheck>Selecione o ambiente</LabelCheck>
          <Search placeholder='Selecione o ambiente' name='workspace_id' path='workspaces' isMult={false} />
          <Alert
                message={
                  'Atenção! Ao migrar o promotor você estará apagando as suas visitas pendentes.'
                }
                type='error'
                style={{marginTop: 10}}
              />
          <Button type='submit' style={{ width: '100%', height: '55px', marginTop: 20 }}>
            Alterar
          </Button>
        </ContainerStep2>
      </Form>
    </ContainerModal>
  )
}
