import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    border-radius: 12px;
    border: 1px solid #d6d6d6;
    background-color: #f9f9f9;
    padding: 18px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`
export const ContainerHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 13px;

  img {
    margin-right: 13px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
  }
`

export const Name = styled.p`
    color: #242424;
    font-family: "Open Sans";
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 0;
    display: flex;
    p {
      margin-left: 10px;
      text-transform: capitalize;
    }
`

export const ContainerOff = styled.div`
  display: flex;
  align-items: center;
`

export const CirculeStatus = styled.div`
  width: 10px;
  height: 10px;
  background-color: #e9830f;
  border-radius: 5px;
`

export const ContainerStatus = styled.div`
  height: 50px;
`

export const TextOff = styled.p`
    color: #6f7782;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: italic;
    margin-bottom: 0;
    margin-left: 10px;
`

export const Active = styled.div`
  width: 50px;
  border-radius: 4px;
  border: 1px solid #16c266;
  background-color: rgba(184, 255, 217, 0.5);
  color: #0b9c4e;
  font-family: 'Open Sans';
  font-size: 10px;
	padding: 3px 8px;
  font-weight: 600;
  text-align: center;
  align-self: baseline;
`

export const ContainerInfo = styled.div`
    color: #6f7782;
    font-family: "Open Sans";
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 5px;
    display: flex;
    align-items: center;

    svg {
        color:  #6600cc;
        font-size: 20px;
        margin-right: 9px;
    }

  p {
    color: #6600cc;
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 0;
    margin-right: 9px;
  }
`
export const ContainerAction = styled.div`
  width: 100%;
  margin-top: 8px;
  display: flex;
  align-items: center;
  z-index: 1;

    p {
        color: #6600cc;
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 0;
        margin-right: 15px;
    }

  img {
    margin-right: 10px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  &&& .tooltip {
    color: #272d3b !important;
    margin: 0;
  }
`