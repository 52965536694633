import React, { useEffect, useState } from 'react'
import { Container, Title, TextError } from './styles'
import { Picture } from '../Picture'
import { Int } from '../Int'
import { Text } from '../Text'
import { UniqueChoice } from '../UniqueChoices/'
import { Money } from '../Money'
import { Boolean } from '../Boolean'
import { DateForm } from '../DateForm'
import { Select } from 'antd'

interface IQuestion {
  id: number
  is_required: boolean
  name: string
  order: string
  type: string
  fields?: any
  options?: any
}
interface IDataQuestion {
  dataQuestion: IQuestion
  handleAnswers: (value: any, dataQuestion: IQuestion) => void
  error?: boolean
  checkRequired: (value: any) => void
  listError: any
  body: any
  defaultVal?: any
}

export const MultipleChoise = ({
  dataQuestion,
  handleAnswers,
  error,
  checkRequired,
  listError,
  body,
  defaultVal
}: IDataQuestion) => {
  const [isRender, setIsRender] = useState(false)
  const [value, setValue] = useState([])
  const [checked, setChecked] = useState<number[]>([])

  const handleValue = (check: any, index: any) => {
    setValue(check)
    let indexs: number[] = []
    check.forEach((el: any) => {
      indexs.push(dataQuestion.options.indexOf(el))
    })

    setChecked(indexs)
  }

  useEffect(() => {
    const answers = () => {
      handleAnswers(value, dataQuestion)
    }

    answers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    if (dataQuestion.is_required && !isRender) {
      setIsRender(true)
      checkRequired(dataQuestion.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // const val = defaultVal && defaultVal[0] ? defaultVal[0].toLowerCase() : undefined
    // if(val && val == 'sim') {
    //   handleValue({
    //     target: {
    //       value: 'SIM'
    //     }
    //   })
    // } else {
    //   handleValue({
    //     target: {
    //       value: 'NÃO'
    //     }
    //   })
    // }
  }, [defaultVal])

  return (
    <Container>
      <Title>
        {dataQuestion.order} - {dataQuestion.name} {dataQuestion.is_required && '*'}
      </Title>
      <Select mode='multiple' allowClear style={{ width: '100%' }} onChange={handleValue}>
        {dataQuestion.options.map((el: any, index: any) => (
          <Select.Option key={index} value={el}>
            {el}
          </Select.Option>
        ))}
      </Select>
      {checked && checked.length > 0 ? (
        <>
          {dataQuestion &&
            dataQuestion.fields &&
            dataQuestion.fields.length > 0 &&
            dataQuestion.fields.map((question: any, indexP: number) => (
              <div key={indexP}>
                {question &&
                  question.subFields.length > 0 &&
                  question.subFields.map((quest: any, index: number) => (
                    <div key={index}>
                      {quest.type === 'PICTURE_CAPTURE' && checked.indexOf(indexP) > -1 && (
                        <Picture
                          checkRequired={checkRequired}
                          dataQuestion={quest}
                          handleAnswers={handleAnswers}
                          error={listError.indexOf(question.id) > -1}
                          body={body}
                          defaultVal={body.all.find((item: any) => item.id === question.id)?.field_value}

                        />
                      )}
                      {quest.type === 'NUMBER_INTEGER' && checked.indexOf(indexP) > -1 && (
                        <Int
                          checkRequired={checkRequired}
                          dataQuestion={quest}
                          handleAnswers={handleAnswers}
                          error={listError.indexOf(question.id) > -1}
                          defaultVal={body.all.find((item: any) => item.id === question.id)?.field_value}

                        />
                      )}
                      {quest.type === 'NUMBER_DECIMAL' && checked.indexOf(indexP) > -1 && (
                        <Int
                          checkRequired={checkRequired}
                          dataQuestion={quest}
                          handleAnswers={handleAnswers}
                          error={listError.indexOf(question.id) > -1}
                          defaultVal={body.all.find((item: any) => item.id === question.id)?.field_value}

                        />
                      )}
                      {quest.type === 'UNIQUE_CHOICE' && checked.indexOf(indexP) > -1 && (
                        <UniqueChoice
                          checkRequired={checkRequired}
                          dataQuestion={quest}
                          handleAnswers={handleAnswers}
                          error={listError.indexOf(question.id) > -1}
                          listError={listError}
                          body={body}
                          defaultVal={body.all.find((item: any) => item.id === question.id)?.field_value}

                        />
                      )}
                      {quest.type === 'BOOLEAN' && checked.indexOf(indexP) > -1 && (
                        <Boolean
                          checkRequired={checkRequired}
                          dataQuestion={quest}
                          handleAnswers={handleAnswers}
                          error={listError.indexOf(question.id) > -1}
                          listError={listError}
                          body={body}
                          defaultVal={body.all.find((item: any) => item.id === question.id)?.field_value}

                        />
                      )}
                      {quest.type === 'TEXT' && checked.indexOf(indexP) > -1 && (
                        <Text
                          checkRequired={checkRequired}
                          dataQuestion={quest}
                          handleAnswers={handleAnswers}
                          error={listError.indexOf(question.id) > -1}
                          defaultVal={body.all.find((item: any) => item.id === question.id)?.field_value}

                        />
                      )}
                      {quest.type === 'MULTIPLE_CHOICE' && checked.indexOf(indexP) > -1 && (
                        <MultipleChoise
                          checkRequired={checkRequired}
                          dataQuestion={quest}
                          handleAnswers={handleAnswers}
                          error={listError.indexOf(question.id) > -1}
                          listError={listError}
                          body={body}
                          defaultVal={body.all.find((item: any) => item.id === question.id)?.field_value}

                        />
                      )}
                      {quest.type === 'MONEY' && checked.indexOf(indexP) > -1 && (
                        <Money
                          checkRequired={checkRequired}
                          dataQuestion={question}
                          handleAnswers={handleAnswers}
                          error={listError.indexOf(question.id) > -1}
                          defaultVal={body.all.find((item: any) => item.id === question.id)?.field_value}

                        />
                      )}
                      {question.type === 'DATE' && checked.indexOf(indexP) > -1 && (
                        <DateForm
                          checkRequired={checkRequired}
                          dataQuestion={question}
                          handleAnswers={handleAnswers}
                          key={index}
                          error={listError.indexOf(question.id) > -1}
                          defaultVal={body.all.find((item: any) => item.id === question.id)?.field_value}

                        />
                      )}
                    </div>
                  ))}
              </div>
            ))}
        </>
      ) : null}
      {error && value.length === 0 && <TextError>Campo obrigatório</TextError>}
    </Container>
  )
}
