import { useState } from 'react'
import { ModalInvateUser } from '../../../components/ModalInvateUser'
import DefaultPage, { getStatusProduct } from '../../../components/DefaultPage'
import { DataStatus } from '../../../components/TabsVisits/style'
import { Button, Dropdown, Menu } from 'antd'
import { EditOutlined, MoreOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { ContextUpload } from '../../../context/contextUpload'
import { hasIndex } from '../../../components/Menu/menuHorizonte'
import { ModalPassword } from '../../../components/ModalPassword'

export interface IUserInfo {
  cpf: string
  email: string | undefined
  id: number
  is_active: boolean
  name: string
  state: string
  type: string
  phone: string | undefined
}

export const WorkProfilePageGestor = () => {
  const { permissions } = ContextUpload()
  const [loading, setLoading] = useState(false)
  const [reload] = useState(true)
  const [inviteUser, setInviteUser] = useState(false)
  const [visibleModalPassword, setVisibleModalPassword] = useState(false)
  const [user, setUser] = useState<any>({})
  return (
    <>
      <DefaultPage
        titlePage='Gestores'
        breadcrumb={[{ name: 'Home', url: '/' }, { name: 'Gestores' }]}
        urlPage='/backoffice/team/users'
        routeApi={{
          method: 'get',
          route: 'user',
          versionApi: 'v1',
          reload,
          config: 'type=GESTOR',
        }}
        listButtons={{
          buttonimport: {
            visible: hasIndex(['home.add_promoter'], permissions) > -1,
            path: 'GESTOR',
          },
          buttonCreate: { visible: true, type: 'function', function: () => setInviteUser(true) },
        }}
        load={{ loading, setLoading }}
        columns={[
          {
            title: 'Status',
            dataIndex: 'is_active',
            key: 'is_active',
            width: 80,
            render: (status: any) => {
              return <DataStatus>{getStatusProduct(status)}</DataStatus>
            },
          },
          {
            title: 'Nome',
            dataIndex: 'name',
            sorter: (a, b) => {
              return a.name.localeCompare(b.name)
            },
          },
          {
            title: 'Email',
            dataIndex: 'email',
            sorter: (a, b) => {
              return a.email.localeCompare(b.email)
            },
          },
          {
            title: 'Telefone',
            dataIndex: 'phone',
            sorter: (a, b) => {
              return a.phone.localeCompare(b.phone)
            },
            render: (a: any) => <>{a ? a : 'Não cadastrado'}</>,
          },
          {
            title: '#',
            width: 50,
            render: (obj: any) => {
              return (
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item key='2' icon={<EditOutlined />}>
                        <Link to={`/backoffice/team/users/edit/${obj.id}`}>Editar usuário</Link>
                      </Menu.Item>
                      <Menu.Item key='2' icon={<EditOutlined />} onClick={() => {
                        setUser(obj)
                        setVisibleModalPassword(!visibleModalPassword)
                      }}>
                        Mudar senha
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button size='small' shape='circle' icon={<MoreOutlined />} />
                </Dropdown>
              )
            },
          },
        ]}
      />

      <ModalInvateUser
        visible={inviteUser}
        close={(close: any) => {
          setInviteUser(!inviteUser)
        }}
      />

      <ModalPassword visible={visibleModalPassword} close={() => {
        setVisibleModalPassword(false)
        setUser({})
      }} promoter={user} />

    </>
  )
}
