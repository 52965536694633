import styled from 'styled-components'
import { Modal } from 'antd'

export const ContainerModal = styled(Modal)`
    &&& .ant-modal-content{
        border-radius: 12px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    }

    &&& .ant-modal-body{
        border-radius: 12px;
    }

    &&& .ant-modal-close-x{
        color: red;
    }

    &&& .ant-upload.ant-upload-drag {
        width: auto !important;
        height: auto !important;
        padding: 28px 5px;
    }
`

export const ConatinerTeste = styled.div`
    width: 100%;
    height: 100%;
    border-left: 2px solid #b56aff;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`

export const Title = styled.div`
    font-size: 17px;
    color: #272d3b;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
`

export const SmallText = styled.div`
    font-size: 17px;
    color: #6f7782;
    text-align: center;
    width: 100%;
    margin: 15px 0;

    a{
        color: #b56aff;
        margin-left: 5px;
    }
`

export const ContainerDescription = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px;

    img{
        width: 90%;
        max-width: 350px;
    }

    h3{
        font-size: 17px;
        color: #6f7782;
        text-align: center;
        width: 100%;
        margin: 15px 0;
        font-weight: bold;
    }
`

export const ContainerLogo = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        height: 50px;
    }

    p{
        font-size: 17px;
        color: #6f7782;
        text-align: center;
        margin: 15px 0;
        margin-left: 15px;


        b{
            font-weight: bold;
        }
    }


`