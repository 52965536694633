import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { Breadcrumb, Button, Checkbox, Col, message, Row } from 'antd'
import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import Input from '../../../components/form/SimpleInput'
import { Container } from '../../../GlobalStyles'
import history from '../../../routes/history'
import api from '../../../services/api'
import { Loading } from '../../../components/Loading'
import { Mixpanel } from '../../../utils/mixpanel'

const JustificationCreatePage: React.FC = () => {
  const [mobile, setMobile] = useState<boolean>()
  const [loading, setLoading] = useState<boolean>(false)
  const [observation, setObservation] = useState<boolean>()
  const [picture, setPicture] = useState<boolean>()
  const formRef = useRef<FormHandles>(null)

  async function handleSubmit(body: any) {
    setLoading(true)
    try {
      await api.post('/faults_options', {
        description: body.description,
        mandatory_observation: observation,
        mandatory_picture: picture,
        mobile_active: mobile,
      })

      Mixpanel.track('Criou justificativa de falta', {
        params: {
          description: body.description,
          mandatory_observation: observation,
          mandatory_picture: picture,
          mobile_active: mobile,
        },
      })
      message.success('justificativa cadastrada com sucesso')
      history.push('/backoffice/register/justification')
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((el: any) => {
          formRef.current!.setFieldError(el.path, el.message)
        })
      } else {
        message.error('Já existe um registro com essas informações!')
      }
    }
    setLoading(false)
  }

  return (
    <Container>
      {loading ? <Loading /> : null}
      <Row>
        <Col span={12}>
          <h1>Justificativa de Falta </h1>
          {/*<p>Insira os dados para realizar o cadastro</p>*/}
          <Breadcrumb separator='>' className='breadcumb'>
            <Link to='/backoffice'>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
            </Link>
            <Link to='/backoffice/survey'>
              <Breadcrumb.Item>Pesquisas</Breadcrumb.Item>
            </Link>
            <Link to='/backoffice/register/justification'>
              <Breadcrumb.Item>Justificativa de Falta</Breadcrumb.Item>
            </Link>
            <Breadcrumb.Item>Cadastro</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Form className='form' ref={formRef} onSubmit={handleSubmit}>
        <Row gutter={8}>
          <Col span={8}>
            <Input type='text' label='Justificativa de falta *' name='description' />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Checkbox
              name='mobile_active'
              checked={mobile}
              defaultChecked={false}
              onChange={(event: any) => {
                setMobile(event.target.checked)
              }}
            >
              Ativo Mobile?
            </Checkbox>
          </Col>
          <Col span={8}>
            <Checkbox
              checked={picture}
              defaultChecked={false}
              name='mandatory_picture'
              onChange={(event: any) => {
                setPicture(event.target.checked)
              }}
            >
              Foto obrigatória?
            </Checkbox>
          </Col>
          <Col span={8}>
            <Checkbox
              checked={observation}
              defaultChecked={false}
              name='mandatory_observation'
              onChange={(event: any) => {
                setObservation(event.target.checked)
              }}
            >
              Observação obrigatória?
            </Checkbox>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button type='primary' htmlType='submit'>
              Cadastrar
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  )
}

export default JustificationCreatePage
