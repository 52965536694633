import { Col, Row } from 'antd'
import { useEffect, useState } from 'react'
import DefaultPage from '../../components/DefaultPage'
import api_v2 from '../../services/api-v2'
import { getWorkspaceId } from '../../utils/login'

export interface ProductFilter {
  name?: string
  brand?: string
  product_type?: string
  category?: string
  is_active?: any
}

const Licenses = () => {
  const [loading, setLoading] = useState(false)
  const [reload] = useState(false)
  const [licenses, setLicenses] = useState({
    hired: 0,
    allocated: 0,
    available: 0
  })

  const getCountLicenses = async () => {
    // setLoading(true)
    let { data } = await api_v2.get(`/licenses/count/${getWorkspaceId()}`)
    setLicenses(data)
    // setLoading(false)
  }

  useEffect(() => {
    getCountLicenses()
  }, [])

  return (
    <DefaultPage
      titlePage='Lincenças'
      breadcrumb={[{ name: 'Home', url: '/' }, { name: 'Linceças' }]}
      urlPage='/backoffice/licenses'
      routeApi={{
        method: 'get',
        route: 'licenses',
        versionApi: 'v2',
        reload,
      }}
      childrenHeader={(
        <Row
          gutter={[20, 20]}
          style={{
            marginTop: 20,
            marginBottom: 20
          }}
        >
          <Col
            span={8}
          >
            <div
              style={{
                borderRadius: 10,
                width: '100%',
                border: '1px solid #d6d6d6',
                padding: '15px'
              }}
            >
              <h3
                style={{
                  fontWeight: 'bold',
                  fontSize: '20px'
                }}
              >
                Linceças contratadas
              </h3>
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: 30,
                  marginTop: 10,
                  color: 'rgba(0, 0, 0, 0.85)'
                }}
              >
                {licenses.hired}
              </p>

            </div>
          </Col>
          <Col
            span={8}
          >
            <div
              style={{
                borderRadius: 10,
                width: '100%',
                border: '1px solid #d6d6d6',
                padding: '15px'
              }}
            >
              <h3
                style={{
                  fontWeight: 'bold',
                  fontSize: '20px'
                }}
              >
                Linceças alocadas no ambiente
              </h3>
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: 30,
                  marginTop: 10,
                  color: 'rgba(0, 0, 0, 0.85)'
                }}
              >
                                {licenses.allocated}

              </p>

            </div>
          </Col>
          <Col
            span={8}
          >
            <div
              style={{
                borderRadius: 10,
                width: '100%',
                border: '1px solid #d6d6d6',
                padding: '15px'
              }}
            >
              <h3
                style={{
                  fontWeight: 'bold',
                  fontSize: '20px'
                }}
              >
                Linceças disponíveis
              </h3>
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: 30,
                  marginTop: 10,
                  color: 'rgba(0, 0, 0, 0.85)'
                }}
              >
                                                {licenses.available}

              </p>

            </div>
          </Col>
        </Row>
      )
      }
      listButtons={{
        buttonFilter: {
          visible: true,
          filters: [
            {
              label: 'Selecione o promotor:',
              name: 'promoter',
              type: 'search',
              urlSearch: 'promoters',
              getEnv: 'label',
            },
          ],
        },
      }}
      load={{ loading, setLoading }}
      columns={[
        {
          title: 'Ambientes',
          dataIndex: 'name',
        },
        {
          title: 'Colaborador de campo',
          dataIndex: 'count_promoters',
        },
        {
          title: 'Linceças alocadas',
          dataIndex: 'licenses',
        }
      ]}
    />
  )
}

export default Licenses
