import {
  BodyVisits,
  ButtonAdd,
  Container,
  ContainerForm,
  ContainerTableModal,
  Count,
  Day,
  Footer,
  Header,
} from './style'
import { PlusOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { Button, Col, Modal, Row, Spin, message, Table } from 'antd'
import { Form, Input } from '@rocketseat/unform'
import qs from 'querystring'
import api from '../../services/api'
import { IGlobalResponse, INIT_GLOBAL_RESPONSE } from '../../utils/global-response'
import { FileSearchOutlined } from '@ant-design/icons'
import { useParams } from 'react-router'
import { TableSubWorkspace } from '../TableSubWorkspace'
import { CardItem } from './CardItem'
import { useDrop } from 'react-dnd'
import { Mixpanel } from '../../utils/mixpanel'
import { Loading } from '../Loading'
import api_v2 from '../../services/api-v2'
import { addIsScriptIsPermited } from '../../utils/addScript'
interface Props {
  list: any
  index?: any
  semana: any
  dia: any
  frequency: any
  setterList: any
  setterList2: any
  setLoanding: any
  copyScript: any
  setCopyScript: any
}

export interface PdvFilter {
  name?: string
  pdv_code?: string
  corporate_name?: string
  cnpj?: string
  telephone?: string
  manager?: string
  cep?: string
  city?: string
  address?: string
}
interface Pdv {
  name: string
  id: string
}
interface pdvList extends IGlobalResponse {
  result: Pdv[]
}

export const CardCalendar: React.FC<Props> = ({
  list,
  semana,
  dia,
  frequency,
  setterList,
  setterList2,
  setLoanding,
  copyScript,
  setCopyScript,
}) => {
  const [{ DateDays }, setDateDays] = useState<any>({ DateDays: [] })
  const [visible, setVisible] = useState(false)
  const [loading2, setLoading2] = useState<boolean>(false)
  const [pdvInfo, setPdvInfo] = useState<pdvList>(INIT_GLOBAL_RESPONSE)
  const [selection, setSelection] = useState<number[]>([])
  const [totalDate, setTotalDate] = useState(0)
  const [selects, setSelects] = useState<any>([])
  const [queryFilter, setQueryFilter] = useState<any>('')

  const { id }: any = useParams()

  let getWorkspace = sessionStorage.getItem('@rocketpdv:workspace')
  let newGetWorkspace = JSON.parse(getWorkspace!.toString())

  let item_correct: any = []
  const Arr: any = ['ONE', 'TWO', 'THREE', 'FOUR']
  const newArr: any = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY']

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      width: 250,
    },
    {
      title: 'PDV',
      dataIndex: 'pdv_code',
      ellipsis: true,
      width: 100,
    },
    {
      title: 'CEP',
      dataIndex: 'cep',
      ellipsis: true,
      width: 100,
    },
    {
      title: 'Estado',
      dataIndex: 'state',
      ellipsis: true,
      width: 80,
    },
    {
      title: 'Cidade',
      dataIndex: 'city',
      ellipsis: true,
    },
    {
      title: 'Endereço',
      dataIndex: 'address',
      ellipsis: true,
    },
  ]

  const getWeek = (reference: string) => {
    const referenceDate = moment(reference)
    const weekStart = referenceDate.clone().startOf('week')

    const days = []
    for (let i = 0; i <= 6; i++) {
      let day_selected = moment(weekStart).add(i, 'days')
      let day = day_selected.format('DD')
      let name = day_selected.format('ddd')
      let long_name = day_selected.format('dddd')
      let month = day_selected.format('MMMM')
      let year = day_selected.format('YYYY')
      let description = `${long_name}, ${day} de ${month} de ${year}`

      days.push({
        day,
        name,
        description,
        date: day_selected.format('YYYY-MM-DD'),
        date_formated: day_selected.format('DD/MM'),
        is_today: day_selected.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD'),
        active: day_selected.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD'),
      })
    }
    days[dia].active = true
    setDateDays({ DateDays: days[dia] })

    return days
  }

  useEffect(() => {
    let DayFormatted =
      frequency === 'FORTNIGHTLY' ? moment().startOf('month').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')

    if (semana === 0) {
      getWeek(frequency === 'FORTNIGHTLY' ? DayFormatted : DateDays)
    } else if (semana === 1) {
      let newFormatted = moment(DayFormatted).add(7, 'days').format('YYYY-MM-DD')
      getWeek(newFormatted)
    } else if (semana === 2) {
      let newFormatted = moment(DayFormatted).add(14, 'days').format('YYYY-MM-DD')
      getWeek(newFormatted)
    } else if (semana === 3) {
      let newFormatted = moment(DayFormatted).add(21, 'days').format('YYYY-MM-DD')
      getWeek(newFormatted)
    }
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let total = 0
    list.forEach((item: any) => {
      if (Arr[semana] === item.weekNumber && newArr[dia] === item.weekDay) {
        total = total + 1
      }
    })
    setTotalDate(total)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list])

  const handleCancel = () => {
    setQueryFilter('')
    setVisible(false)
  }

  async function searchPdv(body: PdvFilter) {
    setLoading2(true)
    const { name, pdv_code, corporate_name, cnpj, telephone, manager, city, address, cep } = body
    let filters: PdvFilter = {}
    if (name) filters.name = name
    if (pdv_code) filters.pdv_code = pdv_code
    if (corporate_name) filters.corporate_name = corporate_name
    if (cnpj) filters.cnpj = cnpj
    if (telephone) filters.telephone = telephone
    if (manager) filters.manager = manager
    if (city) filters.city = city
    if (address) filters.address = address
    if (cep) filters.cep = cep
    const queryFilter = qs.stringify({ ...filters, is_active: true })
    setQueryFilter(queryFilter)
    const { data } = await api.get(`/pdv?${queryFilter}`)

    await data.result.forEach(async (item: any, index_remove: any) => {
      await list.forEach((listItem: any) => {
        if (
          listItem.pdv.name === item.name &&
          Arr[semana] === listItem.weekNumber &&
          newArr[dia] === listItem.weekDay
        ) {
          let array = [item.name]
          let elemento = item.name

          let idx = array.indexOf(elemento)
          while (idx !== -1) {
            item_correct.push(idx)
            idx = array.indexOf(elemento, idx + 1)
            data.result.splice(index_remove, 1)
          }
          data.result.splice(index_remove, 1)
        }
      })
    })
    setLoading2(false)
    setPdvInfo({ ...data, loading: false })
  }

  async function handleSubmit() {
    setLoading2(true)
    try {
      if (!frequency) {
        message.error('Selecione o periodo !')
      } else {
        let data: any = {
          user: parseInt(id),
          weekDay: newArr[dia],
          weekNumber: Arr[semana],
          pdv: selection,
          frequency,
          dt_visit: DateDays.date,
        }

        if (newGetWorkspace.shared_environment) {
          if (selects.length === 0) {
            message.error('Selecione o sub workspaces !')
          } else {
            let dataPdv: any = []

            pdvInfo.result.forEach((item) => {
              selects.forEach((el: any) => {
                if (item.id === el.pdv) return dataPdv.push(item)
              })
            })

            let onlyArray: any = []

            selects.forEach((element: any) => {
              onlyArray.push(...element.subWorksapces)
            })

            data = {
              ...data,
              sub_workspaces: onlyArray,
              pdv: dataPdv,
            }
          }
        }

        await api_v2.post('script', data)
        Mixpanel.track('Criar roteiro manual', {
          params: data,
        })
        message.success('Roteiro cadastrado')
        loadLists()
        // window.location.reload()
      }
    } catch (err) {
      console.log(err)
    }
    setLoading2(false)
  }

  useEffect(() => {
    setSelects([])
  }, [visible])

  async function loadLists() {
    try {
      setLoanding(true)
      const query = qs.stringify({ user: id })
      const { data } = await api.get(`scripts?` + query)

      const ListamFrequency = data.result.map((item: any) => {
        return item.frequency
      })

      setterList2(ListamFrequency.reverse()[0])
      setterList(data.result)
      setCopyScript({})
      setTimeout(() => {
        setLoanding(false)
      }, 200)
    } catch (err) {
      console.log(err)
      setLoading2(false)
    }
  }

  const onSelectChange = (selectedRowKeys: any) => {
    setSelection(selectedRowKeys)
  }

  const rowSelection = {
    selection,
    onChange: onSelectChange,
  }

  const handleTable = async (queryParam: any) => {
    setLoading2(true)
    setPdvInfo({ ...pdvInfo, loading: true })
    const query = qs.stringify({
      perpage: queryParam.pageSize,
      page: queryParam.current,
    })
    const { data } = await api.get(`/pdv?${query}${queryFilter ? '&' + queryFilter : ''}`)

    await data.result.forEach(async (item: any, index_remove: any) => {
      await list.forEach((listItem: any) => {
        if (
          listItem.pdv.name === item.name &&
          Arr[semana] === listItem.weekNumber &&
          newArr[dia] === listItem.weekDay
        ) {
          let array = [item.name]
          let elemento = item.name

          let idx = array.indexOf(elemento)
          while (idx !== -1) {
            item_correct.push(idx)
            idx = array.indexOf(elemento, idx + 1)
            data.result.splice(index_remove, 1)
          }
          data.result.splice(index_remove, 1)
        }
      })
    })
    setLoading2(false)
    setPdvInfo({ ...data, loading: false })
  }

  const showModal = () => {
    if (!addIsScriptIsPermited()) return message.error('Você não tem autorização para mexer no roteiro.')
    setQueryFilter('')
    setVisible(true)
    loadPdv()
  }

  async function loadPdv() {
    setLoading2(true)
    const { data } = await api.get('pdv')
    await data.result.forEach(async (item: any, index_remove: any) => {
      await list.forEach((listItem: any) => {
        if (
          listItem.pdv.name === item.name &&
          Arr[semana] === listItem.weekNumber &&
          newArr[dia] === listItem.weekDay
        ) {
          let array = [item.name]
          let elemento = item.name

          let idx = array.indexOf(elemento)
          while (idx !== -1) {
            item_correct.push(idx)
            idx = array.indexOf(elemento, idx + 1)
            data.result.splice(index_remove, 1)
          }
          data.result.splice(index_remove, 1)
        }
      })
    })
    setLoading2(false)
    setPdvInfo({ ...data, loading: false })
  }

  const deleteVisit = async (data: any) => {
    try {
      await api_v2.delete(`/script/${data.id}`, data)
    } catch (error) {
      setLoading2(false)
    }
  }

  const updateScript = async (data: any) => {
    try {
      await api.post('/scripts', { ...data })
      await loadLists()
    } catch (error) {
      setLoading2(false)
    }
  }

  const [, dropRef] = useDrop(() => ({
    accept: 'CARD',
    async drop(item: any, monitor) {
      console.log('aquiiii')
      if (!addIsScriptIsPermited()) return message.error('Você não tem autorização para mexer no roteiro.')
      setLoading2(true)
      await deleteVisit({
        id: item.item.id,
        date: item.date.date,
        user: item.item.user.id,
        pdv: item.item.pdv.id,
      })

      let data: any = {
        user: parseInt(id),
        weekDay: newArr[dia],
        weekNumber: Arr[semana],
        pdv: [item.item.pdv.id],
        frequency,
        dt_visit: DateDays.date,
        order: 2,
      }

      if (newGetWorkspace.shared_environment) {
        data = {
          ...data,
          sub_workspaces: item.item.sub_workspaces,
        }
      }

      await updateScript(data)
    },
  }))

  return (
    <Container>
      <Header>
        <Day>{frequency !== 'MONTHLY' ? DateDays.date_formated : 'Total de visitas:'}</Day>
        <Count>{totalDate}</Count>
      </Header>

      {/*<BodyVisits ref={dropRef}>*/}
        <BodyVisits >
        {list.filter((item) => item.weekNumber === Arr[semana] && newArr[dia] === item.weekDay).sort((a, b) => a.order - b.order).map((item: any, key: any) => (
          <div key={key}>
            {/* {Arr[semana] === item.weekNumber && newArr[dia] === item.weekDay && ( */}
              <CardItem
                setCopyScript={setCopyScript}
                copyScript={copyScript}
                item={item}
                index={key}
                loadLists={loadLists}
                setLoading2={setLoading2}
                DateDays={DateDays}
                setLoanding={setLoanding}
                semana={semana}
                allItems={list.filter((item) => item.weekNumber === Arr[semana] && newArr[dia] === item.weekDay).sort((a, b) => a.order - b.order)}
                Arr={Arr}
                frequency={frequency}
                total={list.filter((item) => item.weekNumber === Arr[semana] && newArr[dia] === item.weekDay).sort((a, b) => a.order - b.order).length}
              />
            {/* )} */}
          </div>
        ))}
      </BodyVisits>
      <Footer>
        <ButtonAdd onClick={showModal}>
          <PlusOutlined twoToneColor='#6600cc' style={{ marginRight: 10 }} />
          Adicionar
        </ButtonAdd>
      </Footer>

      {visible && (
        <Modal
          title='Editar roteiro'
          visible={visible}
          okButtonProps={{ form: 'create-script', htmlType: 'submit' }}
          onCancel={handleCancel}
          width='90%'
          style={{ top: 20 }}
          bodyStyle={{ height: '100%' }}
        >
          <ContainerForm>
            <Form className='form' onSubmit={searchPdv}>
              <Row gutter={[18, 18]}>
                <Col span={6}>
                  <Input name='name' placeholder="Encontrar PDV's" />
                </Col>
                <Col span={6}>
                  <Input name='pdv_code' placeholder='Encontrar código do PDV' />
                </Col>
                <Col span={6}>
                  <Input name='corporate_name' placeholder='Encontrar corporação' />
                </Col>
                <Col span={6}>
                  <Input name='cnpj' placeholder='Encontrar CNPJ' />
                </Col>
                <Col span={6}>
                  <Input name='telephone' placeholder='Encontrar telefone' />
                </Col>
                <Col span={6}>
                  <Input name='manager' placeholder='Encontrar gerente' />
                </Col>
                <Col span={6}>
                  <Input name='cep' placeholder='Encontrar CEP' />
                </Col>
                <Col span={6}>
                  <Input name='city' placeholder='Encontrar cidade' />
                </Col>
                <Col span={6}>
                  <Input name='address' placeholder='Encontrar endereço' />
                </Col>
                <Col span={24}>
                  {loading2 ? (
                    loading2 === true && (
                      <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ textAlign: 'center' }}>
                          <Spin />
                          <p style={{ fontSize: '18px' }}> Aguarde ...</p>
                        </div>
                      </div>
                    )
                  ) : (
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Button htmlType='submit' type='primary'>
                        <FileSearchOutlined />
                        Pesquisar
                      </Button>
                    </div>
                  )}
                </Col>
              </Row>
            </Form>
          </ContainerForm>

          <Form id='create-script' className='form' onSubmit={handleSubmit}>
            {loading2 ? (
              <Loading />
            ) : (
              <ContainerTableModal>
                {newGetWorkspace.shared_environment ? (
                  <TableSubWorkspace
                    dataSource={pdvInfo.result}
                    pagination={{
                      current: pdvInfo.page,
                      pageSize: pdvInfo.perpage,
                      total: pdvInfo.total,
                      size: 'small',
                      showSizeChanger: true,
                      showTotal: () => `Exibindo ${pdvInfo.result.length} de ${pdvInfo.total} de registros`,
                    }}
                    loading={pdvInfo.loading}
                    onChange={handleTable}
                    setter={(e: any) => setSelects(e)}
                  />
                ) : (
                  <Table
                    columns={columns}
                    rowSelection={rowSelection}
                    rowKey={(pdv) => pdv.id}
                    dataSource={pdvInfo.result}
                    size='middle'
                    scroll={{ y: 1500 }}
                    pagination={{
                      current: pdvInfo.page,
                      pageSize: pdvInfo.perpage,
                      total: pdvInfo.total,
                      size: 'small',
                      showSizeChanger: true,
                      showTotal: () => `Exibindo ${pdvInfo.result.length} de ${pdvInfo.total} de registros`,
                    }}
                    loading={pdvInfo.loading}
                    onChange={handleTable}
                  />
                )}
              </ContainerTableModal>
            )}
          </Form>
        </Modal>
      )}
    </Container>
  )
}
