import React from 'react'
import {
  Container,
  ContainerHeader,
  ContainerUser,
  ContainerStatus,
  TextBlack,
  ContainerOff,
  ContainerTasks,
  Tasks,
  StatusRealizado,
  StatusInProgress,
  StatusPendente,
  StatusExpired,
  TextLight,
} from './style'
import { MdBatteryChargingFull } from 'react-icons/md'
import { Link, useParams } from 'react-router-dom'
import moment from 'moment'

interface IProps {
  data: any
  user: any
}

export const CardVisits = ({ data, user }: IProps) => {
  const { id }: any = useParams()

  return (
    <Container>
      <Link to={`/backoffice/survey/visitsDetails/${id}/${data.id}`}>
        <ContainerHeader>
          <ContainerUser>
            <img src={`https://via.placeholder.com/150/ff872c/fff/?text=${user.name?.substr(0, 1)}`} alt='name' />
            <ContainerStatus>
              <TextBlack style={{ fontSize: 17 }}>{user.name}</TextBlack>
              <ContainerOff>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: 20 }}>
                  <MdBatteryChargingFull style={{ transform: 'rotateZ(90deg)', fontSize: 20, color: '#b56aff' }} />
                  <TextLight>80%</TextLight>
                </div>
                {data.status === 'IN_PROGRESS' ? (
                  <StatusInProgress> Em Progresso </StatusInProgress>
                ) : data.status === 'COMPLETE' ? (
                  <StatusRealizado> Realizado </StatusRealizado>
                ) : data.status === 'PENDENT' ? (
                  <StatusPendente> Pendente </StatusPendente>
                ) : (
                  <StatusExpired> Expirada </StatusExpired>
                )}
              </ContainerOff>
            </ContainerStatus>
          </ContainerUser>
        </ContainerHeader>
        <ContainerTasks>
          <Tasks>
            <TextBlack>Loja:</TextBlack>
            {data.pdv && 
              <TextLight>{data.pdv.name}</TextLight>
            }
            {data.pdv_name && 
              <TextLight>{data.pdv_name}</TextLight>
            }
          </Tasks>
          <Tasks>
            <TextBlack>Criado em:</TextBlack>
            <TextLight>{moment(data.dt_visit).format('DD/MM/YYYY')}</TextLight>
          </Tasks>
          <Tasks>
            <TextBlack>Tempo de deslocamento:</TextBlack>
            <TextLight>00:00</TextLight>
          </Tasks>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Tasks>
                <TextBlack>Check in:</TextBlack>
                <TextLight>{data.check_in}</TextLight>
              </Tasks>
              <div style={{ display: 'flex', alignItems: 'center', marginRight: 20 }}>
                <MdBatteryChargingFull style={{ transform: 'rotateZ(90deg)', fontSize: 20, color: '#b56aff' }} />
                <TextLight>80% - distância</TextLight>
              </div>
            </div>
            <div>
              <Tasks>
                <TextBlack>Check out:</TextBlack>
                <TextLight>{data.check_out}</TextLight>
              </Tasks>
              <div style={{ display: 'flex', alignItems: 'center', marginRight: 20 }}>
                <MdBatteryChargingFull style={{ transform: 'rotateZ(90deg)', fontSize: 20, color: '#b56aff' }} />
                <TextLight>70% - distância - duração</TextLight>
              </div>
            </div>
          </div>
          <Tasks>
            <TextBlack>ENDEREÇO:</TextBlack>
            {data.pdv && 
              <TextLight>{data.pdv.address}</TextLight>
            }
          </Tasks>
        </ContainerTasks>
      </Link>
    </Container>
  )
}
