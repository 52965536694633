import {
  ButtonAvaliate,
  ButtonSendAvaliate,
  ContainerButtonsAvaliate,
  ContainerModal,
  SmallTitleModal,
  TitleDescrip,
  TitleModal,
} from './style'
import { Mixpanel } from '../../utils/mixpanel'
import { useEffect, useState } from 'react'
import { TOKEN_USER } from '../../utils/login'
import TextArea from 'antd/lib/input/TextArea'
import { Label } from '../../GlobalStyles'
import api_v2 from '../../services/api-v2'
import { message } from 'antd'
import { Loading } from '../Loading'

interface IProps {
  visible: boolean
  close: (boolean: any) => void
}

interface IAvaliate {
  rate: number
  obs: string
}

export const ModalAvaliate = ({ visible, close }: IProps) => {
  const [name, setName] = useState('')
  const [avaliate, setAvaliate] = useState<IAvaliate>({} as IAvaliate)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    let getName = localStorage.getItem(TOKEN_USER)
    if (getName) {
      let splitName = getName.split(' ')
      if (splitName.length > 0) setName(splitName[0])
    }
  }, [])

  useEffect(() => {
    setAvaliate({} as IAvaliate)
  }, [visible])

  const sendRating = async () => {
    try {
      if (!avaliate.rate) {
        message.error('Por favor, selecione uma nota.')

        return
      }
      setLoading(true)

      await api_v2.post(`user/rating-backoffice`, avaliate)
      message.success('Sucesso ao enviar sua avaliação.')
      Mixpanel.track('Avaliar backoffice', {
        params: avaliate,
      })
      setLoading(false)
      close(false)
    } catch (error) {
      console.log(error)
      setLoading(false)

      message.error('Erro ao enviar sua avaliação.')
    }
  }

  return (
    <ContainerModal
      visible={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
      width={'65vw'}
    >
      {loading && <Loading />}
      <TitleModal>Olá, {name} seu feedback é muito importante para nós.</TitleModal>
      <SmallTitleModal>
        Em uma escala de 0 a 10, como o rock-at atende as suas necessidades no fluxo de trabalho?
      </SmallTitleModal>
      <ContainerButtonsAvaliate>
        <ButtonAvaliate
          active={avaliate.rate === 0}
          onClick={() => setAvaliate({ ...avaliate, rate: 0 })}
          color='#B83455'
          colorHover='#cc4f6e'
        >
          0
        </ButtonAvaliate>
        <ButtonAvaliate
          active={avaliate.rate === 1}
          onClick={() => setAvaliate({ ...avaliate, rate: 1 })}
          color='#B83455'
          colorHover='#cc4f6e'
        >
          1
        </ButtonAvaliate>
        <ButtonAvaliate
          active={avaliate.rate === 2}
          onClick={() => setAvaliate({ ...avaliate, rate: 2 })}
          color='#B83455'
          colorHover='#cc4f6e'
        >
          2
        </ButtonAvaliate>
        <ButtonAvaliate
          active={avaliate.rate === 3}
          onClick={() => setAvaliate({ ...avaliate, rate: 3 })}
          color='#B83455'
          colorHover='#cc4f6e'
        >
          3
        </ButtonAvaliate>
        <ButtonAvaliate
          active={avaliate.rate === 4}
          onClick={() => setAvaliate({ ...avaliate, rate: 4 })}
          color='#B83455'
          colorHover='#cc4f6e'
        >
          4
        </ButtonAvaliate>
        <ButtonAvaliate
          active={avaliate.rate === 5}
          onClick={() => setAvaliate({ ...avaliate, rate: 5 })}
          color='#FBAD3F'
          colorHover='#f9c172'
        >
          5
        </ButtonAvaliate>
        <ButtonAvaliate
          active={avaliate.rate === 6}
          onClick={() => setAvaliate({ ...avaliate, rate: 6 })}
          color='#FBAD3F'
          colorHover='#f9c172'
        >
          6
        </ButtonAvaliate>
        <ButtonAvaliate
          active={avaliate.rate === 7}
          onClick={() => setAvaliate({ ...avaliate, rate: 7 })}
          color='#FBAD3F'
          colorHover='#f9c172'
        >
          7
        </ButtonAvaliate>
        <ButtonAvaliate
          active={avaliate.rate === 8}
          onClick={() => setAvaliate({ ...avaliate, rate: 8 })}
          color='#03C775'
          colorHover='#42e8a2'
        >
          8
        </ButtonAvaliate>
        <ButtonAvaliate
          active={avaliate.rate === 9}
          onClick={() => setAvaliate({ ...avaliate, rate: 9 })}
          color='#03C775'
          colorHover='#42e8a2'
        >
          9
        </ButtonAvaliate>
        <ButtonAvaliate
          active={avaliate.rate === 10}
          onClick={() => setAvaliate({ ...avaliate, rate: 10 })}
          color='#03C775'
          colorHover='#42e8a2'
        >
          10
        </ButtonAvaliate>
      </ContainerButtonsAvaliate>
      <ContainerButtonsAvaliate style={{ marginBottom: 20, justifyContent: 'space-between' }}>
        <TitleDescrip>Não se adapta</TitleDescrip>
        <TitleDescrip>É perfeita</TitleDescrip>
      </ContainerButtonsAvaliate>
      <Label>Escreva alguma observação:</Label>
      <TextArea rows={4} onChange={(e) => setAvaliate({ ...avaliate, obs: e.target.value })} />
      <ButtonSendAvaliate onClick={() => sendRating()}>Avaliar</ButtonSendAvaliate>
    </ContainerModal>
  )
}
