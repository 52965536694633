import axios from 'axios'
import { getToken } from '../utils/login'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'multipart/form-data'
  }
})

api.interceptors.request.use(config => {
  const token = getToken()
  const team = sessionStorage.getItem('@rocketpdv:workspace')
    ? sessionStorage.getItem('@rocketpdv:workspace')
    : undefined

  const headers = { ...config.headers }
  if (token) headers.Authorization = `Bearer ${token}`
  if (team) {
    headers.workspace = JSON.parse(team).slug
  }
  return { ...config, headers }
})

export default api
