import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;

  .ant-descriptions-item-label {
    font-weight: bold;
  }
  .image-survey {
    max-width: 260px;
    max-height: 160px;
    width: auto;
    height: auto;
  }
`

export const Title = styled.div`
  display: flex;
  flex: 1;
  margin: 10px 0px 0px 0px;
  font-size: 18px;
`

export const ContentDivider = styled.div`
  margin-bottom: 3%;
`