import { ApexOptions } from 'apexcharts'
import React from 'react'
import ReactApexChart from 'react-apexcharts'

interface Props {
  series: SeriesProps[]
  id?: string
  type?: string
  legend?: boolean
  dataLabel?: boolean
  tooltip?: boolean
  categories: string[]
}

interface SeriesProps {
  name?: string
  data: any
}

export const LinearCard = ({ series, categories, legend, type, tooltip, dataLabel }: Props) => {
  const apexLineChartWithLables: ApexOptions = {
    chart: {
      type: 'line',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: dataLabel === false ? false : true,
      distributed: false,
      offsetY: -6,
      formatter: function (value, opt) {
        if (type === 'time') {
          return series[opt.seriesIndex].data[opt.dataPointIndex]
        } else {
          return `${value}`
        }
      },
      background: {
        enabled: false,
        foreColor: '#fff',
        padding: 4,
        borderRadius: 2,
        borderWidth: 1,
        borderColor: '#fff',
        opacity: 0.9,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 2,
          blur: 1,
          color: '#000',
          opacity: 0.45,
        },
      },
      style: {
        fontWeight: 'bold',
        fontSize: '14px',
        colors: ['#637282'],
      },
    },

    stroke: {
      width: [3, 3],
      curve: 'smooth',
    },
    grid: {
      show: true,
    },
    markers: {
      size: 6,
    },
    yaxis: {
      min: 0,
    },
    xaxis: {
      categories,
    },
    tooltip: {
      enabled: tooltip ? tooltip : false,
      y: {
        formatter: function (value, opt) {
          if (type === 'time') {
            return series[opt.seriesIndex].data[opt.dataPointIndex]
          } else {
            return `${value}`
          }
        },
        title: {
          formatter: (seriesName) => '',
        },
      },
    },
    legend: {
      show: true,
    },
    colors: ['#6600cc', '#00C9A7', '#005B44'],
    fill: {
      opacity: 0.3,
      type: '',
      gradient: {
        type: 'vertical',
        shadeIntensity: 1,
        colorStops: [
          {
            offset: 0,
            color: '#b56aff',
            opacity: 0.4,
          },
          {
            offset: 100,
            color: '#6600cc',
            opacity: 0.1,
          },
        ],
      },
    },
  }

  return <ReactApexChart height={450} options={apexLineChartWithLables} series={series} type='area' />
}
