import React, { useState } from 'react'
import { ContainerModal } from './style'
import { CloudUploadOutlined, CloudDownloadOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import { Loading } from '../Loading'
import { BtnExport, ButtonLink, CardCreateUpload, ContainerType, Description, Title } from '../ModalImportGeneral/style'
import api_v2 from '../../services/api-v2'
import ImportDoc from '../../assets/images/importDoc.png'
import { Mixpanel } from '../../utils/mixpanel'

interface IProps {
  visible: boolean
  close: (boolean: any) => void
  reloadApi?: (boolean: any) => void
  id: any
  context: any
}

export const ModalCreateMixC = ({ visible, close, reloadApi, id, context }: IProps) => {
  const [loading, setLoading] = useState<boolean>(false)

  return (
    <ContainerModal
      visible={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
      destroyOnClose={true}
    >
      {loading && <Loading />}
      <ContainerType>
        <Title>Importar</Title>
        <Description>Importe sua planilha automaticamente.</Description>
      </ContainerType>
      <CardCreateUpload
        customRequest={async (file: any) => {
          setLoading(true)
          if (file) {
            const archive: any = new FormData()
            archive.append('file', file.file)
            archive.append('mix_category_id', id)
            archive.append('context', 'MIX_CATEGORY')
            archive.append('config', JSON.stringify({ id: id }))
            try {
              await api_v2.post(`/import-xlsx/MIX_CATEGORY`, archive, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              })

              Mixpanel.track('Importou mix de categoria', {
                params: {
                  mix_product_id: id,
                  context: context,
                },
              })

              Modal.success({
                title: 'Arquivo importado com sucesso!',
                content: 'Seu arquivo será processado, e ao finalizar você receberá um e-mail.',
                onOk() {
                  close(false)
                },
              })
            } catch (error) {
              Modal.error({
                title: 'Ops!',
                content: 'Ocorreu um erro ao enviar arquivo, tente novamente mais tarde!',
                onOk() {
                  close(false)
                },
              })
            }
          }

          setLoading(false)
        }}
      >
        <img src={ImportDoc} alt='user' />
        <h4>Importar XLSX</h4>
        <p>Importe planilha.</p>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Button style={{ backgroundColor: '#b56aff', marginTop: 6 }}>
            <CloudUploadOutlined />
            Importar
          </Button>
        </div>
      </CardCreateUpload>
      {context === 'NETWORK' && (
        <ButtonLink>
          <CloudDownloadOutlined />
          <BtnExport
            href={
              'https://firebasestorage.googleapis.com/v0/b/rocketpdv-dev.appspot.com/o/rock-at-config%2Fmodelos%2FMODELO_MIX_REDE_CATEGORY.xlsx?alt=media&token=9cea1557-54ce-41ca-ac6d-639d37dff57a'
            }
            target='_blank'
          >
            Baixe o modelo
          </BtnExport>
        </ButtonLink>
      )}
      {context === 'FLAG' && (
        <ButtonLink>
          <CloudDownloadOutlined />
          <BtnExport
            href={
              'https://firebasestorage.googleapis.com/v0/b/rocketpdv-dev.appspot.com/o/rock-at-config%2Fmodelos%2FMODELO_MIX_BANDEIRA_CATEGORIA.xlsx?alt=media&token=0ecf543b-74cc-4a5b-b0fe-8f773d505f36'
            }
            target='_blank'
          >
            Baixe o modelo
          </BtnExport>
        </ButtonLink>
      )}
      {context === 'PDV' && (
        <ButtonLink>
          <CloudDownloadOutlined />
          <BtnExport
            href={
              'https://firebasestorage.googleapis.com/v0/b/rocketpdv-dev.appspot.com/o/rock-at-config%2Fmodelos%2FMODELO_MIX_PDV_CATEGORY.xlsx?alt=media&token=c6f703f6-eb12-405a-8994-f32e701292b3'
            }
            target='_blank'
          >
            Baixe o modelo
          </BtnExport>
        </ButtonLink>
      )}
    </ContainerModal>
  )
}
