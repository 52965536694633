import { useState, useRef, useEffect } from 'react'
import { ContainerModal, Container, ContainerDatePick } from './style'
import { Form } from '@unform/web'
import { EditOutlined, UploadOutlined } from '@ant-design/icons'
import { FormHandles } from '@unform/core'
import { Button, ContainerStep2 } from '../ModalCreateVisit/style'
import { LabelCheck } from '../../pages/Products/ProductCreatePage/styles'
import { ContainerLogo } from '../ModalSugestion/style'
import { GroupForm } from '../../GlobalStyles'
import SimpleSelect from '../form/SimpleSelect'
import { Checkbox, DatePicker, message, Upload, Button as ButtonAntd } from 'antd'
import * as Yup from 'yup'
import moment from 'moment'
import api from '../../services/api'
import TextArea from '../form/SimpleTextArea'
import firebase from '../../utils/firebase'
import api_v2 from '../../services/api-v2'
import { Loading } from '../Loading'
import { Mixpanel } from '../../utils/mixpanel'
import { ContextUpload } from '../../context/contextUpload'
import { hasIndex } from '../Menu/menuHorizonte'
import { getDataUser } from '../../utils/login'

interface IProps {
  visible: boolean
  close: (boolean: any) => void
  visit: any
  resetVisit: any
}

interface IPendent {
  reset_visit: boolean
}

interface IComplete {
  check_in: string
  check_out: string
}

export const ModalEditStatus = ({ visible, close, visit, resetVisit }: IProps) => {
  const { permissions } = ContextUpload()
  const [loading, setLoading] = useState(false)
  const [pictures, setPictures] = useState<any>([])
  const [options, setOptions] = useState<any>([])
  const [statusSelected, setStatusSelected] = useState<any>()
  const [faultsOptions, setFaultsOptions] = useState<any>({})
  const [dataPendent, setDataPendent] = useState<IPendent>({ reset_visit: false })
  const [dataComplete, setDataComplete] = useState<IComplete>({ check_out: '', check_in: '' })
  const formRef = useRef<FormHandles>(null)

  const handleSubmit = async (body: any) => {
    try {
      setLoading(true)
      let required: any = {
        new_status: Yup.string().required('Selecione o status.'),
      }

      if (body.new_status && body.new_status === 'JUSTIFIED_ABSENCE') {
        if (body.faults_option === '') body.faults_option = null
        required.faults_option = Yup.number().required('Selecione o motivo.').nullable()

        let getRequired = faultsOptions.filter((el: any) => el.id === body.faults_option)
        if (getRequired.length > 0) {
          if (getRequired[0].mandatory_picture && pictures.length === 0) {
            message.error('É obrigatório enviar uma foto junto.')
            setLoading(false)
            return
          }
          if (getRequired[0].mandatory_observation)
            required.observation = Yup.string().required('É obrigatório colocar a observação.')
          body.pictures = pictures.map((el: any) => el.url)
        }
      }

      if (body.new_status && body.new_status === 'PENDENT') {
        body.reset_visit = dataPendent.reset_visit
      }

      if (body.new_status && body.new_status === 'COMPLETE') {
        if (!dataComplete.check_in || !dataComplete.check_out) {
          message.error('É necessario preencher todas as datas')
          setLoading(false)
          return
        } else {
          body.check_in = dataComplete.check_in
          body.check_out = dataComplete.check_out
        }
      }
      const schema = Yup.object().shape(required)
      await schema.validate(body, {
        abortEarly: false,
      })
      await api_v2.put(`/visit/change-status/${visit.id}`, body)
      await api_v2.put(`/visit/change-status-history/${visit.id}`, {newStatus: body, oldStatus: visit, edit_by: getDataUser().id})
      Mixpanel.track('Alterou status visita', {
        params: { id_visit: visit.id, ...body },
      })

      resetVisit()
      message.success('Sucesso ao editar visita.')
      setLoading(false)
      close(!visible)
    } catch (error) {
      setLoading(false)
      if (error instanceof Yup.ValidationError) {
        setLoading(false)
        error.inner.forEach((el: any) => {
          formRef.current!.setFieldError(el.path, el.message)
        })
      } else {
        message.error('Erro ao editar visita.')
      }
    }
  }

  const getFaultsOptions = async () => {
    try {
      const { data } = await api.get(`/faults_options?page=1&perpage=100`)
      setFaultsOptions(data.result)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    let newOptions = [
      { value: 'PENDENT', label: 'Pendente' },
      { value: 'COMPLETE', label: 'Concluído' },
      { value: 'IN_PROGRESS', label: 'Em progresso' },
      { value: 'JUSTIFIED_ABSENCE', label: 'Justificado' },
    ]
    if (visit.status === 'PENDENT') {
      delete newOptions[0]
      delete newOptions[2]
    }
    if (visit.status === 'COMPLETE') delete newOptions[1]
    if (visit.status === 'IN_PROGRESS') delete newOptions[2]
    if (visit.status === 'JUSTIFIED_ABSENCE') delete newOptions[3]

    setOptions(newOptions)

    setStatusSelected(null)
    setPictures([])
    setDataComplete({
      ...dataComplete,
      check_out: visit.check_out !== '-' ? visit.check_out : '',
      check_in: visit.check_in !== '-' ? visit.check_in : '',
    })

    getFaultsOptions()
  }, [visible]) //eslint-disable-line

  const handleChange = ({ file, fileList }: any) => {
    const team: any = sessionStorage.getItem('@rocketpdv:workspace')
    uploadImage({
      uri: file.originFileObj,
      slug: JSON.parse(team).slug,
      fileList: file,
      all: fileList,
    })
  }

  const uploadImage = async (urls: any) => {
    try {
      setLoading(true)
      let file = firebase.storage().ref(`${urls.slug}/JUSTIFIED_ABSENCE/${urls.uri.name}`)

      await file.put(urls.uri)
      const url = await file.getDownloadURL()

      let newPictures: any = []

      urls.all.forEach((el: any) => {
        if (el.uid === urls.fileList.uid) {
          newPictures.push({ ...urls.fileList, status: 'done', url: url })
        } else {
          newPictures.push(el)
        }
      })

      setPictures(newPictures)
      setLoading(false)
      message.success('Sucesso ao subir arquivo')
    } catch (error) {
      console.log(error)
      setLoading(false)
      message.error('Erro ao subir arquivo')
    }
  }

  function disabledDate(current: any) {
    // Can not select days before today and today
    return current && current < moment(dataComplete.check_in, 'DD/MM/YYYY HH:mm:ss').locale('pt-br')
  }

  return (
    <ContainerModal
      visible={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
      destroyOnClose={true}
    >
      {loading && <Loading />}
      <Container>
        <Form className='form' ref={formRef} initialData={{ is_active: true }} onSubmit={handleSubmit}>
          <ContainerStep2>
            <h3>Editar status</h3>
            <ContainerLogo style={{ marginBottom: 10 }}>
              <EditOutlined />
            </ContainerLogo>
            <GroupForm>
              <LabelCheck style={{ textAlign: 'left' }}>Status original</LabelCheck>
              <SimpleSelect
                name='origin_status'
                isMulti={false}
                isDisabled={true}
                value={{
                  value: visit.status,
                  label:
                    visit.status === 'PENDENT'
                      ? 'Pendente'
                      : visit.status === 'JUSTIFIED_ABSENCE'
                      ? 'Justificado'
                      : visit.status === 'IN_PROGRESS'
                      ? 'Em progresso'
                      : 'Concluído',
                }}
                options={options}
              />
            </GroupForm>
            <GroupForm>
              <LabelCheck style={{ textAlign: 'left' }}>Novo status</LabelCheck>
              <SimpleSelect
                name='new_status'
                isMulti={false}
                value={statusSelected}
                onChange={setStatusSelected}
                options={options}
                placeholder='Selecione o novo status'
              />
            </GroupForm>

            {statusSelected && statusSelected.value === 'JUSTIFIED_ABSENCE' && (
              <>
                <GroupForm>
                  <LabelCheck style={{ textAlign: 'left' }}>Selecione o motivo</LabelCheck>
                  <SimpleSelect
                    name='faults_option'
                    isMulti={false}
                    placeholder='Selecione o motivo'
                    options={faultsOptions.map((el: any) => {
                      return { value: el.id, label: el.description }
                    })}
                  />
                </GroupForm>

                <GroupForm>
                  <LabelCheck style={{ textAlign: 'left' }}>Observação</LabelCheck>
                  <TextArea name='observation' />
                </GroupForm>
                <GroupForm>
                  <LabelCheck style={{ textAlign: 'left' }}>Foto</LabelCheck>
                  <Upload
                    listType='picture'
                    onChange={handleChange}
                    defaultFileList={pictures}
                    customRequest={({ onSuccess }) => {
                      if (onSuccess) onSuccess('ok', undefined)
                    }}
                  >
                    <ButtonAntd icon={<UploadOutlined />}>Upload</ButtonAntd>
                  </Upload>
                </GroupForm>
              </>
            )}
            {hasIndex(['team.reset_task'], permissions) > -1 && (
              <>
                {statusSelected && statusSelected.value === 'PENDENT' && (
                  <GroupForm>
                    <Checkbox
                      value={dataPendent.reset_visit}
                      onChange={(evt: any) => setDataPendent({ reset_visit: evt.target.checked })}
                    >
                      <div style={{ display: 'flex' }}>
                        Zerar tarefas ?
                        <p style={{ fontSize: 13, color: '#8d8989', marginLeft: 10 }}>
                          (Isso irá apagar as respostas já enviadas)
                        </p>
                      </div>
                    </Checkbox>
                  </GroupForm>
                )}
              </>
            )}
            {statusSelected && statusSelected.value === 'COMPLETE' && (
              <>
                <GroupForm>
                  <LabelCheck style={{ textAlign: 'left' }}>Selecione a data do check-in </LabelCheck>
                  <ContainerDatePick>
                    <DatePicker
                      format='DD/MM/YYYY HH:mm:ss'
                      onChange={(e, b) => setDataComplete({ ...dataComplete, check_in: b })}
                      defaultValue={
                        dataComplete.check_in
                          ? moment(dataComplete.check_in, 'DD/MM/YYYY HH:mm:ss').locale('pt-br')
                          : undefined
                      }
                      showTime={true}
                      showHour={true}
                      showMinute={true}
                      disabled={visit.check_in !== '-' && true}
                      style={{ width: '100%', height: 38 }}
                    />
                  </ContainerDatePick>
                </GroupForm>
                <GroupForm>
                  <LabelCheck style={{ textAlign: 'left' }}>Selecione a data do check-out </LabelCheck>
                  <ContainerDatePick>
                    <DatePicker
                      format='DD/MM/YYYY HH:mm:ss'
                      onChange={(e, b) => setDataComplete({ ...dataComplete, check_out: b })}
                      defaultValue={dataComplete.check_out ? moment(dataComplete.check_out) : undefined}
                      showTime={true}
                      showHour={true}
                      showMinute={true}
                      // disabledDate={disabledDate}
                      style={{ width: '100%', height: 38 }}
                    />
                  </ContainerDatePick>
                </GroupForm>
              </>
            )}

            <Button type='submit' style={{ width: '100%', height: '55px', marginTop: 20 }}>
              Editar
            </Button>
          </ContainerStep2>
        </Form>
      </Container>
    </ContainerModal>
  )
}
