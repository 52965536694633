import React from 'react'
import { Router, Switch } from 'react-router-dom'
import LayoutDefault from '../components/LayoutDefault'
import BrandCreatePage from '../pages/Products/BrandCreatePage'
import BrandEditPage from '../pages/Products/BrandEditPage'
import BrandListPage from '../pages/Products/BrandListPage'
import CategoryListPage from '../pages/Products/CategoryListPage'
import ProductCreatePage from '../pages/Products/ProductCreatePage'
import ProductEditPage from '../pages/Products/ProductEditPage'
import { ProductListPage } from '../pages/Products/ProductListPage'
// import DashboardPage from '../pages/DashboardPage'
import { MonitoringPage } from '../pages/MonitoryPage/MonitoringPage'
import SignInPage from '../pages/SignInPage'
import FlagCreatePage from '../pages/Stores/FlagCreatePage'
import FlagEditPage from '../pages/Stores/FlagEditPage'
import FlagListPage from '../pages/Stores/FlagListPage'
import NetworkCreatePage from '../pages/Stores/NetworkCreatePage'
import NetworkEditPage from '../pages/Stores/NetworkEditPage'
import NetworkListPage from '../pages/Stores/NetworkListPage'
import PDVCreatePage from '../pages/Stores/PDVCreatePage'
import PDVEditPage from '../pages/Stores/PDVEditPage'
import PDVListPage from '../pages/Stores/PDVListPage'
import RegionalCreatePage from '../pages/Stores/RegionalCreatePage'
import RegionalEditPage from '../pages/Stores/RegionalEditPage'
import RegionalListPage from '../pages/Stores/RegionalListPage'
import FormListPage from '../pages/Survey/FormListPage'
import JustificationCreatePage from '../pages/Survey/JustificationCreatePage'
import JustificationEditPage from '../pages/Survey/JustificationEditPage'
import JustificationListPage from '../pages/Survey/JustificationListPage'
import ProfileEditPage from '../pages/Team/ProfileEditPage'
import ProfileListPage from '../pages/Team/ProfileListPage'
import WorkspacePage from '../pages/WorkspacePage'
import history from '../routes/history'
import PrivateRoute from '../routes/private'
import GuestRoute from './guest'
import VisitsPage from '../pages/Survey/VisitsPage'
import MixProductListPage from '../pages/Products/MixProductListPage'
import MixProductCreatePage from '../pages/Products/MixProductCreatePage'
import MixProductSub from '../pages/Products/MixProductSubList'
import { WorkProfilePagePromoter } from '../pages/Team/Promoter'
import { WorkProfilePageGestor } from '../pages/Team/User'
import { ViewUser } from '../pages/Team/ViewUser'
import { VisitsDetail } from '../pages/Survey/VisitsDetail'
import FormCreatePage from '../pages/FormCreatePage'
import SearchListPage from '../pages/Reports/SearchListPage'
import SearchViewPage from '../pages/Reports/SearchViewPage'
import ProfileCreatePage from '../pages/Team/ProfileCreatePage'
import NewWorkspace from '../pages/WorkspacePage/newWorkspace'
import Tasks from '../pages/Survey/Tasks'
import Pictures from '../pages/Reports/Pictures'
import Training from '../pages/Reports/Training'
import DashboardPage from '../pages/DashboardPage'
import AppsPage from '../pages/AppsPage'
import { ListDash } from '../pages/Analytcs/listDash'
import { DashView } from '../pages/Analytcs/viewDash'
import { WorkProfilePageSupervisor } from '../pages/Team/Supervisor'
import TimeComparate from '../pages/Analytcs/time'
import PainelVisits from '../pages/Analytcs/painelVisits'
import JustifyPage from '../pages/Analytcs/justify'
import RankingPage from '../pages/Analytcs/team/ranking'
import DetailPage from '../pages/Analytcs/team/detail'
import ComparateTimePage from '../pages/Analytcs/team/service'
import ScrollToTop from './ScrollToTop'
import PlannedPage from '../pages/MonitoryPage/Planned'
import VisitsJustify from '../pages/Reports/VisitsJustify'
import Move from '../pages/Analytcs/move'
import ProcessPage from '../pages/Process/ProcessPage'
import { AfterAndLast } from '../pages/Process/AfterAndLast'
import SchedulingTask from '../pages/Survey/Scheduling_task'
import TrainingFile from '../pages/Reports/TrainingFile'
import SchedulingTaskCreate from '../pages/Survey/Scheduling_task_create'
import ProcessPagePPT from '../pages/Process/ProcessPagePPT'
import { Block } from '../pages/block'
import { NotAccess } from '../pages/notAccess'
import StatusDay from '../pages/MonitoryPage/StatusDay'
import Licenses from '../pages/Licenses'
import MixCategoryListPage from '../pages/Products/MixCategoryListPage'
import JorneyList from '../pages/Team/JorneyList'
import JorneyCreate from '../pages/Team/JorneyCreate'
import RemovalListPage from '../pages/Survey/RemovalListPage'
import RemovalCreatePage from '../pages/Survey/RemovalCreatePage'
import MixCategorySubList from '../pages/Products/MixCategorySubList'
import MixBrandListPage from '../pages/Products/MixBrandListPage'
import MixBrandSubList from '../pages/Products/MixBrandSubList'
import HistoryRemoval from '../pages/Reports/HistoryRemoval'
import { VisitsJustifyHistory } from '../pages/Reports/VisitsJustifyHistory'
import UserEditPage from '../pages/Team/UserEditPage'
import {HistoryScript} from "../pages/Reports/HistoryScript";
import LunchListPage from '../pages/Reports/LunchListPage'
import PointListPage from '../pages/Reports/PointListPage'
import KMPage from '../pages/Reports/KMPage'
import AdminWork from '../pages/AdminWork'

const Routes = () => (
  <Router history={history}>
    <ScrollToTop>
      <Switch>
        <GuestRoute exact path='/backoffice/signin' component={SignInPage} />
        <PrivateRoute exact path='/viewsApp' component={AppsPage} />
        <PrivateRoute exact path='/backoffice/workspace' component={WorkspacePage} />
        <PrivateRoute exact path='/backoffice/survey/forms/create' component={FormCreatePage} />
        <PrivateRoute exact path='/block' component={Block} />
        <PrivateRoute exact path='/notAcces' component={NotAccess} />
        <PrivateRoute exact path='/backoffice/survey/forms/create/:id' component={FormCreatePage} />
        <PrivateRoute exact path='/backoffice/newWorkspace' component={NewWorkspace} />
        <PrivateRoute exact path='/backoffice/admin' component={AdminWork} />
        <PrivateRoute exact path='/backoffice/newWorkspace/:id' component={NewWorkspace} />

        <PrivateRoute exact path='/backoffice/testeee' component={NewWorkspace} />
        <PrivateRoute exact path='/analitycs/view/:id' component={DashView} />
        <LayoutDefault>
          <PrivateRoute exact path='/analitycs/move' component={Move} />

          <PrivateRoute exact path='/Analitycs' component={ListDash} />
          <PrivateRoute exact path='/Analitycs/timeComparate' component={TimeComparate} />
          <PrivateRoute exact path='/Analitycs/visits' component={PainelVisits} />
          <PrivateRoute exact path='/Analitycs/justify' component={JustifyPage} />
          <PrivateRoute exact path='/Analitycs/team/ranking' component={RankingPage} />
          <PrivateRoute exact path='/Analitycs/team/detail' component={DetailPage} />
          <PrivateRoute exact path='/Analitycs/team/comparative' component={ComparateTimePage} />

          <PrivateRoute exact path='/backoffice/monitory/monitoring' component={MonitoringPage} />
          <PrivateRoute exact path='/backoffice/monitory/planned' component={PlannedPage} />
          <PrivateRoute exact path='/backoffice/monitory/status-day' component={StatusDay} />
          <PrivateRoute exact path='/backoffice/licenses' component={Licenses} />
          <PrivateRoute exact path={['/backoffice/', '/']} component={DashboardPage} />
          {/* <PrivateRoute exact path={['/backoffice/', '/']} component={DashboardPage} /> */}

          <PrivateRoute exact path='/backoffice/process/' component={ProcessPage} />
          <PrivateRoute exact path='/backoffice/lunch' component={LunchListPage} />
          <PrivateRoute exact path='/backoffice/km' component={KMPage} />
          <PrivateRoute exact path='/backoffice/point' component={PointListPage} />
          <PrivateRoute exact path='/backoffice/processPPT' component={ProcessPagePPT} />
          <PrivateRoute exact path='/backoffice/team/users/promoter' component={WorkProfilePagePromoter} />
          <PrivateRoute exact path='/backoffice/team/users/supervisor' component={WorkProfilePageSupervisor} />
          <PrivateRoute exact path='/backoffice/team/users' component={WorkProfilePageGestor} />
          <PrivateRoute exact path='/backoffice/team/users/edit/:id' component={UserEditPage} />
          <PrivateRoute exact path='/backoffice/teamById/:id' component={ViewUser} />
          <PrivateRoute exact path='/backoffice/team/profiles' component={ProfileListPage} />
          <PrivateRoute exact path='/backoffice/team/jorney' component={JorneyList} />
          <PrivateRoute exact path='/backoffice/team/jorney/create' component={JorneyCreate} />
          <PrivateRoute exact path='/backoffice/team/jorney/edit/:id' component={JorneyCreate} />
          <PrivateRoute exact path='/backoffice/team/profiles/edit/:id' component={ProfileEditPage} />
          <PrivateRoute exact path='/backoffice/catalog/brands' component={BrandListPage} />
          <PrivateRoute exact path='/backoffice/catalog/brands/create' component={BrandCreatePage} />
          <PrivateRoute exact path='/backoffice/catalog/brands/edit/:id' component={BrandEditPage} />
          <PrivateRoute exact path='/backoffice/catalog/categories' component={CategoryListPage} />
          <PrivateRoute exact path='/backoffice/catalog/products' component={ProductListPage} />
          <PrivateRoute exact path='/backoffice/catalog/products/create' component={ProductCreatePage} />
          <PrivateRoute exact path='/backoffice/catalog/products/edit/:id' component={ProductEditPage} />
          <PrivateRoute exact path='/backoffice/catalog/mix-products' component={MixProductListPage} />
          <PrivateRoute exact path='/backoffice/catalog/mix-category' component={MixCategoryListPage} />
          <PrivateRoute exact path='/backoffice/catalog/mix-category/:id' component={MixCategorySubList} />
          <PrivateRoute exact path='/backoffice/catalog/mix-brand' component={MixBrandListPage} />
          <PrivateRoute exact path='/backoffice/catalog/mix-brand/:id' component={MixBrandSubList} />
          <PrivateRoute exact path='/backoffice/catalog/mix-products/:id' component={MixProductSub} />
          <PrivateRoute exact path='/backoffice/history/removal' component={HistoryRemoval} />
          <PrivateRoute exact path='/backoffice/history/script' component={HistoryScript} />
          <PrivateRoute exact path='/backoffice/catalog/mix-products-create/create' component={MixProductCreatePage} />
          <PrivateRoute exact path='/backoffice/store/regionals' component={RegionalListPage} />
          <PrivateRoute exact path='/backoffice/store/regionals/edit/:id' component={RegionalEditPage} />
          <PrivateRoute exact path='/backoffice/store/regionals/create' component={RegionalCreatePage} />
          <PrivateRoute exact path='/backoffice/store/networks' component={NetworkListPage} />
          <PrivateRoute exact path='/backoffice/store/networks/create' component={NetworkCreatePage} />
          <PrivateRoute exact path='/backoffice/store/networks/edit/:id' component={NetworkEditPage} />
          <PrivateRoute exact path='/backoffice/store/flags' component={FlagListPage} />
          <PrivateRoute exact path='/backoffice/store/flags/create' component={FlagCreatePage} />
          <PrivateRoute exact path='/backoffice/store/flags/edit/:id' component={FlagEditPage} />
          <PrivateRoute exact path='/backoffice/store/pdvs' component={PDVListPage} />
          <PrivateRoute exact path='/backoffice/store/pdvs/create' component={PDVCreatePage} />
          <PrivateRoute exact path='/backoffice/store/pdvs/edit/:id' component={PDVEditPage} />
          <PrivateRoute exact path='/backoffice/survey/forms' component={FormListPage} />
          <PrivateRoute exact path='/backoffice/survey/scheduling_task_create' component={SchedulingTaskCreate} />
          <PrivateRoute exact path='/backoffice/survey/scheduling_task' component={SchedulingTask} />
          <PrivateRoute exact path='/backoffice/survey/visits' component={VisitsPage} />
          <PrivateRoute exact path='/backoffice/survey/visitsDetails/:userId/:visitId' component={VisitsDetail} />
          <PrivateRoute exact path='/backoffice/register/justification' component={JustificationListPage} />
          <PrivateRoute exact path='/backoffice/register/removal' component={RemovalListPage} />
          <PrivateRoute exact path='/backoffice/register/justification/create' component={JustificationCreatePage} />
          <PrivateRoute exact path='/backoffice/register/removal/create' component={RemovalCreatePage} />
          <PrivateRoute exact path='/backoffice/register/removal/edit/:id' component={RemovalCreatePage} />
          <PrivateRoute exact path='/backoffice/register/justification/edit/:id' component={JustificationEditPage} />
          <PrivateRoute exact path='/backoffice/reports/search' component={SearchListPage} />
          <PrivateRoute exact path='/backoffice/reports/justifyVisits' component={VisitsJustify} />
          <PrivateRoute exact path='/backoffice/reports/editVisits' component={VisitsJustifyHistory} />
          <PrivateRoute
            exact
            path='/backoffice/reports/survey/:form_id/:user_id/:visit_id'
            component={SearchViewPage}
          />
          <PrivateRoute exact path='/backoffice/team/profiles/create' component={ProfileCreatePage} />
          <PrivateRoute exact path='/backoffice/survey/tasks' component={Tasks} />
          <PrivateRoute exact path='/backoffice/monitory/pictureLastAndAfter' component={AfterAndLast} />
          <PrivateRoute exact path='/backoffice/reports/pictures' component={Pictures} />
          <PrivateRoute exact path='/backoffice/register/training' component={Training} />
          <PrivateRoute exact path='/backoffice/register/training/file/:id' component={TrainingFile} />
        </LayoutDefault>
      </Switch>
    </ScrollToTop>
  </Router>
)

export default Routes
