import styled from 'styled-components'

export const ContainerTable = styled.div<any>`
  width: 100%;
  z-index: 99999999999999999999;

  &&& .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th {
    border-color: #b46aff !important;
  }

  &&& .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table {
    border-color: #b46aff !important;
  }

  &&& .ant-table.ant-table-bordered > .ant-table-container {
    border-color: #b46aff !important;
  }

  &&& .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > th,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > td {
    border-color: #b46aff !important;
    padding: 0 10px !important;
    border-bottom: 1px solid #b46aff !important;
    border-right: 1px solid #b46aff !important;
  }

  &&& .ant-table-cell-scrollbar {
    border-left: 1px solid #b46aff !important;
  }

  &&& .ant-table-header {
    border-top: 1px solid #b46aff !important;
  }

  &&& .ant-table-thead > tr > th {
    color: #637282;
    font-size: 15px;
    font-weight: bold;
  }

  &&& .ant-table-cell {
    font-size: 15px;
  }

  &&& .ant-table table {
    width: 100%;
  }

  &&& .ant-table-cell {
    padding: 0 !important;
    height: 50px;
  }

  &&& .ant-table-content {
    overflow: auto ${({ fullY }) => (fullY ? 'auto' : 'hidden')} !important;
    ::-webkit-scrollbar-thumb {
      background-color: #b56aff;
      border: 1px solid #b56aff;
      border-radius: 5px;
      height: 2px;
      width: 2px;
    }

    ::-webkit-scrollbar {
      width: ${({ fullY }) => (fullY ? '10px' : '0')};
      height: 7px;
    }
  }

  &&& .ant-table-body {
    overflow: auto ${({ fullY }) => (fullY ? 'auto' : 'hidden')} !important;

    ::-webkit-scrollbar-thumb {
      background-color: #b56aff;
      border: 1px solid #b56aff;
      border-radius: 5px;
      height: 2px;
      width: 2px;
    }

    ::-webkit-scrollbar {
      width: ${({ fullY }) => (fullY ? '10px' : '0')};
      height: 7px;
    }
  }

  &&& .ant-table-row:hover > .ant-table-cell {
    background: #e0befa !important;
  }

  &&& .ant-table-header {
    box-shadow: 0px 8px 18px -12px #b56aff !important;
  }

  &&& .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th {
    box-shadow: 0px 3px 10px #b56aff !important;
  }
`

export const ContainerPorcent = styled.div<any>`
  width: calc(100% + 20px);
  position: relative;
  left: -10px;
  height: 100%;
  color: #000;
  background: linear-gradient(
    90deg,
    ${({ color }) => color} ${({ percent }) => (percent > 100 ? 100 : percent)}%,
    rgba(243, 243, 251, 1) ${({ percent }) => (percent > 100 ? 100 : percent)}%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`
