import { useState } from 'react'
import { ContainerType, Title, Description, CardCreateUpload, Button, ButtonLink, BtnExport } from './../style'
import { CloudUploadOutlined, CloudDownloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import ImportDoc from '../../../assets/images/importDoc.png'
import { Checkbox, Dropdown, Menu, message, Modal } from 'antd'
import api_v2 from '../../../services/api-v2'
import { Loading } from '../../Loading'
import { Mixpanel } from '../../../utils/mixpanel'
import * as XLSX from 'xlsx'
import { addIsScriptIsPermited } from '../../../utils/addScript'
import moment from 'moment'

interface IProps {
  close: (boolean: any) => void
  title: string
  path: string
  config?: any
  children?: any
}

export const ImportXls = ({ close, title, path, config, children }: IProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [checked, setChecked] = useState<boolean>(true)

  let getSharement = sessionStorage.getItem('@rocketpdv:workspace')
  let newGetWorkspace: any = {}
  if (getSharement) newGetWorkspace = JSON.parse(getSharement)

  const sendFile = async (file: any) => {
    setLoading(true)
    if (file) {
      const archive: any = new FormData()
      archive.append('file', file.file)

      if (config) {
        archive.append('config', JSON.stringify(config))
      }

      if (path === 'MIX_FORM' && config !== undefined) {
        await api_v2.put(`/form/mix/reset/${config.form_id}`, { delete_tasks: checked })
      }

      try {
        await api_v2.post(`/import-xlsx/${path}`, archive, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        Mixpanel.track('Importando arquivo', {
          params: `path: ${path}, config: ${config}`,
        })

        Modal.success({
          title: 'Arquivo importado com sucesso!',
          content: 'Seu arquivo será processado, e ao finalizar você receberá um e-mail.',
          onOk() {
            Mixpanel.track(`Importou arquivo ${path}`, {})
            close(false)
          },
        })
      } catch (error) {
        Modal.error({
          title: 'Ops!',
          content: 'Ocorreu um erro ao enviar arquivo, tente novamente mais tarde!',
          onOk() {
            close(false)
          },
        })
      }
    }

    setLoading(false)
  }

  const checkFile = (list: any) => {
    let isWrong = false

    if (list && list.length > 0) {
      switch (path) {
        case 'PROMOTER':
          if (
            list[1][0] !== 'Id' ||
            list[1][1] !== 'Nome*' ||
            list[1][2] !== 'CPF*' ||
            list[1][3] !== 'Senha*' ||
            list[1][4] !== 'Perfil' ||
            list[1][5] !== 'Celular' ||
            list[1][6] !== 'E-mail' ||
            list[1][7] !== 'CEP' ||
            list[1][8] !== 'Rua' ||
            list[1][9] !== 'Bairro' ||
            list[1][10] !== 'Cidade' ||
            list[1][11] !== 'UF (Sigla)*' ||
            list[1][12] !== 'Regionais'
          ) {
            isWrong = true
          }
          break
        case 'COLLABORATOR':
          if (
            list[1][0] !== 'Id' ||
            list[1][1] !== 'Nome*' ||
            list[1][2] !== 'Senha acesso*' ||
            list[1][3] !== 'Nome Social' ||
            list[1][4] !== 'Gênero' ||
            list[1][5] !== 'Data de Nascimento' ||
            list[1][6] !== 'Nome da Mãe' ||
            list[1][7] !== 'Nome do Pai' ||
            list[1][8] !== 'Nacionalidade' ||
            list[1][9] !== 'Naturalidade' ||
            list[1][10] !== 'Cor/Raça' ||
            list[1][11] !== 'PCD' ||
            list[1][12] !== 'RG' ||
            list[1][13] !== 'CPF*' ||
            list[1][14] !== 'CTPS - Número' ||
            list[1][15] !== 'CTPS - Série' ||
            list[1][16] !== 'PIS' ||
            list[1][17] !== 'CNPJ/MEI (QUANDO PJS)' ||
            list[1][18] !== 'Data de Admissão' ||
            list[1][19] !== 'Data do Desligamento' ||
            list[1][20] !== 'Ativo' ||
            list[1][21] !== 'Perfil (Equipe)' ||
            list[1][22] !== 'Perfil do Colaborador' ||
            list[1][23] !== 'Marca Atendida' ||
            list[1][24] !== 'Centro de custo' ||
            list[1][25] !== 'Matrícula' ||
            list[1][26] !== 'Temporário' ||
            list[1][27] !== 'Vinculo' ||
            list[1][28] !== 'Cargo' ||
            list[1][29] !== 'Setor' ||
            list[1][30] !== 'Colaborador Superior' ||
            list[1][31] !== 'Jornada de Trabalho' ||
            list[1][32] !== 'Equipe de Campo' ||
            list[1][33] !== 'Móvel' ||
            list[1][34] !== 'Compartilhado' ||
            list[1][35] !== 'Tam. Camisa' ||
            list[1][36] !== 'Tam. Calça' ||
            list[1][37] !== 'Tam. Jaqueta' ||
            list[1][38] !== 'Tam. Calçado' ||
            list[1][39] !== 'Recebeu Notebook' ||
            list[1][40] !== 'Recebeu Celular Corporativo' ||
            list[1][41] !== 'Recebeu Tablet' ||
            list[1][42] !== 'Salário' ||
            list[1][43] !== 'Banco' ||
            list[1][44] !== 'Tipo de Conta' ||
            list[1][45] !== 'Agência' ||
            list[1][46] !== 'Conta' ||
            list[1][47] !== 'Digito' ||
            list[1][48] !== 'Tipo Chave PIX' ||
            list[1][49] !== 'Chave PIX' ||
            list[1][50] !== 'Celular' ||
            list[1][51] !== 'Celular Corporativo' ||
            list[1][52] !== 'Telefone' ||
            list[1][53] !== 'Telefone Corporativo' ||
            list[1][54] !== 'E-mail' ||
            list[1][55] !== 'E-mail Corporativo' ||
            list[1][56] !== 'Nome do Contato de Emergência' ||
            list[1][57] !== 'Contato de emergência' ||
            list[1][58] !== 'Vinculo contato de emergência' ||
            list[1][59] !== 'Plano de Saúde' ||
            list[1][60] !== 'Plano Odontologico' ||
            list[1][61] !== 'Seguro de Vida' ||
            list[1][62] !== 'Tipo de Transporte' ||
            list[1][63] !== 'Vale Alimentação' ||
            list[1][64] !== 'Vale Refeição' ||
            list[1][65] !== 'Vale Cultura' ||
            list[1][66] !== 'Vale Presente' ||
            list[1][67] !== 'Vale Despesas' ||
            list[1][68] !== 'Vale Educação' ||
            list[1][69] !== 'Vale Saúde' ||
            list[1][70] !== 'Cesta Básica' ||
            list[1][71] !== 'Insalubridade' ||
            list[1][72] !== 'Periculosidade' ||
            list[1][73] !== 'Manutenção Veicular' ||
            list[1][74] !== 'Premiação' ||
            list[1][75] !== 'Ajuda de Custo' ||
            list[1][76] !== 'Auxilio Home Office' ||
            list[1][77] !== 'Pensão Alimentícia' ||
            list[1][78] !== 'CEP' ||
            list[1][79] !== 'Endereço' ||
            list[1][80] !== 'Número' ||
            list[1][81] !== 'Complemento' ||
            list[1][82] !== 'Bairro' ||
            list[1][83] !== 'Cidade' ||
            list[1][84] !== 'UF (Sigla)' ||
            list[1][85] !== 'Regional' ||
            list[1][86] !== 'Região'
          ) {
            isWrong = true
          }
          break

        case 'PRODUCT':
          if (
            list[1][0] !== 'ID (Esse campo é obrigatorio caso queira editar o registro)' ||
            list[1][1] !== 'Nome*' ||
            list[1][2] !== 'Categoria*' ||
            list[1][3] !== 'Marca*' ||
            list[1][4] !== 'Valor máximo' ||
            list[1][5] !== 'Valor mínimo' ||
            list[1][6] !== 'Tipo (Próprio ou Concorrente)*' ||
            list[1][7] !== 'Código Interno' ||
            list[1][8] !== 'EAN13'
          ) {
            isWrong = true
          }
          break

        case 'PDV':
          if (
            list[0][0] !== 'ID (Esse campo é obrigatorio caso queira editar o registro)' ||
            list[0][1] !== 'Nome' ||
            list[0][2] !== 'Nome fantasia' ||
            list[0][3] !== 'CNPJ' ||
            list[0][4] !== 'Código do PDV' ||
            list[0][5] !== 'Tipo PDV' ||
            list[0][6] !== 'Perfil do PDV' ||
            list[0][7] !== 'Canal PDV' ||
            list[0][8] !== 'Telefone' ||
            list[0][9] !== 'Gerente' ||
            list[0][10] !== 'Regional' ||
            list[0][11] !== 'Rede' ||
            list[0][12] !== 'Bandeira' ||
            list[0][13] !== 'CEP' ||
            list[0][14] !== 'Endereço' ||
            list[0][15] !== 'Número' ||
            list[0][16] !== 'Bairro' ||
            list[0][17] !== 'Cidade' ||
            list[0][18] !== 'Estado (UF)' ||
            list[0][19] !== 'Complemento' ||
            list[0][20] !== 'Ativo' ||
            list[0][21] !== 'Latitude' ||
            list[0][22] !== 'Longitude' ||
            list[0][23] !== 'Região' ||
            list[0][24] !== 'Quantidade de Checkouts'
          ) {
            isWrong = true
          }
          break

        case 'MIX_FORM':
          if (
            list[0][0] !== 'Mix por (Rede, Bandeira, PDV)' ||
            list[0][1] !== 'Referencia (Rocket ID, Nome, Código Interno)'
          ) {
            isWrong = true
          }
          break

        case 'SCRIPT':
          if (
            list[1][0] !== 'Colaborador' ||
            list[1][1] !== 'Perfil (Equipe)' ||
            list[1][2] !== 'Colaborador Superior' ||
            list[1][3] !== 'Setor' ||
            list[1][4] !== 'Ativo' ||
            list[1][5] !== 'Rede' ||
            list[1][6] !== 'Bandeira' ||
            list[1][7] !== 'Nome do PDV' ||
            list[1][8] !== 'CNPJ do PDV' ||
            list[1][9] !== 'Código do PDV' ||
            list[1][10] !== 'Tipo PDV' ||
            list[1][11] !== 'Perfil do PDV' ||
            list[1][12] !== 'Canal PDV' ||
            list[1][13] !== 'CEP' ||
            list[1][14] !== 'Endereço' ||
            list[1][15] !== 'Número' ||
            list[1][16] !== 'Complemento' ||
            list[1][17] !== 'Bairro' ||
            list[1][18] !== 'Cidade' ||
            list[1][19] !== 'UF (Sigla)' ||
            list[1][20] !== 'Regional' ||
            list[1][21] !== 'Região' ||
            list[1][22] !== 'Período' ||
            list[1][23] !== 'Semana' ||
            list[1][24] !== 'Segunda-Feira' ||
            list[1][25] !== 'Terça-Feira' ||
            list[1][26] !== 'Quarta-Feira' ||
            list[1][27] !== 'Quinta-Feira' ||
            list[1][28] !== 'Sexta-Feira' ||
            list[1][29] !== 'Sábado' ||
            list[1][30] !== 'Domingo'
          ) {
            isWrong = true
          }

          break

        case 'VISIT':
          if (
            list[1][0] !== 'Nome*' ||
            list[1][1] !== 'CPF*' ||
            list[1][2] !== 'UF' ||
            list[1][3] !== 'Rocket ID (PDV)' ||
            list[1][4] !== 'Código*' ||
            list[1][5] !== 'Rede' ||
            list[1][6] !== 'Bandeira' ||
            list[1][7] !== 'PDV*' ||
            list[1][8] !== 'UF' ||
            list[1][9] !== 'Endereço' ||
            list[1][10] !== 'Bairro' ||
            list[1][11] !== 'Cidade'
          ) {
            isWrong = true
          }
          break

        case 'EDIT_VISIT':
          if (
            list[1][0] !== 'Colaborador' ||
            list[1][27] !== 'Status da Visita' 
          ) {
            isWrong = true
          }
          break
        case 'REMOVAL':
          if (
            list[1][0] !== 'Colaborador*' ||
            list[1][1] !== 'Perfil (Equipe)' ||
            list[1][2] !== 'Colaborador Superior' ||
            list[1][3] !== 'Ativo' ||
            list[1][4] !== 'Motivo (Conforme Justificativa)*' ||
            list[1][5] !== 'Data de Início*' ||
            list[1][6] !== 'Data de Término*' ||
            list[1][7] !== 'Colaborador Substituto' ||
            list[1][8] !== 'Assumir o Roteiro' ||
            list[1][9] !== 'Observação' ||
            list[1][10] !== 'Data de Alteração'
          ) {
            isWrong = true
          }
          break

        default:
          break
      }
    }
    return isWrong
  }

  // const menuExport = (
  //   <Menu>
  //     <Menu.Item>
  //       <BtnExport
  //         href={
  //           'https://firebasestorage.googleapis.com/v0/b/rocketpdv-dev.appspot.com/o/rock-at-config%2Fmodelos%2FMODELO_ROTEIRO.xlsx?alt=media&token=8d790bb5-8a95-4572-bb3b-96c9d6e7426c'
  //         }
  //         target='_blank'
  //       >
  //         Template 1
  //       </BtnExport>
  //     </Menu.Item>
  //     <Menu.Item>
  //       <BtnExport
  //         href={
  //           'https://firebasestorage.googleapis.com/v0/b/rocketpdv-dev.appspot.com/o/rock-at-config%2Fmodelos%2FMODELO_ROTEIRO_TEMPLATE_2.xlsx?alt=media&token=e4ab5618-7f40-4fd4-974d-1fc41887b923'
  //         }
  //         target='_blank'
  //       >
  //         {/* Modelo compartilhado */}
  //         Template 2
  //       </BtnExport>
  //     </Menu.Item>
  //   </Menu>
  // )

  const menuExportPromoter = (
    <Menu>
      <Menu.Item>
        <BtnExport
          href={
            'https://firebasestorage.googleapis.com/v0/b/rocketpdv-dev.appspot.com/o/rock-at-config%2Fmodelos%2FMODELO_PROMOTOR.xlsx?alt=media&token=c24bf03f-1d76-4687-9e3e-d71a0fe5f8ea'
          }
          target='_blank'
        >
          Simples
        </BtnExport>
      </Menu.Item>
      {/* <Menu.Item>
        <BtnExport
          href={
            'https://firebasestorage.googleapis.com/v0/b/rocketpdv-dev.appspot.com/o/rock-at-config%2Fmodelos%2FMODELO_PROMOTOR_AVAN%C3%87ADO.xlsx?alt=media&token=4602e369-a5c1-4e79-ba01-1d73779913b4'
          }
          target='_blank'
        >
          Avançado
        </BtnExport>
      </Menu.Item> */}
    </Menu>
  )
  const handleFileUpload = (file) => {
    setLoading(true);
    const f = file.file;
    const reader = new FileReader();

    let isWrong = false;

    reader.onload = function (e) {
      if (path === 'SCRIPT' && !addIsScriptIsPermited()) {
        message.error('Você não tem autorização para mexer no roteiro.');
        setLoading(false);
        return
      }

      const data = e.target.result;
      const readedData = XLSX.read(data, { type: 'binary' });
      // const wsname = readedData.SheetNames[0];
      // const ws = readedData.Sheets[wsname];
      const worksheet = readedData.Sheets[readedData.SheetNames[0]];

      const dataMatrix = [];

      // Intervalo de células assumido: A1:Z2
      const startCell = { c: 0, r: 0 };
      const endCell = { c: 86, r: 1 };

      for (let R = startCell.r; R <= endCell.r; ++R) {
        const row = [];
        for (let C = startCell.c; C <= endCell.c; ++C) {
          const cellRef = XLSX.utils.encode_cell({ c: C, r: R });
          const cell = worksheet[cellRef];
          if (cell && cell.v !== undefined) {
            row.push(cell.v);
          } else {
            row.push('');
          }
        }
        dataMatrix.push(row);
      }


      // const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      isWrong = checkFile(dataMatrix);
      if (isWrong) {
        message.error('Esse arquivo não é o modelo de importação correto.');
        setLoading(false);
        return;
      }
      sendFile(file);
      setLoading(false);
    };

    reader.readAsBinaryString(f);
  };


  return (
    <>
      <ContainerType>
        {loading && <Loading />}
        <Title>{title}</Title>
        <Description>Importe sua planilha automaticamente.</Description>
      </ContainerType>
      <CardCreateUpload
        customRequest={async (file) => {
          if(config?.schedule && !config?.date) {
            message.error('Para agendar o roteiro tem que selecionar a data.');

            return
          }

          if (config?.is_reset_script == true) {
            Modal.confirm({
              title: 'Resetar roteiro',
              icon: <ExclamationCircleOutlined />,
              content: 'Você tem certeza que deseja subir um roteiro novo? isso apagará o roteiro de todos os promotores na planilha.',
              onOk() {
                handleFileUpload(file);
              },
              onCancel() {
                close(true);
              },
            });
          } else {
            console.log('aqui')
            handleFileUpload(file);
          }
        }}
      >
        <img src={ImportDoc} alt='user' />
        <h4>Importar XLSX</h4>
        <p>Importe planilha.</p>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Button style={{ backgroundColor: '#b56aff', marginTop: 6 }}>
            <CloudUploadOutlined />
            Importar
          </Button>

        </div>
      </CardCreateUpload>
      {path === 'PDV' && (
        <ButtonLink>
          <CloudDownloadOutlined />
          <BtnExport
            href={
              'https://firebasestorage.googleapis.com/v0/b/rocketpdv-dev.appspot.com/o/rock-at-config%2Fmodelos%2FMODELO_PDV_2.xlsx?alt=media&token=1d391bd3-2d8f-4bb3-8214-38e2f196b4b4'
            }
            target='_blank'
          >
            Baixe o modelo
          </BtnExport>
        </ButtonLink>
      )}
      {path === 'REMOVAL' && (
        <ButtonLink>
          <CloudDownloadOutlined />
          <BtnExport
            href={
              'https://firebasestorage.googleapis.com/v0/b/rocketpdv-dev.appspot.com/o/rock-at-config%2Fmodelos%2FMODELO_AFASTAMENTO.xlsx?alt=media&token=7e845959-4084-47ba-99a3-09f4161874b8'
            }
            target='_blank'
          >
            Baixe o modelo
          </BtnExport>
        </ButtonLink>
      )}
      {path === 'MIX_FORM' && (
        <>
          <Checkbox
            onChange={(e: any) => {
              setChecked(e.target.checked)
            }}
            defaultChecked={true}
            value={checked}
          >
            Deseja apagar as tarefas já lançadas nesse mix?
          </Checkbox>
          <ButtonLink>
            <CloudDownloadOutlined />
            <BtnExport
              href={
                'https://firebasestorage.googleapis.com/v0/b/rocketpdv-dev.appspot.com/o/rock-at-config%2Fmodelos%2FMODELO_MIX_FORMULARIO.xlsx?alt=media&token=d6a8753a-d62c-44e9-ba28-3c793144efc5'
              }
              target='_blank'
            >
              Baixe o modelo
            </BtnExport>
          </ButtonLink>
        </>
      )}

      {path === 'PRODUCT' && (
        <ButtonLink>
          <CloudDownloadOutlined />
          <BtnExport
            href={
              newGetWorkspace && !newGetWorkspace.shared_environment
                ? 'https://firebasestorage.googleapis.com/v0/b/rocketpdv-dev.appspot.com/o/rock-at-config%2Fmodelos%2FMODELO_PRODUTO2.xlsx?alt=media&token=dea47bf4-dc0e-4f0a-8f72-7bafcc608590&_gl=1*1gk8jc9*_ga*MTMxMDE2NTQyNy4xNjk2MjcxNzQy*_ga_CW55HF8NVT*MTY5NzIxMzI1Mi4zLjEuMTY5NzIxMzMzNy4zNi4wLjA.'
                : 'https://firebasestorage.googleapis.com/v0/b/rocketpdv-dev.appspot.com/o/rock-at-config%2Fmodelos%2FMODELO_PRODUTO_COMPARTILHADO2.xlsx?alt=media&token=eedf6c48-7cb3-4a7a-8d68-9586703840e1&_gl=1*qmf6r0*_ga*MTMxMDE2NTQyNy4xNjk2MjcxNzQy*_ga_CW55HF8NVT*MTY5NzIxMzI1Mi4zLjEuMTY5NzIxMzM5Mi40Mi4wLjA.'
            }
            target='_blank'
          >
            Baixe o modelo
          </BtnExport>
        </ButtonLink>
      )}
      {path === 'SCRIPT' && (
        <ButtonLink>
          <CloudDownloadOutlined />
          <BtnExport
            href={
              'https://firebasestorage.googleapis.com/v0/b/rocketpdv-dev.appspot.com/o/rock-at-config%2Fmodelos%2Fmodelo_roteiro.xlsx?alt=media&token=287b9340-d7c4-4de2-a121-71109d489dc8'
            }
            target='_blank'
          >
            Baixe o modelo
          </BtnExport>
        </ButtonLink>
      )}

      {path === 'VISIT' && (
        <ButtonLink>
          <CloudDownloadOutlined />
          <BtnExport
            href={
              'https://firebasestorage.googleapis.com/v0/b/rocketpdv-dev.appspot.com/o/rock-at-config%2Fmodelos%2FMODELO_VISITA_COLUNA.xlsx?alt=media&token=df556452-f4ff-40f2-b387-6a8eae76c1ec'
            }
            target='_blank'
          >
            Baixe o modelo
          </BtnExport>
        </ButtonLink>
      )}
      {path === 'BRAND' && (
        <ButtonLink>
          <CloudDownloadOutlined />
          <BtnExport
            href={
              'https://firebasestorage.googleapis.com/v0/b/rocketpdv-dev.appspot.com/o/rock-at-config%2Fmodelos%2FMODELO_MARCA.xlsx?alt=media&token=a268133e-224e-4333-8f99-409edaa2d76f'
            }
            target='_blank'
          >
            Baixe o modelo
          </BtnExport>
        </ButtonLink>
      )}
      {path === 'REGIONAL' && (
        <ButtonLink>
          <CloudDownloadOutlined />
          <BtnExport
            href={
              'https://firebasestorage.googleapis.com/v0/b/rocketpdv-dev.appspot.com/o/rock-at-config%2Fmodelos%2FMODELO%20REGIONAL.xlsx?alt=media&token=b9f5f787-e148-47f1-bd8d-11929a250779'
            }
            target='_blank'
          >
            Baixe o modelo
          </BtnExport>
        </ButtonLink>
      )}
      {path === 'NETWORK' && (
        <ButtonLink>
          <CloudDownloadOutlined />
          <BtnExport
            href={
              'https://firebasestorage.googleapis.com/v0/b/rocketpdv-dev.appspot.com/o/rock-at-config%2Fmodelos%2FMODELO%20REDE.xlsx?alt=media&token=8e57e52e-687b-4b22-89f3-578e9efbe3c2'
            }
            target='_blank'
          >
            Baixe o modelo
          </BtnExport>
        </ButtonLink>
      )}
      {path === 'FLAG' && (
        <ButtonLink>
          <CloudDownloadOutlined />
          <BtnExport
            href={
              'https://firebasestorage.googleapis.com/v0/b/rocketpdv-dev.appspot.com/o/rock-at-config%2Fmodelos%2FMODELO%20BANDEIRA.xlsx?alt=media&token=dccdd539-b6b4-4314-816e-9fcf8fcc2704'
            }
            target='_blank'
          >
            Baixe o modelo
          </BtnExport>
        </ButtonLink>
      )}
      {path === 'PROMOTER' && (
        <Dropdown overlay={menuExportPromoter}>
          <ButtonLink>
            <CloudDownloadOutlined />
            Baixe o modelo
          </ButtonLink>
        </Dropdown>
      )}
      {/* {path === 'COLLABORATOR' && (
        <Dropdown overlay={menuExportPromoter}>
          <ButtonLink>
            <CloudDownloadOutlined />
            Baixe o modelo
          </ButtonLink>
        </Dropdown>
      )} */}
      {children && children}
    </>
  )
}
