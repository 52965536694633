import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { Breadcrumb, Button, Checkbox, Col, message, Row } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import Input from '../../../components/form/SimpleInput'
import { Container } from '../../../GlobalStyles'
import history from '../../../routes/history'
import { Loading } from '../../../components/Loading'
import { Mixpanel } from '../../../utils/mixpanel'
import api_v2 from '../../../services/api-v2'

const JustificationEditPage: React.FC = () => {
  const formRef = useRef<FormHandles>(null)
  const [mobile, setMobile] = useState<boolean>()
  const [observation, setObservation] = useState<boolean>()
  const [loading, setLoading] = useState<boolean>(false)
  const [justification, setJustification] = useState<any>()
  const [picture, setPicture] = useState<boolean>()
  const { id }: any = useParams()

  async function loadInitialData(id: string | undefined) {
    setLoading(true)
    if (id) {
      const { data } = await api_v2.get(`faults/${id}`)
      formRef.current!.setData({ ...data.faults_options })
      setMobile(data.faults_options.mobile_active)
      setPicture(data.faults_options.mandatory_picture)
      setObservation(data.faults_options.mandatory_observation)

      setJustification(data.faults_options)
    }
    setLoading(false)
  }

  useEffect(() => {
    loadInitialData(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  async function handleSubmit(body: any) {
    setLoading(true)
    try {
      await api_v2.put(`/faults/${id}`, {
        description: body.description,
        mandatory_observation: observation,
        mandatory_picture: picture,
        mobile_active: mobile,
      })
      Mixpanel.track('Editou justificativa de falta', {
        params: {
          description: body.description,
          mandatory_observation: observation,
          mandatory_picture: picture,
          mobile_active: mobile,
          id: id,
        },
      })
      message.success('justificativa atualizada com sucesso')
      history.push('/backoffice/register/justification')
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((el: any) => {
          formRef.current!.setFieldError(el.path, el.message)
        })
      } else {
        message.error('Já existe um registro com essas informações!')
      }
    }
    setLoading(false)
  }

  return (
    <Container>
      {loading ? <Loading /> : null}
      <Row>
        <Col span={12}>
          <h1>Atualização de justificativa de falta </h1>
          <p>Insira os dados para realizar a atualização</p>
          <Breadcrumb separator='>' className='breadcumb'>
            <Link to='/backoffice/register/justification'>
              <Breadcrumb.Item>Listagem de justificativa de faltas</Breadcrumb.Item>
            </Link>
            <Breadcrumb.Item>atualização de justificativa de falta</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Form className='form' initialData={justification} ref={formRef} onSubmit={handleSubmit}>
        <Row gutter={8}>
          <Col span={8}>
            <Input type='text' label='Justificativa de falta *' name='description' />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Checkbox
              name='mobile_active'
              checked={mobile}
              defaultChecked={mobile}
              onChange={(event: any) => {
                setMobile(event.target.checked)
              }}
            >
              Ativo Mobile?
            </Checkbox>
          </Col>
          <Col span={8}>
            <Checkbox
              checked={picture}
              defaultChecked={false}
              name='mandatory_picture'
              onChange={(event: any) => {
                setPicture(event.target.checked)
              }}
            >
              Foto obrigatória?
            </Checkbox>
          </Col>
          <Col span={8}>
            <Checkbox
              checked={observation}
              defaultChecked={false}
              name='mandatory_observation'
              onChange={(event: any) => {
                setObservation(event.target.checked)
              }}
            >
              Observação obrigatória?
            </Checkbox>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button type='primary' htmlType='submit'>
              Cadastrar
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  )
}

export default JustificationEditPage
