import React, { useEffect, useState } from 'react'
import { Col, Drawer, message, Row } from 'antd'
import api_v2 from '../../services/api-v2'
import { PlusOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { ButtonSearch } from '../../pages/Team/Promoter/style'
import { CardTitle } from './style'
import { INIT_GLOBAL_RESPONSE } from '../../utils/global-response'
import { getStatusProduct } from '../DefaultPage'
import { ModalSchedule } from '../ModalSchedule'
import { ContextUpload } from '../../context/contextUpload'
import { hasIndex } from '../Menu/menuHorizonte'
import confirm from 'antd/lib/modal/confirm'

interface IProps {
  visible: boolean
  close: (boolean: any) => void
  id: number
  context: string
}

export const DrawerScheduleTask = ({ visible, close, id, context }: IProps) => {
  const { permissions } = ContextUpload()
  const [loading, setLoading] = useState(false)
  const [schedules, setSchedules] = useState(INIT_GLOBAL_RESPONSE)
  const [openModal, setOpenModal] = useState(false)
  const [defaultValue, setDefaultValue] = useState<any>(undefined)

  const getSchedule = async () => {
    try {
      setLoading(!loading)
      const { data } = await api_v2.get(`/task/schedule?form_id=${id}&page=1&perpage=100`)
      setSchedules(data)
      setLoading(!loading)
    } catch (error) {
      setLoading(!loading)
      console.log(error)
    }
  }

  useEffect(() => {
    getSchedule()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const handleDelete = (task: any) => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: `Tem certeza que gostaria de excluir o agendamento: ${task.name}.`,
      onOk() {
        taskDelete(task)
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  const taskDelete = async (task: any) => {
    try {
      await api_v2.delete(`/task/schedule/${task.id}`)
      message.success('Sucesso ao apagar agendamento.')
      getSchedule()
    } catch (error) {
      message.error('Erro ao apagar agendamento.')
    }
  }

  return (
    <Drawer
      title='Agendamento de tarefa'
      placement='right'
      onClose={close}
      visible={visible}
      destroyOnClose={true}
      width={410}
      footer={
        hasIndex(['form.add_schedule_form'], permissions) > -1 ? (
          <ButtonSearch style={{ width: '100%' }} type='button' onClick={() => setOpenModal(true)}>
            <PlusOutlined />
            Novo agendamento
          </ButtonSearch>
        ) : undefined
      }
    >
      {schedules.result.length > 0 &&
        schedules.result.map((el: any) => (
          <CardTitle key={id}>
            <div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: '15px', marginRight: 10 }}>{getStatusProduct(el.is_active)}</div>
                  {el.name}
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  {hasIndex(['form.change_schedule_form'], permissions) > -1 && (
                    <DeleteOutlined
                      style={{ fontSize: 20, cursor: 'pointer', marginRight: 20 }}
                      onClick={() => handleDelete(el)}
                    />
                  )}
                  {hasIndex(['form.change_schedule_form'], permissions) > -1 && (
                    <EditOutlined
                      style={{ fontSize: 20, cursor: 'pointer' }}
                      onClick={() => {
                        setDefaultValue(el)
                        setOpenModal(true)
                      }}
                    />
                  )}
                </div>
              </div>
              <Row gutter={[8, 8]} style={{ marginTop: 10, fontSize: 12 }}>
                <Col span={12}>Data de início: {el.start_date}</Col>
                <Col span={12}>Data de término: {el.end_date ? el.end_date : '-'}</Col>
              </Row>
            </div>
          </CardTitle>
        ))}

      <ModalSchedule
        reloadApi={() => getSchedule()}
        form_id={id}
        visible={openModal}
        context={context}
        close={(bool: any) => {
          setDefaultValue(undefined)
          setOpenModal(false)
        }}
        defaultValue={defaultValue}
      />
    </Drawer>
  )
}
