import styled from 'styled-components'

export const Container = styled.div`
  flex-direction: column;
  flex: 1;
  padding: 20px;
`

export const CreateForm = styled.div`
  input {
    border: 1px solid #c9d3dd;
    padding: 5px 10px;
    box-sizing: content-box;
  }
`

export const SearchWorks = styled.div`
  color: #545454;
  display: flex;

  svg {
    font-size: 20px;
    margin: 5px 10px;
  }
`

export const Wrapper = styled.header`
  justify-content: center;
  align-items: center;

  h1 {
    color: #000;
  }

  p {
    color: #637282;
    margin-bottom: 30px;
  }
`

export const WorkspaceList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 260px);
  gap: 30px;
  position: relative;
  width: 100%;
  margin-top: 1rem;
  padding-top: 10px;
`

export const WorkspaceOption = styled.div<any>`
  background: ${({disabled}: any) => disabled ?  '#dfdfdf' : '#F8F8F8' };
  border-radius: 10px;
  border: 1px solid #ccc;
  height: 220px;

  width: 100%;
  margin: 5px 10px;
  text-align: center;
  box-shadow: -2px 2px 8px 3px #dddddd;
  cursor: ${({disabled}: any) => disabled ? 'not-allowed' : 'pointer'};;
  transition: 0.1s;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: ${({disabled}: any) => disabled ? 1 : 0.8};

  :hover {
    transition: all 0.2s;
    background: ${({disabled}: any) => disabled ? '#dfdfdf' : '#fff'};
    border: ${({disabled}: any) => disabled ? '1px solid #ccc' : 'none'};
  }
  svg {
    font-size: 35px;
  }
`
export const WorkspaceLogo = styled.img`
  width: 70px;
  height: 100px;
  margin: 0 auto 20px;
`

export const WorkspaceName = styled.h4`
  font-weight: 500;
  font-size: 20px;
  color: #637282;
  text-transform: uppercase;
  letter-spacing: 2px;
`
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ContainerFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 17px;
  position: relative;
  top: 60%;

  img{
    width: 150px;
  }
`