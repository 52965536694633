import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons'
import { Button, Dropdown, Menu, message, Popconfirm, Tag } from 'antd'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import api from '../../../services/api'
import DefaultPage from '../../../components/DefaultPage'
import { ModalCreateProfile } from '../../../components/ModalCreateProfile'
import { Mixpanel } from '../../../utils/mixpanel'

interface Profile {
  id: string
  name: string
  description: string
  is_active: boolean
}
export interface ProfileFilter {
  name?: any
  description?: any
  is_active?: any
}

const ProfileListPage = () => {
  const [reload, setReload] = useState(false)
  const [createProfile, setCreateProfile] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)

  const deleteProfile = async (id: string) => {
    setLoading(true)
    try {
      await api.delete('/profile/' + id)
      message.success('Perfil excluido com sucesso!')
      setReload(true)
    } catch (err) {
      message.error('Esse perfil está vinculado a usuários')
    }
    setLoading(false)
  }

  return (
    <>
      <DefaultPage
        titlePage='Grupos & Permissões'
        breadcrumb={[{ name: 'Home', url: '/' }, { name: 'Grupos & Permissões' }]}
        urlPage='/backoffice/team/profiles'
        routeApi={{
          method: 'get',
          route: 'profile',
          versionApi: 'v2',
          reload,
        }}
        listButtons={{
          buttonCreate: { visible: true, type: 'function', function: () => setCreateProfile(true) },
        }}
        load={{ loading, setLoading }}
        columns={[
          {
            title: 'Nome',
            dataIndex: 'name',
            width: '30%',
            sorter: (a, b) => {
              return a.name.localeCompare(b.name)
            },
          },
          {
            title: 'Descrição',
            dataIndex: 'description',
            width: '50%',
            sorter: (a, b) => {
              return a.description.localeCompare(b.description)
            },
          },
          {
            title: 'Ativo',
            render: (obj: Profile) => (obj.is_active ? <Tag color='green'>Sim</Tag> : <Tag color='red'>Não</Tag>),
          },
          {
            title: '#',
            width: 50,
            render: (obj: any) => {
              return (
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item key='1' icon={<DeleteOutlined />}>
                        <Popconfirm
                          title='Tem certeza que deseja excluir este perfil?'
                          onConfirm={() => {
                            Mixpanel.track('Confirmou excluir perfil', obj)
                            deleteProfile(obj.id)
                          }}
                          okText='Sim'
                          cancelText='Não'
                          placement='topRight'
                        >
                          Deletar perfil
                        </Popconfirm>
                      </Menu.Item>
                      <Menu.Item key='2' icon={<EditOutlined />}>
                        <Link to={`/backoffice/team/profiles/edit/${obj.id}?params=${JSON.stringify(obj)}`}>Editar perfil</Link>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button size='small' shape='circle' icon={<MoreOutlined />} />
                </Dropdown>
              )
            },
          },
        ]}
      />
      <ModalCreateProfile
        visible={createProfile}
        close={() => setCreateProfile(!createProfile)}
        reloadApi={() => setReload(!reload)}
      />
    </>
  )
}

export default ProfileListPage
