import React, { useState, useEffect, useRef } from 'react'
import { Button, ContainerModal, ContainerStep2 } from './style'
import AddUser from '../../assets/images/addUser.png'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { message } from 'antd'
import { Loading } from '../Loading'
import Input from '../form/SimpleInput'
import * as Yup from 'yup'
import { GroupForm } from '../../GlobalStyles'
import api_v2 from '../../services/api-v2'

interface IProps {
  visible: boolean
  close: (boolean: any) => void
}

export const ModalForgatPassword = ({ visible, close }: IProps) => {
  const formRef = useRef<FormHandles>(null)
  const [loading, setLoading] = useState<boolean>(false)

  async function handleSubmit(body: any) {
    setLoading(true)
    try {
      const schema = Yup.object().shape({
        email: Yup.string().required('Email é obrigatório'),
      })
      await schema.validate(body, {
        abortEarly: false,
      })

      const { email } = body

      const { data } = await api_v2.post(`user/resetPassword`, body)
      if (data.statusCode && data.statusCode === 500) {
        setLoading(false)
        message.error(data.err)
        return
      }
      message.success(`Senha enviada para email ${email}`)

      close(false)
    } catch (err) {
      setLoading(false)
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((el: any) => {
          formRef.current!.setFieldError(el.path, el.message)
        })
        message.error('Erro ao resetar senha!')
      }
    }
    setLoading(false)
  }

  const functionThatResetsForm = () => {
    formRef.current!.reset()
  }

  useEffect(() => {
    if (formRef.current != null) functionThatResetsForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  return (
    <ContainerModal
      visible={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
      destroyOnClose={true}
    >
      {loading && <Loading />}

      <Form className='form' ref={formRef} initialData={{ is_active: true }} onSubmit={handleSubmit}>
        <ContainerStep2>
          <h3>Trocar senha</h3>
          <img src={AddUser} alt='user' />

          <GroupForm style={{ width: '100%' }}>
            <Input
              label='Digite seu email:'
              placeholder='digiter seu email'
              type='text'
              name='email'
              style={{ width: '100%' }}
            />
          </GroupForm>

          <Button type='submit' style={{ width: '100%', height: '55px', marginTop: 20 }}>
            Resetar senha
          </Button>
        </ContainerStep2>
      </Form>
    </ContainerModal>
  )
}
