import styled from 'styled-components'

export const ContainerDataNumber = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const SmalDescriptions = styled.p`
  color: #242424;
  font-family: "Open Sans";
  font-size: 14px;
`

export const ContainerScript = styled.div`
  border-radius: 12px;
  border: 1px solid #d6d6d6;
  width: 100%;
  padding: 23px;
  margin-top: 20px;
`

export const HeaderScript = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #d6d6d6;
`

export const CountScripts = styled.div`
  color: #ffffff;
  font-family: "Open Sans";
  font-size: 16px;
  width: 105px;
  height: 32px;
  border-radius: 16px;
  background-color: #6600cc;
  display: flex;
  align-items: center;
  margin-left: 10px;
  justify-content: center;
`

export const BodyCalendar = styled.div`
  padding-top: 20px;
  min-width: 1000px;
  overflow: scroll;
`

export const HeaderCalendar = styled.p`
  color: #272d3b;
  font-family: "Open Sans";
  font-size: 24px;
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 30px;
`

export const DaysOfWeek = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const DaysWeeK = styled.p`
  width: 100%;
  color: #390071;
  font-family: "Open Sans";
  font-size: 18px;
  text-align: center;
`

export const BodyDays = styled.div`
  width: 100%;
  overflow-y: auto;
  ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-track {
        background: #EBEBEB;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #666666;
        border-radius: 20px;
        border: 3px solid #EBEBEB;
    }
`

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 25px 0;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
`

export const ContainerDays = styled.div`
  flex: 0 0 260px;
`
