import styled from 'styled-components'

export const CardTitle = styled.div`
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.06);

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 8px;
  border-radius: 5px;

  margin-bottom: 10px;
  div {
    width: 100%;
  }
`
