import styled, { css } from 'styled-components'
import CloseIcon from '@material-ui/icons/Close'

interface IPropsDragging {
  isDragging?: any
}

export const ContainerQuestion = styled.div<IPropsDragging>`
  display: flex;
  width: 100%;
  height: 80px;
  padding: 20px 30px;
  margin-bottom: 20px;
  position: relative;
  align-items: center;
  /* :hover {
    background: #FAFAFA;
    transition: 0.2s linear;
    cursor: move;
  } */

  z-index: 1;
  padding-left: 5px;
  &&& .ant-input-affix-wrapper {
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
  }

  &&& .ant-modal-wrap {
    z-index: 1 !important;
  }

  &&& .ant-modal-mask {
    z-index: 1 !important;
  }

  ${(props) =>
    props.isDragging &&
    css`
      background: #fafafa;
      transition: 0.2s linear;
    `}
`

export const ButtonStyle = styled.button`
  width: 50px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 15px;
  border: none;
  cursor: pointer;
  p {
    margin: 0;
    color: white;
    font-weight: bold;
  }
`

export const IdQuestion = styled.div`
  display: flex;
  align-items: center;
  justify-content: conter;
`
export const Forms = styled.div`
  width: 100%;

  &&& .MuiTextField-root {
    width: 100%;
    margin-top: 25px;
    height: 90px;
  }
`

export const FormsDescri = styled.div`
  padding: 20px 30px;
  height: 120px;
  width: 100%;
  margin-top: -50px;

  &&& .MuiTextField-root {
    width: 100%;
  }
`
export const QuestJump = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  justify-content: space-between;
`
export const ContainerQuestJump = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0 20px;
  }
`
export const CloseConfig = styled(CloseIcon)`
  cursor: pointer;
`
// interface ContainerProps{
//   transform: string,
//   width: string,
// }

export const ContainerModal = styled.div`
  width: 100%;
  height: 100%;

  &&& .site-drawer-render-in-current-wrapper {
    position: relative;
    height: 200px;
    padding: 48px;
    overflow: hidden;
    text-align: center;
    background: #fafafa;
    border: 1px solid #ebedf0;
    border-radius: 2px;
  }
`
