import styled from 'styled-components'
import { Modal } from 'antd'

export const ContainerModal = styled(Modal)`
    &&& .ant-modal-content{
        border-radius: 12px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    }

    &&& .ant-modal-body{
        border-radius: 12px;
    }

    &&& .ant-modal-close-x{
        color: red;
    }

    &&& .ant-upload.ant-upload-drag {
        width: auto !important;
        height: auto !important;
        padding: 28px 5px;
    }
`
export const Body = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h3 {
        color: #6f7783;
        font-weight: bold;
        letter-spacing: 1px;
    }

    h2 {
        font-weight: bold;
        letter-spacing: 1px;
        color: #a96cf6;
        margin-bottom: 0;
        font-size: 17px;
    }
`

export const ContainerLogo = styled.div`
    width: 80px;
    height: 80px;
    border-radius: 40px;
    background-color: #dfe0e2;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;

    svg {
        font-size: 30px;
        color: #fff;
    }
`