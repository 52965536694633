import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons'
import { Button, Dropdown, Menu, message, Popconfirm, Tag } from 'antd'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import api from '../../../services/api'
import { isActiveSelect } from '../../../utils/option-user'
import { Mixpanel } from '../../../utils/mixpanel'
import DefaultPage from '../../../components/DefaultPage'
import { ContextUpload } from '../../../context/contextUpload'
import { hasIndex } from '../../../components/Menu/menuHorizonte'
import api_v2 from '../../../services/api-v2'

const RemovalListPage: React.FC = () => {
  const { permissions } = ContextUpload()
  const [reload, setReload] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  function compareByAlph(a: any, z: any) {
    if (a > z) {
      return -1
    }
    if (a < z) {
      return 1
    }
    return 0
  }

  const deleteRemoval = async (id: string) => {
    setLoading(true)
    try {
      const { data } = await api_v2.delete('/removal/' + id)
      if (data) {
        message.success('motivo de afastamento excluido com sucesso!')
        Mixpanel.track('Deletou motivo de afastamento de falta', {
          params: {
            id: id,
          },
        })
        setReload(!reload)
      } else {
        message.error('Erro ao deletear motivo de afastamento.')
      }
    } catch (err) {
      message.error('Não foi possivel deletar a motivo de afastamento')
    }
    setLoading(false)
  }

  return (
    <DefaultPage
      titlePage='Motivo de afastamento'
      breadcrumb={[{ name: 'Home', url: '/' }, { name: 'Motivo de afastamento' }]}
      urlPage={`/backoffice/register/removal`}
      routeApi={{
        method: 'get',
        route: 'removal',
        versionApi: 'v2',
        reload,
      }}
      listButtons={{
        buttonCreate: {
          visible: true,
          type: 'url',
          url: '/backoffice/register/removal/create',
        },
        // buttonFilter: {
        //   visible: true,
        //   filters: [
        //     {
        //       label: 'Descrição:',
        //       name: 'description',
        //       type: 'text',
        //     },
        //     {
        //       label: 'Ativo no mobile?',
        //       name: 'mobile_active',
        //       type: 'select',
        //       options: isActiveSelect,
        //       getEnv: 'value',
        //     },
        //     {
        //       label: 'Foto obrigatória?',
        //       name: 'mandatory_picture',
        //       type: 'select',
        //       options: isActiveSelect,
        //       getEnv: 'value',
        //     },
        //   ],
        // },
      }}
      load={{ loading, setLoading }}
      columns={[
        {
          title: 'Nome',
          dataIndex: 'name',
          sorter: (a, b) => compareByAlph(a.name, b.name),
        },
        {
          title: 'Ativo',
          render: (obj: any) => (obj.is_active ? <Tag color='green'>Sim</Tag> : <Tag color='red'>Não</Tag>),
        },

        // {
        //   title: 'Motivo de cadastrado',
        //   dataIndex: 'reazon',
        // },
        {
          title: '#',
          width: 50,
          render: (obj: any) => {
            return (
              <Dropdown
                overlay={
                  <Menu>
                    {hasIndex(['justify.remove_justify'], permissions) > -1 && (
                      <Menu.Item key='1' icon={<DeleteOutlined />}>
                        <Popconfirm
                          title='Tem certeza que deseja excluir este afastamento?'
                          onConfirm={() => deleteRemoval(obj.id)}
                          okText='Sim'
                          cancelText='Não'
                          placement='topRight'
                        >
                          Deletar afastamento
                        </Popconfirm>
                      </Menu.Item>
                    )}
                    <Menu.Item key='2' icon={<EditOutlined />}>
                      <Link to={`/backoffice/register/removal/edit/${obj.id}`}>Editar afastamento</Link>
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button size='small' shape='circle' icon={<MoreOutlined />} />
              </Dropdown>
            )
          },
        },
      ]}
    />
  )
}

export default RemovalListPage
