import { FormHandles } from '@unform/core'
import Input from '../../../components/form/SimpleInput'
import { Form } from '@unform/web'
import { Button, Col, message, Row, Checkbox, Upload } from 'antd'
import React, { useEffect, useState, useRef } from 'react'
import * as Yup from 'yup'
import { Container, Label } from '../../../GlobalStyles'
import history from '../../../routes/history'
import api from '../../../services/api'
import { Loading } from '../../../components/Loading'
import { InputCheckbox, LabelCheck } from './styles'
import { Search } from '../../../components/form/SimpleSearch'
import { Mixpanel } from '../../../utils/mixpanel'
import firebase from '../../../utils/firebase'
import { generateUUID } from '../../../utils/funcao'
import { UploadOutlined } from '@material-ui/icons'
import api_v2 from '../../../services/api-v2'

const FlagCreatePage: React.FC = () => {
  const formRef = useRef<FormHandles>(null)
  const [checked, setChecked] = useState<boolean>(true)
  const [, setSelectNetwork] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const [fileUrls, setFileUrls] = useState<any[]>([]);

  useEffect(() => {
    async function loadAsyncNetwork() {
      setLoading(true)
      const { data } = await api.get('/network')
      const result = data.result.map((item: any) => ({
        value: item.id,
        label: item.name,
      }))
      setSelectNetwork(result)
      setLoading(false)
    }

    loadAsyncNetwork()
  }, [])

  async function handleSubmit(body: any) {
    setLoading(true)
    try {
      const { name, network } = body
      const schema = Yup.object().shape({
        name: Yup.string().required('* Informe o nome da bandeira'),
      })

      await schema.validate(body, { abortEarly: false })

      await api_v2.post('/flag', {
        name,
        network: network?.value,
        is_active: checked,
        fileUrls: fileUrls.map((item) => item.url)
      })

      Mixpanel.track('Criou Rede', {
        params: {
          name,
          network: network?.value,
          is_active: checked,
        },
      })

      message.success('Bandeira cadastrada com sucesso')
      history.push('/backoffice/store/flags')
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((el: any) => {
          formRef.current!.setFieldError(el.path, el.message)
        })
      } else {
        message.error('Já existe um registro com essas informações!')
      }
    }
    setLoading(false)
  }

  return (
    <Container>
      {loading ? <Loading /> : null}
      <h1>Cadastro de bandeira</h1>
      <p>Insira os dados para realizar o cadastro</p>

      <Form className='form' initialData={{ is_active: true }} onSubmit={handleSubmit} ref={formRef}>
        <Row justify='end'>
          <Col>
            <Button type='primary' htmlType='submit'>
              Cadastrar
            </Button>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={11}>
            <Label>Nome da bandeira *</Label>
            <Input name='name' type='text' placeholder='Insira o nome da bandeira' />
          </Col>
          <Col span={11}>
            <Label>Rede *</Label>
            <Search placeholder='Selecione a rede' name='network' path='networks' isMult={false} />
          </Col>
          <Col span={12}>
            <Label> Planograma </Label>
            <Upload
              listType='picture'
              style={{ width: '100%' }}
              fileList={fileUrls}
              onRemove={(file) => {
                let newFiles = fileUrls.filter((item) => item.url != file.url)
                setFileUrls([...newFiles])
              }}
              customRequest={async ({ file, onSuccess, onError }: any) => {
                try {
                  const team: any = sessionStorage.getItem('@rocketpdv:workspace')
                  const fileRef = firebase
                    .storage()
                    .ref(`${JSON.parse(team).slug}/planograma/${generateUUID()}${file.name}`)
                  await fileRef.put(file)
                  const url = await fileRef.getDownloadURL()
                  const pictureList = {
                    uid: generateUUID(),
                    status: 'done',
                    url, 
                  }

                  let newList = fileUrls


                  newList.push(pictureList)
                  
                  console.log(newList)
                  setFileUrls([...newList]);
                  // Retorne a URL diretamente
                  message.success('Sucesso ao subir arquivo')
                } catch (error) {
                  console.log(error)
                  message.error('Erro ao subir arquivo')
                }
              }}
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
          </Col>
          <Col span={2}>
            <LabelCheck>Ativo?</LabelCheck>
            <InputCheckbox>
              <Checkbox
                name='is_active'
                checked={checked}
                defaultChecked={true}
                onChange={(e: any) => {
                  setChecked(e.target.checked)
                }}
              />
            </InputCheckbox>
          </Col>
        </Row>
      </Form>
    </Container>
  )
}

export default FlagCreatePage
