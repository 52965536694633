import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons'
import { Button, Dropdown, Menu, message, Popconfirm, Tag } from 'antd'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import api from '../../../services/api'
import { isActiveSelect } from '../../../utils/option-user'
import { Mixpanel } from '../../../utils/mixpanel'
import DefaultPage from '../../../components/DefaultPage'
import { ContextUpload } from '../../../context/contextUpload'
import { hasIndex } from '../../../components/Menu/menuHorizonte'

const JustificationListPage: React.FC = () => {
  const { permissions } = ContextUpload()
  const [reload, setReload] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  function compareByAlph(a: any, z: any) {
    if (a > z) {
      return -1
    }
    if (a < z) {
      return 1
    }
    return 0
  }

  const deleteForm = async (id: string) => {
    setLoading(true)
    try {
      await api.delete('/faults_options/' + id)
      message.success('Justificativa excluido com sucesso!')
      Mixpanel.track('Deletou justificativa de falta', {
        params: {
          id: id,
        },
      })

      setReload(!reload)
    } catch (err) {
      message.error('Não foi possivel deletar a justificativa')
    }
    setLoading(false)
  }

  return (
    <DefaultPage
      titlePage='Justificativas de Falta'
      breadcrumb={[{ name: 'Home', url: '/' }, { name: 'Justificativas de Falta' }]}
      urlPage={`/backoffice/register/justification`}
      routeApi={{
        method: 'get',
        route: 'faults_options',
        versionApi: 'v1',
        reload,
      }}
      listButtons={{
        buttonCreate: {
          visible: hasIndex(['justify.add_justify'], permissions) > -1,
          type: 'url',
          url: '/backoffice/register/justification/create',
        },
        buttonFilter: {
          visible: true,
          filters: [
            {
              label: 'Descrição:',
              name: 'description',
              type: 'text',
            },
            {
              label: 'Ativo no mobile?',
              name: 'mobile_active',
              type: 'select',
              options: isActiveSelect,
              getEnv: 'value',
            },
            {
              label: 'Foto obrigatória?',
              name: 'mandatory_picture',
              type: 'select',
              options: isActiveSelect,
              getEnv: 'value',
            },
          ],
        },
      }}
      load={{ loading, setLoading }}
      columns={[
        {
          title: 'Justificativa',
          dataIndex: 'description',
          sorter: (a, b) => compareByAlph(a.description, b.description),
        },
        {
          title: 'Ativo no mobile',
          render: (obj: any) => (obj.mobile_active ? <Tag color='green'>Sim</Tag> : <Tag color='red'>Não</Tag>),
        },

        {
          title: 'Foto obrigatória',
          render: (obj: any) => (obj.mandatory_picture ? <Tag color='green'> Sim </Tag> : <Tag color='red'> Não </Tag>),
        },

        {
          title: 'Observação obrigatória',
          render: (obj: any) =>
            obj.mandatory_observation ? <Tag color='green'> Sim </Tag> : <Tag color='red'> Não </Tag>,
        },
        {
          title: '#',
          width: 50,
          render: (obj: any) => {
            if (hasIndex(['justify.change_justify', 'justify.remove_justify'], permissions) === -1) return undefined
            return (
              <Dropdown
                overlay={
                  <Menu>
                    {hasIndex(['justify.remove_justify'], permissions) > -1 && (
                      <Menu.Item key='1' icon={<DeleteOutlined />}>
                        <Popconfirm
                          title='Tem certeza que deseja excluir esta justificativa?'
                          onConfirm={() => deleteForm(obj.id)}
                          okText='Sim'
                          cancelText='Não'
                          placement='topRight'
                        >
                          Deletar justificativa
                        </Popconfirm>
                      </Menu.Item>
                    )}
                    {hasIndex(['justify.change_justify'], permissions) > -1 && (
                      <Menu.Item key='2' icon={<EditOutlined />}>
                        <Link to={`/backoffice/register/justification/edit/${obj.id}`}>Editar justificativa</Link>
                      </Menu.Item>
                    )}
                  </Menu>
                }
              >
                <Button size='small' shape='circle' icon={<MoreOutlined />} />
              </Dropdown>
            )
          },
        },
      ]}
    />
  )
}

export default JustificationListPage
