import React, { useState } from 'react'
import {
  ContainerQuestion,
  TitleBoolean,
  Description,
} from '../style'
import { Radio, Form } from 'antd'
import ViewQuestion from '..'
import ViewPicture from './viewPicture'
const radioStyle = {
  display: 'block',
  height: '60px',
  lineHeight: '30px',
  width: '100%',
  borderRadius: '15px',
  border: '2px solid #7157C1',
  marginBottom: '30px',
  paddingTop: '12px',
  paddingLeft: '10px',
  backgroundColor: 'rgb(113, 87, 193, 0.1)',
}
const BooleanQuestion: React.FC<any> = ({ quest, fields }) => {
  const [value, setValue] = useState<string>('')

  return (
    <>
      <ContainerQuestion>
        <TitleBoolean>
          <h1>
            {quest.is_required ? '*' : null} {quest.order} →{' '}
          </h1>{' '}
          {quest.name}
        </TitleBoolean>
        {quest.description ? <Description>{quest.description}</Description> : null}
        {quest.picture && typeof quest.picture == 'string' &&  <ViewPicture picture={quest.picture} />}
        <Form initialValues={{
          'BOOLEAN': value
        }}>
          <Form.Item name='BOOLEAN' label='Sim ou não' rules={[{ required: quest.is_required ? true : false }]}>
            <Radio.Group style={{ display: 'block' }} onChange={(e) => setValue(e.target.value)}>
              <Radio style={radioStyle} value={'SIM'} >
                SIM
              </Radio>
              <Radio style={radioStyle} value={'NÃO'} >
                NÃO
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </ContainerQuestion>
      {quest && quest.fields_true && quest.fields_true.length > 0 && value && value === 'SIM' && <ViewQuestion questions={quest.fields_true} />}
      {quest && quest.fields_false && quest.fields_false.length > 0 && value && value === 'NÃO' && <ViewQuestion questions={quest.fields_false} />}
    </>
  )
}

export default BooleanQuestion
