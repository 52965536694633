import React, { useState, useEffect, useRef } from 'react'
import { Button, ContainerModal, ContainerStep2 } from './style'
import AddUser from '../../assets/images/addUser.png'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { Col, message, Row } from 'antd'
import { Loading } from '../Loading'
import { Mixpanel } from '../../utils/mixpanel'
import Input from '../form/SimpleInput'
import * as Yup from 'yup'
import { GroupForm } from '../../GlobalStyles'
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import api_v2 from '../../services/api-v2'
import { getDataUser } from '../../utils/login'

interface IProps {
  visible: boolean
  close: (boolean: any) => void
}

export const ModalPasswordUser = ({ visible, close }: IProps) => {
  const formRef = useRef<FormHandles>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [seePass, setSeePass] = useState<boolean>(false)
  const [seePass2, setSeePass2] = useState<boolean>(false)

  async function handleSubmit(data: any) {
    setLoading(true)
    try {
      const schema = Yup.object().shape({
        password: Yup.string().required('Senha é obrigatório'),
        confirm: Yup.string().required('É obrigatório confirma a senha'),
      })
      await schema.validate(data, {
        abortEarly: false,
      })

      const { password, confirm } = data

      if (password !== confirm) {
        message.error('As senhas estão diferentes uma da outra.')
        return
      }

      let user = getDataUser()

      await api_v2.put(`user/password/${user.id}`, data)

      Mixpanel.track('Mudou senha do usuario', {
        params: user,
      })

      message.success('Senha alterada com sucesso')
      close(false)
    } catch (err) {
      setLoading(false)
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((el: any) => {
          formRef.current!.setFieldError(el.path, el.message)
        })
        message.error('Erro ao mudar senha!')
      }
    }
    setLoading(false)
  }

  const functionThatResetsForm = () => {
    formRef.current!.reset()
  }

  useEffect(() => {
    if (formRef.current != null) functionThatResetsForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  return (
    <ContainerModal
      visible={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
      destroyOnClose={true}
    >
      {loading && <Loading />}

      <Form className='form' ref={formRef} initialData={{ is_active: true }} onSubmit={handleSubmit}>
        <ContainerStep2>
          <h3>Trocar senha</h3>
          <img src={AddUser} alt='user' />
          <Row gutter={[20, 20]}>
            <Col span={12}>
              <GroupForm style={{ display: 'flex', alignItems: 'center' }}>
                <Input
                  label='Digite a nova senha'
                  type={seePass ? 'text' : 'password'}
                  name='password'
                  style={{ width: '100%' }}
                />
                {seePass && (
                  <EyeOutlined
                    style={{ marginLeft: 5, cursor: 'pointer', fontSize: 20 }}
                    onClick={() => setSeePass(!seePass)}
                  />
                )}
                {!seePass && (
                  <EyeInvisibleOutlined
                    style={{ marginLeft: 5, cursor: 'pointer', fontSize: 20 }}
                    onClick={() => setSeePass(!seePass)}
                  />
                )}
              </GroupForm>
            </Col>
            <Col span={12}>
              <GroupForm style={{ display: 'flex', alignItems: 'center' }}>
                <Input
                  label='Confirme a nova senha'
                  type={seePass2 ? 'text' : 'password'}
                  name='confirm'
                  style={{ width: '100%' }}
                />
                {seePass2 && (
                  <EyeOutlined
                    style={{ marginLeft: 5, cursor: 'pointer', fontSize: 20 }}
                    onClick={() => setSeePass2(!seePass2)}
                  />
                )}
                {!seePass2 && (
                  <EyeInvisibleOutlined
                    style={{ marginLeft: 5, cursor: 'pointer', fontSize: 20 }}
                    onClick={() => setSeePass2(!seePass2)}
                  />
                )}
              </GroupForm>
            </Col>
          </Row>
          <Button type='submit' style={{ width: '100%', height: '55px', marginTop: 20 }}>
            Alterar
          </Button>
        </ContainerStep2>
      </Form>
    </ContainerModal>
  )
}
