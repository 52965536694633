import {
  PlusOutlined,
  MinusOutlined,
  FileSearchOutlined,
  ClearOutlined,
  FilterOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import React, { useEffect, useState, useCallback, useRef } from 'react'
import { ContainerDrag, Less, More, ContainerDash } from './style'
import { DragPreviewImage, useDrag, useDrop } from 'react-dnd'
import { getWindowDimensions } from '../../../components/GraphDash'
import {
  ContainerExpandGraph,
  ContainerGraph,
  ContainerHeader,
  ContainerIconExpand,
  ContainerIconGraph,
  ContainerLegend,
  ContainerTitle,
  ContainerToolTip,
  GraphContainerExpan,
} from '../../../components/GraphDash/style'
import { Dropdown, Menu, Tag, Tooltip as TooltipAntd } from 'antd'
import { useCurrentPng } from 'recharts-to-png'
import FileSaver from 'file-saver'
import { AiOutlineClose } from 'react-icons/ai'
import { BsDownload } from 'react-icons/bs'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import LinearImg from './linear.png'
import BarImg from './bar.png'
import PieImg from './pie.png'
import { DrawerFilter } from '../../../components/DrawerFilter'
import { BigButton, ContainerButtonsFootter, ContainerFormFilter, GroupForm } from '../../../GlobalStyles'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { Search } from '../../../components/form/SimpleSearch'
import { ButtonClear, ButtonSearch, LabelForm } from '../../Team/Promoter/style'
import { ContainerTagg } from '../../Survey/FormListPage/styles'

export const Drag = (props: any) => {
  const [visibleFilter, setVisibleFilter] = useState<boolean>(false)
  const [filterTag, setFilterTag] = useState<any>({})
  const [, dropRef] = useDrop(() => ({
    accept: 'CARD',
    drop(item: any, monitor) {
      let newListData = props.list
      let getData = props.list[item.index]
      let oldData = props.list[props.index]
      newListData[item.index] = oldData
      newListData[props.index] = getData

      props.setList([...newListData])

      item.index = props.index
    },
  }))

  const [count, setCount] = useState<number>(0)
  const [countLess, setCountLess] = useState<number>(0)
  const formRef = useRef<FormHandles>(null)

  const expandLedd = () => {
    let key = props.index
    let newProps = props.list
    newProps[key].size = newProps[key].size - 6
    props.setList([...newProps])
  }

  const expand = () => {
    let key = props.index
    let newProps = props.list

    if (newProps[key].size < 24) {
      newProps[key].size = newProps[key].size + 6
      props.setList([...newProps])
    }
  }

  useEffect(() => {
    let key = props.index
    let newProps = props.list
    setCountLess(newProps[key].size)
    setCount(newProps[key].size)
    // eslint-disable-next-line
  }, [props.list])

  const [{ isDragging }, dragRef, preview] = useDrag(() => ({
    type: 'CARD',
    item: { index: props.index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }))

  const [getLinearPng, { ref: linearRef }] = useCurrentPng()
  const [getPiePng, { ref: pieRef }] = useCurrentPng()
  const [getBarPng, { ref: barRef }] = useCurrentPng()
  const [expandGraph, setExpandGraph] = useState(false)
  const { height } = getWindowDimensions()
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042']
  const RADIAN = Math.PI / 180

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return percent * 100 > 0 ? (
      <text x={x} y={y} fill='white' textAnchor={x > cx ? 'start' : 'end'} dominantBaseline='central'>
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    ) : (
      <></>
    )
  }

  const handleAreaDownload = useCallback(async () => {
    let file
    if (props.type === 'PIE') file = await getPiePng()
    if (props.type === 'LINEAR') file = await getLinearPng()
    if (props.type === 'BAR') file = await getBarPng()
    if (file) {
      FileSaver.saveAs(file, `${props.type}-chart.png`)
    }
    // eslint-disable-next-line
  }, [getPiePng, getLinearPng])

  const menu = (
    <Menu>
      <Menu.ItemGroup key={1}>
        <Menu.Item onClick={handleAreaDownload}>Download PNG</Menu.Item>
        <Menu.Item onClick={() => setExpandGraph(!expandGraph)}>Expandir</Menu.Item>
      </Menu.ItemGroup>
      {/* {type !== 'PIE' && ( */}
      <Menu.SubMenu title='Trocar de gráfico' key={2}>
        {props.type !== 'PIE' && <Menu.Item onClick={() => convertGraph('PIE')}>Pizza</Menu.Item>}
        {props.type !== 'BAR' && <Menu.Item onClick={() => convertGraph('BAR')}>Barra</Menu.Item>}
        {props.type !== 'LINEAR' && <Menu.Item onClick={() => convertGraph('LINEAR')}>Linha</Menu.Item>}
      </Menu.SubMenu>
      {/* )} */}
    </Menu>
  )

  const convertGraph = (typeSelected: string) => {
    let newList = props.list
    let keysName = []

    if (props.type === 'PIE') {
      let newData: any = {}
      props.data.forEach((el: any) => {
        let obj: any = {}
        obj[el.name] = el.value
        newData = { ...newData, ...obj, name: props.title }
        keysName.push(el.name)
      })

      newList[props.index].data = [newData]
    } else {
      for (var prop in newList[props.index].data[0]) {
        if (prop !== 'name') keysName.push(prop)
      }
    }

    newList[props.index] = { ...newList[props.index], type: typeSelected, keysName }

    if (typeSelected === 'PIE') {
      let obj: any = []
      Object.keys(newList[props.index].data[0]).forEach((el) => {
        if (el !== 'name')
          obj.push({
            name: el,
            value: newList[props.index].data[0][el],
          })
      })

      newList[props.index].data = obj
    }

    props.setList([...newList])
  }

  const cleanFilter = async () => {
    setVisibleFilter(false)
    setFilterTag({})
    formRef!.current!.reset()
  }

  async function searchProduct(body: any, { reset }: any) {
    console.log(body)
  }

  const removeFilter = async (typeRemove: string, value: string) => {
    let newObjTagg = filterTag
    if (typeRemove === 'name') delete newObjTagg.name
    if (typeRemove === 'brand') delete newObjTagg.brand
    if (typeRemove === 'product_type') delete newObjTagg.product_type
    if (typeRemove === 'category') delete newObjTagg.category
    if (typeRemove === 'is_active') delete newObjTagg.is_active
    setFilterTag(newObjTagg)
  }

  return (
    <>
      {isDragging ? (
        <ContainerDash />
      ) : (
        <ContainerDrag id={props.id} ref={dropRef} isDragging={isDragging}>
          <div className='isDraggingGraph'>
            <ContainerGraph>
              <ContainerHeader>
                <p className='titleGraph'>{props.title}</p>
                <ContainerIconGraph ref={dragRef}>
                  <DragPreviewImage
                    connect={preview}
                    src={
                      props.type === 'LINEAR'
                        ? LinearImg
                        : props.type === 'BAR'
                        ? BarImg
                        : props.type === 'PIE'
                        ? PieImg
                        : ''
                    }
                  />
                  <TooltipAntd
                    placement='top'
                    title={
                      <>
                        <p>Clique para ver as opções.</p>
                        <p>Arraste para mover o card</p>
                      </>
                    }
                  >
                    <Dropdown.Button overlay={menu} trigger={['click']} />
                  </TooltipAntd>
                  <TooltipAntd placement='top' title={'Filtrar'}>
                    <BigButton onClick={() => setVisibleFilter(!visibleFilter)} style={{ border: 'none' }}>
                      <FilterOutlined />
                    </BigButton>
                  </TooltipAntd>
                </ContainerIconGraph>
              </ContainerHeader>
              <div>
                <ContainerTagg>
                  {filterTag.name && (
                    <Tag color='geekblue' onClick={() => removeFilter('name', filterTag.name)}>
                      {filterTag.name} <CloseOutlined />
                    </Tag>
                  )}
                  {filterTag.brand && (
                    <Tag color='geekblue' onClick={() => removeFilter('brand', filterTag.valueBrand)}>
                      {filterTag.brand}
                      <CloseOutlined />
                    </Tag>
                  )}
                  {filterTag.product_type && (
                    <Tag color='geekblue' onClick={() => removeFilter('product_type', filterTag.valueProductType)}>
                      {filterTag.valueProductType}
                      <CloseOutlined />
                    </Tag>
                  )}
                  {filterTag.category && (
                    <Tag color='geekblue' onClick={() => removeFilter('category', filterTag.category)}>
                      {filterTag.valueCategory}
                      <CloseOutlined />
                    </Tag>
                  )}
                  {filterTag.is_active && (
                    <Tag color='geekblue' onClick={() => removeFilter('is_active', `${filterTag.is_active}`)}>
                      {filterTag.is_active !== 'false' ? 'ATIVO' : 'INATIVO'}
                      <CloseOutlined />
                    </Tag>
                  )}
                </ContainerTagg>
              </div>
              {props.type === 'LINEAR' && (
                <div style={{ width: '100%', height: 230 }}>
                  <ResponsiveContainer>
                    <LineChart ref={linearRef} width={200} height={200} data={props.data} style={{ marginTop: 30 }}>
                      <CartesianGrid strokeDasharray='10 10' />
                      <Tooltip />
                      <Legend />
                      <Line type='monotone' dataKey='pv' stroke='#6600cc' activeDot={{ r: 8 }} strokeWidth={4} />
                      <Line type='monotone' dataKey='uv' stroke='#00ccf2' activeDot={{ r: 8 }} strokeWidth={4} />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              )}
              {props.type === 'BAR' && (
                <div style={{ width: '100%', height: 230 }}>
                  <ResponsiveContainer>
                    <BarChart width={200} height={200} data={props.data} ref={barRef} style={{ marginTop: 30 }}>
                      <CartesianGrid strokeDasharray='3 3' />
                      <XAxis dataKey='name' />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      {props.keysName &&
                        props.keysName.map((entry: any, indexK: number) => (
                          <Bar dataKey={entry} fill={COLORS[indexK % COLORS.length]} key={indexK} />
                        ))}
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              )}
              {props.type === 'PIE' && (
                <div style={{ width: '100%', height: 230 }}>
                  <ResponsiveContainer>
                    <PieChart ref={pieRef}>
                      <Pie
                        data={props.data}
                        cx='50%'
                        cy='50%'
                        labelLine={false}
                        label={renderCustomizedLabel}
                        outerRadius={80}
                        fill='#8884d8'
                        dataKey='value'
                      >
                        {props.data.map((entry: any, index: number) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                  <ContainerToolTip>
                    {props.keysName &&
                      props.keysName.map((entry: any, index: number) => (
                        <TooltipAntd placement='top' title={entry} key={index}>
                          <ContainerLegend>
                            <div style={{ background: COLORS[index % COLORS.length] }} />
                            <p style={{ color: COLORS[index % COLORS.length] }}>{entry}</p>
                          </ContainerLegend>
                        </TooltipAntd>
                      ))}
                  </ContainerToolTip>
                </div>
              )}
              {expandGraph && (
                <ContainerExpandGraph>
                  <ContainerTitle>
                    <p>{props.title}</p>
                  </ContainerTitle>
                  <ContainerIconExpand>
                    <BsDownload onClick={handleAreaDownload} color='#EEF2FF' />
                    <AiOutlineClose color='#FF5959' onClick={() => setExpandGraph(!expandGraph)} />
                  </ContainerIconExpand>
                  <GraphContainerExpan>
                    {props.type === 'LINEAR' && (
                      <div style={{ width: '100%', height: height * 0.7 }}>
                        <ResponsiveContainer>
                          <LineChart data={props.data} ref={linearRef}>
                            <CartesianGrid strokeDasharray='10 10' />
                            <Tooltip />
                            <Legend />
                            <Line type='monotone' dataKey='pv' stroke='#6600cc' activeDot={{ r: 8 }} strokeWidth={4} />
                            <Line type='monotone' dataKey='uv' stroke='#00ccf2' activeDot={{ r: 8 }} strokeWidth={4} />
                          </LineChart>
                        </ResponsiveContainer>
                      </div>
                    )}
                    {props.type === 'PIE' && (
                      <div style={{ width: '100%', height: height * 0.7 }}>
                        <ResponsiveContainer>
                          <PieChart ref={pieRef}>
                            <Pie
                              data={props.data}
                              cx='50%'
                              cy='50%'
                              labelLine={false}
                              label={renderCustomizedLabel}
                              outerRadius={80}
                              fill='#8884d8'
                              dataKey='value'
                            >
                              {props.data.map((entry: any, index: number) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                              ))}
                            </Pie>
                          </PieChart>
                        </ResponsiveContainer>
                      </div>
                    )}
                    {props.type === 'BAR' && (
                      <div style={{ width: '100%', height: height * 0.7 }}>
                        <ResponsiveContainer>
                          <BarChart data={props.data} ref={barRef}>
                            <CartesianGrid strokeDasharray='3 3' />
                            <XAxis dataKey='name' />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            {props.keysName &&
                              props.keysName.map((entry: any, index: number) => (
                                <Bar dataKey={entry} fill={COLORS[index % COLORS.length]} />
                              ))}
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    )}
                  </GraphContainerExpan>
                </ContainerExpandGraph>
              )}
            </ContainerGraph>
          </div>
          <div className='containerExpanded'>
            {countLess > 6 && (
              <Less
                style={{
                  borderRight: '1px solid #fff',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={expandLedd}
              >
                <MinusOutlined />
              </Less>
            )}
            {count < 24 && (
              <More onClick={expand}>
                <PlusOutlined />
              </More>
            )}
          </div>
        </ContainerDrag>
      )}

      <DrawerFilter
        visible={visibleFilter}
        close={setVisibleFilter}
        title={props.title}
        footer={
          <ContainerButtonsFootter>
            <ButtonSearch type='button' onClick={() => formRef?.current?.submitForm()}>
              <FileSearchOutlined />
              Pesquisar
            </ButtonSearch>
            <ButtonClear onClick={() => cleanFilter()}>
              <ClearOutlined />
              Limpar
            </ButtonClear>
          </ContainerButtonsFootter>
        }
      >
        <ContainerFormFilter
          tabIndex={0}
          onKeyDown={(evt: any) => {
            if (evt.key === 'Enter') formRef?.current?.submitForm()
          }}
        >
          <Form ref={formRef} className='form' style={{ marginTop: '15px' }} onSubmit={searchProduct}>
            <GroupForm>
              <LabelForm>Selecione o nome do promotor</LabelForm>
              <Search placeholder='Selecione o nome do promotor' name='promoters' path='promoters' isMult={true} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione produto</LabelForm>
              <Search placeholder='Selecione o produto' name='products' path='products' isMult={true} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione a categoria</LabelForm>
              <Search placeholder='Selecione a categoria' name='category' path='categories' isMult={true} />
            </GroupForm>
          </Form>
        </ContainerFormFilter>
      </DrawerFilter>
    </>
  )
}
