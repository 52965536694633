import { ApexOptions } from 'apexcharts'
import moment from 'moment'
import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { LoadingOutlined } from '@ant-design/icons'

interface Props {
  categories: string[]
  series: SeriesProps[]
  id?: string
  type?: string
  legend?: boolean
  tooltip?: boolean
  columnWidth?: string
  widthTable?: any
  orientation?: any
  loading?: boolean
}

interface SeriesProps {
  name?: string
  data: any
}

export const randonValue = (qtd: number) => {
  let list = []
  for (let index = 0; index <= qtd; index++) {
    list.push(Math.floor(Math.random() * (100 - 0 + 1) + 0))
  }
  return list
}

export const randonValueTimeStamp = (qtd: number) => {
  let list = []
  for (let index = 0; index <= qtd; index++) {
    list.push(moment(new Date(+new Date() - Math.floor(Math.random() * 10000000000))).toString())
  }
  return list
}

export const sequenceValueTimeStamp = (qtd: number, format?: string) => {
  let list = []
  for (let index = 0; index <= qtd; index++) {
    if (format) {
      list.push(
        moment()
          .add(index + 1, 'day')
          .format(format)
          .toString()
      )
    } else {
      list.push(
        moment()
          .add(index + 1, 'day')
          .toString()
      )
    }
  }
  return list
}

const ColumnDash = ({
  loading,
  categories,
  series,
  id,
  type,
  legend,
  tooltip,
  columnWidth,
  widthTable,
  orientation,
}: Props) => {
  let options: ApexOptions = {
    grid: {
      show: true,
    },
    chart: {
      id: id ? id : 'basic-bar',
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      type: 'bar',
      events: {
        click: function (chart: any, w: any, e: any) {
          // console.log(chart, w, e)
        },
      },
    },
    // colors: colors,
    plotOptions: {
      bar: {
        columnWidth: columnWidth ? columnWidth : '50px',
        distributed: false,
        dataLabels: {
          position: orientation ? 'bottom' : 'top',
          orientation: orientation ? orientation : 'horizontal',
        },
      },
    },
    /*     theme: {
      monochrome: {
        enabled: true,
        color: '#6600cc',
      },
    }, */
    colors: ['#6600cc', '#b56aff', '#00C9A7', '#005B44'],
    dataLabels: {
      distributed: true,
      enabled: true,
      offsetY: orientation ? 15 : -20,
      formatter: function (value, opt) {
        if (type === 'time') {
          let value = series[opt.seriesIndex].data[opt.dataPointIndex]
          if (value === '0') return value

          return `${value.slice(0, 2)}:${value.slice(2, 4)}:${value.slice(3, 5)}`
        } else {
          return `${value}`
        }
      },
      style: {
        fontWeight: 'bold',
        fontSize: '14px',
        colors: [orientation ? '#fff' : '#637282'],
      },
    },
    tooltip: {
      enabled: tooltip ? tooltip : false,
      y: {
        formatter: function (value, opt) {
          if (type === 'time') {
            let value = series[opt.seriesIndex].data[opt.dataPointIndex]
            if (value === '0') return value
            return `${value.slice(0, 2)}:${value.slice(2, 4)}:${value.slice(3, 5)}`
          } else {
            return `${value}`
          }
        },
      },
    },
    legend: {
      show: true,
    },
    xaxis: {
      categories,
      position: 'center',
      labels: {
        show: true,
        style: {
          // colors: colors,
          fontSize: '12px',
        },
      },
    },
    fill: {
      type: legend ? '' : 'gradient',
      gradient: {
        type: 'vertical',
        shadeIntensity: 1,
        colorStops: [
          {
            offset: 0,
            color: '#b56aff',
            opacity: 0.5,
          },
          {
            offset: 100,
            color: '#6600cc',
            opacity: 1,
          },
        ],
      },
    },
  }

  if (loading) {
    return <LoadingOutlined style={{ color: '#6600cc' }} />
  }
  return (
    <>
      <ReactApexChart
        options={options}
        series={series.map((body: any) => {
          return {
            ...body,
            data: body.data.map((value: any) => {
              if (!!!value) return '0'
              return value
            }),
          }
        })}
        type='bar'
        width={
          id === 'full-bar-dash'
            ? widthTable
              ? categories.length * widthTable
              : categories.length > 10
              ? categories.length * 100
              : '100%'
            : '100%'
        }
        height={450}
      />
    </>
  )
}

export default ColumnDash
