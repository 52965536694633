import styled from 'styled-components'


export const ContainerCardTop = styled.div`
  display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 30px;

  margin-top: 50px;
`

export const ContainerCardScroll = styled.div`
  margin-top: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  max-width: 95vw;
  overflow-y: scroll;
  padding: 10px 0;
  scrollbar-width: thin;
  border-bottom: 1px solid #e2e2e2;
::-webkit-scrollbar {
  width: 1px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  background-color: #b56aff;
  border: 1px solid #b56aff;
  border-radius: 5px;
  height: 2px;
  width: 2px;
}
`

export const ContainerFullGraph = styled.div`
  width: 100%;
  margin-top: 60px;
  max-width: 95vw;
  overflow-y: scroll;
  height: 100%;

  h3 {
    color: #6600cd;
    font-weight: bold;
    font-size: 20px;
  }

  &&& .ant-switch-checked {
    background-color: #b570fc !important;
  }

  ::-webkit-scrollbar {
  width: 1px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  background-color: #b56aff;
  border: 1px solid #b56aff;
  border-radius: 5px;
  height: 2px;
  width: 2px;
}

  &&& .ant-collapse-borderless > .ant-collapse-item{
    border-bottom: 1px solid #6600cd !important;
  }

  &&& .ant-collapse-header {
    background-color: #f8f8f8!important;
    color: #6600cd !important;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 1px;
  }

  &&& .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content{
    background-color: #f8f8f8 !important;
  }
`
