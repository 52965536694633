import styled from 'styled-components'

export const ContainerType = styled.div`
  margin-left: -420px;
  z-index: 9999999;
  width: 420px;
  height: 300px;
  border-radius: 15px;
  box-shadow: 2px 3px 18px -4px #666;
  display: flex;
  align-items: center;
  justifiy-content: center;
  margin-bottom: 310px;
`
export const TypeLeft = styled.div`
  width: 100%;
  height: 100%;
  padding-left: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
  .Title{
    font-weight: bold;
  }
`
export const TypeRight = styled.div`
  width: 100%;
  height: 100%;
  background: #F0F0F0;
`
export const ListTypes = styled.div`
  overflow-x: auto;
  max-height: 85%;
  scrollbar-color: dark;
  ul li{
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 40px;
  }
  ul li:hover{
    background: #F0F0F0;
  }

  ul li p{
    margin-left: 15px;
    margin-bottom: 0;
  }

  ::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
  }
  
  ::-webkit-scrollbar
  {
    width: 6px;
    background-color: #F5F5F5;
  }
  
  ::-webkit-scrollbar-thumb
  {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #7157C1;
  }
    
`
export const InfoType = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h3{
    font-weight: bold;
    font-size: 20px;
  }
`
export const TextInfo = styled.div`
  width: 100%;
  padding: 10px;
  p{
    width: 100%;
    margin: 0;
    letter-spacing: 1px;
    text-align: justify;
  }
`