import { ApexOptions } from 'apexcharts'
import React from 'react'
import ReactApexChart from 'react-apexcharts'

interface Props {
  categories: string[]
  series: number[]
  id?: string
  type?: string
  legend?: boolean
  tooltip?: boolean
}

const DonultsDash = ({ categories, series, id, type, legend, tooltip }: Props) => {
  const options: ApexOptions = {
    chart: {
      width: '100%',
      type: 'pie',
    },
    labels: categories,
    theme: {
      monochrome: {
        enabled: true,
        color: '#6600cc',
        shadeTo: 'light',
      },
    },
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -5,
        },
      },
    },
    legend: {
      show: legend,
      position: 'bottom',
    },
  }
  return <ReactApexChart options={options} series={series} type='pie' />
}

export default DonultsDash
