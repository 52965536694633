import React, { useEffect, useState } from 'react'
import { Container, TextError, Title } from './styles'
import MoneyInput from 'antd-money'

interface IQuestion {
  id: number
  is_required: boolean
  name: string
  order: string
  type: string
  fields_true?: any
}
interface IDataQuestion {
  dataQuestion: IQuestion
  handleAnswers: (value: any, dataQuestion: IQuestion) => void
  error?: boolean
  checkRequired: (value: any) => void
}

export const MoneyMass = ({ dataQuestion, handleAnswers, error, checkRequired }: IDataQuestion) => {
  const [value, setValue] = useState<any>()
  const [isRender, setIsRender] = useState(false)

  const handleValue = (value: any) => {
    let money = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value)

    setValue(value)
    handleAnswers([money.replace(/\s+/g, '')], dataQuestion)
  }

  useEffect(() => {
    if (dataQuestion.is_required && !isRender) {
      setIsRender(true)
      checkRequired(dataQuestion.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <Title>
        {dataQuestion.order} - {dataQuestion.name} {dataQuestion.is_required && '*'}
      </Title>
      <MoneyInput prefix={'R$'} commaSeperator={true} onChange={handleValue} style={{ width: '100%' }} />
      {error && !value && <TextError>Campo obrigatório</TextError>}
    </Container>
  )
}
