import { EyeOutlined, MoreOutlined, FileAddOutlined, DeleteOutlined,CloudDownloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Dropdown, Menu, message, Modal, Tooltip } from 'antd'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { ModalCreateMix } from '../../../components/ModalCreateMix'
import DefaultPage from '../../../components/DefaultPage'
import { compareByAlph, exportXls } from '../../../utils/funcoes'
import { ModalCreateMixProduct } from '../../../components/ModalCreateMixProduct'
import { ContextUpload } from '../../../context/contextUpload'
import { hasIndex } from '../../../components/Menu/menuHorizonte'
import api_v2 from '../../../services/api-v2'

const MixProductListPage = () => {
  const { permissions } = ContextUpload()
  const [loading, setLoading] = useState(false)
  const [reload, setReload] = useState(false)
  const [createMix, setCreateMix] = useState<boolean>(false)
  const [createMixUpload, setCreateMixUpload] = useState<boolean>(false)
  const [selectedMix, setSelectedMix] = useState<any>({})
  
  const deleteMix = async (mix: any) => {
    try {
      setLoading(true)
      await api_v2.delete(`product-mix/deleteMix/${mix.id}`)
      setLoading(false)
      setReload(!reload)
    } catch (error) {
      console.log(error)
      message.error('Erro ao deletar mix')
      setLoading(false)
    }
  }
  const exportMix = async (mix: any) => {
    try {
     setLoading(true)
     await exportXls({}, `products-mix/${mix.id}`)
     setLoading(false)
    } catch (error) {
      console.log(error);
      message.error('Erro ao Exportar mix');
      setLoading(false);
    }
  };

  return (
    <>
      <DefaultPage
        titlePage='Mix de Produtos'
        breadcrumb={[{ name: 'Home', url: '/' }, { name: 'Mix de Produtos' }]}
        urlPage='/backoffice/catalog/mix-products'
        routeApi={{
          method: 'get',
          route: '/product-mix',
          versionApi: 'v2',
          reload,
        }}
        listButtons={{
          buttonCreate: {
            visible: hasIndex(['catalog.add_product_mix'], permissions) > -1,
            type: 'function',
            function: () => setCreateMix(true),
          },
          buttonFilter: {
            visible: true,
            filters: [
              {
                label: 'Selecione o mix:',
                name: 'id_mix',
                type: 'search',
                urlSearch: 'products-mix',
                isMult: true,
                getEnv: 'value',
              },
            ],
          },
        }}
        load={{ loading, setLoading }}
        columns={[
          {
            title: 'Nome',
            dataIndex: 'name',
            sorter: (a: any, b: any) => compareByAlph(a.name, b.name),
          },
          {
            title: 'Tipo',
            dataIndex: 'mix_by',
            sorter: (a: any, b: any) => compareByAlph(a.mix_by, b.mix_by),
            render: (type: any) => (
              <>
                {type === 'FLAG' && <p>Bandeira</p>}
                {type === 'NETWORK' && <p>Rede</p>}
                {type === 'PDV' && <p>PDV</p>}
              </>
            ),
          },
          {
            title: 'Data de criação',
            dataIndex: 'created_at',
            sorter: (a: any, b: any) => compareByAlph(a.created_at, b.created_at),
            render: (a: any) => <p>{moment(a).format('DD/MM/YYYY HH:mm')}</p>,
          },
          {
            title: '#',
            width: 50,
            render: (obj: any) => {
              return (
                <Dropdown
                  overlay={
                    <Menu>
                      {hasIndex(['catalog.import_product_mix'], permissions) > -1 && (
                        <Menu.Item
                          key='2'
                          icon={<FileAddOutlined />}
                          onClick={() => {
                            setSelectedMix(obj)
                            setCreateMixUpload(!createMixUpload)
                          }}
                        >
                          <Tooltip placement='top' title={'Criar sub mix'}>
                            Criar sub mix
                          </Tooltip>
                        </Menu.Item>
                      )}
                      <Menu.Item key='1' icon={<EyeOutlined />}>
                        <Link to={`/backoffice/catalog/mix-products/${obj.id}?context=${obj.mix_by}`}>
                          Visualizar mix
                        </Link>
                      </Menu.Item>
                      <Menu.Item
                        key='2'
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          Modal.confirm({
                            title: 'Deletar mix',
                            icon: <ExclamationCircleOutlined />,
                            content: 'Você tem certeza que deseja deletar esse mix?',
                            onOk() {
                              deleteMix(obj)
                            },
                            onCancel() {
                              console.log('Cancel')
                            },
                          })
                        }}
                      >
                        <Tooltip placement='top' title={'Excluir mix'}>
                          Excluir mix
                        </Tooltip>
                      </Menu.Item>
                      <Menu.Item
                        key='2'
                        icon={<CloudDownloadOutlined  />}
                        onClick={() => {
                          exportMix(obj)                      
                        }}
                      >
                        <Tooltip placement='top' title={'Exportar Mix'}>
                          Exportar mix
                        </Tooltip>
                      </Menu.Item>

                    </Menu>
                  }
                >
                  <Button size='small' shape='circle' icon={<MoreOutlined />} />
                </Dropdown>
              )
            },
          },
        ]}
      />
      <ModalCreateMix visible={createMix} close={setCreateMix} reloadApi={() => setReload(!reload)} />
      <ModalCreateMixProduct
        id={selectedMix.id}
        context={selectedMix.mix_by}
        visible={createMixUpload}
        close={setCreateMixUpload}
      />
    </>
  )
}

export default MixProductListPage
