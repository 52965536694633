import { EyeOutlined, MoreOutlined, FileAddOutlined, DeleteOutlined } from '@ant-design/icons'
import { Button, Dropdown, Menu, message, Tooltip } from 'antd'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { ModalCreateMix } from '../../../components/ModalCreateMix'
import DefaultPage from '../../../components/DefaultPage'
import { compareByAlph } from '../../../utils/funcoes'
import { ContextUpload } from '../../../context/contextUpload'
import { hasIndex } from '../../../components/Menu/menuHorizonte'
import api_v2 from '../../../services/api-v2'
import { ModalCreateMixC } from '../../../components/ModalCreateMixCategory'

const MixCategoryListPage = () => {
  const { permissions } = ContextUpload()
  const [loading, setLoading] = useState(false)
  const [reload, setReload] = useState(false)
  const [createMix, setCreateMix] = useState<boolean>(false)
  const [createMixUpload, setCreateMixUpload] = useState<boolean>(false)
  const [selectedMix, setSelectedMix] = useState<any>({})

  const deleteMix = async (mix: any) => {
    try {
      setLoading(true)
      await api_v2.delete(`category/delete-mix/${mix.id}`)
      setLoading(false)
      setReload(!reload)
    } catch (error) {
      console.log(error)
      message.error('Erro ao deletar mix de categoria')
      setLoading(false)
    }
  }
  return (
    <>
      <DefaultPage
        titlePage='Mix de categoria'
        breadcrumb={[{ name: 'Home', url: '/' }, { name: 'Mix de categoria' }]}
        urlPage='/backoffice/catalog/mix-category'
        routeApi={{
          method: 'get',
          route: '/category/category-mix',
          versionApi: 'v2',
          reload,
        }}
        listButtons={{
          buttonCreate: {
            visible: true,
            type: 'function',
            function: () => setCreateMix(true),
          },
          buttonFilter: {
            visible: true,
            filters: [
              {
                label: 'Selecione o mix:',
                name: 'id_mix',
                type: 'search',
                urlSearch: 'category-mix',
                isMult: true,
                getEnv: 'value',
              },
            ],
          },
        }}
        load={{ loading, setLoading }}
        columns={[
          {
            title: 'Nome',
            dataIndex: 'name',
            sorter: (a: any, b: any) => compareByAlph(a.name, b.name),
          },
          {
            title: 'Tipo',
            dataIndex: 'mix_by',
            sorter: (a: any, b: any) => compareByAlph(a.mix_by, b.mix_by),
            render: (type: any) => (
              <>
                {type === 'FLAG' && <p>Bandeira</p>}
                {type === 'NETWORK' && <p>Rede</p>}
                {type === 'PDV' && <p>PDV</p>}
              </>
            ),
          },
          {
            title: 'Data de criação',
            dataIndex: 'created_at',
            sorter: (a: any, b: any) => compareByAlph(a.created_at, b.created_at),
            render: (a: any) => <p>{moment(a).format('DD/MM/YYYY HH:mm')}</p>,
          },
          {
            title: '#',
            width: 50,
            render: (obj: any) => {
              return (
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item
                        key='2'
                        icon={<FileAddOutlined />}
                        onClick={() => {
                          setSelectedMix(obj)
                          setCreateMixUpload(!createMixUpload)
                        }}
                      >
                        <Tooltip placement='top' title={'Criar sub mix'}>
                          Criar sub mix
                        </Tooltip>
                      </Menu.Item>
                      <Menu.Item key='1' icon={<EyeOutlined />}>
                        <Link to={`/backoffice/catalog/mix-category/${obj.id}?context=${obj.mix_by}`}>
                          Visualizar mix
                        </Link>
                      </Menu.Item>
                      <Menu.Item
                        key='2'
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          deleteMix(obj)
                        }}
                      >
                        <Tooltip placement='top' title={'Excluir mix'}>
                          Excluir mix
                        </Tooltip>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button size='small' shape='circle' icon={<MoreOutlined />} />
                </Dropdown>
              )
            },
          },
        ]}
      />
      <ModalCreateMix visible={createMix} close={(bool) => setCreateMix(!createMix)} reloadApi={() => setReload(!reload)} name="categoria" route="category/create-mix"/>
      <ModalCreateMixC
        id={selectedMix.id}
        context={selectedMix.mix_by}
        visible={createMixUpload}
        close={setCreateMixUpload}
      />
    </>
  )
}

export default MixCategoryListPage
