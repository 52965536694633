import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import { Breadcrumb, Button, Col, Row, Steps, Tabs, Table, Tag, Checkbox, message, Divider } from 'antd'
import { Container } from './styles'
import { useEffect, useRef, useState, ChangeEvent } from 'react'
import { Link } from 'react-router-dom'
import Select from '../../../components/form/SimpleSelect'
import api from '../../../services/api'
import { INIT_GLOBAL_RESPONSE } from '../../../utils/global-response'
import qs from 'querystring'
import Input from '../../../components/form/SimpleInput'
// import XLSX from 'xlsx'
import { SearchOutlined } from '@ant-design/icons'
import history from '../../../routes/history'

function compareByAlph(a: any, z: any) {
  if (a > z) {
    return -1
  }
  if (a < z) {
    return 1
  }
  return 0
}

const StepOne = ({ setStep }: any) => {
  const handleSubmit = (body: any) => {
    setStep({
      body,
      id: 1,
    })
  }

  return (
    <>
      <Form onSubmit={handleSubmit} translate={undefined}>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Select
              label='Mix de produto por:'
              name='mix_by'
              options={[
                { label: 'Rede', value: 'NETWORK' },
                { label: 'Bandeira', value: 'FLAG' },
                { label: 'Loja', value: 'PDV' },
              ]}
              placeholder='Selecione o tipo de mix'
            />
          </Col>
          <Col span={12}>
            <Input name='mix_name' label='Nome do mix' required />
          </Col>
        </Row>
        <Button style={{ marginTop: 15, float: 'right' }} type='primary' htmlType='submit'>
          Próximo
        </Button>
      </Form>
    </>
  )
}

const StepTwo = ({ bodyStep1, backStep }: any) => {
  const [dataTable, setDataTable] = useState<any>(INIT_GLOBAL_RESPONSE)
  const [dataTableProduct, setDataTableProduct] = useState<any>(INIT_GLOBAL_RESPONSE)
  const [url, setUrl] = useState<string>('')
  const [namePT, setNamePT] = useState<string>('')
  const [selecteds, setSelecteds] = useState<any>([])
  const [typeCreate, setTypeCreate] = useState<any>('1')
  const [importFile] = useState<any[]>([])
  const [importHeader] = useState<any[]>([])
  const [typeImportExcel, setTypeImportExcel] = useState<any>('')
  const [typeImportExcelProduct, setTypeImportExcelProduct] = useState<any>('')
  const [importLoading, setImportLoading] = useState<boolean>(false)
  const [loadingSubMix, setLoadingSubMix] = useState<boolean>(false)

  const formRef = useRef<FormHandles>(null)
  let columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: any, b: any) => compareByAlph(a.name, b.name),
    },
    {
      title: 'Ativo',
      dataIndex: 'is_active',
      key: 'is_active',
      width: '20%',
      sorter: (a: any, b: any) => compareByAlph(a.is_active, b.is_active),
      render: (obj: any) => (obj ? <Tag color='green'>Sim</Tag> : <Tag color='red'>Não</Tag>),
    },
  ]

  let columnsPdv = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: any, b: any) => compareByAlph(a.name, b.name),
    },
    {
      title: 'Bandeira',
      sorter: (a: any, b: any) => compareByAlph(a.flag.name, b.flag.name),
      render: (obj: any) => <p>{obj.flag.name}</p>,
    },
    {
      title: 'Rede',
      sorter: (a: any, b: any) => compareByAlph(a.flag.network.name, b.flag.network.name),
      render: (obj: any) => <p>{obj.flag.network.name}</p>,
    },
    {
      title: 'Ativo',
      dataIndex: 'is_active',
      key: 'is_active',
      width: '20%',
      sorter: (a: any, b: any) => compareByAlph(a.is_active, b.is_active),
      render: (obj: any) => (obj ? <Tag color='green'>Sim</Tag> : <Tag color='red'>Não</Tag>),
    },
  ]

  useEffect(() => {
    setSelecteds([])
    switch (bodyStep1.mix_by) {
      case 'NETWORK':
        setNamePT('Rede')
        setUrl('network')
        loadTable('network')
        break

      case 'FLAG':
        setNamePT('Bandeira')
        setUrl('flag')
        loadTable('flag')
        break

      case 'PDV':
        setNamePT('Loja')
        setUrl('pdv')
        loadTable('pdv')
        break
    }

    async function loadProducts() {
      setDataTableProduct({ ...dataTableProduct, loading: true })
      const { data } = await api.get('/product')
      setDataTableProduct({ ...data, loading: false })
    }

    loadProducts()
  }, [bodyStep1.mix_by]) // eslint-disable-line react-hooks/exhaustive-deps

  const removeSelected = (type: any, product: any) => {
    setLoadingSubMix(true)
    let newSelecte: any = []

    selecteds.forEach((element: any) => {
      if (element.sub_mix === type.id) {
        let newListProduct: any = []
        element.products.forEach((el: any) => {
          if (el !== product.id) newListProduct.push(el.id)
        })
        newSelecte.push({
          sub_mix: element.sub_mix,
          products: newListProduct,
        })
      } else {
        newSelecte.push(element)
      }
    })
    let removeTrash: any = []
    newSelecte.forEach((el: any) => {
      if (el.products.length > 0) removeTrash.push(el)
    })

    setSelecteds(removeTrash)
    setTimeout(() => {
      setLoadingSubMix(false)
    }, 500)
  }

  const addSelected = (type: any, product: any) => {
    setLoadingSubMix(true)
    let newSelecte: any = selecteds
    let haveSub = false

    selecteds.forEach((element: any) => {
      if (element.sub_mix === type.id) {
        haveSub = true
      }
    })

    newSelecte.forEach((element: any) => {
      if (element.sub_mix === type.id) {
        let newListProducts = element.products
        newListProducts.push(product.id)
        return {
          sub_mix: element.sub_mix,
          products: newListProducts,
        }
      }
    })

    if (!haveSub) {
      newSelecte.push({
        sub_mix: type.id,
        products: [product.id],
      })
    }

    setSelecteds(newSelecte)
    setTimeout(() => {
      setLoadingSubMix(false)
    }, 500)
  }

  async function loadTable(el: any) {
    const { data } = await api.get(`/${el}`)
    setDataTable({ ...data, loading: false })
  }

  const handleTable = async (queryParam: any) => {
    setDataTable({ ...dataTable, loading: true })
    const query = qs.stringify({
      perpage: queryParam.pageSize,
      page: queryParam.current,
    })
    const { data } = await api.get(`/${url}?` + query)
    setDataTable({ ...data, loading: false })
  }

  async function searchName(body: any) {
    setDataTable({ ...dataTable, loading: true })
    const { name } = body
    let filterQuery: any = {}
    if (name) filterQuery.name = name
    const queryFilter = qs.stringify({ ...filterQuery })
    const { data } = await api.get(`/${url}?${queryFilter}`)
    setDataTable({ ...data, loading: false })
  }

  const createMix = async (body: any) => {
    if (typeCreate === '1' && selecteds.length === 0) return message.error('Selecione os item da tabela')
    let data = {}
    if (typeCreate === '1') {
      data = {
        name: bodyStep1.mix_name,
        type: bodyStep1.mix_by,
        mix: body,
        type_import: 'SELECTEDS',
      }
    } else {
      if (body.index_column === body.index_column_product)
        return message.error('Selecione colunas de vinculação diferente uma da outra!')
      data = {
        ...body,
        mix: importFile,
        name: bodyStep1.mix_name,
        type: bodyStep1.mix_by,
        type_import: 'XLS',
      }
    }
    setImportLoading(true)
    try {
      await api.post('/create_mix_products', data)
      message.success('Produto cadastrado com sucesso')
      history.push('/backoffice/catalog/mix-products')
    } catch (error) {
      console.log(error)
      message.error('Erro ao realizar mix')
    }
    setImportLoading(false)
  }

  const handleSelectImport = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()

    // const files = e.target.files || []
    // const f = files[0]
    // const reader = new FileReader()

    // reader.onload = function (e: any) {
    //   var data = e.target.result
    //   let readedData = XLSX.read(data, { type: 'binary' })
    //   const wsname = readedData.SheetNames[0]
    //   const ws = readedData.Sheets[wsname]

    //   /* Convert array to json*/
    //   const dataParse: any = XLSX.utils.sheet_to_json(ws, { header: 1 })
    //   setImportFile(dataParse)
    //   setImportHeader(dataParse[0])
    // }

    // reader.readAsBinaryString(f)
  }

  async function searchNameProduct(body: any) {
    setDataTableProduct({ ...dataTableProduct, loading: true })
    const { name } = body
    let filterQuery: any = {}
    if (name) filterQuery.name = name
    const queryFilter = qs.stringify({ ...filterQuery })
    const { data } = await api.get(`/product?${queryFilter}`)
    setDataTableProduct({ ...data, loading: false })
  }

  const handleTableChangeProduct = async (queryParams: any) => {
    setDataTableProduct({ ...dataTableProduct, loading: true })

    const query = qs.stringify({
      perpage: queryParams.pageSize,
      page: queryParams.current,
    })
    const { data } = await api.get('/product?' + query)
    setDataTableProduct({ ...data, loading: false })
  }

  return (
    <Container>
      <Tabs onChange={(key: any) => setTypeCreate(key)}>
        <Tabs.TabPane tab={`Criação ${namePT}`} key='1'>
          <Form
            translate={undefined}
            ref={formRef}
            className='form'
            style={{ marginTop: '15px' }}
            onSubmit={searchName}
          >
            <Row gutter={[8, 8]}>
              <Col span={6}>
                <Input name='name' placeholder={`Nome da ${namePT}`} />
              </Col>
              {namePT === 'Loja' && (
                <>
                  <Col span={6}>
                    <Input name='flag' placeholder={`Nome da bandeira`} />
                  </Col>
                  <Col span={6}>
                    <Input name='network' placeholder={`Nome da rede`} />
                  </Col>
                </>
              )}
              <Col span={2}>
                <Button htmlType='submit' type='primary'>
                  <SearchOutlined />
                  Pesquisar
                </Button>
              </Col>
            </Row>
          </Form>
          <Table
            columns={namePT === 'Loja' ? columnsPdv : columns}
            rowKey={(item) => item.id}
            dataSource={dataTable.result}
            expandable={{
              expandedRowRender: (record) => (
                <>
                  <Form
                    translate={undefined}
                    ref={formRef}
                    className='form'
                    style={{ marginTop: '15px' }}
                    onSubmit={searchNameProduct}
                  >
                    <Row gutter={[8, 8]}>
                      <Col span={6}>
                        <Input name='name' placeholder={`Nome do produto`} />
                      </Col>
                      <Col span={2}>
                        <Button htmlType='submit' type='primary'>
                          <SearchOutlined />
                          Pesquisar
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                  <Table
                    style={{
                      padding: '25px',
                      border: '1px solid rgb(0 0 0 / 25%)',
                      boxShadow: '0px 2px 10px lightgrey',
                    }}
                    columns={[
                      {
                        title: 'Selecione',
                        width: '100px',
                        render: (obj: any) => {
                          let checked = false

                          selecteds.forEach((element: any) => {
                            if (element.sub_mix === record.id) {
                              element.products.forEach((el: any) => {
                                if (el === obj.id) checked = true
                              })
                            }
                          })

                          return (
                            <Checkbox
                              onChange={(evt) => {
                                if (evt.target.checked) return addSelected(record, obj)
                                removeSelected(record, obj)
                              }}
                              checked={checked}
                            />
                          )
                        },
                      },
                      {
                        title: 'Nome',
                        dataIndex: 'name',
                        key: 'name',
                        sorter: (a: any, b: any) => compareByAlph(a.name, b.name),
                      },
                      {
                        title: 'Ativo',
                        dataIndex: 'is_active',
                        key: 'is_active',
                        width: '20%',
                        sorter: (a: any, b: any) => compareByAlph(a.is_active, b.is_active),
                        render: (obj: any) => (obj ? <Tag color='green'>Sim</Tag> : <Tag color='red'>Não</Tag>),
                      },
                    ]}
                    rowKey={(item) => item.id}
                    dataSource={dataTableProduct.result}
                    pagination={{
                      // position: 'both',
                      current: dataTableProduct.page,
                      pageSize: dataTableProduct.perpage,
                      total: dataTableProduct.total,
                      size: 'small',
                      showSizeChanger: true,
                      showTotal: () =>
                        `Exibindo ${dataTableProduct.result.length} de ${dataTableProduct.total} de registros`,
                    }}
                    loading={loadingSubMix}
                    onChange={handleTableChangeProduct}
                  />
                </>
              ),
            }}
            pagination={{
              // position: 'both',
              current: dataTable.page,
              pageSize: dataTable.perpage,
              total: dataTable.total,
              size: 'small',
              showSizeChanger: true,
              showTotal: () => `Exibindo ${dataTable.result.length} de ${dataTable.total} de registros`,
            }}
            loading={dataTable.loading}
            onChange={handleTable}
          />
          <Button style={{ margin: 15, float: 'right' }} type='ghost' htmlType='button' onClick={() => backStep(1)}>
            Voltar
          </Button>
          <Button
            style={{ margin: 15, float: 'right' }}
            type='primary'
            htmlType='submit'
            onClick={() => createMix(selecteds)}
          >
            Criar mix
          </Button>
        </Tabs.TabPane>
        <Tabs.TabPane tab={`Importação ${namePT}`} key='2'>
          <h3>Selecione o Arquivo que deseja criar o mix:</h3>
          <input type='file' onChange={handleSelectImport} />

          <Divider />

          {importHeader.length > 0 && (
            <Form translate={undefined} onSubmit={createMix}>
              <Row gutter={[16, 10]}>
                <Col span={24}>
                  <Select
                    label={`Vincular ${namePT} por:`}
                    name='match'
                    onChange={(el: any) => setTypeImportExcel(el.value)}
                    options={[
                      { label: 'Rocket ID', value: 'id' },
                      { label: 'Código Interno', value: 'pdv_code' },
                      { label: 'Nome', value: 'name' },
                    ]}
                  />
                </Col>
                <Col span={24}>
                  <Select
                    label={`Vincular produto por:`}
                    name='match_product'
                    onChange={(el: any) => setTypeImportExcelProduct(el.value)}
                    options={[
                      { label: 'Rocket ID', value: 'id' },
                      { label: 'Código Interno', value: 'erp_code' },
                      { label: 'Nome', value: 'name' },
                    ]}
                  />
                </Col>
                <Divider />
                {typeImportExcel === 'id' && (
                  <Col span={24}>
                    <Select
                      label={`Vincular ${namePT} ao ID (Rocket)`}
                      name='index_column'
                      options={importHeader.map((h, index) => ({ label: h, value: index }))}
                    />
                  </Col>
                )}
                {typeImportExcel === 'pdv_code' && (
                  <Col span={24}>
                    <Select
                      label={`Vincular ${namePT} ao Código interno (Rocket)`}
                      name='index_column'
                      options={importHeader.map((h, index) => ({ label: h, value: index }))}
                    />
                  </Col>
                )}
                {typeImportExcel === 'name' && (
                  <Col span={24}>
                    <Select
                      label={`Vincular ${namePT} ao Nome (Rocket)`}
                      name='index_column'
                      options={importHeader.map((h, index) => ({ label: h, value: index }))}
                    />
                  </Col>
                )}
                {typeImportExcelProduct === 'id' && (
                  <Col span={24}>
                    <Select
                      label={`Vincular produto ao ID (Rocket)`}
                      name='index_column_product'
                      options={importHeader.map((h, index) => ({ label: h, value: index }))}
                    />
                  </Col>
                )}
                {typeImportExcelProduct === 'pdv_code' && (
                  <Col span={24}>
                    <Select
                      label={`Vincular produto ao Código interno (Rocket)`}
                      name='index_column_product'
                      options={importHeader.map((h, index) => ({ label: h, value: index }))}
                    />
                  </Col>
                )}
                {typeImportExcelProduct === 'name' && (
                  <Col span={24}>
                    <Select
                      label={`Vincular produto ao Nome (Rocket)`}
                      name='index_column_product'
                      options={importHeader.map((h, index) => ({ label: h, value: index }))}
                    />
                  </Col>
                )}
              </Row>
              <Button
                style={{ margin: 15, float: 'right', marginTop: 80 }}
                type='ghost'
                htmlType='button'
                onClick={() => backStep(1)}
              >
                Voltar
              </Button>
              <Button
                style={{ margin: 15, float: 'right', marginTop: 80 }}
                loading={importLoading}
                type='primary'
                htmlType='submit'
              >
                Criar mix
              </Button>
            </Form>
          )}
        </Tabs.TabPane>
      </Tabs>
    </Container>
  )
}

const MixProductCreatePage = () => {
  const [step, setStep] = useState(0)
  const [data, setData] = useState({})

  const nextStep = (valueStep: any) => {
    if (valueStep.body.mix_by === '') return message.error('Selecione o tipo de mix para continuar')
    setStep(valueStep.id)
    setData(valueStep.body)
  }

  const backStep = (back: number) => {
    setStep(step - back)
  }

  return (
    <>
      <Row gutter={[8, 8]} justify='space-between'>
        <Col span={22}>
          <h1>Mix de Produtos</h1>
          <Breadcrumb separator='>' className='breadcumb'>
            <Link to='/backoffice'>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
            </Link>
            <Link to='/backoffice/catalog'>
              <Breadcrumb.Item>Catalogo</Breadcrumb.Item>
            </Link>
            <Breadcrumb.Item>Mix de Produtos</Breadcrumb.Item>
            <Breadcrumb.Item>Criar</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row justify='center'>
        <Container style={{ width: '80%', marginTop: 20 }}>
          <div style={{ width: '100%' }}>
            <Steps size='small' current={step}>
              <Steps.Step title='Tipo de sortimento' />
              <Steps.Step title='Criação/Importação' />
            </Steps>
          </div>

          <div style={{ width: '100%', marginTop: 20 }}>
            {step === 0 && <StepOne setStep={(data: any) => nextStep(data)} />}
            {step === 1 && <StepTwo bodyStep1={data} backStep={backStep} />}
          </div>
        </Container>
      </Row>
    </>
  )
}

export default MixProductCreatePage
