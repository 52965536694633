import { Card, Carousel, Image, Typography } from 'antd'
import React, { useState } from 'react'
import { ContainerDescription } from './style'
import { BiTimeFive } from 'react-icons/bi'
import ReactLoading from 'react-loading'

export const LoadingPicture = ({ el }: any) => {
  const [loadingImg, setLoadingImg] = useState(true)
  const [erroImg, setErroImg] = useState(false)

  return (
    <>
      {erroImg ? (
        <div
          style={{
            display: 'flex',
            marginRight: 10,
            borderRadius: 7,
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: 15,
            justifyContent: 'center',
            height: 260,
            width: '100%',
            border: '1px solid orange',
          }}
        >
          <BiTimeFive size={25} color='orange' />
          <p
            style={{
              marginTop: 7,
              fontSize: 8,
              textAlign: 'center',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              color: 'orange',
              marginRight: 0,
            }}
          >
            Não sincronizada
          </p>
        </div>
      ) : (
        <>
          {loadingImg && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: 260,
                width: '100%',
              }}
            >
              <ReactLoading type='spokes' color='#b670fc' height={20} width={20} />
            </div>
          )}
          <img
            src={el}
            alt=''
            onLoad={() => setLoadingImg(false)}
            onError={() => setErroImg(true)}
            className='image-survey'
            style={{
              height: 260,
              width: '100%',
            }}
          />
        </>
      )}
    </>
  )
}

export const CardPicture = ({ data }: any) => {
  const [visible, setVisible] = useState(false)
  // const carouselRef = useRef<any>(null)

  // const handleNext = () => carouselRef.current.next()
  // const handlePrevius = () => carouselRef.current.prev()

  const getImg = (obj: any) => {
    if (typeof obj === 'object') {
      return (
        <div>
          <div
            style={{
              margin: 30,
              display: 'flex',
              borderRadius: 7,
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: 200,
              width: 180,
              border: '1px solid orange',
            }}
          >
            <BiTimeFive size={25} color='orange' />
            <p
              style={{
                marginTop: 7,
                fontSize: 8,
                textAlign: 'center',
                fontWeight: 'bold',
                textTransform: 'uppercase',
                color: 'orange',
                marginRight: 0,
              }}
            >
              Não sincronizada
            </p>
          </div>
        </div>
      )
    } else {
      return (
        <div onClick={() => setVisible(true)}>
          {/* <LoadingPicture el={obj} type={3} /> */}
          <LoadingPicture el={obj} />
          {/* <img
            alt='promoter_picture'
            src={obj}
            style={{
              height: 260,
              width: '100%',
            }}
          /> */}
        </div>
      )
    }
  }

  const getUrls = (obj: any) => {
    if (typeof obj === 'string') {
      return <Image src={obj} />
    }
  }

  return (
    <>
      <Card
        hoverable
        style={{ width: 240, height: 380 }}
        cover={
          <>
            {/* <PreviusButton onClick={handlePrevius}>
              <CaretLeftOutlined />
            </PreviusButton> */}
            <Carousel
              // autoplay
              // autoplaySpeed={5000}
              // speed={2000}
              // ref={carouselRef}
              slickGoTo={2}
            >
              {data.field_value && data.field_value.map((el: any) => <>{getImg(el)}</>)}
            </Carousel>
            {/* <NextButton onClick={handleNext}>
              <CaretRightOutlined />
            </NextButton> */}
          </>
        }
      >
        <ContainerDescription>
          <p>Promotor:</p>
          <Typography.Text ellipsis={{ tooltip: data.promoter_name }} style={{ maxWidth: 130 }}>
            <h3>{data.promoter_name}</h3>
          </Typography.Text>
        </ContainerDescription>
        <ContainerDescription>
          <p>Pdv:</p>
          <Typography.Text ellipsis={{ tooltip: data.pdv_name }} style={{ maxWidth: 130 }}>
            <h3>{data.pdv_name}</h3>
          </Typography.Text>
        </ContainerDescription>
        <ContainerDescription>
          <p>Formulário:</p>
          <Typography.Text ellipsis={{ tooltip: data.form_name }} style={{ maxWidth: 130 }}>
            <h3>{data.form_name}</h3>
          </Typography.Text>
        </ContainerDescription>
        {data.field_name && (
          <ContainerDescription>
            <p>Questão:</p>
            <Typography.Text ellipsis={{ tooltip: data.field_name }} style={{ maxWidth: 130 }}>
              <h3>{data.field_name}</h3>
            </Typography.Text>
          </ContainerDescription>
        )}
        <ContainerDescription>
          <p>Data de coleta</p>
          <Typography.Text ellipsis={{ tooltip: data.form_name }} style={{ maxWidth: 130 }}>
            <h3>{data.collected_date}</h3>
          </Typography.Text>
        </ContainerDescription>
      </Card>
      <div style={{ display: 'none' }}>
        <Image.PreviewGroup preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}>
          {data.field_value && data.field_value.map((el: any) => <>{getUrls(el)}</>)}
          {/* <Image src='https://gw.alipayobjects.com/zos/antfincdn/cV16ZqzMjW/photo-1473091540282-9b846e7965e3.webp' />
          <Image src='https://gw.alipayobjects.com/zos/antfincdn/x43I27A55%26/photo-1438109491414-7198515b166b.webp' /> */}
        </Image.PreviewGroup>
      </div>
    </>
  )
}
