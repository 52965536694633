import styled from 'styled-components'

export const Container = styled.div`
  width: 100vw;
  height: 100vh;

  &&& .ant-modal-wrap {
    z-index: 1 !important;
  }

  &&& .ant-modal-mask {
    z-index: 1 !important;
  }
`
export const Flex = styled.div`
  display: flex;
`
export const Header = styled.div`
  display: flex;
  width: 100%;
  height: 70px;
  background: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  justify-content: space-between;
  padding: 0 50px;
  align-items: center;

  button{
    background-color: #4FA9B3;
    border: none;
    width: 100px;
    height: 30px;
    color: white;
    font-weight: bold;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    border-radius: 20px;
  }

  button:hover{
    transition: all 0.3s;
    background-color: white;
    border: 2px solid #4FA9B3;
    color: #4FA9B3;
  }

  p{
    letter-spacing: 2px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 16px;
  }
`
export const IdQuestion = styled.div`
  display: flex;
  align-items: center;
  justify-content: conter;

  &&& .ant-btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px;
    margin-right: 15px;
  }

  &&& .ant-btn p {
    margin: 0;
    color: white;
  }
`
export const Menu = styled.div`
  display: flex;
  width: auto;
  height: calc(100vh - 71px);
  background: white;
  border-right: 1px solid rgba(0, 0, 0, 0.07);
`


export const Content = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 71px);
`

export const ContentBuilder = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  width: 50%;
  height: 100%;
  border-right: 1px solid rgba(0, 0, 0, 0.07);
  overflow-x: scroll;
  padding-bottom: 80px;
`

export const ContentPreview = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
  background: white;
`

export const AddQuestion = styled.div`
  padding: 20px 30px;
  cursor: pointer;
  height: 70px;
  margin-left: 90%;
  button {
    margin-right: 8px;
    background-color: #7157C1;
    border-color: #7157C1;
  }
`

export const ContainerQuestion = styled.div`
  display: flex;
  width: 100%;
  height: 80px;
  padding: 20px 30px;

  :hover {
    background: #FAFAFA;
    transition: 0.2s linear;
    cursor: pointer;
  }
`
export const FormGroup = styled.div`
  min-height: 80px;
  width: 100%;
  margin-top: 20px;
  display: flex;
  padding: 0 20px;
  justify-content: center;
  flex-direction: column;
`

