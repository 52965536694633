import React, { useEffect, useRef, useState } from 'react'
import api_v2 from '../../../services/api-v2'
import { Collapse, message, Button } from 'antd'
import { storeTaskStyle } from '../stepTask/styles'
import { getWorkspaceId } from '../../../utils/login'
import { Loading } from '../../Loading'
import {
  CommentOutlined
} from '@ant-design/icons'

interface IProps {
  next: (stage: number) => void;
  visitId: number;
  value?: any;
}

export const StepTask = ({ next, visitId, value }: IProps) => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<any>([]);

  const loadTasks = async () => {
    setLoading(true);
    try {
      const { data } = await api_v2.get(`/visit/${visitId}/tasks`);
      setList(data);
    } catch (error) {
      console.error('Error loading tasks:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadTasks();
  }, [visitId]);

  const handleCollapsePanelClick = (el: any) => {
    value(el);
    next(1);
  };

  return (
    <>
      {loading && <Loading />}
      {list &&
        list.map((form: any, index: number) => (
          <Collapse
            accordion
            expandIconPosition="right"
            key={form.name}
          >
            {form.context === 'STORE' && (
              <div style={storeTaskStyle}>
                {form.name} {form.sub_workspace_name && ` - ${form.sub_workspace_name}`}
                <CommentOutlined onClick={() => handleCollapsePanelClick(form)} style={{ marginLeft: 'auto' , paddingTop:6}} />
              </div>
              
            )}
            {form.context === 'PRODUCT' && (
              <Collapse
                accordion
                expandIconPosition="right"
                key={index}
              >
                <Collapse.Panel
                  header={form.name}
                  key={index}
                  showArrow={true}
                >
                  {form.product && form.product.length > 0 ? (
                    form.product.map((product: any, index: number) => (
                      <div key={product.id} style={{ marginBottom: '8px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: 8 }}>
                          <div>{product.name}</div>
                          <CommentOutlined onClick={() => handleCollapsePanelClick(product)} style={{ cursor: 'pointer', marginLeft: 8 }} />
                        </div>
                        {index < form.product.length - 1 && <hr style={{ margin: '8px 0', borderColor: 'transparent' }} />}
                      </div>
                    ))
                  ) : (
                    <div>-</div>
                  )}
                </Collapse.Panel>
              </Collapse>
            )}
          </Collapse>
        ))}
    </>
  );
};