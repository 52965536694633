/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react'
import {
  DownOutlined,
  LogoutOutlined,
  RetweetOutlined,
  CaretDownOutlined,
  BulbOutlined,
  EditOutlined,
  LockOutlined,
} from '@ant-design/icons'

import { HiOutlineOfficeBuilding } from 'react-icons/hi'
import { Dropdown, Menu, Drawer, Badge } from 'antd'
import LogoFullBackoffice from '../../assets/images/backoffice1.png'
import LogoFullAnalytcs from '../../assets/images/analytics2.png'
import { APP_ROCKET, getUserLocal, getWorkspace, isAuthenticatedaPP, logout, TOKEN_USER } from '../../utils/login'
import {
  Container,
  Logo,
  MyMenu,
  Workspace,
  Profile,
  ContainerMenus,
  ContainerLogo,
  LogoDrawer,
  HeaderDrawer,
  TextHeader,
  ListSubCategory,
  ContainerSubCategory,
  HeaderSubCategory,
  ContainerMenu,
  TextMenu,
  ContainerLogoImg
} from './styles'
import MenuHorizonte from './menuHorizonte'
import { workspace } from '../LayoutDefault'
import LogoWorkspace from '../../assets/images/logo-backoffice.png'
import Analytics from '../../assets/images/Analytics11.svg'
import { ModalMenu } from '../ModalMenu'
import { Link } from 'react-router-dom'
import { ModalSugestion } from '../ModalSugestion'
import api_v2 from '../../services/api-v2'
import { Loading } from '../Loading'
import history from '../../routes/history'
import Backoffice from '../../assets/images/backofficeIcon.png'
import { ModalSubWorkspace } from '../../components/ModalSubWorkspace'
import { ModalEditWorkspace } from '../../components/ModalEditWorkspace'
import MenuHorizonteAnalytcs from './menuHorizonteAnalytcs'
import { ModalPasswordUser } from '../ModalPasswordUser'
import { IoNotificationsOutline } from 'react-icons/io5'
import { MdOutlineDelete, MdOutlineMessage } from 'react-icons/md'
import { INIT_GLOBAL_RESPONSE } from '../../utils/global-response'
import qs from 'querystring'

interface IProps {
  Iworkspace?: workspace | undefined
}

const NavbarMenu: React.FC<IProps> = ({ Iworkspace }) => {
  const [openDrawer, setOpenDrawer] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openDetail, setOpenDetail] = useState(false)
  const [openSugestion, setOpenSugestion] = useState(false)
  const [openPassword, setOpenPassword] = useState(false)
  const [openEditWorkspace, setOpenEditWorkspace] = useState(false)
  const [notification, setNotification] = useState<any>({...INIT_GLOBAL_RESPONSE, totalRead: 0})
  const [openNotification, setOpenNotification] = useState<any>(false)
  const [name, setName] = useState('')
  const [type, setType] = useState('')
  const [loading, setLoading] = useState(false)
  const [listDisabled, setListDisabled] = useState<string[]>([])
  let getSharement = sessionStorage.getItem('@rocketpdv:workspace')
  let newGetWorkspace: any = {}
  if (getSharement) newGetWorkspace = JSON.parse(getSharement)

  const menu = () => (
    <Menu>
      {/* <Menu.Item key='3'>
        <Link to='/backoffice/licenses'>
          <RetweetOutlined />
          Lincenças
        </Link>
      </Menu.Item> */}
      <Menu.Item key='3'>
        <Link to='/backoffice/workspace'>
          <RetweetOutlined />
          Mudar de workspace
        </Link>
      </Menu.Item>
      <Menu.Item onClick={() => setOpenEditWorkspace(!openEditWorkspace)} key='4'>
        <EditOutlined />
        Editar workspace
      </Menu.Item>
      <Menu.Item onClick={() => setOpenSugestion(!openSugestion)} key='4'>
        <BulbOutlined />
        Sugestões
      </Menu.Item>
      {newGetWorkspace && newGetWorkspace.shared_environment && (
        <Menu.Item onClick={() => setOpenModal(!openModal)} key='4'>
          <HiOutlineOfficeBuilding />
          Sub Worksapces
        </Menu.Item>
      )}
      <Menu.Item onClick={() => setOpenPassword(!openPassword)} key='4'>
        <LockOutlined />
        Trocar senha
      </Menu.Item>
      <Menu.Item onClick={() => logout()} key='4'>
        <LogoutOutlined />
        Sair
      </Menu.Item>
    </Menu>
  )

  const tempoDecorrido = (timestamp: number): string => {
    const agora = new Date();
    const passado = new Date(timestamp);
    const diferencaMs = agora.getTime() - passado.getTime();

    const segundos = Math.floor(diferencaMs / 1000);
    const minutos = Math.floor(segundos / 60);
    const horas = Math.floor(minutos / 60);
    const dias = Math.floor(horas / 24);
    const meses = Math.floor(dias / 30); // Aproximadamente 30 dias por mês
    const anos = Math.floor(dias / 365); // Aproximadamente 365 dias por ano

    if (anos > 0) {
      return `Há ${anos} ${anos === 1 ? 'ano' : 'anos'}`;
    } else if (meses > 0) {
      return `Há ${meses} ${meses === 1 ? 'mês' : 'meses'}`;
    } else if (dias > 0) {
      return `Há ${dias} ${dias === 1 ? 'dia' : 'dias'}`;
    } else if (horas > 0) {
      return `Há ${horas} ${horas === 1 ? 'hora' : 'horas'}`;
    } else if (minutos > 0) {
      return `Há ${minutos} ${minutos === 1 ? 'minuto' : 'minutos'}`;
    } else {
      return `Há ${segundos} ${segundos === 1 ? 'segundo' : 'segundos'}`;
    }
  }

  const deleteNotification = async (item) => {
    try {
      setLoading(true)
      await api_v2.get(`/notification/delete/${item.id}`)
      await getNotification({ pageSize: 10, current: notification.page })
      setLoading(false)
    } catch (error) {
      setLoading(true)
      console.log(error)
    }
  }

  const getList = async () => {
    try {
      setLoading(true)
      let { data } = await api_v2.get(`/search/me`)
      setListDisabled(data.apps)
      setLoading(false)
    } catch (error) {
      setLoading(true)
      console.log(error)
    }
  }

  const getNotification = async (queryParam) => {
    try {
      const queryFilter = qs.stringify({
        perpage: queryParam.pageSize,
        page: queryParam.current,
      })

      let { data } = await api_v2.get(`/notification?${queryFilter}`)
      setNotification({...data, result: [...notification.result, ...data.result]})
    } catch (error) {

    }
  }

  useEffect(() => {
    getNotification({ pageSize: 10, current: 1 })
    if (getWorkspace()) getList()
    let getName = localStorage.getItem(TOKEN_USER)
    if (getName) {
      let splitName = getName.split(' ')
      if (splitName.length > 0) setName(splitName[0])
    }
  }, [])

  return (
    <Container>
      {loading && <Loading />}
      <ContainerMenus className='ocult'>
        <ContainerLogo onClick={() => setOpenDrawer(!openDrawer)}>
          <ContainerLogoImg>
            <Logo src={isAuthenticatedaPP() === '"Analitycs"' ? LogoFullAnalytcs : LogoFullBackoffice} />
            <p>{isAuthenticatedaPP() === '"Analitycs"' ? 'Analytics' : 'Backoffice'}</p>
          </ContainerLogoImg>
          <CaretDownOutlined />
        </ContainerLogo>
        {/* {Iworkspace && isAuthenticatedaPP() === '"Backoffice"' ? <ButtonMenu /> : null} */}
        {/* {Iworkspace && isAuthenticatedaPP() === '"Analitycs"' && getUserLocal() == 'RAPHAEL GARCIA' ? (
          <MenuHorizonteAnalytcs />
        ) : null} */}
      </ContainerMenus>
      {Iworkspace && isAuthenticatedaPP() === '"Backoffice"' ? <MenuHorizonte /> : null}
      {Iworkspace &&
        isAuthenticatedaPP() === '"Analitycs"' &&
        (getUserLocal() == 'RAPHAEL GARCIA' ||
        getUserLocal() == 'TI BEBIDAS DUELO' ||
        getUserLocal() == 'Rodrigo Otávio' ||
        getUserLocal() == 'Efrain Jacob' ||
        getUserLocal() == 'GREGORY DE COSTA' ||
        getUserLocal() == 'CAROLINE LADISLAU' ||
        getUserLocal() == 'Eliaquim Carvalho' ||
        getUserLocal() == 'Fábio de Oliveira Cavalcante' ||
        getUserLocal() == 'Leonardo Castro' ||
        getUserLocal() == 'LUDMILA CASTRO' ||
        getUserLocal() == 'BRITO' ||
          getUserLocal() == 'BRENDO DELDSON' ||
          getUserLocal() == 'Márcio Araújo' ||
          getUserLocal() == 'LUIZ GUSTAVO' ||
          getUserLocal() == 'MARILEIDE SANTOS' ||
          getUserLocal() == 'RAÍSSA HIESKA' ||
          getUserLocal() == 'MARCOS ANDALAFT' ||
          getUserLocal() == 'Adriel Coelho' ||
          getUserLocal() == 'Alexandro Abade' ||
          getUserLocal() == 'JEREMIAS DE SOUZA NASCIMENTO' ||
          getUserLocal() == 'GLEISON' ||
          getUserLocal() == 'ADRIEL DA SILVA BARBOSA COELHO' ||
          getUserLocal() == 'TASTING' ||
          getUserLocal() == 'MARCELO JIMENEZ') ? (
        <MenuHorizonteAnalytcs />
      ) : null}
      <div style={{ display: 'flex', gap: 15, alignItems: 'center', width: 250 }}>
        <Dropdown overlay={menu} className='ant-dropdown-link'>
          <MyMenu className='ant-dropdown-link'>
            <p style={{ width: '120px' }}>Olá, {name}</p>
            <DownOutlined />
            {Iworkspace && (
              <Workspace>
                <Profile src={Iworkspace.avatar_url} />
              </Workspace>
            )}
          </MyMenu>
        </Dropdown>
        <Badge count={notification.totalRead} size="small" >
          <IoNotificationsOutline style={{ cursor: 'pointer' }} size={20} color='#fff' onClick={async () => {
            setOpenNotification(!openNotification)
            for (const item of notification.result) {
              if(!item.read) await api_v2.get(`/notification/read/${item.id}`)
            }
          }} />
        </Badge>
      </div>
      <Drawer
        placement='right'
        closable={false}
        onClose={() => {
          setOpenNotification(!openNotification)
          getNotification({ pageSize: 10, current: 1 })
        }}
        visible={openNotification}
        key='right'
        width={461}
        bodyStyle={{ padding: 0 }}
      >
        <HeaderDrawer style={{ marginBottom: 30 }}>
          <TextHeader style={{ border: 'none' }}>Notificações</TextHeader>
        </HeaderDrawer>
        {loading && <Loading />}
        {notification.result && notification.result.map((item) => (
          <div style={{
            width: '100%',
            backgroundColor: item.read ? '#fff' : '#D3F2FF',
            padding: '24px 16px',
            display: 'flex',
            alignItems: 'center',
            gap: 24,
          }}>
            <div style={{
              backgroundColor: '#3D5F90',
              width: 60,
              height: 60,
              borderRadius: 30,
              fontSize: 22,
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#fff',
              textTransform: 'uppercase'
            }}>
              {item?.title?.slice(0, 2)}
            </div>
            <div style={{ flex: 1 }}>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%'
              }}>
                <p style={{
                  fontSize: 18,
                  color: '#3D3D3D',
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 15
                }}>
                  <MdOutlineMessage />
                  {item.title}
                </p>
                <MdOutlineDelete color="#F05252" style={{ cursor: 'pointer' }} size={20} onClick={() => deleteNotification(item)} />
              </div>
              <p style={{
                fontSize: 14,
                color: '#3D3D3D',
                display: 'flex',
                alignItems: 'center',
                gap: 15,
                marginTop: 8
              }}>
                <MdOutlineMessage />
                {item.message}
              </p>
              <p style={{
                color: '#0087FF',
                fontSize: 12,
                marginTop: 8
              }}>
                {tempoDecorrido(item.created_at)}
              </p>
            </div>
          </div>
        ))}

        {(notification.page * notification.perpage) < notification.total &&
          <p style={{
            fontSize: 14,
            color: '#3D3D3D',
            textAlign: 'center',
            cursor: 'pointer',
            height: 80,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
            onClick={() => getNotification({ pageSize: 10, current: notification.page + 1 })}
          >
            Mais notificações
          </p>
        }
      </Drawer>
      <Drawer
        placement='left'
        closable={false}
        onClose={() => setOpenDrawer(!openDrawer)}
        visible={openDrawer}
        key='left'
        width={290}
      >
        <HeaderDrawer>
          <LogoDrawer src={isAuthenticatedaPP() === '"Analitycs"' ? Analytics : LogoWorkspace} />
          <TextHeader>Escolha um dos app's abaixo.</TextHeader>
        </HeaderDrawer>
        <ContainerMenu>
          <ListSubCategory>
            <ContainerSubCategory
              disabled={listDisabled.indexOf('BACKOFFICE') > -1 ? false : true}
              active={window.location.pathname.indexOf('backoffice') > -1}
              onClick={() => {
                if (window.location.pathname.indexOf('backoffice') > -1) return
                if (listDisabled.indexOf('BACKOFFICE') > -1) {
                  localStorage.setItem(APP_ROCKET, JSON.stringify('Backoffice'))

                  history.push('/backoffice/')
                  setOpenDrawer(false)
                } else {
                  setType('analytics')
                  setOpenDetail(true)
                  setOpenDrawer(false)
                }
              }}
            >
              <HeaderSubCategory>
                <img src={Backoffice} alt='Backoffice' />
                <TextMenu>Backoffice</TextMenu>
              </HeaderSubCategory>
            </ContainerSubCategory>
            <ContainerSubCategory
              disabled={listDisabled.indexOf('ANALYTICS') > -1 ? false : true}
              active={window.location.pathname.indexOf('Analitycs') > -1}
              onClick={() => {
                if (window.location.pathname.indexOf('Analitycs') > -1) return
                if (listDisabled.indexOf('ANALYTICS') > -1) {
                  localStorage.setItem(APP_ROCKET, JSON.stringify('Analitycs'))
                  history.push('/Analitycs/')
                  setOpenDrawer(false)
                } else {
                  setType('analytics')
                  setOpenDetail(true)
                  setOpenDrawer(false)
                }
              }}
            >
              <HeaderSubCategory>
                <img src={Analytics} alt='analytics' />
                <TextMenu>Analytics</TextMenu>
              </HeaderSubCategory>
            </ContainerSubCategory>
            {/* <ContainerSubCategory
              disabled={listDisabled.indexOf('PDV Manager') > -1 ? false : true}
              onClick={() => {
                if (listDisabled.indexOf('PDV Manager') > -1) {
                  console.log(false)
                } else {
                  setType('pdv maneger')
                  setOpenDetail(true)
                  setOpenDrawer(false)
                }
              }}
            >
              <HeaderSubCategory>
                <img src={PdvManager} alt='pdv maneger' />
                <TextMenu>pdv manager</TextMenu>
              </HeaderSubCategory>
            </ContainerSubCategory> */}
            {/* <ContainerSubCategory
              disabled={listDisabled.indexOf('Supervisor') > -1 ? false : true}
              onClick={() => {
                if (listDisabled.indexOf('Supervisor') > -1) {
                  console.log(false)
                } else {
                  setType('supervisor')
                  setOpenDetail(true)
                  setOpenDrawer(false)
                }
              }}
            >
              <HeaderSubCategory>
                <img src={Supervisor} alt='supervisor' />
                <TextMenu>supervisor</TextMenu>
              </HeaderSubCategory>
            </ContainerSubCategory> */}
            {/* <ContainerSubCategory
              disabled={listDisabled.indexOf('Pipiline') > -1 ? false : true}
              onClick={() => {
                if (listDisabled.indexOf('Pipiline') > -1) {
                  console.log(false)
                } else {
                  setType('pipeline')
                  setOpenDetail(true)
                  setOpenDrawer(false)
                }
              }}
            >
              <HeaderSubCategory>
                <img src={Pipeline} alt='pipeline' />
                <TextMenu>pipeline</TextMenu>
              </HeaderSubCategory>
            </ContainerSubCategory> */}

            {/* <ContainerSubCategory
              disabled={listDisabled.indexOf('incentive') > -1 ? false : true}
              onClick={() => {
                if (listDisabled.indexOf('Pipiline') > -1) {
                  console.log(false)
                } else {
                  setType('incentive')
                  setOpenDetail(true)
                  setOpenDrawer(false)
                }
              }}
            >
              <HeaderSubCategory>
                <img src={Incentive} alt='incentive' />
                <TextMenu>incentive</TextMenu>
              </HeaderSubCategory>
            </ContainerSubCategory> */}
          </ListSubCategory>
        </ContainerMenu>
      </Drawer>
      <ModalMenu visible={openDetail} close={setOpenDetail} type={type} />
      <ModalSubWorkspace visible={openModal} close={setOpenModal} />
      <ModalEditWorkspace visible={openEditWorkspace} close={setOpenEditWorkspace} />
      {openSugestion && <ModalSugestion visible={openSugestion} close={setOpenSugestion} />}
      <ModalPasswordUser visible={openPassword} close={setOpenPassword} />
    </Container>
  )
}

export default NavbarMenu
