import { useEffect, useRef, useState } from 'react'
import { Breadcrumb, Col, Row, Table, Tag, Tooltip, DatePicker } from 'antd'
import {
  FileSearchOutlined,
  FilterOutlined,
  CloseOutlined,
  FileExcelOutlined,
  ClearOutlined,
  CloudUploadOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import { Link } from 'react-router-dom'
import qs from 'querystring'
import Select from '../../../components/form/SimpleSelect'
import { Container as ContainerRange } from '../../../components/form/SimpleRangePicker/styles'
import {
  BigButton,
  ContainerButtonsFootter,
  ContainerFormFilter,
  ContainerList,
  customStyles,
  GroupForm,
} from '../../../GlobalStyles'
import { contextFile, statusFileReader } from '../../../utils/option-user'
import { Loading } from '../../../components/Loading'
import { ButtonClear, ButtonSearch, LabelForm } from '../../Team/Promoter/style'
import { StatusInativo, StatusRealizado } from '../../../components/TabsVisits/style'
import api_v2 from '../../../services/api-v2'
import { NoSearch } from '../../../components/NoSearch'
import useReactRouter from 'use-react-router'
import history from '../../../routes/history'
import moment from 'moment'
import { Search } from '../../../components/form/SimpleSearch'
import { DrawerFilter } from '../../../components/DrawerFilter'
import { ContainerTagg } from '../../Survey/FormListPage/styles'

export const getStatus = (status: string, justify?: any) => {
  const listStatus: any = {
    PENDENT: (
      <Tooltip title='Pendente'>
        <StatusInativo style={{ backgroundColor: 'rgb(0, 136, 254)' }} />
      </Tooltip>
    ),
    PENDING: (
      <Tooltip title='Pendente'>
        <StatusInativo style={{ backgroundColor: 'rgb(0, 136, 254)' }} />
      </Tooltip>
    ),
    COMPLETE: (
      <Tooltip title='Concluída'>
        <StatusRealizado />
      </Tooltip>
    ),
    COMPLETED: (
      <Tooltip title='Concluída'>
        <StatusRealizado />
      </Tooltip>
    ),
    IN_PROGRESS: (
      <Tooltip title='Em progresso'>
        <StatusRealizado style={{ backgroundColor: 'rgb(255, 187, 40)' }} />
      </Tooltip>
    ),
    IN_PROCESS: (
      <Tooltip title='Em progresso'>
        <StatusRealizado style={{ backgroundColor: 'rgb(255, 187, 40)' }} />
      </Tooltip>
    ),
  }

  return listStatus[status] || '-'
}

const ProcessPage = () => {
  const { location } = useReactRouter()
  const [newVisible, setNewVisible] = useState<any>(false)
  const [inputData, setDate] = useState<any>([])

  const [filters, setFilters] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [filterTag, setFilterTag] = useState<any>({})
  const [urlFilter, setUrlFilter] = useState<string>('')
  const [changePage, setChangePage] = useState<any>({})

  const [process, setProcess] = useState<any>({
    pagination: {
      page: 1,
      perpage: 10,
      total: 1,
    },
    loading: true,
  })
  const formRef = useRef<FormHandles>(null)

  async function SearchProcess(body: any, { reset }: any) {
    try {
      setLoading(true)
      let { user_id, status, context } = body
      let filterQuery: any = {}
      let filterQueryUrl: any = {}
      let dataTag = {}
      if (user_id && user_id.value) {
        filterQuery.user_id = user_id.value
        dataTag = { ...dataTag, user_id }
        filterQueryUrl = { ...filterQueryUrl, user_id: JSON.stringify(user_id) }
      }

      if (status) {
        filterQuery.status = status
        dataTag = { ...dataTag, status }
        filterQueryUrl = { ...filterQueryUrl, status }
      }

      if (context) {
        filterQuery.context = context
        dataTag = { ...dataTag, context }
        filterQueryUrl = { ...filterQueryUrl, context }
      }

      if (inputData) {
        filterQueryUrl = { ...filterQueryUrl, date: JSON.stringify(inputData) }
        if (inputData[0]) {
          filterQuery.start_date = moment(inputData[0]).format('YYYY-MM-DD')
          dataTag = { ...dataTag, start_date: moment(inputData[0]).format('YYYY-MM-DD') }
        }

        if (inputData[1]) {
          filterQuery.end_date = moment(inputData[1]).format('YYYY-MM-DD')
          dataTag = { ...dataTag, end_date: moment(inputData[1]).format('YYYY-MM-DD') }
        }
      }
      const queryFilter = qs.stringify({ ...filterQuery })
      const queryFilterUrl = qs.stringify({ ...filterQueryUrl })
      setUrlFilter(queryFilter)
      const { data } = await api_v2.get(`/import-xlsx?${queryFilter}`)
      setProcess({ ...data, loading: false })
      setLoading(false)
      setFilterTag(dataTag)
      setNewVisible(false)
      history.push(`/backoffice/process?${queryFilterUrl}`)
    } catch (error) {
      setLoading(false)
    }
  }

  const cleanFilter = async () => {
    history.push(`/backoffice/process/`)
    setProcess({ ...process, loading: true })
    setNewVisible(false)
    setUrlFilter('')
    setFilterTag({})
    const { data } = await api_v2.get(`/import-xlsx`)
    setProcess({ ...data, loading: false })
    formRef!.current!.reset()
  }

  const removeFilter = async (typeRemove: string, value: string) => {
    let newUrl: any = qs.parse(urlFilter)
    let newObjTagg = filterTag
    if (typeRemove === 'user_id') {
      delete newObjTagg.user_id
      delete newUrl.user_id
    }

    if (typeRemove === 'status') {
      delete newObjTagg.status
      delete newUrl.status
    }

    if (typeRemove === 'context') {
      delete newObjTagg.context
      delete newUrl.context
    }
    if (typeRemove === 'start_date') {
      delete newObjTagg.start_date
      delete newUrl.start_date
    }
    if (typeRemove === 'end_date') {
      delete newObjTagg.end_date
      delete newUrl.end_date
    }

    const queryFilter = qs.stringify({ ...newUrl })

    setLoading(true)
    const { data } = await api_v2.get(`/import-xlsx?${queryFilter}`)
    setProcess({ ...data, loading: false })
    setUrlFilter(queryFilter)
    setFilterTag(newObjTagg)
    setLoading(false)
    history.push(`/backoffice/process?${queryFilter}`)
  }

  const handleTableChange = async (queryParams: any) => {
    setProcess({ ...process, loading: true })

    let newObj = {
      perpage: queryParams.pageSize,
      page: queryParams.current,
    }
    const query = qs.stringify(newObj)
    setChangePage(queryParams)
    const { data } = await api_v2.get(`/import-xlsx?${query}${urlFilter ? '&' + urlFilter : ''}`)
    setProcess({ ...data, loading: false })
  }

  let types: any = {
    "VISIT": "Visita",
    "PROMOTER": "Promotor",
    // "COLLABORATOR": "Promotor avançado",
    "PDV": "PDV",
    "MIX_CATEGORY": "Mix de categoria",
    "FLAG": "Bandeira",
    "MIX_PRODUCT": "Mix de produto",
    "REMOVAL":"Afastamento",
    "PRODUCT": "Produto",
    "MIX_BRAND": "Mix de marca",
    "MIX_FORM": "Mix de formulário",
    "EDIT_VISIT": "Edição de visita",
    "SCRIPT": "Roteiro"
  }

  const columns: any = [
    {
      title: '#',
      dataIndex: 'status',
      key: 'status',
      width: 60,
      render: (a: any) => <p>{getStatus(a)}</p>,
    },
    {
      title: 'Contexto',
      dataIndex: 'context',
      key: 'context',
      render: (a: any, b: any) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p>
            {types[a]}
            {b.config && b.config.schedule && b.config.date && ` agendado para ${moment(b.config.date).format('DD/MM/YYYY')}`}
          </p>
        </div>
      ),
    },
    {
      title: 'Nome do usuário',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Nome do arquivo',
      dataIndex: 'file_name',
      key: 'file_name',
    },
    {
      title: 'URL',
      dataIndex: 'file_path',
      key: 'file_path',
      ellipsis: true,
      width: 150,
      render: (a: any, b: any) => (
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Tooltip placement='top' title={'Arquivo enviado'}>
            <FileExcelOutlined
              style={{ cursor: 'pointer', fontSize: 20, color: '#00C49F' }}
              onClick={() => window.open(a, '_blank')}
            />
          </Tooltip>
          {b.file_callback_path !== null ? (
            <Tooltip placement='top' title={'Arquivo reprovado'}>
              <FileExcelOutlined
                style={{ cursor: 'pointer', fontSize: 20, color: '#F75C4C' }}
                onClick={() => window.open(b.file_callback_path, '_blank')}
              />
            </Tooltip>
          ) : (
            '-'
          )}
        </div>
      ),
    },
    {
      title: 'Data de criação',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (a: any) => <p>{moment(a).format('DD/MM/YYYY HH:mm:ss')}</p>,
    },
    {
      title: 'Início / Fim',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (a: any, b: any) => (
        <div>
          {b.init_process && (
            <Tooltip placement='top' title={'Início processamento'}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CloudUploadOutlined style={{ fontSize: 17, marginRight: 10, color: '#6e55cd' }} />
                {moment(b.init_process).format('DD/MM/YYYY HH:mm:ss')}
              </div>
            </Tooltip>
          )}
          {b.end_process && (
            <Tooltip placement='top' title={'Fim do processamento'}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CheckCircleOutlined style={{ fontSize: 17, marginRight: 10, color: '#00C49F' }} />
                {moment(b.end_process).format('DD/MM/YYYY HH:mm:ss')}
              </div>
            </Tooltip>
          )}
        </div>
      ),
    },
  ]

  const convertUrl = () => {
    let obj: any = qs.parse(location.search.replaceAll('?', ''))
    if (obj && obj.user_id) obj.user_id = JSON.parse(obj.user_id)
    if (obj && obj.date) obj.date = JSON.parse(obj.date)

    return obj
  }

  useEffect(() => {
    SearchProcess(convertUrl(), {})
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const timer = setInterval(() => handleTableChange(changePage), 10000)
    return () => {
      clearInterval(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [process])

  return (
    <ContainerList>
      <Row gutter={[8, 8]} justify='space-between'>
        <Col span={8}>
          <h1>Importação XLSX</h1>
          <Breadcrumb separator='>' className='breadcumb'>
            <Link to='/backoffice'>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
            </Link>
            <Breadcrumb.Item>Importação XLSX</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col>
          <Tooltip placement='top' title={'Filtrar'}>
            <BigButton onClick={() => setNewVisible(!newVisible)} style={{ padding: 9 }}>
              <FilterOutlined />
            </BigButton>
          </Tooltip>
        </Col>
        <Col span={24}>
          <ContainerTagg>
            {filterTag?.user_id && (
              <Tag color='geekblue' onClick={() => removeFilter('user_id', `${filterTag?.user_id.value}`)}>
                {filterTag?.user_id.label}
                <CloseOutlined />
              </Tag>
            )}

            {filterTag.pdv_id && (
              <Tag color='geekblue' onClick={() => removeFilter('pdv_id', `${filterTag.pdv_id.value}`)}>
                {filterTag.pdv_id.label}
                <CloseOutlined />
              </Tag>
            )}
            {filterTag.dt_visit && (
              <Tag color='geekblue' onClick={() => removeFilter('dt_visit', `${filterTag.dt_visit}`)}>
                {filterTag.dt_visit}
                <CloseOutlined />
              </Tag>
            )}
            {filterTag.brand_id && (
              <Tag color='geekblue' onClick={() => removeFilter('brand_id', `${filterTag.brand_id.value}`)}>
                {filterTag.brand_id.label}
                <CloseOutlined />
              </Tag>
            )}
            {filterTag.network_id && (
              <Tag color='geekblue' onClick={() => removeFilter('network_id', `${filterTag.network_id.value}`)}>
                {filterTag.network_id.label}
                <CloseOutlined />
              </Tag>
            )}
            {filterTag.status && (
              <Tag color='geekblue' onClick={() => removeFilter('status', `${filterTag.status}`)}>
                {filterTag.status === 'PENDENT'
                  ? 'Pendente'
                  : filterTag.status === 'COMPLETE'
                  ? 'Completo'
                  : filterTag.status === 'IN_PROGRESS'
                  ? 'Em progresso'
                  : ''}

                <CloseOutlined />
              </Tag>
            )}
            {filterTag.context && (
              <Tag color='geekblue' onClick={() => removeFilter('context', `${filterTag.context}`)}>
                {filterTag.context === 'VISIT'
                  ? 'Visita'
                  : filterTag.context === 'PROMOTER'
                  ? 'Promotor'
                  : filterTag.context === 'MIX_FORM'
                  ? 'Mix de Formulário'
                  : filterTag.context === 'SCRIPT'
                  ? 'Roteiro'
                  : filterTag.context === 'MIX_PRODUCT'
                  ? 'Mix de Produtos'
                  : filterTag.context === 'PRODUCT'
                  ? 'Produto'
                  : filterTag.context === 'PROMOTER'
                  ? 'Promotor'
                  : filterTag.context === 'PDV'
                  ? 'PDV'
                  : ''}

                <CloseOutlined />
              </Tag>
            )}
            {filterTag.start_date && (
              <Tag color='geekblue' onClick={() => removeFilter('start_date', `${filterTag.start_date}`)}>
                {moment(filterTag.start_date).format('DD/MM/YYYY')}
                <CloseOutlined />
              </Tag>
            )}
            {filterTag.end_date && (
              <Tag color='geekblue' onClick={() => removeFilter('end_date', `${filterTag.end_date}`)}>
                {moment(filterTag.end_date).format('DD/MM/YYYY')}
                <CloseOutlined />
              </Tag>
            )}
          </ContainerTagg>
        </Col>
      </Row>
      {process.result && process.result.length > 0 ? (
        <Table
          rowKey='id'
          dataSource={process.result}
          columns={columns}
          onChange={handleTableChange}
          loading={process.loading || false}
          pagination={{
            current: process.page,
            pageSize: process.perpage,
            total: process.total,
            size: 'small',
            showSizeChanger: true,
            showTotal: () => `Exibindo ${process.result.length} de ${process.total} de registros`,
          }}
        />
      ) : (
        <NoSearch text='Não encontramos nenhum dado aqui.' />
      )}

      {loading ? <Loading /> : null}

      <DrawerFilter
        visible={newVisible}
        close={setNewVisible}
        title='Visitas'
        footer={
          <ContainerButtonsFootter>
            <ButtonSearch type='button' onClick={() => formRef?.current?.submitForm()}>
              <FileSearchOutlined />
              Pesquisar
            </ButtonSearch>
            <ButtonClear onClick={() => cleanFilter()}>
              <ClearOutlined />
              Limpar
            </ButtonClear>
          </ContainerButtonsFootter>
        }
      >
        <ContainerFormFilter
          tabIndex={0}
          onKeyDown={(evt: any) => {
            if (evt.key === 'Enter') formRef?.current?.submitForm()
          }}
        >
          <Form ref={formRef} className='form' style={{ marginTop: '15px' }} onSubmit={SearchProcess}>
            <GroupForm>
              <LabelForm>Selecione o usuário</LabelForm>
              <Search placeholder='Selecione o usuário' name='user_id' path='gestors' isMult={false} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione o contexto</LabelForm>
              <Select
                styles={customStyles}
                className='pre-select'
                placeholder='Selecione o contexto'
                options={contextFile}
                name='context'
                defaultValue={filters && filters.context ? filters.context : ''}
                onChange={(value: any) => {
                  if (value && value.value)
                    setFilters({
                      ...filters,
                      context: value.value,
                    })
                }}
              />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione o status</LabelForm>
              <Select
                styles={customStyles}
                className='pre-select'
                placeholder='Selecione o status'
                options={statusFileReader}
                name='status'
                defaultValue={filters && filters.status ? filters.status : ''}
                onChange={(value: any) => {
                  if (value && value.value)
                    setFilters({
                      ...filters,
                      status: value.value,
                    })
                }}
              />
            </GroupForm>

            <ContainerRange>
              <LabelForm>Selecione um período</LabelForm>

              <DatePicker.RangePicker
                value={inputData}
                allowClear={true}
                format='YYYY-MM-DD'
                onChange={(val) => setDate(val)}
                defaultValue={inputData}
              />
            </ContainerRange>
          </Form>
        </ContainerFormFilter>
      </DrawerFilter>
    </ContainerList>
  )
}

export default ProcessPage
