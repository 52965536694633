import React, { useEffect, useState } from 'react'
import { Container, TextError, Title } from './styles'
import { Input } from 'antd'
interface IQuestion {
  id: number
  is_required: boolean
  name: string
  order: string
  type: string
  fields_true?: any
}
interface IDataQuestion {
  dataQuestion: IQuestion
  handleAnswers: (value: any, dataQuestion: IQuestion) => void
  error?: boolean
  checkRequired: (value: any) => void
}

export const TextMass = ({ dataQuestion, handleAnswers, checkRequired, error }: IDataQuestion) => {
  const [value, setValue] = useState('')
  const [isRender, setIsRender] = useState(false)

  const handleValue = (value: string) => {
    setValue(value)
    handleAnswers([value], dataQuestion)
    if (dataQuestion.is_required) checkRequired(dataQuestion.id)
  }

  useEffect(() => {
    if (dataQuestion.is_required && !isRender) {
      setIsRender(true)
      checkRequired(dataQuestion.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <Title>
        {dataQuestion.order} - {dataQuestion.name} {dataQuestion.is_required && '*'}
      </Title>
      <Input value={value} onChange={(nextValue) => handleValue(nextValue.target.value)} style={{ width: '100%' }} />
      {error && !value && <TextError>Campo obrigatório</TextError>}
    </Container>
  )
}
