import React, { useState } from 'react'
import {
  TitleMultiple,
  Description,
  ContainerQuestionMultiple,
} from '../style'
import { Checkbox } from 'antd'
import ViewQuestion from '..'
import ViewPicture from './viewPicture'

const radioStyle = {
  display: 'block',
  height: '60px',
  lineHeight: '30px',
  width: '100%',
  borderRadius: '15px',
  border: '2px solid #7157C1',
  marginBottom: '30px',
  paddingTop: '12px',
  paddingLeft: '10px',
  backgroundColor: 'rgb(113, 87, 193, 0.1)',
}
const MultipleChoiceQuestion: React.FC<any> = ({ quest }) => {
  const [value, setValue] = useState<any>([])

  const getIndex = (e: any) => {
    let newValues: any = []

    quest.options.forEach((el: any, index: number) => {
      e.forEach((val: any) => {
        if (val === el) newValues.push(index)
      })
    });

    setValue(newValues)
  }

  return (
    <>
      <ContainerQuestionMultiple>
        <TitleMultiple>
          <h1>
            {quest.is_required ? '*' : null} {quest.order} →{' '}
          </h1>{' '}
          {quest.name}
        </TitleMultiple>
        {quest.description ? <Description>{quest.description}</Description> : null}
        {quest.picture && typeof quest.picture == 'string' &&  <ViewPicture picture={quest.picture} />}
        <Checkbox.Group onChange={(e) => getIndex(e)} style={{ width: '100%' }}>
          {quest.options &&
            quest.options.map((option: any) => (
              <Checkbox style={radioStyle} value={option}>
                {option}
              </Checkbox>
            ))}
        </Checkbox.Group>
      </ContainerQuestionMultiple>
      {quest && quest.fields && Object.keys(quest.fields).length > 0 && value && value.length > 0 &&
        <>
          {value.map((el: any) => (
            <>
              {quest && quest.fields && quest.fields[el] && quest.fields[el].subFields && quest.fields[el].subFields.length > 0 && <ViewQuestion questions={quest.fields[el].subFields} />}
            </>
          ))}
        </>
      }
    </>
  )
}

export default MultipleChoiceQuestion
