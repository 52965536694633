import React, { useEffect, useState } from 'react'
// import api from '../../../services/api'
import { useParams } from 'react-router-dom'
import { Container } from './style'
import { Button, Checkbox, Col, Form, Input, message, Row } from 'antd'
import { DaysJorney } from '../../../components/DaysJorney'
import api_v2 from '../../../services/api-v2'
import history from '../../../routes/history'
import { Loading } from '../../../components/Loading'
import moment from 'moment'

const ProfileCreatePage: React.FC = () => {
  const { id }: any = useParams()
  const [form] = Form.useForm()
  const [copy, setCopy] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const [defaultValue, setDefaultValue] = useState<any>({})

  const onFinish = async (values: any) => {
    try {
      let body: any = {}
      Object.keys(values).forEach((el: any) => {
        if (values[el] && values[el]._isValid) {
          body[el] = values[el].format('HH:mm')
        } else {
          body[el] = values[el]
        }
      })

      let sucess
      if (id) {
        const { data } = await api_v2.put(`/jorney/edit/${id}`, body)
        sucess = data
      } else {
        const { data } = await api_v2.post('/jorney/create', body)
        sucess = data
      }
      if (sucess) {
        message.success(`Sucesso ao ${id ? 'editar' : 'criar'} intrajornada`)
        history.push('/backoffice/team/jorney')
      } else {
        message.error(`Erro ao  ${id ? 'editar' : 'criar'} intrajornada`)
      }
    } catch (error) {
      message.error(`Erro ao  ${id ? 'editar' : 'criar'} intrajornada`)
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    message.error(`Erro ao  ${id ? 'editar' : 'criar'} intrajornada`)
    console.log('Failed:', errorInfo)
  }

  const getData = async () => {
    try {
      setLoading(true)
      const { data } = await api_v2.get(`/jorney/${id}`)
      console.log(data, 'data')
      if (data && data.length > 0) {
        delete data[0].workspace_id
        delete data[0].created_at
        delete data[0].updated_at
        delete data[0].id

        let createObj: any = {}
        Object.keys(data[0]).forEach((el: any) => {
          if (data[0][el] && typeof data[0][el] === 'string' && data[0][el].indexOf(':') > -1) {
            createObj[el] = moment(data[0][el], 'HH:mm')
          } else {
            createObj[el] = data[0][el]
          }
        })

        console.log(createObj)
        setDefaultValue(createObj)
        form.setFieldsValue(createObj)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (id) getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <Container>
      <h1 style={{ marginBottom: 30 }}>{id ? 'Editar' : 'Criar'} jornada</h1>
      {loading && <Loading />}
      <Form
        name='basic'
        initialValues={defaultValue}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        form={form}
      >
        <Row gutter={[20, 20]}>
          <Col md={8}>
            <Form.Item
              label={<p style={{ color: '#637282' }}>Nome</p>}
              name='name'
              rules={[{ required: true, message: 'Nome é orbigatório' }]}
              style={{ display: 'block' }}
            >
              <Input placeholder='Nome da intrajornada' />
            </Form.Item>
          </Col>
          <Col md={6} style={{ alignItems: 'center', justifyContent: 'flex-end' }}>
            <Form.Item
              name='active'
              rules={[{ required: false }]}
              valuePropName='checked'
              style={{ alignItems: 'center', justifyContent: 'flex-end', margin: 0, height: '100%' }}
            >
              <div
                style={{
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  display: 'flex',
                }}
              >
                <Checkbox onChange={(evt) => setDefaultValue({...defaultValue, active: evt.target.checked})} checked={id && defaultValue.active ? true : false}>Ativo</Checkbox>
              </div>
            </Form.Item>
          </Col>
          <DaysJorney
            defaultValue={defaultValue}
            copy={copy}
            setCopy={setCopy}
            form={form}
            name='Segunda-feira'
            nameProps='monday'
          />
          <DaysJorney
            defaultValue={defaultValue}
            copy={copy}
            setCopy={setCopy}
            form={form}
            name='Terça-feira'
            nameProps='tuesday'
          />
          <DaysJorney
            defaultValue={defaultValue}
            copy={copy}
            setCopy={setCopy}
            form={form}
            name='Quarta-feira'
            nameProps='wednesday'
          />
          <DaysJorney
            defaultValue={defaultValue}
            copy={copy}
            setCopy={setCopy}
            form={form}
            name='Quinta-feira'
            nameProps='thursday'
          />
          <DaysJorney
            defaultValue={defaultValue}
            copy={copy}
            setCopy={setCopy}
            form={form}
            name='Sexta-feira'
            nameProps='friday'
          />
          <DaysJorney
            defaultValue={defaultValue}
            copy={copy}
            setCopy={setCopy}
            form={form}
            name='Sábado'
            nameProps='saturday'
          />
          <DaysJorney
            defaultValue={defaultValue}
            copy={copy}
            setCopy={setCopy}
            form={form}
            name='Domingo'
            nameProps='sunday'
          />
          <Col md={24}>
            <Form.Item labelCol={{ span: 24 }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Button
                  type='primary'
                  htmlType='submit'
                  style={{ width: 250, height: 50, borderRadius: 20, fontSize: 20 }}
                >
                  {id ? 'Editar' : 'Criar'}
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Container>
  )
}

export default ProfileCreatePage
