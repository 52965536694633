import React, { useEffect, useState } from 'react'
import { Menu } from 'antd'
import { ContainerMenuH } from './styles'
import { workspace } from '../LayoutDefault'
import { Link } from 'react-router-dom'
import useReactRouter from 'use-react-router'
import { ContextUpload } from '../../context/contextUpload'
import { hasIndex } from './menuHorizonte'
interface IProps {
  Iworkspace?: workspace | undefined
}

const MenuHorizonteAnalytcs: React.FC<IProps> = ({ Iworkspace }) => {
  const { location } = useReactRouter()
  const { permissions } = ContextUpload()
  const [state, setState] = useState<any>('')

  useEffect(() => {
    let rota = location.pathname
    let divider = rota.split('/')

    if (rota !== '/' && rota !== '/backoffice/' && divider.length > 0) {
      setState(divider[2])
    } else {
      setState('/')
    }
  }, [location.pathname])

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
      <ContainerMenuH>
        <Menu selectedKeys={[state]} mode='horizontal'>
          {hasIndex([
            "dash.list_dash"
          ], permissions) > -1 &&
            <Menu.Item key='/'>
              <Link to='/Analitycs'>
                <div style={{ display: 'flex', alignItems: 'center' }}>Dashboard</div>
              </Link>
            </Menu.Item>
          }
          {hasIndex([
            "time_and_move.view"
          ], permissions) > -1 &&
            <Menu.Item key='move'>
              <Link to='/Analitycs/move'>
                <div style={{ display: 'flex', alignItems: 'center' }}>Tempo e movimento</div>
              </Link>
            </Menu.Item>
          }
          {hasIndex([
            "attendance.view"
          ], permissions) > -1 &&
            <Menu.Item key='team'>
              <Link to='/Analitycs/team/detail'>
                <div style={{ display: 'flex', alignItems: 'center' }}>Atendimento</div>
              </Link>
            </Menu.Item>
          }
          {hasIndex([
            "justify.view"
          ], permissions) > -1 &&
            <Menu.Item key='justify'>
              <Link to='/Analitycs/justify'>
                <div style={{ display: 'flex', alignItems: 'center' }}>Justificativas</div>
              </Link>
            </Menu.Item>
          }
          {hasIndex([
            "visits_panel.view"
          ], permissions) > -1 &&
            <Menu.Item key='visits'>
              <Link to='/Analitycs/visits'>
                <div style={{ display: 'flex', alignItems: 'center' }}>Painel de visitas</div>
              </Link>
            </Menu.Item>
          }
          {hasIndex([
            "time_compare.view"
          ], permissions) > -1 &&
            <Menu.Item key='timeComparate'>
              <Link to='/Analitycs/timeComparate'>
                <div style={{ display: 'flex', alignItems: 'center' }}>Comparativo de tempo</div>
              </Link>
            </Menu.Item>
          }
        </Menu>
      </ContainerMenuH>
    </div>
  )
}

export default MenuHorizonteAnalytcs
