import styled from 'styled-components'
import { Modal, Upload } from 'antd'

export const ContainerModal = styled(Modal)`
  top: 20 !important;

  h3 {
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: #8f61e2;
  }

  &&& .ant-modal {
    top: 20 !important;
  }

  &&& .ant-modal-content {
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  }

  &&& .ant-modal-body {
    border-radius: 12px;
  }

  &&& .ant-modal-close-x {
    color: red;
  }

  &&& .ant-upload.ant-upload-drag {
    width: auto !important;
    height: auto !important;
    padding: 28px 5px;
  }
`
export const ContainerStep1 = styled.div`
  display: flex;
  width: 650px;
  height: 350px;
  align-items: center;
  justify-content: center;
`

export const ContainerUpload = styled.div`
  &&& .ant-btn {
    width: 100% !important;
  }
`

export const CardCreate = styled.div`
  width: 292px;
  height: 316px;
  border-radius: 12px;
  border: 1px solid #d6d6d6;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 36px 26px 56px;

  img {
    width: 60px;
    height: 60px;
  }

  h4 {
    color: #242424;
    font-family: 'Open Sans';
    font-size: 20px;
    font-weight: 600;
  }

  p {
    margin-bottom: 0;
    color: #666666;
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 400;
    text-align: center;
  }

  i {
    color: #6f7782;
    font-family: 'Open Sans';
    font-size: 16px;
    font-style: italic;
    text-align: center;
    width: 100%;
  }
`

export const CardCreateUpload = styled(Upload.Dragger)`
  width: 292px;
  height: 316px;
  border-radius: 12px;
  border: 1px solid #d6d6d6;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 36px 26px 56px;

  img {
    width: 60px;
    height: 60px;
  }

  h4 {
    color: #242424;
    font-family: 'Open Sans';
    font-size: 20px;
    font-weight: 600;
  }

  p {
    margin-bottom: 0;
    color: #666666;
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 400;
    text-align: center;
  }

  i {
    color: #6f7782;
    font-family: 'Open Sans';
    font-size: 16px;
    font-style: italic;
    text-align: center;
    width: 100%;
  }
`

export const Button = styled.button`
  cursor: pointer;
  padding: 7px;
  border-radius: 5px;
  background-color: #6600cc;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: 400;
  border: none;

  svg {
    font-size: 20px;
  }
`

export const ContainerStep2 = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  img {
    width: 92px;
    height: 92px;
  }

  h3 {
    color: #242424;
    font-family: 'Open Sans';
    font-size: 22px;
    font-weight: 700;
  }

  input {
    background-color: #f7f7f7;
  }

  &&& .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    min-height: 38px !important;
    background-color: #f7f7f7;
  }
`

export const LabelCheck = styled.div`
  text-align: center;
  font-weight: bold;
  margin: 10px 0;
`

export const InputCheckbox = styled.div`
  label,
  span {
    display: flex;
    margin: 0 auto;
  }
`
