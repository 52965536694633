import { useField } from '@unform/core'
import React, { useEffect, useRef } from 'react'
import { Container, Error, Label } from './styles'
interface Props {
  name: string
  label?: string
}
type InputProps = JSX.IntrinsicElements['input'] & Props
const Input: React.FC<InputProps> = ({ name, label, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const { fieldName, defaultValue, registerField, error } = useField(name)
  useEffect(() => {
    registerField({
      name: fieldName,
      path: 'value',
      ref: inputRef.current,
    })
  }, [fieldName, registerField])


  useEffect(() => {
    console.log(error)
  }, [error])
  return (
    <Container>
      <Label htmlFor={fieldName}>
        {error && <Error>{error}</Error>}
        <input id={fieldName} ref={inputRef} defaultValue={defaultValue} {...rest} />
        <span> {label} </span>
      </Label>
    </Container>
  )
}
export default Input
