import { useEffect, useState } from 'react'
import { Container, ContainerHeader, Name, ContainerStatus } from './style'

const CardStoreSurvey: React.FC<any> = ({data, active}) => {
  const [name, setName] = useState('')
  useEffect(() => {
    if(data.pdv.name.split(' - ')[1] !== undefined){
       setName(data.pdv.name.split(' - ')[1].split(' ')[0])
    }else{
      setName(data.pdv.name.split(' ')[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Container style={{backgroundColor: active ? '#ebebeb' : '#fff'}}>
      <ContainerHeader>
        <img src={`https://via.placeholder.com/150/ff872c/fff/?text=${name}`} alt='user' />
        <ContainerStatus>
          <Name>{data.pdv.name}</Name>
          {/* <Name>Formulário de display</Name> */}
          <Name>
            Respondido em: <b>{data.check_in}</b>
          </Name>
        </ContainerStatus>
      </ContainerHeader>
    </Container>
  )
}

export default CardStoreSurvey
