import styled from 'styled-components'

export const Container = styled.div`
  &&& .ant-collapse-borderless > .ant-collapse-item {
    margin-bottom: 15px;
  }
  &&& .ant-collapse-header {
    background: linear-gradient(90deg, #6600cc 0%, #b56aff 100%) !important;
    border-radius: 5px;
    color: #fff;
  }

  &&& .ant-collapse-content-box {
    padding-top: 15px !important;
  }

  &&& .ant-collapse-borderless {
    background-color: transparent !important;
  }

  &&& .ant-collapse-item {
    border: none !important;
  }
`

export const ContainerCheckAll = styled.div`
  /* width: 200px; */
  height: 22px;
  color: #fff;
  z-index: 99999999999;

  &&& .ant-checkbox-wrapper {
    color: #fff;
  }
`

export const ContainerCheckBox = styled.div`
  min-height: 75px;
  height: 100%;
  width: 100%;
  border: 1px solid #6e55cc;
  padding: 10px;
  border-radius: 5px;

  &&& .ant-checkbox-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
  }

  &&& .ant-checkbox {
    margin-bottom: 7px;
  }
`
