import styled from 'styled-components'
export const Root = styled.div`
  background-color: lightblue;
  box-sizing: border-box;
  padding: ${8 * 2}px;
  min-height: 100vh;

  /* flexbox */
  display: flex;
  flex-direction: column;
`

export const ContainerGraph = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const H3Italic = styled.div`
  cursor: pointer;
  text-decoration: underline;
  text-align: center;
  width: 100%;
  color: #a7a7a7;
  font-style: italic;
`

export const ContainerIconGraph = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  right: 40px;
  top: 20px;

  svg {
    padding: 3px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 23px;
  }

  svg:hover {
    transition: all 0.3s;
    background-color: #b468fe;
    color: #fff;
  }

  &&& .ant-btn-icon-only {
    box-shadow: none;
    border: none;
    background: transparent;
  }
`

export const ContainerExpandGraph = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`

export const GraphContainerExpan = styled.div`
  width: 50%;
  height: 50%;
  background-color: #e8e8e8;

  display: flex;
  align-items: center;
  justify-content: center;
`
