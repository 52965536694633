import styled from 'styled-components'

export const ContainerModal = styled.div`
    max-width: 100%;
    max-height: 95%;
    overflow-x: auto !important;
    padding: 25px;
    margin-top: 30px;

    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #b56aff;
        border: 3px solid #b56aff;
        border-radius: 5px;
    }

`

export const ContainerHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 13px;

    img{
        margin-right: 13px;
        width: 40px;
        height: 40px;
        border-radius: 20px;
    }
`

export const ContainerStatus = styled.div``

export const Name = styled.p`
    color: #242424;
    font-family: "Open Sans";
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 0;
    margin-right: 10px;
`

export const Active = styled.div`
    padding: 1px 3px;
    border-radius: 4px;
    max-width: 100px;
    border: 1px solid #16c266;
    background-color: rgba(184, 255, 217, 0.5);
    color: #0b9c4e;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    align-self: baseline;
    margin-top: 9px;
`

export const ContainerData = styled.div`
    width: 100%;
    max-width: 1053px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const ContainerInfo = styled.div`
    color: #6f7782;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
    display: flex;
    align-items: center;

    svg {
        color:  #6600cc;
        font-size: 25px;
        margin-right: 9px;
    }

    p {
        color: #6600cc;
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 0;
        margin-right: 9px;
    }
`

export const ContainerAction = styled.div`
    display: flex;
    align-items: center;

    p {
        color: #6600cc;
        font-family: "Open Sans";
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0;
        margin-right: 15px;
    }

    img {
        margin-right: 10px;
        width: 30px;
        height: 30px;
        cursor: pointer;
    }

    &&& .tooltip {
        color: #272d3b !important;
        margin: 0;
    }
`

export const ButtonBack = styled.button`
    width: 102px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #d6d6d6;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: #6f7782;
    font-family: "Open Sans";
    font-size: 16px;
    margin-left: 10px;
    background-color: transparent;
`

export const ContainerSurvey = styled.div`
    width: 100%;

    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #b56aff;
        border: 3px solid #b56aff;
        border-radius: 5px;
    }


    &&& .ant-collapse{
        border-radius: 12px !important;
    }

    &&& .ant-collapse > .ant-collapse-item:last-child, .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header{
        border-radius: 12px;
        background-color: #6600cc;
        color: #fff;
    }
`

export const ContainerInfoGeral = styled.div`
    width: 100%;
    border-radius: 12px;
    border: 1px solid #d6d6d6;
    padding: 17px 32px;

    &&& .ant-modal {
        bottom: 5vh !important;
    }
`

export const StrongText = styled.p`
    color: #272d3b;
    font-family: "Open Sans";
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
    text-transform: uppercase;
`

export const ContainerGroupInfo = styled.div`
    width: 100%;
    height: 100%;
`

export const ContainerDataInfo = styled.div`
    display: flex;
    align-items: center;
`

export const LightText = styled.div`
    color: #6f7782;
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 400;
    margin-left: 7px;
    display: flex;
    align-items: center;
`

export const ContainerDateSearch = styled.div`
    border-radius: 12px;
    border: 2px solid #eeddff;
    background-color: #faf5ff;
    padding: 22px 27px;
`

export const ContainerImg = styled.div`
    display: flex;
    align-items: center;
    max-width: 100%;
    overflow-y: scroll;
    padding: 15px;
    
    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #b56aff;
        border: 3px solid #b56aff;
        border-radius: 5px;
    }

    img {
        width: 90px;
        height: 163px;
        border-radius: 3px;
        cursor: pointer;
        margin-right: 20px;
    }
`