import styled from 'styled-components'

export const IdQuestion = styled.div`
  display: flex;
  align-items: center;
  justify-content: conter;

  &&& .ant-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px;
    margin-right: 15px;
  }

  &&& .ant-btn p {
    margin: 0;
    color: white;
  }
`

export const AddQuestion = styled.div`
  padding: 20px 30px;
  cursor: pointer;
  height: 70px;
  margin-left: 90%;
  button {
    margin-right: 8px;
    background-color: #7157c1;
    border-color: #7157c1;
  }
`
