import { useField } from '@unform/core'
import React, { useEffect, useRef } from 'react'
import { Container, Error, Label } from './styles'
interface Props {
  name: string
  label?: string
}

type InputProps = JSX.IntrinsicElements['textarea'] & Props
const TextArea: React.FC<InputProps> = ({ name, label, ...rest }) => {
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const { fieldName, defaultValue, registerField, error } = useField(name)
  useEffect(() => {
    registerField({
      name: fieldName,
      path: 'value',
      ref: inputRef.current,
    })
  }, [fieldName, registerField])
  return (
    <Container style={{width: '100%'}}>
      <Label htmlFor={fieldName}>
        {error && <Error>{error}</Error>}
        <textarea id={fieldName} ref={inputRef} defaultValue={defaultValue} {...rest} />
        <span> {label} </span>
      </Label>
    </Container>
  )
}
export default TextArea
