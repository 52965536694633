import styled from 'styled-components'
import { Select } from 'antd'

export const CPSelect = styled(Select)`
  min-height: 38px;
  height: auto !important;
  background-color: #fff;
`

export const Error = styled.span`
  color: #ff4d4f;
  font-weight: bold;
`

export const Container = styled.div`
  width: 100%;

  &&& .ant-select-show-search {
    min-height: 38px !important;
    height: auto !important;
    background-color: #fff !important;
  }

  &&& .ant-select-selector {
    height: auto !important;
    background-color: #fff !important;
  }

  &&& .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: auto !important;
  }
`

export const ContainerOption = styled.div`
  display: flex;
  align-items: center;
`

interface IStatus {
  is_active: boolean
}

export const StatusOptions = styled.div<IStatus>`
  width: 14px;
  margin-right: 7px;
  height: 14px;
  border-radius: 50%;
  background-color: ${({ is_active }) => (is_active === true ? '#00C49F' : '#FFBB28')};
`

export const Label = styled.label`
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  color: #6f7782;
  font-family: 'Open Sans';
  font-size: 15px;
  font-weight: 400;
  > span:first-child {
    color: #ff4d4f;
    line-height: 1.3;
    width: 100%;
    border-radius: 4px;
    transform: none;
    animation: fadeIn 350ms ease-in-out 1;
    @keyframes fadeIn {
      from {
        transform: translateY(-20px);
        opacity: 0;
      }
      to {
        transform: translateY(0);
        opacity: 1;
      }
    }
    + input,
    select {
      border-color: #ff4d4f;
      + svg {
        fill: #ff4d4f;
      }
    }
  }

  input {
    border-radius: 12px;
    border: 1px solid #d6d6d6;
    padding: 5px;
  }
`
