import ReactLoading from 'react-loading'
import { Container } from './style'

export const Loading: React.FC<any> = ({ text }) => {
  return (
    <Container>
      <ReactLoading type={'spokes'} color={'#fff'} height={200} width={100} />
      {text && (
        <div style={{ fontSize: 20, fontWeight: 'bold', textTransform: 'uppercase', color: '#fff' }}>{text}</div>
      )}
    </Container>
  )
}
