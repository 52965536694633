import styled from 'styled-components'

export const CardStatus = styled.div`
    margin-bottom: 30px;
    width: 100%;
    padding: 23px 23px 33px;
    border-radius: 12px;
    border: 1px dashed #b56aff;
    background-color: #ebebeb;

    &&& .ant-table-thead > tr > th {
        color: #272d3b;
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: 700;
    }

    &&& .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
        display: none;
    }

    &&& .ant-table-thead > tr > th {
        border-bottom-width: 3px;
    }

    &&& .ant-table{
        padding: 0 23px;
        border-radius: 12px;
    }

    &&& .ant-pagination{
        display: none;
    }


    &&& .ant-collapse {
        background-color: transparent !important;
        border: none !important;
        border-radius: none !important;
        border-bottom: none !important;
    }

    &&& .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        padding: 0;
    }

    &&& .ant-collapse > .ant-collapse-item {
        border-bottom:  none !important;
    }
`

export const TextType = styled.p`
    color: #272d3b;
    font-family: "Open Sans";
    font-size: 20px;
    font-weight: 600;
`

export const ContainerStatus = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #cccccc;
    padding-bottom: 10px;
    margin-bottom: 20px;
    width: 100%;
`

export const Count = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-color: #b56aff;
    color: #ffffff;
    font-family: "Open Sans";
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const CircleStatus = styled.div`
    width: 15px;
    height: 15px;
    border-radius: 7px;
    background-color: #16c266;
`

export const ImgUser = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-left: 40px;
`

export const ContainerUser = styled.div`
    display: flex;
    align-items: center;

    p{
        margin: 0;
        padding: 0;
    }

    svg{
        color: #AF7CE3;
        font-size: 20px;
        margin-right: 10px;
    }
`