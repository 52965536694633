import {
  CloudDownloadOutlined,
  FileSearchOutlined,
  FilterOutlined,
  ClearOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { Breadcrumb, Button, Col, Row, Tag, Tooltip } from 'antd'
import qs from 'querystring'
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { BigButton, ContainerButtonsFootter, ContainerFormFilter, GroupForm } from '../../../GlobalStyles'
import history from '../../../routes/history'
import api from '../../../services/api'
import { Loading } from '../../../components/Loading'
import { ButtonClear, ButtonSearch, LabelForm } from '../../Team/Promoter/style'
import { ContainerTagg } from '../../Survey/FormListPage/styles'
import useReactRouter from 'use-react-router'
import { NoSearch } from '../../../components/NoSearch'
import api_v2 from '../../../services/api-v2'
import { DrawerFilter } from '../../../components/DrawerFilter'
import moment from 'moment'
import { ContainerFullGraph } from '../../Analytcs/move/style'
import { TableAnalytcs } from '../../../components_analytcs/Table'
import SimpleRangePicker from '../../../components/form/SimpleRangePicker'
import { ContainerPorcent } from '../../../components_analytcs/Table/style'
import { CirculeStatus, ContainerStatus } from '../../Analytcs/justify/style'
import { exportXls } from '../../../utils/funcoes'
import SimpleSelectObject from '../../../components/form/SimpleSelectObject'
import { Search as SearchInput } from '../../../components/form/SimpleSearch'

export interface ProductFilter {
  name?: string
  brand?: string
  product_type?: string
  category?: string
  is_active?: any
  sub_workspaces?: number
}

const PlannedPage = () => {
  const [dataTable, setDataTable] = useState<any>([])
  const [visible, setVisible] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [filterTag, setFilterTag] = useState<any>({})
  const formRef = useRef<FormHandles>(null)
  const { location } = useReactRouter()
  let getSharement = sessionStorage.getItem('@rocketpdv:workspace')
  let newGetWorkspace: any = {}
  if (getSharement) newGetWorkspace = JSON.parse(getSharement)

  async function searchPlanned(body: any, { reset }: any) {
    const { date, city, state, sub_workspaces, promoter_id, pdv_id } = body
    setLoading(true)

    let filters: any = {
      filterQuery: {},
      dataTag: {},
      filterQueryUrl: {},
    }

    if (pdv_id && pdv_id.value) {
      filters.filterQuery.pdv_id = pdv_id.value
      filters.dataTag = { ...filters.dataTag, pdv_id }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, pdv_id: JSON.stringify(pdv_id) }
    }

    if (promoter_id && promoter_id.value) {
      filters.filterQuery.promoter_id = promoter_id.value
      filters.dataTag = { ...filters.dataTag, promoter_id }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, promoter_id: JSON.stringify(promoter_id) }
    }

    if (city && city.value) {
      filters.filterQuery.city = city.value
      filters.dataTag = { ...filters.dataTag, city }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, city: JSON.stringify(city) }
    }

    if (state) {
      filters.filterQuery.state = state.value
      filters.dataTag = { ...filters.dataTag, state }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, state: JSON.stringify(state) }
    }

    if (date) {
      filters.filterQueryUrl = { ...filters.filterQueryUrl, date: JSON.stringify(date) }
      if (date[0]) {
        filters.filterQuery.start_date = date[0]
        filters.dataTag = { ...filters.dataTag, start_date: date[0] }
      }

      if (date[1]) {
        filters.filterQuery.end_date = date[1]
        filters.dataTag = { ...filters.dataTag, end_date: date[1] }
      }
    } else {
      filters.filterQuery = {
        ...filters.filterQuery,
        end_date: moment().format('YYYY-MM-DD'),
        start_date: moment().format('YYYY-MM-DD'),
      }
      filters.dataTag = {
        ...filters.dataTag,
        end_date: moment().format('YYYY-MM-DD'),
        start_date: moment().format('YYYY-MM-DD'),
      }
    }
    if (sub_workspaces && sub_workspaces.value) {
      filters.filterQuery.sub_workspaces = sub_workspaces.value
      filters.dataTag = { ...filters.dataTag, sub_workspaces }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, sub_workspaces: JSON.stringify(sub_workspaces) }
    }

    const queryFilter = qs.stringify({ ...filters.filterQuery })

    const { data } = await api_v2.get(`/kanban/promoter-visits-resume?${queryFilter}`)
    setDataTable(data)
    setLoading(false)
    setFilterTag(filters.dataTag)
    setVisible(false)
    history.push(`/backoffice/monitory/planned?${qs.stringify({ ...filters.filterQueryUrl })}`)
  }

  const cleanFilter = async () => {
    history.push(`/backoffice/monitory/planned`)
    setDataTable({ ...dataTable, loading: true })
    setVisible(false)
    setFilterTag({})
    formRef!.current!.reset()
    const { data } = await api.get(`/form`)
    setDataTable(data)
  }

  const convertUrl = () => {
    let obj: any = qs.parse(location.search.replaceAll('?', ''))
    if (obj && obj.promoter_id) obj.promoter_id = JSON.parse(obj.promoter_id)
    if (obj && obj.pdv_id) obj.pdv_id = JSON.parse(obj.pdv_id)
    if (obj && obj.brand_id) obj.brand_id = JSON.parse(obj.brand_id)
    if (obj && obj.network_id) obj.network_id = JSON.parse(obj.network_id)
    if (obj && obj.city) obj.city = JSON.parse(obj.city)
    if (obj && obj.state) obj.state = JSON.parse(obj.state)
    if (obj && obj.date) obj.date = JSON.parse(obj.date)
    if (!obj || !obj.date) obj.date = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
    if (obj && obj.sub_workspaces) obj.sub_workspaces = JSON.parse(obj.sub_workspaces)


    return obj
  }

  const removeFilter = async (typeRemove: any) => {
    let obj: any = qs.parse(location.search.replaceAll('?', ''))
    delete obj[typeRemove]
    formRef.current?.reset()

    const { city, state, date, sub_workspaces, promoter_id, pdv_id } = obj
    setLoading(true)

    let filters: any = {
      filterQuery: {},
      dataTag: {},
      filterQueryUrl: {},
    }

    if (date) {
      let d = JSON.parse(date)
      filters.filterQueryUrl = { ...filters.filterQueryUrl, date: JSON.stringify(d) }
      if (d[0]) {
        filters.filterQuery.start_date = d[0]
        filters.dataTag = { ...filters.dataTag, start_date: d[0] }
      }

      if (d[1]) {
        filters.filterQuery.end_date = d[1]
        filters.dataTag = { ...filters.dataTag, end_date: d[1] }
      }
    }

    if (city) {
      const c = typeof city === 'string' ? JSON.parse(city) : city
      filters.filterQuery.city = c.value
      filters.dataTag = { ...filters.dataTag, c }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, city: JSON.stringify(c) }
    }

    if (promoter_id) {
      const c = typeof promoter_id === 'string' ? JSON.parse(promoter_id) : promoter_id
      filters.filterQuery.promoter_id = c.value
      filters.dataTag = { ...filters.dataTag, c }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, promoter_id: JSON.stringify(c) }
    }

    if (pdv_id) {
      const c = typeof pdv_id === 'string' ? JSON.parse(pdv_id) : pdv_id
      filters.filterQuery.pdv_id = c.value
      filters.dataTag = { ...filters.dataTag, c }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, pdv_id: JSON.stringify(c) }
    }

    if (state) {
      const s = typeof state === 'string' ? JSON.parse(state) : state

      filters.filterQuery.state = s.value
      filters.dataTag = { ...filters.dataTag, s }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, state: JSON.stringify(s) }
    }
    if (sub_workspaces) {
      const c = typeof sub_workspaces === 'string' ? JSON.parse(sub_workspaces) : sub_workspaces
      filters.filterQuery.sub_workspaces = c.value
      filters.dataTag = { ...filters.dataTag, c }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, sub_workspaces: JSON.stringify(c) }
    }

    const queryFilter = qs.stringify({ ...filters.filterQuery })

    const { data } = await api_v2.get(`/kanban/promoter-visits-resume?${queryFilter}`)
    setDataTable(data)
    setLoading(false)
    setFilterTag(filters.dataTag)
    setVisible(false)
    history.push(`/backoffice/monitory/planned?${qs.stringify({ ...filters.filterQueryUrl })}`)
  }

  useEffect(() => {
    searchPlanned(convertUrl(), {})
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  const objExportXls = () => {
    const { date, sub_workspaces, promoter_id, pdv_id }: any = qs.parse(location.search.replaceAll('?', ''))
    let [start_date, end_date] = JSON.parse(date)

    let filterObj: any = {
      start_date,
      end_date,
    }

    if (sub_workspaces) filterObj.sub_workspace = JSON.parse(sub_workspaces).value
    if (promoter_id) filterObj.promoter_id = JSON.parse(promoter_id).value
    if (pdv_id) filterObj.pdv_id = JSON.parse(pdv_id).value

    return filterObj
  }

  const column: any = [
    {
      title: 'Promotor',
      dataIndex: 'name',
      key: 'name',
      width: 150,
      render: (a: any, b: any) => (
        <Link to={`/backoffice/teamById/${b.user_id}`} style={{ color: '#85868b', width: '100%', height: '100%' }}>
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'left',
              flexDirection: 'column',
            }}
          >
            {a}
            <i style={{ fontSize: 11 }}>{b.sync_last_change !== null && b.sync_last_change}</i>
          </div>
        </Link>
      ),
    },
    {
      title: 'Planejado',
      dataIndex: 'plan',
      key: 'plan',
      width: 60,
      align: 'center',
      render: (a: any, b: any) => {
        let obj: any = {
          promoter_id: JSON.stringify({
            label: b.name,
            value: b.user_id,
          }),
          date: JSON.stringify([filterTag.start_date, filterTag.end_date]),
        }
        return (
          <Link
            to={`/backoffice/survey/visits?${qs.stringify(obj)}`}
            style={{ color: '#85868b', width: '100%', height: '100%' }}
          >
            <div
              style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              {a}
            </div>
          </Link>
        )
      },
    },
    {
      title: 'Concluído',
      dataIndex: 'complete',
      width: 60,
      key: 'complete',
      align: 'center',
      render: (a: any, b: any) => {
        let obj: any = {
          promoter_id: JSON.stringify({
            label: b.name,
            value: b.user_id,
          }),
          date: JSON.stringify([filterTag.start_date, filterTag.end_date]),
        }
        return (
          <Link
            to={`/backoffice/survey/visits?${qs.stringify(obj)}&status=COMPLETE`}
            style={{ color: '#85868b', width: '100%', height: '100%' }}
          >
            <div
              style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              {a}
            </div>
          </Link>
        )
      },
    },
    {
      title: 'Justificado',
      dataIndex: 'justify',
      width: 60,
      key: 'justify',
      align: 'center',
      render: (a: any, b: any) => {
        let obj: any = {
          promoter_id: JSON.stringify({
            label: b.name,
            value: b.user_id,
          }),
          date: JSON.stringify([filterTag.start_date, filterTag.end_date]),
        }
        return (
          <Link
            to={`/backoffice/survey/visits?${qs.stringify(obj)}&status=JUSTIFIED_ABSENCE`}
            style={{ color: '#85868b', width: '100%', height: '100%' }}
          >
            <div
              style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              {a}
            </div>
          </Link>
        )
      },
    },
    {
      title: 'Pendente',
      dataIndex: 'pendent',
      width: 60,
      key: 'pendent',
      align: 'center',
      render: (a: any, b: any) => {
        let obj: any = {
          promoter_id: JSON.stringify({
            label: b.name,
            value: b.user_id,
          }),
          date: JSON.stringify([filterTag.start_date, filterTag.end_date]),
        }
        return (
          <Link
            to={`/backoffice/survey/visits?${qs.stringify(obj)}&status=PENDENT`}
            style={{ color: '#85868b', width: '100%', height: '100%' }}
          >
            <div
              style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              {a}
            </div>
          </Link>
        )
      },
    },
    {
      title: 'Em Progresso',
      dataIndex: 'in_progress',
      width: 60,
      key: 'in_progress',
      align: 'center',
      render: (a: any, b: any) => {
        let obj: any = {
          promoter_id: JSON.stringify({
            label: b.name,
            value: b.user_id,
          }),
          date: JSON.stringify([filterTag.start_date, filterTag.end_date]),
        }
        return (
          <Link
            to={`/backoffice/survey/visits?${qs.stringify(obj)}&status=IN_PROGRESS`}
            style={{ color: '#85868b', width: '100%', height: '100%' }}
          >
            <div
              style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              {a}
            </div>
          </Link>
        )
      },
    },
    {
      title: 'Assiduidade',
      dataIndex: 'assiduity',
      width: 60,
      key: 'assiduity',
      align: 'center',
      render: (a: any) => (
        <ContainerPorcent
          color={
            a <= 49 || !a
              ? 'rgba(255, 128, 66, 0.4)'
              : a >= 50 && a <= 79
                ? 'rgba(255, 187, 40, 0.4)'
                : 'rgba(0, 196, 159, 0.4)'
          }
          percent={a}
        >
          <ContainerStatus>
            <CirculeStatus
              color={
                a <= 49 || !a
                  ? 'rgba(255, 128, 66, 1)'
                  : a >= 50 && a <= 79
                    ? 'rgba(255, 187, 40, 1)'
                    : 'rgba(0, 196, 159, 1)'
              }
            />
            {a} %
          </ContainerStatus>
        </ContainerPorcent>
      ),
    },
    {
      title: 'Atendimento',
      dataIndex: 'attendance',
      width: 60,
      key: 'attendance',
      align: 'center',
      render: (a: any) => (
        <ContainerPorcent
          color={
            a <= 49 || !a
              ? 'rgba(255, 128, 66, 0.4)'
              : a >= 50 && a <= 79
                ? 'rgba(255, 187, 40, 0.4)'
                : 'rgba(0, 196, 159, 0.4)'
          }
          percent={a}
        >
          <ContainerStatus>
            <CirculeStatus
              color={
                a <= 49 || !a
                  ? 'rgba(255, 128, 66, 1)'
                  : a >= 50 && a <= 79
                    ? 'rgba(255, 187, 40, 1)'
                    : 'rgba(0, 196, 159, 1)'
              }
            />
            {a} %
          </ContainerStatus>
        </ContainerPorcent>
      ),
    },
  ]

  return (
    <div style={{ padding: '0 10px' }}>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Row gutter={[8, 8]} justify='space-between'>
            <Col span={16}>
              <h1>Planejado X Realizado</h1>
              <Breadcrumb separator='>' className='breadcumb'>
                <Link to='/backoffice'>
                  <Breadcrumb.Item>Home</Breadcrumb.Item>
                </Link>
                <Breadcrumb.Item>Planejado X Realizado</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col>
              <Button
                type='primary'
                icon={<CloudDownloadOutlined />}
                onClick={async () => {
                  setLoading(true)
                  await exportXls(objExportXls(), newGetWorkspace.shared_environment ? 'visits-resume2' : 'visits-resume')
                  setLoading(false)
                }}
              >
                Exportar
              </Button>
            </Col>
            {newGetWorkspace.shared_environment &&
            
              <Col>
                <Button
                  type='primary'
                  icon={<CloudDownloadOutlined />}
                  onClick={async () => {
                    setLoading(true)
                    await exportXls(objExportXls(), 'visits-resume-sub-workspace')
                    setLoading(false)
                  }}
                >
                  Relatório de empresas
                </Button>
              </Col>
            }
            <Col>
              <Tooltip placement='top' title={'Filtrar'}>
                <BigButton onClick={() => setVisible(!visible)} style={{ padding: 9 }}>
                  <FilterOutlined />
                </BigButton>
              </Tooltip>
            </Col>
            <Col span={24}>
              <ContainerTagg>
                {filterTag.start_date && (
                  <Tag color='geekblue'>{moment(filterTag.start_date).format('DD/MM/YYYY')}</Tag>
                )}
                {filterTag && filterTag.city && (
                  <Tag color='geekblue' onClick={() => removeFilter('city')}>
                    {filterTag.city.label}
                    <CloseOutlined />
                  </Tag>
                )}

                {filterTag && filterTag.promoter_id && (
                  <Tag color='geekblue' onClick={() => removeFilter('promoter_id')}>
                    {filterTag.promoter_id.label}
                    <CloseOutlined />
                  </Tag>
                )}

                {filterTag && filterTag.pdv_id && (
                  <Tag color='geekblue' onClick={() => removeFilter('pdv_id')}>
                    {filterTag.pdv_id.label}
                    <CloseOutlined />
                  </Tag>
                )}


                {filterTag && filterTag.state && (
                  <Tag color='geekblue' onClick={() => removeFilter('state')}>
                    {filterTag.state.label}
                    <CloseOutlined />
                  </Tag>
                )}
                {filterTag && filterTag.sub_workspaces && (
                  <Tag color='geekblue' onClick={() => removeFilter('sub_workspaces')}>
                    {filterTag.sub_workspaces.label}
                    <CloseOutlined />
                  </Tag>
                )}
                {filterTag.end_date && <Tag color='geekblue'>{moment(filterTag.end_date).format('DD/MM/YYYY')}</Tag>}

              </ContainerTagg>
            </Col>
          </Row>
          {loading ? (
            <Loading />
          ) : (
            <>
              {dataTable && dataTable.length > 0 ? (
                <Row>
                  <Col span={24}>
                    <ContainerFullGraph>
                      <TableAnalytcs
                        columns={column}
                        data={dataTable}
                        pagination={false}
                        fullY={true}
                        noScrool={true}
                      />
                    </ContainerFullGraph>
                  </Col>
                </Row>
              ) : (
                <NoSearch text='Não encontramos nenhum dado aqui.' />
              )}
            </>
          )}
        </>
      )}

      <DrawerFilter
        visible={visible}
        close={setVisible}
        title='Planejados'
        footer={
          <ContainerButtonsFootter>
            <ButtonSearch type='button' onClick={() => formRef?.current?.submitForm()}>
              <FileSearchOutlined />
              Pesquisar
            </ButtonSearch>
            <ButtonClear onClick={() => cleanFilter()}>
              <ClearOutlined />
              Limpar
            </ButtonClear>
          </ContainerButtonsFootter>
        }
      >
        <ContainerFormFilter
          tabIndex={0}
          onKeyDown={(evt: any) => {
            if (evt.key === 'Enter') formRef?.current?.submitForm()
          }}
        >
          <Form ref={formRef} className='form' style={{ marginTop: '15px' }} onSubmit={searchPlanned}>
            <GroupForm>
              <LabelForm>Selecione a cidade</LabelForm>
              <SearchInput placeholder='Selecione a cidade' name='city' path='city' isMult={false} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione o estado</LabelForm>
              <SimpleSelectObject
                placeholder='Selecione o estado'
                name='state'
                isMult={false}
                options={[
                  { value: 'AC', label: 'AC' },
                  { value: 'AL', label: 'AL' },
                  { value: 'AP', label: 'AP' },
                  { value: 'AM', label: 'AM' },
                  { value: 'BA', label: 'BA' },
                  { value: 'CE', label: 'CE' },
                  { value: 'DF', label: 'DF' },
                  { value: 'ES', label: 'ES' },
                  { value: 'GO', label: 'GO' },
                  { value: 'MA', label: 'MA' },
                  { value: 'MT', label: 'MT' },
                  { value: 'MS', label: 'MS' },
                  { value: 'MG', label: 'MG' },
                  { value: 'PA', label: 'PA' },
                  { value: 'PB', label: 'PB' },
                  { value: 'PR', label: 'PR' },
                  { value: 'PE', label: 'PE' },
                  { value: 'PI', label: 'PI' },
                  { value: 'RJ', label: 'RJ' },
                  { value: 'RN', label: 'RN' },
                  { value: 'RS', label: 'RS' },
                  { value: 'RO', label: 'RO' },
                  { value: 'RR', label: 'RR' },
                  { value: 'SC', label: 'SC' },
                  { value: 'SP', label: 'SP' },
                  { value: 'SE', label: 'SE' },
                  { value: 'TO', label: 'TO' },
                ]}
              />
            </GroupForm>
            <SimpleRangePicker name='date' label='Selecione o periodo:' />
            {newGetWorkspace.shared_environment ? (
              <GroupForm style={{ marginTop: 10 }}>
                <LabelForm>Selecione a empresa</LabelForm>
                <SearchInput placeholder='Selecione a empresa' name='sub_workspaces' path='sub-workspaces' isMult={false} />
              </GroupForm>
            ) : null}
            <GroupForm style={{ marginTop: 10 }}>
              <LabelForm>Selecione o promotor</LabelForm>
              <SearchInput placeholder='Selecione o promotor' name='promoter_id' path='promoters' isMult={false} />
            </GroupForm>
            <GroupForm style={{ marginTop: 10 }}>
              <LabelForm>Selecione o pdv</LabelForm>
              <SearchInput placeholder='Selecione o pdv' name='pdv_id' path='pdvs' isMult={false} />
            </GroupForm>
          </Form>
        </ContainerFormFilter>
      </DrawerFilter>
    </div>
  )
}

export default PlannedPage
