import { Check, Form } from '@rocketseat/unform'
import { Breadcrumb, Button, Col, message, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import Input from '../../../components/form/Input'
import { Container } from '../../../GlobalStyles'
import history from '../../../routes/history'
import api from '../../../services/api'
import { Loading } from '../../../components/Loading'
import { IGlobalResponse, INIT_GLOBAL_RESPONSE } from '../../../utils/global-response'
import { Mixpanel } from '../../../utils/mixpanel'

interface Regional {
  id: string
}

interface editRegional extends IGlobalResponse {
  result: Regional[]
}

const schema = Yup.object().shape({
  name: Yup.string().required('* Informe o nome da regional'),
})
const RegionalEditPage: React.FC = () => {
  const [loading, setLoading] = useState<any>(false)
  const [regionalEdit, setRegionalEdit] = useState<editRegional>(INIT_GLOBAL_RESPONSE)
  const { id }: any = useParams()

  useEffect(() => {
    async function loadRegional() {
      setLoading(true)
      const { data } = await api.get(`/regional/${id}`)

      setRegionalEdit({ ...data, loading: false })
      setLoading(false)
    }
    loadRegional()
  }, [id])

  async function handleSubmit(data: any) {
    setLoading(true)
    try {
      await api.put(`/regional/${id}`, {
        ...data,
      })

      Mixpanel.track('Editou regional', {
        params: {
          id: id,
          ...data,
        },
      })
      message.success('As informações foram atualizadas')
      history.push('/store/regionals')
    } catch (err) {
      message.error('Verique as informações')
    }
    setLoading(false)
  }

  return (
    <Container>
      {loading ? <Loading /> : null}
      <h1>Atualizar as informações da regional</h1>
      <p>Insira os dados corretamente fazer a atualização.</p>
      <Breadcrumb separator='>' className='breadcumb'>
        <Link to='/store/regionals'>
          <Breadcrumb.Item> Regionais </Breadcrumb.Item>
        </Link>
        <Breadcrumb.Item> Edição de regional </Breadcrumb.Item>
      </Breadcrumb>
      <Form onSubmit={handleSubmit} className='form' initialData={regionalEdit} schema={schema}>
        <Row gutter={8}>
          <Col span={20}>
            <Input name='name' type='text' placeholder='Informe o nome completo' label='Nome da regional' />
          </Col>
          <Col span={2} className='checkbox'>
            <Check name='is_active' label='Ativo' />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button type='primary' htmlType='submit'>
              Atualizar
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  )
}
export default RegionalEditPage
