import styled from 'styled-components'

export const Label = styled.label`
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  color: #6f7782;
  font-family: 'Open Sans';
  font-size: 15px;
  font-weight: 400;
  > span:first-child {
    color: #e0042d;
    font-size: 0.9rem;
    font-weight: 500;
    /* margin-top: 15px; */
    line-height: 1.3;
    width: 100%;
    border-radius: 4px;
    transform: none;
    animation: fadeIn 350ms ease-in-out 1;
    @keyframes fadeIn {
      from {
        transform: translateY(-20px);
        opacity: 0;
      }
      to {
        transform: translateY(0);
        opacity: 1;
      }
    }
    + input,
    select {
      border-color: #e0042d;
      + svg {
        fill: #e0042d;
      }
    }
  }
  > input,
  select {
    transition: 180ms ease-in-out;
    :focus {
      box-shadow: none;
    }
    + span {
      margin-bottom: 0.1rem;
    }
  }
  > button {
    position: absolute;
    padding: 0;
    right: 1.6rem;
    top: 4.5rem;
    z-index: 2;
    :hover {
      transform: scale(1.1);
    }
  }

  input{
    border-radius: 12px;
    border: 1px solid #d6d6d6;
    padding:5px;
  }
  
`
