import React from 'react'
import {
  ContainerQuestion,
  TitleDATE,
  Description,
} from '../style'
import { DatePicker, Form } from 'antd'
import moment from 'moment'
import 'moment/locale/pt-br'
import locale from 'antd/es/date-picker/locale/pt_BR'
import ViewPicture from './viewPicture'

const DateQuestion: React.FC<any> = ({ quest }) => {

  return (
    <>
      <ContainerQuestion>
        <TitleDATE>
          <h1>
            {quest.is_required ? '*' : null} {quest.order} →{' '}
          </h1>{' '}
          {quest.name}
        </TitleDATE>
        {quest.description ? <Description>{quest.description}</Description> : null}
        {quest.picture && typeof quest.picture == 'string' &&  <ViewPicture picture={quest.picture} />}
        <Form.Item name='DATE' label='Data' rules={[{ required: quest.is_required ? true : false }]}>
          <DatePicker locale={locale} defaultValue={moment(new Date())} format='DD/MM/YYYY' style={{ width: '100%' }} />
        </Form.Item>
      </ContainerQuestion>
    </>
  )
}

export default DateQuestion
