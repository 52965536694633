import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { Breadcrumb, Button, Cascader, Checkbox, Col, message, Row } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { MdClose, MdFileUpload } from 'react-icons/md'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import Input from '../../../components/form/SimpleInput'
import Select from '../../../components/form/SimpleSelect'
import { Container, customStyles, Label } from '../../../GlobalStyles'
import history from '../../../routes/history'
import api from '../../../services/api'
import { Loading } from '../../../components/Loading'
import { Mixpanel } from '../../../utils/mixpanel'
import { optionsProductType } from '../../../utils/option-user'
import { InputCheckbox, LabelCheck, MySpan, UploadImage } from './styles'
import api_v2 from '../../../services/api-v2'
import { Search } from '../../../components/form/SimpleSearch'
import InputNumberDefault from '../../../components/form/InputNumberDefault'

const schema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  category: Yup.string().required('Selecione a categoria'),
  brand: Yup.string().required('Marca é obrigatório'),
  product_type: Yup.string().required('Selecione o tipo de produto'),
})

const ProductCreatePage: React.FC = () => {
  const [checked, setChecked] = useState(true)
  const formRef = useRef<FormHandles>(null)
  const [categories, setCategories] = useState()
  const [loading, setLoading] = useState<boolean>(false)
  const [categorySelected, setCategorySelected] = useState()
  const [listWorkspaces, setListWorkspaces] = useState()
  const [otherEan, setOtherEan] = useState<any>([null])
  const [image, setImage] = useState({ preview: '', raw: '' })
  let getWorkspace = sessionStorage.getItem('@rocketpdv:workspace')
  let newGetWorkspace = JSON.parse(getWorkspace!.toString())

  useEffect(() => {


    async function loadWorkspaces() {
      setLoading(true)
      const { data } = await api_v2.get(`workspace-sub?perpage=1000&page=1`)
      const result = data.result.map((item: any) => ({
        value: item.id,
        label: item.name,
      }))
      setLoading(false)
      setListWorkspaces(result)
    }

    async function loadCategories() {
      const result = await getCategories('0')
      setCategories(result)
    }

    loadWorkspaces()
    loadCategories()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  async function getCategories(id: string) {
    setLoading(true)
    const { data } = await api.get('category/' + id)
    const result = data.categories.map((item: any) => ({
      value: item.id,
      label: item.name,
      isLeaf: item.count_childrens === 0,
    }))
    setLoading(false)
    return result
  }

  const handleChange = (e: any) => {
    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0],
    })
  }

  async function handleSubmit(body: any) {
    if(newGetWorkspace.shared_environment && !body.sub_workspace) {
      message.error('Empresa é obrigatório!!')
      return
    }
    setLoading(true)
    try {
      if(body?.brand?.value) body.brand = body.brand.value
      await schema.validate(body, {
        abortEarly: false,
      })
      const formData = new FormData()
      formData.append('image_url', image.raw)
      let { data } = await api.post('/upload-image', formData)
      await api_v2.post('/product', {
        ...body,
        ean_others: otherEan,
        is_active: checked,
        image_url: data.filename,
        image_name: data.name,
      })
      Mixpanel.track('Criou produto', {
        params: {
          ...body,
          ean_others: otherEan,
          is_active: checked,
          image_url: data.filename,
          image_name: data.name,
        },
      })
      message.success('Produto cadastrado com sucesso')
      history.push('/backoffice/catalog/products')
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((el: any) => {
          formRef.current!.setFieldError(el.path, el.message)
        })
      } else {
        message.error('Já existe um registro com essas informações!')
      }
    }
    setLoading(false)
  }

  async function loadData(selectedOptions: any) {
    const targetOption = selectedOptions[selectedOptions.length - 1]
    targetOption.loading = true
    const result = await getCategories(targetOption.value)
    targetOption.loading = false
    targetOption.children = result
    setCategories(categories)
  }

  const addOtherEan = () => {
    setOtherEan([...otherEan, ''])
  }

  const handleInput = (event: any, index: any) => {
    otherEan[index] = event.target.value
    setOtherEan(otherEan)
  }

  const DeleteEan = (index: any) => {
    otherEan.splice(index, 1)
    setOtherEan([...otherEan])
  }
  return (
    <Container>
      {loading ? <Loading /> : null}
      <h1>Cadastro de produto</h1>
      {/*<p>Insira os dados para realizar o cadastro</p>*/}
      <Breadcrumb className='breadcumb'>
        <Link to='/backoffice'>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </Link>
        <Link to='/backoffice/catalog'>
          <Breadcrumb.Item>Estoque</Breadcrumb.Item>
        </Link>
        <Link to='/backoffice/catalog/products'>
          <Breadcrumb.Item> Produtos </Breadcrumb.Item>
        </Link>
        <Breadcrumb.Item>Cadastro</Breadcrumb.Item>
      </Breadcrumb>
      <Form
        translate={undefined}
        className='form'
        ref={formRef}
        initialData={{ is_active: true }}
        onSubmit={handleSubmit}
      >
        <Row gutter={16}>
          <Col span={11}>
            <Input type='text' label='Nome *' name='name' placeholder='Nome' />
          </Col>
          <Col span={11}>
            <Label>Categoria *</Label>
            <Cascader
              style={{ width: '100%', marginBottom: '10px' }}
              options={categories}
              loadData={loadData}
              onChange={(value: any) => setCategorySelected(value[value.length - 1])}
              placeholder='Selecionar categoria'
              changeOnSelect
            />
            <Input type='hidden' name='category' value={categorySelected} />
          </Col>
          <Col span={2}>
            <LabelCheck>Está ativo?</LabelCheck>
            <InputCheckbox>
              <Checkbox
                name='is_active'
                checked={checked}
                defaultChecked={true}
                onChange={(setValue: any) => {
                  setChecked(setValue.target.checked)
                }}
              />
            </InputCheckbox>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Input type='text' label='Código ERP' name='erp_code' placeholder='Código ERP' />
          </Col>
          <Col span={8}>
            <Label>Marca *</Label>
            <Search placeholder='Selecione' path='brands' name='brand' />
          </Col>
          <Col span={8}>
            <Label>Tipo *</Label>
            <Select placeholder='Selecione' styles={customStyles} name='product_type' options={optionsProductType} />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            {newGetWorkspace.multiple_ean ? (
              <>
                {otherEan.map((index: any, item: any) => (
                  <MySpan key={item}>
                    <Input
                      type='text'
                      name='ean_others'
                      placeholder='Informe o EAN'
                      label='EAN'
                      onChange={(event: any) => handleInput(event, item)}
                    />
                    <MdClose onClick={() => DeleteEan(item)} />
                  </MySpan>
                ))}
                <Button onClick={() => addOtherEan()}>Adicionar</Button>
              </>
            ) : (
              <Input type='text' label='EAN' name='ean' placeholder='EAN' />
            )}
          </Col>
          {newGetWorkspace.shared_environment ? (
            <Col span={6}>
              <Label>Empresas *</Label>
              <Select placeholder='Selecione' styles={customStyles} options={listWorkspaces} name='sub_workspace' />
            </Col>
          ) : null}
          <Col xs={10} sm={10} md={6} lg={6}>
            <Label>Valor máximo</Label>
            <InputNumberDefault label='Valor máximo'  name='max_value' placeholder='Valor máximo' />
          </Col>
          <Col xs={10} sm={10} md={6} lg={6}>
            <Label>Valor mínimo</Label>
            <InputNumberDefault label='Valor mínimo' name='min_value' placeholder='Valor mínimo' />
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <UploadImage>
              <label htmlFor='upload-button'>
                {image.preview ? (
                  // eslint-disable-next-line jsx-a11y/alt-text
                  <img src={image.preview} width='300' height='300' />
                ) : (
                  <>
                    <span className='fa-stack fa-2x mt-3 mb-2'>
                      <MdFileUpload size={30} />
                    </span>
                    <h5 className='text-center'>Upload</h5>
                  </>
                )}
              </label>
              <input
                type='file'
                name='image_url'
                id='upload-button'
                style={{ display: 'none' }}
                onChange={handleChange}
              />
            </UploadImage>
          </Col>
        </Row>
        <Row gutter={[16, 30]} style={{ marginTop: 10, paddingLeft: 10 }}>
          <Col>
            <Button type='primary' htmlType='submit'>
              Cadastrar
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  )
}
export default ProductCreatePage
