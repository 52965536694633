import React, { useCallback, useState } from 'react'
import {
  ContainerExpandGraph,
  ContainerGraph,
  ContainerIconGraph,
  ContainerTitle,
  GraphContainerExpan,
  ContainerIconExpand,
  ContainerToolTip,
  ContainerLegend,
  ContainerHeader,
} from './style'
import FileSaver from 'file-saver'
import { useCurrentPng } from 'recharts-to-png'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { Dropdown, Menu, Tooltip as TooltipAntd } from 'antd'
import { AiOutlineClose } from 'react-icons/ai'
import { BsDownload } from 'react-icons/bs'
import { useDrag, DragPreviewImage } from 'react-dnd'
export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

interface Props {
  data: any
  title: string
  type: string
  keysName?: string[]
  index: number
  list: any
  setList: any
  setIsDragging: any
}
const GraphDash = ({ data, title, type, keysName, index, list, setList, setIsDragging }: Props) => {
  const [getLinearPng, { ref: linearRef }] = useCurrentPng()
  const [getPiePng, { ref: pieRef }] = useCurrentPng()
  const [getBarPng, { ref: barRef }] = useCurrentPng()
  const [expandGraph, setExpandGraph] = useState(false)
  const { height } = getWindowDimensions()
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042']
  const RADIAN = Math.PI / 180

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return percent * 100 > 0 ? (
      <text x={x} y={y} fill='white' textAnchor={x > cx ? 'start' : 'end'} dominantBaseline='central'>
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    ) : (
      <></>
    )
  }

  const handleAreaDownload = useCallback(async () => {
    let file
    if (type === 'PIE') file = await getPiePng()
    if (type === 'LINEAR') file = await getLinearPng()
    if (type === 'BAR') file = await getBarPng()
    if (file) {
      FileSaver.saveAs(file, `${type}-chart.png`)
    }
    // eslint-disable-next-line
  }, [getPiePng, getLinearPng])

  const menu = (
    <Menu>
      <Menu.ItemGroup key={1}>
        <Menu.Item onClick={handleAreaDownload}>Download PNG</Menu.Item>
        <Menu.Item onClick={() => setExpandGraph(!expandGraph)}>Expandir</Menu.Item>
      </Menu.ItemGroup>
      {/* {type !== 'PIE' && ( */}
      <Menu.SubMenu title='Trocar de gráfico' key={2}>
        {type !== 'PIE' && <Menu.Item onClick={() => convertGraph('PIE')}>Pizza</Menu.Item>}
        {type !== 'BAR' && <Menu.Item onClick={() => convertGraph('BAR')}>Barra</Menu.Item>}
        {type !== 'LINEAR' && <Menu.Item onClick={() => convertGraph('LINEAR')}>Linha</Menu.Item>}
      </Menu.SubMenu>
      {/* )} */}
    </Menu>
  )

  const convertGraph = (typeSelected: string) => {
    let newList = list
    let keysName = []

    if (type === 'PIE') {
      let newData: any = {}
      data.forEach((el: any) => {
        let obj: any = {}
        obj[el.name] = el.value
        newData = { ...newData, ...obj, name: title }
        keysName.push(el.name)
      })

      newList[index].data = [newData]
    } else {
      for (var prop in newList[index].data[0]) {
        if (prop !== 'name') keysName.push(prop)
      }
    }

    newList[index] = { ...newList[index], type: typeSelected, keysName }

    if (typeSelected === 'PIE') {
      let obj: any = []
      Object.keys(newList[index].data[0]).forEach((el) => {
        if (el !== 'name')
          obj.push({
            name: el,
            value: newList[index].data[0][el],
          })
      })

      newList[index].data = obj
    }

    setList([...newList])
  }
  const [, dragRef, preview] = useDrag(() => ({
    type: 'CARD',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }))

  return (
    <ContainerGraph>
      <ContainerHeader>
        <p className='titleGraph'>{title}</p>
        <ContainerIconGraph ref={dragRef}>
          <DragPreviewImage
            connect={preview}
            src='https://static.vecteezy.com/packs/media/components/global/search-explore-nav/img/vectors/term-bg-1-666de2d941529c25aa511dc18d727160.jpg'
          />
          <Dropdown.Button overlay={menu} trigger={['click']} />
        </ContainerIconGraph>
      </ContainerHeader>
      {type === 'LINEAR' && (
        <div style={{ width: '100%', height: 230 }}>
          <ResponsiveContainer>
            <LineChart ref={linearRef} width={200} height={200} data={data} style={{ marginTop: 30 }}>
              <CartesianGrid strokeDasharray='10 10' />
              <Tooltip />
              <Legend />
              <Line type='monotone' dataKey='pv' stroke='#6600cc' activeDot={{ r: 8 }} strokeWidth={4} />
              <Line type='monotone' dataKey='uv' stroke='#00ccf2' activeDot={{ r: 8 }} strokeWidth={4} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      )}
      {type === 'BAR' && (
        <div style={{ width: '100%', height: 230 }}>
          <ResponsiveContainer>
            <BarChart width={200} height={200} data={data} ref={barRef} style={{ marginTop: 30 }}>
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='name' />
              <YAxis />
              <Tooltip />
              <Legend />
              {keysName &&
                keysName.map((entry: any, indexK: number) => (
                  <Bar dataKey={entry} fill={COLORS[indexK % COLORS.length]} key={indexK} />
                ))}
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
      {type === 'PIE' && (
        <div style={{ width: '100%', height: 230 }}>
          <ResponsiveContainer>
            <PieChart ref={pieRef}>
              <Pie
                data={data}
                cx='50%'
                cy='50%'
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={80}
                fill='#8884d8'
                dataKey='value'
              >
                {data.map((entry: any, index: number) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
          <ContainerToolTip>
            {keysName &&
              keysName.map((entry: any, index: number) => (
                <TooltipAntd placement='top' title={entry} key={index}>
                  <ContainerLegend>
                    <div style={{ background: COLORS[index % COLORS.length] }} />
                    <p style={{ color: COLORS[index % COLORS.length] }}>{entry}</p>
                  </ContainerLegend>
                </TooltipAntd>
              ))}
          </ContainerToolTip>
        </div>
      )}
      {expandGraph && (
        <ContainerExpandGraph>
          <ContainerTitle>
            <p>{title}</p>
          </ContainerTitle>
          <ContainerIconExpand>
            <BsDownload onClick={handleAreaDownload} color='#EEF2FF' />
            <AiOutlineClose color='#FF5959' onClick={() => setExpandGraph(!expandGraph)} />
          </ContainerIconExpand>
          <GraphContainerExpan>
            {type === 'LINEAR' && (
              <div style={{ width: '100%', height: height * 0.7 }}>
                <ResponsiveContainer>
                  <LineChart data={data} ref={linearRef}>
                    <CartesianGrid strokeDasharray='10 10' />
                    <Tooltip />
                    <Legend />
                    <Line type='monotone' dataKey='pv' stroke='#6600cc' activeDot={{ r: 8 }} strokeWidth={4} />
                    <Line type='monotone' dataKey='uv' stroke='#00ccf2' activeDot={{ r: 8 }} strokeWidth={4} />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            )}
            {type === 'PIE' && (
              <div style={{ width: '100%', height: height * 0.7 }}>
                <ResponsiveContainer>
                  <PieChart ref={pieRef}>
                    <Pie
                      data={data}
                      cx='50%'
                      cy='50%'
                      labelLine={false}
                      label={renderCustomizedLabel}
                      outerRadius={80}
                      fill='#8884d8'
                      dataKey='value'
                    >
                      {data.map((entry: any, index: number) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </div>
            )}
            {type === 'BAR' && (
              <div style={{ width: '100%', height: height * 0.7 }}>
                <ResponsiveContainer>
                  <BarChart data={data} ref={barRef}>
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis dataKey='name' />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {keysName &&
                      keysName.map((entry: any, index: number) => (
                        <Bar dataKey={entry} fill={COLORS[index % COLORS.length]} />
                      ))}
                  </BarChart>
                </ResponsiveContainer>
              </div>
            )}
          </GraphContainerExpan>
        </ContainerExpandGraph>
      )}
    </ContainerGraph>
  )
}

export default GraphDash
