import styled from 'styled-components';


export const Container = styled.div`
    width: 98%;
    margin: 25px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.35);
    letter-spacing: 2px;
    label{
        font-size: 16px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.65);
        margin-right: 20px;
    }
    p{
        color: ${props => props.color ? props.color : 'rgba(0, 0, 0, 0.65)'};
        margin-right: 30px;
        margin-bottom: 0;
        font-size: 16px;
    }

    svg {
        justify-self: flex-end;
    }
`
export const IconChange = styled.div`
`

export const FlexTitle = styled.div`
    display: flex;
    align-items: center;
    letter-spacing: 2px;

    label{
        font-size: 16px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.65);
        margin-right: 20px;
    }
    p{
        color: ${props => props.color ? props.color : 'rgba(0, 0, 0, 0.65)'};
        margin-right: 30px;
        margin-bottom: 0;
        font-size: 16px;
    }
`