import React, { useEffect, useState } from 'react'
import { Container, Title, TextError } from './styles'
import { Upload, Modal } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import firebase from '../../../../../utils/firebase'
import { generateUUID } from '../../../../../utils/funcao'

interface IQuestion {
  id: number
  is_required: boolean
  name: string
  order: string
  type: string
  fields_true?: any
}
interface IDataQuestion {
  dataQuestion: IQuestion
  handleAnswers: (value: any, dataQuestion: IQuestion) => void
  error?: boolean
  loading: any
  body: any
  checkRequired: (value: any) => void
}

function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export const PictureMass = ({ dataQuestion, handleAnswers, error, loading, checkRequired, body }: IDataQuestion) => {
  const [pictures, setPictures] = useState<any>([])
  const [urls, setUrls] = useState<any>([])
  const [isRender, setIsRender] = useState(false)
  const [preview, setPreview] = useState<any>({})

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  // useEffect(() => {
  //   const answers = () => {
  //     handleAnswers(urls, dataQuestion)
  //   }

  //   answers()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [pictures])

  useEffect(() => {
    if (dataQuestion.is_required && !isRender) {
      setIsRender(true)
      checkRequired(dataQuestion.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCancel = () => setPreview({ previewVisible: false })

  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    setPreview({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    })
  }
  
  useEffect(() => {
    if(error) {
      console.log(error, dataQuestion, 'aqui')
    }
  }, [error])
  return (
    <>
      <Container>
        <Title>
          {dataQuestion.order} - {dataQuestion.name} {dataQuestion.is_required && '*'}
        </Title>
        <Upload
          // action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
          listType='picture-card'
          fileList={pictures}
          onPreview={handlePreview}
          onRemove={(file) => {
            let newFiles = pictures.filter((item) => item.url != file.url)
            let listUrl = newFiles.map((el: any) => el.url)
            setPictures(newFiles)
            setUrls(listUrl)
            handleAnswers(listUrl, dataQuestion)
          }}        
          customRequest={async ({ file, onSuccess, onError }: any) => {
            try {
              const team: any = sessionStorage.getItem('@rocketpdv:workspace')
              let fileUpload = firebase
              .storage()
              .ref(`${JSON.parse(team).slug}/tasks/${body.form_id}/backoffice/${file.name}`)
      
            await fileUpload.put(file)
            const url = await fileUpload.getDownloadURL()
              const pictureList = {
                uid: generateUUID(),
                status: 'done',
                url, 
              }
                
              let newFiles = pictures
              newFiles.push(pictureList)

              let listUrl = newFiles.map((el: any) => el.url)
              setPictures([...newFiles])
              setUrls(listUrl)
              handleAnswers(listUrl, dataQuestion)

            } catch (error) {
              console.log(error)
              // message.error('Erro ao subir arquivo')
            }
          }}
        >
          {pictures.length >= 20 ? null : uploadButton}
        </Upload>
        {error && urls.length === 0 && <TextError>Campo obrigatório</TextError>}
        {error && <TextError>Campo obrigatório</TextError>}
      </Container>
      <Modal visible={preview?.previewVisible} title={preview?.previewTitle} footer={null} onCancel={handleCancel}>
        <img alt='example' style={{ width: '100%' }} src={preview?.previewImage} />
      </Modal>
    </>
  )
}
