import styled from 'styled-components'

export const ContainerHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 13px;

  img {
    margin-right: 13px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
  }
`

export const Name = styled.p`
  color: #242424;
  font-family: 'Open Sans';
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
  margin-right: 10px;
  text-transform: capitalize;
`

export const ContainerStatus = styled.div``

export const Active = styled.div`
  width: 53px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid #16c266;
  background-color: rgba(184, 255, 217, 0.5);
  color: #0b9c4e;
  font-family: 'Open Sans';
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  align-self: baseline;
  margin-top: 9px;
  margin-right: 7px;
`

export const ContainerEdit = styled.div`
  display: flex;
  align-self: center;
  margin-left: 10px;
  cursor: pointer;
  z-index: 99;
`

export const Ticket = styled.div`
  width: 115px;
  height: 28px;
  background-color: #eeddff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: #272d3b;
    margin: 0;
  }
`

export const ContainerInfo = styled.div`
  color: #6f7782;
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
  display: flex;
  align-items: center;

  svg {
    color: #6600cc;
    font-size: 25px;
    margin-right: 9px;
  }

  p {
    color: #6600cc;
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0;
    margin-right: 9px;
  }
`
export const ContainerAction = styled.div`
  display: flex;
  align-items: center;

  p {
    color: #6600cc;
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
    margin-right: 15px;
  }

  img {
    margin-right: 10px;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  &&& .tooltip {
    color: #272d3b !important;
    margin: 0;
  }
`

export const ContainerData = styled.div`
  width: 100%;
  max-width: 1053px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
`

export const ContainerActions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: -100px;
  margin-bottom: 100px;
`

export const ContainerActionsCalc = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: -100px;
  margin-bottom: 100px;
  width: auto;
  position: absolute;
  left: calc(100vw - 206px - 3%);
`

export const BigButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6f7782;
  background-color: #fff;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #6f7782;
  background-color: #f9f9f9;
  font-size: 16px;
  font-weight: 400;
  padding: 8px;
  svg {
    margin-right: 10px;
  }
`

export const ContainerActionBack = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const ButtonBack = styled.button`
  width: 102px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #d6d6d6;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: #6f7782;
  font-family: 'Open Sans';
  font-size: 16px;
  margin-left: 10px;
  background-color: transparent;
  padding: 5px;
  :hover {
    background-color: #b56aff;
    color: #fff;
    transition: all 0.2s;
  }
`

export const ContainerOptions = styled.div`
  position: relative;
  left: calc(100vw - 610px);
  bottom: 10px;
  display: flex;
  width: 568px;
  justify-content: flex-start;
  z-index: 9;
`
