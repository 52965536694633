import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;

  h1,
  p {
    padding: 0px 10px;
  }

  form {
    margin-top: 30px;
    font-weight: 600;
  }
  button {
    text-align: center;
    margin-top: 10px;
  }
  label {
    font-weight: 600;
  }
  input,
  select {
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    height: 38px;
    padding: 0 15px;
    color: rgba(0, 0, 0, 0.65);
    margin: 0 0 10px;
    width: 100%;
    display: inline-block;

    &::placeholder {
      color: #d9d9d9;
    }
    &:hover,
    &:focus {
      border-color: #1890ff;
    }
  }
`

export const ButtonDesiner = styled.div`
  button {
    display: flex;
    align-items: center;
  }
  svg {
    margin-right: 8px;
  }
`

export const BtnExport = styled.a`
  padding: 10px;
  color: #fff;

  &:hover {
    color: #fff;
  }
`

export const StatusInativo = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 16px;
  text-align: center;
  background-color: #f75c4c;
`
