import { Breadcrumb, Col, Collapse, Row, Tag, Tooltip } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { DrawerFilter } from '../../../components/DrawerFilter'
import { Loading } from '../../../components/Loading'
import { BigButton, ContainerButtonsFootter, ContainerFormFilter, customStyles, GroupForm } from '../../../GlobalStyles'
import { ContainerTagg } from '../../Survey/FormListPage/styles'
import { ButtonClear, ButtonSearch, LabelForm } from '../../Team/Promoter/style'
import {
  FileSearchOutlined,
  FilterOutlined,
  CloseOutlined,
  MinusOutlined,
  PlusOutlined,
  ClearOutlined,
} from '@ant-design/icons'
import { FormHandles } from '@unform/core'
import history from '../../../routes/history'
import { Form } from '@unform/web'
import SimpleRangePicker from '../../../components/form/SimpleRangePicker'
import moment from 'moment'
import qs from 'querystring'
import useReactRouter from 'use-react-router'
import ColumnDash from '../../../components_analytcs/ColumnsDash'
import { ContainerFullGraph } from '../move/style'
import { Link } from 'react-router-dom'
import api_v2 from '../../../services/api-v2'
import { LinearCard } from '../../../components_analytcs/LinearCard'
import Select from '../../../components/form/SimpleSelect'
import { optionUF } from '../../../utils/option-user'
import { Search } from '../../../components/form/SimpleSearch'
import { ContextUpload } from '../../../context/contextUpload'
import { hasIndex } from '../../../components/Menu/menuHorizonte'
import { notAccess } from '../../../routes/ScrollToTop'

function TimeComparate() {
  const formRef = useRef<FormHandles>(null)
  const { permissions } = ContextUpload()
  const { location } = useReactRouter()
  const [filterTag, setFilterTag] = useState<any>({})
  const [visible, setVisible] = useState<boolean>(false)
  const [expanded, setExpanded] = useState<string>('1')
  const [loading, setLoading] = useState<boolean>(false)
  const [jorneyMounth, setJorneyMount] = useState<any>({
    categories: [],
    data: [],
  })
  const [jorneyMounthPdv, setJorneyMountPdv] = useState<any>({
    categories: [],
    data: [],
  })
  const [supervisorWeek] = useState<any>({
    categories: [],
    data: [],
  })
  const [supervisorPdv] = useState<any>({
    categories: [],
    data: [],
  })
  const [jorneyWeek, setJorneyWeek] = useState<any>({
    categories: [],
    data: [],
  })
  const [jorneyWeekPdv, setJorneyWeekPdv] = useState<any>({
    categories: [],
    data: [],
  })
  const [jorneyState, setJorneyState] = useState<any>({
    categories: [],
    series: [],
  })
  const [jorneyStateWeek, setJorneyStateWeek] = useState<any>({
    categories: [],
    series: [],
  })
  async function searchTime(body: any, url: string, setter: any) {
    let { states_ids, date, flags_ids, promoters_ids, networks_ids, pdvs_ids, regions_ids, status, uf, week } = body
    let filters: any = {
      filterQuery: {},
      dataTag: {},
      filterQueryUrl: {},
    }

    if (promoters_ids && promoters_ids.length > 0) {
      let text = ''
      promoters_ids.forEach((el: any) => (text = text === '' ? `${el.value}` : text + `,${el.value}`))
      filters.filterQuery.promoters_ids = text
      filters.dataTag = { ...filters.dataTag, promoters_ids }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, promoters_ids: JSON.stringify(promoters_ids) }
    }

    if (states_ids && states_ids.length > 0) {
      let text = ''
      states_ids.forEach((el: any) => (text = text === '' ? `${el}` : text + `,${el}`))
      filters.filterQuery.states_ids = text
      filters.dataTag = { ...filters.dataTag, states_ids }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, states_ids: JSON.stringify(states_ids) }
    }

    if (pdvs_ids && pdvs_ids.length > 0) {
      let text = ''
      pdvs_ids.forEach((el: any) => (text = text === '' ? `${el.value}` : text + `,${el.value}`))
      filters.filterQuery.pdvs_ids = text
      filters.dataTag = { ...filters.dataTag, pdvs_ids }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, pdvs_ids: JSON.stringify(pdvs_ids) }
    }

    if (networks_ids && networks_ids.length > 0) {
      let text = ''
      networks_ids.forEach((el: any) => (text = text === '' ? `${el.value}` : text + `,${el.value}`))
      filters.filterQuery.networks_ids = text
      filters.dataTag = { ...filters.dataTag, networks_ids }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, networks_ids: JSON.stringify(networks_ids) }
    }

    if (regions_ids && regions_ids.length > 0) {
      let text = ''
      regions_ids.forEach((el: any) => (text = text === '' ? `${el.value}` : text + `,${el.value}`))

      filters.filterQuery.regions_ids = text
      filters.dataTag = { ...filters.dataTag, regions_ids }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, regions_ids: regions_ids }
    }

    if (status) {
      filters.filterQuery.status = status
      filters.dataTag = { ...filters.dataTag, status }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, status: status }
    }

    if (uf) {
      filters.filterQuery.uf = uf
      filters.dataTag = { ...filters.dataTag, uf }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, uf: uf }
    }

    if (week) {
      filters.filterQuery.week = week
      filters.dataTag = { ...filters.dataTag, week }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, week: week }
    }

    if (flags_ids && flags_ids.length > 0) {
      let text = ''
      flags_ids.forEach((el: any) => (text = text === '' ? `${el.value}` : text + `,${el.value}`))

      filters.filterQuery.flags_ids = text
      filters.dataTag = { ...filters.dataTag, flags_ids }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, flags_ids: JSON.stringify(flags_ids) }
    }

    if (date) {
      filters.filterQueryUrl = { ...filters.filterQueryUrl, date: JSON.stringify(date) }
      if (date[0]) {
        filters.filterQuery.start_date = date[0]
        filters.dataTag = { ...filters.dataTag, start_date: date[0] }
      }

      if (date[1]) {
        filters.filterQuery.end_date = date[1]
        filters.dataTag = { ...filters.dataTag, end_date: date[1] }
      }
    } else {
      filters.filterQuery = {
        ...filters.filterQuery,
        end_date: moment().format('YYYY-MM-DD'),
        start_date: moment().format('YYYY-MM-DD'),
      }
      filters.dataTag = {
        ...filters.dataTag,
        end_date: moment().format('YYYY-MM-DD'),
        start_date: moment().format('YYYY-MM-DD'),
      }
    }

    const queryFilter = qs.stringify({ ...filters.filterQuery })

    const { data } = await api_v2.get(`${url}?${queryFilter}`)
    setter(data)
    setFilterTag(filters.dataTag)
    setVisible(false)
    history.push(`/Analitycs/timeComparate?${qs.stringify({ ...filters.filterQueryUrl })}`)
  }

  const removeFilter = async (typeRemove: string, value: string) => {
    let newObjTagg = filterTag

    if (typeRemove === 'promoters_ids') {
      newObjTagg.promoters_ids = newObjTagg.promoters_ids.filter((el: any) => el.value !== value)
      if (newObjTagg.promoters_ids.length === 0) delete newObjTagg.promoters_ids
    }

    if (typeRemove === 'states_ids') {
      newObjTagg.states_ids = newObjTagg.states_ids.filter((el: any) => el !== value)
      if (newObjTagg.states_ids.length === 0) delete newObjTagg.states_ids
    }

    if (typeRemove === 'flags_ids') {
      newObjTagg.flags_ids = newObjTagg.flags_ids.filter((el: any) => el.value !== value)
      if (newObjTagg.flags_ids.length === 0) delete newObjTagg.flags_ids
    }

    if (typeRemove === 'pdvs_ids') {
      newObjTagg.pdvs_ids = newObjTagg.pdvs_ids.filter((el: any) => el.value !== value)
      if (newObjTagg.pdvs_ids.length === 0) delete newObjTagg.pdvs_ids
    }

    if (typeRemove === 'regions_ids') {
      newObjTagg.regions_ids = newObjTagg.regions_ids.filter((el: any) => el.value !== value)
      if (newObjTagg.regions_ids.length === 0) delete newObjTagg.regions_ids
    }

    if (typeRemove === 'networks_ids') {
      newObjTagg.networks_ids = newObjTagg.networks_ids.filter((el: any) => el.value !== value)
      if (newObjTagg.networks_ids.length === 0) delete newObjTagg.networks_ids
    }

    if (typeRemove === 'start_date') {
      newObjTagg.date = [
        moment().format('YYYY-MM-DD'),
        filterTag.end_date ? filterTag.end_date : moment().format('YYYY-MM-DD'),
      ]
    }

    if (typeRemove === 'end_date') {
      newObjTagg.date = [
        filterTag.start_date ? filterTag.start_date : moment().format('YYYY-MM-DD'),

        moment().format('YYYY-MM-DD'),
      ]
    }

    setLoading(true)
    // const { data } = await api_v2.get(`/visit?${queryFilter}`)
    // setForms({ ...data, loading: false })
    updateUrl(newObjTagg)
    setFilterTag(newObjTagg)
    await searchTime(newObjTagg, '/comparative-time/journey-media-monthly', setJorneyMount)
    await searchTime(newObjTagg, '/comparative-time/journey-media-weekly', setJorneyWeek)
    await searchTime(newObjTagg, '/comparative-time/journey-media-pdv-monthly', setJorneyMountPdv)
    await searchTime(newObjTagg, '/comparative-time/journey-media-pdv-weekly', setJorneyWeekPdv)
    await searchTime(newObjTagg, '/comparative-time/journey-media-state-monthly', setJorneyState)
    await searchTime(newObjTagg, '/comparative-time/journey-media-state-weekly', setJorneyStateWeek)
    setLoading(false)
  }

  const cleanFilter = async () => {
    window.sessionStorage.removeItem('filterAnalytcs')

    history.push(`/Analitycs/timeComparate`)
    setVisible(false)
    setFilterTag({})
    formRef!.current!.reset()
  }

  const updateUrl = (obj: any) => {
    window.sessionStorage.setItem('filterAnalytcs', JSON.stringify(obj))

    let url: any = {}
    if (obj && obj.date) {
      url.date = JSON.stringify(obj.date)
    } else {
      url.date = [moment().startOf('week').format('YYYY-MM-DD'), moment().endOf('week').format('YYYY-MM-DD')]
    }

    if (obj && obj.pdvs_ids) {
      url.pdvs_ids = JSON.stringify(obj.pdvs_ids)
    }

    if (obj && obj.networks_ids) {
      url.networks_ids = JSON.stringify(obj.networks_ids)
    }

    if (obj && obj.states_ids) {
      url.states_ids = JSON.stringify(obj.states_ids)
    }

    if (obj && obj.regions_ids) {
      url.regions_ids = JSON.stringify(obj.regions_ids)
    }

    if (obj && obj.flags_ids) {
      url.flags_ids = JSON.stringify(obj.flags_ids)
    }

    if (obj && obj.promoters_ids) {
      url.promoters_ids = JSON.stringify(obj.promoters_ids)
    }
    history.push(`/Analitycs/timeComparate?${qs.stringify(url)}`)
  }

  const getStatusWithFilter = async (data?: any) => {
    let block = notAccess(permissions, window.location.pathname)
    if (block === -2) return history.push('/notAcces')

    let obj: any = { ...qs.parse(location.search.replaceAll('?', '')), ...data }
    if (obj && obj.promoters_ids && typeof obj.promoters_ids === 'string')
      obj.promoters_ids = JSON.parse(obj.promoters_ids)

    if (obj && obj.states_ids && typeof obj.states_ids === 'string') obj.states_ids = JSON.parse(obj.states_ids)

    if (obj && obj.flags_ids && typeof obj.flags_ids === 'string') obj.flags_ids = JSON.parse(obj.flags_ids)
    if (obj && obj.regions_ids && typeof obj.regions_ids === 'string') obj.regions_ids = JSON.parse(obj.regions_ids)
    if (obj && obj.networks_ids && typeof obj.networks_ids === 'string') obj.networks_ids = JSON.parse(obj.networks_ids)
    if (obj && obj.pdvs_ids && typeof obj.pdvs_ids === 'string') obj.pdvs_ids = JSON.parse(obj.pdvs_ids)
    // if (obj && obj.wek) obj.wek = JSON.parse(obj.wek)
    // if (obj && obj.uf) obj.uf = JSON.parse(obj.uf)
    if (obj && obj.date && typeof obj.date === 'string') obj.date = JSON.parse(obj.date)

    if (!obj || !obj.date)
      obj.date = [moment().startOf('week').format('YYYY-MM-DD'), moment().endOf('week').format('YYYY-MM-DD')]

    updateUrl(obj)
    setLoading(true)
    await searchTime(obj, '/comparative-time/journey-media-monthly', setJorneyMount)
    await searchTime(obj, '/comparative-time/journey-media-weekly', setJorneyWeek)
    await searchTime(obj, '/comparative-time/journey-media-pdv-monthly', setJorneyMountPdv)
    await searchTime(obj, '/comparative-time/journey-media-pdv-weekly', setJorneyWeekPdv)
    await searchTime(obj, '/comparative-time/journey-media-state-monthly', setJorneyState)
    await searchTime(obj, '/comparative-time/journey-media-state-weekly', setJorneyStateWeek)
    setLoading(false)
  }

  useEffect(() => {
    let getValue = window.sessionStorage.getItem('filterAnalytcs')
    if (getValue !== undefined && getValue !== null && getValue !== 'undefined') {
      getStatusWithFilter(JSON.parse(getValue))
    } else {
      getStatusWithFilter()
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div style={{ padding: '0 10px' }}>
      <Row gutter={[8, 8]} justify='space-between'>
        <Col span={20}>
          <h1>Comparativo de tempo</h1>
          <Breadcrumb className='breadcumb'>
            <Link to='Analytics'>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
            </Link>
            <Breadcrumb.Item>
              <Breadcrumb.Item>Comparativo de tempo</Breadcrumb.Item>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col>
          <Tooltip placement='top' title={'Expandir todos gráficos'}>
            <BigButton
              onClick={() => {
                setLoading(true)
                setExpanded('1')
                setTimeout(() => {
                  setLoading(false)
                }, 200)
              }}
              style={{ padding: 9 }}
            >
              <PlusOutlined />
            </BigButton>
          </Tooltip>
        </Col>
        <Col>
          <Tooltip placement='top' title={'Ocultar todos gráficos'}>
            <BigButton
              onClick={() => {
                setLoading(true)
                setExpanded('0')
                setTimeout(() => {
                  setLoading(false)
                }, 200)
              }}
              style={{ padding: 9 }}
            >
              <MinusOutlined />
            </BigButton>
          </Tooltip>
        </Col>
        <Col>
          <Tooltip placement='top' title={'Filtrar'}>
            <BigButton onClick={() => setVisible(!visible)} style={{ padding: 9 }}>
              <FilterOutlined />
            </BigButton>
          </Tooltip>
        </Col>
        <Col span={24}>
          <ContainerTagg>
            {filterTag.start_date && (
              <Tag color='geekblue' onClick={() => removeFilter('start_date', `${filterTag.start_date}`)}>
                {moment(filterTag.start_date).format('DD/MM/YYYY')}
                <CloseOutlined />
              </Tag>
            )}
            {filterTag.end_date && (
              <Tag color='geekblue' onClick={() => removeFilter('end_date', `${filterTag.end_date}`)}>
                {moment(filterTag.end_date).format('DD/MM/YYYY')}
                <CloseOutlined />
              </Tag>
            )}

            {filterTag.week && (
              <Tag color='geekblue' onClick={() => removeFilter('week', filterTag.week)}>
                {filterTag.week} <CloseOutlined />
              </Tag>
            )}
            {filterTag.uf && (
              <Tag color='geekblue' onClick={() => removeFilter('uf', filterTag.uf)}>
                {filterTag.uf} <CloseOutlined />
              </Tag>
            )}
            {filterTag.status && (
              <Tag color='geekblue' onClick={() => removeFilter('status', filterTag.status)}>
                {filterTag.status} <CloseOutlined />
              </Tag>
            )}
            {filterTag.flags_ids &&
              filterTag.flags_ids.map((el: any) => (
                <Tag color='geekblue' onClick={() => removeFilter('flags_ids', el.value)}>
                  {el.label} <CloseOutlined />
                </Tag>
              ))}
            {filterTag.states_ids &&
              filterTag.states_ids.map((el: any) => (
                <Tag color='geekblue' onClick={() => removeFilter('states_ids', el)}>
                  {el} <CloseOutlined />
                </Tag>
              ))}
            {filterTag.regions_ids &&
              filterTag.regions_ids.map((el: any) => (
                <Tag color='geekblue' onClick={() => removeFilter('regions_ids', el.value)}>
                  {el.label} <CloseOutlined />
                </Tag>
              ))}
            {filterTag.promoters_ids &&
              filterTag.promoters_ids.map((el: any) => (
                <Tag color='geekblue' onClick={() => removeFilter('promoters_ids', el.value)}>
                  {el.label} <CloseOutlined />
                </Tag>
              ))}
            {filterTag.networks_ids &&
              filterTag.networks_ids.map((el: any) => (
                <Tag color='geekblue' onClick={() => removeFilter('networks_ids', el.value)}>
                  {el.label} <CloseOutlined />
                </Tag>
              ))}
            {filterTag.pdvs_ids &&
              filterTag.pdvs_ids.map((el: any) => (
                <Tag color='geekblue' onClick={() => removeFilter('pdvs_ids', el.value)}>
                  {el.label} <CloseOutlined />
                </Tag>
              ))}
          </ContainerTagg>
        </Col>
      </Row>
      {hasIndex(['time_compare.view'], permissions) > -1 && (
        <>
          {loading ? (
            <Loading />
          ) : (
            <>
              <Row gutter={[20, 20]}>
                <Col span={12}>
                  <ContainerFullGraph>
                    <Collapse bordered={false} defaultActiveKey={[expanded]}>
                      <Collapse.Panel header='Jornada média mensal' key='1'>
                        {/* {jorneyMounth.categories.length > 1 ? (
                      <LinearCard
                        categories={jorneyMounth.categories}
                        series={[{ data: jorneyMounth.data }]}
                        type='time'
                        tooltip={false}
                      />
                    ) : (
                      <ColumnDash
                        categories={jorneyMounth.categories}
                        series={[{ data: jorneyMounth.data }]}
                        legend={false}
                        type='time'
                      />
                    )} */}
                        <ColumnDash
                          categories={jorneyMounth.categories}
                          series={[
                            {
                              data: jorneyMounth.data.map((time: any) => {
                                if (!!!time) return '0'
                                return time.replaceAll(':', '')
                              }),
                            },
                          ]}
                          legend={false}
                          type='time'
                        />
                      </Collapse.Panel>
                    </Collapse>
                  </ContainerFullGraph>
                </Col>
                <Col span={12}>
                  <ContainerFullGraph>
                    <Collapse bordered={false} defaultActiveKey={[expanded]}>
                      <Collapse.Panel header='Jornada média por semana' key='1'>
                        {jorneyWeek.categories.length > 1 ? (
                          <LinearCard
                            categories={jorneyWeek.categories}
                            series={[{ data: jorneyWeek.data }]}
                            type='time'
                            tooltip={true}
                            dataLabel={false}
                          />
                        ) : (
                          <ColumnDash
                            categories={jorneyWeek.categories}
                            series={[
                              {
                                data: jorneyWeek.data.map((time: any) => {
                                  if (!!!time) return '0'
                                  return time.replaceAll(':', '')
                                }),
                              },
                            ]}
                            legend={false}
                            type='time'
                          />
                        )}

                        {/* <ColumnDash
                      categories={jorneyWeek.categories}
                      series={[{ data: jorneyWeek.data }]}
                      legend={false}
                      type='time'
                    /> */}
                      </Collapse.Panel>
                    </Collapse>
                  </ContainerFullGraph>
                </Col>
              </Row>
              <Row gutter={[20, 20]}>
                <Col span={12}>
                  <ContainerFullGraph>
                    <Collapse bordered={false} defaultActiveKey={[expanded]}>
                      <Collapse.Panel header='Média de tempo em loja mensal' key='1'>
                        <ColumnDash
                          categories={jorneyMounthPdv.categories}
                          series={[
                            {
                              data: jorneyMounthPdv.data.map((time: any) => {
                                if (!!!time) return '0'
                                return time.replaceAll(':', '')
                              }),
                            },
                          ]}
                          legend={false}
                          type='time'
                        />
                        {/* <LinearCard
                      categories={jorneyMounthPdv.categories}
                      series={
                        jorneyMounthPdv.data.length > 0
                          ? jorneyMounthPdv.data.map((el: any, index: any) => {
                              return {
                                name: jorneyMounthPdv.categories[index],
                                data: [el],
                              }
                            })
                          : []
                      }
                      legend={false}
                    /> */}
                      </Collapse.Panel>
                    </Collapse>
                  </ContainerFullGraph>
                </Col>
                <Col span={12}>
                  <ContainerFullGraph>
                    <Collapse bordered={false} defaultActiveKey={[expanded]}>
                      <Collapse.Panel header='Média de tempo em loja por semana' key='1'>
                        {jorneyWeekPdv.categories.length > 1 ? (
                          <LinearCard
                            categories={jorneyWeekPdv.categories}
                            series={[{ data: jorneyWeekPdv.data }]}
                            type='time'
                            tooltip={true}
                            dataLabel={false}
                          />
                        ) : (
                          <ColumnDash
                            categories={jorneyWeekPdv.categories}
                            series={[
                              {
                                data: jorneyWeekPdv.data.map((time: any) => {
                                  if (!!!time) return '0'
                                  return time.replaceAll(':', '')
                                }),
                              },
                            ]}
                            legend={false}
                            type='time'
                          />
                        )}
                        {/* <ColumnDash
                      categories={jorneyWeekPdv.categories}
                      series={[{ data: jorneyWeekPdv.data }]}
                      legend={false}
                      type='time'
                    /> */}
                      </Collapse.Panel>
                    </Collapse>
                  </ContainerFullGraph>
                </Col>
              </Row>
              <ContainerFullGraph>
                <Collapse bordered={false} defaultActiveKey={[expanded]}>
                  <Collapse.Panel header='Jornada média por semana por supervisor' key='1'>
                    <ColumnDash
                      id='full-bar-dash'
                      categories={supervisorWeek.categories}
                      series={supervisorWeek.data.map((time: any) => {
                        if (!!!time) return '0'
                        return time.replaceAll(':', '')
                      })}
                      type='time'
                      tooltip={true}
                      legend={true}
                    />
                  </Collapse.Panel>
                </Collapse>
              </ContainerFullGraph>
              <ContainerFullGraph>
                <Collapse bordered={false} defaultActiveKey={[expanded]}>
                  <Collapse.Panel header='Média de tempo em loja por supervisor' key='1'>
                    <ColumnDash
                      id='full-bar-dash'
                      categories={supervisorPdv.categories}
                      series={supervisorPdv.data.map((time: any) => {
                        if (!!!time) return '0'
                        return time.replaceAll(':', '')
                      })}
                      type='time'
                      tooltip={true}
                      legend={true}
                    />
                  </Collapse.Panel>
                </Collapse>
              </ContainerFullGraph>
              <ContainerFullGraph>
                <Collapse bordered={false} defaultActiveKey={[expanded]}>
                  <Collapse.Panel header='Jornada média por regional' key='1'>
                    <ColumnDash
                      id='full-bar-dash'
                      categories={jorneyState.categories}
                      series={jorneyState.series.map((el: any) => {
                        return {
                          ...el,
                          data: el.data.map((time: any) => {
                            if (!!!time) return '0'
                            return time.replaceAll(':', '')
                          }),
                        }
                      })}
                      type='time'
                      tooltip={false}
                      legend={true}
                      widthTable={jorneyState.categories.length > 1 ? 720 : undefined}
                      orientation='vertical'
                    />
                  </Collapse.Panel>
                </Collapse>
              </ContainerFullGraph>
              <ContainerFullGraph>
                <Collapse bordered={false} defaultActiveKey={[expanded]}>
                  <Collapse.Panel header='Tempo médio em loja por regional' key='1'>
                    <ColumnDash
                      id='full-bar-dash'
                      categories={jorneyStateWeek.categories}
                      series={jorneyStateWeek.series.map((el: any) => {
                        return {
                          ...el,
                          data: el.data.map((time: any) => {
                            if (!!!time) return '0'
                            return time.replaceAll(':', '')
                          }),
                        }
                      })}
                      type='time'
                      tooltip={true}
                      legend={true}
                      widthTable={720}
                      columnWidth='70px'
                      orientation='vertical'
                    />
                    {/* <ColumnDash
                  id='full-bar-dash'
                  categories={jorneyStateWeek.categories}
                  series={jorneyStateWeek.series}
                  type='time'
                  tooltip={false}
                  legend={true}
                  columnWidth='300px'
                /> */}
                  </Collapse.Panel>
                </Collapse>
              </ContainerFullGraph>
            </>
          )}
        </>
      )}
      <DrawerFilter
        visible={visible}
        close={setVisible}
        title='Comparativo de tempo'
        footer={
          <ContainerButtonsFootter>
            <ButtonSearch type='button' onClick={() => formRef?.current?.submitForm()}>
              <FileSearchOutlined />
              Pesquisar
            </ButtonSearch>
            <ButtonClear onClick={() => cleanFilter()}>
              <ClearOutlined />
              Limpar
            </ButtonClear>
          </ContainerButtonsFootter>
        }
      >
        <ContainerFormFilter
          tabIndex={0}
          onKeyDown={(evt: any) => {
            if (evt.key === 'Enter') formRef?.current?.submitForm()
          }}
        >
          <Form ref={formRef} className='form' style={{ marginTop: '15px' }} onSubmit={getStatusWithFilter}>
            <SimpleRangePicker
              name='date'
              label='Selecione o periodo:'
              defaultValueInput={[moment().startOf('week'), moment().endOf('week')]}
              ranges={{
                Ontem: [moment().subtract('d', 1), moment().subtract('d', 1)],
                Hoje: [moment(), moment()],
                Amanhã: [moment().add('d', 1), moment().add('d', 1)],
                'Esta semana': [moment().startOf('week'), moment().endOf('week')],
                'Este mês': [moment().startOf('month'), moment().endOf('month')],
              }}
            />
            <GroupForm>
              <LabelForm>Selecione a promotor</LabelForm>
              <Search placeholder='Selecione o promotor' name='promoters_ids' path='promoters' isMult={true} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione a bandeira</LabelForm>
              <Search placeholder='Selecione a bandeira' name='flags_ids' path='flags' isMult={true} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione a rede</LabelForm>
              <Search placeholder='Selecione a rede' name='networks_ids' path='networks' isMult={true} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione o pdv</LabelForm>
              <Search placeholder='Selecione o pdv' name='pdvs_ids' path='pdvs' isMult={true} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione a região</LabelForm>
              <Search placeholder='Selecione a região' name='regions_ids' path='regions' isMult={true} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione o estado</LabelForm>
              <Select
                styles={customStyles}
                className='pre-select'
                placeholder='Selecione o status'
                options={optionUF}
                isMulti={true}
                name='states_ids'
              />
            </GroupForm>
          </Form>
        </ContainerFormFilter>
      </DrawerFilter>
    </div>
  )
}

export default TimeComparate
