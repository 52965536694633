/* eslint-disable jsx-a11y/alt-text */
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { Breadcrumb, Button, Checkbox, Col, message, Row } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import Input from '../../../components/form/SimpleInput'
import Select from '../../../components/form/SimpleSelect'
import { Container, customStyles, Label } from '../../../GlobalStyles'
import history from '../../../routes/history'
import { Mixpanel } from '../../../utils/mixpanel'
import { InputCheckbox, LabelCheck } from './styles'
import { Loading } from '../../../components/Loading'
import api_v2 from '../../../services/api-v2'
import { Search } from '../../../components/form/SimpleSearch'
import SimpleSelectObject from '../../../components/form/SimpleSelectObject'

const UserEditPage: React.FC = () => {
  let getWorkspace = sessionStorage.getItem('@rocketpdv:workspace')
  let newGetWorkspace = JSON.parse(getWorkspace!.toString())

  const { id }: any = useParams()
  const formRef = useRef<FormHandles>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [active, setActive] = useState<boolean>(true)
  const [client, setClient] = useState<boolean>(false)
  const [subWorkspaces, setSubWorkspaces] = useState<any>(null)
  const [data, setData] = useState<any>()

  async function loadInitialData() {
    setLoading(true)
    const { data } = await api_v2.get(`/user/${id}`)
    if(data && data.length > 0) {
      formRef.current!.setFieldValue('name', data[0]?.name)
      formRef.current!.setFieldValue('email', data[0]?.email)
      formRef.current!.setFieldValue('profile_id', {value: data[0]?.profile_id, label: data[0]?.profile_name})
      setActive(data[0].is_active)
      if(data[0]?.apps)formRef.current!.setFieldValue('apps', data[0]?.apps.map((item: any) => ({value: item, label: item})))

      if(data[0]?.config) {
        setClient(data[0]?.config?.is_client || false)
        setSubWorkspaces(data[0]?.config?.sub_workspaces || null)

        if(data[0]?.config?.no_book)formRef.current!.setFieldValue('no_book', data[0]?.config?.no_book.map((item: any) => ({value: item, label: item})))

      }

      setData(data)
    }
    setLoading(false)
  }
  useEffect(() => {
    loadInitialData()
  }, [formRef]) // eslint-disable-line react-hooks/exhaustive-deps

  async function handleSubmit(body: any) {
    setLoading(true)
    try {
      delete body.sub_workspaces
      
      if (body.profile_id && body.profile_id?.value) body.profile_id = body.profile_id?.value
      body.is_active = active
      const schema = Yup.object().shape({
        name: Yup.string().required('O Campo nome é obrigatório'),
        email: Yup.string().required('O Campo email é obrigatório'),
        profile_id: Yup.string().required('O Campo perfil é obrigatório'),
        ean: Yup.string(),
      })

      body.config = JSON.stringify({
        is_client: client,
        sub_workspaces: subWorkspaces ? subWorkspaces.map((item) => ({key: item.key, value: item.value, label: item.key})) : undefined,
        no_book: body.no_book
      })

      await schema.validate(body, {
        abortEarly: false,
      })


      const {data} = await api_v2.put(`/user/${id}`, body)

      Mixpanel.track('Editar usuário', {
        body
      })
      message.success('Usuário editado com sucesso')
      history.push('/backoffice/team/users')
    } catch (err) {
      console.log(err)
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((el: any) => {
          formRef.current!.setFieldError(el.path, el.message)
        })
      } else {
        message.error('Já existe um registro com essas informações!')
      }
    }
    setLoading(false)
  }

  return (
    <Container>
      {loading ? <Loading /> : null}
      <h1>Edição do usuário</h1>
      <p>Insira os dados para realizar a edição</p>
      <Breadcrumb style={{ paddingLeft: 10 }} separator='>' className='breadcumb'>
        <Link to='/backoffice/team/users'>
          <Breadcrumb.Item> Gestores </Breadcrumb.Item>
        </Link>
        <Breadcrumb.Item> Edição de usuário </Breadcrumb.Item>
      </Breadcrumb>
      <Form translate={undefined} className='form' ref={formRef} initialData={data} onSubmit={handleSubmit}>
        <Row gutter={16}>
          <Col xs={10} sm={10} md={11} lg={11}>
            <Input type='text' label='Nome *' name='name' placeholder='Nome' />
          </Col>
          <Col xs={10} sm={10} md={11} lg={11}>
            <Input type='text' label='Email *' name='email' placeholder='Email' />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8}>
            <p>Apps:</p>
           <SimpleSelectObject name='apps' isMulti={true} options={[{value: 'BAKCOFFICE', label: 'Backoffice'}, {value: 'BOOK', label: 'Book'}, {value: 'ANALYTICS', label: 'Analytics'} ]}  />
          </Col>
          <Col xs={24} sm={24} md={12} lg={4}>
            <p>Selecione o perfil</p>
            <Search placeholder='Selecione o perfil' name='profile_id' path='profiles' isMult={false} />
          </Col>
          <Col xs={4} sm={4} md={2} lg={2}>
            <LabelCheck>Está ativo ?</LabelCheck>
            <InputCheckbox>
              <Checkbox
                name='is_active'
                checked={active}
                defaultChecked={true}
                onChange={(e: any) => {
                  setActive(e.target.checked)
                }}
              />
            </InputCheckbox>
          </Col>
          <Col xs={4} sm={4} md={2} lg={2}>
            <LabelCheck>É cliente ?</LabelCheck>
            <InputCheckbox>
              <Checkbox
                name='is_client'
                checked={client}
                defaultChecked={true}
                onChange={(e: any) => {
                  setClient(e.target.checked)
                }}
              />
            </InputCheckbox>
          </Col>
         
          <Col xs={24} sm={24} md={12} lg={4}>
            <p>Não visualizar no book:</p>
           <SimpleSelectObject name='no_book' isMulti={true} options={[{value: 'Formulários', label: 'Formulários'}, {value: 'Visitas', label: 'Visitas'}, {value: 'Justificativas', label: 'Justificativas'} ]}  />
          </Col>
          {newGetWorkspace.shared_environment && (
            <Col xs={24} sm={24} md={12} lg={8}>
              <p>Selecione a empresa</p>
              <Search
                placeholder='Selecione as empresas que poderá visualizar'
                name='sub_workspaces'
                path='sub-workspaces'
                isMult={true}
                value={subWorkspaces}
                  onChange={(dataSelected) => {
                    setSubWorkspaces(dataSelected)
                  }}
              />
            </Col>
          )}
        </Row>

        <Row gutter={[16, 30]} style={{ marginTop: 10, paddingLeft: 10 }}>
          <Col>
            <Button type='primary' htmlType='submit'>
              Editar
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  )
}

export default UserEditPage
