import React, { useRef } from 'react'
import { Button, ContainerModal, ContainerStep2 } from './style'
import AddUser from '../../assets/images/addUser.png'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { message } from 'antd'
import api_v2 from '../../services/api-v2'
import TextArea from '../form/SimpleTextArea'

interface IProps {
  visible: boolean
  close: (boolean: any) => void
  row: any
  reload: any
}

export const ModalReproveJustify = ({ visible, close, row, reload }: IProps) => {
  const formRef = useRef<FormHandles>(null)

  async function handleSubmit(body: any) {
    try {
      if(!body.faults_denied_observation)return message.error('Observação é obrigatório.')
      const {data} = await api_v2.post(`/justify/justifie-response/${row.id}`, {...body, faults_response: 'reproved'})
      if(!data) return message.error('Erro ao reprovar justificativa')
      message.success('Justificativa reprovada')
      reload()
      close(false)
    } catch (error) {
      message.error('Erro ao reprovar justificativa')
    }
   
  }


  return (
    <ContainerModal
      visible={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
      destroyOnClose={true}
      width={450}
    >

      <Form
        translate={undefined}
        className='form'
        ref={formRef}
        initialData={{ is_active: true }}
        onSubmit={handleSubmit}
        style={{width: '100%'}}
      >
        <ContainerStep2 style={{width: '100%'}}>
          <h3>Reprovar justificativa </h3>
          <img src={AddUser} alt='user' />
          <TextArea name='faults_denied_observation' required={true} style={{width: '100%'}} label='Motivo de reprovação*'/>
          <Button type='submit' style={{ width: '100%', height: '55px', marginTop: 20 }}>
            Reprovar
          </Button>
        </ContainerStep2>
      </Form>
    </ContainerModal>
  )
}
