import styled from 'styled-components'

export const ContainerTable = styled.div`
  width: 100%;
  &&& .editable-cell-value-wrap {
    min-height: 30px;
  }

  &&& .ant-collapse-borderless {
    background-color: transparent;
  }

  &&& .ant-table {
    background-color: transparent !important;
  }

  &&& .ant-table-thead > tr > th {
    background-color: transparent !important;
  }
`
