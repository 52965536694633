import styled from 'styled-components'

export const ContainerVisit = styled.div`
  border-radius: 12px;
  border: 1px solid #d6d6d6;
  min-height: 522px;
  width: 100%;
  margin-top: 27px;
  padding: 26px 32px;
  display: flex;
`

export const ContainerShowVisits = styled.div`
  margin-top: 15px;
  max-height: 100%;
  overflow-y: auto;
  ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-track {
        background: #EBEBEB;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #666666;
        border-radius: 20px;
        border: 3px solid #EBEBEB;
    }

    &&& .ant-table{
      background-color: transparent;
    }

    &&& .ant-table-thead > tr > th{
      background-color: transparent;
      border-bottom: 2px solid #d6d6d6;
    }

    &&& .ant-table-tbody > tr > td{
      background-color: transparent;
      border-bottom: 2px solid #d6d6d6;
    }
`

export const DataFinish = styled.div`
  display: flex;
`

export const TextCheckIn = styled.p`
  color: #242424;
  font-family: "Open Sans";
  font-size: 14px;
`

export const TextDataCheckIn = styled.p`
  color: #242424;
  font-family: "Open Sans";
  font-size: 16px;
`

export const ContainerData = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  b{
    margin: 0 20px;
    font-size: 18px;
  }

  svg {
    font-size: 20px;
    cursor: pointer;
    color: #7b5ad6;
  }
`

export const ContainerCardPdv = styled.div`
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  overflow-x: scroll;
  flex-direction: column;
  border-right: 1px solid rgba(0, 0, 0, 0.07);
  padding-right: 10px;

 /* overflow-x: scroll;

  ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #b56aff;
        border: 3px solid #b56aff;
        border-radius: 5px;
    } */
`

export const ContainerSurvey = styled.div`
  width: 70%;
  height: 100%;
  overflow-x: scroll;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 20px;
  ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #b56aff;
        border: 3px solid #b56aff;
        border-radius: 5px;
    }
`

export const ContainerNo = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`