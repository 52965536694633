import React, { useState, useEffect } from 'react'
import { ContainerModal } from './style'
import { Col, message, Row, Select, Tooltip } from 'antd'
import { Loading } from '../Loading'
import { GroupForm } from '../../GlobalStyles'
import { LabelCheck } from '../ModalSubWorkspace/style'
import { Button } from '../ModalCreateVisit/style'
import { AddButtonWhite } from '../DefaultPage/style'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import api_v2 from '../../services/api-v2'
import moment from 'moment'
import { Mixpanel } from '../../utils/mixpanel'
const { Option } = Select

interface IProps {
  visible: boolean
  close: (boolean: any) => void
  dataParam: any
}

export const ModalExportPPT = ({ visible, close, dataParam }: IProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [order, setOrder] = useState<any>([{ order_by: '', type: '' }])
  const [insert, setInsert] = useState<any>([0])

  const sendOrder = async () => {
    try {
      const { flag_id, promoter_id, pdv_id, network_id, form_id, state, dateRange, question_id, sub_workspaces } = dataParam
      setLoading(true)
      let obj: any = {}
      if (flag_id && flag_id.length > 0) obj = { ...obj, flag: flag_id.map((el: any) => el.value) }

      if (promoter_id && promoter_id.length > 0) obj = { ...obj, promoter: promoter_id.map((el: any) => el.value) }

      if (sub_workspaces && sub_workspaces.length > 0) obj = { ...obj, sub_workspaces: sub_workspaces.map((el: any) => el.value) }

      if (question_id && question_id.length > 0) obj = { ...obj, question: question_id.map((el: any) => el.value) }

      if (pdv_id && pdv_id.length > 0) obj = { ...obj, pdv: pdv_id.map((el: any) => el.value) }

      if (network_id && network_id.length > 0) obj = { ...obj, network: network_id.map((el: any) => el.value) }

      if (form_id && form_id.length > 0) obj = { ...obj, form: form_id.map((el: any) => el.value) }

      if (state && state.length > 0) obj = { ...obj, state }
      if (dateRange)
        obj = {
          ...obj,
          start_date: moment(dateRange[0]).format('YYYY-MM-DD'),
          end_date: moment(dateRange[1]).format('YYYY-MM-DD'),
        }
      if (order[0].order_by === '') {
        message.error('Para gerar a exportação é necessário ao menos uma ordenação.')
        setLoading(false)
        return
      }
      let data = {
        order,
        filter: obj,
      }
      await api_v2.post('/pictures/export-ppt', data)
      Mixpanel.track('Solicitou PPT resposta', {
        params: { ...data },
      })
      message.success('Sua exportação está sendo gerada, aguarde o envio no email.')
      setLoading(false)
      close(true)
    } catch (error) {
      setLoading(false)
    }
  }

  const updateOrder = () => {
    if (order.length === 5) return message.error('Já inseriu todos os tipos de ordenação.')
    let newInser = [...insert]
    newInser.push(insert.length)
    setInsert(newInser)

    let newOrder = [...order]
    newOrder.push({ order_by: '', type: '' })
    setOrder(newOrder)
  }

  const removeOrder = (id: number) => {
    let newInsert: any = []
    insert.forEach((el: any, index: number) => {
      if (el !== id) newInsert.push(el)
    })
    let newIds = newInsert.map((el: any, index: number) => index)
    let newOrder: any = []
    order.forEach((el: any, index: number) => {
      if (index !== id) newOrder.push(el)
    })
    setInsert(newIds)
    setOrder(newOrder)
  }

  useEffect(() => {
    setOrder([{ order_by: '', type: '' }])
    setInsert([0])
  }, [visible])

  return (
    <ContainerModal
      visible={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
      style={{ top: 10 }}
      destroyOnClose={true}
    >
      {loading && <Loading />}
      <h2>Ordernar por</h2>
      <p>
        Orderne as fotos do seu jeito, com base nos campos disponíveis do sistema adicionando quantos níveis desejar.
      </p>
      <p>
        <b>Exemplo:</b>
      </p>
      <p>Você pode ordernar por Data da pesquisa, e dentre as fotos da data da pesquisa ordernar por PDV.</p>
      {insert &&
        insert.map((el: any, index: number) => (
          <Row gutter={[20, 20]} style={{ marginTop: 30 }} key={index}>
            <Col span={11}>
              <GroupForm>
                <LabelCheck style={{ textAlign: 'left' }}>Ordernar por</LabelCheck>
                <Select
                  onChange={(value: any) => {
                    let newOrder: any = [...order]
                    newOrder[el] = {
                      ...newOrder[el],
                      order_by: value,
                    }
                    setOrder(newOrder)
                  }}
                  value={order[el] && order[el].order_by && order[el].order_by}
                >
                  {JSON.stringify(order).indexOf('ID Pesquisa') === -1 && (
                    <Option value='ID Pesquisa'>ID Pesquisa</Option>
                  )}
                  {JSON.stringify(order).indexOf('Data Pesquisa') === -1 && (
                    <Option value='Data Pesquisa'>Data Pesquisa</Option>
                  )}
                  {JSON.stringify(order).indexOf('Formulário') === -1 && <Option value='Formulário'>Formulário</Option>}
                  {JSON.stringify(order).indexOf('Questão') === -1 && <Option value='Questão'>Questão</Option>}
                  {JSON.stringify(order).indexOf('PDV') === -1 && <Option value='PDV'>PDV</Option>}
                </Select>
              </GroupForm>
            </Col>
            <Col span={11}>
              <GroupForm>
                <LabelCheck style={{ textAlign: 'left' }}>Tipo</LabelCheck>
                <Select
                  onChange={(value: any) => {
                    let newOrder: any = [...order]
                    newOrder[el] = {
                      ...newOrder[el],
                      type: value,
                    }
                    setOrder(newOrder)
                  }}
                  value={order[el] && order[el].type && order[el].type}
                >
                  <Option value='Crescente'>Crescente</Option>
                  <Option value='Descrecente'>Decrescente</Option>
                </Select>
              </GroupForm>
            </Col>
            <Col span={2}>
              <Tooltip title='Deletar ordenação'>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <DeleteOutlined
                    style={{ fontSize: 18, color: 'red', cursor: 'pointer' }}
                    onClick={() => removeOrder(el)}
                  />
                </div>
              </Tooltip>
            </Col>
          </Row>
        ))}

      <Row gutter={[8, 8]}>
        <Col span={12}>
          <AddButtonWhite
            onClick={() => updateOrder()}
            style={{ width: '100%', height: '55px', marginTop: 20, fontSize: '16px' }}
          >
            <PlusOutlined style={{ marginRight: 10 }} />
            Adicionar ordem
          </AddButtonWhite>
        </Col>
        <Col span={12}>
          <Button style={{ width: '100%', height: '55px', marginTop: 20 }} onClick={() => sendOrder()}>
            Exportar
          </Button>
        </Col>
      </Row>
    </ContainerModal>
  )
}
