import React, { useState, useEffect, useMemo } from 'react'
import { ContainerModal } from './style'
import api_v2 from '../../services/api-v2'
import { ColumnsType } from 'antd/lib/table'
import { compareByAlph } from '../../utils/funcoes'
import { INIT_GLOBAL_RESPONSE } from '../../utils/global-response'
import qs from 'querystring'
import { Button, Col, Divider, Popover, Row, Table } from 'antd'
import moment from 'moment'
import useReactRouter from 'use-react-router'
import { debounce } from 'lodash'
import { ContainerSearch, Search } from '../../pages/Team/Promoter/style'
import { MdSearch } from 'react-icons/md'
import { ContainerPopover } from '../../pages/MonitoryPage/MonitoringPage/styles'
import { ModalImportGeneral } from '../ModalImportGeneral'
import { ContextUpload } from '../../context/contextUpload'
import { hasIndex } from '../Menu/menuHorizonte'

interface IProps {
  visible: boolean
  close: (boolean: any) => void
  id: number
}

const columns: ColumnsType<any> = [
  {
    title: 'Mix de seleção',
    dataIndex: 'mix_context',
    sorter: (a, b) => a.mix_context.localeCompare(b.mix_context),
  },
  {
    title: 'Nome do PDV',
    dataIndex: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: 'Data de criação',
    dataIndex: 'created_at',
    sorter: (a, b) => compareByAlph(a.created_at, b.created_at),
  },
]

export const ModalMixForm = ({ visible, close, id }: IProps) => {
  const { permissions } = ContextUpload()
  const [mix, setMix] = useState<any>(INIT_GLOBAL_RESPONSE)
  const [dtSearch, setDtSearch] = useState<any>([])
  const [namePromoter, setNamePromoter] = useState<any>('')
  const [visiblePopver, setVisiblePopver] = useState<any>('')
  const [visibleImport, setVisibleImport] = useState<boolean>(false)
  const [checked, setChecked] = useState<any>([])

  const { location } = useReactRouter()

  const handleTable = async (queryParam: any) => {
    setMix({ ...mix, loading: true })
    let filterQuery: any = {}

    if (namePromoter) {
      filterQuery.name = namePromoter
    }

    filterQuery = {
      ...filterQuery,
      perpage: queryParam.pageSize,
      page: queryParam.current,
    }
    const query = qs.stringify(filterQuery)

    const { data } = await api_v2.get(`/form/mix/${id}?${query}`)
    setMix({ ...data, loading: false })
  }

  const getMix = async (evt: any) => {
    try {
      let name = evt?.target?.value
      let filterQuery: any = {}

      if (name) {
        filterQuery.name = name
        setNamePromoter(name)
      }

      if (dtSearch) {
        filterQuery.start_date = moment(dtSearch[0]).format('YYYY-MM-DD').toString()
        filterQuery.end_date = moment(dtSearch[1]).format('YYYY-MM-DD').toString()
      }

      const queryFilter = qs.stringify({ ...filterQuery })

      const { data } = await api_v2.get(`/form/mix/${id}?${queryFilter}`)
      setMix({ ...data, loading: false })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getMix({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandler = useMemo(() => debounce(getMix, 300), [])

  useEffect(() => {
    let obj: any = qs.parse(location.search.replaceAll('?', ''))
    if (obj) {
      setDtSearch([moment(obj.date_start), moment(obj.date_end)])
    }

    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      let numbers: any = []
      selectedRowKeys.forEach((el: any) => {
        let inNumber = checked.includes(el)
        if (!inNumber) numbers.push(el)
      })
      setChecked([...checked, ...numbers])
    },
    onSelect: (record: any, selected: any, selectedRows: any, nativeEvent: any) => {
      if (selected) {
        let numbers: number[] = []
        selectedRows.forEach((el: any) => {
          let inNumber = checked.includes(el.id)
          if (!inNumber) numbers.push(el)
        })
        setChecked([...checked, ...numbers])
      } else {
        let remove: any = checked.filter((el: any) => el !== record.id)
        setChecked(remove)
      }
    },
  }

  return (
    <ContainerModal
      visible={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
      width={700}
    >
      <h3>Visualização de mix</h3>
      <Row justify='space-between' style={{ marginTop: 20, marginBottom: 20 }} gutter={[20, 20]}>
        <Col>
          <p>Buscar pelo nome:</p>
          <ContainerSearch style={{ height: 30 }}>
            <Search placeholder='Buscar por nome' onChange={debouncedChangeHandler} />
            <MdSearch size={20} color={'#A56EFC'} />
          </ContainerSearch>
        </Col>
        {hasIndex([
          "form.import_form_mix",
          "form.remove_form_mix",
        ], permissions) > -1 &&
          <Col>
            <Popover
              content={
                <ContainerPopover>
                  {hasIndex([
                    "form.import_form_mix",
                  ], permissions) > -1 &&
                    <>
                      <p
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setVisibleImport(!visibleImport)
                          setVisiblePopver(!visiblePopver)
                        }}
                      >
                        Importar
                      </p>
                      <Divider />
                    </>
                  }
                  {hasIndex([
                    "form.remove_form_mix",
                  ], permissions) > -1 &&
                    <p
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setVisiblePopver(!visiblePopver)
                      }}
                    >
                      Exportar
                    </p>
                  }
                </ContainerPopover>
              }
              trigger='hover'
              visible={visiblePopver}
              onVisibleChange={() => setVisiblePopver(!visiblePopver)}
              placement='left'
            >
              <Button
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  borderRadius: 5,
                  color: '#6f7782',
                  padding: 8,
                }}
              >
                Ações
              </Button>
            </Popover>
          </Col>
        }
      </Row>
      <Table
        // scroll={{ x: 'max-content' }}
        columns={columns}
        tableLayout='auto'
        rowKey={(mixTable) => mixTable.id}
        dataSource={mix.result}
        rowSelection={{
          type: 'checkbox',
          hideSelectAll: true,
          ...rowSelection,
        }}
        size='small'
        pagination={{
          // position: 'both',
          current: mix.page,
          pageSize: mix.perpage,
          total: mix.total,
          size: 'small',
          showSizeChanger: true,
          showTotal: () => `Exibindo ${mix.result.length} de ${mix.total} de registros`,
        }}
        loading={mix.loading}
        onChange={handleTable}
      />
      {visibleImport && (
        <ModalImportGeneral
          visible={visibleImport}
          close={setVisibleImport}
          title='Importar mix de formulários'
          path='MIX_FORM'
          config={{ form_id: id }}
        />
      )}
    </ContainerModal>
  )
}
