import {
  Col,
  Row,
  Tooltip,
  DatePicker,
  TablePaginationConfig,
  Dropdown,
  Menu,
  Button,
  Popover,
  Divider,
  Modal,
  message,
} from 'antd'
import { CardStatus, ContainerMenu, DataNumber, TextHeader, TextInfo } from '../TabGeneralUser/style'
import Ticket from '../../assets/images/ticket.png'
import { PieChart, Pie, Cell } from 'recharts'
import {
  ColumnVisits,
  ContainerChart,
  ContainerDataChart,
  ContainerShowVisits,
  ContainerStatusChart,
  ContainerTasks,
  ContainerVisit,
  CountVisits,
  DataStatus,
} from './style'
import { Table } from 'antd'
import { ButtonBack } from '../../pages/Team/ViewUser/styles'
import {
  PlusOutlined,
  DownOutlined,
  CheckSquareOutlined,
  InfoCircleOutlined,
  SyncOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined,
  CalendarOutlined,
  CopyOutlined,
  PlusSquareOutlined,
  DeleteOutlined,
  CaretRightOutlined,
  EyeOutlined,
  MoreOutlined,
  ExclamationCircleOutlined,
  HighlightOutlined,
  AppstoreOutlined,
  SettingOutlined,
  FormOutlined,
} from '@ant-design/icons'
import { Key, useEffect, useRef, useState } from 'react'
import { IUserInfo } from '../CardUser'
import { useParams } from 'react-router'
import { Loading } from '../Loading'
import { ModalCreateVisit } from '../ModalCreateVisit'
import { ModalCreateTask } from '../ModalCreateTask'
import { ModalDuplicateVisit } from '../ModalDuplicateVisit'
import api_v2 from '../../services/api-v2'
import moment from 'moment'
import qs from 'qs'
import { Link } from 'react-router-dom'
import { ContainerPopover } from '../../pages/MonitoryPage/MonitoringPage/styles'
import { CardVisits } from '../CardVisits'
import { CardCreate } from '../ModalCreateVisit/style'
import AddUser from '../../assets/images/addUser.png'
import locale from 'antd/es/date-picker/locale/pt_BR'
import history from '../../routes/history'
// import useReactRouter from 'use-react-router'
import { ModalEditStatus } from '../ModalEditStatus'
import { getStatus } from '../../pages/Survey/VisitsPage'
import { Mixpanel } from '../../utils/mixpanel'
import { ModalViewSubWorkspace } from '../ModalViewSubWorkspace'
import { ContextUpload } from '../../context/contextUpload'
import { hasIndex } from '../Menu/menuHorizonte'
import { ModalAddTask } from '../ModalAddTask'
import { addIsVisit } from '../../utils/addScript'
import { ModalMoveVisits } from '../ModalMoveVisits'
import { IoBagRemoveSharp } from 'react-icons/io5'
const { confirm } = Modal

interface Props {
  dateFilter: any
  setterDateFilter: any
}

export const TabsVisits: React.FC<Props> = ({ dateFilter, setterDateFilter }) => {
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042']
  const { permissions } = ContextUpload()

  // const COLORS = ['#FFBB28', '#00C49F', '#0088FE', '#ff0000']

  const [openModalVisit, setOpenModalVisit] = useState(false)
  const [openModalTask, setOpenModalTask] = useState(false)
  const [user, setUser] = useState<IUserInfo>({} as IUserInfo)
  const [loading, setLoading] = useState(false)

  const { id }: any = useParams()
  // const { location } = useReactRouter()

  let getWorkspace = sessionStorage.getItem('@rocketpdv:workspace')
  let newGetWorkspace = JSON.parse(getWorkspace!.toString())
  const ref: any = useRef(null)
  const [countVisit, setVisit] = useState(-1)
  const [countVisitPendent, setVisitPendent] = useState(-1)
  const [countVisitComplete, setVisitComplete] = useState(-1)
  const [countVisitInProgress, setVisitInProgress] = useState(-1)
  const [countVisitAbsence, setVisitAbsence] = useState(-1)
  const [visible, setVisible] = useState(false)
  const [openModalMove, setOpenModalMove] = useState(false)
  const [visibleAddForm, setVisibleAddForm] = useState(false)
  const [visibleEditStatus, setVisibleEditStatus] = useState(false)
  const [visibleEditDate, setVisibleEditDate] = useState(false)
  const [visibleEditDateLot, setVisibleEditDateLot] = useState(false)
  const [openModalSubWorkspaces, setOpenModalSubWorkspaces] = useState(false)
  const [openModalDuplicateVisit, setOpenModalDuplicateVisit] = useState(false)
  const [selectVisit, setSelectVisit] = useState<any>({})
  const [selectedVisits, setSelectedVisits] = useState<Key[]>([])
  const [selectedAllVisit, setSelectedAllVisit] = useState<any[]>([])
  const [dateSelected, setDateSelected] = useState<any>()
  const [typeView, setTypeView] = useState(1)
  const [visitList, setVisitList] = useState({
    result: [],
    filter: {},
    perpage: 0,
    page: 0,
    total: 0,
    loading: true,
  })

  const [chart, setChart] = useState([
    { name: 'Pendente', value: 0 },
    { name: 'Completa', value: 0 },
    { name: 'Em Andamento', value: 0 },
    { name: 'Justificada', value: 0 },
  ])

  const dateFormat = 'DD/MM/YYYY'

  let { start_date, end_date } = dateFilter || {
    start_date: moment(new Date()).format('YYYY-MM-DD'),
    end_date: moment(new Date()).format('YYYY-MM-DD'),
  }

  const loadCards = async () => {
    setVisit(-1)
    setVisitPendent(-1)
    setVisitComplete(-1)
    setVisitInProgress(-1)
    setVisitAbsence(-1)

    const { data: visit } = await api_v2.get(`/total/visits/${id}?start_date=${start_date}&end_date=${end_date}`)
    setVisit(visit.total)

    const { data: pendent } = await api_v2.get(
      `/total/visits/${id}?status=PENDENT&start_date=${start_date}&end_date=${end_date}`
    )
    setVisitPendent(pendent.total)

    const { data: complete } = await api_v2.get(
      `/total/visits/${id}?status=COMPLETE&start_date=${start_date}&end_date=${end_date}`
    )
    setVisitComplete(complete.total)

    const { data: in_progress } = await api_v2.get(
      `/total/visits/${id}?status=IN_PROGRESS&start_date=${start_date}&end_date=${end_date}`
    )
    setVisitInProgress(in_progress.total)

    const { data: absence } = await api_v2.get(
      `/total/visits/${id}?status=JUSTIFIED_ABSENCE&start_date=${start_date}&end_date=${end_date}`
    )
    setVisitAbsence(absence.total)
  }

  const getVisits = async () => {
    setVisitList({ ...visitList, loading: true })
    const { data } = await api_v2.get(`visit?promoter_id=${id}&start_date=${start_date}&end_date=${end_date}`)
    setVisitList(data)
  }

  useEffect(() => {
    getVisits()
    loadCards()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dateFilter])

  useEffect(() => {
    setChart([
      { name: `Pendente (${countVisitPendent})`, value: countVisitPendent },
      { name: `Completa (${countVisitComplete})`, value: countVisitComplete },
      { name: `Em Andamento (${countVisitInProgress})`, value: countVisitInProgress },
      { name: `Justificada (${countVisitAbsence})`, value: countVisitAbsence },
    ])
  }, [countVisitPendent, countVisitComplete, countVisitInProgress, countVisitAbsence])

  const deleteVisit = async (ids: any[]) => {
    if(!addIsVisit()) return message.error('Sem autorização')
    return confirm({
      title: 'Deletar visitas',
      icon: <ExclamationCircleOutlined />,
      content: 'Tem certeza que deseja deletar essas visitas ?',
      async onOk() {
        try {
          setLoading(true)

          if (ids.length === 0) {
            message.error('Selecione pelo menos uma visita para continuar')
            return
          }

          for await (let el of ids) {
            await api_v2.delete(`/visit/${el}`)
            Mixpanel.track('Deletou visita', { id_visit: el })
          }

          message.success('Visita deletada com sucesso')
          setSelectedVisits([])
          getVisits()
          loadCards()
          setLoading(false)
        } catch (error) {
          console.log(error)
          setLoading(false)
          message.error('Erro ao deletar visita')
        }
      },
      onCancel() {
        setSelectedVisits([])
      },
    })
  }

  const columns: any = [
    {
      title: '#',
      width: 50,
      render: (a: any) => {
        return (
          <>
            {a.status === 'PENDENT' ? (
              <Dropdown
                overlay={
                  <Menu>
                    {hasIndex(['team.change_visit_status'], permissions) > -1 && (
                      <Menu.Item
                        key='0'
                        icon={<HighlightOutlined />}
                        onClick={() => {
                          if(!addIsVisit()) return message.error('Sem autorização')
                          setSelectVisit(a)
                          setVisibleEditStatus(!visibleEditStatus)
                        }}
                      >
                        Alterar status
                      </Menu.Item>
                    )}
                    {hasIndex(['team.change_visit_status'], permissions) > -1 && (
                      <Menu.Item
                        key='0'
                        icon={<FormOutlined />}
                        onClick={() => {
                          if(!addIsVisit()) return message.error('Sem autorização')
                          setSelectVisit(a)
                          setVisibleAddForm(!visibleAddForm)
                        }}
                      >
                        Adicionar formulário
                      </Menu.Item>
                    )}
                    {hasIndex(['team.change_visit_date'], permissions) > -1 && (
                      <Menu.Item
                        key='1'
                        icon={<CalendarOutlined />}
                        onClick={() => {
                          if(!addIsVisit()) return message.error('Sem autorização')
                          setSelectVisit(a)
                          setVisibleEditDate(!visibleEditDate)
                        }}
                      >
                        Alterar data
                      </Menu.Item>
                    )}
                    <Menu.Item
                      key='2'
                      icon={<CopyOutlined />}
                      onClick={() => {
                        if(!addIsVisit()) return message.error('Sem autorização')
                        setSelectedAllVisit([a])
                        setOpenModalDuplicateVisit(!openModalDuplicateVisit)
                      }}
                    >
                      Duplicar
                    </Menu.Item>
                    {/* <Menu.Item key='3' icon={<SwapOutlined />}>
                      Transferir
                    </Menu.Item> */}
                    <Menu.Item
                      key='5'
                      icon={<PlusSquareOutlined />}
                      onClick={() => {
                        setSelectVisit(a)
                        setOpenModalTask(true)
                      }}
                    >
                      Visualizar tarefas
                    </Menu.Item>
                    {newGetWorkspace.shared_environment && (
                      <Menu.Item
                        key='8'
                        icon={<AppstoreOutlined />}
                        onClick={() => {
                          setSelectVisit(a)
                          setOpenModalSubWorkspaces(true)
                        }}
                      >
                        Visualizar empresas
                      </Menu.Item>
                    )}
                    {hasIndex(['team.remove_task'], permissions) > -1 && (
                      <Menu.Item key='5' icon={<IoBagRemoveSharp />} onClick={() => {
                        setSelectVisit(a)
                        setOpenModalMove(true)
                      }}>
                        Mover visita
                      </Menu.Item>
                    )}
                    {hasIndex(['team.remove_task'], permissions) > -1 && (
                      <Menu.Item key='4' icon={<DeleteOutlined />} danger={true} onClick={() => deleteVisit([a.id])}>
                        Excluir
                      </Menu.Item>
                    )}
                    
                  </Menu>
                }
              >
                <Button size='small' shape='circle' icon={<MoreOutlined />} />
              </Dropdown>
            ) : a.status === 'IN_PROGRESS' ? (
              <Dropdown
                overlay={
                  <Menu>
                    {hasIndex(['team.change_visit_status'], permissions) > -1 && (
                      <Menu.Item
                        key='0'
                        icon={<HighlightOutlined />}
                        onClick={() => {
                          setSelectVisit(a)
                          setVisibleEditStatus(!visibleEditStatus)
                        }}
                      >
                        Alterar status
                      </Menu.Item>
                    )}
                    {hasIndex(['team.change_visit_status'], permissions) > -1 && (
                      <Menu.Item
                        key='0'
                        icon={<FormOutlined />}
                        onClick={() => {
                          setSelectVisit(a)
                          setVisibleAddForm(!visibleAddForm)
                        }}
                      >
                        Adicionar formulário
                      </Menu.Item>
                    )}
                    <Menu.Item
                      key='5'
                      icon={<PlusSquareOutlined />}
                      onClick={() => {
                        setSelectVisit(a)
                        setOpenModalTask(true)
                      }}
                    >
                      Visualizar tarefas
                    </Menu.Item>
                    {newGetWorkspace.shared_environment && (
                      <Menu.Item
                        key='8'
                        icon={<AppstoreOutlined />}
                        onClick={() => {
                          setSelectVisit(a)
                          setOpenModalSubWorkspaces(true)
                        }}
                      >
                        Visualizar empresas
                      </Menu.Item>
                    )}
                  </Menu>
                }
              >
                <Button size='small' shape='circle' icon={<MoreOutlined />} />
              </Dropdown>
            ) : (
              <Dropdown
                overlay={
                  <Menu>
                    {hasIndex(['team.change_visit_status'], permissions) > -1 && (
                      <Menu.Item
                        key='0'
                        icon={<HighlightOutlined />}
                        onClick={() => {
                          setSelectVisit(a)
                          setVisibleEditStatus(!visibleEditStatus)
                        }}
                      >
                        Alterar status
                      </Menu.Item>
                    )}
                    {newGetWorkspace.shared_environment && (
                      <Menu.Item
                        key='8'
                        icon={<AppstoreOutlined />}
                        onClick={() => {
                          setSelectVisit(a)
                          setOpenModalSubWorkspaces(true)
                        }}
                      >
                        Visualizar empresas
                      </Menu.Item>
                    )}
                  </Menu>
                }
              >
                <Button size='small' shape='circle' icon={<MoreOutlined />} />
              </Dropdown>
            )}
          </>
        )
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 80,
      render: (status: any, all: any) => {
        return <DataStatus>{getStatus(status, all.justify, all.reason)}</DataStatus>
      },
    },
    {
      title: 'Nome',
      dataIndex: ['pdv', 'name'],
      key: 'pdv_name',
      width: 110,
      ellipsis: true,
    },
    {
      title: 'Data',
      width: 110,
      dataIndex: 'dt_visit',
      key: 'dt_visit',
    },
    {
      title: 'Check-in/out',
      key: 'check_in',
      width: 190,
      render: (data: any) => (
        <>
          {data.check_in !== '-' && (
            <Tooltip title='Check-in' placement='left'>
              <div style={{ color: '#00cec9', textAlign: 'center' }}>
                <ArrowRightOutlined /> {data.check_in}
              </div>
            </Tooltip>
          )}
          {data.check_out !== '-' && (
            <Tooltip title='Check-out' placement='left'>
              <div style={{ color: '#ff7675', textAlign: 'center' }}>
                <ArrowLeftOutlined /> {data.check_out}
              </div>
            </Tooltip>
          )}
        </>
      ),
    },
    {
      title: 'Tarefas',
      width: 180,
      render: (data: any, dt: any) => {
        return (
          <ContainerTasks>
            {moment
              .duration(
                moment(moment(data.dt_visit, 'DD/MM/YYYY')).diff(moment(moment().format('DD/MM/YYYY'), 'DD/MM/YYYY'))
              )
              .asDays() > 0 && dt.task_count === 0 ? (
              <Tooltip placement='top' title='As terefas serão criadas apenas no dia da visita.'>
                <div style={{ marginRight: 30, display: 'flex', alignItems: 'center', color: 'rgb(255 172 66)' }}>
                  <SettingOutlined style={{ color: 'rgb(255 172 66)' }} />
                  Processando
                </div>
              </Tooltip>
            ) : (
              <div
                style={{ marginRight: 30 }}
                onClick={() => {
                  setSelectVisit(data)
                  setOpenModalTask(true)
                }}
              >
                <CheckSquareOutlined />
                {data.task_count} Tarefas
              </div>
            )}
          </ContainerTasks>
        )
      },
    },
    {
      title: 'Local',
      dataIndex: ['pdv', 'address'],
      // key: 'locale',
      width: 180,
      ellipsis: true,
    },
    {
      title: <CaretRightOutlined />,
      // fixed: 'right',
      width: 60,
      render: (data: any) => (
        <Tooltip placement='top' title='Ver mais'>
          <div style={{ width: '100%', height: '100%', fontSize: 18 }}>
            <Link to={`/backoffice/survey/visitsDetails/${id}/${data.id}`}>
              <InfoCircleOutlined />
            </Link>
          </div>
        </Tooltip>
      ),
    },
  ]

  useEffect(() => {
    const loadPerfis = async () => {
      setLoading(true)
      const { data } = await api_v2.get(`/user/${id}`)
      setUser(data[0])
      setLoading(false)
    }

    loadPerfis()
  }, [id])

  const handleTableChange = async ({ pageSize: perpage, current: page }: TablePaginationConfig) => {
    const query = {
      promoter_id: id,
      start_date,
      end_date,
      perpage,
      page,
    }

    setVisitList({ ...visitList, loading: true })
    const { data } = await api_v2.get(`visit?` + qs.stringify(query))
    setVisitList(data)

    document.getElementById('scrollTo')?.scrollIntoView({ block: 'start', behavior: 'smooth' })
  }

  const disabledDate = (current: any) => {
    return current && current < moment(new Date()).subtract(1, 'd').endOf('day')
  }

  const alterDateUnique = async () => {
    try {
      if (!dateSelected) {
        message.error('Selecione uma data válida.')
        return
      }

      await api_v2.put(`/visit/${selectVisit.id}/change_date`, { dt_visit: moment(dateSelected).format('YYYY-MM-DD') })
      message.success('Data de visita alterada com sucesso')
      Mixpanel.track('Alterou data 1 visita', {
        params: { visit: selectVisit.id, data_visit: moment(dateSelected).format('YYYY-MM-DD') },
      })
      setVisibleEditDate(!visibleEditDate)
      setSelectVisit({})
      setDateSelected(null)
      getVisits()
      loadCards()
    } catch (error) {
      console.log(error)
      message.error('Erro ao alterar data de visita')
    }
  }

  const alterDateLot = async () => {
    try {
      setLoading(true)
      if (!dateSelected) {
        message.error('Selecione uma data válida.')
        return
      }

      if (selectedVisits.length === 0) {
        message.error('Selecione pelo menos uma visita para continuar')
        return
      }

      for await (let el of selectedVisits) {
        await api_v2.put(`/visit/${el}/change_date`, { dt_visit: moment(dateSelected).format('YYYY-MM-DD') })
      }

      Mixpanel.track('Alterou data varias visita', {
        params: { visits: selectedVisits, dt_visit: moment(dateSelected).format('YYYY-MM-DD') },
      })

      message.success('Data de visita alterada com sucesso')
      setVisibleEditDateLot(!visibleEditDate)
      setSelectVisit({})
      setDateSelected(null)
      getVisits()
      loadCards()
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
      message.error('Erro ao alterar data de visita')
    }
  }

  // useEffect(() => {
  //   setDtSearch(dateFilter)
  // }, [dateFilter])

  const restart = async (res: boolean) => {
    if (res) {
      getVisits()
      loadCards()
    }
  }

  // useEffect(() => {
  //   let obj: any = qs.parse(location.search.replaceAll('?', ''))

  //   if (obj && obj.date_start) {
  //     setDtSearch([moment(obj.date_start), moment(obj.date_end)])
  //   }

  //   window.scrollTo(0, 0)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location.search])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Row gutter={[40, 40]} id={'scrollTo'} justify='space-between'>
            <Col span={24}>
              <ContainerMenu>
                <DatePicker.RangePicker
                  ranges={{
                    Ontem: [moment().subtract('d', 1), moment().subtract('d', 1)],
                    Hoje: [moment(), moment()],
                    Amanhã: [moment().add('d', 1), moment().add('d', 1)],
                    'Esta semana': [moment().startOf('week'), moment().endOf('week')],
                    'Este mês': [moment().startOf('month'), moment().endOf('month')],
                  }}
                  value={[moment(dateFilter.start_date, 'YYYY-MM-DD'), moment(dateFilter.end_date, 'YYYY-MM-DD')]}
                  defaultValue={[
                    moment(dateFilter.start_date, 'YYYY-MM-DD'),
                    moment(dateFilter.end_date, 'YYYY-MM-DD'),
                  ]}
                  format={dateFormat}
                  onChange={(val) => {
                    let start = ''
                    let end = ''

                    if (val && val[0]) start = val[0].format('YYYY-MM-DD')
                    if (val && val[1]) end = val[1].format('YYYY-MM-DD')

                    history.push(`/backoffice/teamById/${id}?start_date=${start}&end_date=${end}`)

                    // setterDateFilter(val)
                    // setDtSearch(val)
                  }}
                  style={{ width: 300, marginBottom: 10 }}
                />
                {hasIndex(['team.add_task'], permissions) > -1 && (
                  <ButtonBack
                    style={{
                      backgroundColor: '#6600cc',
                      color: '#fff',
                      width: 156,
                    }}
                    onClick={() => {
                      if(!addIsVisit()) return message.error('Sem autorização')
                        setOpenModalVisit(true)
                    }}
                  >
                    <PlusOutlined style={{ fontSize: 20 }} />
                    Adicionar visita
                  </ButtonBack>
                )}
                {/* <Tooltip placement='top' title={'Filtrar'}>
                  <ButtonBack style={{ width: 'auto', height: 'auto', padding: 10 }}>
                    <FilterOutlined style={{ fontSize: 20 }} />
                  </ButtonBack>
                </Tooltip> */}
              </ContainerMenu>
            </Col>
            <Col xs={24} sm={12} md={8} lg={4}>
              <CardStatus>
                <TextHeader>Total de visitas</TextHeader>
                <TextInfo>Agendadas + Roteiro</TextInfo>
                <DataNumber>
                  <img src={Ticket} alt='Ticket' /> {countVisit < 0 ? <SyncOutlined spin /> : countVisit}
                </DataNumber>
              </CardStatus>
            </Col>
            <Col xs={24} sm={12} md={8} lg={4}>
              <CardStatus>
                <TextHeader>Visitas Completas</TextHeader>
                <TextInfo>Check-in & Check-out</TextInfo>
                <DataNumber>
                  <img src={Ticket} alt='Ticket' />{' '}
                  {countVisitComplete < 0 ? <SyncOutlined spin /> : countVisitComplete}
                </DataNumber>
              </CardStatus>
            </Col>
            <Col xs={24} sm={12} md={8} lg={5}>
              <CardStatus>
                <TextHeader>Gráfico de visitas</TextHeader>
                <ContainerChart>
                  <PieChart width={81} height={81}>
                    <Pie
                      data={chart}
                      cx={'50%'}
                      cy={'50%'}
                      innerRadius={30}
                      outerRadius={40}
                      fill='#8884d8'
                      paddingAngle={5}
                      dataKey='value'
                    >
                      {chart.map((entry: any, index: number) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                  </PieChart>
                  <ContainerStatusChart>
                    {chart.length > 0 &&
                      chart.map((entry: any, index: number) => (
                        <ContainerDataChart color={COLORS[index]} key={index}>
                          <div />
                          {entry.name}
                        </ContainerDataChart>
                      ))}
                  </ContainerStatusChart>
                </ContainerChart>
              </CardStatus>
            </Col>
            <Col xs={24} sm={12} md={8} lg={4}>
              <CardStatus>
                <TextHeader>Visitas pendentes</TextHeader>
                <TextInfo>Sem check-in</TextInfo>
                <DataNumber>
                  <img src={Ticket} alt='Ticket' /> {countVisitPendent < 0 ? <SyncOutlined spin /> : countVisitPendent}
                </DataNumber>
              </CardStatus>
            </Col>
            <Col xs={24} sm={12} md={8} lg={4}>
              <CardStatus>
                <TextHeader>Visitas Justificadas</TextHeader>
                <TextInfo>Falta com justificativa</TextInfo>
                <DataNumber>
                  <img src={Ticket} alt='Ticket' /> {countVisitAbsence < 0 ? <SyncOutlined spin /> : countVisitAbsence}
                </DataNumber>
              </CardStatus>
            </Col>
          </Row>
          <ContainerVisit>
            <div style={{ display: 'flex', justifyContent: 'space-between', maxWidth: 100 }}>
              <ColumnVisits>
                <TextHeader>Visitas</TextHeader>
              </ColumnVisits>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Popover
                  content={
                    <ContainerPopover>
                      <p
                        style={{ cursor: 'pointer', color: typeView === 1 ? '#0984e3' : '' }}
                        onClick={() => {
                          setTypeView(1)
                          setVisible(!visible)
                        }}
                      >
                        Tabelas
                      </p>
                      <Divider />
                      <p
                        style={{ cursor: 'pointer', color: typeView === 0 ? '#0984e3' : '' }}
                        onClick={() => {
                          setTypeView(0)
                          setVisible(!visible)
                        }}
                      >
                        Cards
                      </p>
                    </ContainerPopover>
                  }
                  trigger='hover'
                  visible={visible}
                  onVisibleChange={() => setVisible(!visible)}
                  placement='left'
                >
                  <Button
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      borderRadius: 5,
                      color: '#6f7782',
                      padding: 8,
                    }}
                  >
                    <EyeOutlined size={20} />
                    Modo de visualização
                  </Button>
                </Popover>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {selectedVisits.length > 0 && (
                    <Dropdown
                      overlay={
                        <Menu>
                          {hasIndex(['team.change_visit_date'], permissions) > -1 && (
                            <Menu.Item
                              key='1'
                              icon={<CalendarOutlined />}
                              onClick={() => setVisibleEditDateLot(!visibleEditDateLot)}
                            >
                              Alterar data
                            </Menu.Item>
                          )}
                          <Menu.Item
                            key='2'
                            icon={<CopyOutlined />}
                            onClick={() => setOpenModalDuplicateVisit(!openModalDuplicateVisit)}
                          >
                            Duplicar
                          </Menu.Item>
                          {/* <Menu.Item key='3' icon={<SwapOutlined />}>
                            Transferir
                          </Menu.Item> */}
                          {hasIndex(['team.remove_task'], permissions) > -1 && (
                            <Menu.Item
                              key='4'
                              icon={<DeleteOutlined />}
                              danger={true}
                              onClick={() => deleteVisit(selectedVisits)}
                            >
                              Excluir
                            </Menu.Item>
                          )}
                        </Menu>
                      }
                    >
                      <Button>
                        Ações em massa ({selectedVisits.length}) <DownOutlined />
                      </Button>
                    </Dropdown>
                  )}
                  <CountVisits>{visitList.total}</CountVisits>
                </div>
              </div>
            </div>
            <ContainerShowVisits ref={ref}>
              {typeView === 1 ? (
                // <ContainerTableDefault width={wTable}>
                <Table
                  rowSelection={{
                    selectedRowKeys: selectedVisits,
                    onChange: (selectedRowKeys) => {
                      let filter: any = []
                      selectedRowKeys.forEach((keyVisit: any) => {
                        visitList.result.forEach((el: any) => {
                          if (el.id === keyVisit) {
                            filter.push(el)
                          }
                        })
                      })

                      setSelectedAllVisit(filter)
                      setSelectedVisits(selectedRowKeys)
                    },
                    getCheckboxProps: (record: any) => ({
                      disabled: record.status !== 'PENDENT',
                    }),
                  }}
                  // scroll={{ x: "max-content" }}
                  rowKey='id'
                  dataSource={visitList.result}
                  columns={columns}
                  onChange={handleTableChange}
                  loading={visitList.loading || false}
                  pagination={{
                    current: visitList.page,
                    pageSize: visitList.perpage,
                    total: visitList.total,
                    size: 'small',
                    showSizeChanger: true,
                    showTotal: () => `Exibindo ${visitList.result.length} de ${visitList.total} de registros`,
                  }}
                />
              ) : (
                // </ContainerTableDefault>
                <Row gutter={[25, 25]}>
                  {visitList.result.map((el: any, index: any) => (
                    <Col span={8} key={index}>
                      <CardVisits data={el} user={user} />
                    </Col>
                  ))}
                </Row>
              )}
            </ContainerShowVisits>
          </ContainerVisit>
          {visibleEditDate && (
            <Modal
              visible={visibleEditDate}
              footer={null}
              maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
              onCancel={() => setVisibleEditDate(!visibleEditDate)}
              closable={true}
              width={300}
            >
              <CardCreate style={{ width: '100%' }}>
                <img src={AddUser} alt='user' />
                <p>Alterar data da visita de</p>
                {selectVisit && <b>{selectVisit.dt_visit} </b>}
                <p>para</p>
                <DatePicker
                  style={{ width: '100%' }}
                  disabledDate={disabledDate}
                  locale={locale}
                  onChange={setDateSelected}
                  format={dateFormat}
                />
                <ButtonBack
                  style={{
                    backgroundColor: '#6600cc',
                    color: '#fff',
                    width: 156,
                    marginTop: 30,
                  }}
                  onClick={() => alterDateUnique()}
                >
                  <PlusOutlined style={{ fontSize: 20 }} />
                  Alterar data
                </ButtonBack>
              </CardCreate>
            </Modal>
          )}
          {visibleEditDateLot && (
            <Modal
              visible={visibleEditDateLot}
              footer={null}
              maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
              onCancel={() => setVisibleEditDateLot(!visibleEditDateLot)}
              closable={true}
              width={300}
            >
              <CardCreate style={{ width: '100%' }}>
                <img src={AddUser} alt='user' />
                <p>Alterar data da visita de</p>
                {selectVisit && <b>{selectVisit.dt_visit} </b>}
                <p>para</p>
                <DatePicker
                  style={{ width: '100%' }}
                  disabledDate={disabledDate}
                  locale={locale}
                  onChange={setDateSelected}
                  format={dateFormat}
                />
                <ButtonBack
                  style={{
                    backgroundColor: '#6600cc',
                    color: '#fff',
                    width: 156,
                    marginTop: 30,
                  }}
                  onClick={() => alterDateLot()}
                >
                  <PlusOutlined style={{ fontSize: 20 }} />
                  Alterar data
                </ButtonBack>
              </CardCreate>
            </Modal>
          )}
          {openModalVisit && (
            <ModalCreateVisit
              visible={openModalVisit}
              close={setOpenModalVisit}
              type={user.type === 'GESTOR' ? 'GESTOR' : 'PROMOTER'}
              stepJump={true}
              promoter={user}
              reloadApi={(bool: any) => restart(bool)}
            />
          )}
          <ModalCreateTask visible={openModalTask} close={setOpenModalTask} visit={selectVisit} />
          {openModalDuplicateVisit && (
            <ModalDuplicateVisit
              visible={openModalDuplicateVisit}
              close={setOpenModalDuplicateVisit}
              visit={selectedAllVisit}
            />
          )}
        </>
      )}
      <ModalAddTask visit={selectVisit} close={setVisibleAddForm} visible={visibleAddForm} />
      <ModalEditStatus
        resetVisit={getVisits}
        visible={visibleEditStatus}
        close={setVisibleEditStatus}
        visit={selectVisit}
      />
      {openModalSubWorkspaces &&
        <ModalViewSubWorkspace close={setOpenModalSubWorkspaces} visible={openModalSubWorkspaces} visit={selectVisit} />
      }
      <ModalMoveVisits close={setOpenModalMove} visible={openModalMove} visit={selectVisit} />
      </>
  )
}
