import React, { useState, useEffect, useMemo, useRef } from 'react'
import { ContainerModal, ContainerUpload } from './style'
import api_v2 from '../../services/api-v2'
import { ColumnsType } from 'antd/lib/table'
import { compareByAlph } from '../../utils/funcoes'
import firebase from '../../utils/firebase'
import { INIT_GLOBAL_RESPONSE } from '../../utils/global-response'
import qs from 'querystring'
import { Button, Col, Divider, Drawer, Dropdown, Menu, message, Modal, Popconfirm, Popover, Row, Table, Upload } from 'antd'
import moment from 'moment'
import useReactRouter from 'use-react-router'
import { debounce } from 'lodash'
import { ButtonSearch, ContainerSearch, LabelForm, Search } from '../../pages/Team/Promoter/style'
import { MdSearch } from 'react-icons/md'
import { ContainerPopover } from '../../pages/MonitoryPage/MonitoringPage/styles'
import { DeleteOutlined, EditOutlined, MoreOutlined, FileSearchOutlined, UploadOutlined } from '@ant-design/icons'
import { Loading } from '../Loading'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { ContainerButtonsFootter } from '../../GlobalStyles'
import Input from '../form/SimpleInput'
import { generateUUID } from '../../utils/funcao'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Mixpanel } from '../../utils/mixpanel'

interface IProps {
  visible: boolean
  close: (boolean: any) => void
}

export const ModalSubWorkspace = ({ visible, close }: IProps) => {
  const [SubWorkspace, setSubWorkspace] = useState<any>(INIT_GLOBAL_RESPONSE)
  const [name, setName] = useState<any>('')
  const [visiblePopver, setVisiblePopver] = useState<any>('')
  const [editSubworkspace, setEditSubWorkspace] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  const [checked, setChecked] = useState<any>([])
  const [pictures, setPictures] = useState<any>([])
  const [template, setTemplate] = useState<any>([])
  const formRef = useRef<FormHandles>(null)

  const { location } = useReactRouter()

  const handleTable = async (queryParam: any) => {
    setSubWorkspace({ ...SubWorkspace, loading: true })
    let filterQuery: any = {}

    if (name) {
      filterQuery.name = name
    }

    filterQuery = {
      ...filterQuery,
      perpage: queryParam.pageSize,
      page: queryParam.current,
    }
    const query = qs.stringify(filterQuery)

    const { data } = await api_v2.get(`/workspace-sub?${query}`)
    setSubWorkspace({ ...data, loading: false })
  }

  

  const deleteSub = async (id: number) => {
    try {
      setLoading(true)
      await api_v2.delete(`/workspace-sub/${id}`)
      await getSubWorkspace({})
      message.success('Sucesso ao deletar empresa')
      setLoading(false)
    } catch (error) {
      setLoading(false)
      message.error('Erro ao deleter empresa')
    }
  }

  const getSubWorkspace = async (evt: any) => {
    try {
      let name = evt?.target?.value
      let filterQuery: any = {}

      if (name) {
        filterQuery.name = name
        setName(name)
      }

      const queryFilter = qs.stringify({ ...filterQuery })

      const { data } = await api_v2.get(`/workspace-sub?${queryFilter}`)
      setSubWorkspace({ ...data, loading: false })
    } catch (error) {
      console.log(error)
    }
  }

  const deleteSelectdsSubWorkspace = async () => {
    try {
      setLoading(true)
      for await (let el of checked) {
        await api_v2.delete(`/workspace-sub/${el}`)
      }
      await getSubWorkspace({})
      message.success('Sucesso ao deletar empresas')
      setLoading(false)
    } catch (error) {
      setLoading(false)
      message.error('Erro ao deleter empresa')
    }
  }

  const changeSub = async (id: number) => {
    try {
      setLoading(true)
      await api_v2.put(`/workspace-sub/activeInactive/${id}`)
      await getSubWorkspace({})
      message.success('Sucesso ao editar empresa')
      setLoading(false)
    } catch (error) {
      setLoading(false)
      message.error('Erro ao deleter empresa')
    }
  }

  const createEdit = async (body: any) => {
    try {
      setLoading(true)
      if (editSubworkspace && editSubworkspace.id) {
        await api_v2.put(`/workspace-sub/${editSubworkspace.id}`, {
          ...body,
          avatar_url: pictures.length > 0 ? pictures[0].url : '',
          template_ppt: template.length > 0 ? template[0].url : '',
        })
      } else {
        await api_v2.post('/workspace-sub', {
          ...body,
          avatar_url: pictures.length > 0 ? pictures[0].url : '',
          template_ppt: template.length > 0 ? template[0].url : '',
        })
      }
      getSubWorkspace({})
      setLoading(false)
      setOpenDrawer(false)
      message.success(`Empresa ${editSubworkspace && editSubworkspace.id ? 'editada' : 'criada'} com sucesso`)
    } catch (error) {
      setLoading(false)
    }
  }

  const handleChange = ({ file, fileList }: any) => {
    if (pictures.length > 0) {
      message.error('Insira apenas uma foto')
      return
    }
    const team: any = sessionStorage.getItem('@rocketpdv:workspace')
    uploadImage({
      uri: file.originFileObj,
      slug: JSON.parse(team).slug,
      fileList: file,
      all: fileList,
    })
  }

  const handleChangeTamplete = ({ file, fileList }: any) => {
    if (pictures.length > 0) {
      message.error('Insira apenas um template')
      return
    }
    const team: any = sessionStorage.getItem('@rocketpdv:workspace')
    uploadTemplate({
      uri: file.originFileObj,
      slug: JSON.parse(team).slug,
      fileList: file,
      all: fileList,
    })
  }

  const uploadImage = async (urls: any) => {
    try {
      setLoading(true)
      let file = firebase.storage().ref(`${urls.slug}/SUB-WORKSPACES/${urls.uri.name}`)

      await file.put(urls.uri)
      const url = await file.getDownloadURL()

      let newPictures: any = []

      urls.all.forEach((el: any) => {
        if (el.uid === urls.fileList.uid) {
          newPictures.push({ ...urls.fileList, status: 'done', url: url })
        } else {
          newPictures.push(el)
        }
      })

      setPictures(newPictures)
      setLoading(false)
      message.success('Sucesso ao subir arquivo')
    } catch (error) {
      console.log(error)
      setLoading(false)
      message.error('Erro ao subir arquivo')
    }
  }

  const uploadTemplate = async (urls: any) => {
    try {
      setLoading(true)
      let file = firebase.storage().ref(`${urls.slug}/SUB-WORKSPACES/${urls.uri.name}`)

      await file.put(urls.uri)
      const url = await file.getDownloadURL()

      let newPictures: any = []

      urls.all.forEach((el: any) => {
        if (el.uid === urls.fileList.uid) {
          newPictures.push({ ...urls.fileList, status: 'done', url: url })
        } else {
          newPictures.push(el)
        }
      })

      setTemplate(newPictures)
      setLoading(false)
      message.success('Sucesso ao subir arquivo')
    } catch (error) {
      console.log(error)
      setLoading(false)
      message.error('Erro ao subir arquivo')
    }
  }

  useEffect(() => {
    getSubWorkspace({})
    setChecked([])
    setEditSubWorkspace({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandler = useMemo(() => debounce(getSubWorkspace, 300), [])

  useEffect(() => {
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      let numbers: any = []
      selectedRowKeys.forEach((el: any) => {
        let inNumber = checked.includes(el)
        if (!inNumber) numbers.push(el)
      })
      setChecked([...checked, ...numbers])
    },
    onSelect: (record: any, selected: any, selectedRows: any, nativeEvent: any) => {
      if (selected) {
        let numbers: number[] = []
        selectedRows.forEach((el: any) => {
          let inNumber = checked.includes(el.id)
          if (!inNumber) numbers.push(el)
        })
        setChecked([...checked, ...numbers])
      } else {
        let remove: any = checked.filter((el: any) => el !== record.id)
        setChecked(remove)
      }
    },
  }

  const columns: ColumnsType<any> = [
    {
      title: 'Status',
      dataIndex: 'is_active',
      sorter: (a, b) => a.mix_context.localeCompare(b.mix_context),
      width: 55,
      render: (a) => <>
      {a ? 
        <div style={{
          width: 16,
          height: 16,
          borderRadius: 8,
          backgroundColor: '#16c266'
        }}>

        </div>
      :  <div style={{
        width: 16,
        height: 16,
        borderRadius: 8,
        backgroundColor: '#e9830f'
      }}>
        </div>}
      </>,
    },
    {
      title: 'Avatar',
      dataIndex: 'avatar_url',
      sorter: (a, b) => a.mix_context.localeCompare(b.mix_context),
      width: 70,
      render: (a) => <>{a.length > 10 ? <img src={a} style={{ width: 50, height: 50 }} alt='avatar' /> : '-'}</>,
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Data de criação',
      dataIndex: 'created_at',
      sorter: (a, b) => compareByAlph(a.created_at, b.created_at),
      render: (a: any, b) => <>{moment(a).format('DD/MM/YYYY')}</>,
    },
    {
      title: '#',
      width: 50,
      render: (obj: any) => {
        return (
          <Dropdown
            overlay={
              <Menu>
                
                <Menu.Item key='1' icon={<DeleteOutlined />}>
                  <Popconfirm
                    title={`Tem certeza que deseja ${obj.is_active ? 'inativar está empresa? Ao inativar irá remover ela das visitas pendentes e do roteiro' : 'ativar está empresa?'} `}
                    onConfirm={() => {
                      Mixpanel.track('Confirmou ativação ou inativação sub,', { params: { obj } })

                        changeSub(obj.id)
                      
                    }}
                    okText='Sim'
                    cancelText='Não'
                    placement='topRight'
                  >
                    {obj.is_active ? 'Inativar' : 'Ativar'} empresa
                  </Popconfirm>
                </Menu.Item>

                <Menu.Item key='1' icon={<DeleteOutlined />}>
                  <Popconfirm
                    title={`Tem certeza que deseja deletar está empresa? Ao inativar irá remover ela das visitas pendentes e do roteiro.`}
                    onConfirm={() => {
                      Mixpanel.track('Confirmou delete sub,', { params: { obj } })

                      deleteSub(obj.id)
                    }}
                    okText='Sim'
                    cancelText='Não'
                    placement='topRight'
                  >
                    <p style={{color: 'red'}}>Deletar empresa</p>
                  </Popconfirm>
                </Menu.Item>
                <Menu.Item
                  key='2'
                  icon={<EditOutlined />}
                  onClick={() => {
                    setPictures(obj.avatar_url ? [{ url: obj.avatar_url }] : [])
                    setTemplate(obj.template_ppt ? [{ url: obj.template_ppt }] : [])
                    setEditSubWorkspace(obj)
                    setOpenDrawer(true)
                  }}
                >
                  Editar
                </Menu.Item>
              </Menu>
            }
          >
            <Button size='small' shape='circle' icon={<MoreOutlined />} />
          </Dropdown>
        )
      },
    },
  ]

  const handlePreview = (file) => {
    window.open(file.url, '_blank') // Abra a URL em outra aba
  }

  return (
    <ContainerModal
      visible={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
      style={{ top: 10 }}
      destroyOnClose={true}
    >
      {loading && <Loading />}
      <h3>Visualização de sub workspace</h3>
      <Row justify='space-between' style={{ marginTop: 20, marginBottom: 20 }} gutter={[20, 20]}>
        <Col>
          <p>Buscar pelo nome:</p>
          <ContainerSearch style={{ height: 30 }}>
            <Search placeholder='Buscar por nome' onChange={debouncedChangeHandler} />
            <MdSearch size={20} color={'#A56EFC'} />
          </ContainerSearch>
        </Col>
        <Col>
          <Popover
            content={
              <ContainerPopover>
                <p
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setPictures([])
                    setEditSubWorkspace({})
                    setOpenDrawer(true)
                  }}
                >
                  Criar empresa
                </p>
                {checked.length > 0 && (
                  <>
                    <Divider />
                    <p
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        deleteSelectdsSubWorkspace()
                      }}
                    >
                      Excluir
                    </p>
                  </>
                )}
              </ContainerPopover>
            }
            trigger='hover'
            visible={visiblePopver}
            onVisibleChange={() => setVisiblePopver(!visiblePopver)}
            placement='left'
          >
            <Button
              style={{
                display: 'flex',
                alignItems: 'center',
                borderRadius: 5,
                color: '#6f7782',
                padding: 8,
              }}
            >
              Ações
            </Button>
          </Popover>
        </Col>
      </Row>
      <Table
        scroll={{ y: 240 }}
        columns={columns}
        tableLayout='auto'
        rowKey={(SubWorkspaceTable) => SubWorkspaceTable.id}
        dataSource={SubWorkspace.result}
        rowSelection={{
          type: 'checkbox',
          hideSelectAll: true,
          ...rowSelection,
        }}
        size='small'
        pagination={{
          // position: 'both',
          current: SubWorkspace.page,
          pageSize: SubWorkspace.perpage,
          total: SubWorkspace.total,
          size: 'small',
          showSizeChanger: true,
          showTotal: () => `Exibindo ${SubWorkspace.result.length} de ${SubWorkspace.total} de registros`,
        }}
        loading={SubWorkspace.loading}
        onChange={handleTable}
      />
      <Drawer
        title={editSubworkspace && editSubworkspace.id ? 'Editar empresa' : 'Criar empresa'}
        placement='right'
        closable={true}
        onClose={() => setOpenDrawer(!openDrawer)}
        visible={openDrawer}
        key='right'
        destroyOnClose={true}
        footer={
          <ContainerButtonsFootter>
            <ButtonSearch type='button' onClick={() => formRef?.current?.submitForm()} style={{ width: '100%' }}>
              <FileSearchOutlined />
              {editSubworkspace && editSubworkspace.id ? 'Editar empresa' : 'Criar empresa'}
            </ButtonSearch>
          </ContainerButtonsFootter>
        }
      >
        <Form ref={formRef} className='form' style={{ marginTop: '15px' }} onSubmit={createEdit}>
          {editSubworkspace && editSubworkspace.id ? (
            <>
              <LabelForm>Nome da empresa*</LabelForm>
              <Input name='name' defaultValue={editSubworkspace.name} />
            </>
          ) : (
            <>
              <LabelForm>Nome da empresa*</LabelForm>
              <Input name='name' />
            </>
          )}

          <LabelForm>Insira uma foto</LabelForm>
          <ContainerUpload>
            <Upload
              beforeUpload={() => {
                if (pictures.length > 0) {
                  message.error('Insira apenas uma foto')
                  return
                }
              }}
              onPreview={handlePreview}
              listType='picture'
              fileList={pictures}
              style={{ width: '100%' }}
              onRemove={(file) => {
                let newFiles = pictures.filter((item) => item.url != file.url)
                setPictures([...newFiles])
              }}
              customRequest={async ({ file, onSuccess, onError }: any) => {
                try {
                  const team: any = sessionStorage.getItem('@rocketpdv:workspace')
                  const fileRef = firebase
                    .storage()
                    .ref(`${JSON.parse(team).slug}/avatar/${file.name}`)
                  await fileRef.put(file)
                  const url = await fileRef.getDownloadURL()
                  const pictureList = {
                    uid: generateUUID(),
                    status: 'done',
                    url, 
                  }
                    
                  setPictures([pictureList]);
                  // Retorne a URL diretamente
                  message.success('Sucesso ao subir arquivo')
                } catch (error) {
                  console.log(error)
                  message.error('Erro ao subir arquivo')
                }
              }}
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
          </ContainerUpload>

          <div style={{ marginTop: 30 }}>
            <LabelForm>Insira um template</LabelForm>
            <ContainerUpload>
              <Upload
                beforeUpload={() => {
                  if (template.length > 0) {
                    message.error('Insira apenas um template')
                    return false
                  }
                }}
                onPreview={handlePreview}
                listType='picture'
                accept='application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation'
                fileList={template}
                onRemove={(file) => {
                  let newFiles = template.filter((item) => item.url != file.url)
                  setTemplate([...newFiles])
                }}
                style={{ width: '100%' }}
                customRequest={async ({ file, onSuccess, onError }: any) => {
                  try {
                    const team: any = sessionStorage.getItem('@rocketpdv:workspace')
                    const fileRef = firebase
                      .storage()
                      .ref(`${JSON.parse(team).slug}/template_book/${file.name}`)
                    await fileRef.put(file)
                    const url = await fileRef.getDownloadURL()
                    const pictureList = {
                      uid: generateUUID(),
                      status: 'done',
                      url, 
                    }
                      
                    setTemplate([pictureList]);
                    // Retorne a URL diretamente
                    message.success('Sucesso ao subir arquivo')
                  } catch (error) {
                    console.log(error)
                    message.error('Erro ao subir arquivo')
                  }
                }}
              >
                <Button icon={<UploadOutlined />}>Upload</Button>
              </Upload>
            </ContainerUpload>
          </div>
        </Form>
      </Drawer>
    </ContainerModal>
  )
}
