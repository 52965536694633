import {
  CloudDownloadOutlined,
  FileSearchOutlined,
  FilterOutlined,
  EyeOutlined,
  CloseOutlined,
  ClearOutlined,
  MoreOutlined,
} from '@ant-design/icons'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import {
  Breadcrumb,
  Button,
  Col,
  DatePicker,
  Dropdown,
  Menu,
  message,
  notification,
  Row,
  Table,
  Tag,
  Tooltip,
} from 'antd'
import moment from 'moment'
import qs from 'querystring'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  BigButton,
  ContainerButtonsFootter,
  ContainerFormFilter,
  ContainerList,
  GroupForm,
} from '../../../GlobalStyles'
import api from '../../../services/api'
import { Loading } from '../../../components/Loading'
import { IGlobalResponse, INIT_GLOBAL_RESPONSE } from '../../../utils/global-response'
import { getUniqueToken, getWorkspace, getWorkspaceId } from '../../../utils/login'
import { BtnExport, Container } from './styles'
import { ContainerTagg } from '../../Survey/FormListPage/styles'
import { ButtonClear, ButtonSearch, LabelForm } from '../../Team/Promoter/style'
import history from '../../../routes/history'
import 'moment/locale/pt-br'
import locale from 'antd/es/date-picker/locale/pt_BR'
import api_v2 from '../../../services/api-v2'
import { NoSearch } from '../../../components/NoSearch'
import useReactRouter from 'use-react-router'
import { Search } from '../../../components/form/SimpleSearch'
import { DrawerFilter } from '../../../components/DrawerFilter'
import { exportXls } from '../../../utils/funcoes'

import { Container as ContainerNoSearch } from '../../../components/NoSearch/style'

const dateFormat = 'DD/MM/YYYY'
interface Searchs {
  id: string
  form: any
  dt_visit: string
  pdv_name: string
  user_id?: string
  user: any
  product_id: string
  form_id: any
  pdv_id: any
  status: any
  visit_id: any
  visit: any
  sub_workspace: any
}

export interface SearchFilters {
  id?: string
  description?: string
  date?: string
  form?: number
  pdv?: number
  user?: number
  dt_visit?: string
  start_date?: string
  end_date?: string
  sub_workspace?: number
}

interface SearchList extends IGlobalResponse {
  result: Searchs[]
}

function compareByAlph(a: any, z: any) {
  if (a > z) {
    return -1
  }
  if (a < z) {
    return 1
  }
  return 0
}

const SearchListPage: React.FC = () => {
  const { location } = useReactRouter()

  const [searchInfo, setSearchInfo] = useState<SearchList>(INIT_GLOBAL_RESPONSE)
  const [visible, setVisible] = useState<boolean>(false)
  const [filters, setFilters] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [filterTag, setFilterTag] = useState<any>({})
  const [urlFilter, setUrlFilter] = useState<string>('')
  const [dateRange, setDateRange] = useState<any>([moment(new Date(), dateFormat), moment(new Date(), dateFormat)])
  const [promoter, setPromoter] = useState('')
  const [pdvName, setPdvName] = useState('')
  const [formName, setFormName] = useState('')
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [columns, setComlumns] = useState<any>([
    {
      title: 'Nome do formulário',
      dataIndex: 'form_name',
      sorter: (a: any, b: any) => compareByAlph(a.form_name, b.form_name),
    },
    {
      title: 'PDV',
      dataIndex: 'pdv_name',
      sorter: (a: any, b: any) => compareByAlph(a.pdv_name, b.pdv_name),
    },
    {
      title: 'Usuário',
      dataIndex: 'user_name',
      sorter: (a: any, b: any) => compareByAlph(a.user_name, b.user_name),
    },
    {
      title: 'Coletado em',
      dataIndex: 'collected_date',
      sorter: (a: any, b: any) => compareByAlph(a.user_name, b.user_name),
    },
    {
      title: '#',
      width: 50,
      render: (obj: any) => {
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key='1' icon={<EyeOutlined />}>
                  <Link to={`/backoffice/reports/survey/${obj.form_id}/${obj.user_id}/${obj.visit_id}`}>
                    Visualizar resposta
                  </Link>
                </Menu.Item>
              </Menu>
            }
          >
            <Button size='small' shape='circle' icon={<MoreOutlined />} />
          </Dropdown>
        )
      },
    },
  ])
  
  let getSharement = sessionStorage.getItem('@rocketpdv:workspace')
  let newGetWorkspace: any = {}
  if (getSharement) newGetWorkspace = JSON.parse(getSharement)

  const rowSelection = {
    onChange: (newSelectedRowKeys: React.Key[], selections: any) => {
      setSelectedRowKeys(newSelectedRowKeys)
    },
    selectedRowKeys,
  }

  const formRef = useRef<FormHandles>(null)
  let getWorkspaceShared = sessionStorage.getItem('@rocketpdv:workspace')
  let sharedWorkspace = JSON.parse(getWorkspaceShared!.toString())

  const handleTable = async (queryParam: any) => {
    setLoading(true)
    setSearchInfo({ ...searchInfo })

    let newObj = {
      perpage: queryParam.pageSize,
      page: queryParam.current,
      // start_date: moment(dateRange[0]).format('YYYY-MM-DD'),
      // end_date: moment(dateRange[1]).format('YYYY-MM-DD'),
    }
    const query = qs.stringify(newObj)

    // let result: any = []
    // setSearchInfo({ ...result, loading: true })

    const { data } = await api_v2.get(`/answer?${query}${urlFilter ? '&' + urlFilter : ''}`)

    setSearchInfo({ ...data })
    setLoading(false)
  }

  async function searchIn(body: any) {
    try{
      setLoading(true)
      const { form, user, sub_workspace, pdv, profile_id} = body
      let start_date = ''
      let end_date = ''
      let filterQuery: any = {}
      let filterQueryUrl: any = {}
      let dataTag = {}
      if (form && form.value) {
        filterQuery = Object.assign({ form: form.value }, filterQuery)
        filterQueryUrl = { ...filterQueryUrl, form: JSON.stringify(form) }
        dataTag = { ...dataTag, form, valueForm: form.label }
      }
      if (pdv && pdv.value) {
        if (pdv) filterQuery = Object.assign({ pdv: pdv?.value ? pdv.value : pdv }, filterQuery)
        filterQueryUrl = { ...filterQueryUrl, pdv: JSON.stringify(pdv) }
        dataTag = { ...dataTag, selectPdv: pdv.label }
      }
      if (profile_id && profile_id.value) {
        filterQuery = Object.assign({ profile_id: profile_id.value }, filterQuery)
        filterQueryUrl = { ...filterQueryUrl, profile_id: JSON.stringify(profile_id) }
        dataTag = { ...dataTag, profile_id, valueProfile_id: profile_id.label }
      }

      if (user && user.value) {
        filterQuery = Object.assign({ user: user.value }, filterQuery)
        filterQueryUrl = { ...filterQueryUrl, user: JSON.stringify(user) }
        dataTag = { ...dataTag, user, valueUser: user.label }
      }
      if (sub_workspace && sub_workspace.value) {
        filterQuery = Object.assign({ sub_workspace: sub_workspace.value }, filterQuery)
        filterQueryUrl = { ...filterQueryUrl, sub_workspace: JSON.stringify(sub_workspace) }
        dataTag = { ...dataTag, sub_workspace, valuesub_workspace: sub_workspace.label }
      }
      if (dateRange && !body.start_date && !body.end_date) {
        start_date = moment(dateRange[0]).format('YYYY-MM-DD')
        end_date = moment(dateRange[1]).format('YYYY-MM-DD')
        filterQuery = Object.assign({ start_date, end_date }, filterQuery)
        filterQueryUrl = { ...filterQueryUrl, start_date, end_date }

        dataTag = { ...dataTag, start_date: start_date, end_date: end_date }
      }

      if(body.start_date && body.end_date) {
        start_date = body.start_date
        end_date = body.end_date
        filterQuery = Object.assign({ start_date, end_date }, filterQuery)
        filterQueryUrl = { ...filterQueryUrl, start_date, end_date }

        dataTag = { ...dataTag, start_date: start_date, end_date: end_date }
      }

      const queryFilter = qs.stringify(filterQuery)
      const queryFilterUrl = qs.stringify(filterQueryUrl)
      setFilters(queryFilter)
      setUrlFilter(queryFilter)


      if (body && body.form) {
        const { data } = await api_v2.get(`/answer?` + queryFilter)
        setSearchInfo({ ...data })
        setVisible(false)
      } else {
        setSearchInfo(INIT_GLOBAL_RESPONSE)
        setVisible(true)
      }

      setLoading(false)
      setFilterTag(dataTag)
      history.push(`/backoffice/reports/search?${queryFilterUrl}`)

    } catch(err){
      console.log(err)
    }
  }

  const removeFilter = async (typeRemove: string, value: string) => {
    let newUrl: any = new URLSearchParams(urlFilter)
    let newObjTagg = filterTag
    if (typeRemove === 'form') {
      setFormName('')
      delete newObjTagg.form
      formRef!.current!.clearField('form')
      newUrl.delete('form')
    }
    if (typeRemove === 'pdv') {
      setPdvName('')
      delete newObjTagg.selectPdv
      delete newObjTagg.valuePdv
      delete newObjTagg.pdv
      formRef!.current!.clearField('pdv')
      newUrl.delete('pdv')
    }
    if (typeRemove === 'user') {
      delete newObjTagg.user
      setPromoter('')
      formRef!.current!.clearField('user')
      newUrl.delete('user')
    }

    if (typeRemove === 'profile_id') {
      delete newObjTagg.profile_id
      delete newObjTagg.valueProfile_id
      formRef!.current!.clearField('profile_id')
      newUrl.delete('profile_id')
    }

    if (typeRemove === 'sub_workspace') {
      delete newObjTagg.sub_workspace
      delete newObjTagg.valuesub_workspace
      formRef!.current!.clearField('sub_workspace')
      newUrl.delete('sub_workspace')
    }
    if (typeRemove === 'start_date') delete newObjTagg.start_date
    if (typeRemove === 'end_date') delete newObjTagg.end_date


    newUrl = newUrl.toString()
    newUrl = newUrl.replace(`${typeRemove}=${value.replaceAll(' ', '%20')}`, '')
    newUrl = newUrl.replace(`${typeRemove}=${value.replaceAll(' ', '%20')}`, '')

    setLoading(true)

    if (newObjTagg && newObjTagg.form) {
      const { data } = await api_v2.get(`/answer?` + urlFilter)
      setSearchInfo({ ...data })
    } else {
      setSearchInfo(INIT_GLOBAL_RESPONSE)
      setVisible(true)
    }

    setUrlFilter(newUrl)
    setFilterTag(newObjTagg)
    setLoading(false)
    history.push(`/backoffice/reports/search?${newUrl}`)
  }

  const openNotification = () => {
    const args = {
      message: 'Formulário não selecionado',
      description: 'É necessário informar o formulário nos filtros para exportar',
      duration: 0,
    }
    notification.open(args)
  }

  const menuExport = (
    <Menu>
      <Menu.Item>
        {filters !== undefined && filters.indexOf('form=') > -1 ? (
          <BtnExport
            target='_blank'
            href={
              process.env.REACT_APP_API_URL +
              `survey-report?workspace=${getWorkspace()}&${filters}${
                selectedRowKeys.length > 0 ? `&id=${JSON.stringify(selectedRowKeys)}` : ''
              }&token=${getUniqueToken()}`
            }
          >
            Em colunas
          </BtnExport>
        ) : (
          <BtnExport onClick={openNotification}>Em colunas</BtnExport>
        )}
      </Menu.Item>
    </Menu>
  )

  useEffect(() => {
    let obj: any = qs.parse(location.search.replaceAll('?', ''))
    if (obj && obj.form) obj.form = JSON.parse(obj.form)
    if (obj && obj.user) obj.user = JSON.parse(obj.user)
    if (obj && obj.profile_id) obj.profile_id = JSON.parse(obj.profile_id)
    if (obj && obj.sub_workspace) obj.sub_workspace = JSON.parse(obj.sub_workspace)
    if (obj && obj.pdv) obj.pdv = JSON.parse(obj.pdv)

    searchIn(obj)

    window.scrollTo(0, 0)

    let newColumn: any = []
    columns.forEach((el: any, index: number) => {
      if (!sharedWorkspace.shared_environment) {
        if (el.title !== 'Subworkspace') return newColumn.push(el)
      } else {
        return newColumn.push(el)
      }
    })

    setComlumns(newColumn)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  const cleanFilter = async () => {
    history.push(`/backoffice/reports/search`)
    setLoading(true)
    setSearchInfo({ ...searchInfo })
    setVisible(false)
    setUrlFilter('')
    formRef!.current!.reset()
    setFilterTag({})
    const { data } = await api.get(`/form`)
    setSearchInfo({ ...data })
    setLoading(false)
  }

  return (
    <ContainerList>
      <Row gutter={[8, 8]} justify='space-between'>
        {loading ? <Loading /> : null}
        <Col span={18}>
          <h1>Pesquisas</h1>
          <Breadcrumb className='breadcrumb'>
            <Link to='/backoffice/reports'>
              <Breadcrumb.Item>Relatórios</Breadcrumb.Item>
            </Link>
            <Breadcrumb.Item>Pesquisa</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col>
          {
            getWorkspaceId() == 89 || getWorkspaceId() == 86 || getWorkspaceId() == 85 || getWorkspaceId() == 84 || getWorkspaceId() == 83 || getWorkspaceId() == 82 || getWorkspaceId() == 81 ?
<Dropdown overlay={menuExport}>
            <Button type='primary' icon={<CloudDownloadOutlined />}>
              Exportar Pesquisas
            </Button>
          </Dropdown>
            :
            <Button
            type='primary'
            icon={<CloudDownloadOutlined />}
            onClick={async () => {
              let filter = qs.parse(urlFilter)
              if (!filter.form) {
                return message.error('É necessário filtrar pelo menos um formulário.')
              }
              console.log(filter)
              setLoading(true)
              await exportXls({ ...filter }, 'answers')
              setLoading(false)
            }}
          >
            Exportar Pesquisas
          </Button>
          }

          {/* </Dropdown> */}
        </Col>
        <Col>
          <Tooltip placement='top' title={'Filtrar'}>
            <BigButton onClick={() => setVisible(!visible)} style={{ padding: 9 }}>
              <FilterOutlined />
            </BigButton>
          </Tooltip>
        </Col>
        <Col span={24}>
          <ContainerTagg>
            {filterTag.form || formName ? (
              <Tag color='geekblue' onClick={() => removeFilter('form', `${filterTag.form}`)}>
                {formName !== '' ? formName : filterTag.valueForm} <CloseOutlined />
              </Tag>
            ) : null}
            {filterTag.user || promoter !== '' ? (
              <Tag color='geekblue' onClick={() => removeFilter('user', `${filterTag.user}`)}>
                {promoter !== '' ? promoter : filterTag.valueUser}
                <CloseOutlined />
              </Tag>
            ) : null}

            {filterTag.profile_id || promoter !== '' ? (
              <Tag color='geekblue' onClick={() => removeFilter('profile_id', `${filterTag.profile_id}`)}>
                {filterTag.valueProfile_id}
                <CloseOutlined />
              </Tag>
            ) : null}

            {filterTag.selectPdv || pdvName !== '' ? (
              <>
                <Tag color='geekblue' onClick={() => removeFilter('pdv', `${filterTag.selectPdv}`)}>
                  {pdvName !== '' ? pdvName : filterTag.selectPdv}
                  <CloseOutlined />
                </Tag>
              </>
            ) : null}

            {filterTag.sub_workspace || promoter !== '' ? (
              <Tag color='geekblue' onClick={() => removeFilter('sub_workspace', `${filterTag.sub_workspace}`)}>
                {filterTag.valuesub_workspace}
                <CloseOutlined />
              </Tag>
            ) : null}
            {filterTag.start_date && (
              <Tag color='geekblue' onClick={() => removeFilter('start_date', `${filterTag.start_date}`)}>
                {moment(filterTag.start_date).format('DD/MM/YYYY')}
                <CloseOutlined />
              </Tag>
            )}
            {filterTag.end_date && (
              <Tag color='geekblue' onClick={() => removeFilter('end_date', `${filterTag.end_date}`)}>
                {moment(filterTag.end_date).format('DD/MM/YYYY')}
                <CloseOutlined />
              </Tag>
            )}
          </ContainerTagg>
        </Col>
      </Row>
      {loading ? (
        <Loading />
      ) : (
        <>
          {searchInfo.result && searchInfo.result.length > 0 ? (
            <Table
              columns={columns}
              rowKey={(search) => search.visit_id}
              dataSource={searchInfo.result}
              size='middle'
              rowSelection={{
                type: 'checkbox',

                ...rowSelection,
              }}
              pagination={{
                // position: 'both',
                current: searchInfo.page,
                pageSize: searchInfo.perpage,
                total: searchInfo.total,
                size: 'small',
                showSizeChanger: true,
                showTotal: () => `Exibindo ${searchInfo.result.length} de ${searchInfo.total} de registros`,
              }}
              loading={searchInfo.loading}
              onChange={handleTable}
            />
          ) : (
            <div>
              { urlFilter.includes('form=') ? (
                <NoSearch text='Não encontramos nenhum dado aqui.' />
              ): (
                <ContainerNoSearch>
                  <h3>Selecione um formulário para carregar as informações!</h3>
                  <Button icon={<FileSearchOutlined />} type="primary" onClick={() => setVisible(true)}>Filtrar formulário</Button>
                </ContainerNoSearch>
              ) }

            </div>
          )}
        </>
      )}

      <DrawerFilter
        visible={visible}
        close={setVisible}
        title='Pesquisas'
        footer={
          <ContainerButtonsFootter>
            <ButtonSearch type='button' onClick={() => formRef?.current?.submitForm()}>
              <FileSearchOutlined />
              Pesquisar
            </ButtonSearch>
            <ButtonClear onClick={() => cleanFilter()}>
              <ClearOutlined />
              Limpar
            </ButtonClear>
          </ContainerButtonsFootter>
        }
      >
        <ContainerFormFilter
          tabIndex={0}
          onKeyDown={(evt: any) => {
            if (evt.key === 'Enter') formRef?.current?.submitForm()
          }}
        >
          <Form ref={formRef} className='form' style={{ marginTop: '15px' }} onSubmit={searchIn}>
            <GroupForm>
              <LabelForm>Selecione o formulário</LabelForm>
              <Search placeholder='Selecione o formulário' name='form' path='forms' isMult={false} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione o Promotor</LabelForm>
              <Search placeholder='Selecione o Promotor' name='user' path='promoters' isMult={false} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione o PDV</LabelForm>
              <Search placeholder='Selecione o PDV' name='pdv' path='pdvs' />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione o perfil</LabelForm>
              <Search placeholder='Selecione o perfil' name='profile_id' path='profiles' isMult={false} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione o periodo </LabelForm>
              <DatePicker.RangePicker
                locale={locale}
                defaultValue={dateRange}
                format={dateFormat}
                ranges={{
                  Hoje: [moment(), moment()],
                  'Esta semana': [moment().startOf('week'), moment().endOf('week')],
                  'Este mês': [moment().startOf('month'), moment().endOf('month')],
                }}
                onChange={(val: any) => setDateRange(val)}
              />
            </GroupForm>
            {newGetWorkspace.shared_environment  ? (
            <GroupForm>
            <LabelForm>Selecione a empresa</LabelForm>
            <Search placeholder='Selecione a empresa' name='sub_workspace' path='sub-workspaces' isMult={false} />          
            </GroupForm>
               ) : null}
          </Form>
        </ContainerFormFilter>
      </DrawerFilter>
    </ContainerList>
  )
}

export default SearchListPage
