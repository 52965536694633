import React, { useState, useEffect } from 'react'
import { ContainerAnswer, ContainerForm, TitleAnswer } from './style'
import { ContainerRadio } from '../Aswers/Boolean/styles'
import { FormMass } from '../AswersMass'
import moment from 'moment'
import { Collapse, Radio } from 'antd'

export const CardAnswerProduct = ({ item, answer, validate, error, body, form }: any) => {
  const [checked, setChecked] = useState<any>()
  const [dataAnswer] = useState<any>([answer])
  const [teste, setTeste] = useState([])
  const [uniqueError, setUniqueError] = useState<number[]>([0])
  const [hasError, setHasError] = useState<any>(undefined)

  const handleSetAnswers = (answers: any) => {
    let newDataAnswer = dataAnswer

    let exist = false

    let indexFind = newDataAnswer.findIndex((item) => item.id === answers.id)

    if(indexFind > -1) {
      newDataAnswer[indexFind] = answers
    } else {
      newDataAnswer.push(answers)
    }

    // newDataAnswer = newDataAnswer.map((answer: any) => {
    //   if (answer.id === answers.id) {
    //     exist = true
    //     return { ...answer, field_value: answers.field_value }
    //   } else {
    //     return answer
    //   }
    // })

    // if (exist === false) newDataAnswer.push(answers)

    if (checked === 'NÃO' || checked === 'não') {
      answer({
        answers: [
          {
            field_name: 'O produto está presente na loja?',
            field_order: 0,
            field_type: 'BOOLEAN',
            field_value: ['NÃO'],
            id: 0,
          },
        ],
        collected_date: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        form_context: 'PRODUCT',
        form_id: body.form_id,
        product_id: item.product_id,
      })
    } else {
      answer({
        collected_date: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        form_context: 'PRODUCT',
        form_id: body.form_id,
        product_id: item.product_id,
        answers: [
          {
            field_name: 'O produto acima está presente na loja ?',
            field_order: '0',
            field_type: 'BOOLEAN',
            field_value: ['sim'],
            id: 0,
          },
          ...newDataAnswer,
        ],
      })
    }
  }

  const checks = (id: any) => {
    let ids: any = teste
    ids.push(id)
    setTeste(ids)
  }

  const checkAnswe = (index: any) => {
    index = index.target.value
    if (index === 'Não') {
      answer({
        answers: [
          {
            field_name: 'O produto está presente na loja?',
            field_order: 0,
            field_type: 'BOOLEAN',
            field_value: ['NÃO'],
            id: 0,
          },
        ],
        collected_date: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        form_context: 'PRODUCT',
        form_id: body.form_id,
        product_id: item.product_id,
      })
    } else {
      answer({
        collected_date: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        form_context: 'PRODUCT',
        form_id: body.form_id,
        product_id: item.product_id,
        answers: [
          {
            field_name: 'O produto acima está presente na loja ?',
            field_order: '0',
            field_type: 'BOOLEAN',
            field_value: ['sim'],
            id: 0,
          },
        ],
      })
    }
    setChecked(index)
  }
  useEffect(() => {
    let newError = error.filter((el: any) => el.idProduto === item.product_id)
    if (newError.length > 0) {
      setUniqueError(newError[0].ids)
      console.log(newError[0].ids)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  useEffect(() => {
    if (validate.ids_answers.length > 0) {
      let has = null
      validate.ids_answers.forEach((el: any) => {
        if (el.ids_answers === item.product_id) {
          has = true
        }
      })
      setHasError(has)
    } else {
      setHasError(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validate])

  return (
    <ContainerForm>
      <Collapse
        activeKey={['1']}
        style={{
          borderColor: hasError === true ? '#dc4c66' : hasError === undefined ? '#f7f7f7' : '#51ab6e90',
        }}
      >
        <Collapse.Panel header={item.name} key='1'>
          <ContainerAnswer>
            <TitleAnswer>O produto está presente na loja ?</TitleAnswer>
            <ContainerRadio>
              <Radio.Group onChange={checkAnswe} value={checked}>
                <Radio value={'Sim'}>Sim</Radio>
                <Radio value={'Não'}>Não</Radio>
              </Radio.Group>
            </ContainerRadio>
          </ContainerAnswer>
          {checked === 'Sim' && (
            <FormMass
              body={body}
              product={item}
              data={form ? form : []}
              handleSetAnswers={handleSetAnswers}
              checkRequired={(a: any) => checks(a)}
              error={uniqueError}
            />
          )}
        </Collapse.Panel>
      </Collapse>
    </ContainerForm>
  )
}
