import { Col, Collapse, message, Row, Tooltip } from 'antd'
import { ContainerMenu } from '../TabGeneralUser/style'
import { ContainerVisit, ContainerData, ContainerCardPdv, ContainerSurvey } from './style'
import { ButtonBack } from '../../pages/Team/ViewUser/styles'
import { FilterOutlined, CloudUploadOutlined, LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons'
import { useState, useEffect } from 'react'
import moment from 'moment'
import CardStoreSurvey from '../CardStoreSurvey'
import EditAnswer from '../EditAnswer'
import { useParams } from 'react-router'
import api_v2 from '../../services/api-v2'
import { Loading } from '../Loading'
import history from '../../routes/history'
import EditAnswer2 from '../EditAnswer2'
const { Panel } = Collapse

interface Props {
  dateFilter: any
  setterDateFilter: any
}

export const TabsSurveys: React.FC<Props> = ({ dateFilter, setterDateFilter }) => {
  const { id }: any = useParams()
  const [date, setDate] = useState<any>(
    dateFilter.end_date ? moment(dateFilter.end_date, 'YYYY-MM-DD').toDate() : new Date()
  )
  const [visits, setVisits] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [answer, setAnswer] = useState<any>([])
  const [pdvSelected, setPdvSelected] = useState<any>({})
  const [pdvActive, setPdvActive] = useState<any>(0)

  useEffect(() => {
    loadVisit(dateFilter.end_date ? moment(dateFilter.end_date, 'YYYY-MM-DD').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'))
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    setDate(dateFilter.end_date ? moment(dateFilter.end_date, 'YYYY-MM-DD').toDate() : new Date())
  }, [dateFilter])

  const loadVisit = async (start: string) => {
    const res = await api_v2.get(`/visit?promoter_id=${id}&start_date=${start}&end_date=${start}&status=COMPLETE`)
    const res2 = await api_v2.get(`/visit?promoter_id=${id}&start_date=${start}&end_date=${start}&status=IN_PROGRESS`)
    let result = [...res.data.result, ...res2.data.result]
    setVisits(result)
    if (result.length > 0) {
      setPdvSelected(result[0].pdv)
      loadAnswer(result[0].id)
    } else {
      setPdvSelected('')
      const list = res.data.result.map((item) => {
        return {
          ...item,
          answers: item.answers.map((ans) => {
            return {
              ...ans,
              product_name: item.product.name,
              product_id: item.product.id,
              form_name: item.form.name,
              form_id: item.form.id,
            }
          }),
        }
      })
      setAnswer(list)
    }
  }

  const loadAnswer = async (id?: any) => {
    const { data } = await api_v2.get(`/visit/${id}/answers`)

    const newList = []

    data.forEach((item) => {
       newList.push({
          ...item,
          product_name: item.product.name,
          product_id: item.product.id,
          form_name: item.form.name,
          form_id: item.form.id,
          answer_id: item.id,
          answers: item.answers.map((el) => ({
            ...el,
            product_name: item.product.name,
            product_id: item.product.id,
            form_name: item.form.name,
            form_id: item.form.id,
            answer_id: item.id,
          })),
        })
    })

    setAnswer(newList)
  }

  return (
    <>
      <Row gutter={[40, 40]} style={{ marginTop: 20 }}>
        <Col span={20}>
          <ContainerData>
            <LeftCircleOutlined
              onClick={() => {
                setLoading(true)
                setTimeout(() => {
                  setLoading(false)
                }, 500)
                setPdvActive(0)
                setDate(moment(date).subtract(1, 'd').toDate())
                loadVisit(moment(date).subtract(1, 'd').format('YYYY-MM-DD').toString())
                history.push(
                  `/backoffice/teamById/${id}?start_date=${moment(dateFilter[0])
                    .format('YYYY-MM-DD')
                    .toString()}&end_date=${moment(date).subtract(1, 'd').format('YYYY-MM-DD').toString()}`
                )
              }}
            />
            <b>{moment(date).format('DD/MM/YYYY')}</b>
            <RightCircleOutlined
              onClick={() => {
                setLoading(true)
                setTimeout(() => {
                  setLoading(false)
                }, 500)
                setPdvActive(0)
                if (moment(date).format('DD/MM/YYYY') === moment(new Date()).format('DD/MM/YYYY'))
                  return message.error('Data superior ao dia de hoje.')
                setDate(moment(date).add(1, 'd').toDate())
                loadVisit(moment(date).add(1, 'd').format('YYYY-MM-DD').toString())

                history.push(
                  `/backoffice/teamById/${id}?start_date=${dateFilter.start_date}&end_date=${moment(date)
                    .add(1, 'd')
                    .format('YYYY-MM-DD')
                    .toString()}`
                )
              }}
            />
          </ContainerData>
        </Col>
        <Col span={4}>
          <ContainerMenu>
            <ButtonBack
              style={{
                backgroundColor: '#b56aff',
                color: '#fff',
                width: 126,
              }}
            >
              <CloudUploadOutlined style={{ fontSize: 20 }} />
              Download
            </ButtonBack>
            <Tooltip placement='top' title={'Filtrar'}>
              <ButtonBack style={{ width: 'auto', height: 'auto', padding: 10 }}>
                <FilterOutlined style={{ fontSize: 20 }} />
              </ButtonBack>
            </Tooltip>
          </ContainerMenu>
        </Col>
      </Row>
      {visits.length === 0 ? (
        <ContainerVisit style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <h3 style={{ fontSize: 18, fontWeight: 'bold', letterSpacing: 2 }}>
            Sem pesquisas realizadas até o momento.
          </h3>
        </ContainerVisit>
      ) : (
        <>
          {loading ? (
            <Loading />
          ) : (
            <ContainerVisit>
              <ContainerCardPdv>
                {visits.map((el: any, key: number) => (
                  <div
                    style={{ width: '100%' }}
                    onClick={() => {
                      setLoading(true)
                      setPdvSelected(el.pdv)
                      loadAnswer(el.id)
                      setPdvActive(key)
                      window.scrollTo(0, 0)
                      setTimeout(() => {
                        setLoading(false)
                      }, 500)
                    }}
                  >
                    <CardStoreSurvey data={el} active={pdvActive === key} />
                  </div>
                ))}
              </ContainerCardPdv>
              <ContainerSurvey>
                {pdvSelected && (
                  <>
                    <b>{pdvSelected?.name}</b>
                    <div style={{ width: '100%' }}>
                      {answer.length > 0 &&
                        answer.map((el: any, index: number) => (
                          <>
                            <Collapse defaultActiveKey={answer.map((el: any, index: number) => index)}>
                              <Panel
                                header={
                                  el.form.context === 'PRODUCT' ? `${el.form.name} - ${el.product.name}` : el.form.name
                                }
                                key={index}
                              >
                                {Array.isArray(el.answers) &&
                                  el.answers.map((subEl: any) => (
                                    <>
                                      <EditAnswer2
                                        quest={subEl.field_name}
                                        answer={subEl.field_value}
                                        type={subEl.field_type}
                                        all={el.answers}
                                        answer_id={el.id}
                                        form_id={el.form.id}
                                      />
                                    </>
                                  ))}
                              </Panel>
                            </Collapse>
                          </>
                        ))}
                    </div>
                  </>
                )}
              </ContainerSurvey>
            </ContainerVisit>
          )}
        </>
      )}
    </>
  )
}
