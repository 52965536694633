import styled from "styled-components";

export const Container = styled.div`
    padding: 0 20px;
`

export const Title = styled.p`
    font-weight: bold;
    font-size: 18px;
    color: #2e2f34;
    text-align: center;
    font-family: bold;
`

