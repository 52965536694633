import { Table } from 'antd'
import moment from 'moment'
import React from 'react'
import {
  randonCitiesTable,
  randonIdTable,
  randonNameTable,
  randonNetworkTable,
  randonPdvChanelTable,
  randonScriptsTable,
  randonStorePointTable,
  randonTimeTable,
  randonTypePdvTable,
  randonUfTable,
} from '../../utils/randonDatas'
import { randonValue } from '../ColumnsDash'
import { ContainerPorcent, ContainerTable } from './style'

const columnsTeste: any = [
  {
    title: 'Colaborador',
    width: 150,
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
    align: 'center',
  },
  { title: 'Mês', dataIndex: 'mounth', key: '1', width: 150, align: 'center' },
  { title: 'Semana', dataIndex: 'week', key: '2', width: 150, align: 'center' },
  { title: 'Data', dataIndex: 'date', key: '3', width: 150, align: 'center' },
  {
    title: 'Lojas visitadas',
    dataIndex: 'store_visiteds',
    key: '4',
    width: 150,
    align: 'center',
    render: (el: any) => (
      <ContainerPorcent color='rgba(	180, 106, 255, 0.6)' percent={el}>
        {el}
      </ContainerPorcent>
    ),
  },
  { title: 'Check-in', dataIndex: 'check_in', key: '5', width: 150, align: 'center' },
  { title: 'Check-out', dataIndex: 'check_out', key: '6', width: 150, align: 'center' },
  { title: 'Jornada diaria', dataIndex: 'journy', key: '7', width: 150, align: 'center' },
  { title: 'Jornada sem Almoço', dataIndex: 'launch', key: '8', width: 150, align: 'center' },
  { title: 'Jornada em loja', dataIndex: 'jouny_in_store', key: '9', width: 150, align: 'center' },
  { title: 'Deslocamento diario', dataIndex: 'moviment', key: '10', width: 150, align: 'center' },
  { title: 'Tempo Almoço', dataIndex: 'time_lunch', key: '11', width: 150, align: 'center' },
]

const dataTeste: any = [
  {
    key: '1',
    name: 'John Brown',
    mounth: '01-2022',
    week: 'Semana 4',
    date: '17/01/2022',
    store_visiteds: '12',
    check_in: '08:12:00',
    check_out: '18:12:00',
    journy: '9:30:00',
    launch: '8:25:00',
    jouny_in_store: '6:26:00',
    moviment: '1:59:00',
    time_lunch: '01:05:00',
  },
]

interface Props {
  columns?: any
  data?: any
  fullY?: boolean
  noScrool?: boolean
  pagination?: any | undefined
  onChange?: any | undefined
}

export const createRandomDataTable = (column: any, qtd: number, children?: boolean) => {
  let list: any = []

  for (let index = 0; index <= qtd; index++) {
    let obj: any = {}

    for (const [key, value] of Object.entries(column)) {
      obj[key] =
        value === 'name' || value === 'gerente' || value === 'executive'
          ? randonNameTable()
          : value === 'script'
          ? randonScriptsTable()
          : value === 'network' || value === 'flag' || value === 'profile_pdv'
          ? randonNetworkTable()
          : value === 'pdv_id'
          ? randonIdTable()
          : value === 'store_point'
          ? randonStorePointTable()
          : value === 'type_pdv'
          ? randonTypePdvTable()
          : value === 'pdv_chanel'
          ? randonPdvChanelTable()
          : value === 'city'
          ? randonCitiesTable()
          : value === 'state'
          ? randonUfTable()
          : value === 'script_date'
          ? moment().format('DD/MM/YYYY')
          : value === 'first_check_in' || value === 'last_check_out' || value === 'time_in_store'
          ? randonTimeTable()
          : value === 'out_script'
          ? 'NÃO'
          : value === 'type_check_in'
          ? 'MANUAL'
          : value === 'randon_value'
          ? randonValue(1)[0]
          : null
    }
    if (children) obj.children = []
    if (column.type) obj.type = column.type
    list.push({ ...obj, key: Math.floor(Math.random() * (999999 - 1 - 1000 + 1) + 1000) })
  }
  return list
}

export const TableAnalytcs = ({ columns, data, fullY, pagination, noScrool, onChange }: Props) => {
  return (
    <ContainerTable fullY={fullY}>
      {pagination === false ? (
        <Table
          columns={columns ? columns : columnsTeste}
          dataSource={data ? data : dataTeste}
          bordered
          scroll={{ x: noScrool === true ? '100%' : 1200, y: fullY ? 500 : '100%' }}
          pagination={false}
        />
      ) : (
        <Table
          columns={columns ? columns : columnsTeste}
          dataSource={data ? data : dataTeste}
          bordered
          pagination={pagination}
          onChange={onChange}
          scroll={{ x: noScrool === true ? '100%' : 1200, y: fullY ? 500 : '100%' }}
        />
      )}
    </ContainerTable>
  )
}
