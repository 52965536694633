import { Breadcrumb, Col, Row, Tag, Tooltip as TooltipAntd } from 'antd'
import React, { useState, useEffect, useRef } from 'react'
import { Drag } from './Drag'
import { DrawerGraph } from '../../components/DrawerGraph'
import { Loading } from '../../components/Loading'
import { CardChart } from '../../components/CardChart'
import api_v2 from '../../services/api-v2'
import moment from 'moment'
import qs from 'querystring'
import { H3Italic } from './style'
import { ModalUsersNoTask } from '../../components/ModalUsersNoTask'
import { AddButtonWhite } from '../../components/DefaultPage/style'
import { FilterOutlined, FileSearchOutlined, ClearOutlined, CloseOutlined } from '@ant-design/icons'
import { DrawerFilter } from '../../components/DrawerFilter'
import { ContainerButtonsFootter, ContainerFormFilter, GroupForm } from '../../GlobalStyles'
import { ButtonClear, ButtonSearch, LabelForm } from '../Team/Promoter/style'
import history from '../../routes/history'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import useReactRouter from 'use-react-router'
import SimpleDate from '../../components/form/SimpleDate'
import { ContainerTagg } from '../Survey/FormListPage/styles'
import { ContextUpload } from '../../context/contextUpload'
import { ModalAvaliate } from '../../components/ModalAvaliate'
import SimpleSelectObject from '../../components/form/SimpleSelectObject'
import { Search } from '../../components/form/SimpleSearch'
import { RangePickerProps } from 'antd/lib/date-picker'
import { setTimeout } from 'timers'
// import firebase from '../../utils/firebase'
// import { VscGraph } from 'react-icons/vsc'

interface PropsDefaults {
  dash_tasks_percent: number
  dash_visits_planned: number
  dash_visits_pendent: number
  dash_visits_complete: number
  dash_visits_justify: number
  dash_visits_percent: number
  dash_tasks_total: number
  dash_tasks_pendent: number
  dash_tasks_complete: number
  dash_visits_in_progress: number
  dash_promoter_online: number
  dash_promoter_offline: number
  dash_promoter_without_script: number
  dash_promoter_with_script_no_interaction: number
  dash_promoter_with_script_no_interaction_json: object[]
  dash_visits_assiduity_complete: number
  dash_visits_assiduity_justify: number
  dash_visits_assiduity_pendent: number
  dash_visits_assiduity_percent: number
  dash_visits_assiduity_planned: number
}

const DashboardPage: React.FC = () => {
  const { permissions } = ContextUpload()

  const formRef = useRef<FormHandles>(null)
  const { location } = useReactRouter()
  const [loading, setLoading] = useState<boolean>(false)
  const [step, setStep] = useState<string>('')
  const [visibleDrawer, setVisibleDrawer] = useState(false)
  const [visibleAvaliate, setVisibleAvaliate] = useState(false)
  const [visibleUsers, setVisibleUsers] = useState(false)
  const [visibleFilter, setVisibleFilter] = useState(false)
  const [scripts, setScripts] = useState<any>([])
  const [list, setList] = useState<any>([])
  const [filterTag, setFilterTag] = useState<any>({
    ref_date: null,
  })
  const [defaultValue, setDefaultValue] = useState<PropsDefaults>({} as PropsDefaults)
  let getSharement = sessionStorage.getItem('@rocketpdv:workspace')
  let newGetWorkspace: any = {}
  if (getSharement) newGetWorkspace = JSON.parse(getSharement)

  // const getdefaultDash = async () => {
  //   try {
  //     setLoading(true)
  //     let { data } = await api_v2.get('/analytics/default-backoffice-dashboards')
  //     setDefaultValue(data)
  //     setLoading(false)
  //   } catch (error) {
  //     setLoading(false)
  //     console.log(error)
  //   }
  // }

  const updateList = (data: any) => {
    setLoading(true)
    setTimeout(() => {
      setList(data)
      setLoading(false)
    }, 700)
  }

  async function search(body: any) {
    try {
      setLoading(true)
      const { ref_date, profile_id, state, sub_workspaces } = body
      setFilterTag(body)

      let obj: any = {}

      if (ref_date) {
        obj.ref_date = body.ref_date
        formRef.current!.setFieldValue('ref_date', moment(ref_date))
      }

      if (profile_id && profile_id.length > 0) {
        let text = ''
        profile_id.forEach((el: any) => (text = text === '' ? `${el.value}` : text + `,${el.value}`))
        obj = { ...obj, profile_id: text }
      }

      if (state && state.length > 0) {
        let text = ''
        state.forEach((el: any) => (text = text === '' ? `${el}` : text + `,${el}`))
        obj = { ...obj, state: text }
      }

      if (sub_workspaces) {
        obj = { ...obj, sub_workspaces: sub_workspaces.value }
      }

      const urlApi = qs.stringify(obj)
      const dashPromise = api_v2.get(`/analytics/default-backoffice-dashboards-2?${urlApi}`);
      const resPromise = api_v2.get(`/script/not-has-scripts?${urlApi}`);

      const [dashResponse, resResponse] = await Promise.all([dashPromise, resPromise]);

      const dash = dashResponse.data;
      const notHasScript = resResponse.data.result;

      setDefaultValue(dash)
      setScripts(notHasScript)
      setLoading(false)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const getStatusWithFilter = async (data?: any) => {
    try {
      let obj: any = qs.parse(location.search.replaceAll('?', ''))
      const { ref_date, profile_id, state, sub_workspaces } = data ||
        obj || {
          ref_date: moment().format('YYYY-MM-DD'),
        }
      let url: any = {}
      if (!obj || !obj.ref_date) {
        url.ref_date = moment().format('YYYY-MM-DD')
        obj.ref_date = moment().format('YYYY-MM-DD')
      }

      if (ref_date) {
        obj.ref_date = ref_date
        url.ref_date = ref_date
      } else {
        url.ref_date = moment().format('YYYY-MM-DD')
        obj.ref_date = moment().format('YYYY-MM-DD')
      }
      if (profile_id) {
        obj.profile_id = typeof profile_id === 'string' ? JSON.parse(profile_id) : profile_id
        url.profile_id = typeof profile_id === 'string' ? profile_id : JSON.stringify(profile_id)
      }

      if (state) {
        obj.state = typeof state === 'string' ? JSON.parse(state) : state
        url.state = typeof state === 'string' ? state : JSON.stringify(state)
      }
      if (sub_workspaces) {
        obj.sub_workspaces = typeof sub_workspaces === 'string' ? JSON.parse(sub_workspaces) : sub_workspaces
        url.sub_workspaces =
          typeof sub_workspaces === 'string' ? sub_workspaces['value'] : JSON.stringify(sub_workspaces['value'])
      }

      history.push(`/?${qs.stringify(url)}`)
      await search(obj)
      setVisibleFilter(false)
    } catch (error) {
      history.push(`/?ref_date=${moment().format('YYYY-MM-DD')}`)
    }
  }

  useEffect(() => {
    getStatusWithFilter()
    // isRating()
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formRef])

  useEffect(() => {
    const timer = setInterval(() => search(filterTag), 60000)
    return () => {
      clearInterval(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue])

  const cleanFilter = async () => {
    setFilterTag({ ref_date: moment().format('YYYY-MM-DD') })
    formRef.current?.clearField('state')
    formRef.current?.clearField('profile_id')
    let url: any = { ref_date: moment().format('YYYY-MM-DD') }
    formRef.current?.clearField('sub_workspaces')

    history.push(`/?${qs.stringify(url)}`)
    search({ ref_date: moment().format('YYYY-MM-DD') })
  }

  let convertUrl = () => {
    try {
      let getUrl: any = qs.parse(location.search.replaceAll('?', ''))
      let filterQuery: any = {}
      for (var el in getUrl) {
        filterQuery[el] = JSON.parse(getUrl[el])
      }
      return filterQuery
    } catch (error) {
      console.log(error)
    }
  }

  const removeFilter = async (typeRemove: any, item?: any) => {
    let obj: any = qs.parse(window.location.search.replaceAll('?', ''))
    if (obj && typeRemove === 'state') {
      if (typeof obj.state === 'string') obj.state = JSON.parse(obj.state)
      obj.state = obj.state.filter((value) => value !== item)
      if (obj.state.length === 0) delete obj[typeRemove]
      // formRef.current?.clearField('state')
    }

    if (obj && typeRemove === 'profile_id') {
      if (typeof obj.profile_id === 'string') obj.profile_id = JSON.parse(obj.profile_id)
      obj.profile_id = obj.profile_id.filter((value) => value.label !== item.label)
      if (obj.profile_id.length === 0) delete obj[typeRemove]
      // formRef.current?.clearField('state')
    }
    if (obj && typeRemove === 'sub_workspaces') {
      if (typeof obj.sub_workspaces === 'string') obj.sub_workspaces = JSON.parse(obj.sub_workspaces)
      console.log(obj.sub_workspaces)
      if (obj.sub_workspaces.length === 0) delete obj[typeRemove]
      console.log(obj.sub_workspaces.length === 0)

      // formRef.current?.clearField('state')
    }

    if (obj && typeRemove === 'ref_date') delete obj[typeRemove]
    formRef.current.reset()
    try {
      const { ref_date, profile_id, state, sub_workspaces } = obj || {
        ref_date: moment().format('YYYY-MM-DD'),
      }
      let url: any = {}
      if (!obj || !obj.ref_date) {
        url.ref_date = moment().format('YYYY-MM-DD')
        obj.ref_date = moment().format('YYYY-MM-DD')
      }

      if (ref_date) {
        obj.ref_date = ref_date
        url.ref_date = ref_date
      } else {
        url.ref_date = moment().format('YYYY-MM-DD')
        obj.ref_date = moment().format('YYYY-MM-DD')
      }
      if (profile_id && !Array.isArray(profile_id)) {
        obj.profile_id = typeof profile_id === 'string' ? JSON.parse(profile_id) : profile_id
        url.profile_id = typeof profile_id === 'string' ? profile_id : JSON.stringify(profile_id)
        formRef.current?.clearField('profile_id')
      }

      if (state) {
        obj.state = typeof state === 'string' ? JSON.parse(state) : state
        url.state = typeof state === 'string' ? state : JSON.stringify(state)
        formRef.current?.clearField('state')
      }
      if (sub_workspaces && !Array.isArray(sub_workspaces)) {
        obj.sub_workspaces = typeof sub_workspaces === 'string' ? JSON.parse(sub_workspaces) : sub_workspaces
        url.sub_workspaces = typeof sub_workspaces === 'string' ? sub_workspaces : JSON.stringify(sub_workspaces)
        formRef.current?.clearField('sub_workspaces')
      }

      history.push(`/?${qs.stringify(url)}`)
      search(obj)
      setVisibleFilter(false)
    } catch (error) {
      history.push(`/?ref_date=${moment().format('YYYY-MM-DD')}`)
    }
  }

  const isRating = async () => {
    try {
      const { data } = await api_v2.get(`user/is-rate`)
      if (data.isExist.length === 0) setVisibleAvaliate(true)
    } catch (error) {}
  }

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return current && current > moment().endOf('day')
  }

  return (
    <div style={{ padding: '0 10px' }}>
      <Row gutter={[8, 8]} justify='space-between'>
        <Col>
          <h1>Indicadores</h1>
          <Breadcrumb className='breadcumb'>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        {/* {permissions.indexOf('home.add_metric') > -1 &&
          <Col span={1}>
            <TooltipAntd placement='top' title={'Adicionar gráfico'}>
              <BigButton onClick={() => setVisibleDrawer(!visibleDrawer)} style={{ padding: 9 }}>
                <VscGraph color='#666' />
              </BigButton>
            </TooltipAntd>
          </Col>} */}
        {permissions.indexOf('home.filter') > -1 && (
          <Col>
            <TooltipAntd placement='top' title={'Filtrar'}>
              <AddButtonWhite
                onClick={() => {
                  setVisibleFilter(!visibleFilter)
                }}
                style={{ padding: 9 }}
              >
                <FilterOutlined />
              </AddButtonWhite>
            </TooltipAntd>
          </Col>
        )}
        <Col span={24}>
          <ContainerTagg>
            {filterTag && filterTag.ref_date && (
              <Tag color='geekblue' onClick={() => removeFilter('ref_date')}>
                {moment(filterTag.ref_date).format('DD/MM/YYYY')}
                <CloseOutlined />
              </Tag>
            )}
            {filterTag &&
              filterTag.profile_id &&
              filterTag.profile_id.length > 0 &&
              filterTag.profile_id.map((item) => (
                <Tag color='geekblue' onClick={() => removeFilter('profile_id', item)}>
                  {item.label}
                  <CloseOutlined />
                </Tag>
              ))}
            {filterTag &&
              filterTag.state &&
              filterTag.state.length > 0 &&
              filterTag.state.map((item) => (
                <Tag color='geekblue' onClick={() => removeFilter('state', item)}>
                  {item}
                  <CloseOutlined />
                </Tag>
              ))}
            {filterTag &&
              filterTag.sub_workspaces &&
              Object.keys(filterTag.sub_workspaces).map((key) => {
                const item = filterTag.sub_workspaces[key]
                if (key == 'label') {
                  return (
                    <Tag
                      key={key}
                      color='geekblue'
                      onClick={() => removeFilter('sub_workspaces', filterTag.sub_workspaces)}
                    >
                      {item}
                      <CloseOutlined />
                    </Tag>
                  )
                }
              })}
          </ContainerTagg>
        </Col>
      </Row>
      {loading && <Loading />}

      <Row gutter={[20, 20]} style={{ marginTop: 40 }}>
        {permissions.indexOf('home.attendance') > -1 && (
          <Col xs={24} sm={12} md={6}>
            <CardChart
              title='Atendimento do dia'
              type='indicator'
              valueIndicator={{
                JUSTIFIED_ABSENCE: defaultValue.dash_visits_justify,
                PENDENT: defaultValue.dash_visits_pendent,
                COMPLETE: defaultValue.dash_visits_complete,
                TOTAL: defaultValue.dash_visits_planned,
                PERCENT: defaultValue.dash_visits_percent,
                IN_PROGRESS: defaultValue.dash_visits_in_progress,
              }}
              tagActive={true}
              titleTollTipo='Visualizar atendimento do dia'
              route={`/backoffice/survey/visits`}
              dateToday={JSON.stringify([
                moment(filterTag.ref_date).format('YYYY-MM-DD'),
                moment(filterTag.ref_date).format('YYYY-MM-DD'),
              ])}
              profile_id={filterTag.profile_id ? JSON.stringify(filterTag.profile_id) : ''}
              state={filterTag.state ? JSON.stringify(filterTag.state) : ''}
              sub_workspaces={filterTag.sub_workspaces ? JSON.stringify(filterTag.sub_workspaces) : ''}
            />
          </Col>
        )}
        {permissions.indexOf('home.assiduity') > -1 && (
          <Col xs={24} sm={12} md={6}>
            <CardChart
              title='Assiduidade'
              type='indicator'
              valueIndicator={{
                JUSTIFIED_ABSENCE: defaultValue.dash_visits_assiduity_justify,
                PENDENT: defaultValue.dash_visits_assiduity_pendent,
                COMPLETE: defaultValue.dash_visits_assiduity_complete,
                TOTAL: defaultValue.dash_visits_assiduity_planned,
                PERCENT: defaultValue.dash_visits_assiduity_percent,
              }}
              tagActive={true}
              titleTollTipo='Visualizar assiduidade do dia'
              route={`/backoffice/survey/visits`}
              dateToday={JSON.stringify([
                moment(filterTag.ref_date).format('YYYY-MM-DD'),
                moment(filterTag.ref_date).format('YYYY-MM-DD'),
              ])}
              profile_id={filterTag.profile_id ? JSON.stringify(filterTag.profile_id) : ''}
              state={filterTag.state ? JSON.stringify(filterTag.state) : ''}
              sub_workspaces={filterTag.sub_workspaces ? JSON.stringify(filterTag.sub_workspaces) : ''}
            />
          </Col>
        )}
        {permissions.indexOf('home.tasks') > -1 && (
          <Col xs={24} sm={12} md={6}>
            <CardChart
              title='Tarefas do dia'
              type='indicator'
              valueIndicator={{
                JUSTIFIED_ABSENCE: null,
                PENDENT: defaultValue.dash_tasks_pendent,
                COMPLETE: defaultValue.dash_tasks_complete,
                TOTAL: defaultValue.dash_tasks_total,
                PERCENT: defaultValue.dash_tasks_percent,
              }}
              tagActive={true}
              titleTollTipo='Visualizar tarefas do dia'
              route={`/backoffice/survey/tasks?${qs.stringify({
                date: JSON.stringify([moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]),
              })}`}

              profile_id={filterTag.profile_id ? JSON.stringify(filterTag.profile_id) : ''}
              state={filterTag.state ? JSON.stringify(filterTag.state) : ''}
              sub_workspaces={filterTag.sub_workspaces ? JSON.stringify(filterTag.sub_workspaces) : ''}
            />
          </Col>
        )}
        {permissions.indexOf('home.team_on_off') > -1 && (
          <Col xs={24} sm={12} md={6}>
            <CardChart
              title='Equipe de campo'
              type='donults'
              valueDonults={{
                offline: defaultValue.dash_promoter_offline,
                onlline: defaultValue.dash_promoter_online,
              }}
              chartIsActive={true}
              titleTollTipo='Visualizar equipe de campo'
              route='/backoffice/monitory/monitoring'
              dateToday={JSON.stringify([
                moment(filterTag.ref_date).format('YYYY-MM-DD'),
                moment(filterTag.ref_date).format('YYYY-MM-DD'),
              ])}
              children={
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: '100%'}}>
                  <H3Italic onClick={() => {
                    setStep('VISITS')
                    setVisibleUsers(!visibleUsers)
                  }}>
                    Colaboradores sem visita para hoje: {defaultValue.dash_promoter_without_script}
                  </H3Italic>

                  <H3Italic onClick={() => {
                    setStep('SCRIPTS')
                    setVisibleUsers(!visibleUsers)
                  }}>
                    Colaboradores sem roteiro para hoje: {scripts.length}
                  </H3Italic>
                </div>
              }
              profile_id={filterTag.profile_id ? JSON.stringify(filterTag.profile_id) : ''}
              state={filterTag.state ? JSON.stringify(filterTag.state) : ''}
              sub_workspaces={filterTag.sub_workspaces ? JSON.stringify(filterTag.sub_workspaces) : ''}
            />
          </Col>
        )}
      </Row>

      <Row gutter={[10, 10]} style={{ marginTop: 50 }}>
        {list &&
          list.length > 0 &&
          list.map((el: any, index: number) => (
            <Col span={el.size} key={index}>
              <Drag
                index={index}
                id={`board-${index}`}
                size={el.size}
                setList={updateList}
                list={list}
                data={el.data}
                title={el.title}
                type={el.type}
                keysName={el.keysName}
                key={index}
              />
            </Col>
          ))}
      </Row>

      <DrawerGraph
        visible={visibleDrawer}
        close={(value: boolean) => {
          setLoading(true)
          setTimeout(() => {
            setVisibleDrawer(false)
            setLoading(false)
          }, 4)
        }}
        title='Adicionar gráficos'
        listData={list}
        setListData={setList}
      />
      <ModalUsersNoTask
        visible={visibleUsers}
        close={setVisibleUsers}
        users={defaultValue.dash_promoter_with_script_no_interaction_json}
        reloadApi={() => search(filterTag)}
        defaultStep={step}
        usersScripts={scripts}
      />
      <ModalAvaliate visible={visibleAvaliate} close={setVisibleAvaliate} />
      <Form className='form' ref={formRef} style={{ marginTop: '15px' }} onSubmit={getStatusWithFilter}>
        <DrawerFilter
          visible={visibleFilter}
          close={setVisibleFilter}
          title={'Indicadores'}
          footer={
            <ContainerButtonsFootter>
              <ButtonSearch type='button' onClick={() => formRef?.current?.submitForm()}>
                <FileSearchOutlined />
                Pesquisar
              </ButtonSearch>
              <ButtonClear onClick={() => cleanFilter()}>
                <ClearOutlined />
                Limpar
              </ButtonClear>
            </ContainerButtonsFootter>
          }
        >
          <ContainerFormFilter
            tabIndex={0}
            onKeyDown={(evt: any) => {
              const isAntdSelect = evt.target.id.indexOf('select')

              if (evt.key === 'Enter' && isAntdSelect === -1) {
                formRef?.current?.submitForm()
              }
            }}
          >
            <GroupForm>
              <LabelForm>Selecione a data</LabelForm>
              <SimpleDate
                name='ref_date'
                disabledDate={disabledDate}
                defaultValue={filterTag?.ref_date ? moment(filterTag.ref_date) : undefined}
              />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione o perfil</LabelForm>
              <Search placeholder='Selecione o perfil' name='profile_id' path='profiles' isMult={true} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione o estado</LabelForm>
              <SimpleSelectObject
                placeholder='Selecione o estado'
                name='state'
                isMulti={true}
                defaultValue={filterTag?.state || undefined}
                options={[
                  { value: 'AC', label: 'AC' },
                  { value: 'AL', label: 'AL' },
                  { value: 'AP', label: 'AP' },
                  { value: 'AM', label: 'AM' },
                  { value: 'BA', label: 'BA' },
                  { value: 'CE', label: 'CE' },
                  { value: 'DF', label: 'DF' },
                  { value: 'ES', label: 'ES' },
                  { value: 'GO', label: 'GO' },
                  { value: 'MA', label: 'MA' },
                  { value: 'MT', label: 'MT' },
                  { value: 'MS', label: 'MS' },
                  { value: 'MG', label: 'MG' },
                  { value: 'PA', label: 'PA' },
                  { value: 'PB', label: 'PB' },
                  { value: 'PR', label: 'PR' },
                  { value: 'PE', label: 'PE' },
                  { value: 'PI', label: 'PI' },
                  { value: 'RJ', label: 'RJ' },
                  { value: 'RN', label: 'RN' },
                  { value: 'RS', label: 'RS' },
                  { value: 'RO', label: 'RO' },
                  { value: 'RR', label: 'RR' },
                  { value: 'SC', label: 'SC' },
                  { value: 'SP', label: 'SP' },
                  { value: 'SE', label: 'SE' },
                  { value: 'TO', label: 'TO' },
                ]}
              />
            </GroupForm>
            {newGetWorkspace.shared_environment ? (
              <GroupForm>
                <LabelForm>Selecione a empresa</LabelForm>
                <Search placeholder='Selecione a empresa' name='sub_workspaces' path='sub-workspaces' isMult={false} />
              </GroupForm>
            ) : null}
          </ContainerFormFilter>
        </DrawerFilter>
      </Form>
    </div>
  )
}

export default DashboardPage
