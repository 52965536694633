import React, { useEffect, useState } from 'react'
import { Container, Title, TextError } from './styles'
import { Upload, Modal } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import firebase from '../../../../../utils/firebase'

interface IQuestion {
  id: number
  is_required: boolean
  name: string
  order: string
  type: string
  fields_true?: any
}
interface IDataQuestion {
  dataQuestion: IQuestion
  handleAnswers: (value: any, dataQuestion: IQuestion) => void
  error?: boolean
  body: any
  checkRequired: (value: any) => void
}

function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export const Picture = ({ dataQuestion, handleAnswers, error, checkRequired, body }: IDataQuestion) => {
  const [pictures, setPictures] = useState<any>([])
  const [urls, setUrls] = useState<any>([])
  const [isRender, setIsRender] = useState(false)
  const [preview, setPreview] = useState<any>({})
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  useEffect(() => {
    const answers = () => {
      handleAnswers(urls, dataQuestion)
    }

    answers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pictures])

  useEffect(() => {
    if (dataQuestion.is_required && !isRender) {
      setIsRender(true)
      checkRequired(dataQuestion.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCancel = () => setPreview({ previewVisible: false })

  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    setPreview({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    })
  }

  const handleChange = ({ file, fileList }: any) => {
    setPictures(fileList)
    const team: any = sessionStorage.getItem('@rocketpdv:workspace')
    uploadImage({
      store_id: body.pdv_id,
      form_id: body.form_id,
      uri: file.originFileObj,
      slug: JSON.parse(team).slug,
      fileList: file,
      all: fileList,
    })
  }

  const uploadImage = async (urls: any) => {
    try {
      let file = firebase
        .storage()
        .ref(`${urls.slug}/${urls.store_id}/tasks/${urls.form_id}/backoffice/${urls.uri.name}`)

      await file.put(urls.uri)
      const url = await file.getDownloadURL()
      let newPictures: any = []

      urls.all.forEach((el: any) => {
        if (el.uid === urls.fileList.uid) {
          newPictures.push({ ...urls.fileList, status: 'done', url: url })
        } else {
          newPictures.push(el)
        }
      })

      let listUrl = newPictures.map((el: any) => el.url)
      setPictures(newPictures)
      setUrls(listUrl)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Container>
        <Title>
          {dataQuestion.order} - {dataQuestion.name} {dataQuestion.is_required && '*'}
        </Title>
        <Upload
          // action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
          listType='picture-card'
          fileList={pictures}
          onPreview={handlePreview}
          onChange={handleChange}
        >
          {pictures.length >= 20 ? null : uploadButton}
        </Upload>
        {error && urls.length === 0 && <TextError>Campo obrigatório</TextError>}
      </Container>
      <Modal visible={preview?.previewVisible} title={preview?.previewTitle} footer={null} onCancel={handleCancel}>
        <img alt='example' style={{ width: '100%' }} src={preview?.previewImage} />
      </Modal>
    </>
  )
}
