import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { allPermissions } from '../routes/ScrollToTop'

interface ContextUploadData {
  minimize: boolean
  visible: boolean
  close: any
  minimizeTab: any
  openSearch: boolean
  setOpenSearch: any
  path: string
  setPath: any
  permissions: string[]
  setPermissions: any
}

interface UploadProvideProps {
  children: ReactNode
}

export const UploadContext = createContext({} as ContextUploadData)

function UploadProvide({ children }: UploadProvideProps) {
  const [visible, setVisible] = useState(false)
  const [minimize, setMinimize] = useState(false)
  const [openSearch, setOpenSearch] = useState(false)
  const [path, setPath] = useState('')
  const [permissions, setPermissions] = useState<string[]>(allPermissions)

  const close = () => {
    setVisible(!visible)
  }

  const minimizeTab = (change: boolean) => {
    setMinimize(change)
  }

  const handleKeys = (e: any) => {
    if ((e.metaKey || e.ctrlKey) && e.code === 'KeyB') {
      setOpenSearch(true)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeys)

    return () => {
      window.removeEventListener('keydown', handleKeys)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <UploadContext.Provider
      value={{
        minimize,
        visible,
        close,
        minimizeTab,
        path,
        setPath,
        openSearch,
        setOpenSearch,
        permissions,
        setPermissions,
      }}
    >
      {children}
    </UploadContext.Provider>
  )
}

function ContextUpload() {
  const context = useContext(UploadContext)
  return context
}

export { UploadProvide, ContextUpload }
