import axios from 'axios'
import { getDataUser, getToken } from '../utils/login'

const api_ms_promoter = axios.create({
  // baseURL: 'http://localhost:7001',
  baseURL: process.env.REACT_APP_API_MS_PROMOTER_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

api_ms_promoter.interceptors.request.use((config) => {
  const token = getToken()
  const team = sessionStorage.getItem('@rocketpdv:workspace') ? sessionStorage.getItem('@rocketpdv:workspace') : undefined
  const headers = { ...config.headers }
  if (token) headers.Authorization = `Bearer ${token}`
  if (team) {
    headers.user_id = getDataUser().id
  }
  return { ...config, headers }
})

export default api_ms_promoter
