import axios from 'axios'
import { getToken } from '../utils/login'

const api_v2 = axios.create({
  baseURL: process.env.REACT_APP_API_V2_URL,
  // baseURL: 'http://localhost:9000/',
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 600000,
})

api_v2.interceptors.request.use((config) => {
  const token = getToken()
  const team = sessionStorage.getItem('@rocketpdv:workspace') ? sessionStorage.getItem('@rocketpdv:workspace') : undefined
  const headers = { ...config.headers }
  if (token) headers.Authorization = `Bearer ${token}`
  if (team) {
    headers.workspace = JSON.parse(team).slug
    headers.workspaceId = JSON.parse(team).id
  }
  return { ...config, headers }
})

export default api_v2
