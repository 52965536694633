import styled from 'styled-components'

export const UploadImage = styled.div`
  position: relative;
  width: 95%;
  height: 100%;
  text-align: center;
  border: 1px dashed #d9d9d9;
  cursor: pointer;
  background: #fafafa;

  transition: border-color 0.3s;

  h5 {
    cursor: pointer;
  }

  &:hover {
    border-color: #40a9ff;
  }

  svg {
    margin-top: 10px;
    cursor: pointer;
  }

  img {
    height: 150px;
    width: 100%;
  }
`

export const LabelCheck = styled.div`
  text-align: center;
  font-weight: bold;
  margin: 10px 0;
`

export const InputCheckbox = styled.div`
  label,
  span {
    display: flex;
    margin: 0 auto;
  }
`
