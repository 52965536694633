import styled from "styled-components";

export const ContainerOverflow = styled.div`
    overflow-y: auto;
    height: 85%;
    ::-webkit-scrollbar {
    width: 5px;
    }

    ::-webkit-scrollbar-thumb {
    background-color: #b56aff;
    border: 3px solid #b56aff;
    border-radius: 5px;
    }
`

