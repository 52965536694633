import React, { useState, useEffect } from 'react'
import { Container, ContainerQuestion, Preview } from './style'

import { Button } from 'antd'
import ViewQuestion from '../ViewQuestion'

const QuestionPreview: React.FC<any> = ({ questions, order }) => {
  const [dataQuestion, setDataQuestion] = useState<any>([])
  const [fields, setFields] = useState<any>([])
  const [active, setActive] = useState<any>(0)

  useEffect(() => {
    if(questions) setDataQuestion([...questions])
    initQUest()
  }, [questions]) // eslint-disable-line react-hooks/exhaustive-deps

  const initQUest = () => {
    setFields([])
    if (questions.length > 0) setActive(1)
  }

  const subQuestion = async (indexPai: any, fieldQuestion: any, sub: any, subIndex: any) => {
    setActive(0)
    let data = dataQuestion
    let field = fields
    let have = 0
    if (fieldQuestion === 'SIM') {
      if (field.length > 0) {
        field.forEach((value: any, index: any) => {
          if (value?.index === indexPai) {
            if (data[indexPai].fields_true) {
              field[index] = [
                {
                  index: indexPai,
                  question:
                    data[indexPai].fields_true.lenght === 1 ? [data[indexPai].fields_true] : data[indexPai].fields_true,
                },
              ]
              have = 1
            } else {
              field.splice(index, 1)
            }
          }
        })
      }
      if (data[indexPai].fields_true) {
        if (have === 0)
          field = [
            ...field,
            {
              index: indexPai,
              question:
                data[indexPai].fields_true.lenght === 1 ? [data[indexPai].fields_true] : data[indexPai].fields_true,
            },
          ]
      }
    }

    if (fieldQuestion === 'NÃO') {
      if (field.length > 0) {
        field.forEach((value: any, index: any) => {
          if (value?.index === indexPai) {
            if (data[indexPai].fields_false) {
              field[index] = [
                {
                  index: indexPai,
                  question:
                    data[indexPai].fields_false.lenght === 1
                      ? [data[indexPai].fields_false]
                      : data[indexPai].fields_false,
                },
              ]
              have = 1
            } else {
              field.splice(index, 1)
            }
          }
        })
      }
      if (data[indexPai].fields_false) {
        if (have === 0)
          field = [
            ...field,
            {
              index: indexPai,
              question:
                data[indexPai].fields_false.lenght === 1 ? [data[indexPai].fields_false] : data[indexPai].fields_false,
            },
          ]
      }
    }
    await setFields(field)
    await setActive(1)
  }

  return (
    <Container>
      {active === 1 ? (
        <>
          {dataQuestion?.map((question: any, indexPai: any) => (
            <>
              <ViewQuestion
                questions={[question]}
                fields={(fields: any, subIndex: any) => subQuestion(indexPai, fields, '1º', subIndex)}
              />
              {fields.length > 0 &&
                fields.map((quest: any, questSub1: any) => (
                  <>
                    {quest.index === indexPai ? (
                      <>
                        {quest.question.map((subQuest: any) => (
                          <ViewQuestion
                            questions={[subQuest]}
                            fields={(fields: any, subIndex: any) => subQuestion(questSub1, fields, '1º', subIndex)}
                          />
                        ))}
                      </>
                    ) : null}
                  </>
                ))}
            </>
          ))}
        </>
      ) : active === 2 ? (
        <Preview>
          <ContainerQuestion
            style={{
              height: '300px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <h2 style={{ letterSpacing: '2px' }}>Fim de questionario...</h2>
            <Button>Refazer questionario</Button>
          </ContainerQuestion>
        </Preview>
      ) : (
        // ))
        <Preview>
          <ContainerQuestion
            style={{
              height: '300px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <h2 style={{ letterSpacing: '2px' }}>Adicione uma pergunta para ter uma visualização...</h2>
          </ContainerQuestion>
        </Preview>
      )}
    </Container>
  )
}

export default QuestionPreview
