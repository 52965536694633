import React, { useState, useEffect, useRef } from 'react'
import {
  Button,
  CardCreate,
  ContainerModal,
  ContainerStep1,
  ContainerStep2,
  LabelCheck,
  InputCheckbox,
  CardCreateUpload,
} from './style'
import AddUser from '../../assets/images/addUser.png'
import ImportDoc from '../../assets/images/importDoc.png'
import { CloudUploadOutlined, PlusOutlined, CloudDownloadOutlined } from '@ant-design/icons'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import Input from '../form/SimpleInput'
import * as Yup from 'yup'
import { Mixpanel } from '../../utils/mixpanel'
import { message, Checkbox, Select, Modal, Row, Col } from 'antd'
import InputMask from '../form/InputMask'
import { optionUF } from '../../utils/option-user'
import { BtnExport, ButtonLink } from '../ModalImport/style'
import ApiCep from '../../services/ViaCep'
import { Loading } from '../Loading'
import api_v2 from '../../services/api-v2'
import { Search } from '../form/SimpleSearch'

interface IProps {
  visible: boolean
  close: (boolean: any) => void
  type: string
  reloadApi?: (boolean: any) => void
}

interface IAdress {
  district: string
  city: string
  street: string
}

export const ModalCreateUser = ({ visible, close, type, reloadApi }: IProps) => {
  const formRef = useRef<FormHandles>(null)
  const [step, setStep] = useState(1)
  const [checked, setChecked] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [states, setStates] = useState<any>()
  const [defaultAdress, setDefaultAdress] = useState<IAdress>({} as IAdress)

  async function handleSubmit(body: any) {
    if (body.work_hour_id) body.work_hour_id = body.work_hour_id.value
    setLoading(true)
    try {
      console.log(body)
      let obj: any = {
        name: Yup.string().required('* Insira o nome completo'),
        password: Yup.string()
          .min(6, '* A senha precisa ter no mínimo 6 caracteres')
          .required('* A senha é obrigatória'),
        // cpf: Yup.string().required('* Insira um CPF válido'),
      }

      if (type === 'SUPERVISOR') {
        body.promoters = body.promoters.map((el: any) => el.value)
        obj = {
          ...obj,
          promoters: Yup.array().of(Yup.number()).min(1, 'Mínimo 1 promotor').required('Campo obrigatório'),
        }
      }
      const schema = Yup.object().shape(obj)
      await schema.validate(body, {
        abortEarly: false,
      })
      // if(body.phone) {
      //   var x = body.phone.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})/)

      //   body.phone = '(' + x[1] + ')' + x[2] + '-' + x[3]
      // }

      if (body.profile) {
        body.profile_id = body.profile.value
      }
      let data = {
        is_active: checked,
        state_covered: states,
        type,
        ...body,
        state: states,
      }

      await api_v2.post('/promoter', data)
      let getWorkspace = sessionStorage.getItem('@rocketpdv:workspace')
      Mixpanel.track('Criar Usuário', {
        params: `name: ${body.name}, email: ${body.email}, workspace: ${getWorkspace}, type: ${type}`,
      })

      message.success('Perfil cadastrado com sucesso')
      close(false)
      if (reloadApi) {
        reloadApi(true)
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((el: any) => {
          formRef.current!.setFieldError(el.path, el.message)
        })
      } else {
        message.error('Já existe um registro com essas informações!')
      }
    }
    setLoading(false)
  }

  const handleChange = (value: any) => {
    setStates(value)
  }

  const uploadFile = async (info: any) => {
    if (info.event) await importFile(info.file.originFileObj)
  }

  const uploadFileDrop = async (info: any) => {
    if (info.event) await importFile(info.file.originFileObj)
  }

  const importFile = async (file: any) => {
    setLoading(true)
    if (file) {
      const archive: any = new FormData()
      archive.append('file', file)
      try {
        await api_v2.post('/import-xlsx/PROMOTER', archive, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        Mixpanel.track('Criar Usuário por arquivo', {})
        close(!visible)
        Modal.success({
          title: 'Sucesso!!',
          content: 'Arquivo enviado com sucesso!!!',
          onOk() {
            window.location.reload()
          },
        })
      } catch (error) {
        Modal.error({
          title: 'Erro',
          content: 'Erro ao enviar arquivo!!!',
        })
      }
    }
    setLoading(false)
  }

  const getViaCep = async (evt: any) => {
    let cep = evt.target.value.replace('-', '').replaceAll('_', '')
    if (cep.length === 8) {
      let { data } = await ApiCep.SearchCep(cep)
      setDefaultAdress({
        district: data.bairro,
        city: data.localidade,
        street: data.logradouro,
      })
    }
  }

  useEffect(() => {
    setStep(1)
  }, [visible])

  return (
    <ContainerModal
      visible={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
      destroyOnClose={true}
      width={step === 0 ? 700 : '60vw'}
    >
      {loading && <Loading />}
      {step === 0 && (
        <ContainerStep1>
          <CardCreate style={{ marginRight: 30 }}>
            <img src={AddUser} alt='user' />
            <h4>Adicionar Manual</h4>
            <p>
              Adicione um{' '}
              {type === 'PROMOTOR'
                ? 'promotores'
                : type === 'GESTOR'
                ? 'gestores'
                : type === 'SUPERVISOR'
                ? 'supervisor'
                : null}{' '}
              por vez.
            </p>
            <Button onClick={() => setStep(1)}>
              <PlusOutlined />
              Adicionar{' '}
              {type === 'PROMOTOR'
                ? 'Promotor'
                : type === 'GESTOR'
                ? 'Gestor'
                : type === 'SUPERVISOR'
                ? 'supervisor'
                : null}
            </Button>
          </CardCreate>
          <div>
            <CardCreateUpload onDrop={uploadFileDrop} onChange={uploadFile}>
              <img src={ImportDoc} alt='user' />
              <h4>Importar Planilha</h4>
              <p>
                Importe vários{' '}
                {type === 'PROMOTOR'
                  ? 'promotores'
                  : type === 'GESTOR'
                  ? 'gestores'
                  : type === 'SUPERVISOR'
                  ? 'supervisor'
                  : null}{' '}
                de uma só vez.
              </p>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <Button style={{ backgroundColor: '#b56aff', marginTop: 6 }}>
                  <CloudUploadOutlined style={{ marginRight: 10 }} />
                  Importar Planilha
                </Button>
              </div>
            </CardCreateUpload>
            <ButtonLink>
              <CloudDownloadOutlined />
              <BtnExport href={process.env.REACT_APP_API_URL + `/`} target='_blank'>
                Baixe o modelo
              </BtnExport>
            </ButtonLink>
          </div>
        </ContainerStep1>
      )}
      {step === 1 && (
        <Form
          translate={undefined}
          className='form'
          ref={formRef}
          initialData={{ is_active: true }}
          onSubmit={handleSubmit}
        >
          <ContainerStep2>
            <h3>Cadastro</h3>

            {/* <h3>Cadastro de {type === 'PROMOTOR' ? 'Promotor' : type === 'GESTOR' ? 'Gestor' : null}</h3> */}
            <img src={AddUser} alt='user' />
            <Row gutter={[15, 15]}>
              <Col xs={24} sm={24} md={12} lg={8}>
                <Input
                  type='text'
                  label='Nome completo *'
                  name='name'
                  placeholder='Nome completo'
                  style={{ width: '100%' }}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={8}>
                <Input type='email' label='Email' name='email' placeholder='Email' style={{ width: '100%' }} />
              </Col>
              <Col xs={24} sm={24} md={12} lg={8}>
                <InputMask name='cpf' placeholder='CPF ' label='CPF' mask='999.999.999-99' style={{ width: '100%' }} />
              </Col>
              <Col xs={24} sm={24} md={12} lg={8}>
                <Input type='text' label='Nickname' name='nickname' placeholder='Apelido' style={{ width: '100%' }} />
              </Col>
              <Col xs={24} sm={24} md={12} lg={8}>
                <Input
                  type='password'
                  label='Senha *'
                  name='password'
                  placeholder='Informe a senha'
                  style={{ width: '100%' }}
                />
              </Col>
              {type === 'PROMOTER' && (
                <Col xs={24} sm={24} md={12} lg={8}>
                  <Input type='text' label='Telefone' name='phone' placeholder='Telefone' style={{ width: '100%' }} />
                </Col>
              )}
              <Col xs={24} sm={24} md={12} lg={8}>
                <InputMask
                  name='cep'
                  placeholder='CEP '
                  label='CEP'
                  mask='99999-999'
                  style={{ width: '100%' }}
                  onChange={getViaCep}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={8}>
                <Input
                  type='text'
                  defaultValue={defaultAdress.street}
                  label='Rua'
                  name='street'
                  placeholder='rua'
                  style={{ width: '100%' }}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={8}>
                <Input
                  type='text'
                  defaultValue={defaultAdress.district}
                  label='Bairro'
                  name='neighborhood'
                  placeholder='bairro'
                  style={{ width: '100%' }}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={8}>
                <Input
                  type='text'
                  defaultValue={defaultAdress.city}
                  label='Cidade'
                  name='city'
                  placeholder='cidade'
                  style={{ width: '100%' }}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={8}>
                <LabelCheck style={{ width: '100%', textAlign: 'left', fontWeight: 400 }}>
                  Estado (UF)
                </LabelCheck>
                <Select
                  allowClear
                  style={{ width: '100%', margin: 0 }}
                  placeholder='Selecione'
                  onChange={handleChange}
                >
                  {optionUF.map((option, index: number) => (
                    <Select.Option value={option.value} key={index}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>

              </Col>
              <Col xs={24} sm={24} md={12} lg={8}>
                <p>Selecione o perfil</p>
                <Search placeholder='Selecione o perfil' name='profile' path='profiles' isMult={false} />
              </Col>
              <Col xs={24} sm={24} md={12} lg={8}>
                <div style={{ width: '100%', marginTop: 15 }}>
                  <p style={{ marginBottom: 5 }}>Intrajornada</p>
                  <Search label='Intrajornada' placeholder='Intrajornada' name='work_hour_id' path='work_hour' />
                </div>
              </Col>
              {type === 'SUPERVISOR' && (
                <Col xs={24} sm={24} md={12} lg={8}>
                  <LabelCheck style={{ width: '100%', textAlign: 'left', fontWeight: 400 }}>
                    Selecione os promotores*
                  </LabelCheck>
                  <Search placeholder='Selecione o promotor' name='promoters' path='promoters' isMult={true} />
                </Col>
              )}
              <Col xs={24} sm={24} md={24} lg={24}>
                <LabelCheck>Ativo?</LabelCheck>
                <InputCheckbox>
                  <Checkbox
                    name='is_active'
                    checked={checked}
                    defaultChecked={true}
                    onChange={(e: any) => {
                      setChecked(e.target.checked)
                    }}
                  />
                </InputCheckbox>
              </Col>
            </Row>
            <Button type='submit' style={{ width: '100%', height: '55px', marginTop: 20 }}>
              Cadastrar
            </Button>
          </ContainerStep2>
        </Form>
      )}
    </ContainerModal>
  )
}
