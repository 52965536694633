import styled from "styled-components"

export const ContainerGraph = styled.div`
  width: 100%;
  height: 100%;
  z-index: 99999;
  &&& .recharts-legend-wrapper {
    /* bottom: -15px !import; */
  }
`

export const ContainerToolTip = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;


`

export const ContainerLegend = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  div {
    margin-right: 4px;
    width: 10px;
    height: 8px;
  }
  p {
    width: 60%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    margin: 0;
  }
`

export const ContainerHeader = styled.div`
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;

  .titleGraph{
    font-size: 16px;
    font-weight: bold;
  }
`

export const ContainerIconGraph = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: grab;

    svg {
      padding: 3px;
      cursor: pointer;
      border-radius: 5px;
      font-size: 23px;

    }

    svg:hover {
      transition: all 0.3s;
      background-color: #b468fe;
      color: #fff;
    }

    &&& .ant-btn-icon-only {
      box-shadow: none;
      border: none;
      background: transparent;
    }
`

export const ContainerExpandGraph = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0,0,0, 0.3);
  z-index: 9999;
`

export const GraphContainerExpan = styled.div`
  width: 70%;
  height: 70%;
  background-color: #fcfcfc;
  border-radius: 10px;
  box-shadow: 1px 0px 12px 4px #7f7f7f;
  display: flex;
  align-items: center;
  justify-content: center;

`

export const ContainerTitle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 80px;
  background-color: rgba(0,0,0, 0.4);
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  p{
    text-align: center;
    font-size: 25px;
    text-transform: uppercase;
    font-weight: bold;
    color: #fff;
  }
`

export const ContainerIconExpand = styled.div`
  position: fixed;
  top: 0;
  right: 20px;
  height: 80px;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 20px;
    cursor: pointer;
    margin-right: 20px;
  }
`