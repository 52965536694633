import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    border-radius: 12px;
    border: 1px dashed #b56aff;
    background-color: #ebebeb;
    padding: 23px;
    margin-bottom: 30px;
    align-self: baseline;
    height: 650px;
`

export const Count = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-color: #b56aff;
    color: #ffffff;
    font-family: "Open Sans";
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
`
export const ContainerHeader = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 30px;
    border-bottom: 2px solid #D6D6D6;
    margin-bottom: 20px;
`
export const Text = styled.p`
    color: #272d3b;
    font-family: "Open Sans";
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 0;
`
export const Scroll = styled.div`
    overflow-y: scroll;
    max-height: 85%;

    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #b56aff;
        border: 3px solid #b56aff;
        border-radius: 5px;
    }
`