/* eslint-disable jsx-a11y/alt-text */
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { Breadcrumb, Button, Cascader, Checkbox, Col, Collapse, message, Row } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { MdFileUpload } from 'react-icons/md'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import Input from '../../../components/form/SimpleInput'
import Select from '../../../components/form/SimpleSelect'
import { Container, customStyles, Label } from '../../../GlobalStyles'
import history from '../../../routes/history'
import api from '../../../services/api'
import { Mixpanel } from '../../../utils/mixpanel'
import { optionsProductType } from '../../../utils/option-user'
import { InputCheckbox, LabelCheck, UploadImage } from './styles'
import { Loading } from '../../../components/Loading'
import { TableEdit } from '../../../components/TableEdit'
import { Search } from '../../../components/form/SimpleSearch'
import api_v2 from '../../../services/api-v2'
import InputNumberDefault from '../../../components/form/InputNumberDefault'

const ProductEditPage: React.FC = () => {
  const { id }: any = useParams()
  const formRef = useRef<FormHandles>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [product, setProduct] = useState<any>()
  const [categories, setCategories] = useState()
  const [categorySelected, setCategorySelected] = useState()
  const [checked, setChecked] = useState<boolean>(true)
  const [data, setData] = useState<any>()
  const [productEdit, setProductEdit] = useState<any>()
  const [listWorkspaces, setListWorkspaces] = useState()
  const [image, setImage] = useState({ preview: '', raw: '' })
  let getWorkspace = sessionStorage.getItem('@rocketpdv:workspace')
  let newGetWorkspace = JSON.parse(getWorkspace!.toString())

  async function loadInitialData() {
    setLoading(true)
    const { data } = await api_v2.get(`/product/${id}`)

    formRef.current!.setFieldValue('brand_id', {
      value: data.brand.id,
      label: data.brand.name,
    })


    formRef.current!.setFieldValue('product_type', {
      value: data.product_type,
      label:
        (data.product_type === 'OWN' && 'Própria') ||
        (data.product_type === 'CONCURRENT' && 'Concorrente') ||
        (data.product_type === 'ALL' && 'Todos'),
    })

    formRef.current!.setFieldValue('category_id', {
      value: data.category.id,
      label: data.category.name,
    })

    if(newGetWorkspace.shared_environment ) {
      const {data: sub} = await api_v2.get(`workspace-sub?perpage=1000&page=1`)

      let exist = sub.result.find((item) => item.id == data.sub_workspace_id)

      formRef.current!.setFieldValue('sub_workspace', {
        value: exist.id,
        label: exist.name,
      })
    }

    setData(data)
    setProductEdit(data)
    setLoading(false)
  }
  useEffect(() => {
    async function loadWorkspaces() {
      setLoading(true)
      const { data } = await api_v2.get(`workspace-sub?perpage=1000&page=1`)
      const result = data.result.map((item: any) => ({
        value: item.id,
        label: item.name,
      }))
      setLoading(false)
      setListWorkspaces(result)
    }

    async function loadBrand() {
      setLoading(true)
      const { data } = await api_v2.get(`/product/${id}`)
      setProduct({ ...data })
      image.preview = data.image_url

      setCategorySelected(data.category.id)
      setLoading(false)
    }

    async function loadCategories() {
      const result = await getCategories('0')
      setCategories(result)
    }
    loadWorkspaces()
    loadCategories()
    loadBrand()
    loadInitialData()
  }, [formRef]) // eslint-disable-line react-hooks/exhaustive-deps

  async function getCategories(id: string) {
    setLoading(true)
    const { data } = await api.get('category/' + id)
    const result = data.categories.map((item: any) => ({
      value: item.id,
      label: item.name,
      isLeaf: item.count_childrens === 0,
    }))
    setLoading(false)
    return result
  }

  const handleChange = (e: any) => {
    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0],
    })
  }

  async function handleSubmit(body: any) {

    if(newGetWorkspace.shared_environment && !body.sub_workspace) {
      message.error('Empresa é obrigatório!!')
      return
    }
      setLoading(true)
    try {
      if (body.brand_id && body.brand_id?.value) body.brand_id = body.brand_id?.value
      if (body.brand_id && body.brand_id[0]?.value) body.brand_id = body.brand_id[0]?.value

      console.log(body)
      const schema = Yup.object().shape({
        name: Yup.string().required('O Campo nome é obrigatório'),
        category_id: Yup.string().required('O Campo categoria é obrigatório'),
        brand_id: Yup.string().required('O Campo categoria é obrigatório'),

        ean: Yup.string(),
      })

      await schema.validate(body, {
        abortEarly: false,
      })

      if (data.image_name) await api.post('/remove-image', { filename: data.image_name })

      const formData = new FormData()
      formData.append('image_url', image.raw)
      let product: any = await api.post('/upload-image', formData)

      await api_v2.put(`/product/${id}`, {
        ...body,
        image_url: product.data.filename,
        image_name: product.data.name,
        is_active: checked,
        custom_fields: productEdit.custom_fields,
      })

      Mixpanel.track('Editar produto', {
        params: {
          ...body,
          image_url: product.data.filename,
          image_name: product.data.name,
          is_active: checked,
          custom_fields: productEdit.custom_fields,
        },
      })
      message.success('Produto editado com sucesso')
      history.push('/backoffice/catalog/products')
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((el: any) => {
          formRef.current!.setFieldError(el.path, el.message)
        })
      } else {
        message.error('Já existe um registro com essas informações!')
      }
    }
    setLoading(false)
  }

  async function loadData(selectedOptions: any) {
    const targetOption = selectedOptions[selectedOptions.length - 1]
    targetOption.loading = true

    const result = await getCategories(targetOption.value)
    targetOption.loading = false
    targetOption.children = result

    setCategories(categories)
  }

  return (
    <Container>
      {loading ? <Loading /> : null}
      <h1>Edição do produto</h1>
      <p>Insira os dados para realizar o cadastro</p>
      <Breadcrumb style={{ paddingLeft: 10 }} separator='>' className='breadcumb'>
        <Link to='/backoffice/catalog/products'>
          <Breadcrumb.Item> Produtos </Breadcrumb.Item>
        </Link>
        <Breadcrumb.Item> Edição de produtos </Breadcrumb.Item>
      </Breadcrumb>
      <Form translate={undefined} className='form' ref={formRef} initialData={data} onSubmit={handleSubmit}>
        <Row gutter={16}>
          <Col xs={10} sm={10} md={11} lg={11}>
            <Input type='text' label='Nome *' name='name' placeholder='Nome' />
          </Col>
          <Col xs={10} sm={10} md={11} lg={11}>
            <Label>Categoria *</Label>
            <Cascader
              style={{ width: '100%', marginBottom: '10px' }}
              options={categories}
              loadData={loadData}
              onChange={(value: any) => setCategorySelected(value[value.length - 1])}
              placeholder={product?.category?.name}
              changeOnSelect
            />
            <Input type='hidden' name='category_id' value={categorySelected} />
          </Col>
          <Col xs={4} sm={4} md={2} lg={2}>
            <LabelCheck>Está ativo ?</LabelCheck>
            <InputCheckbox>
              <Checkbox
                name='is_active'
                checked={checked}
                defaultChecked={true}
                onChange={(e: any) => {
                  setChecked(e.target.checked)
                }}
              />
            </InputCheckbox>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col md={8} lg={8}>
            <Input type='text' label='Código ERP' name='erp_code' placeholder='Código ERP' />
          </Col>
          <Col span={8}>
            <Label>Marca *</Label>
            <Search placeholder='Selecione' path='brands' name='brand_id' />
          </Col>
          <Col md={8} lg={8}>
            <Label>Tipo *</Label>
            <Select
              placeholder='Informe o tipo do produto'
              styles={customStyles}
              name='product_type'
              options={optionsProductType}
            />
          </Col>
        </Row>
        <Row  gutter={16}>
          <Col span={6}>
            <Input type='text' label='EAN' name='ean' placeholder='EAN' />
          </Col>
          {newGetWorkspace.shared_environment && (
            <Col span={6}>
              <Label>Empresas *</Label>
              <Select placeholder='Selecione' styles={customStyles} options={listWorkspaces} name='sub_workspace' />
            </Col>
          )}
          <Col xs={10} sm={10} md={6} lg={6}>
            <Label>Valor máximo</Label>
            <InputNumberDefault label='Valor máximo'  name='max_value' placeholder='Valor máximo' />
          </Col>
          <Col xs={10} sm={10} md={6} lg={6}>
            <Label>Valor mínimo</Label>
            <InputNumberDefault label='Valor mínimo' name='min_value' placeholder='Valor mínimo' />
          </Col>
        </Row>
        <Row>
          <Col span={4}>
            <UploadImage>
              <label htmlFor='upload-button'>
                {image.preview ? (
                  <img src={image.preview} width={50} height={300} />
                ) : (
                  <>
                    <span className='fa-stack fa-2x mt-3 mb-2'>
                      <MdFileUpload size={30} />
                    </span>
                    <h5 className='text-center'>Upload</h5>
                  </>
                )}
              </label>
              <input
                type='file'
                name='image_url'
                id='upload-button'
                style={{ display: 'none' }}
                onChange={handleChange}
              />
            </UploadImage>
          </Col>
        </Row>
        {productEdit &&
          !Array.isArray(productEdit.custom_fields) &&
          Object.getOwnPropertyNames(productEdit.custom_fields).length > 0 && (
            <Collapse bordered={false}>
              <Collapse.Panel header='Campos customizados' key='1'>
                <Row>
                  <Col span={24}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                      <TableEdit
                        productEdit={productEdit}
                        update={(newData: any) => {
                          let newObj: any = {}
                          newData.forEach((el: any) => {
                            newObj[el.chave] = el.valor
                          })

                          setProductEdit({ ...productEdit, custom_fields: newObj })
                        }}
                      />
                      {/* <Table
                          style={{ width: '100%' }}
                          components={components}
                          columns={column}
                          pagination={false}
                          dataSource={Object.getOwnPropertyNames(productEdit.custom_fields).map((el: any) => {
                            return {
                              chave: el,
                              valor: productEdit.custom_fields[el],
                            }
                          })}
                        /> */}
                    </div>
                  </Col>
                </Row>
              </Collapse.Panel>
            </Collapse>
          )}
        <Row gutter={[16, 30]} style={{ marginTop: 10, paddingLeft: 10 }}>
          <Col>
            <Button type='primary' htmlType='submit'>
              Salvar
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  )
}

export default ProductEditPage
