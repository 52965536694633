import React from 'react'
import QuestionBuilder from '../QuestionBuilder'
import {
  SlidersTwoTone,
  CopyTwoTone,
  DiffTwoTone,
  CheckCircleTwoTone,
  CameraTwoTone,
  QuestionCircleTwoTone,
  AccountBookTwoTone,
  DatabaseTwoTone,
  AppstoreTwoTone,
  BranchesOutlined,
  QrcodeOutlined,
  CloudTwoTone,
  SnippetsTwoTone
} from '@ant-design/icons'
import { Button } from 'antd'
import { IdQuestion } from '../DragDrop/style'
import { useDrag, useDrop } from 'react-dnd'
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai'

const CardForm: React.FC<any> = ({
  refactorOrderDragDrop,
  quest,
  editQuestion,
  setOpenConfig,
  setConfigQuestion,
  questionDelete,
  jump,
  questions,
  index,
  upOrder,
  downOrder
}) => {
  const [{ isDragging }, dragRef] = useDrag(() => ({
    type: 'CARD',
    item: quest,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }))

  const [, dropRef] = useDrop(() => ({
    accept: 'CARD',
    drop: (item: any, monitor: any) => {
      refactorOrderDragDrop(item, quest)
      // #item é qual card esta sendo arrastado
      // #quest é o item a sobrepor
    },
  }))

  return (
    <div ref={dropRef}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* <HolderOutlined
          ref={dragRef}
          style={{
            marginLeft: 20,
            marginBottom: 15,
            cursor: 'grab',
          }}
        /> */}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 20
          }}
        >
          {questions && questions.length - 1 !== index &&
          <AiOutlineArrowDown
            onClick={() => refactorOrderDragDrop(questions[index + 1], quest)}
            style={{
              color: "red",
              fontWeight: 'bold',
              fontSize: 20,
              marginRight: 5,
              cursor: 'pointer'
            }}
          />}

          {index > 0 &&
            <AiOutlineArrowUp
              style={{
                color: "green",
                fontWeight: 'bold',
                fontSize: 20,
                cursor: 'pointer'
              }}
              onClick={() => refactorOrderDragDrop(questions[index - 1], quest)}
              />
          }
        </div>
        <QuestionBuilder
          key={quest.id}
          question={quest}
          edit={editQuestion}
          openConfigQuestion={setOpenConfig}
          setQuestion={setConfigQuestion}
          questionDelete={questionDelete}
          jump={jump}
          isDragging={isDragging}
        />
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {quest && quest.fields_true ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '20px',
            }}
          >
            <BranchesOutlined
              style={{
                fontSize: '18px',
                marginRight: '30px',
                color: '#52c41a',
              }}
            />
            {quest.fields_true
              ? quest.fields_true.map((questionJump: any) => (
                  <>
                    <IdQuestion>
                      {questionJump.type === 'DATE' ? (
                        <Button
                          style={{
                            backgroundColor: '#4FA9B3',
                          }}
                        >
                          <CheckCircleTwoTone twoToneColor='#4FA9B3' style={{ fontSize: '23px' }} />
                          <p>{questionJump.order}</p>
                        </Button>
                      ) : questionJump.type === 'TEXT' ? (
                        <Button
                          style={{
                            backgroundColor: '#FABA48',
                          }}
                        >
                          <CopyTwoTone twoToneColor='#FABA48' style={{ fontSize: '23px' }} />
                          <p>{questionJump.order}</p>
                        </Button>
                      ) : questionJump.type === 'MONEY' ? (
                        <Button
                          style={{
                            backgroundColor: '#E26D5A',
                          }}
                        >
                          <DiffTwoTone twoToneColor='#E26D5A' style={{ fontSize: '23px' }} />
                          <p>{questionJump.order}</p>
                        </Button>
                        ) : questionJump.type === 'DB_PRODUCTS' ? (
                          <Button
                            style={{
                              backgroundColor: '#4FA9B3',
                            }}
                          >
                            <SnippetsTwoTone twoToneColor='#4FA9B3' style={{ fontSize: '23px' }} />
                            <p>{questionJump.order}</p>
                          </Button>
                      ) : questionJump.type === 'PICTURE_CAPTURE' ? (
                        <Button
                          style={{
                            backgroundColor: '#A086C3',
                          }}
                        >
                          <CameraTwoTone twoToneColor='#A086C3' style={{ fontSize: '23px' }} />
                          <p>{questionJump.order}</p>
                        </Button>
                      ) : questionJump.type === 'QR_CODE' ? (
                        <Button
                          style={{
                            backgroundColor: '#3ca133',
                          }}
                        >
                          <QrcodeOutlined twoToneColor='#3ca133' style={{ fontSize: '23px' }} />
                          <p>{jump.order}</p>
                        </Button>
                      ) : questionJump.type === 'BOOLEAN' ? (
                        <Button
                          style={{
                            backgroundColor: '#BF415C',
                          }}
                        >
                          <QuestionCircleTwoTone twoToneColor='#BF415C' style={{ fontSize: '23px' }} />
                          <p>{questionJump.order}</p>
                        </Button>
                      ) : questionJump.type === 'NUMBER_INTEGER' ? (
                        <Button
                          style={{
                            backgroundColor: '#E05178',
                          }}
                        >
                          <AccountBookTwoTone twoToneColor='#E05178' style={{ fontSize: '23px' }} />
                          <p>{questionJump.order}</p>
                        </Button>
                      ) : questionJump.type === 'NUMBER_DECIMAL' ? (
                        <Button
                          style={{
                            backgroundColor: '#E05178',
                          }}
                        >
                          <DiffTwoTone twoToneColor='#E26D5A' style={{ fontSize: '23px' }} />
                          <p>{questionJump.order}</p>
                        </Button>
                      ) : questionJump.type === 'UNIQUE_CHOICE' ? (
                        <Button
                          style={{
                            backgroundColor: '#BAC26C',
                          }}
                        >
                          <DatabaseTwoTone twoToneColor='#BAC26C' style={{ fontSize: '23px' }} />
                          <p>{questionJump.order}</p>
                        </Button>
                      ) : questionJump.type === 'MULTIPLE_CHOICE' ? (
                        <Button style={{ backgroundColor: 'red' }}>
                          <AppstoreTwoTone twoToneColor='red' style={{ fontSize: '23px' }} />
                          <p>{questionJump.order}</p>
                        </Button>
                      ) : questionJump.type === 'GALLERY' ? (
                        <Button
                          style={{
                            backgroundColor: '#ff19a7',
                          }}
                        >
                          <CloudTwoTone twoToneColor='#ff19a7' style={{ fontSize: '23px' }} />
                          <p>{questionJump.order}</p>
                        </Button>
                      ) : null}
                    </IdQuestion>
                  </>
                ))
              : null}
          </div>
        ) : null}
        {quest && quest.fields_false ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingRight: '20px',
            }}
          >
            <SlidersTwoTone
              twoToneColor='red'
              style={{
                fontSize: '18px',
                marginRight: '30px',
              }}
            />
            {quest.fields_false
              ? quest.fields_false.map((questionJump: any) => (
                  <>
                    <IdQuestion>
                      {questionJump.type === 'DATE' ? (
                        <Button
                          style={{
                            backgroundColor: '#4FA9B3',
                          }}
                        >
                          <CheckCircleTwoTone twoToneColor='#4FA9B3' style={{ fontSize: '23px' }} />
                          <p>{questionJump.order}</p>
                        </Button>
                      ) : questionJump.type === 'TEXT' ? (
                        <Button
                          style={{
                            backgroundColor: '#FABA48',
                          }}
                        >
                          <CopyTwoTone twoToneColor='#FABA48' style={{ fontSize: '23px' }} />
                          <p>{questionJump.order}</p>
                        </Button>
                      ) : questionJump.type === 'MONEY' ? (
                        <Button
                          style={{
                            backgroundColor: '#E26D5A',
                          }}
                        >
                          <DiffTwoTone twoToneColor='#E26D5A' style={{ fontSize: '23px' }} />
                          <p>{questionJump.order}</p>
                        </Button>
                        ) : questionJump.type === 'DB_PRODUCTS' ? (
                          <Button
                            style={{
                              backgroundColor: '#4FA9B3',
                            }}
                          >
                            <SnippetsTwoTone twoToneColor='#4FA9B3' style={{ fontSize: '23px' }} />
                            <p>{questionJump.order}</p>
                          </Button>
                      ) : questionJump.type === 'PICTURE_CAPTURE' ? (
                        <Button
                          style={{
                            backgroundColor: '#A086C3',
                          }}
                        >
                          <CameraTwoTone twoToneColor='#A086C3' style={{ fontSize: '23px' }} />
                          <p>{questionJump.order}</p>
                        </Button>
                      ) : questionJump.type === 'QR_CODE' ? (
                        <Button
                          style={{
                            backgroundColor: '#3ca133',
                          }}
                        >
                          <QrcodeOutlined twoToneColor='#3ca133' style={{ fontSize: '23px' }} />
                          <p>{jump.order}</p>
                        </Button>
                      ) : questionJump.type === 'BOOLEAN' ? (
                        <Button
                          style={{
                            backgroundColor: '#BF415C',
                          }}
                        >
                          <QuestionCircleTwoTone twoToneColor='#BF415C' style={{ fontSize: '23px' }} />
                          <p>{questionJump.order}</p>
                        </Button>
                      ) : questionJump.type === 'NUMBER_INTEGER' ? (
                        <Button
                          style={{
                            backgroundColor: '#E05178',
                          }}
                        >
                          <AccountBookTwoTone twoToneColor='#E05178' style={{ fontSize: '23px' }} />
                          <p>{questionJump.order}</p>
                        </Button>
                      ) : questionJump.type === 'NUMBER_DECIMAL' ? (
                        <Button
                          style={{
                            backgroundColor: '#E05178',
                          }}
                        >
                          <DiffTwoTone twoToneColor='#E26D5A' style={{ fontSize: '23px' }} />
                          <p>{questionJump.order}</p>
                        </Button>
                      ) : questionJump.type === 'UNIQUE_CHOICE' ? (
                        <Button
                          style={{
                            backgroundColor: '#BAC26C',
                          }}
                        >
                          <DatabaseTwoTone twoToneColor='#BAC26C' style={{ fontSize: '23px' }} />
                          <p>{questionJump.order}</p>
                        </Button>
                      ) : questionJump.type === 'MULTIPLE_CHOICE' ? (
                        <Button style={{ backgroundColor: 'red' }}>
                          <AppstoreTwoTone twoToneColor='red' style={{ fontSize: '23px' }} />
                          <p>{questionJump.order}</p>
                        </Button>
                      ) : questionJump.type === 'GALLERY' ? (
                        <Button
                          style={{
                            backgroundColor: '#ff19a7',
                          }}
                        >
                          <CloudTwoTone twoToneColor='#ff19a7' style={{ fontSize: '23px' }} />
                          <p>{questionJump.order}</p>
                        </Button>
                      ) : null}
                    </IdQuestion>
                  </>
                ))
              : null}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default CardForm
