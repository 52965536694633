import FileSaver from 'file-saver'
import api_v2 from '../services/api-v2'
import { Mixpanel } from './mixpanel'

export function compareByAlph(a: any, z: any) {
  if (a > z) {
    return -1
  }
  if (a < z) {
    return 1
  }
  return 0
}

export const removeCaracter = (text: string) => {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export const exportXls = async (body: any, url: string) => {
  await api_v2
    .post(`/export-xlsx/${url}`, body, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(async (response) => {
      const blob = new Blob([response.data], {
        type: 'application/xls',
      })

      FileSaver.saveAs(blob, `${url}.xlsx`)
    })
    .catch((error) => console.log(error))

  Mixpanel.track('Exportar xls', {
    params: { type: url, body: body },
  })
}


export const clearCpf = (cpf) => {
  if(cpf.includes('DELETE')) {
    let split = cpf.split('-DELETED-')
    return split[split.length - 1]
  }

  return cpf
}