import { useEffect, useState } from 'react'
import { ContainerModal, Tag, ContainerType, Title, Description } from './style'
import { MdAccountCircle, MdDescription, MdToday } from 'react-icons/md'
import { Checkbox, Select, Popconfirm, DatePicker } from 'antd'
import { GroupForm } from '../../GlobalStyles'
import { ImportXls } from '../ModalImportGeneral/ImportXls'
import { ContextUpload } from '../../context/contextUpload'
import { hasIndex } from '../Menu/menuHorizonte'
import type { RangePickerProps } from 'antd/es/date-picker';
import moment from 'moment'
import { width } from '@amcharts/amcharts4/.internal/core/utils/Utils'

interface IProps {
  visible: boolean
  close: (boolean: any) => void
  type: string
  noSelect?: string | undefined
}

export const ModalImport = ({ noSelect, visible, close, type }: IProps) => {
  const { permissions } = ContextUpload()
  const [reset, setReset] = useState<boolean>(true)
  const [date, setDate] = useState<any>()
  const [schedule, setSchedule] = useState<boolean>(false)
  const [typeScript, setTypeScript] = useState<string>('Exclusivo')
  const [importType, setImportType] = useState<number | null>(null)

  let getSharement = sessionStorage.getItem('@rocketpdv:workspace')
  let newGetWorkspace: any = {}
  if (getSharement) newGetWorkspace = JSON.parse(getSharement)

  useEffect(() => {
    setImportType(null)
    setReset(true)
    setSchedule(false)
    setDate(undefined)
    setTypeScript('Exclusivo')
  }, [visible])

  const getType: any = {
    1: 'SCRIPT',
    2: 'VISIT',
    3: 'PROMOTER',
    4: 'REMOVAL',
  }

  useEffect(() => {
    if (importType === 3) {
      setTypeScript('PROMOTER')
    } else {
      setTypeScript('Exclusivo')
    }
  }, [importType])

  const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current && current < moment().endOf('day');
  };

  return (
    <ContainerModal
      visible={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
    >
      {importType === null ? (
        <>
          <h2 style={{ textAlign: 'center' }}>Importações em massa</h2>
          {hasIndex(['team.import_script_promoter'], permissions) > -1 && (
            <Tag onClick={() => setImportType(1)}>
              <MdDescription size={25} />
              <ContainerType>
                <Title>Roteiro</Title>
                <Description>
                  Importe sua planilha de roteirização para que o sistema crie visitas recorrentes automaticamente.
                </Description>
              </ContainerType>
            </Tag>
          )}
          {hasIndex(['team.import_visit_promoter'], permissions) > -1 && (
            <>
              {newGetWorkspace && !newGetWorkspace.shared_environment && (
                <Tag onClick={() => setImportType(2)}>
                  <MdToday size={25} />
                  <ContainerType>
                    <Title>Visita Agendada</Title>
                    <Description>
                      Importe sua planilha de visitas agendadas para que o sistema crie a visita na data especificada
                      sem regras de recorrência.
                    </Description>
                  </ContainerType>
                </Tag>
              )}
            </>
          )}
          {!noSelect && hasIndex(['team.import_promoter'], permissions) > -1 && (
            <Tag onClick={() => setImportType(3)}>
              <MdAccountCircle size={25} />
              <ContainerType>
                <Title>Promotor</Title>
                <Description>Importe sua planilha de promotores e cadastre os usuários em massa.</Description>
              </ContainerType>
            </Tag>
          )}
          <Tag onClick={() => setImportType(4)}>
            <MdDescription size={25} />
            <ContainerType>
              <Title>Afastamento</Title>
              <Description>
                Importe sua planilha de afastamento para que afaste os promotores automáticamente.
              </Description>
            </ContainerType>
          </Tag>
        </>
      ) : (
        <ImportXls
          close={close}
          title={
            importType === 1
              ? 'Roteiro'
              : importType === 2
                ? 'Visita Agendada'
                : importType === 4
                  ? 'Afastamento'
                  : 'Promotor'
          }
          path={importType != 3 ? getType[importType] : typeScript}
          config={
            importType === 1
              ? {
                is_reset_script: reset,
                typeScript: newGetWorkspace && !newGetWorkspace.shared_environment ? typeScript : 'Compartilhado',
                schedule,
                date
              }
              : importType === 2
                ? { is_reset_script: reset }
                : {}
          }
          children={
            <>
              {importType === 1 && (
                <>
                  <GroupForm style={{ marginTop: 20 }}>
                    <Checkbox defaultChecked={true} value={reset} onChange={(evt: any) => setReset(evt.target.checked)}>
                      <div>
                        <div style={{ marginBottom: 10 }}>
                          <strong>Zerar roteiro?</strong>
                          <p style={{ fontSize: 13, color: '#8d8989' }}>
                            (Isso irá apagar o roteiro dos promotores a ser importado)
                          </p>
                        </div>
                        <div style={{ fontSize: 13, color: '#8d8989' }}>
                          Não será permitido dois tipos de roteiro para o mesmo usuário(Semanal, Quinzenal ou Mensal).
                        </div>
                      </div>
                    </Checkbox>
                  </GroupForm>
                  <GroupForm style={{ marginTop: 20 }}>
                    <Checkbox value={schedule} onChange={(evt: any) => setSchedule(evt.target.checked)}>
                      <div>
                        <div style={{ marginBottom: 10 }}>
                          <strong>Agendar roteiro?</strong>
                          <p style={{ fontSize: 13, color: '#8d8989' }}>
                            Seu roteiro só será processado apartir da data selecionada
                          </p>
                        </div>
                      </div>
                    </Checkbox>
                  </GroupForm>
                  {schedule &&

                    <GroupForm style={{ marginTop: 20 }}>
                      <div>
                        <p style={{fontWeight: 'bold'}}>Para qual data?</p>
                        <DatePicker format={'DD/MM/YYYY'}  disabledDate={disabledDate} style={{width: '100%'}} onChange={(evt) => setDate(evt)} value={date}/>

                      </div>
                    </GroupForm>
                  }

                </>
              )}
              {/* {importType === 3 && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                <GroupForm style={{marginTop: 20, width: '50%'}}>
                  <label>*Qual modelo:</label>
                  <Select
                    onChange={(value: any) => setTypeScript(value)}
                    defaultValue='PROMOTER'
                    value={typeScript}
                  >
                    <Select.Option value='PROMOTER'>Simples</Select.Option>
                    <Select.Option value='COLLABORATOR'>Avançado</Select.Option>
                  </Select>
                </GroupForm>
                </div>
              )} */}
              {importType === 2 && (
                <GroupForm style={{ marginTop: 20 }}>
                  <Checkbox defaultChecked={true} value={reset} onChange={(evt: any) => setReset(evt.target.checked)}>
                    <div>
                      Zerar visitas ?
                      <p style={{ fontSize: 13, color: '#8d8989' }}>
                        (Isso irá apagar o visitas dos promotores a ser importado)
                      </p>
                    </div>
                  </Checkbox>
                </GroupForm>
              )}
            </>
          }
        />
      )}
    </ContainerModal>
  )
}
