import { Check, Form } from '@rocketseat/unform'
import { Breadcrumb, Button, Col, message, Row } from 'antd'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import Input from '../../../components/form/Input'
import { Container } from '../../../GlobalStyles'
import history from '../../../routes/history'
import api from '../../../services/api'
import { Mixpanel } from '../../../utils/mixpanel'
import { Loading } from '../../../components/Loading'
const schema = Yup.object().shape({
  name: Yup.string().required('* O campo nome é obrigatório'),
})

const BrandCreatePage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  async function handleSubmit(body: any) {
    setLoading(true)
    try {
      await api.post('/brand', { ...body })
      Mixpanel.track('Criou marca', body)

      message.success('Marca cadastrada com sucesso')
      history.push('/backoffice/catalog/brands')
    } catch (err) {
      message.error('Já existe um registro com essas informações!')
    }
    setLoading(false)
  }

  return (
    <Container>
      <h1>Cadastro de marca</h1>
      {/*<p>Insira os dados para realizar o cadastro</p>*/}
      <Breadcrumb className='breadcumb'>
        <Link to='/backoffice'>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </Link>
        <Link to='/backoffice/catalog'>
          <Breadcrumb.Item>Estoque</Breadcrumb.Item>
        </Link>
        <Link to='/backoffice/catalog/brands'>
          <Breadcrumb.Item> Marcas </Breadcrumb.Item>
        </Link>
        <Breadcrumb.Item>Cadastro</Breadcrumb.Item>
      </Breadcrumb>
      <Form className='form' schema={schema} initialData={{ is_active: true }} onSubmit={handleSubmit}>
        <Row gutter={[16, 30]}>
          <Col span={14}>
            <Input type='text' label='Nome *' name='name' placeholder='Nome completo' />
          </Col>
          <Col className='checkbox' span={2}>
            <Check name='is_active' label='Ativo?*' />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button type='primary' htmlType='submit'>
              Cadastrar
            </Button>
          </Col>
        </Row>
      </Form>
      {loading ? <Loading /> : null}
    </Container>
  )
}

export default BrandCreatePage
