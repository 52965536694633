import React, { useState } from 'react'
import DefaultPage from '../../../components/DefaultPage'
import { isActiveSelectVisita } from '../../../utils/option-user'
import { DataStatus } from '../../../components/TabsVisits/style'
import { compareByAlph, exportXls } from '../../../utils/funcoes'
import { getStatus } from '../../Survey/VisitsPage'
import moment from 'moment'
import { Col, Modal, Row } from 'antd'
import { BsEye } from 'react-icons/bs'
import qs from 'querystring'
import useReactRouter from 'use-react-router'

interface IPropsInfo {
  visible: boolean
  info: any
}

export const VisitsJustifyHistory = () => {
  const { location } = useReactRouter()

  const [loading, setLoading] = useState(false)
  const [info, setInfo] = useState<IPropsInfo>({
    visible: false,
    info: {},
  })

  let tranlateStatus = {
    PENDENT: 'Pendente',
    IN_PROGRESS: 'Em progresso',
    JUSTIFIED_ABSENCE: 'Justificado',
    COMPLETE: 'Concluído',
  }

  let convertUrlToExportXls = (defaultUrl?: any) => {
    try {
      let getUrl: any = qs.parse(location.search.replaceAll('?', ''))

      let filterQuery: any = {}
      for (var el in getUrl) {
        filterQuery[el] = JSON.parse(getUrl[el]).value
      }

      console.log(filterQuery)

      return filterQuery
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>
      <DefaultPage
        titlePage='Histórico status de visitas'
        breadcrumb={[{ name: 'Home', url: '/' }, { name: 'Visitas' }]}
        urlPage='/backoffice/reports/editVisits'
        routeApi={{
          method: 'get',
          route: '/visit/history',
          versionApi: 'v2',
          reload: false,
        }}
        listButtons={{
          buttonFilter: {
            visible: true,
            filters: [
              {
                label: 'Selecione o nome do promotor:',
                name: 'promoter_id',
                type: 'search',
                urlSearch: 'promoters',
                getEnv: 'value',
              },
              {
                label: 'Selecione o nome do pdv:',
                name: 'pdv_id',
                type: 'search',
                urlSearch: 'pdvs',
                getEnv: 'value',
              },
              {
                label: 'Selecione o status',
                name: 'status',
                type: 'select',
                options: isActiveSelectVisita,
                getEnv: 'value',
              },
            ],
          },
          buttonExport: {
            visible: true,
            type: 'function',
            function: async () => {
              setLoading(true)
              await exportXls(convertUrlToExportXls(), `history-status-visits`)
              setLoading(false)
            },
          },
        }}
        load={{ loading, setLoading }}
        columns={[
          {
            title: 'Status',
            dataIndex: 'status_new',
            key: 'status_new',
            width: 80,
            render: (status: any) => {
              return <DataStatus>{getStatus(status)}</DataStatus>
            },
          },
          {
            title: 'Quem editou',
            dataIndex: 'user_name',
            sorter: (a, b) => compareByAlph(a.user_id, b.user_id),
          },
          {
            title: 'Nome do promotor',
            dataIndex: 'promoter_name',
            sorter: (a, b) => compareByAlph(a.promoter_name, b.promoter_name),
          },
          {
            title: 'Nome PDV',
            dataIndex: 'pdv_name',
            sorter: (a, b) => compareByAlph(a.pdv_name, b.pdv_name),
          },
          {
            title: 'Data da visita',
            dataIndex: 'dt_visit',
            sorter: (a, b) => compareByAlph(a.dt_visit, b.dt_visit),
            render: (value: any) => moment(value).format('DD/MM/YYYY'),
          },
          {
            title: 'Data de edição',
            dataIndex: 'date_edition',
            sorter: (a, b) => compareByAlph(a.date_edition, b.date_edition),
            render: (value: any) => moment(value).format('DD/MM/YYYY HH:mm:ss'),
          },
          {
            title: '#',
            render: (value: any) => (
              <>
                <BsEye
                  style={{
                    cursor: 'pointer',
                    fontSize: 18,
                  }}
                  onClick={() =>
                    setInfo({
                      visible: true,
                      info: value,
                    })
                  }
                />
              </>
            ),
          },
        ]}
      />

      <Modal
        visible={info.visible}
        onCancel={() =>
          setInfo({
            visible: false,
            info: {},
          })
        }
        destroyOnClose={true}
        footer={null}
      >
        <p style={{ textAlign: 'center', fontSize: '20px', marginBottom: 20 }}>Edição realizada</p>
        <Row gutter={[20, 20]}>
          <Col span={12}>
            <p style={{ fontWeight: 'bold' }}>Data da visita:</p>
            <p>{moment(info.info.dt_visit).format('DD/MM/YYYY')}</p>
          </Col>
          <Col span={12}>
            <p style={{ fontWeight: 'bold' }}>Nome promotor:</p>
            <p>{info.info.promoter_name}</p>
          </Col>
          <Col span={12}>
            <p style={{ fontWeight: 'bold' }}>Nome do PDV:</p>
            <p>{info.info.pdv_name}</p>
          </Col>

          <Col span={12}>
            <p style={{ fontWeight: 'bold' }}>Quem editou:</p>
            <p>{info.info.user_name}</p>
          </Col>
          <Col span={12}>
            <p style={{ fontWeight: 'bold' }}>Data de edição:</p>
            <p>{moment(info.info.date_edition).format('DD/MM/YYYY HH:mm:ss')}</p>
          </Col>

          <Col span={12}>
            <p style={{ fontWeight: 'bold' }}>Novo status:</p>
            <p>{tranlateStatus[info.info.status_new]}</p>
          </Col>
          <Col span={12}>
            <p style={{ fontWeight: 'bold' }}>Status antigo:</p>
            <p>{tranlateStatus[info.info.status_old]}</p>
          </Col>
          {info.info.description_faults && (
            <Col span={12}>
              <p style={{ fontWeight: 'bold' }}>Motivo da falta:</p>
              <p>{info.info.description_faults}</p>
            </Col>
          )}
          {info.info.faults_obs && (
            <Col span={12}>
              <p style={{ fontWeight: 'bold' }}>Observação de falta:</p>
              <p>{info.info.faults_obs}</p>
            </Col>
          )}
          {info.info.date_check_in && (
            <Col span={12}>
              <p style={{ fontWeight: 'bold' }}>Data de check-in:</p>
              <p>{moment(info.info.date_check_in).format('DD/MM/YYYY HH:mm:ss')}</p>
            </Col>
          )}
          {info.info.date_check_out && (
            <Col span={12}>
              <p style={{ fontWeight: 'bold' }}>Data de check-out:</p>
              <p>{moment(info.info.date_check_out).format('DD/MM/YYYY HH:mm:ss')}</p>
            </Col>
          )}
          {info.info.faults_image && (
            <Col span={12}>
              <p style={{ fontWeight: 'bold' }}>Imagens de falta:</p>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 10,
                }}
              >
                {info.info.faults_image &&
                  JSON.parse(info.info.faults_image).map((item: any) => (
                    <img
                      src={item}
                      onClick={() => window.open(item, '_blank')}
                      style={{ width: 50, height: 50, cursor: 'pointer', borderRadius: 3 }}
                      alt='falts'
                    />
                  ))}
              </div>
            </Col>
          )}
        </Row>
      </Modal>
    </>
  )
}
