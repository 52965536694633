import { Drawer } from 'antd'
import React, { useEffect, useState } from 'react'
import { DivGroupTextIcon } from '../../GlobalStyles'

interface IProps {
  visible: boolean
  close: (boolean: any) => void
  children: any
  footer: any
  title: string
}

export const DrawerFilter = ({ visible, close, children, footer, title }: IProps) => {
  const onClose = () => {
    close(false)
  }

  return (
    <>
      <Drawer
        title={
          <DivGroupTextIcon>
            <p>Filtrar {title}</p>
          </DivGroupTextIcon>
        }
        placement='right'
        closable={true}
        onClose={onClose}
        visible={visible}
        key='right'
        footer={footer}
      >
        {children}
      </Drawer>
    </>
  )
}
