import React, { useState, useEffect } from 'react'
import { ContainerModal } from './style'
import { ColumnsType } from 'antd/lib/table'
import { Button, Dropdown, Menu, message, Popconfirm, Table } from 'antd'
import { ModalAddSubWorkspaceVisit } from '../ModalAddSubWorkspaceVisit'
import api_v2 from '../../services/api-v2'
import { DeleteOutlined, MoreOutlined } from '@ant-design/icons'
import { ContainerValues } from '../CardChart/style'
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai'
import { addIsVisit } from '../../utils/addScript'

interface IProps {
  visible: boolean
  close: (boolean: any) => void
  visit: any
  type?: string
}

interface IWorkspace {
  avatar_url: string
  id: number
  name: string
}

export const ModalViewSubWorkspace = ({ visible, close, visit, type = 'visit' }: IProps) => {
  const [subWorkspace, setSubWorkspace] = useState<IWorkspace[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState<boolean>(false)

  const getSubWorkspace = async () => {
    try {
      setLoading(true)
      const { data } = await api_v2.get(`/workspace-sub/${type}/${visit.id}`)
      setSubWorkspace([...data])
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getSubWorkspace()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const deleteSubWorkspace = async (sub_workspace: IWorkspace) => {
    try {
      setLoading(true)
      if (type == 'visit') {
        await api_v2.post(`/workspace-sub/remove-by/visit`, {
          visit_id: visit.id,
          sub_workspace_id: sub_workspace.id,
        })
      } else {
        await api_v2.post(`/workspace-sub/remove-by/script`, {
          script_id: visit.id,
          sub_workspace_id: sub_workspace.id,
        })
      }
      message.success('Sucesso ao remover empresa.')
      await getSubWorkspace()
    } catch (error) {
      message.error('Erro ao remover empresa.')
    } finally {
      setLoading(false)
    }
  }

  const changePosition = async (index: number, position: 'up' | 'down') => {
    let newSub = [...subWorkspace]

    if (position === 'up' && index > 0) {
      [newSub[index - 1], newSub[index]] = [newSub[index], newSub[index - 1]];
    } else if (position === 'down' && index < newSub.length - 1) {
      [newSub[index + 1], newSub[index]] = [newSub[index], newSub[index + 1]];
    }

    try {
      setLoading(true)
      if (type == 'visit') {
        await api_v2.post(`/workspace-sub/change_order_visit`, {
          visit_id: visit.id,
          new_order: newSub,
        })
      } else {
        await api_v2.post(`/workspace-sub/change_order_script`, {
          script_id: visit.id,
          new_order: newSub,
        })
      }
      message.success('Sucesso ao alterar ordem de  empresa.')
      await getSubWorkspace()
    } catch (error) {
      message.error('Erro ao alterar ordem de empresa empresa.')
    } finally {
      setLoading(false)
    }
  }

  const columns: ColumnsType<IWorkspace> = [
    {
      width: 50,
      render: (a, b, index) => (
        <div style={{ display: 'flex', width: '45%', gap: 8, justifyContent: 'space-around' }}>
          {type != 'visit' &&
            <>
              <div style={{ cursor: 'pointer' }} onClick={() => {
                if (index == 0) return
                changePosition(index, 'up')
              }}>
                <ContainerValues>
                  <AiOutlineArrowUp color={index == 0 ? 'lightgray' : '#1ac165'} fontSize={18} />
                </ContainerValues>
              </div>
              <div style={{ cursor: 'pointer' }} onClick={() => {
                if (index == subWorkspace.length - 1) return
                changePosition(index, 'down')
              }}>
                <ContainerValues>
                  <AiOutlineArrowDown color={index == subWorkspace.length - 1 ? 'lightgray' : '#af2f4e'} fontSize={18} />
                </ContainerValues>
              </div>
            </>
          }
        </div>
      )
    },
    {
      title: 'Avatar',
      dataIndex: 'avatar_url',
      width: 70,
      render: (a) => <>{a.length > 10 ? <img src={a} style={{ width: 50, height: 50 }} alt='avatar' /> : '-'}</>,
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: '#',
      width: 100,
      align: 'center',
      render: (a) => (
        <>
          <Dropdown
            overlay={
              <Menu>
                  <Popconfirm
                    title='Deseja remover empresa da visita'
                    onConfirm={() => deleteSubWorkspace(a)}
                    okText='Sim'
                    cancelText='Não'
                    placement='topRight'
                  >
                  <Menu.Item key='1' icon={<DeleteOutlined />}>
                      Remover empresa
                  </Menu.Item>
                </Popconfirm>
              </Menu>
            }
          >
            <Button size='small' shape='circle' icon={<MoreOutlined />} />
          </Dropdown>
        </>
      ),
    },
  ]
  return (
    <ContainerModal
      visible={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
      style={{ top: 10 }}
      destroyOnClose={true}
    >
      <h3>Visualização de sub workspace</h3>
      <Table
        scroll={{ y: 240 }}
        columns={columns}
        tableLayout='auto'
        rowKey={(SubWorkspaceTable) => SubWorkspaceTable.id}
        dataSource={subWorkspace}
        size='small'
        loading={loading}
        pagination={false}
      />
      {visit.status !== 'COMPLETE' && (
        <Button style={{ width: '100%', height: '40px', marginTop: 20 }} onClick={() => {
          if(!addIsVisit()) return message.error('Sem autorização')
          setOpenModal(!openModal)
        }}>
          Adicionar Empresa
        </Button>
      )}
      <ModalAddSubWorkspaceVisit
        close={setOpenModal}
        visible={openModal}
        visit={visit}
        reloadApi={() => getSubWorkspace()}
        type={type}
      />
    </ContainerModal>
  )
}
