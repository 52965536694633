import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import qs from 'querystring'
import { Page } from './styles'
import { getWindowDimensions } from '../../components/GraphDash'

export const DashView = () => {
  const { id }: any = useParams()

  const [frame] = useState(
    `<iframe width="100%" height="${getWindowDimensions().height - 7}px" src="${
      qs.parse(id).frame
    }" frameborder="0" style="border:0" allowfullscreen="true"></iframe>`
  )

  return (
    <Page>
      <div className='noScrool'>
        <div dangerouslySetInnerHTML={{ __html: frame }} />
        <div
          style={{
            width: '100vw',
            height: 35,
            position: 'fixed',
            zIndex: 9999999999,
            bottom: 0,
            left: 0,
            backgroundColor: 'transparent',
          }}
        >
          .
        </div>
      </div>
    </Page>
  )
}
