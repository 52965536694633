import styled from "styled-components"

export const CardITem = styled.button`
    padding: 10px 20px;
    flex-direction: row;
    align-items: center;
    margin: 5px 0;
    min-height: 90px;
`
export const Title = styled.p`
    font-size: 15px;
    margin-left: 20px;
    text-align: center;
    color: #2e2f34;
`

export const ContainerForm = styled.div`
    background-color: #f7f7f7;
    padding: 10px;
    margin-bottom: 30px;
    border-radius: 10px;
`

export const TitleAnswer = styled.p`
    color: #2e2f34;
    font-size: 16px;
`

export const ContainerAnswer = styled.div`
    margin-bottom: 10px;
`