import { Check, Form, Input } from '@rocketseat/unform'
import { Button, Checkbox, Col, Collapse, Divider, message, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import history from '../../../routes/history'
// import api from '../../../services/api'
import { Loading } from '../../../components/Loading'
import { useParams } from 'react-router-dom'
import useReactRouter from 'use-react-router'
import qs from 'querystring'
import api_v2 from '../../../services/api-v2'
import { Mixpanel } from '../../../utils/mixpanel'
import { Container, ContainerCheckAll, ContainerCheckBox } from './style'

const ProfileCreatePage: React.FC = () => {
  const { location } = useReactRouter()
  const [permissions, setPermissions] = useState<any[]>([])
  const [selectedPermission, setSelectedPermission] = useState<any>([])
  const [params, setParams] = useState<any>({})
  const [loading, setLoading] = useState<any>(false)
  const { id }: any = useParams()

  async function requestPermissions() {
    setLoading(true)
    let param: any = qs.parse(location.search.replaceAll('?', ''))
    let obj = JSON.parse(param.params)
    const { data } = await api_v2.get(`/profile/permission/${obj.context.toLowerCase()}`)
    setPermissions(data)
    setLoading(false)
  }

  async function getParams() {
    let param: any = qs.parse(location.search.replaceAll('?', ''))
    setParams({ ...JSON.parse(param.params), is_active: true })
  }

  // async function getPermissionEdit() {
  //   setLoading(true)
  //   const { data } = await api.get(`/profile/${id}`)
  //   setProfile({ ...data, loading: false })
  //   setLoading(false)
  // }

  useEffect(() => {
    // getPermissionEdit()
    getParams()
    requestPermissions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  async function handleSubmit(body: any) {
    setLoading(true)
    try {
      if (!body.description) {
        message.error('O campo descrição é obrigatório.')
      }

      const newBody = { ...params, ...body }

      let newSelected: any = []

      selectedPermission.forEach((el: any) => {
        if (typeof el === 'string') newSelected.push(el)
      })

      newBody.profile_permissions = newSelected

      await api_v2.post('/profile', newBody)

      message.success('Perfil criado com sucesso!')

      Mixpanel.track('Criação de perfil', {
        params: newBody,
      })

      history.push('/backoffice/team/profiles')
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  const genExtra = (permissions: any) => (
    <ContainerCheckAll>
      <Checkbox value={permissions}>Selecionar todos</Checkbox>
    </ContainerCheckAll>
  )
  return (
    <Container>
      {loading ? <Loading /> : null}
      <h1>Criar perfil</h1>
      <Form className='form' initialData={params} onSubmit={handleSubmit}>
        <Row gutter={12}>
          <Col span={6}>
            <Input name='name' label='Nome *' />
          </Col>
          <Col span={16}>
            <Input name='description' label='Descrição*' />
          </Col>
          <Col className='checkbox' span={2}>
            <Check name='is_active' label='Ativo' />
          </Col>
        </Row>
        <Divider />
        <Checkbox.Group
          style={{ width: '100%' }}
          onChange={(e) => {
            let newInser: any = []

            e.forEach((el: any) => {
              if (typeof el === 'string') {
                newInser.push(el)
              } else {
                el.forEach((el2: any) => {
                  if (newInser.indexOf(el2.value) === -1) newInser.push(el2.value)
                })
                newInser.push(el)
              }
            })
            let filter: any = []
            let removes: any = []

            newInser.forEach((el: any) => {
              selectedPermission.forEach((el2: any) => {
                if (typeof el2 !== 'string' && el !== 'string') {
                  if (JSON.stringify(newInser).indexOf(JSON.stringify(el2)) === -1) {
                    el2.forEach((el3: any) => {
                      removes.push(el3.value)
                    })
                  }
                }
              })
            })

            newInser.forEach((el: any) => {
              let exist: any = true

              if (removes.indexOf(el) > -1) exist = false
              if (exist) {
                filter.push(el)
              }
            })

            setSelectedPermission(filter)
          }}
          value={selectedPermission}
        >
          <Row>
            <Col span={24}>
              <Collapse bordered={false}>
                {permissions &&
                  permissions.map((permission) => (
                    <Collapse.Panel
                      header={`${permission.module_name}`}
                      key={permission.module_name}
                      extra={genExtra(permission.module_permissions)}
                    >
                      {/* <Row style={{ marginBottom: 5 }}>
                      <Col span={24} className='rowScop'>
                        <Checkbox checked={permission.module_access}>Acesso a pagina</Checkbox>
                      </Col>
                    </Row> */}
                      <Row style={{ marginBottom: 5 }} gutter={[30, 30]}>
                        {permission.module_permissions.map((el: any) => (
                          <Col span={4} className='rowScop'>
                            <ContainerCheckBox>
                              <Checkbox value={el.value}>{el.label}</Checkbox>
                            </ContainerCheckBox>
                          </Col>
                        ))}
                      </Row>

                      {/* <Scope path='permissions'>
                          <Choice name={permission.module_name} options={permission.module_permissions} multiple  />
                        </Scope> */}
                    </Collapse.Panel>
                  ))}
              </Collapse>
            </Col>
          </Row>
        </Checkbox.Group>

        <Row style={{ marginTop: 15 }}>
          <Col>
            <Button type='primary' htmlType='submit'>
              Salvar
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  )
}

export default ProfileCreatePage
