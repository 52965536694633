import React, { useEffect, useState } from 'react'

import { MdChevronRight } from 'react-icons/md'
import { Menu, Divider } from 'antd'

import { ContainerMenuH } from './styles'
import { workspace } from '../LayoutDefault'
import { Link } from 'react-router-dom'
import useReactRouter from 'use-react-router'
import { ContextUpload } from '../../context/contextUpload'
import { getDataUser, getToken, getWorkspaceInfo } from '../../utils/login'
import { IDS } from '../../utils/removeUser'
import moment from "moment/moment";
import api_v2 from '../../services/api-v2'
// import api_v2 from '../../services/api-v2'
interface IProps {
  Iworkspace?: workspace | undefined
}

export const hasIndex = (needHas: string[], permissions: string[]) => {
  let has: any = -1
  needHas.forEach((el) => {
    if (permissions && permissions.indexOf(el) > -1) has = permissions.indexOf(el)
  })
  return has
}

const MenuHorizonte: React.FC<IProps> = ({ Iworkspace }) => {
  const { location } = useReactRouter()
  const { permissions } = ContextUpload()
  const [state, setState] = useState<any>('')

  // const getPermissions = async () => {
  //   const { data } = await api_v2.get('/profile/permission/promoter')
  // }

  useEffect(() => {
    let rota = location.pathname
    let divider = rota.split('/')

    if (rota !== '/' && rota !== '/backoffice/' && divider.length > 0) {
      setState(divider[2])
    } else {
      setState('/')
    }
  }, [location.pathname])
  const user = getDataUser()
  const workspa = getWorkspaceInfo()

  return (
    <div className='maxW' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
      <ContainerMenuH>
        <Menu selectedKeys={[state]} mode='horizontal'>
          {hasIndex(
            ['home.filter', 'home.add_metric', 'home.attendance', 'home.assiduity', 'home.tasks', 'home.team_on_off'],
            permissions
          ) > -1 && (
              <Menu.Item key='/'>
                <Link to='/backoffice/'>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {/* <MdShowChart size={16} style={{ marginRight: 10 }} /> */}
                    Indicadores
                  </div>
                </Link>
              </Menu.Item>
            )}

          {hasIndex(
            [
              'team.promoter',
              'team.add_promoter',
              'team.import_promoter',
              'team.import_visit_promoter',
              'team.import_script_promoter',
              'team.export_promoter',
              'team.export_script_promoter',
              'team.change_visit_status',
              'team.change_visit_date',
              'team.remove_visit_date',
              'team.add_task',
              'team.remove_task',
              'team.reset_task',
              'group.change_profile',
              'group.profile',
            ],
            permissions
          ) > -1 &&
            IDS.indexOf(user.id) === -1 && (
              <Menu.SubMenu
                key='team'
                title={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {/* <MdPeople size={16} style={{ marginRight: 10 }} /> */}
                    Time
                  </div>
                }
              >
                <Divider style={{ margin: '0' }} />
                {hasIndex(
                  [
                    'team.promoter',
                    'team.add_promoter',
                    'team.import_promoter',
                    'team.import_visit_promoter',
                    'team.import_script_promoter',
                    'team.export_promoter',
                    'team.export_script_promoter',
                    'team.change_visit_status',
                    'team.change_visit_date',
                    'team.remove_visit_date',
                    'team.add_task',
                    'team.remove_task',
                    'team.reset_task',
                  ],
                  permissions
                ) > -1 && (
                    <Menu.Item key='setting:3'>
                      <Link to='/backoffice/team/users/promoter'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <MdChevronRight color='#A56CDF' size={16} style={{ marginRight: 10 }} />
                          Promotores
                        </div>
                      </Link>
                    </Menu.Item>
                  )}
                {/* <Divider style={{ margin: '0' }} />
            <Menu.Item key='setting:19'>
              <Link to='/backoffice/team/users/supervisor'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <MdChevronRight color='#A56CDF' size={16} style={{ marginRight: 10 }} />
                  Supervisor
                </div>
              </Link>
            </Menu.Item> */}
                {hasIndex(
                  [
                    'team.promoter',
                    'team.add_promoter',
                    'team.import_promoter',
                    'team.import_visit_promoter',
                    'team.import_script_promoter',
                    'team.export_promoter',
                    'team.export_script_promoter',
                    'team.change_visit_status',
                    'team.change_visit_date',
                    'team.remove_visit_date',
                    'team.add_task',
                    'team.remove_task',
                    'team.reset_task',
                  ],
                  permissions
                ) > -1 && (
                    <>
                      <Divider style={{ margin: '0' }} />

                      <Menu.Item key='setting:1'>
                        <Link to='/backoffice/team/users'>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <MdChevronRight color='#A56CDF' size={16} style={{ marginRight: 10 }} />
                            Usuários
                          </div>
                        </Link>
                      </Menu.Item>
                    </>
                  )}
                {hasIndex(['group.change_profile', 'group.profile'], permissions) > -1 && (
                  <>
                    <Divider style={{ margin: '0' }} />
                    <Menu.Item key='setting:2'>
                      <Link to='/backoffice/team/profiles'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <MdChevronRight color='#A56CDF' size={16} style={{ marginRight: 10 }} />
                          Grupos e Permissões
                        </div>
                      </Link>
                    </Menu.Item>
                  </>
                )}
                <>
                  <Divider style={{ margin: '0' }} />
                  <Menu.Item key='setting:2'>
                    <Link to='/backoffice/team/jorney'>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <MdChevronRight color='#A56CDF' size={16} style={{ marginRight: 10 }} />
                        Intrajornada
                      </div>
                    </Link>
                  </Menu.Item>
                </>
              </Menu.SubMenu>
            )}
          {hasIndex(
            [
              'catalog.brand',
              'catalog.add_brand',
              'catalog.change_brand',
              'catalog.remove_brand',
              'catalog.import_brand',
              'catalog.export_brand',
              'catalog.category',
              'catalog.add_category',
              'catalog.change_category',
              'catalog.remove_category',
              'catalog.product',
              'catalog.add_product',
              'catalog.change_product',
              'catalog.remove_product',
              'catalog.import_product',
              'catalog.export_product',
              'catalog.product_mix',
              'catalog.add_product_mix',
              'catalog.change_product_mix',
              'catalog.remove_product_mix',
              'catalog.import_product_mix',
              'catalog.export_product_mix',
              'store.regional',
              'store.add_regional',
              'store.change_regional',
              'store.remove_regional',
              'store.import_regional',
              'store.export_regional',
              'store.network',
              'store.add_network',
              'store.change_network',
              'store.remove_network',
              'store.import_network',
              'store.export_network',
              'store.flag',
              'store.add_flag',
              'store.change_flag',
              'store.remove_flag',
              'store.import_flag',
              'store.export_flag',
              'store.pdv',
              'store.add_pdv',
              'store.change_pdv',
              'store.remove_pdv',
              'store.import_pdv',
              'store.export_pdv',
              'justify.list_justify',
              'justify.add_justify',
              'justify.change_justify',
              'justify.remove_justify',
              'training.list_training',
              'training.add_training',
              'training.change_training',
              'training.remove_training',
            ],
            permissions
          ) > -1 &&
            IDS.indexOf(user.id) === -1 && (
              <Menu.SubMenu
                key='register'
                title={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {/* <MdLocalOffer size={16} style={{ marginRight: 10 }} /> */}
                    Cadastros
                  </div>
                }
              >
                {hasIndex(
                  [
                    'catalog.brand',
                    'catalog.add_brand',
                    'catalog.change_brand',
                    'catalog.remove_brand',
                    'catalog.import_brand',
                    'catalog.export_brand',
                    'catalog.category',
                    'catalog.add_category',
                    'catalog.change_category',
                    'catalog.remove_category',
                    'catalog.product',
                    'catalog.add_product',
                    'catalog.change_product',
                    'catalog.remove_product',
                    'catalog.import_product',
                    'catalog.export_product',
                    'catalog.product_mix',
                    'catalog.add_product_mix',
                    'catalog.change_product_mix',
                    'catalog.remove_product_mix',
                    'catalog.import_product_mix',
                    'catalog.export_product_mix',
                  ],
                  permissions
                ) > -1 && (
                    <Menu.SubMenu
                      key='catalog'
                      title={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {/* <MdLocalOffer size={16} style={{ marginRight: 10 }} /> */}
                          Produto
                        </div>
                      }
                    >
                      {hasIndex(
                        [
                          'catalog.brand',
                          'catalog.add_brand',
                          'catalog.change_brand',
                          'catalog.remove_brand',
                          'catalog.import_brand',
                          'catalog.export_brand',
                        ],
                        permissions
                      ) > -1 && (
                          <Menu.Item key='catalog:1'>
                            <Link to='/backoffice/catalog/brands'>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <MdChevronRight color='#A56CDF' size={16} style={{ marginRight: 10 }} />
                                Marcas
                              </div>
                            </Link>
                          </Menu.Item>
                        )}
                      {hasIndex(
                        [
                          'catalog.category',
                          'catalog.add_category',
                          'catalog.change_category',
                          'catalog.remove_category',
                        ],
                        permissions
                      ) > -1 && (
                          <>
                            <Divider style={{ margin: '0' }} />
                            <Menu.Item key='catalog:2'>
                              <Link to='/backoffice/catalog/categories'>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <MdChevronRight color='#A56CDF' size={16} style={{ marginRight: 10 }} />
                                  Categorias
                                </div>
                              </Link>
                            </Menu.Item>
                          </>
                        )}
                      {hasIndex(
                        [
                          'catalog.product',
                          'catalog.add_product',
                          'catalog.change_product',
                          'catalog.remove_product',
                          'catalog.import_product',
                          'catalog.export_product',
                        ],
                        permissions
                      ) > -1 && (
                          <>
                            <Divider style={{ margin: '0' }} />
                            <Menu.Item key='catalog:3'>
                              <Link to='/backoffice/catalog/products'>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <MdChevronRight color='#A56CDF' size={16} style={{ marginRight: 10 }} />
                                  Produtos
                                </div>
                              </Link>
                            </Menu.Item>
                          </>
                        )}
                      {hasIndex(
                        [
                          'catalog.product_mix',
                          'catalog.add_product_mix',
                          'catalog.change_product_mix',
                          'catalog.remove_product_mix',
                          'catalog.import_product_mix',
                          'catalog.export_product_mix',
                        ],
                        permissions
                      ) > -1 && (
                          <>
                            <Divider style={{ margin: '0' }} />
                            <Menu.Item key='catalog:4'>
                              <Link to='/backoffice/catalog/mix-products'>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <MdChevronRight color='#A56CDF' size={16} style={{ marginRight: 10 }} />
                                  Mix de produtos
                                </div>
                              </Link>
                            </Menu.Item>
                          </>
                        )}
                      {/* {hasIndex(
                        [
                          'catalog.product_mix',
                          'catalog.add_product_mix',
                          'catalog.change_product_mix',
                          'catalog.remove_product_mix',
                          'catalog.import_product_mix',
                          'catalog.export_product_mix',
                        ],
                        permissions
                      ) > -1 && ( */}
                      <>
                        <Divider style={{ margin: '0' }} />
                        <Menu.Item key='catalog:5'>
                          <Link to='/backoffice/catalog/mix-category'>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <MdChevronRight color='#A56CDF' size={16} style={{ marginRight: 10 }} />
                              Mix de categoria
                            </div>
                          </Link>
                        </Menu.Item>
                      </>
                      <>
                        <Divider style={{ margin: '0' }} />
                        <Menu.Item key='catalog:6'>
                          <Link to='/backoffice/catalog/mix-brand'>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <MdChevronRight color='#A56CDF' size={16} style={{ marginRight: 10 }} />
                              Mix de marca
                            </div>
                          </Link>
                        </Menu.Item>
                      </>
                      {/* )} */}
                    </Menu.SubMenu>
                  )}
                {hasIndex(
                  [
                    'store.regional',
                    'store.add_regional',
                    'store.change_regional',
                    'store.remove_regional',
                    'store.import_regional',
                    'store.export_regional',
                    'store.network',
                    'store.add_network',
                    'store.change_network',
                    'store.remove_network',
                    'store.import_network',
                    'store.export_network',
                    'store.flag',
                    'store.add_flag',
                    'store.change_flag',
                    'store.remove_flag',
                    'store.import_flag',
                    'store.export_flag',
                    'store.pdv',
                    'store.add_pdv',
                    'store.change_pdv',
                    'store.remove_pdv',
                    'store.import_pdv',
                    'store.export_pdv',
                  ],
                  permissions
                ) > -1 && (
                    <>
                      <Divider style={{ margin: '0' }} />
                      <Menu.SubMenu
                        key='store'
                        title={
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            {/* <MdStoreMallDirectory size={16} style={{ marginRight: 10 }} /> */}
                            Loja
                          </div>
                        }
                      >
                        {hasIndex(
                          [
                            'store.regional',
                            'store.add_regional',
                            'store.change_regional',
                            'store.remove_regional',
                            'store.import_regional',
                            'store.export_regional',
                          ],
                          permissions
                        ) > -1 && (
                            <Menu.Item key='store:1'>
                              <Link to='/backoffice/store/regionals'>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <MdChevronRight color='#A56CDF' size={16} style={{ marginRight: 10 }} />
                                  Regionais
                                </div>
                              </Link>
                            </Menu.Item>
                          )}
                        {hasIndex(
                          [
                            'store.network',
                            'store.add_network',
                            'store.change_network',
                            'store.remove_network',
                            'store.import_network',
                            'store.export_network',
                          ],
                          permissions
                        ) > -1 && (
                            <>
                              <Divider style={{ margin: '0' }} />
                              <Menu.Item key='store:2'>
                                <Link to='/backoffice/store/networks'>
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <MdChevronRight color='#A56CDF' size={16} style={{ marginRight: 10 }} />
                                    Redes
                                  </div>
                                </Link>
                              </Menu.Item>
                            </>
                          )}
                        {hasIndex(
                          [
                            'store.flag',
                            'store.add_flag',
                            'store.change_flag',
                            'store.remove_flag',
                            'store.import_flag',
                            'store.export_flag',
                          ],
                          permissions
                        ) > -1 && (
                            <>
                              <Divider style={{ margin: '0' }} />
                              <Menu.Item key='store:3'>
                                <Link to='/backoffice/store/flags'>
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <MdChevronRight color='#A56CDF' size={16} style={{ marginRight: 10 }} />
                                    Bandeiras
                                  </div>
                                </Link>
                              </Menu.Item>
                            </>
                          )}
                        {hasIndex(
                          [
                            'store.pdv',
                            'store.add_pdv',
                            'store.change_pdv',
                            'store.remove_pdv',
                            'store.import_pdv',
                            'store.export_pdv',
                          ],
                          permissions
                        ) > -1 && (
                            <>
                              <Divider style={{ margin: '0' }} />
                              <Menu.Item key='store:4'>
                                <Link to='/backoffice/store/pdvs'>
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <MdChevronRight color='#A56CDF' size={16} style={{ marginRight: 10 }} />
                                    PDV's
                                  </div>
                                </Link>
                              </Menu.Item>
                            </>
                          )}
                      </Menu.SubMenu>
                    </>
                  )}
                {hasIndex(
                  ['justify.list_justify', 'justify.add_justify', 'justify.change_justify', 'justify.remove_justify'],
                  permissions
                ) > -1 && (
                    <>
                      <Divider style={{ margin: '0' }} />
                      <Menu.Item key='survey:49886'>
                        <Link to='/backoffice/register/justification'>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            {/* <MdChevronRight color='#A56CDF' size={16} style={{ marginRight: 10 }} /> */}
                            Justificativa de falta
                          </div>
                        </Link>
                      </Menu.Item>
                    </>
                  )}

                <>
                  <Divider style={{ margin: '0' }} />
                  <Menu.Item key='survey:49886'>
                    <Link to='/backoffice/register/removal'>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {/* <MdChevronRight color='#A56CDF' size={16} style={{ marginRight: 10 }} /> */}
                        Motivo de afastamento
                      </div>
                    </Link>
                  </Menu.Item>
                </>
                {hasIndex(
                  [
                    'training.list_training',
                    'training.add_training',
                    'training.change_training',
                    'training.remove_training',
                  ],
                  permissions
                ) > -1 && (
                    <>
                      <Divider style={{ margin: '0' }} />
                      <Menu.Item key='reports:38897987'>
                        <Link to='/backoffice/register/training'>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            {/* <MdChevronRight color='#A56CDF' size={16} style={{ marginRight: 10 }} /> */}
                            Treinamento
                          </div>
                        </Link>
                      </Menu.Item>
                    </>
                  )}
              </Menu.SubMenu>
            )}
          {hasIndex(
            [
              'form.list_form',
              'form.add_form',
              'form.change_form',
              'form.toggle_form',
              'form.add_schedule_form',
              'form.change_schedule_form',
              'form.remove_form_mix',
              'form.import_form_mix',
              'form.export_form_mix',
              'task.list_task',
              'task.export_task',
              'visit.list_visit',
              'visit.add_visit_survey',
              'visit.export_visit',
            ],
            permissions
          ) > -1 &&
            IDS.indexOf(user.id) === -1 && (
              <Menu.SubMenu
                key='survey'
                title={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {/* <MdDescription size={16} style={{ marginRight: 10 }} /> */}
                    Pesquisa
                  </div>
                }
              >
                {hasIndex(
                  [
                    'form.list_form',
                    'form.add_form',
                    'form.change_form',
                    'form.toggle_form',
                    'form.add_schedule_form',
                    'form.change_schedule_form',
                    'form.remove_form_mix',
                    'form.import_form_mix',
                    'form.export_form_mix',
                  ],
                  permissions
                ) > -1 && (
                    <Menu.Item key='survey:1'>
                      <Link to='/backoffice/survey/forms'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <MdChevronRight color='#A56CDF' size={16} style={{ marginRight: 10 }} />
                          Formulário
                        </div>
                      </Link>
                    </Menu.Item>
                  )}
                {hasIndex(['task.list_task', 'task.export_task'], permissions) > -1 && (
                  <>
                    <Divider style={{ margin: '0' }} />
                    <Menu.Item key='survey:2'>
                      <Link to={`/backoffice/survey/tasks?date=%5B"${moment().format('YYYY-MM-DD')}"%2C"${moment().format('YYYY-MM-DD')}"%5D`}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <MdChevronRight color='#A56CDF' size={16} style={{ marginRight: 10 }} />
                          Atividades
                        </div>
                      </Link>
                    </Menu.Item>
                  </>
                )}
                {hasIndex(['visit.list_visit', 'visit.add_visit_survey', 'visit.export_visit'], permissions) > -1 && (
                  <>
                    <Divider style={{ margin: '0' }} />
                    <Menu.Item key='survey:3'>
                      <Link to='/backoffice/survey/visits'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <MdChevronRight color='#A56CDF' size={16} style={{ marginRight: 10 }} />
                          Visitas
                        </div>
                      </Link>
                    </Menu.Item>
                  </>
                )}

                {/* <Divider style={{ margin: '0' }} /> */}

                {/* <Menu.Item key='survey:5'>
              <Link to='/backoffice/survey/scheduling_task'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <MdChevronRight color='#A56CDF' size={16} style={{ marginRight: 10 }} />
                  Agendamento de atividade
                </div>
              </Link>
            </Menu.Item> */}
              </Menu.SubMenu>
            )}
          {hasIndex(
            [
              'survey.list_survey',
              'justify_report.list_justify_report',
              'justify_report.homolog_justify_report',
              'pictures.list_pictures',
              'pictures.export_pictures_ppt',
              'before_afer.list_before_afer',
            ],
            permissions
          ) > -1 && (
              <Menu.SubMenu
                key='reports'
                title={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {/* <MdLibraryBooks size={16} style={{ marginRight: 10 }} /> */}
                    Relatórios
                  </div>
                }
              >
                {hasIndex(['survey.list_survey'], permissions) > -1 && (
                  <Menu.Item key='reports:1'>
                    <Link to='/backoffice/reports/search'>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <MdChevronRight color='#A56CDF' size={16} style={{ marginRight: 10 }} />
                        Pesquisas
                      </div>
                    </Link>
                  </Menu.Item>
                )}
                {hasIndex(['justify_report.list_justify_report', 'justify_report.homolog_justify_report'], permissions) >
                  -1 && (
                    <>
                      <Divider style={{ margin: '0' }} />
                      <Menu.Item key='reports:2'>
                        <Link to='/backoffice/reports/justifyVisits'>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <MdChevronRight color='#A56CDF' size={16} style={{ marginRight: 10 }} />
                            Visitas justificadas
                          </div>
                        </Link>
                      </Menu.Item>
                    </>
                  )}

                {hasIndex(['pictures.list_pictures', 'pictures.export_pictures_ppt'], permissions) > -1 && (
                  <>
                    <Divider style={{ margin: '0' }} />
                    <Menu.Item key='reports:3'>
                      <div
                        onClick={async () => {
                          let { data } = await api_v2.get('/search/me')
                          const workspace: any = getWorkspaceInfo()
            
                          window.open(
                            `https://book.rock-at.com/loading?token=${getToken()}&user=${JSON.stringify({
                              "id": workspace.id,
                              "name": workspace.name,
                              "name_user": data.name,
                              "user_id": workspace.user_id,
                              "provider": "PG",
                              "slug": workspace.slug,
                              "shared_environment": workspace.shared_environment,
                              "is_active": workspace.is_active,
                              "client": data?.config?.is_client || false,
                              "sub_workspaces": data?.config?.sub_workspaces || false,
                          })}`,
                            '_blank'
                            )
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <MdChevronRight color='#A56CDF' size={16} style={{ marginRight: 10 }} />
                          Fotos
                        </div>
                      </div>
                    </Menu.Item>
                    {/* <Menu.Item key='reports:3'>
                    <Link to='/backoffice/reports/pictures'>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <MdChevronRight color='#A56CDF' size={16} style={{ marginRight: 10 }} />
                        Fotos
                      </div>
                    </Link>
                  </Menu.Item> */}
                  </>
                )}
                {hasIndex(['before_afer.list_before_afer'], permissions) > -1 && (
                  <>
                    <Divider style={{ margin: '0' }} />
                    <Menu.Item key='reports:5'>
                      <Link to='/backoffice/monitory/pictureLastAndAfter'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <MdChevronRight color='#A56CDF' size={16} style={{ marginRight: 10 }} />
                          Antes X Depois
                        </div>
                      </Link>
                    </Menu.Item>
                  </>
                )}
                <Divider style={{ margin: '0' }} />
                <Menu.SubMenu key='sub' title="Histórico" icon={<MdChevronRight color='#A56CDF' size={16} style={{ marginRight: 10 }} />}>
                  <Menu.Item key='survey:49886'>
                    <Link to='/backoffice/history/removal'>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <MdChevronRight color='#A56CDF' size={16} style={{ marginRight: 10 }} />
                        Histórico de afastamento
                      </div>
                    </Link>
                  </Menu.Item>
                  <Divider style={{ margin: '0' }} />
                  <Menu.Item key='survey:498386'>
                    <Link to='/backoffice/history/script'>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <MdChevronRight color='#A56CDF' size={16} style={{ marginRight: 10 }} />
                        Histórico de roteiro
                      </div>
                    </Link>
                  </Menu.Item>
                  <Divider style={{ margin: '0' }} />
                  <Menu.Item key='reports:2'>
                    <Link to='/backoffice/reports/editVisits'>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <MdChevronRight color='#A56CDF' size={16} style={{ marginRight: 10 }} />
                        Histórico status de visitas
                      </div>
                    </Link>
                  </Menu.Item>
                </Menu.SubMenu>
                {workspa && (workspa.id == 170 || workspa.id == 188 || workspa.id == 189) && <>
                  <Divider style={{ margin: '0' }} />
                  <Menu.Item key='reports:3'>
                    <Link to='/backoffice/point'>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <MdChevronRight color='#A56CDF' size={16} style={{ marginRight: 10 }} />
                        Relatório de ponto
                      </div>
                    </Link>
                  </Menu.Item>
                </>}
                <Divider style={{ margin: '0' }} />
                <Menu.Item key='reports:3'>
                  <Link to='/backoffice/lunch'>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <MdChevronRight color='#A56CDF' size={16} style={{ marginRight: 10 }} />
                      Almoço
                    </div>
                  </Link>
                </Menu.Item>
                {workspa && (workspa.id == 170 || workspa.id == 188 || workspa.id == 189) && <>

                  <Divider style={{ margin: '0' }} />
                  <Menu.Item key='reports:3'>
                    <Link to='/backoffice/km'>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <MdChevronRight color='#A56CDF' size={16} style={{ marginRight: 10 }} />
                        Relatório de KM
                      </div>
                    </Link>
                  </Menu.Item>
                </>}
              </Menu.SubMenu>
            )}
          {hasIndex(['kanban.list_kanban', 'plan_exec.list_plan_exec', 'status_day.list_status_day'], permissions) >
            -1 && (
              <Menu.SubMenu
                key='monitory'
                title={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {/* <MdDialpad size={16} style={{ marginRight: 10 }} /> */}
                    Monitoramento
                  </div>
                }
              >
                {hasIndex(['kanban.list_kanban'], permissions) > -1 && (
                  <Menu.Item key='monitory:1'>
                    <Link to='/backoffice/monitory/monitoring'>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <MdChevronRight color='#A56CDF' size={16} style={{ marginRight: 10 }} />
                        Kanban
                      </div>
                    </Link>
                  </Menu.Item>
                )}
                {hasIndex(['plan_exec.list_plan_exec'], permissions) > -1 && (
                  <>
                    <Divider style={{ margin: '0' }} />
                    <Menu.Item key='monitory:2'>
                      <Link to='/backoffice/monitory/planned'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <MdChevronRight color='#A56CDF' size={16} style={{ marginRight: 10 }} />
                          Planejado X Realizado
                        </div>
                      </Link>
                    </Menu.Item>
                  </>
                )}
                {hasIndex(['status_day.list_status_day'], permissions) > -1 && (
                  <>
                    <Divider style={{ margin: '0' }} />
                    <Menu.Item key='monitory:2'>
                      <Link to='/backoffice/monitory/status-day'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <MdChevronRight color='#A56CDF' size={16} style={{ marginRight: 10 }} />
                          Status Day
                        </div>
                      </Link>
                    </Menu.Item>
                  </>
                )}
              </Menu.SubMenu>
            )}
          {hasIndex(['process.list_process_import', 'process.download_process_import'], permissions) > -1 &&
            IDS.indexOf(user.id) === -1 && (
              <Menu.SubMenu
                key='process'
                title={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {/* <MdShowChart size={16} style={{ marginRight: 10 }} /> */}
                    Processamento
                  </div>
                }
              >
                <Menu.Item key='monitory:5'>
                  <Link to='/backoffice/process'>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <MdChevronRight color='#A56CDF' size={16} style={{ marginRight: 10 }} />
                      Importação XLSX
                    </div>
                  </Link>
                </Menu.Item>
                <Menu.Item key='monitory:5'>
                  <Link to='/backoffice/processPPT'>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <MdChevronRight color='#A56CDF' size={16} style={{ marginRight: 10 }} />
                      Processamento PPT
                    </div>
                  </Link>
                </Menu.Item>
              </Menu.SubMenu>
            )}
        </Menu>
      </ContainerMenuH>
    </div>
  )
}

export default MenuHorizonte
