import styled from 'styled-components'
import { BigButton } from '../../GlobalStyles'

export const AddButton = styled(BigButton)`
  color: #fff;
  background-color: #6600cc;
  border: none;
`

export const AddButtonWhite = styled(BigButton)``

export const ContainerSearch = styled.div`
  border-radius: 5px;
  width: 300px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #eeddfe;
  font-size: 14px;
  color: black;
  padding-right: 10px;
`

export const Search = styled.input`
  height: 100%;
  width: 100%;
  border: none;
  background-color: #eeddfe;
  outline: none;
  border-radius: 5px;
  padding-left: 5px;
  font-size: 14px;
`

export const ContainerTableDefault = styled.div`
  margin-top: 15px;
  max-height: 100%;
  overflow-x: auto;

  @media (max-width: 900px) {
    max-width: 83vw;
  }

  @media (max-width: 600px) {
    max-width: 80vw;
  }



  /* max-width: 100%; */
  /* overflow: auto; */

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #ebebeb;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #666666;
    border-radius: 20px;
    border: 3px solid #ebebeb;
  }

  &&& .ant-table {
    background-color: transparent;
  }

  &&& .ant-table-thead > tr > th {
    background-color: transparent;
    border-bottom: 2px solid #d6d6d6;
  }

  &&& .ant-table-tbody > tr > td {
    background-color: transparent;
    border-bottom: 2px solid #d6d6d6;
  }
`

