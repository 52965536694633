import React, { useState, useEffect, useRef } from 'react'
import { Button, ContainerModal, ContainerStep2, LabelCheck } from './style'
import { Search } from '../form/SimpleSearch'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import AddUser from '../../assets/images/addUser.png'
import { Loading } from '../Loading'
import { Checkbox, message } from 'antd'
import api_v2 from '../../services/api-v2'
import * as Yup from 'yup'
import { Mixpanel } from '../../utils/mixpanel'

interface IProps {
  visible: boolean
  close: (boolean: any) => void
  visit: any
  type?: string
}


export const ModalMoveVisits = ({ visible, close, visit, type = 'visit' }: IProps) => {
  const formRef = useRef<FormHandles>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [check, setCheck] = useState<boolean>(false)

  async function handleSubmit(body: any) {
    setLoading(true)
    try {

      let obj: any = {
        promoter: Yup.string().required('Campo obrigatório'),
      }

      let bodyApi: any = {}

      if(body.promoter && body.promoter.value) bodyApi.promoter = body.promoter.value
      if(check) bodyApi.move_script = check

      const schema = Yup.object().shape(obj)
      await schema.validate(bodyApi, {
        abortEarly: false,
      })

      Mixpanel.track('Moveu visita', {
        visit,
        bodyApi,
      })

      const { data } = await api_v2.post(`/visit/move/${visit.id}`, bodyApi)

      message.success(`Vista movida com sucesso`)
      close(false)
      setTimeout(() => {
        window.location.reload()
      }, 1500);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((el: any) => {
          formRef.current!.setFieldError(el.path, el.message)
        })
      } else {
        message.error('Erro ao mover visita')
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    setCheck(false)
    // formRef.current.reset()
  }, [visible])

  return (
    <ContainerModal
      visible={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
      style={{ top: 10 }}
      destroyOnClose={true}
    >
      {loading && <Loading />}
      <Form className='form' ref={formRef} initialData={{ is_active: true }} onSubmit={handleSubmit}>
        <ContainerStep2>
          <h3>Mover visita</h3>
          <img src={AddUser} alt='user' />
          <LabelCheck>Selecione o promotor</LabelCheck>
          <Search placeholder='Selecione o promotor' name='promoter' path='promoters' config='&is_active=true' isMult={false} />
          <div style={{width: '100%', marginTop: 10, fontWeight: 'bold'}}>
            <Checkbox checked={check} onChange={(evt) => setCheck(evt.target.checked)}>Mover roteiro junto</Checkbox>
          </div>
          <Button type='submit' style={{ width: '100%', height: '55px', marginTop: 20 }}>
            Mover
          </Button>
        </ContainerStep2>
      </Form>
    </ContainerModal>
  )
}
