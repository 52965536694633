import React, { useState, useEffect } from 'react'
import {
  IconMenu,
  ContainerItems,
  ContainerConfig,
  FormGroup,
  Button,
  CloseConfig,
  Header
} from './styles'
import { SettingTwoTone } from '@ant-design/icons'
import { Tooltip, Select } from 'antd'

const { Option } = Select

const Menu: React.FC<any> = ({ question, configForm, editConfig }) => {
  const [collapsedConfig, setCollapsedConfig] = useState<boolean>(false)
  const [jumpQuestion, setJumpQuestion] = useState<boolean>(false)
  const [config, setConfig] = useState<any>(configForm)

  useEffect(() => {
    setConfig(configForm)
  }, [configForm])

  const onCollapseConfig = () => {
    if (jumpQuestion) {
      setJumpQuestion(false)
      if (collapsedConfig) {
        setCollapsedConfig(false)
      } else {
        setCollapsedConfig(true)
      }
    } else {
      if (collapsedConfig) {
        setCollapsedConfig(false)
      } else {
        setCollapsedConfig(true)
      }
    }
  }

  return (
    <>
      <ContainerItems>
        <Tooltip placement="right" title={'Configurações'}>
          <IconMenu onClick={() => onCollapseConfig()}>
            <SettingTwoTone twoToneColor="#F07375" />
          </IconMenu>
        </Tooltip>
      </ContainerItems>
      {collapsedConfig ? (
        <ContainerConfig>
          <Header>
            <h2>Configurações</h2>
            <CloseConfig
              color="error"
              fontSize="small"
              onClick={() => onCollapseConfig()}
            />
          </Header>
          <FormGroup>
            <label>Nome no formulário</label>
            <input
              type="text"
              placeholder="Digite o nome do formulário"
              value={config.name}
              onChange={(e: any) => {
                editConfig({ ...config, name: e.target.value })
                setConfig({ ...config, name: e.target.value })
              }}
            />
          </FormGroup>
          <FormGroup style={{ width: '100%' }}>
            <label>*Qual tema do formulário:</label>
            <Select
              onChange={(e: any) => {
                editConfig({ ...config, context: e })
                setConfig({ ...config, context: e })
              }}
              defaultValue={config.context}
            >
              <Option value="PRODUCT">Produto</Option>
              <Option value="STORE">Loja</Option>
              <Option value="CHECK_IN">Check-in</Option>
              {/* <Option value="Check-in">Check-in</Option>
              <Option value="Check-out">Check-out</Option>
              <Option value="Ruptura">Ruptura</Option> */}
            </Select>
          </FormGroup>
          {config && config.context === 'Produto' ? (
            <FormGroup style={{ width: '100%' }}>
              <label>Tipo de formulário:</label>
              <Select
                onChange={(e: any) => {
                  editConfig({ ...config, typeForm: e })
                  setConfig({ ...config, typeForm: e })
                }}
                defaultValue={config.typeForm}
              >
                <Option value="OWN">Próprio</Option>
                <Option value="CONCURRENT">Concorrente</Option>
                <Option value="ALL">Todos</Option>
              </Select>
            </FormGroup>
          ) : null}
          <Button>Salvar</Button>
        </ContainerConfig>
      ) : null}
    </>
  )
}

export default Menu
