import { useField } from '@unform/core'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Container, Error, Label } from './styles'
import { DatePicker, DatePickerProps } from 'antd'
import moment from 'moment'

interface Props {
  name: string
  label?: string
}
type InputProps = DatePickerProps & Props
const SimpleDate: React.FC<InputProps> = ({ name, label, defaultValue, ...rest }) => {
  const datepickerRef = useRef(null)
  const { fieldName, registerField, error } = useField(name)
  const [inputData, setDate] = useState<any>(defaultValue || null)

  const handlePickerChange = useCallback((val) => {
    setDate(val)
  }, [])

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      setValue: (ref: any, value: string) => {
        // console.log(ref.pickerRef.current.props.onChange, 'ref date', value)
        if (value) {
          console.log('entrou')
          setDate(value)
        }
      },

      getValue: (ref: any) => {
        if (!ref.props.defaultValue) return ''

        return moment(ref.props.defaultValue).format('YYYY-MM-DD')
      },
    })
  }, [fieldName, registerField, inputData])

  useEffect(() => {
    setDate(defaultValue)
  }, [defaultValue])
  return (
    <Container>
      <Label htmlFor={fieldName}>
        {error && <Error>{error}</Error>}
        <DatePicker
          format='DD/MM/YYYY'
          onChange={handlePickerChange}
          ref={datepickerRef}
          defaultValue={inputData}
          value={inputData}
          {...rest}
        />
        <span> {label} </span>
      </Label>
    </Container>
  )
}
export default SimpleDate
