import React from 'react'
import {
  ContainerQuestion,
  TitlePicture,
  Description,
} from '../style'
import { Upload, Button, Form } from 'antd'
import ViewPicture from './viewPicture'

const QrQuestion: React.FC<any> = ({ quest }) => {

  return (
    <>
      <ContainerQuestion>
        <TitlePicture>
          <h1>
            {quest.is_required ? '*' : null} {quest.order} →{' '}
          </h1>{' '}
          {quest.name}
        </TitlePicture>
        {quest.description ? <Description>{quest.description}</Description> : null}
        {quest.picture && typeof quest.picture == 'string' &&  <ViewPicture picture={quest.picture} />}
        <Form.Item name='QR_CODE' label='Foto' rules={[{ required: quest.is_required ? true : false }]}>
          <Upload name='logo' action='/upload.do' listType='picture' multiple>
            <Button>Escanear codigo</Button>
          </Upload>
        </Form.Item>
      </ContainerQuestion>
    </>
  )
}

export default QrQuestion
