import { Col, DatePicker, Row, Tooltip } from 'antd'
import {
  CardApps,
  CardDonaults,
  CardMap,
  CardStatus,
  ContainerData,
  ContainerHeaderGb,
  ContainerInfo,
  ContainerMenu,
  DataNumber,
  InfoChart,
  LogoApp,
  TextGb,
  TextHeader,
  TextInfo,
  ViewApp,
  ButtonBack,
  ContainerInfoCel,
} from './style'
import Ticket from '../../assets/images/ticket.png'
import { PieChart, Pie, Cell } from 'recharts'
import { SyncOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import api_v2 from '../../services/api-v2'
import { useParams } from 'react-router'
import {
  AndroidOutlined,
  AppstoreOutlined,
  InfoCircleOutlined,
  PhoneOutlined,
  WifiOutlined,
  UsbOutlined,
  DashboardOutlined,
} from '@ant-design/icons'
import moment from 'moment'
import { Loading } from '../Loading'
import history from '../../routes/history'


const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042']

interface Props {
  dateFilter: any
  setterDateFilter: any
}

export const TabGeneralUser: React.FC<Props> = ({ dateFilter, setterDateFilter }) => {
  const { id }: any = useParams()
  const [countVisit, setVisit] = useState(-1)
  const [countVisitPendent, setVisitPendent] = useState(-1)
  const [countVisitComplete, setVisitComplete] = useState(-1)
  const [countVisitInProgress, setVisitInProgress] = useState(-1)
  const [countVisitAbsence, setVisitAbsence] = useState(-1)
  const [countTasks, setCountTasks] = useState(-1)
  const [infoUser, setInfoUser] = useState<any>()
  const [loading,] = useState<any>(false)

  const dateFormat = 'DD/MM/YYYY'

  const [chart, setChart] = useState([
    { name: 'Pendente', value: 0 },
    { name: 'Completa', value: 0 },
    { name: 'Em Andamento', value: 0 },
    { name: 'Justificada', value: 0 },
  ])

  const loadCards = async () => {
    let { start_date, end_date } = dateFilter

    const { data: visit } = await api_v2.get(`/total/visits/${id}?start_date=${start_date}&end_date=${end_date}`)
    setVisit(visit.total)

    const { data: pendent } = await api_v2.get(
      `/total/visits/${id}?status=PENDENT&start_date=${start_date}&end_date=${end_date}`
    )
    setVisitPendent(pendent.total)

    const { data: complete } = await api_v2.get(
      `/total/visits/${id}?status=COMPLETE&start_date=${start_date}&end_date=${end_date}`
    )
    setVisitComplete(complete.total)

    const { data: in_progress } = await api_v2.get(
      `/total/visits/${id}?status=IN_PROGRESS&start_date=${start_date}&end_date=${end_date}`
    )
    setVisitInProgress(in_progress.total)

    const { data: absence } = await api_v2.get(
      `/total/visits/${id}?status=JUSTIFIED_ABSENCE&start_date=${start_date}&end_date=${end_date}`
    )
    setVisitAbsence(absence.total)

    const { data: data_tasks } = await api_v2.get(`/total/tasks/${id}?start_date=${start_date}&end_date=${end_date}`)
    setCountTasks(data_tasks.total)
  }

  useEffect(() => {
    loadCards()
    getInfoFirebase()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dateFilter])


  useEffect(() => {
    setChart([
      { name: `Pendente (${countVisitPendent})`, value: countVisitPendent },
      { name: `Completa (${countVisitComplete})`, value: countVisitComplete },
      { name: `Em Andamento (${countVisitInProgress})`, value: countVisitInProgress },
      { name: `Justificada (${countVisitAbsence})`, value: countVisitAbsence },
    ])
  }, [countVisitPendent, countVisitComplete, countVisitInProgress, countVisitAbsence])

  const RADIAN = Math.PI / 180
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return percent * 100 > 0 ? (
      <text x={x} y={y} fill='white' textAnchor={x > cx ? 'start' : 'end'} dominantBaseline='central'>
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    ) : (
      <></>
    )
  }

  const getInfoFirebase = async () => {
    const { data } = await api_v2.get(
      `/user/info-mobile/${id}`
    )

    setInfoUser(data)

  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <ContainerMenu>
                <ButtonBack style={{ width: 300 }}>
                  {/* <b>Filtrar por periodo:</b> */}
                  <ContainerData>
                    <DatePicker.RangePicker
                      ranges={{
                        Hoje: [moment(), moment()],
                        'Esta semana': [moment().startOf('week'), moment().endOf('week')],
                        'Este mês': [moment().startOf('month'), moment().endOf('month')],
                      }}
                      value={[moment(dateFilter.start_date, 'YYYY-MM-DD'), moment(dateFilter.end_date, 'YYYY-MM-DD')]}
                      defaultValue={[moment(dateFilter.start_date, 'YYYY-MM-DD'), moment(dateFilter.end_date, 'YYYY-MM-DD')]}
                      format={dateFormat}
                      onChange={(val, string) => {

                        let start = ''
                        let end = ''

                        if (val && val[0]) start = val[0].format('YYYY-MM-DD')
                        if (val && val[1]) end = val[1].format('YYYY-MM-DD')



                        // setterDateFilter(val)
                        // setDtSearch(val)
                        // console.log(`/backoffice/teamById/${id}?start_date=${start}&end_date=${end}`)
                        history.push(
                          `/backoffice/teamById/${id}?start_date=${start}&end_date=${end}`
                        )
                      }}
                    />
                  </ContainerData>
                </ButtonBack>
              </ContainerMenu>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} >
              <Row gutter={[10, 10]} wrap={true}>
                <Col span={8}>
                  <CardStatus>
                    <TextHeader>Total de visitas</TextHeader>
                    <TextInfo>Roteiro + Agendadas</TextInfo>
                    <DataNumber>
                      <img src={Ticket} alt='Ticket' />
                      {countVisit < 0 ? <SyncOutlined spin /> : countVisit}
                    </DataNumber>
                  </CardStatus>
                </Col>
                <Col span={8}>
                  <CardStatus>
                    <TextHeader>Total de tarefas</TextHeader>
                    <TextInfo>Pesquisa x Visita</TextInfo>
                    <DataNumber>
                      <img src={Ticket} alt='Ticket' /> {countTasks < 0 ? <SyncOutlined spin /> : countTasks}
                    </DataNumber>
                  </CardStatus>
                </Col>
                <Col span={8}>
                  <CardStatus>
                    <TextHeader>Total de faltas</TextHeader>
                    <TextInfo>Visitas justificadas</TextInfo>
                    <DataNumber>
                      <img src={Ticket} alt='Ticket' />
                      {countVisitAbsence < 0 ? <SyncOutlined spin /> : countVisitAbsence}
                    </DataNumber>
                  </CardStatus>
                </Col>
              </Row>
              <CardDonaults>
                <TextHeader>Visitas</TextHeader>
                <TextInfo>Pendentes x Realizadas x Em andamento x Justificadas</TextInfo>
                <ContainerInfo>
                  <Row gutter={[16, 16]}>
                    <Col span={14}>
                      {chart.length > 0 && (
                        <PieChart width={200} height={200}>
                          <Pie
                            data={chart}
                            cx='50%'
                            cy='50%'
                            labelLine={false}
                            label={renderCustomizedLabel}
                            outerRadius={80}
                            fill='#8884d8'
                            dataKey='value'
                          >
                            {chart.map((entry: any, index: number) => (
                              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                          </Pie>
                        </PieChart>
                      )}
                    </Col>
                    <Col span={10}>
                      <Row gutter={[40, 40]}>
                        {chart.length > 0 &&
                          chart.map((entry: any, index: number) => (
                            <Col span={24} key={index}>
                              <InfoChart color={COLORS[index]}>
                                <div />
                                {entry.name}
                              </InfoChart>
                            </Col>
                          ))}
                      </Row>
                    </Col>
                  </Row>
                </ContainerInfo>
              </CardDonaults>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8}>
              <CardApps>
                <ContainerHeaderGb>
                  <TextHeader>Memória do aparelho:</TextHeader>
                  <TextGb>
                    {infoUser?.free_memory && `${parseFloat(infoUser?.free_memory).toFixed(2)}GB`}/
                    {infoUser?.free_memory &&
                      `${(parseFloat(infoUser?.memory_total) + parseFloat(infoUser?.free_memory)).toFixed(2)}GB`}
                  </TextGb>
                </ContainerHeaderGb>
                <ViewApp>
                  <h1>Apps Instalados:</h1>
                  <Row justify='center'>
                    {infoUser && infoUser.apps && infoUser.apps.length > 0 ? (
                      infoUser.apps.map((el: any, index: number) => (
                        <Col key={index}>
                          <Tooltip
                            placement='top'
                            title={<p style={{ margin: 0, color: '#272d3b' }}>{el.name}</p>}
                            color=' #eeddff'
                          >
                            <LogoApp src={el.url} alt='whatsappApp' />
                          </Tooltip>
                        </Col>
                      ))
                    ) : (
                      <div
                        style={{
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <h3 style={{ fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}>
                          Sem aplicativo coletado
                        </h3>
                      </div>
                    )}
                  </Row>
                </ViewApp>
              </CardApps>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8}>
              <CardMap>
                <TextHeader>Informações celular:</TextHeader>
                <TextInfo>Verifique as informações no celular do promotor.</TextInfo>
                <ContainerInfoCel>
                  <AndroidOutlined />
                  Versão do android: <b> {infoUser?.android_version}</b>
                </ContainerInfoCel>
                <ContainerInfoCel>
                  <AppstoreOutlined />
                  IMEI Telefone: <b> {infoUser?.imei}</b>
                </ContainerInfoCel>
                <ContainerInfoCel>
                  <InfoCircleOutlined />
                  Versão do app: <b> {infoUser?.app_version}</b>
                </ContainerInfoCel>
                <ContainerInfoCel>
                  <PhoneOutlined />
                  Modelo de celular: <b> {infoUser?.model_phone}</b>
                </ContainerInfoCel>
                <ContainerInfoCel>
                  <WifiOutlined />
                  Operadora: <b> {infoUser?.operater}</b>
                </ContainerInfoCel>
                <ContainerInfoCel>
                  <UsbOutlined />
                  Memória disponivel:
                  <b> {infoUser?.free_storage && `${parseFloat(infoUser?.free_storage).toFixed(2)}GB`}</b>
                </ContainerInfoCel>
                <ContainerInfoCel>
                  <DashboardOutlined />
                  Espaço em disco:
                  <b>

                    {infoUser?.free_storage &&
                      `${(parseFloat(infoUser?.total_storage) + parseFloat(infoUser?.free_storage)).toFixed(2)}GB`}
                  </b>
                </ContainerInfoCel>
              </CardMap>
            </Col>
          </Row>
        </>
      )}
    </>
  )
}
