import React, { useEffect, useState } from 'react'
import { Container, TextError, Title } from './styles'
import MoneyInput from 'antd-money'

interface IQuestion {
  id: number
  is_required: boolean
  name: string
  order: string
  type: string
  fields_true?: any
  minMoney?: any
  maxMoney?: any
}
interface IDataQuestion {
  dataQuestion: IQuestion
  handleAnswers: (value: any, dataQuestion: IQuestion) => void
  error?: boolean
  checkRequired: (value: any) => void
  defaultVal?: any
}

export const Money = ({ dataQuestion, handleAnswers, error, checkRequired, defaultVal }: IDataQuestion) => {
  const [value, setValue] = useState('')
  const [isRender, setIsRender] = useState(false)

  const handleValue = (value: any) => {
    setValue(value)
    let money = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value)
    handleAnswers([money.replace(/\s+/g, '')], dataQuestion)
  }

  useEffect(() => {
    if (dataQuestion.is_required && !isRender) {
      setIsRender(true)
      checkRequired(dataQuestion.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const val = defaultVal && defaultVal[0] ? defaultVal[0] : undefined
    if(val && val == 'sim') {
      handleValue({
        target: {
          value: 'SIM'
        }
      })
    } 
  }, [defaultVal])

  return (
    <Container>
      <Title>
        {dataQuestion.order} - {dataQuestion.name} {dataQuestion.is_required && '*'}
      </Title>
      {dataQuestion.minMoney && <p style={{fontSize: 1}}>Valor mínimo: R${dataQuestion.minMoney},00</p>}
      {dataQuestion.maxMoney && <p style={{fontSize: 1}}>Valor máximo: R${dataQuestion.maxMoney},00</p>}
      <MoneyInput prefix={'R$'} commaSeperator={true} onChange={handleValue} style={{ width: '100%' }} />
      {error && !value && <TextError>Campo obrigatório</TextError>}
    </Container>
  )
}
