import { Check, Form } from '@rocketseat/unform'
import { Breadcrumb, Button, Col, message, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import Input from '../../../components/form/Input'
import { Container } from '../../../GlobalStyles'
import history from '../../../routes/history'
import api from '../../../services/api'
import { Mixpanel } from '../../../utils/mixpanel'
import { Loading } from '../../../components/Loading'

interface BrandEdit {
  id: string
  name: string
  is_active: boolean
}

const schema = Yup.object().shape({
  name: Yup.string().required('O campo nome é obrigatório'),
  is_active: Yup.boolean(),
})

const BrandEditPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [brand, setBrand] = useState<BrandEdit>()
  const { id }: any = useParams()

  useEffect(() => {
    async function loadBrand() {
      setLoading(true)
      const { data } = await api.get(`/brand/${id}`)
      setBrand({ ...data })
      setLoading(false)
    }

    loadBrand()
  }, [id])

  async function handleSubmit(body: any) {
    setLoading(true)
    try {
      await api.put(`/brand/${id}`, { ...body })
      Mixpanel.track('Editou marca', { id_brand: id, ...body })
      message.success('Marca atualizado')
      history.push('/backoffice/catalog/brands')
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }
  return (
    <>
      <Container>
        <Row>
          <Col span={16}>
            <h1>Atualizar informações da marca</h1>
            <p>Por gentileza informe os dados</p>
            <Breadcrumb separator='>' className='breadcumb'>
              <Link to='/backoffice/catalog/brands'>
                <Breadcrumb.Item> Marcas </Breadcrumb.Item>
              </Link>
              <Breadcrumb.Item> edição de marcas </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Form className='form' initialData={brand} schema={schema} onSubmit={handleSubmit}>
          <Row gutter={16}>
            <Col span={16}>
              <Input type='text' name='name' label='Nome completo' placeholder='Nome completo' />
            </Col>
            <Col className='checkbox' span={2}>
              <Check name='is_active' label='Ativo' />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button type='primary' htmlType='submit'>
                Atualizar
              </Button>
            </Col>
          </Row>
        </Form>
        {loading ? <Loading /> : null}
      </Container>
    </>
  )
}

export default BrandEditPage
