import React, { useState, useRef } from 'react'
import { Button, ContainerModal, ContainerStep2 } from './style'
import AddUser from '../../assets/images/addUser.png'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import Input from '../form/SimpleInput'
import * as Yup from 'yup'
import { message } from 'antd'
import { Loading } from '../Loading'
import api_v2 from '../../services/api-v2'
import { Mixpanel } from '../../utils/mixpanel'

interface IProps {
  visible: boolean
  close: (boolean: any) => void
  reloadApi?: (boolean: any) => void
  defaultValue: any
}

export const ModalCreateFolder = ({ visible, close, reloadApi, defaultValue }: IProps) => {
  const formRef = useRef<FormHandles>(null)
  const [loading, setLoading] = useState<boolean>(false)

  async function handleSubmit(body: any) {
    setLoading(true)
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('* Insira o nome'),
      })
      await schema.validate(body, {
        abortEarly: false,
      })

      await api_v2.post('/trainings-folder', body)
      Mixpanel.track('Criou pasta de treinamento', {
        params: {
          ...body,
        },
      })
      message.success('Pasta cadastrado com sucesso')
      close(false)
      if (reloadApi) {
        reloadApi(true)
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((el: any) => {
          formRef.current!.setFieldError(el.path, el.message)
        })
      } else {
        message.error('Já existe um registro com essas informações!')
      }
    }
    setLoading(false)
  }

  // useEffect(() => {
  //   setEdit(defaultValue)
  // }, [visible]) //eslint-disable-line

  return (
    <ContainerModal
      visible={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
      width={450}
    >
      {loading && <Loading />}
      <Form
        translate={undefined}
        className='form'
        ref={formRef}
        initialData={{ is_active: true }}
        onSubmit={handleSubmit}
      >
        <ContainerStep2>
          <h3>Criar pasta</h3>

          {/* <h3>Cadastro de {type === 'PROMOTOR' ? 'Promotor' : type === 'GESTOR' ? 'Gestor' : null}</h3> */}
          <img src={AddUser} alt='user' />

          <Input
            type='text'
            label='Nome da pasta *'
            name='name'
            placeholder='Nome da pasta'
            style={{ width: 400 }}
            defaultValue={defaultValue && defaultValue.name ? defaultValue.name : ''}
          />
          <Input type='text' label='Perfil' name='profile' placeholder='perfil' style={{ width: 400 }} />
          <Button type='submit' style={{ width: '100%', height: '55px', marginTop: 20 }}>
            Criar
          </Button>
        </ContainerStep2>
      </Form>
    </ContainerModal>
  )
}
