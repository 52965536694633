import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/messaging'
import 'firebase/functions'

  let getWorkspace = sessionStorage.getItem('@rocketpdv:workspace')
  let workspace: any = {}
  if(getWorkspace) workspace = JSON.parse(getWorkspace.toString())

  let firebaseConfig = {}

  if(workspace?.slug === 'black-hawk-new' || workspace?.slug === 'netflix-new'){
     firebaseConfig = {
      apiKey: 'AIzaSyC6eMkMip_m1nZ-iCIDDZEo7VFm3a1mxBA',
      authDomain: 'rocketpdv-promoter.firebaseapp.com',
      databaseURL: 'https://rocketpdv-promoter.firebaseio.com',
      projectId: 'rocketpdv-promoter',
      storageBucket: 'rocketpdv-promoter.appspot.com',
      messagingSenderId: '523913641942',
      appId: '1:523913641942:web:0c2a41f823200a75b92e77'
    }
  }else{
    firebaseConfig = {
      apiKey: "AIzaSyDKjXrw7aErTJqi2UziSdTzVauXbHP3S6U",
      authDomain: "rocketpdv-dev.firebaseapp.com",
      projectId: "rocketpdv-dev",
      databaseURL: "https://rocketpdv-dev.firebaseio.com",
      storageBucket: "rocketpdv-dev.appspot.com",
      messagingSenderId: "954863674958",
      appId: "1:954863674958:web:f4912a2426952adac8051f"
    }
  }



// firebaseConfig = {
//   apiKey: "AIzaSyAjZjY5MIznoFupsxAmnnnHp3zgxAEvqeQ",
//   authDomain: "rock-at-teste.firebaseapp.com",
//   databaseURL: 'https://rock-at-teste.firebaseio.com',
//   projectId: "rock-at-teste",
//   storageBucket: "rock-at-teste.appspot.com",
//   messagingSenderId: "714428458542",
//   appId: "1:714428458542:web:16f65281ecd14c0ff261bf"
// }

// promo prime >>>>>



// Initialize Firebase
firebase.initializeApp(firebaseConfig)

export default firebase
