import { Col, Row } from 'antd'
import React from 'react'
import {
  ContainerModal,
  ConatinerTeste,
  Title,
  SmallText,
  ContainerDescription,
  ContainerLogo
} from './style'
import Shopp from '../../assets/images/undraw_business_shop_qw-5-t.svg'
import Master from '../../assets/images/undraw_professor_-8-lrt.svg'
import Pipiline from '../../assets/images/undraw_timeline_re_aw6g.svg'
import Analitcs from '../../assets/images/undraw_setup_analytics_re_foim.svg'
import Incentive from '../../assets/images/undraw_gift_re_qr17.svg'
import PdvManager from '../../assets/images/PDV.svg'
import Supervisor from '../../assets/images/supervisor10.svg'
import Pipeline from '../../assets/images/pipeline.svg'
import Analytics from '../../assets/images/Analytics11.svg'
import IncentiveLogo from '../../assets/images/incentive2.svg'

interface IProps {
  visible: boolean
  close: (boolean: any) => void
  type: string
}

export const ModalMenu = ({ visible, close, type }: IProps) => {  

  return (
    <ContainerModal
      visible={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
      width={'80vw'}
    >
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <ContainerDescription>
            <h3>
              Conheça mais sobre o produto {type}, com ele você resolve grande parte dos seus problemas.
            </h3>
            {type === 'pdv maneger' && <img src={Shopp} alt="shop" />}
            {type === 'supervisor' && <img src={Master} alt="shop" />}
            {type === 'pipeline' && <img src={Pipiline} alt="shop" />}
            {type === 'analytics' && <img src={Analitcs} alt="shop" />}
            {type === 'incentive' && <img src={Incentive} alt="shop" />}
          </ContainerDescription>
        </Col>
        <Col span={12}>
          <ConatinerTeste>
            <Title>
              Solicite uma demonstração
            </Title>
            <SmallText>
              Vimos que você tem interesse em conhecer {type}.
            </SmallText>
            <SmallText>
              Para solicitar essa demonstração basta nos enviar um email para o seguinte endereço:
              <a href="mailto:comercial@rock-at.com">comercial@rock-at.com</a>
            </SmallText>
            <ContainerLogo>
              {type === 'pdv maneger' && <img src={PdvManager} alt="PdvManager" />}
              {type === 'supervisor' && <img src={Supervisor} alt="Supervisor" />}
              {type === 'pipeline' && <img src={Pipeline} alt="Pipeline" />}
              {type === 'analytics' && <img src={Analytics} alt="Analytics" />}
              {type === 'incentive' && <img src={IncentiveLogo} alt="IncentiveLogo" />}
              <p>by <b>Rock-at.</b></p>
            </ContainerLogo>
          </ConatinerTeste>
        </Col>
      </Row>
        
    </ContainerModal>
  )
}
