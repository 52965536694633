import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { Breadcrumb, Button, Checkbox, Col, message, Row } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import Input from '../../../components/form/SimpleInput'
import { Container } from '../../../GlobalStyles'
import history from '../../../routes/history'
import { Loading } from '../../../components/Loading'
import { Mixpanel } from '../../../utils/mixpanel'
import { InputCheckbox, LabelCheck } from './styles'
import api_v2 from '../../../services/api-v2'
import { IGlobalResponse, INIT_GLOBAL_RESPONSE } from '../../../utils/global-response'

interface Removal {
  id: string
}
interface editRemoval extends IGlobalResponse {
  result: Removal[]
}

const RemovalCreatePage: React.FC = () => {
  const { id }: any = useParams()
  const [Removal, setRemoval] = useState<editRemoval>(INIT_GLOBAL_RESPONSE)
  const [checked, setChecked] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const formRef = useRef<FormHandles>(null)

  async function handleSubmit(body: any) {
    setLoading(true)
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('* O nome é obrigatório'),
        // reazon: Yup.string().required('* O motivo é obrigatório'),
      })

      await schema.validate(body, {
        abortEarly: false,
      })

      const { data } = await api_v2[id ? 'put' : 'post'](`/removal${id ? `/${id}` : ''}`, {
        ...body,
        is_active: checked,
      })

      if (data) {
        message.success(id ? 'Atualizou afastamento com sucesso' : 'Criou motivo de afastamento com sucesso.')
        Mixpanel.track('Criou motifo de afastamento', {
          params: {
            is_active: checked,
            ...body,
          },
        })
        setTimeout(() => {
          history.push('/backoffice/register/removal')
        }, 1000)
      } else {
        message.error(`Erro ao ${id ? 'atualizar' : 'criar'} motivo de afastamento`)
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((el: any) => {
          formRef.current!.setFieldError(el.path, el.message)
        })
      }
    }
    setLoading(false)
  }

  const getRemoval = async () => {
    setLoading(true)
    try {
      const { data } = await api_v2.get(`/removal/${id}`)
      let body = data[0]
      formRef.current!.setFieldValue('name', body.name ? body.name : '')
      formRef.current!.setFieldValue('reazon', body.reazon ? body.reazon : '')
      setRemoval(body)
      setChecked(body.is_active)
    } catch (error) {
      message.error('Erro ao buscar motivo.')
    }
    setLoading(false)
  }
  useEffect(() => {
    if (id) getRemoval()
  }, [id])

  return (
    <Container>
      {loading ? <Loading /> : null}
      <Row>
        <Col span={12}>
          <h1>Motivo de afastamento </h1>
          {/*<p>Insira os dados para realizar o cadastro</p>*/}
          <Breadcrumb separator='>' className='breadcumb'>
            <Link to='/backoffice'>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
            </Link>
            <Link to='/backoffice/register/removal'>
              <Breadcrumb.Item>Motivo de afastamento</Breadcrumb.Item>
            </Link>
            <Breadcrumb.Item>Cadastro</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Form className='form' ref={formRef} onSubmit={handleSubmit} initialData={Removal}>
        <Row gutter={20}>
          <Col span={10}>
            <Input type='text' label='Nome' name='name' />
          </Col>
          {/* <Col span={10}>
            <Input type='text' label='Motivo de cadastro' name='reazon' />
          </Col> */}
          <Col span={4}>
            <LabelCheck>Ativo?</LabelCheck>
            <InputCheckbox>
              <Checkbox
                name='is_active'
                checked={checked}
                defaultChecked={true}
                onChange={(e: any) => {
                  setChecked(e.target.checked)
                }}
              />
            </InputCheckbox>{' '}
          </Col>
        </Row>
        <Row>
          <Col>
            <Button type='primary' htmlType='submit'>
              {id ? 'Atualizar' : 'Cadastrar'}
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  )
}

export default RemovalCreatePage
