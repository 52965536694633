import { Col, Row, Input, Alert, message } from 'antd'
import React, { useState } from 'react'
import { Body, ContainerLogo, ContainerModal } from './style'
import { BulbOutlined, InboxOutlined } from '@ant-design/icons'
import { BigButton } from '../../GlobalStyles'
import Dragger from 'antd/lib/upload/Dragger'

interface IProps {
  visible: boolean
  close: (boolean: any) => void
}

export const ModalSugestion = ({ visible, close }: IProps) => {
  const [idea, setIdea] = useState({
    title: '',
    description: '',
    file: [],
  })
  const [alert, setAlert] = useState(false)

  const createSugestion = async () => {
    if (!idea) return setAlert(true)
  }

  const props = {
    name: 'file',
    multiple: true,
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    onChange(info: any) {
      const { status } = info.file
      if (status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`)
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
    onDrop(e: any) {
      console.log('Dropped files', e.dataTransfer.files)
    },
  }

  return (
    <ContainerModal
      visible={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
    >
      {alert && (
        <Alert message={'Por favor, preencha sua ideia.'} type='error' showIcon style={{ marginBottom: '20px' }} />
      )}
      <Body>
        <ContainerLogo>
          <BulbOutlined />
        </ContainerLogo>
        <h2>Fábrica de ideias</h2>
        <h3>Compartilhe sua ideia conosco.</h3>
        <Row style={{ width: '100%', marginTop: 15 }} gutter={[20, 20]}>
          <Col span={24}>
            <p style={{ color: '#6f7783', marginBottom: 5 }}>Título:</p>
            <Input
              value={idea.title}
              onChange={(a: any) => setIdea({ ...idea, title: a.target.value })}
              style={{ width: '100%' }}
            />
          </Col>
          <Col span={24}>
            <p style={{ color: '#6f7783', marginBottom: 5 }}>Diga sua ideia:</p>
            <Input.TextArea
              rows={5}
              value={idea.description}
              onChange={(a: any) => setIdea({ ...idea, description: a.target.value })}
              style={{ width: '100%' }}
            />
          </Col>
          <Col span={24}>
            <Dragger {...props} style={{ height: 50 }}>
              <p className='ant-upload-drag-icon'>
                <InboxOutlined />
              </p>
            </Dragger>
          </Col>
          <Col span={24}>
            <BigButton
              onClick={() => createSugestion()}
              style={{
                width: '100%',
                backgroundColor: '#6d55cc',
                color: '#fff',
                height: 50,
                marginTop: 20,
                fontSize: 15,
                fontWeight: 'bold',
              }}
            >
              Enviar
            </BigButton>
          </Col>
        </Row>
      </Body>
    </ContainerModal>
  )
}
