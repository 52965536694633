import styled, { css } from 'styled-components'

interface Props {
  isDragging: boolean;
}

export const ContainerDash = styled.div`
  width: 100%;
  height: 300px;
  border: 2px dashed lightgray;
  border-radius: 15px;
`

export const ContainerDrag = styled.div<Props>`
  width: 100%;
  height: 300px;
  border: 1px solid lightgray;
  border-radius: 15px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  :hover { 
    transition: all 0.3s;
    box-shadow: 0px 1px 5px 0px #6600cd;

    .containerExpanded {
      transition: all 0.3s;
      opacity: 1;
    }
    
  }

  .containerExpanded {
    height: 60px;
    width: 25px;
    opacity: 0;
    position: absolute;
    right: -5px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: lightgray;
      font-size: 12px;
    }
  }

  .isDraggingGraph{
    width: 100%;
    height: 100%;
  }

  ${(props: any) => props.isDragging && css `
    border: 2px dashed rgba(0,0,0,0.2);
    .isDraggingGraph {
      opacity: 0.05;
    }
  `}
`

export const More = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6600cd;
  cursor: pointer;

  :hover {
    background-color: #8429e0;
    transition: all 0.3s;
  }

  svg {
      color: lightgray;
      font-size: 12px;
    }
`

export const Less = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6600cd;
  border-right: 1px solid #fff;
  cursor: pointer;

  :hover {
    background-color: #8429e0;
    transition: all 0.3s;
  }

  svg {
      color: lightgray;
      font-size: 12px;
    }
`