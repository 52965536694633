/* eslint-disable array-callback-return */
import { Col, Row, Table, Modal, Tooltip, Image as ImageAntd } from 'antd'
import { useEffect, useState } from 'react'
import { MdBattery50, MdBatteryChargingFull, MdEmail, MdPhone } from 'react-icons/md'
import { useParams } from 'react-router'
import { IUserInfo } from '../../../components/CardUser'
import { Loading } from '../../../components/Loading'
import api from '../../../services/api'
import { ModalReportSurvey } from '../../../components/ModalReportSurvey'
import { FaThumbtack } from 'react-icons/fa'
import {
  Active,
  ContainerHeader,
  ContainerStatus,
  Name,
  ContainerInfo,
  CardCarrocel,
  ContainerData,
  ContainerActionBack,
  ButtonBack,
  ContainerInfoGeral,
  DataInfo,
  Button,
  StrongText,
  LightText,
  ContainerDataInfo,
  ContainerListImg,
  ContainerNoCheckIn,
  ContainerNoPhoto,
} from './styles'
import GoogleMapReact from 'google-map-react'
import { FormOutlined, InfoCircleOutlined, LeftOutlined, CaretRightOutlined, EyeOutlined, PictureOutlined, AppstoreAddOutlined } from '@ant-design/icons'
import { CardStatus } from '../../../components/TabGeneralUser/style'
import { ContainerChart, ContainerDataChart, ContainerStatusChart } from '../../../components/TabsVisits/style'
import {
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  Tooltip as TooltipRechart,
  XAxis,
  YAxis,
} from 'recharts'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import api_v2 from '../../../services/api-v2'
import Map from '../../../assets/images/undraw_adventure_map_hnin.svg'
import Photo from '../../../assets/images/undraw_mobile_photos_psm5.svg'
import { LoadingPicture } from '../../../components/LoadingPicture'
import DetailSurvey2 from '../../../components/DetailSurvey2'
import { BsFlagFill } from 'react-icons/bs'
import { ContainerStep2 } from '../../../components/ModalSubWorkspace/style'
import { ContainerLogo } from '../../../components/Menu/styles'
import { ContainerBodyModal } from '../../../components/ModalCreateTask/style'
export interface IDetailVisit {
  id: number
  dt_visit: string
  status: string
  pdv: {
    id: number
    name: string
    latitude: string
    longitude: string
  }
  promoter: {
    id: number
    name: string
  }
  check_in: {
    date: string | null
    photo: string | null
    latitude: any
    longitude: any
    battery: string | null
  }
  check_out: {
    date: string | null
    photo: string | null
    latitude: any
    longitude: any
    battery: string | null
  }
  minutes_in_visit: string | null
  travel_in_minute: string | null
  expires_date: string | null
  sync_firebase_date: string | null
  created_at: string | null
}

interface IGallery {
  imgs: string[]
  form: string
  product: string
}

interface IAns {
  id: number
  field_name: string
  product_name?: string
  product_id?: number
  field_type: string
  field_order: string
  field_value: string[]
}

export interface IAnswer {
  form_id: number
  form_name: string
  form_context: string
  collected_date: string
  answer_id?: number
  answer: IAns[]
  sub_workspaces?: string
}

export const VisitsDetail = () => {
  const COLORS = ['#0088FE', '#00C49F']
  const { userId, visitId }: any = useParams()
  const [loading, setLoading] = useState(false)
  const [batterie, setBatterie] = useState<any>()
  const [user, setUser] = useState<IUserInfo>({} as IUserInfo)
  const [viewAnswers, setViewAnswers] = useState<any>({})
  const [visibleReport, setVisibleReport] = useState<boolean>(false)
  const [viewJustifye, setViewJustify] = useState<boolean>(false)
  const [dataBattery, setDataBattery] = useState<any>([{ name: '', Bateria: 0 }])
  const [gallery, setGallery] = useState<any[]>([
    {
      imgs: '',
      form: '',
      product: '',
    },
  ])
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [visiblePicture, setVisiblePicture] = useState<boolean>(false)
  const [currentPicture, setCurrentPicture] = useState<number>(0)
  const [visiblePictureCheckOut, setVisiblePictureCheckOut] = useState<boolean>(false)
  const [visiblePictureCheckIn, setVisiblePictureCheckIn] = useState<boolean>(false)
  const [detailVisit, setDetailVisit] = useState<IDetailVisit>({} as IDetailVisit)
  const [aswer, setAnswer] = useState<IAnswer[]>()
  const [justifyes, setJustifyes] = useState<any>([])
  const [completeTasks, setCompleteTasks] = useState<any>([
    { name: 'Pendente', value: 0 },
    { name: 'Completa', value: 0 },
  ])
  const [visitList, setVisitList] = useState({
    result: [],
    filter: {},
    perpage: 0,
    page: 0,
    total: 0,
    loading: false,
    loadingTable: false,
  })
  const historyBack = useHistory()


  function optimizeData(data: any[]): IAnswer[] {
    const answerMap: { [key: number]: IAnswer } = {};

    data.forEach((el: any) => {
      const formId = el.form?.id;

      if (formId !== undefined) {
        if (!answerMap[formId]) {
          answerMap[formId] = {
            form_context: el.form.context,
            form_id: formId,
            form_name: el.form.name,
            collected_date: el.collected_date,
            answer_id: el.id,
            sub_workspaces: el.sub_workspaces,
            answer: [],
          };
        }

        answerMap[formId].answer.push(
          el.answers.map((el2: any) => ({
            ...el2,
            product_name: el.form.context === 'PRODUCT' ? el.product.name : undefined,
            product_id: el.form.context === 'PRODUCT' ? el.product.id : undefined,
            collected_date: el.collected_date,
            answer_id: el.id,
            sub_workspaces: el.sub_workspaces
          }))
        );
      }
    });

    // Convertendo o mapa de volta para um array
    const filter: IAnswer[] = Object.values(answerMap);

    return filter;
  }

  const getJustifyes = async () => {
    try {
      const { data } = await api_v2.get(`/visit/sub-workspaces-justifies/${visitId}`)
      setJustifyes(data)
    } catch (error) {

    }
  }

  useEffect(() => {
    getJustifyes()
    const loadPerfis = async () => {
      setLoading(true)
      const { data } = await api.get(`/user/${userId}`)
      setUser(data)
      setLoading(false)
    }

    const loadAnswer = async () => {
      setLoading(true)
      setVisitList({ ...visitList, loadingTable: true })
      const { data } = await api_v2.get(`/visit/${visitId}/answers`)
      let filter: IAnswer[] = optimizeData(data)
      setAnswer(filter)
      setVisitList({ ...visitList, loadingTable: false })
      setLoading(false)
    }

    const loadVisit = async () => {
      setLoading(true)
      const { data } = await api_v2.get(`/visit/${visitId}`)
      setDataBattery([
        {
          name: 'Check-in',
          Bateria: data.check_in.battery ? parseInt(data.check_in.battery) : 0,
        },
        {
          name: 'Check-out',
          Bateria: data.check_out.battery ? parseInt(data.check_out.battery) : 0,
        },
      ])
      setDetailVisit(data)
      setLoading(false)
    }

    const loadTasks = async () => {
      setLoading(true)
      const { data } = await api_v2.get(`/total/visits/tasks/${visitId}`)
      setCompleteTasks([
        { name: 'Pendente', value: data.pendent },
        { name: 'Completa', value: data.complete },
      ])
      setLoading(false)
    }


    const loadBatterie = async () => {
      setLoading(true)
      const { data } = await api_v2.get(`/batterie/${userId}`)
      setBatterie(data)
    }
    const loadPhoto = async () => {
      setLoading(true)
      const { data } = await api_v2.get(`visit/pictures/${visitId}`)
      setGallery(data)
      setLoading(false)
    }

    loadPerfis()
    loadVisit()
    loadTasks()
    loadPhoto()
    loadAnswer()
    loadBatterie()
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitId])

  const handleNext = (id: string) => {
    let index = 0
    if (aswer) {
      aswer.forEach((el: any, newIndex: number) => {
        if (el.form_id === id) index = newIndex
      })
      if (index + 1 === aswer.length) {
        setOpenModal(false)

        setTimeout(() => {
          setOpenModal(true)
          setViewAnswers([aswer[0]])
        }, 100)
      } else {
        setTimeout(() => {
          setOpenModal(true)
          setViewAnswers([aswer[index + 1]])
        }, 100)
        // setViewAnswers([aswer[index + 1]])
      }
    }
  }

  const handleBack = (id: string) => {
    let index = 0
    if (aswer) {
      aswer.forEach((el: any, newIndex: number) => {
        if (el.form_id === id) index = newIndex
      })
      if (index - 1 === -1) {
        setOpenModal(false)

        setTimeout(() => {
          setOpenModal(true)
          setViewAnswers([aswer[aswer.length - 1]])
        }, 100)
      } else {
        setOpenModal(false)

        setTimeout(() => {
          setOpenModal(true)
          setViewAnswers([aswer[index - 1]])
        }, 100)
      }
    }
  }

  const columns: any = [
    {
      title: 'Id',
      dataIndex: 'form_id',
      key: 'form_id',
      width: 130,
    },
    {
      title: 'Formulário',
      dataIndex: 'form_name',
      key: 'form_name',
    },
    {
      title: 'Contexto',
      dataIndex: 'form_context',
      key: 'form_context',
      render: (data: any) => (
        <p>{data === 'OTHER' ? 'OUTROS' : data === 'STORE' ? 'LOJA' : data === 'CATEGORY' ? 'CATEGORIA' : data === 'RUPTURE' ? 'RUPTURA' : 'PRODUTO'}</p>
      ),
    },
    {
      title: 'Data de coleta',
      dataIndex: 'collected_date',
      key: 'collected_date',
    },
    {
      title: <CaretRightOutlined />,
      fixed: 'right',
      width: 60,
      render: (data: any, view: any) => (
        <div
          style={{ width: '100%', height: '100%', fontSize: 18, cursor: 'pointer' }}
          onClick={() => {
            setViewAnswers([view])
            setOpenModal(true)
          }}
        >
          <EyeOutlined style={{ color: '#b56aff' }} />
        </div>
      ),
    },
  ]

  const TaskPending = () => {
    if (completeTasks && completeTasks.some(task => task.name === 'Pendente' && task.value !== 0)) {
      return true;
    }
    return false;
  };


  const getUrls = (obj: any) => {
    if (typeof obj === 'string') {
      return <ImageAntd src={obj} />
    }
  }

  const AnyReactComponent = (props: any) => {
    return (
      <Tooltip placement='top' title={props.status} color={props.status === 'Check-in' ? '#7158c1' : '#00b894'}>
        <FaThumbtack size={30} color={props.status === 'Check-in' ? '#7158c1' : '#00b894'} />
      </Tooltip>
    )
  }

  const PinPDV = ({ text, status }: any) => (
    <Tooltip placement='top' title={`PDV: ${text} ${status == 'PENDENT' ? ' | Pendente ' : ''}`}>
      <BsFlagFill
        fontSize='25px'
        color={
          status === 'COMPLETE'
            ? 'rgb(0, 196, 159)'
            : status === 'IN_PROGRESS'
              ? 'rgb(255, 187, 40)'
              : status === 'JUSTIFIED_ABSENCE'
                ? 'rgb(255, 128, 66)'
                : 'rgb(0, 136, 254)'
        }
      />
    </Tooltip>
  )

  return (
    <div>
      {loading && <Loading />}
      <Row justify='space-between' style={{ marginBottom: 30 }}>
        <Col span={20}>
          <ContainerHeader>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {detailVisit.promoter?.name && (
                <img
                  src={`https://via.placeholder.com/150/ff872c/fff/?text=${detailVisit.promoter?.name?.substr(0, 1)}`}
                  alt='user'
                />
              )}
              <ContainerStatus>
                <Name>{detailVisit.promoter?.name}</Name>
              </ContainerStatus>
            </div>
            {user.is_active ? (
              <Active>Ativo</Active>
            ) : (
              <Active style={{ color: '#e9830f', borderColor: '#e9830f', backgroundColor: '#edbf95' }}>Inativo</Active>
            )}
            {batterie && batterie.battery &&
              <Tooltip title={`Nível de bateria ${batterie.battery}%`}>
                <MdBattery50 style={{ fontSize: 18, marginLeft: 10 }} />
              </Tooltip>
            }

            <Col style={{ marginLeft: 10 }}>
              {TaskPending() && (
                <Tooltip title="Reportar uma pesquisa">
                  <Button
                    style={{
                      background: '#6600cc',
                      display: 'flex',
                      alignItems: 'center',
                      color: '#fff',
                      gap: 2,
                      borderRadius: 6,
                      fontSize: 12,
                      padding: 5,
                      border: 'none'
                    }}
                    onClick={() => setVisibleReport(!visibleReport)}
                  >
                    <AppstoreAddOutlined size={20} />
                  </Button>
                </Tooltip>
              )}
            </Col>
            {justifyes && justifyes.length > 0 &&
              <Col style={{ marginLeft: 10 }}>
                <Tooltip title="Visualizar justificativas">
                  <Button
                    style={{
                      background: '#cc0000',
                      display: 'flex',
                      alignItems: 'center',
                      color: '#fff',
                      gap: 2,
                      borderRadius: 6,
                      fontSize: 12,
                      padding: 5,
                      border: 'none'
                    }}
                    onClick={() => setViewJustify(!viewJustifye)}
                  >
                    <InfoCircleOutlined size={20} />
                  </Button>
                </Tooltip>
              </Col>
            }

          </ContainerHeader>
          <ContainerData>
            <ContainerInfo>
              <MdPhone />
              {user.phone ? user.phone : 'Não cadastrado'}
            </ContainerInfo>
            <ContainerInfo>
              <MdEmail />
              {user.email ? user.email : 'Não cadastrado'}
            </ContainerInfo>
            <ContainerInfo>
              <p>CPF</p>
              xxx.xxx.xxx-xx
            </ContainerInfo>
          </ContainerData>
        </Col>
        <Col span={4}>
          <ContainerActionBack>
            <ButtonBack onClick={() => historyBack.goBack()}>
              <LeftOutlined />
              Voltar
            </ButtonBack>
          </ContainerActionBack>
        </Col>
      </Row>

      <ContainerInfoGeral>
        <Name>Dados Gerais da Visita</Name>
        {detailVisit.status === 'COMPLETE' && <Active>Complete</Active>}
        {detailVisit.status === 'PENDENT' && (
          <Active style={{ color: '#e9830f', borderColor: '#e9830f', backgroundColor: '#edbf95' }}>PENDENTE</Active>
        )}
        {detailVisit.status === 'IN_PROGRESS' && (
          <Active style={{ color: '#e9830f', borderColor: '#e9830f', backgroundColor: '#edbf95' }}>EM PROGRESSO</Active>
        )}
        <DataInfo>
          <ContainerDataInfo>
            <StrongText>ID:</StrongText>
            <LightText>{detailVisit.id} </LightText>
          </ContainerDataInfo>
          <Row gutter={[20, 6]}>
            <Col span={12}>
              <ContainerDataInfo>
                <StrongText>Loja:</StrongText>
                <LightText>{detailVisit.pdv?.name}</LightText>
              </ContainerDataInfo>
            </Col>
            <Col span={12}>
              <ContainerDataInfo>
                <StrongText>Data RECEBIMENTO:</StrongText>
                <LightText>{moment(detailVisit.created_at).format('DD/MM/YYYY HH:mm:ss')}</LightText>
              </ContainerDataInfo>
            </Col>
            <Col span={12}>
              <div style={{ display: 'flex' }}>
                <ContainerDataInfo style={{ marginRight: 50 }}>
                  <StrongText>Check in:</StrongText>
                  <LightText>
                    {detailVisit.check_in?.date == null
                      ? '-'
                      : moment(detailVisit.check_in.date).format('DD/MM/YYYY HH:mm:ss')}
                  </LightText>
                  {detailVisit.check_in?.photo && (
                    <PictureOutlined
                      style={{ fontSize: 25, marginLeft: 15, cursor: 'pointer', color: '#8f61e2' }}
                      onClick={() => setVisiblePictureCheckIn(true)}
                    />
                  )}
                </ContainerDataInfo>
                <div style={{ display: 'none' }}>
                  <ImageAntd.PreviewGroup
                    preview={{
                      visible: visiblePictureCheckIn,
                      onVisibleChange: (vis) => setVisiblePictureCheckIn(vis),
                    }}
                  >
                    {getUrls(detailVisit.check_in?.photo)}
                  </ImageAntd.PreviewGroup>
                </div>
                <ContainerDataInfo>
                  <StrongText>Check out:</StrongText>
                  <LightText>
                    {detailVisit.check_out?.date == null
                      ? '-'
                      : moment(detailVisit.check_out.date).format('DD/MM/YYYY HH:mm:ss')}
                  </LightText>
                  {detailVisit.check_out?.photo && (
                    <PictureOutlined
                      style={{ fontSize: 25, marginLeft: 15, cursor: 'pointer', color: '#8f61e2' }}
                      onClick={() => setVisiblePictureCheckOut(true)}
                    />
                  )}
                  <div style={{ display: 'none' }}>
                    <ImageAntd.PreviewGroup
                      preview={{
                        visible: visiblePictureCheckOut,
                        onVisibleChange: (vis) => setVisiblePictureCheckOut(vis),
                      }}
                    >
                      {getUrls(detailVisit.check_out?.photo)}
                    </ImageAntd.PreviewGroup>
                  </div>
                </ContainerDataInfo>
              </div>
            </Col>
            <Col span={12}>
              <ContainerDataInfo>
                <StrongText>Data de solicitação:</StrongText>
                <LightText>{moment(detailVisit.dt_visit).format('DD/MM/YYYY')}</LightText>
              </ContainerDataInfo>
            </Col>
            <Col span={12}>
              <ContainerDataInfo>
                <StrongText>Criado em:</StrongText>
                <LightText>{moment(detailVisit.created_at).format('DD/MM/YYYY HH:mm:ss')}</LightText>
              </ContainerDataInfo>
            </Col>
            <Col span={12}>
              <ContainerDataInfo>
                <StrongText>Data de expiração:</StrongText>
                <LightText>{moment(detailVisit.expires_date).format('DD/MM/YYYY HH:mm:ss')}</LightText>
              </ContainerDataInfo>
            </Col>
            <Col span={12}>
              <ContainerDataInfo>
                <StrongText>Tempo em loja:</StrongText>
                <LightText>
                  {detailVisit.minutes_in_visit == null ? '-' : `${detailVisit.minutes_in_visit} Minutos`}
                </LightText>
              </ContainerDataInfo>
            </Col>
            <Col span={12}>
              <ContainerDataInfo>
                <StrongText>Tempo de deslocamento:</StrongText>
                <LightText>
                  {detailVisit.travel_in_minute == null
                    ? '-'
                    : `${moment(detailVisit.travel_in_minute).format('H:mm:s')} Hrs`}
                </LightText>
              </ContainerDataInfo>
            </Col>
          </Row>
        </DataInfo>
      </ContainerInfoGeral>
      <Row gutter={[20, 20]} style={{ marginTop: 30 }}>
        <Col span={17}>
          <Row gutter={[20, 20]}>
            <Col span={12}>
              <CardStatus>
                <StrongText>Gráfico de Tarefas</StrongText>
                <LightText>Quantidade de atividades realizadas durante a visita.</LightText>

                <ContainerChart style={{ justifyContent: 'center' }}>
                  <PieChart width={200} height={200}>
                    <Pie
                      data={completeTasks}
                      cx={'50%'}
                      cy={'50%'}
                      innerRadius={60}
                      outerRadius={80}
                      fill='#8884d8'
                      paddingAngle={10}
                      dataKey='value'
                    >
                      {completeTasks.map((entry: any, index: number) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                  </PieChart>
                  <ContainerStatusChart>
                    {completeTasks.length > 0 &&
                      completeTasks.map((entry: any, index: number) => (
                        <ContainerDataChart color={COLORS[index]} key={index}>
                          <div />
                          {entry.name + ' (' + entry.value + ')'}
                        </ContainerDataChart>
                      ))}
                  </ContainerStatusChart>
                </ContainerChart>
              </CardStatus>
            </Col>
            <Col span={12}>
              <CardStatus>
                <StrongText>Gráfico de bateria</StrongText>
                <LightText>Nivel de bateria gasto na visita.</LightText>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <LineChart width={400} height={253} data={dataBattery}>
                    <CartesianGrid strokeDasharray='10 10' />
                    <YAxis domain={[0, 100]} />
                    <XAxis dataKey='name' />
                    <TooltipRechart />
                    <Legend />
                    <Line type='monotone' dataKey='Bateria' stroke='#6600cc' activeDot={{ r: 8 }} strokeWidth={4} />
                  </LineChart>
                </div>
              </CardStatus>
            </Col>
            <Col span={24}>
              <ContainerInfoGeral>
                <StrongText>Gráfico Mapas</StrongText>
                <LightText style={{ marginLeft: 0 }}>
                  Veja as informações da loja, e da localização do promotor.
                </LightText>

                <DataInfo>
                  <ContainerDataInfo>
                    <StrongText>Loja:</StrongText>
                    <LightText>{detailVisit.pdv?.name}</LightText>
                  </ContainerDataInfo>
                  <div style={{ display: 'flex' }}>
                    <div>
                      <ContainerDataInfo style={{ marginRight: 50 }}>
                        <StrongText>Check in:</StrongText>
                        <LightText>
                          {detailVisit.check_in?.date == null
                            ? '-'
                            : moment(detailVisit.check_in.date).format('DD/MM/YYYY HH:mm:ss')}
                        </LightText>
                        {detailVisit.check_in?.photo && (
                          <PictureOutlined
                            style={{ fontSize: 25, marginLeft: 15, cursor: 'pointer', color: '#8f61e2' }}
                            onClick={() => setVisiblePictureCheckIn(true)}
                          />
                        )}

                        <div style={{ display: 'none' }}>
                          <ImageAntd.PreviewGroup
                            preview={{
                              visible: visiblePictureCheckIn,
                              onVisibleChange: (vis) => setVisiblePictureCheckIn(vis),
                            }}
                          >
                            {getUrls(detailVisit.check_in?.photo)}
                          </ImageAntd.PreviewGroup>
                        </div>
                      </ContainerDataInfo>
                      <div style={{ display: 'flex', alignItems: 'center', marginRight: 20 }}>
                        <MdBatteryChargingFull
                          style={{ transform: 'rotateZ(90deg)', fontSize: 20, color: '#b56aff' }}
                        />
                        <LightText>
                          {detailVisit.check_in && detailVisit.check_in.battery ? detailVisit.check_in.battery : 0}%{' '}
                        </LightText>
                      </div>
                    </div>
                    <div>
                      <ContainerDataInfo>
                        <StrongText>Check out:</StrongText>
                        <LightText>
                          {detailVisit.check_out?.date == null
                            ? '-'
                            : moment(detailVisit.check_out.date).format('DD/MM/YYYY HH:mm:ss')}
                        </LightText>
                        {detailVisit.check_out?.photo && (
                          <PictureOutlined
                            style={{ fontSize: 25, marginLeft: 15, cursor: 'pointer', color: '#8f61e2' }}
                            onClick={() => setVisiblePictureCheckOut(true)}
                          />
                        )}
                        <div style={{ display: 'none' }}>
                          <ImageAntd.PreviewGroup
                            preview={{
                              visible: visiblePictureCheckOut,
                              onVisibleChange: (vis) => setVisiblePictureCheckOut(vis),
                            }}
                          >
                            {getUrls(detailVisit.check_out?.photo)}
                          </ImageAntd.PreviewGroup>
                        </div>
                      </ContainerDataInfo>
                      <div style={{ display: 'flex', alignItems: 'center', marginRight: 20 }}>
                        <MdBatteryChargingFull
                          style={{ transform: 'rotateZ(90deg)', fontSize: 20, color: '#b56aff' }}
                        />
                        <LightText>
                          {detailVisit.check_out && detailVisit.check_out.battery
                            ? detailVisit.check_out.battery
                            : detailVisit.check_in
                              ? `${detailVisit.check_in.battery}%`
                              : '-'}
                        </LightText>
                      </div>
                    </div>
                  </div>
                </DataInfo>

                {!isNaN(parseFloat(detailVisit.check_in?.longitude)) ||
                  !isNaN(parseFloat(detailVisit.check_out?.latitude)) ? (
                  <div style={{ height: '400px', width: '100%', marginTop: 20 }}>
                    <GoogleMapReact
                      bootstrapURLKeys={{ key: 'AIzaSyC6eMkMip_m1nZ-iCIDDZEo7VFm3a1mxBA' }}
                      zoom={18}
                      center={{
                        lat: !!!detailVisit.check_in?.latitude
                          ? 0
                          : parseFloat(detailVisit.check_in?.latitude) === 0
                            ? parseFloat(detailVisit.check_in?.latitude)
                            : parseFloat(detailVisit.check_in?.latitude),
                        lng: !!!detailVisit.check_in?.longitude
                          ? 0
                          : parseFloat(detailVisit.check_in?.longitude) === 0
                            ? parseFloat(detailVisit.check_in?.longitude)
                            : parseFloat(detailVisit.check_in?.longitude),
                      }}
                    >
                      {detailVisit.check_in?.latitude && (
                        <AnyReactComponent
                          lat={parseFloat(detailVisit.check_in?.latitude)}
                          lng={parseFloat(detailVisit.check_in?.longitude)}
                          status={'Check-in'}
                        />
                      )}

                      {detailVisit.pdv?.latitude && (

                        <PinPDV
                          lat={parseFloat(detailVisit.pdv?.latitude)}
                          lng={parseFloat(detailVisit.pdv?.longitude)}
                          text={`PDV ${detailVisit.pdv.name}`}
                          status={detailVisit.status}
                        />
                      )}


                      {detailVisit.check_out?.latitude && (
                        <AnyReactComponent
                          lat={parseFloat(detailVisit.check_out?.latitude)}
                          lng={parseFloat(detailVisit.check_out?.longitude)}
                          status={'Check-out'}
                        />
                      )}
                    </GoogleMapReact>
                  </div>
                ) : (
                  <>
                    {detailVisit.status !== 'PENDENT' ?
                      <div style={{ height: '400px', width: '100%', marginTop: 20 }}>
                        <GoogleMapReact
                          bootstrapURLKeys={{ key: 'AIzaSyC6eMkMip_m1nZ-iCIDDZEo7VFm3a1mxBA' }}
                          zoom={18}
                          center={{
                            lat: detailVisit.pdv?.latitude
                            ? parseFloat(detailVisit.pdv?.latitude)
                            : 0,
                            lng: detailVisit.pdv?.longitude
                              ? parseFloat(detailVisit.pdv?.longitude)
                              :0,
                          }}
                        >


                          {detailVisit.pdv?.latitude && (

                            <PinPDV
                              lat={parseFloat(detailVisit.pdv?.latitude)}
                              lng={parseFloat(detailVisit.pdv?.longitude)}
                              text={`PDV ${detailVisit.pdv.name}`}
                              status={detailVisit.status}
                            />
                          )}



                        </GoogleMapReact>
                      </div>
                      :
                      <ContainerNoCheckIn>
                        <h3>O promotor(a) não chegou na loja ainda.</h3>
                        <img src={Map} alt='Map' />
                      </ContainerNoCheckIn>
                    }
                  </>
                )}
              </ContainerInfoGeral>
            </Col>
          </Row>
        </Col>
        <Col span={7}>
          <CardCarrocel style={{ justifyContent: 'flex-start' }}>
            <StrongText style={{ color: '#6600cc' }}>Últimas Fotos</StrongText>
            <LightText>Útimas fotos em loja.</LightText>
            {gallery && gallery.length > 0 ? (
              <ContainerListImg>
                {gallery.map((d: IGallery, index: number) => (
                  <>
                    {d.imgs &&
                      d.imgs.map((el) => (
                        <LoadingPicture
                          el={el}
                          text={d.form}
                          index={index}
                          option={2}
                          handlePictures={() => {
                            setCurrentPicture(index)
                            setVisiblePicture(true)
                          }}
                        />
                      ))}
                  </>
                ))}
                <div
                  style={{ display: 'none' }}
                  onKeyDown={(evt: any) => {
                    if (visiblePicture && evt.key === 'ArrowLeft')
                      setCurrentPicture(currentPicture === 0 ? currentPicture : currentPicture - 1)

                    if (visiblePicture && evt.key === 'ArrowRight')
                      setCurrentPicture(currentPicture === gallery.length - 1 ? currentPicture : currentPicture + 1)
                  }}
                >
                  <ImageAntd.PreviewGroup
                    preview={{
                      visible: visiblePicture,
                      onVisibleChange: (vis) => setVisiblePicture(vis),
                      current: currentPicture,
                    }}
                  >
                    {gallery.map((d: IGallery, index: number) => {
                      if (d.imgs && d.imgs.length > 0) {
                        return d.imgs.map((el, indexPicture) => <ImageAntd src={el} key={indexPicture} />)
                      }
                    })}
                  </ImageAntd.PreviewGroup>
                </div>
              </ContainerListImg>
            ) : (
              <ContainerNoPhoto>
                <h3 style={{ textAlign: 'center' }}>Até o momento não foi tirado nenhuma foto.</h3>
                <img src={Photo} alt='Foto' />
              </ContainerNoPhoto>
            )}
          </CardCarrocel>
        </Col>
      </Row>
      <ContainerInfoGeral style={{ marginTop: 30 }}>
        <StrongText>Pesquisas</StrongText>
        <Table
          rowKey='id'
          dataSource={aswer}
          columns={columns}
          onChange={() => { }}
          loading={visitList.loadingTable || false}
        />
        {openModal && (
          <Modal
            bodyStyle={{ height: '97vh' }}
            width={'97vw'}
            style={{ top: '2vh' }}
            visible={openModal}
            footer={null}
            onCancel={() => setOpenModal(!openModal)}
          >
            <DetailSurvey2
              user={user}
              detailVisit={detailVisit}
              answer={viewAnswers}
              next={handleNext}
              back={handleBack}
            />
          </Modal>
        )}
        {visibleReport && <ModalReportSurvey visible={visibleReport} close={setVisibleReport} visitId={visitId} context={'task'} />}
      </ContainerInfoGeral>

      {justifyes &&
        <Modal
          // bodyStyle={{ height: '97vh' }}
          // width={'97vw'}
          // style={{ top: '2vh' }}
          visible={viewJustifye}
          footer={null}
          onCancel={() => setViewJustify(!viewJustifye)}
        >
          <ContainerStep2>
            <h3>Visualizar justificativas</h3>
            <ContainerLogo style={{ marginBottom: 10 }}>
              <FormOutlined />
            </ContainerLogo>
            <ContainerBodyModal>
              <Table columns={[
                {
                  title: 'Empresa',
                  dataIndex: 'name',
                  key: 'name',
                },
                {
                  title: 'Motivo',
                  dataIndex: 'obs',
                  key: 'obs',
                },
              ]} dataSource={justifyes} pagination={false} />
            </ContainerBodyModal>
          </ContainerStep2>
        </Modal>
      }
    </div>
  )
}
