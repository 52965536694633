import { ContainerModal } from './style'

import { ImportXls } from './ImportXls'

interface IProps {
  visible: boolean
  close: (boolean: any) => void
  title: string
  path: string
  config?: {}
}

export const ModalImportGeneral = ({ visible, close, title, path, config }: IProps) => {
  return (
    <ContainerModal
      visible={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
      destroyOnClose={true}
    >
      <ImportXls close={close} title={title} path={path} config={config} />
    </ContainerModal>
  )
}
