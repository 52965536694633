import styled from 'styled-components'
import CloseIcon from '@material-ui/icons/Close';

export const ContainerConfig = styled.div`
  width: 600px;
  height: 100%;
  border-right: 1px solid rgba(0, 0, 0, 0.07);
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  cursor: auto;
  background: white;
  overflow: auto;
`
export const Header = styled.div`
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  h2{
    font-size: 18px;
    letter-spacing: 2px;
    font-weight: bold;
    margin: 0;
  }
`
export const CloseConfig = styled(CloseIcon)`
  cursor: pointer;
`
export const ContainerOptions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 30px;
  p{
    margin: 0;
    margin-left: 15px;
    font-size: 17px;
  }
`

export const Body = styled.div`
  height: 100%;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-aroud;
  flex-direction: column;

  label{
    text-align: left;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .finshSelect{
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  }
`

export const ContainerRequired = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  p{
    margin-bottom: 0;
    font-size: 16px;
  }
`
export const ContainerDrop = styled.div<any>`
  margin-top: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.07);
  width: 100%;
  
`
export const ContainerPicture = styled.div<any>`
  width: 100%;
  margin-top: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.07);
`
export const CardJump = styled.div`
  width: 100%;
  border-radius: 15px;
  box-shadow: rgb(0 0 0 / 30%) 0px 2px 4px;
  background: rgb(245 245 245);
  padding: 20px;
  margin-bottom: 20px;
  p{
    margin-top: 10px;
    margin-right: 10px;
  }
  b{
    color: red;
  }

  .subText{
    margin-top: 0;
  }
`
export const QuestJump = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid gray;
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 20px;
`

export const ContainerQuestJump = styled.div`
  display: flex;

  p{
    margin: 0;
    margin-left: 15px;
  }
`