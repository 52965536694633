import history from '../routes/history'
import mixpanel from 'mixpanel-browser'

export const TOKEN_KEY = '@rocketpdv:jwt'
export const APP_ROCKET = '@rocketpdv:app'
export const TOKEN_USER = '@rocketpdv:user'
export const TOKEN_DATA_USER = '@rocketpdv:dataUser'
export const WORKSPACE: string = '@rocketpdv:workspace'
export const login = (token: string) => {
  localStorage.setItem(TOKEN_KEY, token)
}

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY)
  localStorage.removeItem(TOKEN_USER)
  localStorage.removeItem(APP_ROCKET)
  localStorage.removeItem(TOKEN_DATA_USER)
  mixpanel.reset()
  history.push('/backoffice/signin')
}

export const isAuthenticated = () => {
  return !!localStorage.getItem(TOKEN_KEY)
}

export const isAuthenticatedaPP = () => {
  return localStorage.getItem(APP_ROCKET)
}

export const getUserLocal = () => {
  return localStorage.getItem(TOKEN_USER)
}

export const getToken = (): string | null => localStorage.getItem(TOKEN_KEY)

export const getWorkspace = () => {
  const workspace = sessionStorage.getItem(WORKSPACE)
  if (workspace !== null && workspace !== undefined && typeof workspace === 'string') {
    return JSON.parse(workspace).slug
  }
  return ''
}

export const getWorkspaceInfo = () => {
  const workspace = sessionStorage.getItem(WORKSPACE)
  if (workspace !== null && workspace !== undefined && typeof workspace === 'string') {
    let convert = JSON.parse(workspace)
    convert.name_user = getUserLocal()
    let user = getDataUser()
    return {
      id: convert.id,
      name: convert.name,
      name_user: convert.name_user,
      user_id:user.id,
      provider: convert.provider,
      slug: convert.slug,
      // avatar_url: convert.avatar_url,
      shared_environment: convert.shared_environment,
      is_active: convert.is_active,
    }
  }
  return ''
}

export const getTypeCollected = () => {
  const workspace = sessionStorage.getItem(WORKSPACE)
  if (workspace !== null && workspace !== undefined && typeof workspace === 'string') {
    return JSON.parse(workspace).type_colect
  }
  return ''
}

export const getWorkspaceId = () => {
  const workspace = sessionStorage.getItem(WORKSPACE)
  if (workspace !== null && workspace !== undefined && typeof workspace === 'string') {
    return JSON.parse(workspace).id
  }
  return ''
}

export const getDataUser = () => {
  const user = localStorage.getItem(TOKEN_DATA_USER)
  if (user) {
    return JSON.parse(user)
  }
  return {}
}

export const getUniqueToken = () => {
  const token = localStorage.getItem(TOKEN_KEY)
  if (token !== null && token !== undefined && typeof token === 'string') {
    return token
  }
  return ''
}
