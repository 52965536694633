import styled from "styled-components"

export const ContainerIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const NextButton = styled.div`
  position: absolute;
  background-color: #00000029;
  height: 260px;
  width: 30px;
  top: -1px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;

  svg{
    color: white;
    font-size: 20px;
  }

  :hover{
    background-color: #00000080;
  }
`

export const PreviusButton = styled.div`
  position: absolute;
  background-color: #00000029;
  height: 260px;
  width: 30px;
  top: -1px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;

  svg{
    color: white;
    font-size: 20px;
  }

  :hover{
    background-color: #00000080;
  }
`

export const ContainerDescription = styled.div`
  display: flex;
  align-items: center;

  h3{
    font-weight: bold;
    font-size: 12px;
    margin: 0;
  }

  p{
    font-size: 12px;
    margin: 0;
    margin-right: 10px;
  }
`