import { DeleteOutlined, EditOutlined, MoreOutlined, DownOutlined } from '@ant-design/icons'
import { Button, Col, Dropdown, Menu, message, Popconfirm, Tooltip } from 'antd'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { isActiveSelect } from '../../../utils/option-user'
import { DataStatus, StatusRealizado } from '../../../components/TabsVisits/style'
import { exportXls } from '../../../utils/funcoes'
import { Mixpanel } from '../../../utils/mixpanel'
import DefaultPage from '../../../components/DefaultPage'
import { hasIndex } from '../../../components/Menu/menuHorizonte'
import { ContextUpload } from '../../../context/contextUpload'
import api_v2 from '../../../services/api-v2'
import { StatusInativo } from './styles'

export interface FlagsFilter {
  name?: string
  network?: string
  is_active?: string
}
const FlagListPage: React.FC = () => {
  const { permissions } = ContextUpload()

  const [loading, setLoading] = useState<boolean>(false)
  const [reload, setReload] = useState<boolean>(false)
  const [selecteds, setSelecteds] = useState<any>([])

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      setSelecteds(selectedRows)
    },
    getCheckboxProps: (record: any) => ({
      disabled: !record.is_active, // Column configuration not to be checked
    }),
  }
  function compareByAlph(a: any, z: any) {
    if (a > z) {
      return -1
    }
    if (a < z) {
      return 1
    }
    return 0
  }

  const getStatus = (status: any) => {
    const listStatus: any = {
      PENDENT: (
        <Tooltip title='Inativa'>
          <StatusInativo />
        </Tooltip>
      ),
      COMPLETE: (
        <Tooltip title='Ativa'>
          <StatusRealizado />
        </Tooltip>
      ),
    }

    if (status === true) {
      return listStatus.COMPLETE
    } else if (status === false) {
      return listStatus.PENDENT
    } else {
      return '-'
    }
  }

  async function handleDelete(id: string) {
    setLoading(true)
    try {
      await api_v2.delete(`/flag/${id}`)
      Mixpanel.track('Inativou bandeira', {
        params: {
          id: id,
        },
      })
      message.success('Inativado com sucesso')
      setReload(true)
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  const deleteMass = async (id: string) => {
    try {
      await api_v2.delete(`/flag/${id}`)
      Mixpanel.track('Inativou bandeira', {
        params: { id: id },
      })
      message.success('Inativado com sucesso')
    } catch (err) {}
  }

  return (
    <DefaultPage
      titlePage='Bandeiras'
      breadcrumb={[{ name: 'Home', url: '/' }, { name: 'Bandeiras' }]}
      urlPage='/backoffice/store/flags'
      routeApi={{
        method: 'get',
        route: '/flag',
        versionApi: 'v1',
        reload,
      }}
      listButtons={{
        buttonCreate: {
          visible: hasIndex(['store.add_flag'], permissions) > -1,
          type: 'url',
          url: '/backoffice/store/flags/create',
        },
        buttonExport: {
          visible: hasIndex(['store.export_flag'], permissions) > -1,
          type: 'function',
          function: async () => {
            setLoading(true)
            await exportXls({}, 'flags')
            setLoading(false)
          },
        },
        buttonimport: {
          visible: hasIndex(['store.import_flag'], permissions) > -1,
          path: 'FLAG',
        },
        buttonFilter: {
          visible: true,
          filters: [
            {
              label: 'Selecione a bandeira:',
              name: 'flag',
              type: 'search',
              urlSearch: 'flags',
              getEnv: 'label',
            },
            {
              label: 'Selecione o status',
              name: 'is_active',
              type: 'select',
              options: isActiveSelect,
              getEnv: 'value',
            },
          ],
        },
        newButton: {
          visible: selecteds.length > 0 ? true : false,
          children: (
            <Col span={5}>
              <Dropdown
                overlay={
                  <Menu>
                    {hasIndex(['store.remove_flag'], permissions) > -1 && (
                      <Menu.Item key='1' icon={<DeleteOutlined />}>
                        <Popconfirm
                          title='Tem certeza que deseja inativar estas redes?'
                          onConfirm={async () => {
                            setLoading(!loading)

                            for await (let flag of selecteds) {
                              Mixpanel.track('Confirmou inativar bandeira', flag)
                              await deleteMass(flag.id)
                            }
                            setLoading(!loading)
                            setReload(!reload)
                          }}
                          okText='Sim'
                          cancelText='Não'
                          placement='topRight'
                        >
                          Inativar bandeira
                        </Popconfirm>
                      </Menu.Item>
                    )}
                  </Menu>
                }
              >
                <Button>
                  Ações em massa ({selecteds.length}) <DownOutlined />
                </Button>
              </Dropdown>
            </Col>
          ),
        },
      }}
      rowSelection={{
        type: 'checkbox',
        ...rowSelection,
      }}
      load={{ loading, setLoading }}
      columns={[
        {
          title: 'Status',
          dataIndex: 'is_active',
          key: 'status',
          width: 80,
          render: (status: any) => {
            return <DataStatus>{getStatus(status)}</DataStatus>
          },
        },
        {
          title: 'Nome',
          dataIndex: 'name',
          sorter: (a: any, b: any) => compareByAlph(a.name, b.name),
        },
        {
          title: '#',
          width: 50,
          render: (obj: any) => {
            if (hasIndex(['store.remove_flag', 'store.change_flag'], permissions) === -1) return undefined
            return (
              <Dropdown
                overlay={
                  <Menu>
                    {hasIndex(['store.remove_flag'], permissions) > -1 && (
                      <Menu.Item key='1' icon={<DeleteOutlined />}>
                        <Popconfirm
                          onConfirm={() => handleDelete(obj.id)}
                          title='Tem certeza que deseja inativar esta bandeira ?'
                          okText='Sim'
                          cancelText='Não'
                          placement='topRight'
                        >
                          Inativar bandeira
                        </Popconfirm>
                      </Menu.Item>
                    )}
                    {hasIndex(['store.change_flag'], permissions) > -1 && (
                      <Link to={`/backoffice/store/flags/edit/${obj.id}`}>
                        <Menu.Item key='2' icon={<EditOutlined />}>
                          Editar
                        </Menu.Item>
                      </Link>
                    )}
                  </Menu>
                }
              >
                <Button size='small' shape='circle' icon={<MoreOutlined />} />
              </Dropdown>
            )
          },
        },
      ]}
    />
  )
}
export default FlagListPage
