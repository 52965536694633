import { Button, Col, Row, List, Avatar, message } from 'antd'
import Input from '../../../../components/form/SimpleInput'
import React, { useEffect, useRef, useState } from 'react'
import { SubContainer, UploadImage } from '../styles'
import { MdFileUpload } from 'react-icons/md'
import { DeleteOutlined } from '@ant-design/icons'
import { IPhoto } from './step1'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'

export interface ISubWorkspace {
  name: string
  photo: IPhoto
}

interface IDataStep3 {
  setData3: (data: ISubWorkspace[]) => void
}

const Step3: React.FC<IDataStep3> = ({ setData3 }: IDataStep3) => {
  const formRef = useRef<FormHandles>(null)
  const [nameSubWorkspace, setNameSubWorkspace] = useState<string>('')
  const [imageSub, setImageSub] = useState<IPhoto>({ preview: '', raw: '' })
  const [dataSubWorkspaces, setSubWorkspace] = useState<ISubWorkspace[]>([])

  const getSubWorkspace = () => {
    if (!nameSubWorkspace) {
      message.error('Nome do subworkspace é obrigatório')
      return
    }
    setSubWorkspace([...dataSubWorkspaces, { name: nameSubWorkspace, photo: imageSub }])
    setNameSubWorkspace('')
    setImageSub({ preview: '', raw: '' })
  }

  const removeWorkspace = (name: string) => {
    let newSubWOrkspace = dataSubWorkspaces.filter((el: ISubWorkspace) => el.name !== name)
    setSubWorkspace(newSubWOrkspace)
  }
  const handleChangeSub = (e: any) => {
    setImageSub({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0],
    })
  }

  useEffect(() => {
    setData3(dataSubWorkspaces)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSubWorkspaces])

  return (
    <Form
      translate={undefined}
      className='form'
      ref={formRef}
      initialData={{ check_in_obrigatorio: true }}
      onSubmit={(el: any) => console.log(el)}
    >
      <SubContainer>
        <Row gutter={[0, 50]}>
          <Col span={16}>
            <Row gutter={[0, 50]} justify='space-around'>
              <Col span={6}>
                <Input
                  type='text'
                  label='Nome do workspace'
                  name='name'
                  placeholder='Nome'
                  value={nameSubWorkspace}
                  onChange={(el: any) => setNameSubWorkspace(el.target.value)}
                />
              </Col>
              <Col span={6}>
                <UploadImage>
                  <label htmlFor='upload-button-sub'>
                    {imageSub.preview ? (
                      <img src={imageSub.preview} width='300' height='300' alt='img' />
                    ) : (
                      <>
                        <span className='fa-stack fa-2x mt-3 mb-2'>
                          <MdFileUpload size={30} />
                        </span>
                        <h5 className='text-center'>Upload</h5>
                      </>
                    )}
                  </label>
                  <input
                    type='file'
                    name='avatar_url'
                    id='upload-button-sub'
                    style={{ display: 'none' }}
                    onChange={handleChangeSub}
                  />
                </UploadImage>
              </Col>
              <Col span={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Button
                  type='primary'
                  htmlType='button'
                  style={{ backgroundColor: '#7158c1', border: 'none' }}
                  onClick={() => getSubWorkspace()}
                >
                  Cadastrar subworkspaces
                </Button>
              </Col>
            </Row>
          </Col>

          <Col span={6} style={{ borderLeft: '1px solid lightgray', paddingLeft: 20 }}>
            <List
              itemLayout='horizontal'
              header={<h4 style={{ textAlign: 'center' }}>Subworkspaces criados</h4>}
              dataSource={dataSubWorkspaces}
              renderItem={(item) => (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: 10 }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar src={item.photo.preview} style={{ width: 60, borderRadius: 0, height: 60 }} />
                    <p style={{ margin: '0 15px' }}>{item.name}</p>
                  </div>
                  <DeleteOutlined
                    style={{ fontSize: 17, color: 'red', cursor: 'pointer' }}
                    onClick={() => removeWorkspace(item.name)}
                  />
                </div>
              )}
            />
          </Col>
        </Row>
      </SubContainer>
    </Form>
  )
}

export default Step3
