import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import { Breadcrumb, Button, Col, message, Row, Checkbox, Collapse } from 'antd'
import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import Input from '../../../components/form/SimpleInput'
import { InputCheckbox, LabelCheck } from './styles'
import { Label, Container } from '../../../GlobalStyles'
import history from '../../../routes/history'
import { Loading } from '../../../components/Loading'
import { IGlobalResponse, INIT_GLOBAL_RESPONSE } from '../../../utils/global-response'
import { Search } from '../../../components/form/SimpleSearch'
import { TableEdit } from '../../../components/TableEdit'
import { Mixpanel } from '../../../utils/mixpanel'
import ApiCep from '../../../services/ViaCep'
import InputMask from '../../../components/form/InputMask'
import api_v2 from '../../../services/api-v2'

interface Pdv {
  id: string
}
interface editPdv extends IGlobalResponse {
  result: Pdv[]
}

const PDVEditPage: React.FC = () => {
  const [PdvEdit, setPdvEdit] = useState<editPdv>(INIT_GLOBAL_RESPONSE)
  const [checked, setChecked] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [pdvCustom, setPdvCustom] = useState<any>()
  const [rua, setRua] = useState('')
  const [cidades, setCidades] = useState('')
  const [estados, setEstados] = useState('')
  const [bairros, setBairros] = useState('')
  const formRef = useRef<FormHandles>(null)

  const { id }: any = useParams()

  useEffect(() => {
    async function loadPdv() {
      setLoading(true)
      try {
        const { data } = await api_v2.get(`/pdv/${id}`)
        let body = data[0]
        formRef.current!.setFieldValue('cnpj', body.cnpj ? body.cnpj : '')
        formRef.current!.setFieldValue('pdv_code', body.pdv_code ? body.pdv_code : '')
        formRef.current!.setFieldValue('telephone', body.telephone ? body.telephone : '')
        formRef.current!.setFieldValue('manager', body.manager ? body.manager : '')
        formRef.current!.setFieldValue('chanel_pdv', body.chanel_pdv ? body.chanel_pdv : '')
        formRef.current!.setFieldValue('checkouts_in_pdv', body.checkouts_in_pdv ? body.checkouts_in_pdv : '')
        formRef.current!.setFieldValue('pdv_code', body.pdv_code ? body.pdv_code : '')
        formRef.current!.setFieldValue('type_pdv', body.type_pdv ? body.type_pdv : '')
        formRef.current!.setFieldValue('name', body.name ? body.name : '')
        formRef.current!.setFieldValue('corporate_name', body.corporate_name ? body.corporate_name : '')
        formRef.current!.setFieldValue('cep', body.cep ? body.cep : '')
        formRef.current!.setFieldValue('address', body.address ? body.corporate_name : '')
        if (body.flag_id) {
          formRef.current!.setFieldValue('flag', {
            value: body.flag_id,
            label: body.flag_name,
          })
        }

        if (body.regional_id) {
          formRef.current!.setFieldValue('regional', {
            value: body.regional_id,
            label: body.regional_name,
          })
        }
        setRua(body.address)
        setEstados(body.state)
        setCidades(body.city)
        setBairros(body.neighborhood)
        setPdvEdit(body)
        setPdvCustom(body)
        setChecked(body.is_active)
      } catch (error) {
        console.log(error, 'error')
      }
      setLoading(false)
    }
    loadPdv()
  }, [id])

  async function handleSubmit(body: any) {
    setLoading(true)
    try {
      if (body.flag) body.flag_id = body.flag.value
      if (body.flag) body.flag = body.flag.value
      if (body.regional) body.regional_id = body.regional.value

      const schema = Yup.object().shape({
        name: Yup.string().required('* O nome é obrigatório'),
        cep: Yup.string().required('* O numero do CEP é obrigatório'),
        state: Yup.string().required('* O estado é obrigatório'),
        city: Yup.string().required('* A cidade  é obrigatório'),
        address: Yup.string().required('* O nome de endereço é obrigatório'),
        neighborhood: Yup.string().required('* O nome do bairro obrigatório'),
      })

      await schema.validate(body, {
        abortEarly: false,
      })

      delete body.flag
      delete body.regional

      const { data } = await api_v2.put(`/pdv/${id}`, {
        ...body,
        is_active: checked,
        custom_fields: JSON.stringify(pdvCustom.custom_fields),
      })

      Mixpanel.track('Editou PDV', {
        params: {
          id: id,
        },
      })

      if (data) {
        message.success('As informações do PDV foram atualizadas')
        Mixpanel.track('Atualizou PDV', {
          params: {
            is_active: checked,
            ...body,
          },
        })
        setTimeout(() => {
          history.push('/backoffice/store/pdvs')
        }, 1000)
      } else {
        message.error('Erro ao atualizar PDV')
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((el: any) => {
          formRef.current!.setFieldError(el.path, el.message)
        })
      }
    }
    setLoading(false)
  }

  async function handleDados(event: any) {
    try {
      let cep: any = event.target.value

      cep = cep.replace('-', '')

      if (cep.length !== 8) return

      const response = await ApiCep.SearchCep(cep)

      let logradouro = response.data.logradouro
      let cidade = response.data.localidade
      let estado = response.data.uf
      let bairro = response.data.bairro

      setRua(logradouro)
      setEstados(estado)
      setCidades(cidade)
      setBairros(bairro)
    } catch (err) {
      message.error('CEP informado não encontrado')
    }
  }

  return (
    <Container>
      {loading ? <Loading /> : null}
      <h1>Atualizar informações do PDV</h1>
      <p>Insira os dados corretamente fazer a atualização.</p>
      <Breadcrumb separator='>' className='breadcumb'>
        <Link to='/backoffice/store/pdvs'>
          <Breadcrumb.Item> PDV </Breadcrumb.Item>
        </Link>
        <Breadcrumb.Item> Edição de PDV </Breadcrumb.Item>
      </Breadcrumb>
      <Form onSubmit={handleSubmit} className='form' initialData={PdvEdit} ref={formRef}>
        <Row gutter={25}>
          <Col sm={24} md={11}>
            <Input name='name' type='text' placeholder='Informe o nome completo' label='Nome' />
          </Col>
          <Col sm={24} md={11}>
            <Input name='corporate_name' type='text' placeholder='Informe o nome fantasia' label='Nome fantasia' />
          </Col>
          <Col sm={24} md={2}>
            <LabelCheck>Ativo?</LabelCheck>
            <InputCheckbox>
              <Checkbox
                name='is_active'
                checked={checked}
                defaultChecked={true}
                onChange={(e: any) => {
                  setChecked(e.target.checked)
                }}
              />
            </InputCheckbox>
          </Col>
          <Col sm={24} md={8}>
            <InputMask
              name='cnpj'
              type='text'
              placeholder='99.999.999/9999-99'
              label='CNPJ'
              mask='99.999.999/9999-99'
            />
          </Col>
          <Col sm={24} md={8}>
            <Input name='pdv_code' type='text' placeholder='Informe o código do PDV' label='Código do PDV' />
          </Col>
          <Col sm={24} md={8}>
            <Input name='telephone' type='text' placeholder='Informe o telefone' label='Telefone' />
          </Col>
          <Col sm={24} md={8}>
            <Input name='manager' type='text' placeholder='Informe o nome do gerente' label='Gerente' />
          </Col>
          <Col sm={24} md={4}>
            <Label> Escolha a bandeira </Label>
            <Search placeholder='Selecione a bandeira' name='flag' path='flags' isMult={false} />
          </Col>
          <Col sm={24} md={4}>
            <Label> Escolha a região </Label>
            <Search placeholder='Selecione a região' name='regional' path='regionals' isMult={false} />
          </Col>
          <Col sm={24} md={8}>
            <Input name='cep' type='text' placeholder='Informe o CEP' label='CEP' onChange={handleDados} />
          </Col>
          <Col sm={24} md={8}>
            <Input
              name='address'
              type='text'
              placeholder='Informe o endereço'
              label='Endereço completo'
              value={rua}
              onChange={(e) => setRua(e.target.value)}
            />
          </Col>
          <Col sm={24} md={8}>
            <Input name='number' type='text' placeholder='Informe o número' label='Número' />
          </Col>
          <Col sm={24} md={8}>
            <Input
              name='neighborhood'
              type='text'
              placeholder='Informe o bairro'
              label='Bairro'
              value={bairros}
              onChange={(e) => setBairros(e.target.value)}
            />
          </Col>
          <Col sm={24} md={8}>
            <Input
              name='city'
              type='text'
              placeholder='Informe a cidade'
              label='Cidade'
              value={cidades}
              onChange={(e) => setCidades(e.target.value)}
            />
          </Col>
          <Col sm={24} md={8}>
            <Input
              name='state'
              label='Estado'
              placeholder='Informe o estado'
              value={estados}
              onChange={(e) => setEstados(e.target.value)}
            />
          </Col>
          <Col sm={24} md={8}>
            <Input name='complement' type='text' placeholder='Informe o complemento' label='Complemento' />
          </Col>
          <Col sm={24} md={6}>
            <Input name='type_pdv' type='text' placeholder='Tipo do PDV' label='Tipo do PDV' />
          </Col>
          <Col sm={24} md={6}>
            <Input name='profile_pdv' type='text' placeholder='Perfil do PDV' label='Perfil do PDV' />
          </Col>
          <Col sm={24} md={6}>
            <Input name='chanel_pdv' type='text' placeholder='Canal do PDV' label='Canal do PDV' />
          </Col>
          <Col sm={24} md={6}>
            <Input
              name='checkouts_in_pdv'
              type='text'
              placeholder='Quantidade de caixas'
              label='Quantidade de caixas'
            />
          </Col>
        </Row>
        {pdvCustom &&
          !Array.isArray(pdvCustom.custom_fields) &&
          Object.getOwnPropertyNames(pdvCustom.custom_fields).length > 0 && (
            <Collapse bordered={false}>
              <Collapse.Panel header='Campos customizados' key='1'>
                <Row>
                  <Col span={24}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                      <TableEdit
                        productEdit={pdvCustom}
                        update={(newData: any) => {
                          let newObj: any = {}
                          newData.forEach((el: any) => {
                            newObj[el.chave] = el.valor
                          })

                          setPdvCustom({ ...pdvCustom, custom_fields: newObj })
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Collapse.Panel>
            </Collapse>
          )}

        <Row gutter={[16, 30]} style={{ marginTop: 10, paddingLeft: 10 }}>
          <Col>
            <Button type='primary' htmlType='submit'>
              Atualizar
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  )
}
export default PDVEditPage
