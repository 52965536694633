import { useState, useEffect } from 'react'
import { Row, Col, Breadcrumb } from 'antd'
import { MdSearch } from 'react-icons/md'
import { UploadOutlined } from '@ant-design/icons'

import api_v2 from '../../../services/api-v2'
import CardUser from '../../../components/CardUser'
import { Loading } from '../../../components/Loading'
import { ModalCreateUser } from '../../../components/ModalCreateUser'
import { CloudDownloadOutlined } from '@material-ui/icons'
import { Container, ContainerSearch, Search, ContainerCards } from './style'
import { ModalExportVisit } from '../../../components/ModalExportVisit'
import { ModalImport } from '../../../components/ModalImport'
import { NoSearch } from '../../../components/NoSearch'
import { AddButton, AddButtonWhite } from '../../../components/DefaultPage/style'

export interface IUserInfo {
  id: number
  name: string
  cpf: string
  email: string | undefined
  is_active: boolean
  state: string
  type: string
  phone: string | undefined
}

export const WorkProfilePageSupervisor = () => {
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openModalExport, setOpenModalExport] = useState(false)
  const [openModalImport, setOpenModalImport] = useState(false)
  const [userInfo, setUser] = useState<IUserInfo[]>([])
  const [nameSearch, setNameSearch] = useState<string>('')

  const onSearch = async (value: any) => {
    setNameSearch(value)
  }

  const loadPerfis = async () => {
    setLoading(true)
    const { data } = await api_v2.get(`/supervisor`)

    setUser(data.result)
    setLoading(false)
  }

  useEffect(() => {
    loadPerfis()
  }, [])

  const restart = async (res: boolean) => {
    if (res) {
      loadPerfis()
    }
  }

  return (
    <Container>
      <Row style={{ marginBottom: 20 }} justify='space-between'>
        <Col>
          <Breadcrumb>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>Time</Breadcrumb.Item>
            <Breadcrumb.Item>Supervisores</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col span={16}>
          <Row justify='end' gutter={[10, 10]}>
            <Col>
              <ContainerSearch>
                <Search
                  onChange={(e: any) => onSearch(e.target.value)}
                  placeholder='Buscar por nome, CPF ou Estado'
                  value={nameSearch}
                />
                <MdSearch size={20} color={'#A56EFC'} />
              </ContainerSearch>
            </Col>
            <Col>
              <AddButtonWhite onClick={() => setOpenModalImport(true)}>
                <UploadOutlined style={{ fontSize: 20 }} />
                Importar
              </AddButtonWhite>
            </Col>
            <Col>
              <AddButton onClick={() => setOpenModalExport(true)}>
                <CloudDownloadOutlined />
                Exportar
              </AddButton>
            </Col>
          </Row>
        </Col>
      </Row>
      {loading ? (
        <Loading />
      ) : (
        <ContainerCards>
          {userInfo && userInfo.length > 0 ? (
            <Row gutter={[16, 16]}>
              {userInfo.map((el, index: number) => (
                <Col span={8} key={index}>
                  <CardUser data={el} />
                </Col>
              ))}
            </Row>
          ) : (
            <NoSearch text='Não encontramos nenhum dado aqui.' />
          )}
        </ContainerCards>
      )}
      <ModalCreateUser
        visible={openModal}
        close={setOpenModal}
        type='SUPERVISOR'
        reloadApi={(bool: any) => restart(bool)}
      />
      <ModalImport visible={openModalImport} close={setOpenModalImport} type='SUPERVISOR' />
      <ModalExportVisit loading={setLoading} type='supervisor' visible={openModalExport} close={setOpenModalExport} />
    </Container>
  )
}
