import styled from 'styled-components'
import { Modal } from 'antd'

export const ContainerModal = styled(Modal)`
    &&& .ant-modal-content{
        border-radius: 12px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    }

    &&& .ant-modal-body{
        border-radius: 12px;
    }

    &&& .ant-modal-close-x{
        color: red;
    }
`
export const Container = styled.div`
    width: 100%;
    height: 100%;
    padding: 20px;

    &&& .ant-radio-wrapper{
        font-size: 16px !important;
    }
`

export const Title = styled.p`
    padding: 0 15px;
    background-color: #fff;
    font-weight: bold;
    font-size: 18px;
    width: 100%;
    text-align: center;
`
export const Steps = styled.p`
    color: #6600cc;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    width: 100%;
`