import { DeleteOutlined, EditOutlined, MoreOutlined, DownOutlined } from '@ant-design/icons'
import { Button, Col, Dropdown, Menu, message, Popconfirm } from 'antd'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { isActiveSelect } from '../../../utils/option-user'
import { DataStatus } from '../../../components/TabsVisits/style'
import DefaultPage, { getStatusProduct } from '../../../components/DefaultPage'
import { Mixpanel } from '../../../utils/mixpanel'
import { exportXls } from '../../../utils/funcoes'
import { hasIndex } from '../../../components/Menu/menuHorizonte'
import { ContextUpload } from '../../../context/contextUpload'
import api_v2 from '../../../services/api-v2'

export interface brandsFilter {
  name?: string
  is_active?: string
}

const BrandListPage = () => {
  const { permissions } = ContextUpload()
  const [loading, setLoading] = useState(false)
  const [reload, setReload] = useState(false)
  const [selecteds, setSelecteds] = useState<any>([])

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      setSelecteds(selectedRows)
    },
    getCheckboxProps: (record: any) => ({
      disabled: !record.is_active, // Column configuration not to be checked
    }),
  }

  const activeBrand = async (id: string) => {
    setLoading(true)
    try {
      await api_v2.put('brand/active/' + id)
      message.success('Marca ativada com sucesso!')

      setReload(!reload)
    } catch (err) {
      message.error('Erro ao ativar marca')
    }
    setLoading(false)
  }

  const deleteBrand = async (id: string) => {
    setLoading(true)
    try {
      await api_v2.delete('brand/' + id)
      message.success('Marca excluido com sucesso!')

      setReload(!reload)
    } catch (err) {
      message.error('Erro ao inativar marca.')
    }
    setLoading(false)
  }

  const deleteMass = async (id: string) => {
    try {
      await api_v2.delete('brand/' + id)
      Mixpanel.track('Inativou marca', {
        params: { id: id },
      })
      message.success('Marca inativado com sucesso!')
    } catch (err) {
      message.error('Esse marca está vinculada a algum outro registro')
    }
  }

  return (
    <DefaultPage
      titlePage='Marcas'
      breadcrumb={[{ name: 'Home', url: '/' }, { name: 'Marcas' }]}
      urlPage='/backoffice/catalog/brands'
      routeApi={{
        method: 'get',
        route: 'brand',
        versionApi: 'v1',
        reload,
      }}
      listButtons={{
        buttonCreate: {
          visible: hasIndex(['catalog.add_brand'], permissions) > -1,
          type: 'url',
          url: '/backoffice/catalog/brands/create',
        },
        buttonExport: {
          visible: hasIndex(['catalog.export_brand'], permissions) > -1,
          type: 'function',
          function: async () => {
            setLoading(true)
            exportXls({}, 'brands')
            setLoading(false)
          },
        },
        buttonimport: {
          visible: hasIndex(['catalog.import_brand'], permissions) > -1,
          path: 'BRANDS',
        },
        buttonFilter: {
          visible: true,
          filters: [
            {
              label: 'Selecione a marca:',
              name: 'name',
              type: 'search',
              urlSearch: 'brands',
              getEnv: 'label',
            },
            {
              label: 'Selecione o status',
              name: 'is_active',
              type: 'select',
              options: isActiveSelect,
              getEnv: 'value',
            },
          ],
        },
        newButton: {
          visible: selecteds.length > 0 ? true : false,
          children: (
            <Col span={5}>
              <Dropdown
                overlay={
                  <Menu>
                    {hasIndex(['catalog.remove_brand'], permissions) > -1 && (
                      <Menu.Item key='1' icon={<DeleteOutlined />}>
                        <Popconfirm
                          title='Após deletar está marca, os produtos vinculados ficarão inativos.'
                          onConfirm={async () => {
                            setLoading(!loading)

                            for await (let brand of selecteds) {
                              Mixpanel.track('Confirmou inativar marca', brand)
                              await deleteMass(brand.id)
                            }
                            setLoading(!loading)
                            setReload(!reload)
                          }}
                          okText='Sim'
                          cancelText='Não'
                          placement='topRight'
                        >
                          Inativar marca
                        </Popconfirm>
                      </Menu.Item>
                    )}
                  </Menu>
                }
              >
                <Button>
                  Ações em massa ({selecteds.length}) <DownOutlined />
                </Button>
              </Dropdown>
            </Col>
          ),
        },
      }}
      load={{ loading, setLoading }}
      rowSelection={{
        type: 'checkbox',
        ...rowSelection,
      }}
      columns={[
        {
          title: 'Status',
          dataIndex: 'is_active',
          key: 'is_active',
          width: 80,
          render: (status: any) => {
            return <DataStatus>{getStatusProduct(status)}</DataStatus>
          },
        },
        {
          title: 'Nome',
          dataIndex: 'name',
          sorter: (a, b) => {
            return a.name.localeCompare(b.name)
          },
        },
        {
          title: '#',
          width: 50,
          render: (obj: any) => {
            if (hasIndex(['catalog.change_brand', 'catalog.remove_brand'], permissions) === -1) return undefined

            return (
              <Dropdown
                overlay={
                  <Menu>
                    {hasIndex(['catalog.remove_brand'], permissions) > -1 && (
                      <Menu.Item key='1' icon={<DeleteOutlined />}>
                        <Popconfirm
                          title={
                            obj.is_active
                              ? 'Após inativar está marca, os produtos vinculados ficarão inativos.'
                              : 'Ao ativar esta marca seus produtos vinculados serão ativados também.'
                          }
                          onConfirm={() => {
                            Mixpanel.track('Inativou marca', obj)
                            if (obj.is_active) {
                              deleteBrand(obj.id)
                            } else {
                              activeBrand(obj.id)
                            }
                          }}
                          okText='Sim'
                          cancelText='Não'
                          placement='topRight'
                        >
                          {obj.is_active ? 'Inativar' : 'Ativar'} Marca
                        </Popconfirm>
                      </Menu.Item>
                    )}
                    {hasIndex(['catalog.change_brand'], permissions) > -1 && (
                      <Menu.Item key='2' icon={<EditOutlined />}>
                        <Link to={`/backoffice/catalog/brands/edit/${obj.id}`}>Editar marca</Link>
                      </Menu.Item>
                    )}
                  </Menu>
                }
              >
                <Button size='small' shape='circle' icon={<MoreOutlined />} />
              </Dropdown>
            )
          },
        },
      ]}
    />
  )
}

export default BrandListPage
