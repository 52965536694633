import React, { useRef, useState, useEffect, useMemo } from 'react'
import { ContainerButtonsFootter, ContainerFormFilter, ContainerList, GroupForm } from '../../GlobalStyles'
import { Row, Col, Breadcrumb, Tooltip, Tag, Table } from 'antd'
import { Link } from 'react-router-dom'
import { MdAdd, MdSearch } from 'react-icons/md'
import { AddButton, AddButtonWhite, ContainerSearch, ContainerTableDefault } from './style'
import ReactDragListView from 'react-drag-listview'
import {
  CloudDownloadOutlined,
  UploadOutlined,
  FilterOutlined,
  FileSearchOutlined,
  ClearOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import { Loading } from '../Loading'
import { DrawerFilter } from '../DrawerFilter'
import { ButtonClear, ButtonSearch, LabelForm } from '../../pages/Team/Promoter/style'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import history from '../../routes/history'
import SimpleInput from '../form/SimpleInput'
import { Search } from '../form/SimpleSearch'
import { ModalImportGeneral } from '../ModalImportGeneral'
import qs from 'querystring'
import { ContainerTagg } from '../../pages/Survey/FormListPage/styles'
import SimpleSelectObject from '../form/SimpleSelectObject'
import api from '../../services/api'
import api_v2 from '../../services/api-v2'
import useReactRouter from 'use-react-router'
import { INIT_GLOBAL_RESPONSE } from '../../utils/global-response'
import { NoSearch } from '../NoSearch'
import { ColumnsType } from 'antd/lib/table'
import { StatusInativo, StatusRealizado } from '../TabsVisits/style'
import SimpleRangePicker from '../form/SimpleRangePicker'
import moment from 'moment'
import { notAccess } from '../../routes/ScrollToTop'
import { ContextUpload } from '../../context/contextUpload'
import { debounce } from 'lodash'
import SimpleDate from '../form/SimpleDate'
import { IoIosArrowBack } from 'react-icons/io'

export const encodeObj = (obj: any) => {
  let code: any = {}
  for (var el in obj) {
    if (obj[el]) {
      code[el] = JSON.stringify(obj[el])
    }
  }
  return code
}

interface PropsBreadcrumb {
  url?: string
  name: string
}

interface PropsOptionsButton {
  visible: boolean
  type: 'url' | 'modal' | 'function'
  url?: string
  function?: any
}

interface PropsFilters {
  type: 'rangePicker' | 'search' | 'text' | 'select' | 'date'
  label: string
  name: string
  getEnv?: 'value' | 'label'
  urlSearch?: string
  isMult?: boolean
  options?: any
  invisible?: boolean
}

interface PropsOptionsButtonImport {
  visible: boolean
  path: string
}

interface PropsOptionsButtonFilter {
  visible: boolean
  filters: PropsFilters[]
}

interface PropsOptionsButtonTextFilter {
  url: string
  visible: boolean
}

interface PropsOptionsButtonNew {
  children: any
  visible: boolean
}

interface PropsOptionsGoBack {
  url: string
  visible: boolean
}

interface PropsListButtons {
  buttonCreate?: PropsOptionsButton
  buttonFilter?: PropsOptionsButtonFilter
  buttonExport?: PropsOptionsButton
  buttonimport?: PropsOptionsButtonImport
  buttonTextFilter?: PropsOptionsButtonTextFilter
  newButton?: PropsOptionsButtonNew
  goBack?: PropsOptionsGoBack
}

interface PropsLoadingButtons {
  loading: boolean
  setLoading(value: boolean): void
}

interface PropsRouteApi {
  versionApi: 'v1' | 'v2'
  route: string
  method: 'post' | 'get' | 'put'
  reload: any
  config?: string
}

interface Props {
  titlePage: string
  breadcrumb: PropsBreadcrumb[]
  listButtons?: PropsListButtons
  load: PropsLoadingButtons
  urlPage: string
  routeApi: PropsRouteApi
  columns: ColumnsType<any>
  defaultUrl?: any
  rowSelection?: any
  childrenHeader?: any
}

export const getStatusProduct = (status: any) => {
  const listStatus: any = {
    PENDENT: (
      <Tooltip title='Inativo'>
        <StatusInativo />
      </Tooltip>
    ),
    COMPLETE: (
      <Tooltip title='Ativo'>
        <StatusRealizado />
      </Tooltip>
    ),
  }

  if (status === true) {
    return listStatus.COMPLETE
  } else if (status === false) {
    return listStatus.PENDENT
  } else {
    return '-'
  }
}
let storedBody: any = null;

export async function resultBody(body: any) {
  storedBody = body;
}
export { storedBody };

const DefaultPage = ({
  rowSelection,
  titlePage,
  breadcrumb,
  listButtons,
  load,
  urlPage,
  defaultUrl,
  routeApi,
  columns,
  childrenHeader,
}: Props) => {
  const { permissions } = ContextUpload()
  const [visibleFilter, setVisibleFilter] = useState(false)
  const [visibleImport, setVisibleImport] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [columnsInit, setColumnsInit] = useState(columns)
  const [response, setResponse] = useState(INIT_GLOBAL_RESPONSE)
  const [urlFilter, setUrlFilter] = useState<string>('')
  const [filterTag, setFilterTag] = useState<any>()
  const formRef = useRef<FormHandles>(null)
  const { location } = useReactRouter()

  const cleanFilter = async () => {
    setFilterTag({})
    history.push(urlPage)
    formRef.current?.reset()
    setVisibleFilter(false)
  }

   async function search(body: any) {
    load.setLoading(true)
    setFilterTag(body)
    await resultBody(body);
    let filterQuery: any = {}
    for (var el in body) {
      let indexFilter: any = null
      // eslint-disable-next-line no-loop-func
      listButtons?.buttonFilter?.filters.forEach((elFil: PropsFilters, index: number) => {
        if (elFil.name === el) indexFilter = index
      })

      if (el === 'date' && body[el].length === 2) {
        filterQuery['start_date'] = body[el][0]
        filterQuery['end_date'] = body[el][1]
      } else if (typeof body[el] === 'object' && Object.keys(body[el]).length > 0) {
        if (listButtons?.buttonFilter?.filters[indexFilter]?.isMult) {
          let text = ''
          if (listButtons?.buttonFilter?.filters[indexFilter]?.getEnv === 'value') {
            body[el].forEach((el: any) => (text = text === '' ? `${el.value}` : text + `,${el.value}`))
          } else {
            body[el].forEach((el: any) => (text = text === '' ? `${el.label}` : text + `,${el.label}`))
          }
          filterQuery[el] = text
        } else {
          filterQuery[el] =
            listButtons?.buttonFilter?.filters[indexFilter]?.getEnv === 'value' ? body[el].value : body[el].label
        }
      } else if (typeof body[el] === 'string' && body[el].length > 0) {
        filterQuery[el] = body[el]
      } else if (body[el] && body[el].length > 0) {
        filterQuery[el] = body[el]
      }
    }
    const urlApi = qs.stringify(filterQuery)
    setResponse({ ...response, loading: true })

    if (routeApi.versionApi === 'v1') {
      const { data } = await api[routeApi.method](
        `${routeApi.route}?${urlApi}${
          routeApi.config
            ? urlApi
              ? '&' + routeApi.config
                ? routeApi.config
                : ''
              : routeApi.config
              ? routeApi.config
              : ''
            : ''
        }`
      )
      setResponse({ ...data, loading: false })
    } else if (routeApi.versionApi === 'v2') {
      try {
        const { data } = await api_v2[routeApi.method](
          `${routeApi.route}?${urlApi}${
            routeApi.config
              ? urlApi
                ? '&' + routeApi.config
                  ? routeApi.config
                  : ''
                : routeApi.config
                ? routeApi.config
                : ''
              : ''
          }`
        )
        setResponse({ ...data, loading: false })
      } catch (error) {}
    }
    setUrlFilter(urlApi)
    history.push(`${urlPage}?${qs.stringify(encodeObj(body))}`)
    load.setLoading(false)
  }

  const removeFilter = async (typeRemove: any) => {
    let allFilters: any = convertUrl()
    delete allFilters[typeRemove.name]
    formRef.current?.clearField(typeRemove.name)
    search(allFilters)
  }

  const removeFilterMulti = async (typeRemove: any, index: number) => {
    let allFilters: any = convertUrl()

    let newList: any = []

    allFilters[typeRemove.name].forEach((list: any, indexList: number) => {
      if (index !== indexList) newList.push(list)
    })

    allFilters[typeRemove.name] = newList
    formRef.current?.clearField(typeRemove.name)

    search(allFilters)
  }

  let convertUrl = () => {
    try {
      let block = notAccess(permissions, window.location.pathname)
      if (block === -2) return history.push('/notAcces')

      let getUrl: any = qs.parse(location.search.replaceAll('?', ''))

      let filterQuery: any = {}
      for (var el in getUrl) {
        try {          
          filterQuery[el] = JSON.parse(getUrl[el])
        } catch (error) {
          
        }
      }

      if (filterQuery.start_date && filterQuery.end_date) {
        filterQuery['date'] = [filterQuery.start_date, filterQuery.end_date]
        delete filterQuery.end_date
        delete filterQuery.start_date
      }

      if (defaultUrl) {
        if (Object.keys(defaultUrl).length > 0) {
          Object.keys(defaultUrl).forEach((el: any) => {
            if (el === 'date' && defaultUrl[el].length === 2 && !filterQuery.date) {
              filterQuery['start_date'] = defaultUrl[el][0]
              filterQuery['end_date'] = defaultUrl[el][1]
            }
          })
        }
      }

      return filterQuery
    } catch (error) {
      console.log(error)
    }
  }

  const getOrganTable = () => {
    let getTable = localStorage.getItem(`columnDefault-${titlePage}`)

    if (getTable && getTable !== null) {
      let convert = JSON.parse(getTable)
      let newTable: any = []

      convert.forEach((el: any) => {
        columns.forEach((el2: any) => {
          if (el === el2.title) newTable.push(el2)
        })
      })

      if (newTable.length > 0) setColumnsInit(newTable)
    }
  }

  useEffect(() => {
    let isJson = true

    try {
      let convert = JSON.stringify(filterTag)
      let convert2 = JSON.stringify(convertUrl())
    } catch (e) {
      isJson = false
    }
    if(isJson && JSON.stringify(filterTag) != JSON.stringify(convertUrl())) {
      search(convertUrl())
      window.scrollTo(0, 0)
      getOrganTable()
    } else {
      search(convertUrl())
      window.scrollTo(0, 0)
      getOrganTable()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, routeApi.reload])

  const handleTableChange = async (queryParams: any) => {
    setResponse({ ...response, loading: true })

    let newObj: any = {
      perpage: queryParams.pageSize,
      page: queryParams.current,
    }

    if (searchText) newObj.search = searchText
    const query: any = qs.stringify(newObj)
    setResponse({ ...response, loading: true })

    if (routeApi.versionApi === 'v1') {
      const { data } = await api[routeApi.method](
        `${routeApi.route}?${query}${urlFilter ? '&' + urlFilter : ''}${
          routeApi.config !== undefined ? '&' + routeApi.config : ''
        }`
      )
      setResponse({ ...data, loading: false })
    } else if (routeApi.versionApi === 'v2') {
      console.log(routeApi, 'routeApi')
      const { data } = await api_v2[routeApi.method](
        `${routeApi.route}?${query}${urlFilter ? '&' + urlFilter : ''}${
          routeApi.config !== undefined ? '&' + routeApi.config : ''
        }`
      )
      setResponse({ ...data, loading: false })
    }
  }

  const onSearch = async (event: any) => {
    let searchString = event.target.value
    if (!searchString || searchString === '') {
      search(convertUrl())
      return
    }
    setSearchText(searchString)
    let obj = {
      search: searchString,
    }
    const filterQuery = qs.stringify(obj)

    if (listButtons && listButtons.buttonTextFilter) {
      const { data } = await api_v2.get(`${listButtons.buttonTextFilter.url}?${filterQuery}`)
      setResponse({ ...data, loading: false })
    }
  }

  const debouncedChangeHandler = useMemo(() => debounce(onSearch, 300), []) // eslint-disable-line react-hooks/exhaustive-deps

  const onDragEnd = (fromIndex: any, toIndex: any) => {
    const columnsCopy = columnsInit.slice()
    const item = columnsCopy.splice(fromIndex, 1)[0]
    columnsCopy.splice(toIndex, 0, item)
    localStorage.setItem(`columnDefault-${titlePage}`, JSON.stringify(columnsCopy.map((el: any) => el.title)))
    setColumnsInit(columnsCopy)
  }

  return (
    <ContainerList>
      {(load.loading || response.loading) && <Loading />}
      <Row gutter={[8, 8]} wrap={true} justify='space-between'>
        <Col xs={24} sm={8} md={6} lg={8} flex='none'>
          <h1>{titlePage}</h1>
          <Breadcrumb separator='>' className='breadcumb'>
            {breadcrumb.map((el) => (
              <>
                {el.url ? (
                  <Link to={el.url}>
                    <Breadcrumb.Item>{el.name}</Breadcrumb.Item>
                  </Link>
                ) : (
                  <Breadcrumb.Item>{el.name}</Breadcrumb.Item>
                )}
              </>
            ))}
          </Breadcrumb>
        </Col>
        <Col xs={24} sm={16} md={16} lg={16}>
          {listButtons && (
            <Row gutter={[8, 8]} justify='end'>
              {listButtons.newButton && listButtons.newButton.visible && <>{listButtons.newButton.children}</>}

              {listButtons && listButtons.buttonTextFilter && listButtons.buttonTextFilter.visible && (
                <ContainerSearch>
                  <input
                    placeholder='Buscar por nome'
                    onChange={debouncedChangeHandler}
                    style={{ width: '100%', height: '100%', border: 'none', background: 'transparent' }}
                  />
                  <MdSearch size={20} color={'#A56EFC'} />
                </ContainerSearch>
              )}
              {listButtons.buttonCreate &&
              listButtons.buttonCreate.visible &&
              listButtons.buttonCreate.type === 'url' ? (
                <Col xs={6} sm={7} md={7} lg={3}>
                  <Link to={listButtons.buttonCreate.url ? listButtons.buttonCreate.url : ''}>
                    <AddButton>
                      <MdAdd style={{ fontSize: 20 }} />
                      Novo(s)
                    </AddButton>
                  </Link>
                </Col>
              ) : listButtons.buttonCreate &&
                listButtons.buttonCreate.visible &&
                listButtons.buttonCreate.type === 'function' ? (
                <Col xs={6} sm={7} md={7} lg={3}>
                  <AddButton onClick={() => listButtons.buttonCreate?.function()}>
                    <MdAdd style={{ fontSize: 20 }} />
                    Novo(s)
                  </AddButton>
                </Col>
              ) : null}
              {listButtons.buttonExport &&
              listButtons.buttonExport.visible &&
              listButtons.buttonExport.type === 'url' ? (
                <Col xs={6} sm={7} md={7} lg={3}>
                  <Link to={listButtons.buttonExport.url ? listButtons.buttonExport.url : ''}>
                    <AddButton>
                      <CloudDownloadOutlined style={{ fontSize: 20 }} />
                      Exportar
                    </AddButton>
                  </Link>
                </Col>
              ) : listButtons.buttonExport &&
                listButtons.buttonExport.visible &&
                listButtons.buttonExport.type === 'function' ? (
                <Col xs={6} sm={7} md={7} lg={3}>
                  <AddButton onClick={() => listButtons.buttonExport?.function()}>
                    <CloudDownloadOutlined style={{ fontSize: 20 }} />
                    Exportar
                  </AddButton>
                </Col>
              ) : null}
              {listButtons.buttonimport && listButtons.buttonimport.visible && (
                <Col xs={6} sm={7} md={7} lg={3}>
                  <AddButtonWhite onClick={() => setVisibleImport(!visibleImport)}>
                    <UploadOutlined style={{ fontSize: 20 }} />
                    Importar
                  </AddButtonWhite>
                </Col>
              )}
              {listButtons.goBack && listButtons.goBack.visible && (
                <Col xs={6} sm={7} md={7} lg={3}>
                  <AddButtonWhite onClick={() => history.push(listButtons.goBack.url)}>
                    <IoIosArrowBack style={{ marginRight: 5 }} /> Voltar
                  </AddButtonWhite>
                </Col>
              )}
              {listButtons.buttonFilter && listButtons.buttonFilter.visible && (
                <Col xs={2} sm={2} md={1} lg={2}>
                  <Tooltip placement='top' title={'Filtrar'}>
                    <AddButtonWhite onClick={() => setVisibleFilter(!visibleFilter)} style={{ padding: 9 }}>
                      <FilterOutlined />
                    </AddButtonWhite>
                  </Tooltip>
                </Col>
              )}

              <Col span={24}>
                <ContainerTagg>
                  {listButtons?.buttonFilter?.filters &&
                    listButtons.buttonFilter.filters.map((el) => (
                      <>
                        {filterTag && el.name === 'date' && filterTag[el?.name] && filterTag[el?.name].length === 2 && (
                          <Tag color='geekblue' onClick={() => removeFilter(el)}>
                            {moment(filterTag[el?.name][0]).format('DD/MM/YYYY')}
                            <CloseOutlined />
                          </Tag>
                        )}
                        {filterTag && el.name === 'date' && filterTag[el?.name] && filterTag[el?.name].length === 2 && (
                          <Tag color='geekblue' onClick={() => removeFilter(el)}>
                            {moment(filterTag[el?.name][1]).format('DD/MM/YYYY')}
                            <CloseOutlined />
                          </Tag>
                        )}
                        {filterTag &&
                          filterTag[el?.name] &&
                          typeof filterTag[el?.name] === 'object' &&
                          filterTag[el?.name].label && (
                            <Tag color='geekblue' onClick={() => removeFilter(el)}>
                              {filterTag[el?.name].label ? filterTag[el?.name].label : filterTag[el?.name]}
                              <CloseOutlined />
                            </Tag>
                          )}
                        {el?.isMult && filterTag && filterTag[el?.name] && filterTag[el?.name].length > 0 && (
                          <>
                            {filterTag[el?.name].map((list: any, indexMulti: number) => (
                              <Tag color='geekblue' onClick={() => removeFilterMulti(el, indexMulti)}>
                                {list.label ? list.label : list}
                                <CloseOutlined />
                              </Tag>
                            ))}
                          </>
                        )}
                        {filterTag && filterTag[el?.name] && typeof filterTag[el?.name] === 'string' && (
                          <Tag color='geekblue' onClick={() => removeFilter(el)}>
                            {`${el?.name}: ${filterTag[el?.name]} `}
                            <CloseOutlined />
                          </Tag>
                        )}
                      </>
                    ))}
                </ContainerTagg>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      {childrenHeader && childrenHeader}
      {response.result && response.result.length > 0 ? (
        <ContainerTableDefault>
          <ReactDragListView.DragColumn onDragEnd={onDragEnd} nodeSelector='th'>
            <Table
              columns={columnsInit}
              rowKey={(res) => res.id}
              dataSource={response.result}
              size='small'
              rowSelection={rowSelection ? rowSelection : undefined}
              pagination={{
                // position: 'both',
                current: response.page,
                pageSize: response.perpage,
                total: response.total,
                size: 'small',
                showSizeChanger: true,
                showTotal: () => `Exibindo ${response.result.length} de ${response.total} de registros`,
              }}
              loading={response.loading}
              onChange={handleTableChange}
            />
          </ReactDragListView.DragColumn>
        </ContainerTableDefault>
      ) : (
        <NoSearch text='Não encontramos nenhum dado aqui.' />
      )}
      {listButtons && listButtons.buttonFilter && listButtons.buttonFilter.visible && (
        <DrawerFilter
          visible={visibleFilter}
          close={setVisibleFilter}
          title={titlePage}
          footer={
            <ContainerButtonsFootter>
              <ButtonSearch type='button' onClick={() => formRef?.current?.submitForm()}>
                <FileSearchOutlined />
                Pesquisar
              </ButtonSearch>
              <ButtonClear onClick={() => cleanFilter()}>
                <ClearOutlined />
                Limpar
              </ButtonClear>
            </ContainerButtonsFootter>
          }
        >
          <ContainerFormFilter
            tabIndex={0}
            onKeyDown={(evt: any) => {
              if (evt.key === 'Enter') formRef?.current?.submitForm()
            }}
          >
            <Form ref={formRef} className='form' style={{ marginTop: '15px' }} onSubmit={search}>
              {listButtons.buttonFilter.filters.map((el) => (
               <div>{el.invisible ?  null :
                   <GroupForm>
                  <LabelForm>{el.label}</LabelForm>
                  {el.type === 'search' && (
                    <Search
                      placeholder={el.label}
                      name={el.name}
                      path={el.urlSearch ? el.urlSearch : ''}
                      isMult={el.isMult}
                    />
                  )}
                  {el.type === 'select' && (
                    <SimpleSelectObject placeholder={el.label} name={el.name} options={el.options} />
                  )}
                  {el.type === 'text' && <SimpleInput placeholder={el.label} name={el.name} />}
                  {el.type === 'rangePicker' && <SimpleRangePicker name='date' />}
                  {el.type === 'date' && <SimpleDate name='date' />}
                </GroupForm>
                }
               </div>

              ))}
            </Form>
          </ContainerFormFilter>
        </DrawerFilter>
      )}
      {listButtons && listButtons.buttonimport && listButtons.buttonimport.visible && (
        <ModalImportGeneral
          visible={visibleImport}
          close={setVisibleImport}
          title={`Importar ${titlePage}`}
          path={listButtons.buttonimport!.path}
        />
      )}
    </ContainerList>
  )
}

export default DefaultPage
