import styled from 'styled-components'
import { CardStatus } from '../../../components/TabGeneralUser/style'

export const ContainerHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 13px;

    img{
        margin-right: 13px;
        width: 40px;
        height: 40px;
        border-radius: 20px;
    }
`

export const Name = styled.p`
    color: #242424;
    font-family: "Open Sans";
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 0;
    margin-right: 10px;
`

export const ContainerStatus = styled.div``

export const Active = styled.div`
    padding: 1px 3px;
    border-radius: 4px;
    max-width: 100px;
    border: 1px solid #16c266;
    background-color: rgba(184, 255, 217, 0.5);
    color: #0b9c4e;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    align-self: baseline;
    margin-top: 9px;
`

export const ContainerEdit = styled.div`
  display: flex;
  align-self: center;
  margin-left: 10px;
  cursor: pointer;
  z-index: 99;
`

export const Ticket = styled.div`
    width: 115px;
    height: 28px;
    background-color: #eeddff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    p{
        color: #272d3b;
        margin: 0;
    }
`

export const ContainerInfo = styled.div`
    color: #6f7782;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
    display: flex;
    align-items: center;

    svg {
        color:  #6600cc;
        font-size: 25px;
        margin-right: 9px;
    }

    p {
        color: #6600cc;
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 0;
        margin-right: 9px;
    }
`
export const ContainerAction = styled.div`
    display: flex;
    align-items: center;

    p {
        color: #6600cc;
        font-family: "Open Sans";
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0;
        margin-right: 15px;
    }

    img {
        margin-right: 10px;
        width: 30px;
        height: 30px;
        cursor: pointer;
    }

    &&& .tooltip {
        color: #272d3b !important;
        margin: 0;
    }
`

export const ContainerData = styled.div`
    width: 100%;
    max-width: 1053px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const ContainerActions = styled.div`
    position: relative;
    left: calc((92vw - 12px) - 760px);
    top: -90px;
    max-width: 800px;

    display: flex;
    align-items: center;
    justify-content: flex-end;
`
export const Button = styled.button`
`

export const BigButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6f7782;
  background-color: #fff;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #6f7782;
    background-color: #f9f9f9;
    font-size: 16px;
    font-weight: 400;
    padding: 8px;
    svg{
        margin-right: 10px;
    }
`

export const ContainerActionBack = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

export const ButtonBack = styled.button`
    width: 102px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #d6d6d6;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: #6f7782;
    font-family: "Open Sans";
    font-size: 16px;
    margin-left: 10px;
    background-color: transparent;
`

export const ContainerOptions = styled.div`
    position: relative;
    left: calc(100vw - 610px);
    bottom: 10px;
    display: flex;
    width: 568px;
    justify-content: flex-start;
    z-index: 9;
`
export const ContainerInfoGeral = styled.div`
    width: 100%;
    border-radius: 12px;
    border: 1px solid #d6d6d6;
    padding: 17px 32px;

    &&& .ant-modal {
        bottom: 5vh !important;
    }
`

export const DataInfo = styled.div`
    width: 100%;
    margin-top: 28px;
`

export const StrongText = styled.p`
    color: #272d3b;
    font-family: "Open Sans";
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
    text-transform: uppercase;
    margin-right: 7px;
`

export const LightText = styled.div`
    color: #6f7782;
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 400;
    /* margin-left: 7px; */
    display: flex;
    align-items: center;
`

export const ContainerDataInfo = styled.div`
    display: flex;
    align-items: center;
`
export const Item = styled.div`
  color: #fff;
  /* background: #364d79; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 70px;
  padding:  0 10px;
  color: black;
  .image {
    height: 70px;
    max-width: 70px;
  }
  .image-info {
    margin: 15px 0;
    max-width: 70px;
  }
  img {
    width: 100%; 
    max-width: 70px;
    height: 70px; 
    object-fit: contain;
  }
`

export const ContainerImgList = styled.div`
    width: 100%;
    height: 100%;
`

export const CardCarrocel = styled(CardStatus)`
    .ant-carousel .slick-dots li button {
    background: #7158C1;
  }
  .ant-carousel .slick-dots li.slick-active button {
    background: #7158C1;
  }

  &&& .slick-dots .slick-dots-bottom{
    left: -300% !important;
    transform: rotateZ(90deg) !important;
    position: absolute !important;
    top: -20% !important;
  }
`

export const ContainerSurvey = styled.div`
    width: 100%;

    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #b56aff;
        border: 3px solid #b56aff;
        border-radius: 5px;
    }


    &&& .ant-collapse{
        border-radius: 12px !important;
    }

    &&& .ant-collapse > .ant-collapse-item:last-child, .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header{
        border-radius: 12px;
        background-color: #6600cc;
        color: #fff;
    }
`

export const ContainerGroupInfo = styled.div`
    width: 100%;
    height: 100%;
`

export const ContainerDateSearch = styled.div`
    border-radius: 12px;
    border: 2px solid #eeddff;
    background-color: #faf5ff;
    padding: 22px 27px;
`

export const ContainerImg = styled.div`
    display: flex;
    align-items: center;
    max-width: 100%;
    overflow-y: scroll;
    padding: 15px;
    
    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #b56aff;
        border: 3px solid #b56aff;
        border-radius: 5px;
    }

    img {
        width: 90px;
        height: 163px;
        border-radius: 3px;
        cursor: pointer;
        margin-right: 20px;
    }
`

export const ContainerImgCarrocel = styled.div`
    &&& .ant-carousel{
        width: 300px;
    }
`
export const ItemImg = styled.div`
    width: 120px;
    height: 120px;
    padding: 10px;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`
export const ContainerListImg = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow-y: auto;
    max-height: 850px;

    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #b56aff;
        border: 3px solid #b56aff;
        border-radius: 5px;
    }
`

export const ContainerNoCheckIn = styled.div`
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    border: 1px solid #d6d6d6;
    border-radius: 20px;
    margin: 20px 0;

    h3{
        color: #b56aff;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    img{
        height: 70%;
    }
`

export const ContainerNoPhoto = styled.div`
    width: 100%;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;

    h3{
        color: #b56aff;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    img{
        width: 90%;
        max-width: 300px;
    }
`