import React, { useState } from 'react'
import { Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import { InfoCircleOutlined, CaretRightOutlined } from '@ant-design/icons'
import { compareByAlph, exportXls } from '../../../utils/funcoes'
import { getStatus } from '../VisitsPage'
import DefaultPage from '../../../components/DefaultPage'
import { hasIndex } from '../../../components/Menu/menuHorizonte'
import { ContextUpload } from '../../../context/contextUpload'
import moment from 'moment'
import qs from 'querystring'
import useReactRouter from 'use-react-router'

const Tasks = () => {
  const { permissions } = ContextUpload()
  const { location } = useReactRouter()
  const [loading, setLoading] = useState<boolean>(false)
  const [reload] = useState<boolean>(false)
  let getSharement = sessionStorage.getItem('@rocketpdv:workspace')
  let newGetWorkspace: any = {}
  if (getSharement) newGetWorkspace = JSON.parse(getSharement)
  let convertUrlToExportXls = (defaultUrl?: any) => {
    try {
      let getUrl: any = qs.parse(location.search.replaceAll('?', ''))

      let filterQuery: any = {}
      for (var el in getUrl) {
        if (el === 'date') {
          filterQuery['start_date'] = JSON.parse(getUrl[el])[0]
          filterQuery['end_date'] = JSON.parse(getUrl[el])[1]
        } else {
          let convert = JSON.parse(getUrl[el])
          if (convert && convert.length > 0) {
            filterQuery[el] = convert.map((e: any) => e.value)
          } else {
            filterQuery[el] = JSON.parse(getUrl[el]).value
          }
        }
      }

      if (!filterQuery['start_date'] || !filterQuery['end_date']) {
        if (Object.keys(defaultUrl).length > 0) {
          Object.keys(defaultUrl).forEach((el: any) => {
            if (el === 'date' && defaultUrl[el].length === 2 && !filterQuery.date) {
              filterQuery['start_date'] = defaultUrl[el][0]
              filterQuery['end_date'] = defaultUrl[el][1]
            }
          })
        }
      }

      return filterQuery
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <DefaultPage
      titlePage='Atividades'
      breadcrumb={[{ name: 'Home', url: '/' }, { name: 'Atividades' }]}
      urlPage={`/backoffice/survey/tasks`}
      // defaultUrl={{
      //   date: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      // }}
      routeApi={{
        method: 'get',
        route: 'task',
        versionApi: 'v2',
        reload,
      }}
      listButtons={{
        buttonExport: {
          visible: hasIndex(['task.export_task'], permissions) > -1,
          type: 'function',
          function: async () => {
            setLoading(true)
            await exportXls(
              convertUrlToExportXls({
                date: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
              }),
              'tasks'
            )
            setLoading(false)
          },
        },
        buttonFilter: {
          visible: true,
          filters: [
            {
              label: 'Selecione o PDV:',
              name: 'pdv_ids',
              type: 'search',
              urlSearch: 'pdvs',
              getEnv: 'value',
            },
            {
              label: 'Selecione o perfil:',
              name: 'profile_ids',
              type: 'search',
              urlSearch: 'profiles',
              getEnv: 'value',
            },
            {
              label: 'Selecione o promotor:',
              name: 'promoter_ids',
              type: 'search',
              urlSearch: 'promoters',
              getEnv: 'value',
            },
            {
              label: 'Selecione o formulários:',
              name: 'form_ids',
              type: 'search',
              urlSearch: 'forms',
              getEnv: 'value',
            },
            {
              label: 'Selecione o contexto',
              name: 'context_ids',
              type: 'select',
              options: [
                { value: 'STORE', label: 'Loja' },
                { value: 'PRODUCT', label: 'Produto' },
                { value: 'CHECK_IN', label: 'Check-in' },
              ],
              getEnv: 'value',
            },
            {
              label: 'Selecione um período',
              name: 'date',
              type: 'rangePicker',
            },
            {
              label: 'Selecione a empresa',
              urlSearch: 'sub-workspaces',
              name: 'sub_workspaces',
              type: 'search',
              getEnv: 'value',
              invisible:!newGetWorkspace.shared_environment

            },
          ],
        },
      }}
      load={{ loading, setLoading }}
      columns={[
        {
          title: 'Status',
          dataIndex: 'status',
          width: 50,
          sorter: (a, b) => compareByAlph(a.dt_visit_formated, b.dt_visit_formated),
          render: (a, b) => (
            <>
            { b.obs_justify_sub ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>{getStatus('JUSTIFIED_ABSENCE', '', b.obs_justify_sub)}</div>
              )
              : a === 'PENDENT' ? (
                <>{getStatus(a)}</>
              )               
              : (
                <div style={{ display: 'flex', alignItems: 'center' }}>{getStatus(a)}</div>
              )}
            </>
          ),
        },
        {
          title: 'PDV',
          dataIndex: 'pdv',
          sorter: (a, b) => a.pdv.localeCompare(b.pdv),
        },
        {
          title: 'Formulário',
          dataIndex: 'form',
          sorter: (a, b) => a.form.localeCompare(b.form),
        },
        {
          title: 'Promotor',
          dataIndex: 'promoter',
          sorter: (a, b) => compareByAlph(a.promoter, b.promoter),
        },
        {
          title: 'Empresa',
          dataIndex: 'sub_workspace_name',
          sorter: (a, b) => compareByAlph(a.sub_workspace_name, b.sub_workspace_name),
          className: newGetWorkspace.shared_environment ? '' : 'ocult'
        },
        {
          title: 'Contexto',
          dataIndex: 'context',
          sorter: (a, b) => a.context.localeCompare(b.context),
        },
        {
          title: 'Data da visita',
          dataIndex: 'dt_visit_format',
          sorter: (a, b) => compareByAlph(a.dt_visit_format, b.dt_visit_format),
        },
        {
          title: <CaretRightOutlined />,
          render: (a, b) => (
            <>
              {b.status === 'COMPLETE' ? (
                <Tooltip placement='top' title='Ver mais'>
                  <div style={{ height: '100%', fontSize: 18, marginLeft: 10 }}>
                    <Link to={`/backoffice/reports/survey/${b.form_id}/${b.promoter_id}/${b.visit_id}`}>
                      <InfoCircleOutlined />
                    </Link>
                  </div>
                </Tooltip>
              ) : (
                <></>
              )}
            </>
          ),
        },
      ]}
    />
  )
}

export default Tasks
