import { FolderAddOutlined, DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons'
import { Breadcrumb, Col, Pagination, Row, Dropdown, Menu, Popconfirm, Tooltip, Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { BigButton, ContainerList } from '../../../GlobalStyles'
import { Loading } from '../../../components/Loading'
import 'moment/locale/pt-br'
import { WorkspaceName, WorkspaceOption, ContainerIcon } from '../../WorkspacePage/styles'
import { FolderOpenOutlined } from '@ant-design/icons'
import useReactRouter from 'use-react-router'
import history from '../../../routes/history'
import { ContainerPaginationFooter } from '../../Team/Promoter/style'
import { ModalCreateFolder } from '../../../components/ModalCreateFolder'
import api_v2 from '../../../services/api-v2'
import { IGlobalResponse, INIT_GLOBAL_RESPONSE } from '../../../utils/global-response'
import { ModalUpdateFolder } from '../../../components/ModalUpdateFolder'
import { Mixpanel } from '../../../utils/mixpanel'
import { ContextUpload } from '../../../context/contextUpload'
import { hasIndex } from '../../../components/Menu/menuHorizonte'

export interface SearchFilters {
  id?: string
  description?: string
  date?: string
  form?: number
  pdv?: number
  user?: number
  dt_visit?: string
  start_date?: string
  end_date?: string
  sub_workspace?: number
}

const Training: React.FC = () => {
  const { location } = useReactRouter()
  const { permissions } = ContextUpload()

  const [createFolder, setCreateFolder] = useState<boolean>(false)
  const [updateFolder, setUpdateFolder] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [folder, setFolder] = useState<IGlobalResponse>(INIT_GLOBAL_RESPONSE)
  const [editFolder, setEditFolder] = useState<any>({})

  const searchFolder = async (obj?: any) => {
    try {
      setLoading(true)
      const { data } = await api_v2.get('/trainings-folder?is_deleted=false')
      setFolder(data)
      setLoading(false)

    } catch (error) {
      setLoading(false)
    }
  }

  const handleDeleteFolder = async (id: number) => {
    try {
      setLoading(true)
      await api_v2.delete('/trainings-folder/' + id)
      Mixpanel.track('Deletou pasta de treinamento', {
        params: {
          id: id,
        },
      })
      setLoading(false)
      searchFolder()
    } catch (error) {
      setLoading(false)
    }
  }

  const changePagination = async (page?: any) => {
    try {
      setLoading(true)
      const { data } = await api_v2.get(`/trainings-folder?is_deleted=false&page=${page}`)
      setFolder(data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }


  useEffect(() => {
    searchFolder()
  }, [location.search])

  return (
    <ContainerList>
      <Row gutter={[8, 8]} justify='space-between'>
        {loading ? <Loading /> : null}
        <Col span={18}>
          <h1>Treinamento</h1>
          <Breadcrumb className='breadcrumb'>
            <Link to='/backoffice/reports'>
              <Breadcrumb.Item>Relatórios</Breadcrumb.Item>
            </Link>

            <Breadcrumb.Item>Treinamento</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        {hasIndex(['add_training'], permissions) > -1 && (
          <Col>
            <BigButton
              style={{
                color: '#fff',
                backgroundColor: '#6600cc',
                border: 'none',
              }}
              onClick={() => setCreateFolder(!createFolder)}
            >
              Nova pasta
            </BigButton>
          </Col>
        )}
      </Row>
      <Row gutter={[25, 25]} style={{ marginTop: 30 }}>
        {hasIndex(['training.add_training'], permissions) > -1 && (
          <Col span={4}>
            <WorkspaceOption
              style={{
                background: 'rgb(239 239 239)',
                border: '2px solid #B670FC',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                width: '100%',
              }}
              onClick={() => setCreateFolder(!createFolder)}
            >
              <div style={{ width: '80%' }}>
                <FolderAddOutlined />
                <WorkspaceName>Criar pasta</WorkspaceName>
              </div>
            </WorkspaceOption>
          </Col>
        )}
        {folder &&
          folder.result.length > 0 &&
          folder.result.map((el: any) => (
            <Col span={4}>
              <WorkspaceOption style={{ width: '100%' }}>
                <div
                  style={{ width: '80%' }}
                  onClick={() => {
                    history.push(`/backoffice/register/training/file/${el.id}`)
                  }}
                >
                  <FolderOpenOutlined />
                  <WorkspaceName>{el.name}</WorkspaceName>
                </div>
                <ContainerIcon>
                  <Dropdown
                    overlay={
                      <Menu>
                        {hasIndex(['training.remove_training'], permissions) > -1 && (
                          <Popconfirm
                            onConfirm={() => handleDeleteFolder(el.id)}
                            title={`Tem certeza que deseja excluir a pasta ${el.name} ?`}
                            okText='Sim'
                            cancelText='Não'
                            placement='topRight'
                          >
                            <Menu.Item key='1' icon={<DeleteOutlined />}>
                              <Tooltip placement='top' title={'Deletar pasta'}>
                                Deletar pasta
                              </Tooltip>
                            </Menu.Item>
                          </Popconfirm>
                        )}
                        {hasIndex(['training.change_training'], permissions) > -1 && (
                          <Menu.Item
                            key='2'
                            icon={<EditOutlined />}
                            onClick={() => {
                              setEditFolder({ ...el })
                              setUpdateFolder(true)
                            }}
                          >
                            Editar pasta
                          </Menu.Item>
                        )}
                      </Menu>
                    }
                  >
                    <Button size='small' shape='circle' icon={<MoreOutlined />} />
                  </Dropdown>
                </ContainerIcon>
              </WorkspaceOption>
            </Col>
          ))}
      </Row>

      <ContainerPaginationFooter>
        <Pagination current={folder.page} total={folder.total} onChange={changePagination}/>
      </ContainerPaginationFooter>
      {createFolder && (
        <ModalCreateFolder
          visible={createFolder}
          close={() => {
            setEditFolder({})
            setCreateFolder(false)
          }}
          defaultValue={editFolder}
          reloadApi={(bool: any) => searchFolder(bool)}
        />
      )}
      {updateFolder && (
        <ModalUpdateFolder
          visible={updateFolder}
          close={() => {
            setEditFolder({})
            setUpdateFolder(false)
          }}
          defaultValue={editFolder}
          reloadApi={(bool: any) => searchFolder(bool)}
        />
      )}

      {loading && <Loading />}
    </ContainerList>
  )
}

export default Training
