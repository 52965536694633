import styled from 'styled-components'

export const Container = styled.div`
    width: calc(100% - 10px);
    border-radius: 12px;
    background-color: #f9f9f9;
    padding: 15px 15px 23px;
    margin-bottom: 20px;
    cursor: pointer;

    &&& .ant-divider-horizontal{
        margin: 15px 0;
    }
`

export const ContainerHeader = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 23px;
`

export const ContainerUser = styled.div`
    display: flex;
    align-items: center;

    img {
        margin-right: 13px;
        width: 36px;
        height: 36px;
        border-radius: 20px;
    }

    .giftFlag {
        width: 32px;
        height: 32px;
    }
`

export const ContainerStatus = styled.div``

export const Name = styled.p`
    color: #242424;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0;
`
export const ContainerOff = styled.div`
    display: flex;
    align-items: center;
`

export const CirculeStatus = styled.div`
    width: 10px;
    height: 10px;
    background-color: #e9830f;
    border-radius: 5px;
`

export const TextOff = styled.p`
    color: #6f7782;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: italic;
    margin-bottom: 0;
    margin-left: 10px;
`
export const ContainerTasks = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const Tasks = styled.div`
    height: 35px;
    border-radius: 7px;
    background-color: #f7f0fe;
    display: flex;
    align-items: center;
    color: #6600cc;
    padding: 5px;

    svg{
        margin-right: 4px;
    }
`

export const TextTask = styled.p`
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 0;
`

export const TextPdv = styled.p`
    margin-bottom: 0;
    color: #6f7782;
    font-family: "Open Sans";
    font-size: 16px;
`