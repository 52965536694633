import { Breadcrumb, Col, Row, Tag, Tooltip } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { DrawerFilter } from '../../../components/DrawerFilter'
import { Loading } from '../../../components/Loading'
import { BigButton, ContainerButtonsFootter, ContainerFormFilter, customStyles, GroupForm } from '../../../GlobalStyles'
import { ContainerTagg } from '../../Survey/FormListPage/styles'
import { ButtonClear, ButtonSearch, LabelForm } from '../../Team/Promoter/style'
import { FileSearchOutlined, FilterOutlined, CloseOutlined, ClearOutlined } from '@ant-design/icons'
import { FormHandles } from '@unform/core'
import history from '../../../routes/history'
import { Form } from '@unform/web'
import { Search } from '../../../components/form/SimpleSearch'
import SimpleRangePicker from '../../../components/form/SimpleRangePicker'
import Select from '../../../components/form/SimpleSelect'
import { optionUF } from '../../../utils/option-user'
import moment from 'moment'
import qs from 'querystring'
import useReactRouter from 'use-react-router'
import { ContainerFullGraph } from './style'
import { TableAnalytcs } from '../../../components_analytcs/Table'
import { Link } from 'react-router-dom'
import api_v2 from '../../../services/api-v2'
import { INIT_GLOBAL_RESPONSE } from '../../../utils/global-response'
import { ContextUpload } from '../../../context/contextUpload'
import { hasIndex } from '../../../components/Menu/menuHorizonte'
import { notAccess } from '../../../routes/ScrollToTop'

function PainelVisits() {
  const formRef = useRef<FormHandles>(null)
  const { permissions } = ContextUpload()
  const { location } = useReactRouter()
  const [filterTag, setFilterTag] = useState<any>({})
  const [visible, setVisible] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<any>(INIT_GLOBAL_RESPONSE)
  const [urlFilter, setUrlFilter] = useState<string>('')

  async function searchVisits(body: any, url: any, setter: any) {
    let { states_ids, date, flags_ids, promoters_ids, networks_ids, pdvs_ids, regions_ids, status, uf, week } = body
    let filters: any = {
      filterQuery: {},
      dataTag: {},
      filterQueryUrl: {},
    }

    if (promoters_ids && promoters_ids.length > 0) {
      let text = ''
      promoters_ids.forEach((el: any) => (text = text === '' ? `${el.value}` : text + `,${el.value}`))
      filters.filterQuery.promoters_ids = text
      filters.dataTag = { ...filters.dataTag, promoters_ids }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, promoters_ids: JSON.stringify(promoters_ids) }
    }

    if (states_ids && states_ids.length > 0) {
      let text = ''
      states_ids.forEach((el: any) => (text = text === '' ? `${el}` : text + `,${el}`))
      filters.filterQuery.states_ids = text
      filters.dataTag = { ...filters.dataTag, states_ids }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, states_ids: JSON.stringify(states_ids) }
    }

    if (pdvs_ids && pdvs_ids.length > 0) {
      let text = ''
      pdvs_ids.forEach((el: any) => (text = text === '' ? `${el.value}` : text + `,${el.value}`))
      filters.filterQuery.pdvs_ids = text
      filters.dataTag = { ...filters.dataTag, pdvs_ids }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, pdvs_ids: JSON.stringify(pdvs_ids) }
    }

    if (networks_ids && networks_ids.length > 0) {
      let text = ''
      networks_ids.forEach((el: any) => (text = text === '' ? `${el.value}` : text + `,${el.value}`))
      filters.filterQuery.networks_ids = text
      filters.dataTag = { ...filters.dataTag, networks_ids }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, networks_ids: JSON.stringify(networks_ids) }
    }

    if (regions_ids && regions_ids.length > 0) {
      let text = ''
      regions_ids.forEach((el: any) => (text = text === '' ? `${el.value}` : text + `,${el.value}`))

      filters.filterQuery.regions_ids = text
      filters.dataTag = { ...filters.dataTag, regions_ids }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, regions_ids: regions_ids }
    }

    if (status) {
      filters.filterQuery.status = status
      filters.dataTag = { ...filters.dataTag, status }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, status: status }
    }

    if (uf) {
      filters.filterQuery.uf = uf
      filters.dataTag = { ...filters.dataTag, uf }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, uf: uf }
    }

    if (week) {
      filters.filterQuery.week = week
      filters.dataTag = { ...filters.dataTag, week }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, week: week }
    }

    if (flags_ids && flags_ids.length > 0) {
      let text = ''
      flags_ids.forEach((el: any) => (text = text === '' ? `${el.value}` : text + `,${el.value}`))

      filters.filterQuery.flags_ids = text
      filters.dataTag = { ...filters.dataTag, flags_ids }
      filters.filterQueryUrl = { ...filters.filterQueryUrl, flags_ids: JSON.stringify(flags_ids) }
    }

    if (date) {
      filters.filterQueryUrl = { ...filters.filterQueryUrl, date: JSON.stringify(date) }
      if (date[0]) {
        filters.filterQuery.start_date = date[0]
        filters.dataTag = { ...filters.dataTag, start_date: date[0] }
      }

      if (date[1]) {
        filters.filterQuery.end_date = date[1]
        filters.dataTag = { ...filters.dataTag, end_date: date[1] }
      }
    } else {
      filters.filterQuery = {
        ...filters.filterQuery,
        end_date: moment().format('YYYY-MM-DD'),
        start_date: moment().format('YYYY-MM-DD'),
      }
      filters.dataTag = {
        ...filters.dataTag,
        end_date: moment().format('YYYY-MM-DD'),
        start_date: moment().format('YYYY-MM-DD'),
      }
    }

    const queryFilter = qs.stringify({ ...filters.filterQuery })

    setUrlFilter(queryFilter)
    const { data } = await api_v2.get(`/${url}?${queryFilter}`)
    setter(data)
    setLoading(false)
    setFilterTag(filters.dataTag)
    setVisible(false)
  }

  const removeFilter = async (typeRemove: string, value: string) => {
    let newObjTagg = filterTag

    if (typeRemove === 'promoters_ids') {
      newObjTagg.promoters_ids = newObjTagg.promoters_ids.filter((el: any) => el.value !== value)
      if (newObjTagg.promoters_ids.length === 0) delete newObjTagg.promoters_ids
    }

    if (typeRemove === 'states_ids') {
      newObjTagg.states_ids = newObjTagg.states_ids.filter((el: any) => el !== value)
      if (newObjTagg.states_ids.length === 0) delete newObjTagg.states_ids
    }

    if (typeRemove === 'flags_ids') {
      newObjTagg.flags_ids = newObjTagg.flags_ids.filter((el: any) => el.value !== value)
      if (newObjTagg.flags_ids.length === 0) delete newObjTagg.flags_ids
    }

    if (typeRemove === 'pdvs_ids') {
      newObjTagg.pdvs_ids = newObjTagg.pdvs_ids.filter((el: any) => el.value !== value)
      if (newObjTagg.pdvs_ids.length === 0) delete newObjTagg.pdvs_ids
    }

    if (typeRemove === 'regions_ids') {
      newObjTagg.regions_ids = newObjTagg.regions_ids.filter((el: any) => el.value !== value)
      if (newObjTagg.regions_ids.length === 0) delete newObjTagg.regions_ids
    }

    if (typeRemove === 'networks_ids') {
      newObjTagg.networks_ids = newObjTagg.networks_ids.filter((el: any) => el.value !== value)
      if (newObjTagg.networks_ids.length === 0) delete newObjTagg.networks_ids
    }

    if (typeRemove === 'start_date') {
      newObjTagg.date = [
        moment().format('YYYY-MM-DD'),
        filterTag.end_date ? filterTag.end_date : moment().format('YYYY-MM-DD'),
      ]
    }

    if (typeRemove === 'end_date') {
      newObjTagg.date = [
        filterTag.start_date ? filterTag.start_date : moment().format('YYYY-MM-DD'),

        moment().format('YYYY-MM-DD'),
      ]
    }
    setLoading(true)
    // const { data } = await api_v2.get(`/visit?${queryFilter}`)
    // setForms({ ...data, loading: false })
    updateUrl(newObjTagg)
    setFilterTag(newObjTagg)
    searchVisits(newObjTagg, 'visit/panel/list', setData)
    setLoading(false)
  }

  const updateUrl = (obj: any) => {
    window.sessionStorage.setItem('filterAnalytcs', JSON.stringify(obj))
    let url: any = {}
    if (obj && obj.date) {
      url.date = JSON.stringify(obj.date)
    } else {
      url.date = [moment().startOf('week').format('YYYY-MM-DD'), moment().endOf('week').format('YYYY-MM-DD')]
    }

    if (obj && obj.pdvs_ids) {
      url.pdvs_ids = JSON.stringify(obj.pdvs_ids)
    }

    if (obj && obj.networks_ids) {
      url.networks_ids = JSON.stringify(obj.networks_ids)
    }

    if (obj && obj.states_ids) {
      url.states_ids = JSON.stringify(obj.states_ids)
    }

    if (obj && obj.regions_ids) {
      url.regions_ids = JSON.stringify(obj.regions_ids)
    }

    if (obj && obj.flags_ids) {
      url.flags_ids = JSON.stringify(obj.flags_ids)
    }

    if (obj && obj.promoters_ids) {
      url.promoters_ids = JSON.stringify(obj.promoters_ids)
    }
    history.push(`/Analitycs/visits?${qs.stringify(url)}`)
  }

  const cleanFilter = async () => {
    window.sessionStorage.removeItem('filterAnalytcs')

    history.push(`/Analitycs/visits`)
    setVisible(false)
    setFilterTag({})
    formRef!.current!.reset()
  }

  const getStatusWithFilter = (data?: any) => {
    let block = notAccess(permissions, window.location.pathname)
    if (block === -2) return history.push('/notAcces')

    let obj: any = { ...qs.parse(location.search.replaceAll('?', '')), ...data }
    if (obj && obj.promoters_ids && typeof obj.promoters_ids === 'string')
      obj.promoters_ids = JSON.parse(obj.promoters_ids)

    if (obj && obj.states_ids && typeof obj.states_ids === 'string') obj.states_ids = JSON.parse(obj.states_ids)

    if (obj && obj.flags_ids && typeof obj.flags_ids === 'string') obj.flags_ids = JSON.parse(obj.flags_ids)
    if (obj && obj.regions_ids && typeof obj.regions_ids === 'string') obj.regions_ids = JSON.parse(obj.regions_ids)
    if (obj && obj.networks_ids && typeof obj.networks_ids === 'string') obj.networks_ids = JSON.parse(obj.networks_ids)
    if (obj && obj.pdvs_ids && typeof obj.pdvs_ids === 'string') obj.pdvs_ids = JSON.parse(obj.pdvs_ids)
    // if (obj && obj.wek) obj.wek = JSON.parse(obj.wek)
    // if (obj && obj.uf) obj.uf = JSON.parse(obj.uf)
    if (obj && obj.date && typeof obj.date === 'string') obj.date = JSON.parse(obj.date)

    if (!obj || !obj.date)
      obj.date = [moment().startOf('week').format('YYYY-MM-DD'), moment().endOf('week').format('YYYY-MM-DD')]
    updateUrl(obj)
    searchVisits(obj, 'visit/panel/list', setData)
  }

  useEffect(() => {
    let getValue = window.sessionStorage.getItem('filterAnalytcs')
    if (getValue !== undefined && getValue !== null && getValue !== 'undefined') {
      getStatusWithFilter(JSON.parse(getValue))
    } else {
      getStatusWithFilter()
    }
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns: any = [
    {
      title: 'Colaborador',
      width: 250,
      dataIndex: 'promoter',
      key: 'promoter',
      fixed: 'left',
      align: 'left',
    },
    {
      title: 'Colaborador Superior',
      dataIndex: 'supervisor',
      key: 'supervisor',
      width: 250,
      align: 'left',
    },
    { title: 'Periodicidade do Roteiro', dataIndex: 'period', key: 'period', width: 150, align: 'left' },
    { title: 'Rede', dataIndex: 'network', key: 'network', width: 250, align: 'left' },
    {
      title: 'Bandeira',
      dataIndex: 'flag',
      key: 'flag',
      width: 250,
      align: 'left',
    },
    { title: 'ID do PDV', dataIndex: 'pdv_id', key: 'pdv_id', width: 150, align: 'left' },
    { title: 'Ponto de venda', dataIndex: 'pdv', key: 'pdv', width: 350, align: 'left' },
    { title: 'Tipo de PDV', dataIndex: 'pdv_type', key: 'pdv_type', width: 250, align: 'left' },
    { title: 'Perfil PDV', dataIndex: 'pdv_profile', key: 'pdv_profile', width: 150, align: 'left' },
    { title: 'Canal PDV', dataIndex: 'pdv_channel', key: 'pdv_channel', width: 150, align: 'left' },
    { title: 'Estado', dataIndex: 'pdv_state', key: 'pdv_state', width: 150, align: 'left' },
    { title: 'Data do roteiro', dataIndex: 'dt_visit', key: 'dt_visit', width: 150, align: 'left' },
    { title: 'Primeiro Check-in', dataIndex: 'check_in_date', key: 'check_in_date', width: 150, align: 'left' },
    { title: 'Útimo Check-out', dataIndex: 'check_out_date', key: 'check_out_date', width: 150, align: 'left' },
    { title: 'Tempo em loja', dataIndex: 'time_store', key: 'time_store', width: 150, align: 'left' },
    { title: 'Fora do roteiro ?', dataIndex: 'without_script', key: 'without_script', width: 150, align: 'left' },
    { title: 'Tipo de Check-in', dataIndex: 'type_checkin', key: 'type_checkin', width: 150, align: 'left' },
    { title: 'Razão Não Visitou', dataIndex: 'reason', key: 'reason', width: 150, align: 'left' },
    { title: 'Quem auditou?', dataIndex: 'homolog_by', key: 'homolog_by', width: 150, align: 'left' },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      align: 'left',
      render: (a: any) => (
        <>
          {a === 'JUSTIFIED_ABSENCE' && 'Justificado'}
          {a === 'COMPLETE' && 'Concluído'}
          {a === 'PENDENT' && 'Pendente'}
          {a === 'IN_PROGRESS' && 'Em andamento'}
        </>
      ),
    },
    { title: 'Justificativa', dataIndex: 'justify', key: 'justify', width: 350, align: 'left' },
  ]

  const handleTable = async (queryParam: any) => {
    setLoading(true)
    const query = qs.stringify({
      page: queryParam.current,
    })
    const { data } = await api_v2.get(`/visit/panel/list?` + query + '&' + urlFilter)

    setData(data)
    setLoading(false)
  }

  return (
    <div style={{ padding: '0 10px' }}>
      <Row gutter={[8, 8]} justify='space-between'>
        <Col span={22}>
          <h1>Painel de visitas</h1>
          <Breadcrumb className='breadcumb'>
            <Link to='Analitycs'>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
            </Link>
            <Breadcrumb.Item>
              <Breadcrumb.Item>Painel de visitas</Breadcrumb.Item>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col>
          <Tooltip placement='top' title={'Filtrar'}>
            <BigButton onClick={() => setVisible(!visible)} style={{ padding: 9 }}>
              <FilterOutlined />
            </BigButton>
          </Tooltip>
        </Col>
        <Col span={24}>
          <ContainerTagg>
            {filterTag.start_date && (
              <Tag color='geekblue' onClick={() => removeFilter('start_date', `${filterTag.start_date}`)}>
                {moment(filterTag.start_date).format('DD/MM/YYYY')}
                <CloseOutlined />
              </Tag>
            )}
            {filterTag.end_date && (
              <Tag color='geekblue' onClick={() => removeFilter('end_date', `${filterTag.end_date}`)}>
                {moment(filterTag.end_date).format('DD/MM/YYYY')}
                <CloseOutlined />
              </Tag>
            )}

            {filterTag.week && (
              <Tag color='geekblue' onClick={() => removeFilter('week', filterTag.week)}>
                {filterTag.week} <CloseOutlined />
              </Tag>
            )}
            {filterTag.uf && (
              <Tag color='geekblue' onClick={() => removeFilter('uf', filterTag.uf)}>
                {filterTag.uf} <CloseOutlined />
              </Tag>
            )}
            {filterTag.status && (
              <Tag color='geekblue' onClick={() => removeFilter('status', filterTag.status)}>
                {filterTag.status} <CloseOutlined />
              </Tag>
            )}
            {filterTag.flags_ids &&
              filterTag.flags_ids.map((el: any) => (
                <Tag color='geekblue' onClick={() => removeFilter('flags_ids', el.value)}>
                  {el.label} <CloseOutlined />
                </Tag>
              ))}
            {filterTag.states_ids &&
              filterTag.states_ids.map((el: any) => (
                <Tag color='geekblue' onClick={() => removeFilter('states_ids', el)}>
                  {el} <CloseOutlined />
                </Tag>
              ))}
            {filterTag.regions_ids &&
              filterTag.regions_ids.map((el: any) => (
                <Tag color='geekblue' onClick={() => removeFilter('regions_ids', el.value)}>
                  {el.label} <CloseOutlined />
                </Tag>
              ))}
            {filterTag.promoters_ids &&
              filterTag.promoters_ids.map((el: any) => (
                <Tag color='geekblue' onClick={() => removeFilter('promoters_ids', el.value)}>
                  {el.label} <CloseOutlined />
                </Tag>
              ))}
            {filterTag.networks_ids &&
              filterTag.networks_ids.map((el: any) => (
                <Tag color='geekblue' onClick={() => removeFilter('networks_ids', el.value)}>
                  {el.label} <CloseOutlined />
                </Tag>
              ))}
            {filterTag.pdvs_ids &&
              filterTag.pdvs_ids.map((el: any) => (
                <Tag color='geekblue' onClick={() => removeFilter('pdvs_ids', el.value)}>
                  {el.label} <CloseOutlined />
                </Tag>
              ))}
          </ContainerTagg>
        </Col>
      </Row>
      {hasIndex(['visits_panel.view'], permissions) > -1 && (
        <ContainerFullGraph>
          <TableAnalytcs
            columns={columns}
            data={data.result}
            fullY={true}
            pagination={{
              current: data.page,
              pageSize: data.perpage,
              total: data.total,
              size: 'small',
              showSizeChanger: true,
              showTotal: () => `Exibindo ${data.result.length} de ${data.total} de registros`,
            }}
            onChange={handleTable}
          />
        </ContainerFullGraph>
      )}
      {loading && <Loading />}
      <DrawerFilter
        visible={visible}
        close={setVisible}
        title='Painel de visitas'
        footer={
          <ContainerButtonsFootter>
            <ButtonSearch type='button' onClick={() => formRef?.current?.submitForm()}>
              <FileSearchOutlined />
              Pesquisar
            </ButtonSearch>
            <ButtonClear onClick={() => cleanFilter()}>
              <ClearOutlined />
              Limpar
            </ButtonClear>
          </ContainerButtonsFootter>
        }
      >
        <ContainerFormFilter
          tabIndex={0}
          onKeyDown={(evt: any) => {
            if (evt.key === 'Enter') formRef?.current?.submitForm()
          }}
        >
          <Form ref={formRef} className='form' style={{ marginTop: '15px' }} onSubmit={getStatusWithFilter}>
            <SimpleRangePicker
              name='date'
              label='Selecione o periodo:'
              defaultValueInput={[moment().startOf('week'), moment().endOf('week')]}
              ranges={{
                Ontem: [moment().subtract('d', 1), moment().subtract('d', 1)],
                Hoje: [moment(), moment()],
                Amanhã: [moment().add('d', 1), moment().add('d', 1)],
                'Esta semana': [moment().startOf('week'), moment().endOf('week')],
                'Este mês': [moment().startOf('month'), moment().endOf('month')],
              }}
            />
            <GroupForm>
              <LabelForm>Selecione a promotor</LabelForm>
              <Search placeholder='Selecione o promotor' name='promoters_ids' path='promoters' isMult={true} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione a bandeira</LabelForm>
              <Search placeholder='Selecione a bandeira' name='flags_ids' path='flags' isMult={true} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione a rede</LabelForm>
              <Search placeholder='Selecione a rede' name='networks_ids' path='networks' isMult={true} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione o pdv</LabelForm>
              <Search placeholder='Selecione o pdv' name='pdvs_ids' path='pdvs' isMult={true} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione a região</LabelForm>
              <Search placeholder='Selecione a região' name='regions_ids' path='regions' isMult={true} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione o estado</LabelForm>
              <Select
                styles={customStyles}
                className='pre-select'
                placeholder='Selecione o status'
                options={optionUF}
                isMulti={true}
                name='states_ids'
              />
            </GroupForm>
          </Form>
        </ContainerFormFilter>
      </DrawerFilter>
    </div>
  )
}

export default PainelVisits
