import styled from 'styled-components'
import { Modal, Upload } from 'antd'

export const ContainerModal = styled(Modal)`
    &&& .ant-modal-content{
        border-radius: 12px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    }

    &&& .ant-modal-body{
        border-radius: 12px;
        padding-top: 50px;
    }

    &&& .ant-modal-close-x{
        color: red;
    }

    &&& .ant-upload.ant-upload-drag {
        width: auto !important;
        height: auto !important;
        padding: 28px 5px;
    }
`

export const Tag = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  border: 1px solid #9363e6;
  border-radius: 6px;
  margin-bottom: 15px;
    cursor: pointer;
    :hover{
        transition: all 0.3;
        background-color: rgba(147,99,230, 0.3);
    }
`
export const ContainerType = styled.div`
    width: 90%;
    margin-left: 15px;
`

export const Title = styled.p`
    font-size: 16px;
    font-weight: bold;
    color: #272d3b;
`

export const Description = styled.p`
    font-size: 12px;
    font-weight: bold;
    color: #6f7782;
    margin-bottom: 10px;
    line-height: 14px;
`

export const CardCreateUpload = styled(Upload.Dragger)`
    width: 292px;
    height: 316px;
    border-radius: 12px;
    border: 1px solid #d6d6d6;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 36px 26px 56px;

    img {
        width: 60px;
        height: 60px;
    }

    h4 {
        color: #242424;
        font-family: "Open Sans";
        font-size: 20px;
        font-weight: 600;
    }

    p {
        margin-bottom: 0;
        color: #666666;
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: 400;
        text-align: center;
    }

    i {
        color: #6f7782;
        font-family: "Open Sans";
        font-size: 16px;
        font-style: italic;
        text-align: center;
        width: 100%;
    }
`

export const Button = styled.button`
    cursor: pointer;
    border-radius: 5px;
    background-color: #6600cc;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 400;
    border: none;
    padding: 8px;

    svg {
        font-size: 20px;
    }
`
export const BtnExport = styled.a`
  padding: 10px;
  color: #6600cc;

  &:hover {
    color: #666666;
  }
`

export const ButtonLink = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 5px;
    margin: 10px auto 0;
    background-color: transparent;
    border: 1px solid #6600cc;

    a {
        padding: 0;
        margin-left: 10px;
    }

    svg {
        color: #6600cc;
    }
`