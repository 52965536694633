import { useState } from 'react'
import { CardStatusKanban } from '../CardStatusKanban'
import ModalKanban from '../ModalKanban'
import { Container, Count, ContainerHeader, Text, Scroll } from './style'

interface IProps {
  data: any
  nameTable: string
  tag: any
}

export const ContainerStatusKanban = ({ data, nameTable, tag }: IProps) => {
  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState({
    name: '',
    store_complete: 0,
    store_counts: 0,
    id: 0,
    nameTable
  })

  const selectTaks = (name: string, store_complete: number, store_counts: number, id: number) => {
    setSelected({
      name,
      store_complete,
      store_counts,
      id,
      nameTable
    })
    setVisible(!visible)
  }

  return (
    <Container>
      <ContainerHeader>
        <Text>{nameTable}</Text>
        <Count>{data.length}</Count>
      </ContainerHeader>
      <Scroll style={{ overflow: 'auto' }}>
        {data.length > 0 &&
          data.map((el: any, index: number) => (
            <CardStatusKanban
              key={index}
              data={el}
              status={nameTable}
              open={() => selectTaks(el.promoter_name, el.visits_done, el.visits_all, el.promoter_id)}
            />
          ))}
      </Scroll>
      {visible && <ModalKanban visible={visible} close={setVisible} promoter={selected} tag={tag} />}
    </Container>
  )
}
