import { Tooltip } from 'antd'
import React, { useState } from 'react'
import ReactLoading from 'react-loading'
import { ViewImg } from './style'
import { BiTimeFive } from 'react-icons/bi'

export const LoadingPicture = ({ el, open, index, text, option, handlePictures }: any) => {
  const [loadingImg, setLoadingImg] = useState(true)
  const [erroImg, setErroImg] = useState(false)

  return (
    <>
      {erroImg ? (
        <div
          style={{
            display: 'flex',
            marginRight: 10,
            borderRadius: 7,
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: 15,
            justifyContent: 'center',
            height: 80,
            width: 90,
            border: '1px solid orange',
          }}
        >
          <BiTimeFive size={25} color='orange' />
          <p
            style={{
              marginTop: 7,
              fontSize: 8,
              textAlign: 'center',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              color: 'orange',
              marginRight: 0,
            }}
          >
            Não sincronizada
          </p>
        </div>
      ) : (
        <>
          {loadingImg && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: 80,
                width: 80,
              }}
            >
              <ReactLoading type='spokes' color='#b670fc' height={20} width={20} />
            </div>
          )}
          <Tooltip title={text ? text : 'Visualizar foto'}>
            <ViewImg
              src={el}
              alt=''
              onLoad={() => setLoadingImg(false)}
              onError={() => setErroImg(true)}
              className='image-survey'
              style={{ display: loadingImg ? 'none' : 'block' }}
              onClick={() => {
                if (option === 2) {
                  // setNameForm(text)
                  handlePictures(index)
                } else {
                  open(index)
                }
              }}
            />
          </Tooltip>
        </>
      )}
    </>
  )
}
