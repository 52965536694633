import styled from 'styled-components'

export const Container = styled.div`
  input {
    box-sizing: border-box;
    margin: 0;
    margin-bottom: 10px;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum', 'tnum';
    position: relative;
    display: inline-block;
    width: 100%;
    height: 38px;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 1.5;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;

    border-radius: 4px;
    transition: all 0.4s;

    &:hover {
      border-color: #40a9ff;
      border-right-width: 1px;
    }

    &:focus {
      border-color: #40a0ff;
      border-right-width: 1px;
      outline: 0;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
  }

  &&& .ant-picker-input{
    margin: 0 !important;
    height: 30px !important;
    input {
      margin-bottom: 0 !important;
      height: 30px !important;
    }
  }
`

export const Error = styled.span`
  color: #ff4d4f;
  font-weight: bold;
`

export const Label = styled.label`
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  color: #6f7782;
  font-family: 'Open Sans';
  font-size: 15px;
  font-weight: 400;
  > span:first-child {
    color: #ff4d4f;
    line-height: 1.3;
    width: 100%;
    border-radius: 4px;
    transform: none;
    animation: fadeIn 350ms ease-in-out 1;
    @keyframes fadeIn {
      from {
        transform: translateY(-20px);
        opacity: 0;
      }
      to {
        transform: translateY(0);
        opacity: 1;
      }
    }
    + input,
    select {
      border-color: #ff4d4f;
      + svg {
        fill: #ff4d4f;
      }
    }
  }

  input{
    border-radius: 12px;
    border: 1px solid #d6d6d6;
    padding:5px;
  }
`
