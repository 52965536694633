import React from 'react'
import { Container, Content } from '../LayoutDefault/styles'
import Menu from '../Menu'

const LayoutWorkspace: React.FC = ({ children }) => (
  <Container>
    <Menu />
    <Content style={{ marginTop: 30, padding: 30 }}>{children}</Content>
  </Container>
)

export default LayoutWorkspace
