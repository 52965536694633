import { Container, Text } from './style'
import Logo from '../../assets/images/Rock-At.png'
export const Footer = () => {
  return (
    <Container>
      <Text>desenvolvido por</Text>
      <img src={Logo} alt='logo' />
    </Container>
  )
}
