import { Button, Dropdown, Menu, message, Popconfirm } from 'antd'
import { useState } from 'react'
import DefaultPage, { getStatusProduct } from '../../../components/DefaultPage'
import { DataStatus } from '../../../components/TabsVisits/style'
import { DeleteOutlined, MoreOutlined } from '@ant-design/icons'
import { Mixpanel } from '../../../utils/mixpanel'

const SchedulingTask = () => {
  const [loading, setLoading] = useState(false)
  const [reload, setReload] = useState(false)

  const deleteTask = async (id: string) => {
    setLoading(true)
    try {
      // await api_v2.delete('/brand/' + id)
      message.success('Tarefa excluido com sucesso!')

      setReload(!reload)
    } catch (err) {
      message.error('Erro ao deletar tarefa')
    }
    setLoading(false)
  }

  return (
    <DefaultPage
      titlePage='Agendamento de tarefas'
      breadcrumb={[{ name: 'Home', url: '/' }, { name: 'Agendamento de tarefas' }]}
      urlPage='/backoffice/survey/scheduling_task'
      routeApi={{
        method: 'get',
        route: 'task/schedule',
        versionApi: 'v2',
        reload,
      }}
      listButtons={{
        buttonCreate: { visible: true, type: 'url', url: '/backoffice/survey/scheduling_task_create' },
      }}
      load={{ loading, setLoading }}
      columns={[
        {
          title: 'Status',
          dataIndex: 'is_active',
          key: 'is_active',
          width: 80,
          render: (status: any) => {
            return <DataStatus>{getStatusProduct(status)}</DataStatus>
          },
        },
        {
          title: 'Nome',
          dataIndex: 'name',
          sorter: (a, b) => {
            return a.name.localeCompare(b.name)
          },
        },
        {
          title: 'Formulário',
          dataIndex: 'form_name',
        },
        {
          title: 'Data de início',
          dataIndex: 'start_date',
        },
        {
          title: 'Data de término',
          dataIndex: 'end_date',
          render: (status: string) => {
            if (status === null) return '-'
            return status
          },
        },
        {
          title: 'Frequência',
          dataIndex: 'frequency',
          render: (status: string) => {
            if (status === 'DAILY') return 'Diário'
            if (status === 'WEEKLY') return 'Semanal'
            if (status === 'BIWEEKLY') return 'Quinzenal'
            if (status === 'MONTHLY') return 'Mensal'
          },
        },
        {
          title: '#',
          width: 50,
          render: (obj: any) => {
            return (
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item key='1' icon={<DeleteOutlined />}>
                      <Popconfirm
                        title='Tem certeza que deseja excluir esta tarefa?'
                        onConfirm={() => {
                          Mixpanel.track('Excluir tarefa', obj)
                          deleteTask(obj.id)
                        }}
                        okText='Sim'
                        cancelText='Não'
                        placement='topRight'
                      >
                        Deletar tarefa
                      </Popconfirm>
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button size='small' shape='circle' icon={<MoreOutlined />} />
              </Dropdown>
            )
          },
        },
      ]}
    />
  )
}

export default SchedulingTask
