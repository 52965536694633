export const generateUUID = (): string => {
    const chars = '0123456789abcdef';
    const segments = [8, 4, 4, 4, 12];
  
    let uuid = '';
  
    for (let i = 0; i < segments.length; i++) {
      for (let j = 0; j < segments[i]; j++) {
        uuid += chars[Math.floor(Math.random() * 16)];
      }
  
      if (i < segments.length - 1) {
        uuid += '-';
      }
    }
  
    return uuid;
  }

  export const truncateString = (str, num) => {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  };