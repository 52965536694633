import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  right: 15px;
  width: 350px;
  max-height: 250px;
  border: 1px solid #637282;
  z-index: 9;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: #fff;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 52px;
  background-color: #6e55cc;
  color: #fff;
  padding: 10px;
  font-size: 17px;

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50px;
  }

  div svg {
    cursor: pointer;
  }
`

export const Body = styled.div`
  width: 100%;
  padding: 0 10px;
  margin-top: 10px;
`

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 44px;

  .flex {
    width: 80%;
    display: flex;
    align-items: center;
  }

  .flex p {
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
  }
`
