import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { Breadcrumb, Button, Checkbox, Col, message, Row } from 'antd'
import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import InputMask from '../../../components/form/InputMask'
import Input from '../../../components/form/SimpleInput'
import { Search } from '../../../components/form/SimpleSearch'
import { Container, Label } from '../../../GlobalStyles'
import history from '../../../routes/history'
import api_v2 from '../../../services/api-v2'
import ApiCep from '../../../services/ViaCep'
import { Mixpanel } from '../../../utils/mixpanel'
import { InputCheckbox, LabelCheck } from './styles'

const PDVCreatePage: React.FC = () => {
  const [rua, setRua] = useState('')
  const [cidades, setCidades] = useState('')
  const [estados, setEstados] = useState('')
  const [bairros, setBairros] = useState('')
  const [checked, setChecked] = useState(true)

  const formRef = useRef<FormHandles>(null)

  async function handleDados(event: any) {
    try {
      let cep: any = event.target.value

      cep = cep.replace('-', '')

      if (cep.length !== 8) return

      const response = await ApiCep.SearchCep(cep)

      let logradouro = response.data.logradouro
      let cidade = response.data.localidade
      let estado = response.data.uf
      let bairro = response.data.bairro

      setRua(logradouro)
      setEstados(estado)
      setCidades(cidade)
      setBairros(bairro)
    } catch (err) {
      message.error('CEP informado não encontrado')
    }
  }

  async function handleSubmit(body: any) {
    try {
      if (body.flag) body.flag_id = body.flag.value
      if (body.flag) body.flag = body.flag.value
      if (body.regional) body.regional_id = body.regional.value

      const schema = Yup.object().shape({
        name: Yup.string().required('* O nome é obrigatório'),
        cep: Yup.string().required('* O numero do CEP é obrigatório'),
        flag: Yup.number().required('* Bandeira é obrigatório'),
        state: Yup.string().required('* O estado é obrigatório'),
        city: Yup.string().required('* A cidade  é obrigatório'),
        address: Yup.string().required('* O nome de endereço é obrigatório'),
        neighborhood: Yup.string().required('* O nome do bairro obrigatório'),
      })
      await schema.validate(body, {
        abortEarly: false,
      })

      delete body.flag
      delete body.regional

      const { data } = await api_v2.post('/pdv', {
        is_active: checked,
        ...body,
      })

      if (data) {
        message.success('PDV cadastrada com sucesso')
        Mixpanel.track('Criou PDV', {
          params: {
            is_active: checked,
            ...body,
          },
        })
        setTimeout(() => {
          history.push('/backoffice/store/pdvs')
        }, 1000)
      } else {
        message.error('Erro ao cadastrar PDV')
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((el: any) => {
          formRef.current!.setFieldError(el.path, el.message)
        })
      }
    }
  }

  return (
    <Container>
      <h1>Cadastro de PDVs</h1>
      <p>Insira os dados para realizar o cadastro</p>
      <Breadcrumb separator='>' className='breadcumb'>
        <Link to='/backoffice/store/pdvs'>
          <Breadcrumb.Item> PDV </Breadcrumb.Item>
        </Link>
        <Breadcrumb.Item> Cadastro de PDV </Breadcrumb.Item>
      </Breadcrumb>
      <Form
        translate={undefined}
        className='form'
        onSubmit={handleSubmit}
        initialData={{ is_active: true }}
        ref={formRef}
      >
        <Row gutter={25}>
          <Col sm={24} md={11}>
            <Input name='name' type='text' placeholder='Informe o nome completo' label='Nome' />
          </Col>
          <Col sm={24} md={11}>
            <Input name='corporate_name' type='text' placeholder='Informe o nome fantasia' label='Nome fantasia' />
          </Col>
          <Col sm={24} md={2}>
            <LabelCheck>Ativo?</LabelCheck>
            <InputCheckbox>
              <Checkbox
                name='is_active'
                checked={checked}
                defaultChecked={true}
                onChange={(e: any) => {
                  setChecked(e.target.checked)
                }}
              />
            </InputCheckbox>
          </Col>
          <Col sm={24} md={8}>
            <InputMask
              name='cnpj'
              type='text'
              placeholder='99.999.999/9999-99'
              label='CNPJ'
              mask='99.999.999/9999-99'
            />
          </Col>
          <Col sm={24} md={8}>
            <Input name='pdv_code' type='text' placeholder='Informe o código do PDV' label='Código do PDV' />
          </Col>
          <Col sm={24} md={8}>
            <Input name='telephone' type='text' placeholder='Informe o telefone' label='Telefone' />
          </Col>
          <Col sm={24} md={8}>
            <Input name='manager' type='text' placeholder='Informe o nome do gerente' label='Gerente' />
          </Col>
          <Col sm={24} md={4}>
            <Label> Escolha a bandeira </Label>
            <Search placeholder='Selecione a bandeira' name='flag' path='flags' isMult={false} />
          </Col>
          <Col sm={24} md={4}>
            <Label> Escolha a região </Label>
            <Search placeholder='Selecione a região' name='regional' path='regionals' isMult={false} />
          </Col>
          <Col sm={24} md={8}>
            <Input name='cep' type='text' placeholder='Informe o CEP' label='CEP' onChange={handleDados} />
          </Col>
          <Col sm={24} md={8}>
            <Input
              name='address'
              type='text'
              placeholder='Informe o endereço'
              label='Endereço completo'
              value={rua}
              onChange={(e) => setRua(e.target.value)}
            />
          </Col>
          <Col sm={24} md={8}>
            <Input name='number' type='text' placeholder='Informe o número' label='Número' />
          </Col>
          <Col sm={24} md={8}>
            <Input
              name='neighborhood'
              type='text'
              placeholder='Informe o bairro'
              label='Bairro'
              value={bairros}
              onChange={(e) => setBairros(e.target.value)}
            />
          </Col>
          <Col sm={24} md={8}>
            <Input
              name='city'
              type='text'
              placeholder='Informe a cidade'
              label='Cidade'
              value={cidades}
              onChange={(e) => setCidades(e.target.value)}
            />
          </Col>
          <Col sm={24} md={8}>
            <Input
              name='state'
              label='Estado'
              placeholder='Informe o estado'
              value={estados}
              onChange={(e) => setEstados(e.target.value)}
            />
          </Col>
          <Col sm={24} md={8}>
            <Input name='complement' type='text' placeholder='Informe o complemento' label='Complemento' />
          </Col>
          <Col sm={24} md={6}>
            <Input name='type_pdv' type='text' placeholder='Tipo do PDV' label='Tipo do PDV' />
          </Col>
          <Col sm={24} md={6}>
            <Input name='profile_pdv' type='text' placeholder='Perfil do PDV' label='Perfil do PDV' />
          </Col>
          <Col sm={24} md={6}>
            <Input name='chanel_pdv' type='text' placeholder='Canal do PDV' label='Canal do PDV' />
          </Col>
          <Col sm={24} md={6}>
            <Input
              name='checkouts_in_pdv'
              type='text'
              placeholder='Quantidade de caixas'
              label='Quantidade de caixas'
            />
          </Col>
        </Row>
        <Row gutter={[16, 30]} style={{ marginTop: 10, paddingLeft: 10 }}>
          <Col>
            <Button type='primary' htmlType='submit'>
              Cadastrar
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  )
}
export default PDVCreatePage
