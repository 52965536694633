import {
  FileSearchOutlined,
  FilterOutlined,
  ClearOutlined,
  CloudDownloadOutlined,
  CloseOutlined
} from '@ant-design/icons'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { Breadcrumb, Col, Row, Table, Tooltip, Button, Tag } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import React, { useEffect, useRef, useState } from 'react'
import history from '../../../routes/history'
import { Loading } from '../../../components/Loading'
import {
  BigButton,
  ContainerButtonsFootter,
  ContainerFormFilter,
  ContainerList,
  GroupForm,
} from '../../../GlobalStyles'
import { ButtonClear, ButtonSearch, LabelForm } from '../../Team/Promoter/style'
import useReactRouter from 'use-react-router'
import { NoSearch } from '../../../components/NoSearch'
import { DrawerFilter } from '../../../components/DrawerFilter'
import { Search } from '../../../components/form/SimpleSearch'
import SimpletSelect from '../../../components/form/SimpleSelect2'
import SimpleRangePicker from '../../../components/form/SimpleRangePicker'
import moment from 'moment'
import { exportXls } from '../../../utils/funcoes'
import api_ms_promoter from '../../../services/api-ms-promoter'
import { ContainerTagg } from '../../Survey/FormListPage/styles'
import { IGlobalResponse, INIT_GLOBAL_RESPONSE } from '../../../utils/global-response'
import api_v2 from '../../../services/api-v2'

interface CheckInUser {
  id: string
  name: string
  email: string
  cpf: string
  type: string
  is_active: boolean
}

const HistoryRemoval: React.FC = () => {
  const { location } = useReactRouter()

  const [dataRemoval, setDataRemoval] = useState<any>(INIT_GLOBAL_RESPONSE)
  const [visible, setVisible] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [filterTag, setFilterTag] = useState<any>({})
  const [urlFilter, setUrlFilter] = useState<any>({})

  const formRef = useRef<FormHandles>(null)

  async function searchRemoval(body: any) {
    try {
      setDataRemoval({...dataRemoval, loading: true})
      let { promoter_id, removals_id, date } = body

      let filterQuery: any = {}
      let dataTag = {}

      if (promoter_id && promoter_id.value) {
        filterQuery.promoter_id = promoter_id.value
        dataTag = { ...dataTag, promoter_id }
      }

      if (removals_id && removals_id.value) {
        filterQuery.removals_id = removals_id.value
        dataTag = { ...dataTag, removals_id }
      }

      if (date && date[0] != moment().format('YYYY-MM-DD') && date[1] != moment().format('YYYY-MM-DD')) {
        if (date[0]) {
          filterQuery.start_date = date[0]
          dataTag = { ...dataTag, start_date: date[0] }
        }

        if (date[1]) {
          filterQuery.end_date = date[1]
          dataTag = { ...dataTag, end_date: date[1] }
        }
      }

      setLoading(true)
      const team = sessionStorage.getItem('@rocketpdv:workspace') ? sessionStorage.getItem('@rocketpdv:workspace') : undefined
      const { data } = await api_ms_promoter.post(`/history-removal`, {
        workspace_id: [JSON.parse(team).id],
        filter: filterQuery,
        pagination: {
          perpage: 10,
          page: 1
        }
      })
      setUrlFilter(filterQuery)
      setDataRemoval({ result: data.result.result, total: data.result.total, page: 1, loading: false, perpage: 10})
      setFilterTag(dataTag)
    } catch (error) {

    } finally {
      setLoading(false)
    }

  }

  const handleTable = async (queryParam: any) => {
    setDataRemoval({ ...dataRemoval, loading: true })

    const team = sessionStorage.getItem('@rocketpdv:workspace') ? sessionStorage.getItem('@rocketpdv:workspace') : undefined

    const { data } = await api_ms_promoter.post(`/history-removal`, {
      workspace_id: [JSON.parse(team).id],
      filter: urlFilter,
      pagination: {
        perpage: queryParam.pageSize,
        page: queryParam.current
      }
    })
    setDataRemoval({ result: data.result.result, total: data.result.total, page: queryParam.current, loading: false, perpage: queryParam.pageSize})
  }

  function compareByAlph(a: any, z: any) {
    if (a > z) {
      return -1
    }
    if (a < z) {
      return 1
    }
    return 0
  }

  const removeFilter = async (typeRemove: string, value: string) => {
    let newUrl: any = urlFilter
    let newObjTagg = filterTag

    if (typeRemove === 'promoter_id') {
      delete newObjTagg.promoter_id
      delete newUrl.promoter_id
    }

    if (typeRemove === 'removals_id') {
      delete newObjTagg.removals_id
      delete newUrl.removals_id
    }

    if (typeRemove === 'start_date') {
      delete newObjTagg.start_date
      delete newUrl.start_date
    }
    if (typeRemove === 'end_date') {
      delete newObjTagg.end_date
      delete newUrl.end_date
    }


    setLoading(true)
    const team = sessionStorage.getItem('@rocketpdv:workspace') ? sessionStorage.getItem('@rocketpdv:workspace') : undefined
    const { data } = await api_ms_promoter.post(`/history-removal`, {
      workspace_id: [JSON.parse(team).id],
      filter: newUrl,
      pagination: {
          perpage: 10,
          page: 1
        }
      })
    setDataRemoval({ result: data.result.result, total: data.result.total, page: 1, loading: false, perpage: 10})
    setFilterTag(newObjTagg)
    setLoading(false)
  }


  const columns: ColumnsType<CheckInUser> = [
    {
      title: 'Nome Promotor',
      dataIndex: 'promoter_name',
      sorter: (a: any, b: any) => compareByAlph(a.promoter_name, b.promoter_name),
      ellipsis: true,
    },
    {
      title: 'Início afastamento',
      dataIndex: 'removal_start',
      sorter: (a: any, b: any) => compareByAlph(a.removal_start, b.removal_start),
      ellipsis: true,
      render: (value: any) => moment(value).format('DD/MM/YYYY')
    },
    {
      title: 'Término afastamento',
      dataIndex: 'removal_end',
      sorter: (a: any, b: any) => compareByAlph(a.removal_end, b.removal_end),
      ellipsis: true,
      render: (value: any) => moment(value).format('DD/MM/YYYY')
    },
    {
      title: 'Motivo afastamento',
      dataIndex: 'removal_name',
      sorter: (a: any, b: any) => compareByAlph(a.removal_name, b.removal_name),
      ellipsis: true,
    },
    {
      title: 'Afastado por',
      dataIndex: 'user_name',
      sorter: (a: any, b: any) => compareByAlph(a.user_name, b.user_name),
      ellipsis: true,
    },
    {
      title: 'Data de criação',
      dataIndex: 'created_at',
      sorter: (a: any, b: any) => compareByAlph(a.created_at, b.created_at),
      ellipsis: true,
      render: (value: any) => moment(value).format('DD/MM/YYYY HH:mm:ss')
    }
  ]

  useEffect(() => {
    searchRemoval({})
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  const cleanFilter = async () => {
    history.push(`/backoffice/history/removal`)
    setDataRemoval([])
    setVisible(false)
    setFilterTag({})
    setUrlFilter({})
    formRef!.current!.reset()
  }


  return (
    <ContainerList>
      {loading ? <Loading /> : null}
      <Row gutter={[8, 8]} justify='space-between'>
        <Col span={20}>
          <h1>Historíco de afastamento</h1>
          <Breadcrumb separator='>' className='breadcumb'>

            <Breadcrumb.Item> Historíco de afastamento </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col>
          <Button
            type='primary'
            icon={<CloudDownloadOutlined />}
            onClick={async () => {
              setLoading(true)
              await exportXls(urlFilter, 'removals')
              setLoading(false)
            }}
          >
            Exportar
          </Button>
        </Col>
        <Col>
          <Tooltip placement='top' title={'Filtrar'}>
            <BigButton onClick={() => setVisible(!visible)} style={{ padding: 9 }}>
              <FilterOutlined />
            </BigButton>
          </Tooltip>
        </Col>
       
        <Col span={24}>
          <ContainerTagg>
            {filterTag?.promoter_id && (
              <Tag color='geekblue' onClick={() => removeFilter('promoter_id', `${filterTag?.promoter_id.value}`)}>
                {filterTag?.promoter_id.label}
                <CloseOutlined />
              </Tag>
            )}
            {filterTag.removals_id && (
              <Tag color='geekblue' onClick={() => removeFilter('removals_id', `${filterTag.removals_id.value}`)}>
                {filterTag.removals_id.label}
                <CloseOutlined />
              </Tag>
            )}
            {filterTag.start_date && (
              <Tag color='geekblue' onClick={() => removeFilter('start_date', `${filterTag.start_date}`)}>
                {moment(filterTag.start_date).format('DD/MM/YYYY')}
                <CloseOutlined />
              </Tag>
            )}
            {filterTag.end_date && (
              <Tag color='geekblue' onClick={() => removeFilter('end_date', `${filterTag.end_date}`)}>
                {moment(filterTag.end_date).format('DD/MM/YYYY')}
                <CloseOutlined />
              </Tag>
            )}
          </ContainerTagg>
        </Col>
      </Row>
      {loading ? (
        <Loading />
      ) : (
        <>
          {dataRemoval.result && dataRemoval.result.length > 0 ? (
            <Table
              columns={columns}
              rowKey={(user) => user.id}
              dataSource={dataRemoval.result}
              size='middle'
              pagination={{
                current: dataRemoval.page,
                pageSize: dataRemoval.perpage,
                total: dataRemoval.total,
                size: 'small',
                showSizeChanger: true,
                showTotal: () => `Exibindo ${dataRemoval.result.length} de ${dataRemoval.total} de registros`,
              }}
              loading={dataRemoval.loading}
              onChange={handleTable}
            />
          ) : (
            <NoSearch text='Não encontramos nenhum dado aqui.' />
          )}
        </>
      )}

      <DrawerFilter
        visible={visible}
        close={setVisible}
        title='Visitas justificadas'
        footer={
          <ContainerButtonsFootter>
            <ButtonSearch type='button' onClick={() => formRef?.current?.submitForm()}>
              <FileSearchOutlined />
              Pesquisar
            </ButtonSearch>
            <ButtonClear onClick={() => cleanFilter()}>
              <ClearOutlined />
              Limpar
            </ButtonClear>
          </ContainerButtonsFootter>
        }
      >
        <ContainerFormFilter
          tabIndex={0}
          onKeyDown={(evt: any) => {
            if (evt.key === 'Enter') formRef?.current?.submitForm()
          }}
        >
          <Form ref={formRef} className='form' style={{ marginTop: '15px' }} onSubmit={searchRemoval}>
            <GroupForm>
              <LabelForm>Selecione promotor</LabelForm>
              <Search placeholder='Selecione o promotor' name='promoter_id' path='promoters' isMult={false} />
            </GroupForm>
            <GroupForm>
              <LabelForm>Selecione o afastamento</LabelForm>
              <Search placeholder='Selecione o afastamento' name='removals_id' path='removals' isMult={false} />
            </GroupForm>
            <SimpleRangePicker name='date' label='Selecione o periodo:' />
          </Form>
        </ContainerFormFilter>
      </DrawerFilter>
    </ContainerList>
  )
}

export default HistoryRemoval
