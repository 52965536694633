import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: 912px) {
    width: 100vw;
  }
`

export const ContainerOrder = styled.div`
 position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 762px) {
    flex-wrap: wrap;
    left: 0px;
    padding-left: 10px;
    padding-top: 40px;
    row-gap: 10px;
  }
`

export const ColOrder = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 15;

  @media (max-width: 767px) {
    justify-content: flex-start;
    margin-right: 0;
    flex-wrap: wrap;
  }

`

export const ColOrderAction = styled.div`
  display: flex;
   @media (max-width: 767px) {
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
   }
`

export const BigButton = styled.button`
  padding: 0 5px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  margin-left: 20px;
  background-color: #6D55CC;
  border: none;
  border-radius: 5px;
`
export const ContainerOptions = styled.div`
  height: 90px;
  border: solid 1px #A6A6A6;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 3px;
  justify-content: space-between;
  padding: 3px;
  svg{
    font-size: 19px;
  }

  svg:hover {
    background-color: #F1F1F1;
  }
`

export const ContainerStatus = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const ContainerPopover = styled.div`
  width: 100px;
  a{
    color: #6f7782;
  }

  a:hover {
    transition: all 0.2s;
    color: #272d3b;
  }
  &&& .ant-divider-horizontal{
    margin: 6px 0;
  }
`

export const ContainerFilter = styled.div`
  padding: 10px;
`
export const Label = styled.p`
    color: #4e4e4e;
    font-family: "Open Sans";
    font-size: 14px;
    margin-bottom: 0;
`

export const InputFilter = styled.input`
  border: 1px solid #4e4e4e;
  background-color: #fff;
  padding: 3px 7px;
  border-radius: 5px;
`

export const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`

export const ButtonClean = styled.div`
  width: 45%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #6D55CC;
  border-radius: 7px;
  cursor: pointer;
  font-weight: bold;
`
export const ButtonSearch = styled.div`
  width: 45%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #16c266;
  border-radius: 7px;
  cursor: pointer;
  font-weight: bold;
`

export const OverFlowContainer = styled.div`
  width: 100%;
  @media (max-width: 982px) {
    overflow: auto;
    .row-prop > div > div {
      width: 430px;
      margin-left:10px;
    }
  }
  
  @media (max-width:767px) {
    overflow: auto;
    .row-prop > div > div {
      width: 355px;
      margin-left:10px;
    }
  }

`